import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';

import {
  Container,
  Divider,
  Typography,
  makeStyles,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import { AUTOMATION_CONFIGURATIONS_KEY } from '../../constants/automationConstants';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { isEmptyValue } from '../../utils/commonUtils';

// Import services and api
import {
  getAutomationConfigurations,
  updateConfiguration,
} from '../../apis/configurationApi';

import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  container: {
    margin: '25px, 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '25px',
    padding: '25px',
    border: `3px solid ${colors.bgLightGrey}`,
  },
}));

function AutomationsPanel() {
  const classes = useStyles();
  const [automationRfqStatus, setAutomationRfqStatus] = useState(false);
  const [automationSupplierTrackerStatus, setAutomationSupplierTrackerStatus] =
    useState(false);
  const [automationQuoteVerifierStatus, setAutomationQuoteVerifierStatus] =
    useState(false);

  useQuery('getAutomationConfigurations', () => {
    return getAutomationConfigurations().then((data) => {
      data?.map((config) => {
        switch (config.id) {
          case AUTOMATION_CONFIGURATIONS_KEY.RFQ:
            setAutomationRfqStatus(config.value);
            break;
          case AUTOMATION_CONFIGURATIONS_KEY.SUPPLIER_TRACKER:
            setAutomationSupplierTrackerStatus(config.value);
            break;
          case AUTOMATION_CONFIGURATIONS_KEY.QUOTE_VERIFIER:
            setAutomationQuoteVerifierStatus(config.value);
            break;
          default:
            console.error('invalid key');
            break;
        }
      });
    });
  });

  const onSwitchChange = (event) => {
    const { checked, name: key } = event.target;
    const statusLabel = checked ? 'on' : 'off';
    let actionLabel;
    let action;

    if (isEmptyValue(key)) {
      notifyError(
        `Configuration key: ${key} is not found. Please raise the bug to the product team.`
      );
      return;
    }

    switch (key) {
      case AUTOMATION_CONFIGURATIONS_KEY.RFQ:
        actionLabel = 'Auto-RFQ';
        action = setAutomationRfqStatus;
        break;
      case AUTOMATION_CONFIGURATIONS_KEY.SUPPLIER_TRACKER:
        actionLabel = 'Auto-Supplier Tracker';
        action = setAutomationSupplierTrackerStatus;
        break;
      case AUTOMATION_CONFIGURATIONS_KEY.QUOTE_VERIFIER:
        actionLabel = 'Auto-Quote Verifier';
        action = setAutomationQuoteVerifierStatus;
        break;
      default:
        console.error('invalid key');
        notifyError(
          `Configuration key: "${key}" is not found. Please raise the bug to the product team.`
        );
        return;
    }
    updateConfiguration({
      key,
      value: !!checked,
    })
      .then(() => {
        action(checked);
        notifySuccess(`Switched ${statusLabel} the ${actionLabel}`);
      })
      .catch(() => {
        notifyError(`Failed to switch ${statusLabel} the ${actionLabel}`);
      });
  };

  return (
    <div className={classes.body}>
      <Container className={classes.container}>
        <div>
          <Typography variant='h6'>
            Automation Feature Flags On/Off Switch
          </Typography>
          <Divider />
        </div>
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={automationRfqStatus}
                onChange={onSwitchChange}
                name={AUTOMATION_CONFIGURATIONS_KEY.RFQ}
                color='primary'
              />
            }
            label={'Auto-RFQ'}
          />
          <FormControlLabel
            control={
              <Switch
                checked={automationSupplierTrackerStatus}
                onChange={onSwitchChange}
                name={AUTOMATION_CONFIGURATIONS_KEY.SUPPLIER_TRACKER}
                color='primary'
              />
            }
            label={'Auto-Supplier Tracker'}
          />
          <FormControlLabel
            control={
              <Switch
                checked={automationQuoteVerifierStatus}
                onChange={onSwitchChange}
                name={AUTOMATION_CONFIGURATIONS_KEY.QUOTE_VERIFIER}
                color='primary'
              />
            }
            label={'Auto-Quote Verifier'}
          />
        </FormGroup>
      </Container>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps);

export default withRouter(withConnect(AutomationsPanel));
