import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles/index';

import {
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
} from '@material-ui/core';

import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';

import { recoverPassword } from '../actions';

import Background from '../components/backdrops/Background';

import { useQueryParams } from '../hooks/useQueryParamsHook';

import { validatePassword } from '../utils/passwordUtils';

import { verifyTokenV2 } from '../services/jwtService';

import { PASSWORD_RULES } from '../constants/passwordConstants';

import { colors } from '../palette';
import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  buttonConfirm: {
    width: '80%',
    fontWeight: 600,
    fontSize: '14pt',
    marginBottom: '5px',
    marginTop: '1.875rem',
    textTransform: 'none',
    color: colors.fontWhite,
    borderRadius: '0.375rem',
    backgroundColor: colors.buttonBlue,
    '&:hover': {
      backgroundColor: colors.blue050,
    },
  },
  container: {
    height: '100%',
    display: 'grid',
    alignContent: 'center',
    justifyItems: 'center',
  },
  inputField: {
    width: '90%',
    marginBottom: '5px',
    boxSizing: 'border-box',
  },
  inputLabel: {
    width: '80%',
    fontWeight: 600,
    fontSize: '12pt',
    marginTop: '10px',
    textAlign: 'left',
    color: colors.fontBlack,
    padding: '5px 0px 5px 0px',
  },
  login: {
    fontWeight: 600,
    marginTop: '5px',
    fontSize: '12pt',
    color: colors.bgGrey,
    textTransform: 'none',
    '&:hover': {
      background: colors.fontWhite,
      color: colors.fontBlackGrey,
    },
  },
  subtitle: {
    fontSize: '14pt',
    marginBottom: 10,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12pt',
    },
  },
  title: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: '24pt',
    fontWeight: 'bold',
    letterSpacing: '-1pt',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16pt',
    },
  },
  paper: {
    display: 'flex',
    borderRadius: 5,
    alignItems: 'center',
    padding: '3rem 2.5rem',
    flexDirection: 'column',
    boxShadow: '0 0.375rem 1.25rem 0 rgba(0, 0, 0, 0.20)',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 1rem',
    },
  },
  unvalidated: {
    width: '80%',
    textAlign: 'left',
    fontWeight: '5px',
    color: colors.fontMediumGray,
    [theme.breakpoints.down('sm')]: {
      fontSize: '10pt',
    },
  },
  validated: {
    width: '80%',
    textAlign: 'left',
    color: colors.confirmedProjectJob,
    [theme.breakpoints.down('sm')]: {
      fontSize: '10pt',
    },
  },
}));

function Recover(props) {
  const { recoverPassword: recoverPasswordFunc } = props;
  const classes = useStyles();
  const queryParams = useQueryParams();

  const token = queryParams.get('token');
  const [email, setEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRulesFailed, setPasswordRulesFailed] = useState(
    Object.keys(PASSWORD_RULES)
  );
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const { data: tokenValid, status: loadingStatus } = useQuery(
    'verifyToken',
    () => verifyTokenV2({ token }),
    {
      enabled: !!token,
    }
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = () => {
    const resetData = {
      password,
      email,
    };
    recoverPasswordFunc(resetData, token);
  };

  useEffect(() => {
    const hasEmail = email !== '';
    const samePassword = confirmPassword === password;
    const passwordValidated = isEmptyValue(passwordRulesFailed);

    setButtonDisabled(!hasEmail || !samePassword || !passwordValidated);
  }, [email, password, confirmPassword]);

  const renderResetPasswordForm = () => {
    return (
      <Fragment>
        <div className={classes.subtitle}>
          We take your account security very seriously. <br />
          Please choose a password that meets our criteria.
        </div>
        <InputLabel className={classes.inputLabel}>Email</InputLabel>
        <TextField
          id='email'
          name='email'
          className={classes.inputField}
          onChange={({ target }) => setEmail(target.value)}
          inputProps={{
            style: {
              textTransform: 'none',
            },
          }}
          style={{
            width: '80%',
          }}
          size='small'
          variant='outlined'
        />
        <InputLabel className={classes.inputLabel}>Password</InputLabel>
        <TextField
          id='password'
          name='password'
          type={showPassword ? 'text' : 'password'}
          className={classes.inputField}
          onChange={({ target }) => {
            setPasswordRulesFailed(validatePassword(target.value));
            setPassword(target.value);
          }}
          variant='outlined'
          size='small'
          style={{
            width: '80%',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? (
                    <VisibilityOutlined />
                  ) : (
                    <VisibilityOffOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {Object.entries(PASSWORD_RULES)?.map(([key, value]) => {
          return (
            <div
              key={`${key}-rules`}
              className={
                passwordRulesFailed.includes(key)
                  ? classes.unvalidated
                  : classes.validated
              }
            >
              {value.message}
            </div>
          );
        })}
        <InputLabel className={classes.inputLabel}>Retype Password</InputLabel>
        <TextField
          id='confirmPassword'
          name='confirmPassword'
          type={showPassword ? 'text' : 'password'}
          className={classes.inputField}
          onChange={({ target }) => {
            setConfirmPassword(target.value);
          }}
          variant='outlined'
          size='small'
          style={{
            width: '80%',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? (
                    <VisibilityOutlined />
                  ) : (
                    <VisibilityOffOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type='button'
          disabled={buttonDisabled}
          fullWidth
          variant='contained'
          margin='normal'
          className={classes.buttonConfirm}
          onClick={() => handleSubmit()}
        >
          Reset Password
        </Button>
      </Fragment>
    );
  };

  const renderTokenInvalidMessage = () => {
    return (
      <div className={classes.subtitle}>
        Token is invalid or has expired. <br />
        Please request for a new password reset link.
      </div>
    );
  };

  return (
    <Container component='main' className={classes.container} maxWidth='sm'>
      <Background />
      <Paper className={classes.paper}>
        <div className={classes.title}>Reset Your Password</div>
        {loadingStatus === 'loading' && <CircularProgress />}
        {loadingStatus === 'success' &&
          tokenValid === true &&
          renderResetPasswordForm()}
        {loadingStatus === 'success' &&
          tokenValid === false &&
          renderTokenInvalidMessage()}
        <Button
          type='button'
          fullWidth
          variant='text'
          margin='normal'
          className={classes.login}
          component={Link}
          to='/login'
        >
          Back to Login
        </Button>
      </Paper>
    </Container>
  );
}

function matchDispatchToProps(dispatch, props) {
  return {
    recoverPassword: (resetData, token) =>
      dispatch(recoverPassword(resetData, token, props)),
  };
}

const withConnect = connect(null, matchDispatchToProps);

export default withConnect(Recover);
