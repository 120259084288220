import React, { useContext, useMemo, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';

import { Add } from '@material-ui/icons';

import { FtrBoldText, FtrButton } from '../../ftr-components';
import { FlexColumn, FlexRow } from '../../layouts/FlexLayouts';

import EditIcon from '../../../assets/icons/edit_pencil_icon.svg';
import DeleteIcon from '../../../assets/icons/delete_icon.svg';

import withAddEditCustomerAddressHOC from './withAddEditCustomerAddressHOC';

import { ConfirmOrderPopupContext } from '../ConfirmOrderPopup';
import FtrSvgImage from '../../images/FtrSvgImage';

import {
  updateMultiCheckoutFormState,
  updateMulticheckoutFormAddress,
} from '../../../actions/multiCheckoutForm';

import { getFullDeliveryAddress } from '../../../utils/addressUtils';
import { isEmptyValue } from '../../../utils/commonUtils';

import { colors } from '../../../palette';

// ------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  menuItemRoot: {
    borderRadius: '0.75rem',
    whiteSpace: 'break-spaces',
    margin: '0.5rem 0',
    padding: '0.5rem 1rem',
    '&:hover': {
      backgroundColor: colors.dropdownHover,
    },
    '&.Mui-selected': {
      backgroundColor: colors.dropdownSelected,
    },
  },
}));

function CustomerAddressDisplay(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { customerID } = useContext(ConfirmOrderPopupContext);

  const {
    type = 'shipping',
    addressList = [],
    addressObj = {},
    updateAddEditCustomerAddressHOCState = () => {},
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const header = type === 'shipping' ? 'Shipping Address' : 'Billing Address';
  const addAddressBtnText =
    type === 'shipping' ? 'Add shipping address' : 'Add billing address';

  const { contactName, contactNumber, address } = useMemo(() => {
    if (isEmptyValue(addressObj)) {
      return {};
    }
    return getFullDeliveryAddress(addressObj);
  }, [addressObj]);

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      showAddressListDropdown: false,
      hoverID: null,
    }
  );

  const handleShowAddAddressPopup = () => {
    updateLocalState({ showAddressListDropdown: false });
    updateAddEditCustomerAddressHOCState({
      showAddAddressPopup: true,
      addressType: type,
      mode: 'add',
      customerID,
    });
  };

  const renderAddAddressButton = () => {
    return (
      <FtrButton
        color='blue'
        variant='text'
        startIcon={<Add />}
        onClick={handleShowAddAddressPopup}
      >
        {addAddressBtnText}
      </FtrButton>
    );
  };

  const renderAddressObjActionButtons = (addObj) => {
    return (
      <FlexRow
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      >
        {Boolean(addObj.defaultShipping) !== true && (
          <FtrSvgImage
            src={DeleteIcon}
            width={16}
            height={16}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              updateAddEditCustomerAddressHOCState({
                showDeleteAddressPopup: true,
                addressObj: addObj,
              });
            }}
          />
        )}
        <FtrSvgImage
          src={EditIcon}
          width={16}
          height={16}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            updateAddEditCustomerAddressHOCState({
              showAddAddressPopup: true,
              addressType: type,
              mode: 'edit',
              customerID,
              addressObj: addObj,
            });
            updateLocalState({ showAddressListDropdown: false });
          }}
        />
      </FlexRow>
    );
  };

  const renderAddressMenuItem = (addObj) => {
    const {
      contactName: _contactName,
      contactNumber: _contactNumber,
      address: _address,
    } = getFullDeliveryAddress(addObj);

    const selected = addObj.deliveryInfoID === addressObj.deliveryInfoID;

    return (
      <MenuItem
        key={addObj.deliveryInfoID}
        onClick={() => {
          const value = addObj;
          if (type === 'shipping') {
            dispatch(
              updateMulticheckoutFormAddress({
                country: value?.country,
                deliveryInfoID: value?.deliveryInfoID,
              })
            );
          } else {
            dispatch(
              updateMultiCheckoutFormState({
                billingAddressID: value.deliveryInfoID,
              })
            );
          }
          updateLocalState({ showAddressListDropdown: false });
        }}
        classes={{
          root: clsx(classes.menuItemRoot, {
            'Mui-selected': selected,
          }),
        }}
        value={addObj}
        data-my-value={addObj}
        onMouseEnter={() => {
          updateLocalState({ hoverID: addObj.deliveryInfoID });
        }}
        onMouseLeave={() => {
          updateLocalState({ hoverID: null });
        }}
      >
        <div style={{ position: 'relative', width: '100%' }}>
          <FlexRow>
            <FtrBoldText style={{ color: colors.neutral070, fontWeight: 700 }}>
              {_contactName} |
            </FtrBoldText>
            <FtrBoldText style={{ color: colors.neutral060 }}>
              {_contactNumber}
            </FtrBoldText>
          </FlexRow>
          <FtrBoldText style={{ color: colors.neutral060, whiteSpace: 'wrap' }}>
            {_address}
          </FtrBoldText>
          {localState.hoverID === addObj.deliveryInfoID &&
            renderAddressObjActionButtons(addObj)}
        </div>
      </MenuItem>
    );
  };

  return (
    <>
      <FlexColumn>
        <FtrBoldText style={{ color: colors.neutral070, fontWeight: 700 }}>
          {header}
        </FtrBoldText>
        {!isEmptyValue(addressObj) && (
          <FlexColumn style={{ marginTop: '0.5rem', gap: 0 }}>
            <FlexRow>
              <FtrBoldText
                style={{ color: colors.neutral070, fontWeight: 700 }}
              >
                {contactName} |
              </FtrBoldText>
              <FtrBoldText style={{ color: colors.neutral060 }}>
                {contactNumber}
              </FtrBoldText>
            </FlexRow>
            <FtrBoldText style={{ color: colors.neutral060 }}>
              {address}
            </FtrBoldText>
          </FlexColumn>
        )}
        <div>
          {isEmptyValue(addressObj) && renderAddAddressButton()}
          {!isEmptyValue(addressObj) && (
            <FtrButton
              color='blue'
              variant='text'
              style={{
                paddingLeft: 0,
                paddingTop: 0,
              }}
              onClick={(event) => {
                event.preventDefault();
                setAnchorEl(event.currentTarget);
                updateLocalState({ showAddressListDropdown: true });
              }}
            >
              Change
            </FtrButton>
          )}
        </div>
      </FlexColumn>
      <Popper
        open={localState.showAddressListDropdown}
        anchorEl={anchorEl}
        transition
        disablePortal
        style={{
          zIndex: 9999,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              style={{
                padding: '0 8px',
                maxHeight: 500,
                maxWidth: 700,
                overflowX: 'auto',
                overflowY: 'auto',
                border: `1px solid ${colors.neutral030}`,
                borderRadius: 20,
              }}
            >
              <ClickAwayListener
                onClickAway={() =>
                  updateLocalState({ showAddressListDropdown: false })
                }
              >
                <MenuList>
                  {addressList?.map((addObj) => renderAddressMenuItem(addObj))}
                  <MenuItem onClick={handleShowAddAddressPopup}>
                    {renderAddAddressButton()}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default withAddEditCustomerAddressHOC(CustomerAddressDisplay);
