import React from 'react';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { colors } from '../../palette';
import { createCustomTooltip } from '../CustomizedTooltips';

function FailedIcon(props) {
  const { toolTipText = '', className, style } = props;

  const CustomTooltip = createCustomTooltip(style);
  return (
    <CustomTooltip
      title={toolTipText}
      placement='top'
      arrow
      className={className}
      disableHoverListener={!toolTipText}
    >
      <HighlightOffIcon
        style={{
          fontSize: '1.5rem',
          color: colors.red040,
        }}
      />
    </CustomTooltip>
  );
}

export default FailedIcon;
