import React from 'react';
import { useQuery } from 'react-query';
import { LinearProgress, Grid } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core/styles';

import { getDailyDashboardStats } from '../../apis/dashboardApi';

import { getDaysElapsed } from '../../utils/dateTimeUtils';
import { FtrTypography } from '@components/ftr-components';

const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: theme.spacing(3),
  },
}));

const projectColumns = [
  { field: 'projectID', headerName: 'Project ID', flex: 1 },
  { field: 'ownerName', headerName: 'Customer', flex: 1 },
];

const supplierProjectColumns = [
  { field: 'projectID', headerName: 'Project ID', flex: 1 },
  { field: 'supplierName', headerName: 'Supplier', flex: 1 },
];

const supplierColumns = [
  { field: 'name', headerName: 'Name', flex: 1 },
  {
    field: 'dateAdded',
    headerName: '# of Blacklisted Days',
    flex: 1,
    renderCell: (params) => {
      const daysElapsed = getDaysElapsed(params.row.dateAdded);
      return (
        <FtrTypography type='body' fontSize='14'>
          {daysElapsed} days
        </FtrTypography>
      );
    },
  },
];
const onboardingColumns = [
  { field: 'projectID', headerName: 'Project ID', flex: 1 },
  { field: 'supplierName', headerName: 'Supplier', flex: 1 },
  {
    field: 'orderedDate',
    headerName: 'Days since PO issued',
    flex: 1,
    renderCell: (params) => {
      const daysElapsedSincePOIssued = getDaysElapsed(params.row.orderedDate);
      return (
        <FtrTypography type='body' fontSize='14'>
          {daysElapsedSincePOIssued} days
        </FtrTypography>
      );
    },
  },
];

const getProjectID = (row) => row.projectID;
const getSupplierID = (row) => row.userID;
const getProjectAndSupplierID = (row) =>
  `${getProjectID(row)} - ${row?.supplierName}`;

const sharedProps = {
  pageSize: 5,
  autoHeight: true,
  density: 'compact',
};

const DailyDashboard = () => {
  const classes = useStyles();

  const {
    status: loading,
    data,
    error,
  } = useQuery('getDailyDashboardStats', getDailyDashboardStats);

  if (error) {
    return (
      <FtrTypography type='heading'>
        Error loading dashboard stats
      </FtrTypography>
    );
  }

  return (
    <div className={classes.body}>
      <h1 style={{ marginTop: 0 }}>Daily Dashboard</h1>
      {loading !== 'success' ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <h3>Verifying Projects</h3>
            <DataGrid
              rows={data?.allVerifyingProjects}
              columns={projectColumns}
              getRowId={getProjectID}
              {...sharedProps}
            />
          </Grid>
          <Grid item xs={3}>
            <h3>CAR Raised Projects</h3>
            <DataGrid
              rows={data?.customerActionRequestedProjects}
              columns={projectColumns}
              getRowId={getProjectID}
              {...sharedProps}
            />
          </Grid>
          <Grid item xs={3}>
            <h3>Unquoted Projects</h3>
            <DataGrid
              rows={data?.unquotedProjects}
              columns={projectColumns}
              getRowId={getProjectID}
              {...sharedProps}
            />
          </Grid>
          <Grid item xs={3}>
            <h3>Unissued PO Projects</h3>
            <DataGrid
              rows={data?.unissuedPOProjects}
              columns={projectColumns}
              getRowId={getProjectID}
              {...sharedProps}
            />
          </Grid>
          <Grid item xs={3}>
            <h3>ORM Projects Late List</h3>
            <DataGrid
              rows={data?.lateProjects}
              columns={supplierProjectColumns}
              getRowId={getProjectAndSupplierID}
              {...sharedProps}
            />
          </Grid>
          <Grid item xs={3}>
            <h3>ORM Projects Due Today</h3>
            <DataGrid
              rows={data?.dueTodayProjects}
              columns={supplierProjectColumns}
              getRowId={getProjectAndSupplierID}
              {...sharedProps}
            />
          </Grid>
          <Grid item xs={3}>
            <h3>ORM Projects Due Tomorrow</h3>
            <DataGrid
              rows={data?.dueInOneDayProjects}
              columns={supplierProjectColumns}
              getRowId={getProjectAndSupplierID}
              {...sharedProps}
            />
          </Grid>
          <Grid item xs={3}>
            <h3>ORM Projects Due in Two Days</h3>
            <DataGrid
              rows={data?.dueInTwoDaysProjects}
              columns={supplierProjectColumns}
              getRowId={getProjectAndSupplierID}
              {...sharedProps}
            />
          </Grid>
          <Grid item xs={3}>
            <h3>Unacknowledged PO Projects</h3>
            <DataGrid
              rows={data?.unacknowledgedPOProjects}
              columns={supplierProjectColumns}
              getRowId={getProjectID}
              {...sharedProps}
            />
          </Grid>
          <Grid item xs={3}>
            <h3>Rework Project List</h3>
            <DataGrid
              rows={data?.reworkProjects}
              columns={[
                { field: 'projectID', headerName: 'Project ID', flex: 1 },
                { field: 'itemID', headerName: 'Item ID', flex: 1 },
                { field: 'supplierName', headerName: 'Supplier', flex: 1 },
              ]}
              getRowId={getProjectID}
              {...sharedProps}
            />
          </Grid>
          <Grid item xs={3}>
            <h3>Outbound Deliveries</h3>
            <DataGrid
              rows={data?.outboundDeliveryProjects}
              columns={projectColumns}
              getRowId={getProjectID}
              {...sharedProps}
            />
          </Grid>

          <Grid item xs={3}>
            <h3>Inbound Deliveries</h3>
            <DataGrid
              rows={data?.inboundDeliveryProjects}
              columns={supplierProjectColumns}
              getRowId={getProjectID}
              {...sharedProps}
            />
          </Grid>
          <Grid item xs={3}>
            <h3>Onboarding Projects</h3>
            <DataGrid
              rows={data?.onboardingProjects}
              columns={onboardingColumns}
              getRowId={getProjectID}
              {...sharedProps}
            />
          </Grid>
          <Grid item xs={3}>
            <h3>Blacklisted Suppliers</h3>
            <DataGrid
              rows={data?.blacklistedSuppliers}
              columns={supplierColumns}
              getRowId={getSupplierID}
              {...sharedProps}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default DailyDashboard;
