import { create } from 'zustand';

import { mergeObjects } from '../utils/objectUtils';

import { SEVERITY } from '../constants/errorMessageConstants';

// -------------------------------------------------------------------------------------------------

const DEFAULT_STORE = { message: '', additional: { severity: SEVERITY.INFO } };

const useSnackbarStore = create((set) => ({
  snackbarStore: DEFAULT_STORE,
  setSnackbarStore: (message, additional = {}) => {
    const newStore = mergeObjects(DEFAULT_STORE, { message, additional });
    set({ snackbarStore: newStore });

    setTimeout(() => set({ snackbarStore: DEFAULT_STORE }), 1000);
  },
}));

export default useSnackbarStore;
