// Import settings
import React from 'react';

// Import material UI components
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';
import { DatePicker } from '@material-ui/pickers';

// Import color palette
import { colors } from '../../palette';
import PpeQuoteNoticeLabel from '../labels/PpeQuoteNoticeLabel';
import { convertPriceWithQuantityToCurrency } from '../../utils/currencyUtils';
import KeyValueInfoDisplay from '../labels/KeyValueInfoDisplay';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  containerTitle: {
    margin: '6px 0',
  },
  quoteWrapper: {
    margin: '30px 0px',
    padding: '50px',
    border: `1px solid ${colors.inputLabelGrey}`,
    display: 'flex',
    flexDirection: 'column',
  },
  quotePropertyHeaderText: {
    fontWeight: 'bold',
    margin: 0,
  },
  divider: {
    margin: '30px 0px',
  },
  containerInfo: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      rowGap: '10px',
    },
  },
}));

function PpeQuotationForm(props) {
  const {
    item,
    inputFields,
    userID,
    currency,
    exchangeRate,
    role,
    remarks,
    dateOfExpiry,
    suppliers,
    onRemarksChange,
    onQuoteDateChange,
    onQuoteUserIdChange,
    onAcceptQuote,
  } = props;

  const classes = useStyles();

  const { unitPriceStr, totalPriceStr } = convertPriceWithQuantityToCurrency({
    totalPrice: item.price,
    quantity: item.quantity,
    currency,
    exchangeRate,
  });

  return (
    <div>
      <Grid container fluid='true' spacing={3} direction='column'>
        <Grid item className={classes.container}>
          <Grid item>
            <PpeQuoteNoticeLabel />
            <div className={classes.quoteWrapper}>
              <Grid container className={classes.containerInfo}>
                <Grid item md={2} sm={12}>
                  <Typography
                    gutterBottom
                    variant='body1'
                    className={classes.quotePropertyHeaderText}
                  >
                    Quantity
                  </Typography>
                  <Typography gutterBottom variant='body2'>
                    <KeyValueInfoDisplay
                      value={item.quantity ? item.quantity : 'NIL'}
                      key={'Quantity'}
                      bigValueFontSize
                      color={colors.blue050}
                    />
                  </Typography>
                </Grid>

                <Grid item md={6} sm={12}>
                  <Typography
                    gutterBottom
                    variant='body1'
                    className={classes.quotePropertyHeaderText}
                  >
                    Unit Price
                  </Typography>
                  <Typography component='span' gutterBottom variant='body2'>
                    <KeyValueInfoDisplay
                      value={unitPriceStr}
                      key={'Unit Price'}
                      bigValueFontSize
                      color={colors.blue050}
                    />
                  </Typography>
                </Grid>
                <Grid item md={2} sm={12}>
                  <Typography
                    gutterBottom
                    variant='body1'
                    className={classes.quotePropertyHeaderText}
                  >
                    Total Price
                  </Typography>
                  <Typography component='span' gutterBottom variant='body2'>
                    <KeyValueInfoDisplay
                      value={totalPriceStr}
                      key={'Total Price'}
                      bigValueFontSize
                      color={colors.blue050}
                    />
                  </Typography>
                </Grid>
                <Grid item md={2} sm={12}>
                  <Typography
                    gutterBottom
                    variant='body1'
                    className={classes.quotePropertyHeaderText}
                  >
                    Lead Time
                  </Typography>
                  <Typography gutterBottom variant='body2'>
                    <KeyValueInfoDisplay
                      value={
                        inputFields[0]
                          ? `${inputFields[0].leadTime || '14'} Working Days`
                          : 'NIL'
                      }
                      key={'Lead Time'}
                      bigValueFontSize
                      color={colors.blue050}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid item className={classes.container}>
          <Typography
            gutterBottom
            variant='body1'
            className={classes.containerTitle}
          >
            Remarks / Clarifications
          </Typography>
          <TextField
            required
            placeholder='Please enter any remarks (type NIL for no remarks)'
            multiline={true}
            variant='outlined'
            minRows={6}
            maxRows={6}
            fullWidth
            name='remarks'
            onChange={(event) => {
              onRemarksChange(event.target.value);
            }}
            value={remarks}
            inputProps={{
              maxLength: 8000,
            }}
          />
        </Grid>
        {role === 'admin' || role === 'reviewer' || role === 'superadmin' ? (
          <Grid item className={classes.container}>
            <Typography
              gutterBottom
              variant='body1'
              className={classes.containerTitle}
            >
              Assign Quote to
            </Typography>
            <TextField
              placeholder='Supplier name'
              variant='outlined'
              id='userID'
              select
              fullWidth
              onChange={(event) => onQuoteUserIdChange(event.target.value)}
              value={userID}
              defaultValue=''
            >
              {suppliers?.map((supplier) => (
                <MenuItem key={supplier.userID} value={supplier.userID}>
                  {`${supplier.name} <${supplier.email}>`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        ) : (
          <div />
        )}
        <Grid item className={classes.container}>
          <Tooltip
            title='Quotation will automatically be removed from system beyond selected date'
            placement='right'
            arrow
          >
            <Typography
              gutterBottom
              variant='body1'
              className={classes.containerTitle}
            >
              Quote valid till:
            </Typography>
          </Tooltip>
          <DatePicker
            value={dateOfExpiry}
            onChange={(newDate) => onQuoteDateChange(newDate)}
            animateYearScrolling
            inputVariant='outlined'
            clearable
            clearLabel='No Preference'
          />
        </Grid>
        <Grid item className={classes.container}>
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            fullWidth
            onClick={() => onAcceptQuote()}
          >
            ACCEPT JOB
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default PpeQuotationForm;
