import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Paper } from '@material-ui/core';

import CertificationSummary from './CertificationSummary';
import ContactSupport from '../../ContactSupport';
import CustomerFeedbackButtons from '../../CustomerFeedbackButtons';
import DisplayShippingOptions from './DisplayShippingOptions';
import LeadTimeFeedbackPopup from '../../popups/LeadTimeFeedbackPopup';
import ManualQuotationRequiredBanner from './ManualQuotationRequiredBanner';
import PaperSummary from './PaperSummary';
import PricingSummaryDisplay from './PricingSummaryDisplay';
import { FlexColumn } from '../../layouts/FlexLayouts';
import { FtrLightText, FtrButton, FtrTypography } from '../../ftr-components';

import { upsertLeadTimeFeedback } from '../../../apis/ppeFeedbackApi';

import withContactSupportPopupHOC from '../../../hocs/withContactSupportPopupHOC';

import estimateCostInfoHook from './estimateCostInfoHook';
import estimateQcReportCostInfoHook from './estimateQcReportCostInfoHook';

import { updateCostEstimation } from '../../../actions/estimateCostActions';

import { addressInfoText } from '../../../utils/addressUtils';
import {
  convertPriceToCurrency,
  getGstFromCost,
} from '../../../utils/currencyUtils';
import { getMinMaxLeadTimeFromItems } from '../../../utils/leadTimeUtils';
import { getShippingTooltipText } from '../../../utils/deliveryUtils';
import { isEmptyValue } from '../../../utils/commonUtils';
import {
  formatDeliveryDate,
  addBusinessDays,
  getCurrentDateStr,
} from '../../../utils/dateTimeUtils';

import { FEEDBACK_STATUS } from '../../../constants/feedbackConstants';
import { COUNTRY_NAMES } from '../../../constants/countryConstants';
import { DELIVERY_OPTIONS_DISPLAY_MAPPING } from '../../../constants/itemConstants';
import { DEFAULT_FACTOREM_GST } from '../../../constants';
import { GTM_ID_MAP } from '../../../constants/GTMConstants';

import { colors } from '../../../palette';

// --------------------------------------------------------------------------------------------------------

function DisplayProjectSummaryRightPanel(props) {
  const dispatch = useDispatch();

  const {
    hasRfqItem,
    selectedItems,
    shippingAddressRef,
    qcReports,
    loading,
    loadingPpePrice,
    setShowQcReportDrawer,
    handleSubmitForm,
    setShowAddressOptionsDropdown,
    updateContactSupportPopupHOCState = () => {},
  } = props;

  const { minLeadTime, maxLeadTime } =
    getMinMaxLeadTimeFromItems(selectedItems);

  const isLoadingTDE = useMemo(() => {
    return selectedItems?.some((item) => item.tdeStatus === 'loading');
  }, [selectedItems]);
  const userID = useSelector((state) => state.auth.user.userID);
  const currency = useSelector((state) => state.auth.location.currency);
  const exchangeRates = useSelector((state) => state.auth.rates);
  const exchangeRate = exchangeRates[currency];

  const {
    shippingMode,
    shippingCostOptions,
    shippingAddressObj,
    shippingCost,
    shippingCostStatus,
    estimatedDeliveryDate,
    subTotal,
    selectShippingOption,
  } = estimateCostInfoHook((state) => ({
    shippingMode: state.shippingMode,
    shippingCostOptions: state.shippingCostOptions,
    shippingAddress: state.shippingAddress,
    shippingAddressObj: state.shippingAddressObj,
    shippingCost: state.shippingCost,
    shippingCostStatus: state.shippingCostStatus,
    estimatedDeliveryDate: state.estimatedDeliveryDate,
    subTotal: state.subTotal,
    selectShippingOption: state.selectShippingOption,
  }));

  const {
    mainQcReportCost,
    addOnsQcReportCost,
    totalQcReportCost,
    qcOptionPrices,
  } = estimateQcReportCostInfoHook((state) => ({
    mainQcReportCost: state.mainQcReportCost,
    addOnsQcReportCost: state.addOnsQcReportCost,
    totalQcReportCost: state.totalQcReportCost,
    qcOptionPrices: state.qcOptionPrices,
    setQcReports: state.setQcReports,
    setQcOptionPrices: state.setQcOptionPrices,
  }));

  const qcReportFee = totalQcReportCost();

  const [estimatedTotal, setEstimatedTotal] = useState(0);
  const [leadTime, setLeadTime] = useState('');
  const [feedback, setFeedback] = useState(null);
  const [showFeedbackCard, setShowFeedbackCard] = useState(null);
  const [avgLeadTime, setAvgLeadTime] = useState(null);
  const [isShowLeadTime, setIsShowLeadTime] = useState(false);

  const shippingTooltip = useMemo(() =>
    getShippingTooltipText(
      {
        country: shippingAddressObj?.country,
        currency,
        exchangeRate,
      },
      [shippingAddressObj?.country, currency, exchangeRate]
    )
  );

  const gst = DEFAULT_FACTOREM_GST;

  const gstValue =
    shippingAddressObj?.country === COUNTRY_NAMES.SINGAPORE
      ? getGstFromCost(
          Number(subTotal) + Number(shippingCost) + Number(qcReportFee),
          Number(gst)
        )
      : 0;

  useEffect(() => {
    // this will be used in leave quote feedback
    if (hasRfqItem) {
      dispatch(
        updateCostEstimation({
          addOnsQcReportCost: addOnsQcReportCost(),
          avgLeadTime: null,
          currency,
          estimatedDeliveryDate: null,
          estimatedTotal: null,
          exchangeRate,
          gstValue: null,
          hasRfqItem,
          leadTime: null,
          mainQcReportCost: mainQcReportCost(),
          qcOptionPrices,
          qcReportFee,
          qcReports,
          shippingAddressObj: null,
          shippingCost: null,
          shippingMode: null,
          subTotal: null,
        })
      );
      return;
    }
    const _estimatedTotal = gstValue + shippingCost + subTotal + qcReportFee;
    setEstimatedTotal(_estimatedTotal);
    dispatch(
      updateCostEstimation({
        addOnsQcReportCost: addOnsQcReportCost(),
        avgLeadTime,
        currency,
        estimatedDeliveryDate,
        estimatedTotal: _estimatedTotal,
        exchangeRate,
        gstValue,
        hasRfqItem,
        leadTime,
        mainQcReportCost: mainQcReportCost(),
        qcOptionPrices,
        qcReportFee,
        qcReports,
        shippingAddressObj,
        shippingCost,
        shippingMode,
        subTotal,
      })
    );
  }, [
    addOnsQcReportCost,
    avgLeadTime,
    currency,
    estimatedDeliveryDate,
    exchangeRate,
    gstValue,
    hasRfqItem,
    leadTime,
    mainQcReportCost,
    qcOptionPrices,
    qcReportFee,
    qcReports,
    shippingAddressObj,
    shippingCost,
    shippingMode,
    subTotal,
    totalQcReportCost,
  ]);

  useEffect(() => {
    if (loadingPpePrice) {
      setLeadTime('');
      setAvgLeadTime(null);
      return;
    }
    if (minLeadTime !== maxLeadTime) {
      setLeadTime(`${minLeadTime} - ${maxLeadTime} days`);
      setAvgLeadTime(`Avg ${Math.floor((minLeadTime + maxLeadTime) / 2)} days`);
    } else if (minLeadTime === maxLeadTime) {
      setLeadTime(`${minLeadTime} days`);
      setAvgLeadTime(null);
    }
  }, [minLeadTime, maxLeadTime, loadingPpePrice]);

  const showFeedback = selectedItems.length > 0 && !loading;

  const handleGoodFeedback = () => {
    setFeedback(FEEDBACK_STATUS.GOOD);
    for (let item of selectedItems) {
      upsertLeadTimeFeedback({
        iqLogsID: item.ppePriceLogId,
        userID: userID,
        leadTimeFeedback: FEEDBACK_STATUS.GOOD,
        givenLeadTime: maxLeadTime,
      });
    }
  };

  const handleBadFeedback = () => {
    setFeedback(FEEDBACK_STATUS.BAD);
    setShowFeedbackCard(true);
    for (let item of selectedItems) {
      upsertLeadTimeFeedback({
        iqLogsID: item.ppePriceLogId,
        userID: userID,
        leadTimeFeedback: FEEDBACK_STATUS.BAD,
        givenLeadTime: maxLeadTime,
      });
    }
  };

  const renderLeadTime = () => {
    if (isEmptyValue(selectedItems) || hasRfqItem) {
      return;
    }
    const earliestDeliveryDate = addBusinessDays(
      getCurrentDateStr(),
      minLeadTime
    );
    const earliestDeliveryDateStr = formatDeliveryDate(earliestDeliveryDate);

    return (
      <div style={{ paddingBottom: '.5rem' }}>
        <FtrTypography
          type='heading'
          fontSize='18'
          style={{ color: colors.neutral080, paddingBottom: '0.5rem' }}
        >
          Lead Time
        </FtrTypography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <FtrTypography
            type='body'
            fontSize='14'
            style={{ color: colors.neutral060 }}
          >
            Estimated
          </FtrTypography>
          <FtrTypography
            type='subHeading'
            fontSize='16'
            style={{ color: colors.neutral070 }}
          >
            {leadTime}
          </FtrTypography>
        </div>
        {!loadingPpePrice && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginTop: '0.2rem',
            }}
          >
            <FtrLightText>{earliestDeliveryDateStr}</FtrLightText>
          </div>
        )}
      </div>
    );
  };

  const renderShippingCostInfo = () => {
    return (
      <DisplayShippingOptions
        title={DELIVERY_OPTIONS_DISPLAY_MAPPING[shippingMode]}
        subInfo={
          estimatedDeliveryDate
            ? `Receive by ${formatDeliveryDate(estimatedDeliveryDate)}`
            : 'No estimation available'
        }
        loading={shippingCostStatus === 'loading' || loadingPpePrice}
        price={convertPriceToCurrency({
          price: shippingCost,
          currency,
          exchangeRate: 1,
        })}
        titleTooltip={shippingTooltip}
        shippingMode={shippingMode}
        shippingCostOptions={shippingCostOptions}
        selectShippingOption={selectShippingOption}
      />
    );
  };

  const renderShippingAddressInfo = (_userID) => {
    return (
      <div ref={shippingAddressRef}>
        <PaperSummary
          title='Shipping Address'
          onEditClick={() => setShowAddressOptionsDropdown(true)}
          subInfo={
            shippingAddressObj
              ? addressInfoText(shippingAddressObj)
              : 'No address added'
          }
          disabled={!_userID}
        />
      </div>
    );
  };

  const renderShippingSummary = () => {
    if (isEmptyValue(selectedItems)) {
      return;
    }
    return (
      <div style={{ paddingBottom: '1.5rem' }}>
        <FtrTypography
          type='heading'
          fontSize='18'
          style={{ color: colors.neutral080, paddingBottom: '0.5rem' }}
        >
          Shipping
        </FtrTypography>
        <FlexColumn>
          {renderShippingCostInfo()}
          {renderShippingAddressInfo(userID)}
        </FlexColumn>
      </div>
    );
  };

  const renderQcReportSummary = () => {
    return (
      <CertificationSummary
        qcReports={qcReports}
        onEditClick={() => setShowQcReportDrawer(true)}
        selectedItems={selectedItems}
        mainQcReportCost={mainQcReportCost()}
        qcOptionPrices={qcOptionPrices}
      />
    );
  };

  const renderPricingSummary = () => {
    if (isEmptyValue(selectedItems)) {
      return;
    }

    return (
      <PricingSummaryDisplay
        loading={
          shippingCostStatus === 'loading' || loadingPpePrice || isLoadingTDE
        }
        shippingCost={shippingCost}
        subTotal={subTotal}
        gst={gst}
        gstValue={gstValue}
        qcReportFee={qcReportFee}
        currency={currency}
        exchangeRate={1} // it's already passed as target currency
        shippingTooltip={shippingTooltip}
        estimatedTotal={estimatedTotal}
      />
    );
  };

  const renderProceedButton = () => {
    return (
      <>
        <FtrButton
          color='blue'
          size='large'
          style={{
            width: '100%',
            height: '3rem',
          }}
          onClick={handleSubmitForm}
          loading={loading}
          disabled={
            loading ||
            loadingPpePrice === true ||
            isEmptyValue(selectedItems) ||
            isLoadingTDE
          }
          id={GTM_ID_MAP.BTN_PROCEED_CREATE_PROJECT}
          data-cy='step-2-proceed-btn'
        >
          {hasRfqItem ? 'Submit For Review' : 'Proceed'}
        </FtrButton>
        {!hasRfqItem && (
          <div
            style={{
              marginTop: '1rem',
            }}
          >
            <FtrLightText>
              Clicking proceed will ensure that Factorem receives your enquiry.
              This <span style={{ fontWeight: 600 }}>does not</span> confirm
              your order.
            </FtrLightText>
          </div>
        )}
        <div
          style={{
            marginTop: '2.5rem',
          }}
        >
          <ContactSupport
            onClick={() => updateContactSupportPopupHOCState({ open: true })}
          />
        </div>
      </>
    );
  };

  return (
    <Paper
      style={{
        borderRadius: '20px',
        backgroundColor: colors.neutral010,
        padding: '2rem',
      }}
    >
      {hasRfqItem && (
        <div
          style={{
            marginBottom: '2rem',
          }}
        >
          <FtrTypography
            type='heading'
            fontSize='18'
            style={{
              color: colors.neutral080,
              paddingBottom: '0.5rem',
              marginBottom: '1rem',
            }}
          >
            Quotation
          </FtrTypography>
          <ManualQuotationRequiredBanner />
        </div>
      )}
      {!hasRfqItem ? (
        <>
          {renderLeadTime()}
          {showFeedback && (
            <CustomerFeedbackButtons
              showFeedbackInput={showFeedbackCard}
              upIsActive={feedback === FEEDBACK_STATUS.GOOD}
              handleUpClick={handleGoodFeedback}
              downIsActive={feedback === FEEDBACK_STATUS.BAD}
              handleDownClick={handleBadFeedback}
              handleFeedbackSubmit={() => setIsShowLeadTime(true)}
              handleFeedbackClose={() => setShowFeedbackCard(false)}
              style={{ position: 'relative', left: '8px' }}
              feedbackType={'Lead time'}
            />
          )}
          {renderQcReportSummary()}
          <Box style={{ paddingBottom: '1.5rem' }} />
          {renderShippingSummary()}
          {renderPricingSummary()}
        </>
      ) : (
        renderQcReportSummary()
      )}
      <Box style={{ paddingBottom: '1.5rem' }} />
      {renderProceedButton()}
      {isShowLeadTime && selectedItems.length > 0 && !loading && (
        <LeadTimeFeedbackPopup
          dialog={isShowLeadTime}
          items={selectedItems}
          handleClose={() => setIsShowLeadTime(false)}
          setFeedback={setFeedback}
          maxLeadTime={maxLeadTime}
          minLeadTime={minLeadTime}
        />
      )}
    </Paper>
  );
}

export default withContactSupportPopupHOC(DisplayProjectSummaryRightPanel);
