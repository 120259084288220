import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

import queryString from 'query-string';

export const sendUserFeedback = async (userID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/feedback`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const getAllUserFeedback = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/feedback`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
};

export const getFeedbackOfUsers = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/feedback`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const { data } = await response.json();
    return data;
  });
};

export const getAllLeaveQuoteFeedback = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/feedback/leave-quote`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data;
  });
};

export const sendLeaveQuoteFeedback = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/feedback/leave-quote`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const getAllUserFeedbackMetrics = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/feedback/metrics?${queryString.stringify(params)}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};
