import React from 'react';
import { IconButton, Link, Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Edit as EditIcon } from '@material-ui/icons';

import FilesUploadButton from '../../components/FilesUploadButton';
import { FtrTypography } from '../../components/ftr-components';
import SupplierCreditNoteDisplay from '../../components/SupplierCreditNoteDisplay';
import FtrWarningIconTooltip from '../../components/ftr-components/FtrWarningIconTooltip';

import { removeFirstSlashIfExists } from '../../utils/fileUtils';
import { getPurchaseOrderID } from '../../utils/quotationUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { isAdminOrHigherRole } from '../../utils/roleUtils';
import { fourDigitUtil } from '../../utils/numberUtils';
import { convertToDigits } from '../../utils/stringUtils';
import { extractPriceFromCurrencyString } from '../../utils/currencyUtils';

import { getUserSelector } from '../../selectors/userSelector';

import {
  createS3KeyForSupplierCreditNoteFile,
  deleteFileFromS3,
  uploadFileToS3,
} from '../../services/s3Service';
import { notifyError } from '../../services/notificationService';

import withCreditNotePopupHoC from '../../hocs/withCreditNotePopupHOC';
import {
  deleteSupplierCreditNote,
  uploadSupplierCreditNote,
} from '../../apis/supplierCreditNoteApi';

const ManageSupplierCreditNote = ({
  supplierID,
  projectID,
  poAcknowledgedVersion,
  projectOrderReadyID,
  refreshFunc,
  classes,
  itemOrderReadyList,
  supplierCreditNotesInfo,
  creditNote = 'S$ 0.00',
  creditNoteSentToHubdoc,
  isOrderDelivered = false,
  updateCreditNotePopupHoCState,
  supplierCurrency,
}) => {
  const { role: userRole } = useSelector(getUserSelector);

  const isUserAdminOrHigher = isAdminOrHigherRole(userRole);

  if (isEmptyValue(itemOrderReadyList)) {
    return null;
  }
  const { combinedPOsForm } = itemOrderReadyList[0]?.info || {};
  const hasUploadedAtLeastOneCreditNote = !isEmptyValue(
    supplierCreditNotesInfo
  );
  const supplierCreditNoteInfoSorted = [...supplierCreditNotesInfo].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  const mostRecentCreditNoteInfo = isEmptyValue(supplierCreditNotesInfo)
    ? {}
    : supplierCreditNoteInfoSorted[0];
  const { createdAt } = mostRecentCreditNoteInfo;
  const isAnyCreditNoteSentToHubdoc =
    hasUploadedAtLeastOneCreditNote && !isEmptyValue(creditNoteSentToHubdoc);
  const isCreditNoteExtractionRunning =
    !isEmptyValue(createdAt) && Date.now() - new Date(createdAt) < 10000;

  // code below needed to handle legacy issue where the most recent credit note is stored in the quotations table and supplierCreditNoteInfo is not populated
  // so that all credit note urls have the same structure as a supplierCreditNoteInfo object
  if (isEmptyValue(supplierCreditNoteInfoSorted)) {
    supplierCreditNoteInfoSorted.push({
      supplierCreditNoteUrl: itemOrderReadyList[0]?.info?.supplierCreditNoteUrl,
      apiResponse: '',
      responseError: '',
      isCreditNoteReconciled: false,
      creditNoteExtractionApiCallID: null,
      createdAt: null,
    });
  }
  const mostRecentSupplierCreditNoteInfo = supplierCreditNoteInfoSorted?.slice(
    0,
    1
  );
  const supplierCreditNoteInfoByUserRole = !isUserAdminOrHigher
    ? mostRecentSupplierCreditNoteInfo
    : supplierCreditNoteInfoSorted;

  const handleUploadSupplierCreditNote = async (file) => {
    const quotationIDList = itemOrderReadyList?.map(
      (item) => item.info.quotationID
    );
    const toastId = toast('Uploading credit note...', {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    const combinedPoNumber =
      fourDigitUtil(supplierID) +
      fourDigitUtil(projectID) +
      convertToDigits(poAcknowledgedVersion, 2);
    const fileExtension = file.name?.split('.').pop();
    try {
      if (fileExtension?.toLowerCase() !== 'pdf') {
        throw new Error('File format must be in PDF');
      }
      const data = await uploadFileToS3(
        file,
        createS3KeyForSupplierCreditNoteFile(
          combinedPoNumber,
          projectOrderReadyID
        )
      );
      const { s3ObjectUrl } = data || {};
      const body = {
        newSupplierCreditNoteUrl: s3ObjectUrl,
        quotationIDList,
      };
      await uploadSupplierCreditNote(body);
      toast.update(toastId, {
        render: `Credit Note uploaded successfully and relabelled to CreditNote_${combinedPoNumber}!`,
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      refreshFunc();
      // Set a timeout to load the supplier credit note info again, as the call to the credit note extraction service
      // is made asynchronously
      setTimeout(() => {
        refreshFunc();
      }, 10000);
    } catch (err) {
      refreshFunc();
      notifyError(err.message);
    }
  };

  const handleDeleteSupplierCreditNote = async (creditNoteToDelete) => {
    if (!isUserAdminOrHigher) {
      notifyError('You do not have admin rights to delete the credit note.');
      return;
    }
    const quotationIDList = itemOrderReadyList?.map(
      (item) => item.info.quotationID
    );
    try {
      const toastId = toast('Deleting...', {
        type: toast.TYPE.INFO,
        autoClose: true,
      });
      const { pathname } = new URL(creditNoteToDelete);
      await deleteFileFromS3(removeFirstSlashIfExists(pathname));
      await deleteSupplierCreditNote({ quotationIDList, creditNoteToDelete });
      toast.update(toastId, {
        render: 'Credit Note deleted successfully!',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      refreshFunc();
    } catch (err) {
      notifyError('Error deleting Credit Note');
    }
  };

  const handleEditCreditNote = async () => {
    if (!isUserAdminOrHigher) {
      return;
    }

    updateCreditNotePopupHoCState({
      open: true,
      onSuccess: refreshFunc,
      projectOrderReadyID,
      creditNote,
      currency: supplierCurrency,
    });
  };

  const renderUploadSupplierCreditNoteButton = () => {
    return (
      !isAnyCreditNoteSentToHubdoc && (
        <div
          style={{
            marginTop: '1rem',
            marginBottom: '1rem',
            width: 'fit-content',
          }}
        >
          <FilesUploadButton
            id='upload-supplier-credit-note'
            buttonText={
              hasUploadedAtLeastOneCreditNote
                ? isUserAdminOrHigher
                  ? 'Add A New Credit Note'
                  : 'Replace Credit Note'
                : 'Upload Credit Note'
            }
            handleUploadFiles={(files) =>
              handleUploadSupplierCreditNote(files[0])
            }
            multiple={false}
            note={'Please upload it in PDF format.'}
          />
        </div>
      )
    );
  };

  const renderSupplierCreditNoteList = () => {
    const isModifiable = !isAnyCreditNoteSentToHubdoc && isUserAdminOrHigher;
    return (
      hasUploadedAtLeastOneCreditNote &&
      supplierCreditNoteInfoByUserRole?.map((supplierCreditNoteInfo) => (
        <SupplierCreditNoteDisplay
          key={supplierCreditNoteInfo.supplierCreditNoteUrl}
          supplierCreditNoteInfo={supplierCreditNoteInfo}
          handleDeleteSupplierCreditNote={handleDeleteSupplierCreditNote}
          showDeleteButton={isModifiable}
          isLoading={isCreditNoteExtractionRunning}
          isCreditNoteSentToHubdoc={
            supplierCreditNoteInfo.supplierCreditNoteInfoID ===
            creditNoteSentToHubdoc
          }
          isAnyCreditNoteSentToHubdoc={isAnyCreditNoteSentToHubdoc}
          allowSendToHubdoc={isModifiable}
          refreshFunc={refreshFunc}
          isOrderDelivered={isOrderDelivered}
        />
      ))
    );
  };

  const renderBasedOnHubdocStatus = () => {
    return isAnyCreditNoteSentToHubdoc ? (
      <FtrTypography>
        Your credit note has been successfully reconciled with our records and
        has been processed for payment. In case you need to make further
        modifications, please contact us.
      </FtrTypography>
    ) : (
      <FtrTypography>
        There may be an issue with your credit note. Please ensure that your
        credit note value matches the Factorem credit note. If the credit note
        value is correct, you will receive confirmation within one working day.
      </FtrTypography>
    );
  };

  const renderBasedOnOrderDeliveredStatus = () => {
    if (isOrderDelivered) {
      return renderBasedOnHubdocStatus();
    }
    return mostRecentCreditNoteInfo.isCreditNoteReconciled ? (
      <FtrTypography>
        Your credit note has been successfully reconciled with our records.
      </FtrTypography>
    ) : (
      <FtrTypography>
        There may be an issue with your credit note. Please ensure that the
        value matches the credit note value.
      </FtrTypography>
    );
  };

  const renderCreditNoteUploadWarningHeading = () => {
    return (
      <FtrTypography>This order is pending successful delivery.</FtrTypography>
    );
  };

  const renderExtractingCreditNote = () => {
    return (
      <FtrTypography>
        We are processing your credit note and reconciling with our records.
        Please wait for a short while.
      </FtrTypography>
    );
  };

  const renderUploadCreditNoteHeading = () => {
    return (
      <FtrTypography>
        Please upload a credit note of {creditNote} for rework costs incurred by
        Factorem to ensure timely payouts.
      </FtrTypography>
    );
  };

  const renderCreditNoteValue = () => {
    const creditNoteValue = extractPriceFromCurrencyString(creditNote);
    return (
      <>
        <Tooltip title='Credit note value' arrow>
          <span> ({creditNote})</span>
        </Tooltip>
        {isAdminOrHigherRole(userRole) && (
          <>
            <IconButton
              aria-label='edit'
              size='small'
              onClick={handleEditCreditNote}
            >
              <EditIcon />
            </IconButton>
            {creditNoteValue === 0 && (
              <FtrWarningIconTooltip
                style={{ verticalAlign: 'middle' }}
                toolTipText='Please set the credit note value. The supplier will only be able to view it if the value is not empty.'
              />
            )}
          </>
        )}
      </>
    );
  };

  const renderSupplierCreditNoteHeading = () => {
    if (hasUploadedAtLeastOneCreditNote) {
      return isCreditNoteExtractionRunning
        ? renderExtractingCreditNote()
        : renderBasedOnOrderDeliveredStatus();
    } else if (isOrderDelivered) {
      return renderUploadCreditNoteHeading();
    } else {
      return renderCreditNoteUploadWarningHeading();
    }
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <FtrTypography className={classes.heading}>
        Credit Note for PO #
        <Link style={{ cursor: 'pointer' }}>
          {getPurchaseOrderID(combinedPOsForm)}
          {renderCreditNoteValue()}
        </Link>
      </FtrTypography>
      {renderSupplierCreditNoteHeading()}
      {renderSupplierCreditNoteList()}
      {renderUploadSupplierCreditNoteButton()}
      {!isOrderDelivered && (
        <FtrTypography style={{ marginTop: '1rem' }}>
          Do note that we will only process your credit note once all items have
          been delivered.
        </FtrTypography>
      )}
    </div>
  );
};

export default withCreditNotePopupHoC(ManageSupplierCreditNote);
