import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { CsvBuilder } from 'filefy';

import { ceil } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import { IconButton, Tooltip } from '@material-ui/core';

import { Edit, Visibility } from '@material-ui/icons';

import { useDataGridFilterHook } from '../hooks/useDataGridFilterHook';

import CustomToolbar, {
  DataGridToolbarLayout,
} from '../components/grid-data/CustomToolbar';
import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';
import GridDataPagination from '../components/grid-data/GridDataPagination';
import SearchBar from '../components/grid-data/buttons/SearchBar';
import { AddNewButton } from '../components/grid-data/buttons/AddNewButton';
import { ExportCsvButton } from '../components/grid-data/buttons/ExportCsvButton';

import ForwardEmailIconBlue from '../assets/icons/forward_to_inbox_blue.svg';
import ForwardEmailIconGrey from '../assets/icons/forward_to_inbox_grey.svg';

import { getAllProjectsBasicInfo } from '../apis/projectApi';

import { generateCurrentCustomDateTimeString } from '../util';
import { getOwnerNameAndEmail } from '../../src/utils/projectUtils';
import { isAdminOrHigherRole, isSuperAdminRole } from '../utils/roleUtils';
import {
  openAdminEditProject,
  openCustomerSingleProjectVac,
} from '../utils/navigationUtils';
import { transformCsvExportDataGrid } from '../utils/csvExportUtils';

import {
  customerUploadFilesCol,
  disabledReasonCol,
  qcReportsCol,
  customerUploadFilesNameCol,
} from '../constants/projectTableConstants';

import { ROLE_TYPES } from '../constants';

import { colors } from '../palette';

// --------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    padding: '1rem',
    marginBottom: '2rem',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

function AllProjectsDataGrid(props) {
  const classes = useStyles();

  const { userRole } = props;

  const [tableData, setTableData] = useState([]);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );
  const [filteredData, { setSearchStr, setColumnsDef, setSourceData }] =
    useDataGridFilterHook({
      search: '',
      source: tableData,
    });

  const getProjects = () => {
    getAllProjectsBasicInfo().then(setTableData);
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(tableData);
  }, [tableData]);

  const handleOnEditProject = (rowData) => {
    openAdminEditProject(rowData.projectID);
  };

  const navigateToSharedQuotationLog = (rowData) => {
    props.history.push({
      pathname: `/shared-quotation-logs/${rowData.projectID}`,
      state: { projectID: rowData.projectID },
    });
  };

  const navigateToViewAsCustomer = (rowData) => {
    openCustomerSingleProjectVac(rowData.projectID);
  };

  const navigateToCreateProjectPage = () => {
    props.history.push({
      pathname: `/make-order`,
    });
  };

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const defaultColumns = [
    {
      headerName: ' ',
      field: 'actions',
      renderCell: ({ row: rowData }) => {
        return (
          <>
            {renderEditButton()}
            {renderShareQuotationButton()}
            {renderViewAsCustomerButton()}
          </>
        );

        function renderViewAsCustomerButton() {
          return (
            (isAdminOrHigherRole(userRole) ||
              userRole === ROLE_TYPES.SALES_CONSULTANT) && (
              <Tooltip title='View as customer'>
                <IconButton
                  aria-label='viewAsCustomer'
                  style={{
                    width: '10px',
                    height: '10px',
                    margin: '0 13px 0 0',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (e.ctrlKey || e.metaKey) {
                      window.open(`/manage-projects/${rowData.projectID}/vac`);
                      return;
                    }
                    navigateToViewAsCustomer(rowData);
                  }}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            )
          );
        }

        function renderShareQuotationButton() {
          if (userRole === ROLE_TYPES.SALES_CONSULTANT) {
            return;
          }

          return (
            <Tooltip title='View shared quotation logs'>
              <IconButton
                aria-label='openSharedQuotationLogs'
                style={{ width: '10px', height: '10px', margin: '0 13px 0 0' }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigateToSharedQuotationLog(rowData);
                }}
              >
                {rowData.hasShared ? (
                  <img
                    style={{ width: '30px', height: '30px' }}
                    src={ForwardEmailIconBlue}
                    alt='quotation-sharing-icon'
                  />
                ) : (
                  <img
                    style={{ width: '30px', height: '30px' }}
                    src={ForwardEmailIconGrey}
                    alt='quotation-sharing-icon'
                  />
                )}
              </IconButton>
            </Tooltip>
          );
        }

        function renderEditButton() {
          return (
            <Tooltip title='Hold Ctrl/Cmd and click to edit in new tab'>
              <IconButton
                aria-label='edit'
                style={{ width: '10px', height: '10px', margin: '0 13px 0 0' }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (e.ctrlKey || e.metaKey) {
                    window.open(`/manage-projects/${rowData.projectID}`);
                    return;
                  }
                  handleOnEditProject(rowData);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          );
        }
      },
      export: false,
      width: 120,
    },
    {
      headerName: 'Project ID',
      field: 'projectID',
      renderCell: ({ row: rowData }) => `${rowData.projectID}`,
      flex: 1,
    },
    {
      headerName: 'Project Name',
      field: 'name',
      flex: 1,
    },
    {
      headerName: 'Customer (ID)',
      field: 'userID',
      flex: 1,
    },
    {
      headerName: 'Owner',
      field: 'owner',
      valueGetter: ({ row: rowData }) => {
        return getOwnerNameAndEmail(rowData);
      },
      renderCell: ({ row: rowData }) => {
        const ownerStr = getOwnerNameAndEmail(rowData);

        return <DataGridWrapTextCell text={ownerStr} />;
      },
      flex: 1,
    },
    {
      headerName: 'Date Posted',
      field: 'dateCreated',
      renderCell: ({ row: rowData }) =>
        rowData.dateCreated ? rowData.dateCreated.substring(0, 10) : '',
      flex: 1,
    },
    {
      headerName: 'Status',
      field: 'status',
      flex: 1,
    },
    {
      ...customerUploadFilesNameCol,
      flex: 1,
    },
    {
      ...qcReportsCol,
      flex: 1,
    },
    {
      ...customerUploadFilesCol,
      flex: 1,
    },
    {
      ...disabledReasonCol,
      flex: 1,
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState(defaultColumns);
  const handleDownloadCsv = () => {
    const fileName = `All Projects ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  useEffect(() => {
    setColumnsDef(columns);
  }, [columns]);

  const getCustomerToolbar = () => {
    const searchBar = (
      <SearchBar
        key='search'
        onSearch={handleSearch}
        searchTerm={tableQueryParams.search}
      />
    );

    const exportButton = (
      <ExportCsvButton
        key='export-csv'
        handleClick={handleDownloadCsv}
        show={isSuperAdminRole(userRole)}
      />
    );

    const buttons = [searchBar, exportButton];

    return (
      <DataGridToolbarLayout>
        <CustomToolbar
          buttons={[
            <AddNewButton
              key='add-new-project'
              handleClick={navigateToCreateProjectPage}
              name='Add a new project'
            />,
          ]}
        />
        <CustomToolbar buttons={buttons} />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.body}>
      <h1>Manage projects</h1>
      <DataGrid
        autoHeight
        rows={filteredData ?? []}
        columns={columns?.map((col) => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.projectID}
        rowHeight={80}
        headerHeight={80}
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        rowsPerPageOptions={[10, 20, 50]}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
        loading={tableQueryParams.loading}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userRole: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(AllProjectsDataGrid);
