import { createBulkQuotations } from '../apis/quotationApi';

import { uploadFileToS3 } from '../services/s3Service';

import { isEmptyValue } from '../utils/commonUtils';
import { getDateStr } from '../utils/dateTimeUtils';
import { splitFilenameByExtension } from '../utils/fileUtils';

import { RFQ_QUOTE_ATTACHMENTS_S3_FOLDER } from '../constants/s3Constants';

// -------------------------------------------------------------------------------------------------

export const INIT_FORM_STATE = 'INIT_FORM_STATE';
export const UPDATE_QUOTE_FIELD_VALUE = 'UPDATE_QUOTE_FIELD_VALUE';
export const SUBMIT_QUOTES = 'SUBMIT_QUOTES';
export const ADD_QUOTE_FIELDS = 'ADD_QUOTE_FIELDS';
export const REMOVE_QUOTATION = 'REMOVE_QUOTATION';
export const FORM_ERROR = 'FORM_ERROR';
export const UPDATE_REMARKS_VALUE = 'UPDATE_REMARKS_VALUE';
export const TOGGLE_IGNORE_ITEM = 'TOGGLE_IGNORE_ITEM';
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const UPDATE_WATCHING_JOB = 'UPDATE_WATCHING_JOB';
export const UPDATE_ALL_WATCHING_JOB = 'UPDATE_ALL_WATCHING_JOB';
export const UPDATE_SHOW_CUSTOM_LEAD_TIME = 'UPDATE_SHOW_CUSTOM_LEAD_TIME';

export const initFormState = (project, currency, exchangeRate) => {
  return {
    type: INIT_FORM_STATE,
    payload: {
      ...project,
      currency,
      exchangeRate,
    },
  };
};

export const updateQuoteFieldValue = (payload) => {
  return {
    type: UPDATE_QUOTE_FIELD_VALUE,
    payload,
  };
};

export const submitQuotes = (payload) => async (dispatch, getState) => {
  const authState = getState().auth;
  const currency = authState.location.currency;
  const exchangeRate = authState.rates[authState.location.currency];
  const projectBulkQuotationsForm = getState().projectBulkQuotationsForm;
  const items = projectBulkQuotationsForm.items?.filter(
    (item) => item.ignoreItem === false && !item.supplierQuoted
  );

  // TODO! refactor this
  for (const item of items) {
    const { quotations } = item;
    for (const quotation of quotations) {
      if (isEmptyValue(quotation.attachments)) {
        continue;
      }

      for (const attachment of quotation.attachments) {
        const [fileName, fileType] = splitFilenameByExtension(attachment.name);
        const s3Key = `${RFQ_QUOTE_ATTACHMENTS_S3_FOLDER}/${fileName}_${Date.now()}.${fileType}`;
        const url = await uploadFileToS3(attachment, s3Key)
          .then((res) => res.Location)
          .catch(() => null);
        attachment.s3Url = url;
      }
    }
  }

  const { dateOfExpiry } = payload;
  const requestBody = {
    ...projectBulkQuotationsForm,
    currency,
    exchangeRate,
    items,
    ...payload,
    dateOfExpiry: getDateStr(dateOfExpiry),
  };

  return createBulkQuotations(requestBody).catch((err) => {
    const { message } = err;
    if (message.startsWith('ER_DUP_ENTRY: Duplicate entry')) {
      throw new Error(
        `Quotation for this supplier and this project is existed.`
      );
    }
    throw err;
  });
};

export const addQuoteFields = (payload) => {
  return {
    type: ADD_QUOTE_FIELDS,
    payload,
  };
};

export const removeQuotation = (payload) => {
  return {
    type: REMOVE_QUOTATION,
    payload,
  };
};

export const formError = (payload) => {
  return {
    type: FORM_ERROR,
    payload,
  };
};

export const updateRemarksValue = (payload) => {
  return {
    type: UPDATE_REMARKS_VALUE,
    payload,
  };
};

export const toggleIgnoreItem = (payload) => {
  return {
    type: TOGGLE_IGNORE_ITEM,
    payload,
  };
};

export const updateCurrency = (currency) => (dispatch, getState) => {
  const authState = getState().auth;
  const exchangeRate = authState.rates[authState.location.currency];
  const action = {
    type: UPDATE_CURRENCY,
    payload: {
      currency,
      exchangeRate,
    },
  };
  dispatch(action);
};

export const updateWatchingJob = (payload) => {
  return {
    type: UPDATE_WATCHING_JOB,
    payload,
  };
};

export const updateAllWatchingJob = (payload) => {
  return {
    type: UPDATE_ALL_WATCHING_JOB,
    payload,
  };
};
export const updateShowCustomLeadTime = (payload) => {
  return {
    type: UPDATE_SHOW_CUSTOM_LEAD_TIME,
    payload,
  };
};
