import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
const cookies = new Cookies();

import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

import SearchInputDropdown from '../../components/dropdowns/SearchInputDropdown';

import { assignItemsToProject } from '../../apis/projectApi';

import { notifyError, notifySuccess } from '../../services/notificationService';

import {
  compareStringsEqualsIgnoreCase,
  includesIgnoreCase,
} from '../../utils/stringUtils';

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(5),
  },
  container: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
}));

export function AssignPartsPage() {
  const classes = useStyles();

  const selectPartRef = useRef(null);
  const selectProjectRef = useRef(null);

  const [parts, setParts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [partId, setPartId] = useState('');
  const [projectId, setProjectId] = useState('');
  const [filterPartText, setFilterPartText] = useState('');
  const [displayedParts, setDisplayedParts] = useState([]);
  const [filterProjectText, setFilterProjectText] = useState('');
  const [displayedProjects, setDisplayedProjects] = useState([]);

  const getItems = () => {
    const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/items`;
    return fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${cookies.get('token')}`,
      },
    })
      .then((resp) => {
        resp.json().then((response) => {
          const partSortByID = _.sortBy(
            response.data,
            (item) => item.itemID
          ).reverse();
          setParts(partSortByID);
          setDisplayedParts(partSortByID);
        });
      })
      .catch((e) => console.log(e));
  };

  const getProjects = () => {
    const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/projectgroup`;
    return fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${cookies.get('token')}`,
      },
    })
      .then((resp) => {
        resp.json().then((response) => {
          const projectSortByID = _.sortBy(
            response.value,
            (project) => project.projectID
          ).reverse();
          setProjects(projectSortByID);
          setDisplayedProjects(projectSortByID);
        });
      })
      .catch((e) => console.log(e));
  };

  const submitAssignPart = (_partId, _projectId) => {
    const assignPartBody = {
      itemIDs: [_partId],
      projectID: _projectId,
    };
    assignItemsToProject(assignPartBody)
      .then(() => {
        notifySuccess('Part has been reassigned!');
      })
      .catch(() => {
        notifyError('Unable to reassign part. Please try again.');
      });
  };
  useEffect(() => {
    const filteredParts = filterPartText
      ? parts?.filter(
          (part) =>
            includesIgnoreCase(part.itemID.toString(), filterPartText) ||
            includesIgnoreCase(part.name, filterPartText)
        )
      : parts;
    setDisplayedParts(filteredParts);
  }, [filterPartText]);

  useEffect(() => {
    const filteredProjects = filterProjectText
      ? projects?.filter(
          (project) =>
            includesIgnoreCase(
              project.projectID.toString(),
              filterProjectText
            ) || includesIgnoreCase(project.name, filterProjectText)
        )
      : projects;
    setDisplayedProjects(filteredProjects);
  }, [filterProjectText]);

  useEffect(() => {
    getProjects();
    getItems();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAssignPart(partId, projectId);
  };

  return (
    <div className={classes.body}>
      Assign part to project:
      <Container component='main' className={classes.container}>
        <FormControl className={classes.formControl}>
          <InputLabel id='select-part-id'>Part ID</InputLabel>
          <Select
            select
            margin='dense'
            id='select-part-id'
            labelId='select-part-id'
            value={partId}
            renderValue={(selectedKey) => {
              if (!selectedKey) {
                return null;
              }
              const selectedOption = displayedParts?.find((option) =>
                compareStringsEqualsIgnoreCase(
                  option.itemID.toString(),
                  selectedKey
                )
              );
              if (selectedOption) {
                return `${selectedOption.name} (Part ID: ${selectedOption.itemID})`;
              }
            }}
          >
            <SearchInputDropdown
              id='select-part'
              key='select-part'
              inputRef={selectPartRef}
              setFilterText={setFilterPartText}
              filterText={filterPartText}
            />
            {displayedParts?.map((option) => (
              <div key={option.itemID}>
                <MenuItem
                  value={option.itemID}
                  onClick={() => setPartId(option.itemID)}
                >
                  {option.name} (Part ID: {option.itemID})
                </MenuItem>
              </div>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id='select-project-id'>Project ID</InputLabel>
          <Select
            select
            margin='dense'
            id='select-project-id'
            labelId='select-project-id'
            value={projectId}
            renderValue={(selectedKey) => {
              if (!selectedKey) {
                return null;
              }
              const selectedOption = displayedProjects?.find((option) =>
                compareStringsEqualsIgnoreCase(
                  option.projectID.toString(),
                  selectedKey
                )
              );
              if (selectedOption) {
                return `${selectedOption.name} (Project ID: ${selectedOption.projectID})`;
              }
            }}
          >
            <SearchInputDropdown
              key='select-project'
              id='select-project'
              inputRef={selectProjectRef}
              setFilterText={setFilterProjectText}
              filterText={filterProjectText}
            />
            {displayedProjects?.map((option) => (
              <div key={option.projectID}>
                <MenuItem
                  value={option.projectID}
                  onClick={() => setProjectId(option.projectID)}
                >
                  {option.name} (Project ID: {option.projectID})
                </MenuItem>
              </div>
            ))}
          </Select>
        </FormControl>
        <div style={{ marginTop: '20px' }}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={(e) => handleSubmit(e)}
          >
            Assign part to project
          </Button>
        </div>
      </Container>
    </div>
  );
}

export default AssignPartsPage;
