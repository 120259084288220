import { convertFormattedAddress } from './userUtils';
import { isEmptyValue } from './commonUtils';

import { COUNTRY_NAMES } from '../constants/countryConstants';

// -------------------------------------------------------------------------------------------------

export const addressTextFormatted = (data) => {
  if (!data) return 'N.A.';
  return `${data?.contactName} | ${data?.contactNumber} | ${data.address}`.replace(
    /\n/g,
    ' '
  );
};

export const checkCountryAddress = (address) => {
  if (!address) {
    return null;
  }
  const _country = convertFormattedAddress(address).country;
  return Object.values(COUNTRY_NAMES)?.find(
    (country) => country.toLowerCase() === _country.toLowerCase()
  );
};

export const getDefaultAddresses = (addresses = []) => {
  let result = {
    defaultShipping: null,
    defaultBilling: null,
  };

  if (isEmptyValue(addresses)) {
    return result;
  }

  addresses?.forEach((address) => {
    if (address.defaultShipping) {
      result.defaultShipping = address;
    }
    if (address.defaultBilling) {
      result.defaultBilling = address;
    }
  });

  if (!result.defaultShipping) {
    result.defaultShipping = addresses[0];
  }

  if (!result.defaultBilling) {
    result.defaultBilling = result.defaultShipping;
  }

  return result;
};

/**
 *
 * @param {object} props
 * @param {string} props.address
 * @param {string} props.unitNo
 * @param {string} props.postalCode
 * @param {string} props.country
 * @example
 * #<unit-no>, <address>, <city>, <country> <postal-code>
 * #01-54, 81 Ayer Rajah Crescent, Singapore 139967
 */
export const addressInfoText = (params) => {
  if (!params) {
    return '';
  }
  let { address, unitNo, postalCode, country } = params;
  let result = [];
  if (unitNo) {
    if (typeof unitNo === 'string' && unitNo[0] === '#') {
      unitNo = unitNo?.slice(1);
    }
    result.push(`#${unitNo}`);
  }
  if (address) {
    result.push(address);
  }
  if (country && postalCode) {
    result.push(`${country} ${postalCode}`);
  } else {
    if (country) {
      result.push(country);
    }
    if (postalCode) {
      result.push(postalCode);
    }
  }
  return result?.join(', ');
};

/**
 * Returns a formatted string representing the full delivery address, including contact name, contact number, and address information.
 *
 * @param {Object} addressObj - An object containing address information.
 * @param {string} addressObj.contactName - The name of the contact.
 * @param {string} addressObj.contactNumber - The contact number.
 * @param {string} addressObj.address - The address.
 * @param {string} addressObj.unitNo - The unit number.
 * @param {string} addressObj.postalCode - The postal code.
 * @param {string} addressObj.country - The country.
 * @return {string} A formatted string representing the full delivery address.
 */
export const getFullDeliveryAddress = (addressObj) => {
  if (!addressObj) {
    return '';
  }

  const address = addressInfoText(addressObj);
  const { contactName, contactNumber, country } = addressObj;
  return {
    contactName,
    contactNumber,
    address,
    country,
  };
};
