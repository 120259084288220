import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';
import { fetchWithErrorHandling } from '../utils/apiUtils';

export const getRepeatOrderDashboardStats = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dashboard/repeat-order`;

  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getDailyDashboardStats = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/dashboard/daily-data`;

  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};
