import React, { useState } from 'react';
import { format } from 'date-fns';
import { ceil } from 'lodash';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import { Link as LinkButton } from '@material-ui/core';

import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';

import withRequestResponseInfoPopupHOC from '../../hocs/withRequestResponseInfoPopupHOC';

import {
  exportCsvDfmTrackingApi,
  searchDfmTrackingApi,
} from '../../apis/dfmApi';

import useDataGridPaginationHook from '../../hooks/useDataGridPaginationHook';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { exportCsvApi } from '../../utils/csvExportUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(2),
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  linkButton: {
    color: colors.blue050,
    texDFMcoration: 'underline',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
    },
  },
  seeMoreButton: {
    textTransform: 'none',
    cursor: 'pointer',
  },
}));

function DFMLogs(props) {
  const classes = useStyles();

  const { updateRequestResponseInfoPopupState = () => {} } = props;

  const role = useSelector(getUserRoleSelector);

  const [isGeneratingCsv, setIsGeneratingCsv] = useState(false);
  const [
    { isFetching, tableQueryParams, tableData },
    { handleSearch, updateTableQueryParams },
  ] = useDataGridPaginationHook(searchDfmTrackingApi);

  const handleDownloadCsv = () => {
    setIsGeneratingCsv(true);
    exportCsvApi(exportCsvDfmTrackingApi(tableQueryParams), 'DFM Logs').then(
      () => {
        setIsGeneratingCsv(false);
      }
    );
  };

  const getCustomerToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              disabled={isGeneratingCsv === true}
              loading={isGeneratingCsv === true}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  const columns = [
    {
      headerName: 'Caller ID',
      field: 'caller',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={`${rowData.callerName} (${rowData.userID})`}
        />
      ),
    },
    {
      headerName: 'Role',
      field: 'callerRole',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.callerRole} />
      ),
    },
    {
      headerName: 'Api Path',
      field: 'apiPath',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={`${rowData.method}: ${rowData.apiPath}`} />
      ),
      width: 150,
    },
    {
      headerName: 'Request',
      field: 'request',
      renderCell: ({ row: rowData }) => {
        let content = JSON.stringify(rowData.request, null, 2) ?? '';
        const rows = content?.split('\n');
        const length = rows.length;
        content = rows?.slice(0, 2)?.join('\n');
        return (
          <div
            style={{
              whiteSpace: 'pre-wrap',
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            {content}
            {length > 1 && (
              <LinkButton
                className={classes.seeMoreButton}
                variant='link'
                onClick={() => {
                  updateRequestResponseInfoPopupState({
                    open: true,
                    request: rowData.request,
                    response: rowData.response,
                    title: `DFM ID ${rowData?.id}`,
                  });
                }}
              >
                see more...
              </LinkButton>
            )}
          </div>
        );
      },
      valueGetter: ({ row: rowData }) => {
        return JSON.stringify(rowData.request) ?? '';
      },
      width: 250,
    },
    {
      headerName: 'Response',
      field: 'response',
      renderCell: ({ row: rowData }) => {
        let content = JSON.stringify(rowData.response, null, 2) ?? '';
        const rows = content?.split('\n');
        const length = rows.length;
        content = rows?.slice(0, 2)?.join('\n');
        return (
          <div
            style={{
              whiteSpace: 'pre-wrap',
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            {content}
            {length > 2 && (
              <LinkButton
                className={classes.seeMoreButton}
                variant='link'
                onClick={() => {
                  updateRequestResponseInfoPopupState({
                    open: true,
                    request: rowData.request,
                    response: rowData.response,
                    title: `DFM ID ${rowData?.id}`,
                  });
                }}
              >
                see more...
              </LinkButton>
            )}
          </div>
        );
      },
      valueGetter: ({ row: rowData }) => {
        return JSON.stringify(rowData.response) ?? '';
      },
      width: 250,
    },
    {
      headerName: 'Error',
      field: 'err',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.err} />
      ),
      width: 200,
    },
    {
      headerName: 'Log ID',
      field: 'id',
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      renderCell: ({ row: rowData }) => {
        return (
          <div>{format(new Date(rowData.createdAt), 'yyyy-MM-dd HH:mm')}</div>
        );
      },
      width: 200,
    },
  ];

  return (
    <div className={classes.body}>
      <DataGrid
        autoHeight
        paginationMode='server'
        rows={tableData}
        columns={columns?.map((col) => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.id}
        rowHeight={105}
        headerHeight={80}
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        pageSize={tableQueryParams.pageSize}
        rowsPerPageOptions={[10, 20, 50, 100]}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        page={tableQueryParams.page}
        onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
        rowCount={tableQueryParams.totalCount}
        loading={isFetching}
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
      />
    </div>
  );
}

export default withRequestResponseInfoPopupHOC(DFMLogs);
