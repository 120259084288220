import React from 'react';
import PropTypes from 'prop-types';
import { FtrTypography } from '../ftr-components';
import { isEmptyValue } from '../../utils/commonUtils';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  advisoryList: { paddingLeft: '1rem' },
  advisoryItem: { fontSize: 14 },
  advisoryItemSmall: { fontSize: 12 },
}));

const RQAdvisoryText = ({ category, messages, size = 'small' }) => {
  const classes = useStyles();

  if (isEmptyValue(messages)) {
    return 'Manual RFQ';
  }

  return (
    <div>
      <FtrTypography type='subHeading' fontSize={size === 'small' ? 12 : 14}>
        {category}
      </FtrTypography>
      <ul className={classes.advisoryList}>
        {messages?.map((msg, index) => (
          <li key={index} className={classes[`${size}Item`]}>
            {msg}
          </li>
        ))}
      </ul>
    </div>
  );
};

RQAdvisoryText.propTypes = {
  category: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.string,
};

RQAdvisoryText.defaultProps = {
  messages: [],
  size: 'small',
};

export default RQAdvisoryText;
