import { useState } from 'react';
import {
  FlexColumn,
  FlexRow,
  FlexRowCenter,
} from '../../components/layouts/FlexLayouts';
import {
  FtrButton,
  FtrTextField,
  FtrTypography,
} from '../../components/ftr-components';
import FilesUploadButton from '../../components/FilesUploadButton';
import { isEmptyValue } from '../../utils/commonUtils';
import { MAX_PDF_FILE_SIZE } from '../../constants/fileConstants';
import { notifyError } from '../../services/notificationService';
import { uploadFileToS3 } from '../../services/s3Service';
import { QC_REPORT_S3_FOLDER } from '../../constants/s3Constants';
import { checkQCReport } from '../../apis/technicalDrawingExtractionApi';
import { colors } from '../../palette';
import { CircularProgress } from '@material-ui/core';

const CheckQCReport = () => {
  const [fileUrl, setFileUrl] = useState('');
  const [checkData, setCheckData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleUploadFile = async (files) => {
    const file = files[0];

    if (file.size > MAX_PDF_FILE_SIZE) {
      notifyError('File size exceeds maximum limit of 100MB');
      return;
    }

    const fileName = file.name;
    const data = await uploadFileToS3(
      file,
      `${QC_REPORT_S3_FOLDER}/${fileName}`,
      fileName
    );

    const s3ObjectUrl = data.Location;

    if (isEmptyValue(s3ObjectUrl)) {
      notifyError('Upload file failed');
      return;
    }

    setFileUrl(s3ObjectUrl);
  };

  const handleCheckQCReport = async () => {
    if (isEmptyValue(fileUrl)) {
      notifyError('Please upload a file first');
      return;
    }
    setLoading(true);

    try {
      const response = await checkQCReport({ fileUrl });
      if (response) {
        setCheckData(response);
      } else {
        notifyError(
          `Failed to check QC report: ${response?.message || 'Unknown error'}`
        );
      }
    } catch (error) {
      notifyError(`Error occurred while checking QC report: ${error.message}`);
    }

    setLoading(false);
  };

  const renderCheckQCReportButton = () => {
    return (
      <FtrButton
        color='blue'
        disabled={isEmptyValue(fileUrl)}
        style={{ minWidth: 150 }}
        onClick={handleCheckQCReport}
      >
        Check QC Report
      </FtrButton>
    );
  };

  const renderFileUrlField = () => {
    return (
      <FlexRowCenter
        style={{
          width: '100%',
          margin: 'auto',
          boxSizing: 'border-box',
        }}
      >
        <FlexRow style={{ flexGrow: 1 }}>
          <FtrTextField
            label='Upload a xlsx, csv, or pdf file'
            value={fileUrl}
            onChange={(e) => setFileUrl(e.target.value)}
            fullWidth
            style={{
              marginBottom: 0,
            }}
          />
        </FlexRow>
        <FilesUploadButton
          buttonText='Upload File'
          handleUploadFiles={handleUploadFile}
          multiple={false}
          disabled={!isEmptyValue(fileUrl)}
          accept='.pdf,.xlsx,.csv'
        />
        {renderCheckQCReportButton()}
      </FlexRowCenter>
    );
  };

  const renderResponseInfo = () => {
    if (loading) {
      return (
        <FlexRowCenter
          style={{
            width: '100%',
            margin: 'auto',
            boxSizing: 'border-box',
          }}
        >
          <CircularProgress />
        </FlexRowCenter>
      );
    } else if (isEmptyValue(checkData)) {
      return;
    }

    return (
      <FlexRow
        style={{
          width: '100%',
          margin: 'auto',
          boxSizing: 'border-box',
        }}
      >
        <FlexColumn>
          <FtrTypography
            type='heading'
            fontSize='40'
            style={{
              color: checkData?.isPass ? colors.green030 : colors.red030,
            }}
          >
            {checkData?.isPass ? 'Pass' : 'Fail'}
          </FtrTypography>

          {checkData?.failures.length > 0 && (
            <div>
              <h4>Failed Dimension Numbers:</h4>
              <ul>
                {checkData?.failures.map((failure, index) => (
                  <li key={index}>{failure}</li>
                ))}
              </ul>
            </div>
          )}

          {checkData?.summary.length > 0 && (
            <div>
              <FtrTypography type='heading'>Summary:</FtrTypography>
              <FtrTypography
                type='body'
                fontSize='16'
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {checkData?.summary}
              </FtrTypography>
            </div>
          )}
        </FlexColumn>
      </FlexRow>
    );
  };

  return (
    <FlexColumn
      style={{
        margin: '2rem 0',
        padding: '0 2rem',
        boxSizing: 'border-box',
        gap: '1rem',
        marginBottom: '1rem',
      }}
    >
      {renderFileUrlField()}
      {renderResponseInfo()}
    </FlexColumn>
  );
};

export default CheckQCReport;
