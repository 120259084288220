import React from 'react';

import ClickablePaper from '../../ClickablePaper';
import { FlexRow } from '../../layouts/FlexLayouts';
import { FtrS2, FtrTypography } from '../../ftr-components';
import FtrInfoIconTooltip from '../../ftr-components/FtrInfoIconTooltip';

import { isEmptyValue } from '../../../utils/commonUtils';

import { colors } from '../../../palette';

// --------------------------------------------------------------------------

function PaperSummary(props) {
  const {
    title = '',
    subInfo = '',
    onEditClick = () => {},
    price = '',
    ref,
    loading = false,
    titleTooltip = '',
    editable = true,
    disabled = false,
  } = props;

  return (
    <ClickablePaper
      ref={ref}
      onEditClick={onEditClick}
      showEditIcon={editable}
      disabled={disabled}
    >
      <FlexRow>
        <FtrTypography
          type='subHeading'
          fontSize='16'
          style={{
            color: colors.neutral070,
            fontWeight: 600,
          }}
        >
          {title}
        </FtrTypography>
        {!isEmptyValue(titleTooltip) && (
          <FtrInfoIconTooltip toolTipText={titleTooltip} />
        )}
      </FlexRow>
      {!isEmptyValue(subInfo) && (
        <FlexRow
          style={{
            alignItems: 'start',
            justifyContent: 'space-between',
            marginTop: '0.5rem',
          }}
        >
          {loading !== true && (
            <FtrS2
              style={{
                color: colors.neutral060,
                fontWeight: 400,
              }}
            >
              {subInfo}
            </FtrS2>
          )}
          {/* {loading === true && <LoadingPriceIcon size={20} />} */}
          {loading !== true && (
            <FtrS2
              style={{
                color: colors.neutral070,
                minWidth: 'fit-content',
                textAlign: 'right',
              }}
            >
              {price}
            </FtrS2>
          )}
        </FlexRow>
      )}
    </ClickablePaper>
  );
}

export default PaperSummary;
