export const itemProxy = {
  get: function (target, prop, receiver) {
    if (prop === 'isRepeatItem') {
      return function () {
        return target.repeatOrder === 1 || target.repeatOrder === true;
      };
    }

    if (prop === 'isSimilarItem') {
      return function () {
        return (
          target.repeatOrder !== 1 &&
          target.repeatOrder !== true &&
          !!target.refPartID
        );
      };
    }

    // Use Reflect.get to ensure correct 'this' binding
    return Reflect.get(target, prop, receiver);
  },
};
