import { useQuery } from 'react-query';

import { getSamplePartsApi } from '../apis/samplePartsApi';

// -------------------------------------------------------------------------------------------------

const useSamplePartsHook = () => {
  const { data, refetch, isLoading, isError, isFetching } = useQuery(
    ['getSamplePartsApi'],
    () => getSamplePartsApi()
  );

  return {
    data,
    refetch,
    isLoading: isLoading || isFetching,
    isError,
  };
};

export default useSamplePartsHook;
