import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { MenuItem, Select, TextField } from '@material-ui/core';

import BootstrapInput from './BootstrapInput';
import BootstrapInputSmall from './BootstrapInputSmall';

import FtrFieldLabel from '../ftr-components/FtrFieldLabel';

import { ppe3dpLayerThicknessOptions } from '../../constants/PPEConstants';

import { renderSkeletonOverlay } from '../util/skeleton';

import { colors } from '../../palette';
import inputsStyles from './inputsStyles';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: 16,
    marginBottom: 7,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    // width: '30%',
    width: 150,
    minWidth: 150,
    position: 'relative',
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    width: '100%',
    position: 'relative',
  },
}));

function ThreeDLayerThicknessField(props) {
  const classes = useStyles();
  const inputStyles = inputsStyles();

  // Defining input styles
  classes.emptyField = inputStyles.emptyField;
  classes.v2 = inputStyles.v2;

  const {
    visible = false,
    onSelect,
    value,
    rowDisplay = false,
    bootstrapStyle = true,
    disabled = false,
    disableLabelOnBootstrapStyle = false,
    v2 = false,
    loading = false,
  } = props;

  if (!visible) {
    return null;
  }

  if (bootstrapStyle) {
    return (
      <div className={rowDisplay ? classes.rowDisplay : classes.container}>
        {loading && renderSkeletonOverlay()}
        {!disableLabelOnBootstrapStyle && (
          <FtrFieldLabel>Layer Thickness</FtrFieldLabel>
        )}
        <Select
          id='3d-layer-thickness'
          className={`${classes.selectField} ${v2 ? classes.v2 : ''} ${
            !value ? classes.emptyField : ''
          }`}
          input={rowDisplay ? <BootstrapInputSmall /> : <BootstrapInput />}
          value={value ?? null}
          onChange={(evt) => onSelect(evt.target.value)}
          disabled={disabled}
          renderValue={(_value) =>
            _value ? `${_value.toFixed(2)} mm` : '3D Layer Thickness'
          }
          displayEmpty
        >
          {ppe3dpLayerThicknessOptions['FDM']?.map((option) => {
            const displayedText = `${option.toFixed(2)} mm`;
            return (
              <MenuItem key={displayedText} value={option}>
                {displayedText}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  }

  return (
    <TextField
      select
      variant='outlined'
      onChange={(evt) => onSelect(evt.target.value)}
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      size='small'
      InputProps={{
        style: {
          borderRadius: '10px',
          fontWeight: 600,
          color: colors.neutral070,
        },
        inputProps: { min: 1 },
      }}
      style={{ width: '100%' }}
      fullWidth
      disabled={disabled}
    >
      {ppe3dpLayerThicknessOptions['FDM']?.map((option) => {
        const displayedText = `${option.toFixed(2)} mm`;
        return (
          <MenuItem key={option} value={option}>
            {displayedText}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

export default ThreeDLayerThicknessField;
