import { Link } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';
import {
  AssignmentReturn as UploadIcon,
  Settings as AttachFileIcon,
  Delete as DeleteIcon,
  FileCopy as FileCopyIcon,
  Visibility as PreviewIcon,
  VisibilityOff as PreviewHiddenIcon,
} from '@material-ui/icons';

import withDownloadFileInfoPopupHOC from '../../hocs/withDownloadFileInfoPopupHOC';

import { notifySuccess, notifyError } from '../../services/notificationService';

import RequestedCard from '../cards/RequestedCard';
import { FlexColumn, FlexRow } from '../layouts/FlexLayouts';
import WarningIcon from '../icons/WarningIcon';
import InfoIcon from '../icons/InfoIcon';

import { convertToDigits } from '../../utils/stringUtils';
import {
  getFileNameFromCadFile,
  isCustomerLatestUpload,
} from '../../utils/itemUtils';
import { getPresignedUrl } from '../../utils/presignedUrlUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import {
  isPdfFile,
  downloadS3File,
  getFileExtension,
  isImageFile,
} from '../../utils/fileUtils';
import { isSuperAdminRole, isAdminRole } from '../../utils/roleUtils';

import { colors } from '../../palette';
import ImageCheckerIcon from '../icons/ImageCheckerIcon';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  previewPdfListItem: {
    cursor: 'pointer',
  },
  previewPdf: {
    backgroundColor: colors.successGreen,
  },
}));

const createStepFileFromUrl = async (url, fileName) => {
  const presignedPdfUrl = await getPresignedUrl(url);
  // Fetch the file data from the URL
  const response = await fetch(presignedPdfUrl);

  // Check if the fetch was successful
  if (!response.ok) {
    throw new Error(`Failed to fetch the file: ${response.statusText}`);
  }

  // Convert the response data to a Blob
  const blob = await response.blob();

  // Create a File object from the Blob
  const file = new File([blob], fileName, { type: blob.type });

  return file;
};

const UploadedListItem = (props) => {
  const {
    index,
    link,
    fileList,
    previewUrl,
    item,
    setPreviewUrl,
    role,
    setEditFiles,
    type,
    handleStepFileUpload = () => {},
    revisions = 1,
    updateDownloadFileInfoPopupHOCState,
    refetch = () => {},
    customerFile = false,
  } = props;

  const classes = useStyles();

  const isCustomerUploadedList = type === 'customer-uploaded';
  const encodedFileName = link?.split('/').pop();
  const fileName = getFileNameFromCadFile(encodedFileName);
  const itemInfo = isCustomerUploadedList
    ? item.customerCadFileUploadInfo
    : item.factoremCadFileUploadInfo;
  const uploadInfo = itemInfo?.find((info) => info.s3ObjectUrl === link);
  const previewedFile = link === previewUrl;
  const autoGeneratedTitleplate = item.autoGeneratedTitleplates?.find(
    (titleplate) => titleplate.generatedPdfUrl === link
  );

  const showWarningReUploadCustomer =
    isCustomerUploadedList && isCustomerLatestUpload(item, link);

  return (
    <ListItem alignItems='flex-start' disableGutters={true}>
      {/* FILE ICON */}
      <ListItemAvatar
        className={isPdfFile(link) && classes.previewPdfListItem}
        onClick={() => {
          // If the file is already being previewed, then clicking the view button again should close the pdf preview
          if (!isEmptyValue(previewUrl) && link === previewUrl) {
            setPreviewUrl('');
            return;
          }
          if (isPdfFile(link)) {
            setPreviewUrl(link);
          }
        }}
      >
        <Avatar className={previewedFile && classes.previewPdf}>
          {isPdfFile(link) ? (
            previewedFile ? (
              <Tooltip title={'Click to hide PDF preview for this file.'}>
                <PreviewIcon />
              </Tooltip>
            ) : (
              <Tooltip title={'Click to preview PDF.'}>
                <PreviewHiddenIcon />
              </Tooltip>
            )
          ) : (
            <AttachFileIcon />
          )}
        </Avatar>
      </ListItemAvatar>
      {/* FILE LINKS LIST */}
      <FlexColumn style={{ gap: 0 }}>
        <FlexColumn style={{ gap: 0 }}>
          <Link
            onClick={(e) => {
              e.stopPropagation();
              downloadS3File(link).then(refetch);
            }}
          >
            {fileName.length < 20
              ? fileName
              : fileName?.slice(0, 20) + '....' + getFileExtension(fileName)}
          </Link>

          {!isEmptyValue(autoGeneratedTitleplate) ? (
            <RequestedCard
              requestedAt={autoGeneratedTitleplate.uploadedAt}
              requestedByName={'Project 100'}
              label={'Uploaded at'}
            />
          ) : (
            !isEmptyValue(uploadInfo) && (
              <RequestedCard
                requestedAt={uploadInfo.uploadedAt}
                requestedByName={uploadInfo.uploadedBy}
                label={'Uploaded at'}
              />
            )
          )}
        </FlexColumn>
        {/* ACTION ICONS */}
        <FlexRow style={{ gap: 0 }}>
          {/* submit step file to factorem uploaded list */}
          {!isPdfFile(link) && isCustomerUploadedList && (
            <IconButton
              edge='start'
              onClick={async () => {
                try {
                  const extension = getFileExtension(link);
                  const newFileName = `${item.itemID}${convertToDigits(
                    revisions,
                    2
                  )}.${extension}`;
                  const existingStepFile = await createStepFileFromUrl(
                    link,
                    newFileName
                  );
                  handleStepFileUpload([existingStepFile]);
                } catch (error) {
                  console.error('Error re-uploading step file:', error);
                  notifyError('Renaming part file failed!');
                }
              }}
            >
              <Tooltip title='Rename and upload the file' arrow>
                <UploadIcon />
              </Tooltip>
            </IconButton>
          )}
          {/* copy file */}
          <IconButton
            edge='start'
            onClick={() => {
              navigator.clipboard.writeText(link);
              notifySuccess('File link is copied!');
            }}
          >
            <Tooltip title='Copy file link' arrow>
              <FileCopyIcon />
            </Tooltip>
          </IconButton>
          {/* delete file */}
          {(isSuperAdminRole(role) || (isAdminRole(role) && !customerFile)) && (
            <IconButton
              edge='start'
              aria-label='delete'
              onClick={() => {
                const cadPartFiles = [...fileList];
                cadPartFiles.splice(index, 1);
                setEditFiles(cadPartFiles);
                notifySuccess('File deleted!');
              }}
            >
              <Tooltip title='Delete file' arrow>
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          )}
          {showWarningReUploadCustomer && (
            <WarningIcon toolTipText='Customer has changed the design!' />
          )}
          <IconButton
            edge={showWarningReUploadCustomer ? 'end' : 'start'}
            onClick={() => {
              updateDownloadFileInfoPopupHOCState({
                open: true,
                itemID: item.itemID,
                downloadFileTracking: item.downloadFileTracking,
              });
            }}
          >
            <InfoIcon toolTipText='Click to open file download info popup!' />
          </IconButton>
          {isImageFile(link) && customerFile && (
            <ImageCheckerIcon imageFile={link} />
          )}
        </FlexRow>
      </FlexColumn>
    </ListItem>
  );
};

const UploadedListItemWithDownloadInfo =
  withDownloadFileInfoPopupHOC(UploadedListItem);

const VerifyItemUploadedFiles = (props) => {
  return (
    <List>
      {props.fileList?.map((link, index) => {
        return (
          <UploadedListItemWithDownloadInfo
            key={index}
            link={link}
            index={index}
            {...props}
          />
        );
      })}
    </List>
  );
};

export default VerifyItemUploadedFiles;
