import { ceil, get } from 'lodash';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Cookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'redux';

import { Button, Dialog, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';
import { Edit as EditIcon, Stars as Project100Icon } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';

import {
  adminEditItem,
  displayEditItemPage,
  receiveFormData,
} from '../../actions';

import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';
import DeliveryDateCell from '../../components/tables/cells/DeliveryDateCell';
import EditItemStatusPopup from '../../components/popups/EditItemStatusPopup';
import FtrCheckboxDropdown from '../../components/ftr-components/FtrCheckboxDropdown';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import ImageWith3DViewer from '../../components/images/ImageWith3DViewer';
import NewHardwarePart from '../../components/NewHardwarePart';
import NotInterestedJobPopup from '../../components/popups/NotInterestedJobPopup';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import StatusButton from '../../archived/StatusButton';
import ViewItemAllowedDeniedSuppliersPopup from '../../components/popups/ViewItemAllowedDeniedSuppliersPopup';
import WatchingJobPopup from '../../components/popups/WatchingJobPopup';
import { AddNewButton } from '../../components/grid-data/buttons/AddNewButton';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import {
  FlexColumn,
  FlexColumnCenter,
  FlexRow,
  FlexRowCenter,
} from '../../components/layouts/FlexLayouts';
import { FtrItalicText, FtrTypography } from '../../components/ftr-components';

import {
  NonPPEIcon,
  PPEIcon,
  RepeatOrderIcon,
  RocketQuoteIconButton,
  SimilarOrderIcon,
} from '../../components/icons/ItemIcon';
import RQAdvisoryText from '../../components/tooltips/RQAdvisoryText';

import withRepeatSimilarOrderDetailsPopupHOC from '../../hocs/withRepeatSimilarOrderDetailsPopupHOC';
import withRocketQuotePopupHOC from '../../hocs/withRocketQuotePopupHOC';
import withConvertPpeToRfqReasonPopupHOC from '../../hocs/withConvertPpeToRfqReasonPopupHOC';

import {
  adminExportItemsAsCsvV2,
  adminGetAllItemsV2,
} from '../../apis/itemApi';

import { isEmptyValue } from '../../utils/commonUtils';
import { convertPriceToCurrency } from '../../utils/currencyUtils';
import {
  dateTzSingapore,
  getDateStr,
  getDateStrWithMonth,
} from '../../utils/dateTimeUtils';
import {
  getCancelledItemInfo,
  getItemOwner,
  getQuoteAcceptanceRate,
  getQuoteAcceptanceRateColor,
  getTdeCoordinateUpdateStatusText,
  isPpeItem,
} from '../../utils/itemUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';
import {
  includesIgnoreCase,
  snakeCaseToTitleCase,
} from '../../utils/stringUtils';
import { exportCsvApi } from '../../utils/csvExportUtils';
import { isNumber } from '../../utils/numberUtils';
import { getRQAdvisoryMessages } from '../../utils/itemUtils';
import { openInSameTab } from '../../utils/navigationUtils';

import { itemProxy } from '../../proxies/itemProxy';

import {
  materialWithColorCol,
  partIDWithNameCol,
  projectIDColumn,
  surfaceFinishingWithColorCol,
  toleranceCol,
} from '../../constants/itemTableConstants';

import { ITEM_PPE_TYPE } from '../../components/popups/RepeatSimilarOrderDetailsPopup';
import { ITEM_STAGE_TYPE, ROLE_TYPES } from '../../constants';
import { CURRENCY_CODE } from '../../constants/currencyConstants';
import {
  FEATURE_FLAG_HUBSPOT,
  FEATURE_FLAG_ROCKET_QUOTE,
} from '../../constants/featureFlagConstants';
import { ITEM_STATUS_MAPPING } from '../../constants/itemStatus';
import { FAILURE_REASON_MAPPING } from '../../constants/automationConstants';

import { colors } from '../../palette';
import DataGridWrapCell from '../../components/tables/cells/DataGridWrapCell';
import { notifyError } from '../../services/notificationService';
import InfoIcon from '../../components/icons/InfoIcon';

// -------------------------------------------------------------------------------------------------

const cookies = new Cookies();

//environment variables
const stripe_url = process.env.REACT_APP_STRIPE_URL;

const useStyles = makeStyles(() => ({
  body: {
    paddingTop: '1rem',
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    marginBottom: '2rem',
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
    },
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: `invert(41%) sepia(90%) saturate(5329%) hue-rotate(203deg) brightness(96%) contrast(86%);`,
  },
  manualRfqIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: '#ffa500',
  },
  ppeIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: '##008000',
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  containerLog: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 'normal',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  activityLog: {
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
  },
  italicCenter: {
    fontStyle: 'italic',
    textAlign: 'center',
  },
}));

const LIST_FILTER_STATUSES = Object.entries(ITEM_STATUS_MAPPING);
const indexQuotesAvailable = LIST_FILTER_STATUSES.findIndex(
  ([_key, value]) => value === ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS
);
const FILTER_PROCESSING = ['processing', 'true'];

LIST_FILTER_STATUSES.splice(indexQuotesAvailable, 0, FILTER_PROCESSING);

const DEFAULT_FILTER_STATUS = LIST_FILTER_STATUSES?.map(([key, value]) => ({
  label: snakeCaseToTitleCase(key),
  value: value,
  checked: ![
    ITEM_STATUS_MAPPING.CANCELLED,
    ITEM_STATUS_MAPPING.DELIVERED,
  ].includes(value),
  type: isNumber(value) ? 'status' : key,
}));

const FILTER_REQUIRED_REWORK = {
  label: 'Required Rework',
  value: true,
  checked: false,
  type: 'requiredRework',
};

DEFAULT_FILTER_STATUS.push(FILTER_REQUIRED_REWORK);

function AllItemsDataGridPagination(props) {
  const classes = useStyles();

  const {
    openEditItem,
    sendFormData,
    role,
    submitEditPart,
    filterByYetToRfq = false,
    csvFileName = 'All Items',
    headerText,
    updateRocketQuotePopupState = () => {},
    updateRepeatSimilarOrderDetailsPopupHOCState = () => {},
    updateConvertPpeToRfqReasonPopupHOCState = () => {},
  } = props;

  const [selectedItem, setSelectedItem] = useState(null);
  const [onEditStatus, setOnEditStatus] = useState(false);
  const [openNewPartDialog, setOpenNewPartDialog] = useState(false);
  const [showWatchingJobPopup, setShowWatchingJobPopup] = useState(false);
  const [showNotInterestedPopup, setShowNotInterestedPopup] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [statusFilter, setStatusFilter] = useState(DEFAULT_FILTER_STATUS);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 50,
      search: '',
      totalCount: 0,
      loading: false,
      filterByYetToRfq,
      processing: true,
    }
  );
  const [itemAllowedSuppliersPopupState, updateItemAllowedSuppliersPopupState] =
    useReducer(
      (prev, next) => {
        return { ...prev, ...next };
      },
      {
        open: false,
        itemID: null,
        locationVisibility: null,
      }
    );

  const [searchAutoFocus, setSearchAutoFocus] = useState(true);

  const getTableData = () => {
    updateTableQueryParams({ loading: true });
    adminGetAllItemsV2(tableQueryParams)
      .then((data) => {
        setTableData(data?.rows || []);
        updateTableQueryParams({ totalCount: data?.totalCount || 0 });
      })
      .finally(() => {
        updateTableQueryParams({ loading: false });
      });
  };

  useEffect(() => {
    updateTableQueryParams({
      filterByYetToRfq,
      statuses: filterByYetToRfq
        ? ''
        : statusFilter
            ?.filter((i) => i.checked && i.type === 'status')
            ?.map((i) => i.value)
            ?.join(','),
    });
    setStatusFilter((prev) =>
      filterByYetToRfq
        ? prev?.map((i) => ({ ...i, checked: false }))
        : DEFAULT_FILTER_STATUS
    );
  }, [filterByYetToRfq]);

  let timer;
  useEffect(() => {
    if (!tableQueryParams.search) {
      getTableData();
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        getTableData();
      }, 300);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [
    tableQueryParams.search,
    tableQueryParams.page,
    tableQueryParams.pageSize,
    tableQueryParams.filterByYetToRfq,
    tableQueryParams.statuses,
    tableQueryParams.requiredRework,
    tableQueryParams.processing,
  ]);

  const updateItemStatus = (itemID, informationToUpdate) => {
    const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/item/edit/${itemID}`;
    fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.get('token')}`,
      },
      body: JSON.stringify(informationToUpdate),
    })
      .then(function (response) {
        response.json().then((res) => {
          if (res.success) {
            toast.success('Your item status has been updated!', {
              position: toast.POSITION.TOP_RIGHT,
            });
            getTableData();
          } else {
            notifyError(
              'Your item status cannot be updated. Please try again.'
            );
          }
        });
      })
      .catch(function () {
        notifyError(
          'Your project name cannot be changed. Please try again later.'
        );
      });
  };

  const disableItemPPEQuotation = (itemID) => {
    const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/item/disablePPEQuotation/${itemID}`;
    fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${cookies.get('token')}`,
      },
    });
  };

  async function getItemDetails(id) {
    const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/item/${id}`;
    let response = await fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${cookies.get('token')}`,
      },
    });
    let data = await response.json().then((item) => item.data[0]);
    return data;
  }

  async function convertPPEtoRFQ(itemID, reasons) {
    let item = await getItemDetails(itemID);
    const itemInformationToUpdate = {
      itemID: itemID,
      editName: item.name || '',
      editReferenceName:
        item.referenceName || `${String(item.itemID).padStart(4, '0')}01`,
      editStatus: item.verifiedDate ? 2 : 1,
      editPaymentStatus: item.paymentStatus,
      editTech: item.technology,
      editDatePosted: item.datePosted,
      editQuantity: item.quantity || 0,
      editDeadline: item.deadline,
      editDescription: item.description,
      editSurfaceFinish: item.surfaceFinish,
      editDeliveryPreference: item.deliveryPreference || 'on_premise',
      editPartApplication: item.partApplication,
      editTolerance: item.tolerance,
      editCadFile: item.cadFile ? item.cadFile?.split(',') : [],
      editOriginalFiles: item.originalFiles
        ? item.originalFiles?.split(',')
        : [],
      editMaterial: item.material,
      editExpectedPrice: item.expectedPrice,
      editUserID: item.userID,
      editPrice: null,
      editPpeMarkdown: item.ppeMarkdown,
      editColor: item.color,
      verifiedDate: item.verifiedDate ? getDateStr(item.verifiedDate) : null,
      editDeliveryDate: item.deliveryDate ? new Date(item.deliveryDate) : null,
      editCollectionDate: item.collectionDate
        ? new Date(item.collectionDate)
        : null,
      editToleranceStandard: item.toleranceStandard,
      ppeToRfqActionInfo: { reasons },
    };
    disableItemPPEQuotation(itemID);
    submitEditPart(itemInformationToUpdate);
    setTimeout(() => {
      getTableData();
    }, 2000); // wait for BE update item finished
  }

  const handleOpenEditStatusPopup = (item) => {
    if (role === ROLE_TYPES.SALES_CONSULTANT) {
      return;
    }
    setSelectedItem(item);
    setOnEditStatus(true);
  };

  const handleCancelEditStatus = () => {
    setSelectedItem(null);
    setOnEditStatus(false);
  };

  const handleEditStatus = (itemID, informationToUpdate) => {
    updateItemStatus(itemID, informationToUpdate);
    setSelectedItem(null);
    setOnEditStatus(false);
  };

  const handleDownloadCsv = async () => {
    setIsLoadingCsv(true);
    await exportCsvApi(
      adminExportItemsAsCsvV2({ ...tableQueryParams }),
      csvFileName
    ).finally(() => {
      setIsLoadingCsv(false);
    });
  };

  const handleConvertPpeToRfq = (rowData) => {
    updateConvertPpeToRfqReasonPopupHOCState({
      open: true,
      onSubmit: (reasons) => convertPPEtoRFQ(rowData.itemID, reasons),
      onSuccess: () => getTableData(),
      itemID: rowData.itemID,
      isSubmitted: Boolean(rowData.ppeToRfqActionOwnerID),
      reasons: rowData.ppeToRfqActionInfo?.reasons,
    });
  };

  function openStripePaymentIntentID(paymentIntentID) {
    const newWindow = window.open(
      `${stripe_url}${paymentIntentID}`,
      '_blank',
      'noopener,noreferrer'
    );
    if (newWindow) newWindow.opener = null;
  }

  const hubspotCardIdCol =
    FEATURE_FLAG_HUBSPOT === 'true'
      ? {
          title: 'Hubspot Card ID',
          headerName: 'Hubspot Card ID',
          field: 'hubspotDealID',
          width: 120,
        }
      : {};

  const columns = [
    {
      headerName: ' ',
      field: 'actions',
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <Tooltip title='Hold Ctrl/Cmd and click to edit in new tab'>
            <IconButton
              aria-label='edit'
              onClick={(e) => {
                if (e.ctrlKey || e.metaKey) {
                  window.open(`/item/edit/${rowData.itemID}`);
                  return;
                }
                openEditItem(rowData.itemID);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        );
      },
      width: 60,
      sortable: false,
    },
    {
      ...projectIDColumn,
      width: 80,
      sortable: false,
    },
    {
      ...partIDWithNameCol({
        setShowWatchingJobPopup,
        setSelectedItem,
        setShowNotInterestedPopup,
        updateItemAllowedSuppliersPopupState,
        role,
      }),
      sortable: false,
    },
    {
      title: 'Customer Part Name',
      headerName: 'Customer Part Name',
      field: 'name',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.name} />
      ),
      width: 150,
      sortable: false,
    },
    {
      title: 'Owner (ID)',
      headerName: 'Owner (ID)',
      field: 'ownerID',
      renderCell: (params) => {
        const rowData = params.row;
        const { ownerID, ownerName, ownerEmail, ownerCompany } =
          getItemOwner(rowData);
        const quoteAcceptanceRate = getQuoteAcceptanceRate(rowData);

        return (
          <FlexColumn
            style={{
              gap: 0,
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              lineHeight: 'normal',
            }}
          >
            <div>
              {ownerName} ({ownerEmail || ownerID})
            </div>
            <FtrTypography type='subHeading' fontSize='12'>
              {ownerCompany}
            </FtrTypography>
            {quoteAcceptanceRate &&
              !includesIgnoreCase(ownerCompany, 'factorem') && (
                <FtrTypography
                  type='subHeading'
                  fontSize='12'
                  style={{
                    marginTop: '4px',
                    color: getQuoteAcceptanceRateColor(quoteAcceptanceRate),
                  }}
                >
                  {quoteAcceptanceRate}% Acceptance Rate
                </FtrTypography>
              )}
          </FlexColumn>
        );
      },
      width: 170,
      sortable: false,
    },
    {
      title: 'Date Uploaded',
      headerName: 'Date Uploaded',
      field: 'datePosted',
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <Tooltip title='Date and time displayed in SGT'>
            <div>
              {rowData.datePosted ? dateTzSingapore(rowData.datePosted) : ''}
            </div>
          </Tooltip>
        );
      },
      width: 150,
      sortable: false,
    },
    {
      title: 'Item Type',
      headerName: 'Item Type',
      field: 'itemType',
      renderCell: (params) => {
        const rowData = params.row;

        const itemProxyObj = new Proxy(rowData, itemProxy);

        const { status } = rowData;
        const listStatusCanRocketQuote = [
          ITEM_STAGE_TYPE.VERIFYING,
          ITEM_STAGE_TYPE.SOURCING,
          ITEM_STAGE_TYPE.QUOTES_AVAILABLE,
        ];
        const canRocketQuote =
          FEATURE_FLAG_ROCKET_QUOTE === 'true' &&
          listStatusCanRocketQuote.includes(status);

        const advisoryText = () => {
          const { category, messages } = getRQAdvisoryMessages(rowData);
          return <RQAdvisoryText category={category} messages={messages} />;
        };

        return (
          <FlexRow style={{ gap: '0.2rem' }}>
            {isPpeItem(rowData) || rowData.rocketQuote === 1 ? (
              <PPEIcon />
            ) : (
              <NonPPEIcon tooltipText={advisoryText()} />
            )}
            {itemProxyObj.isRepeatItem() && (
              <RepeatOrderIcon
                onClick={() => {
                  updateRepeatSimilarOrderDetailsPopupHOCState({
                    open: true,
                    itemID: rowData.itemID,
                    matchedItemID: rowData?.refPartID,
                    refQuoteID: rowData?.refQuoteID,
                    type: ITEM_PPE_TYPE.REPEAT,
                  });
                }}
              />
            )}
            {itemProxyObj.isSimilarItem() && (
              <SimilarOrderIcon
                onClick={() => {
                  updateRepeatSimilarOrderDetailsPopupHOCState({
                    open: true,
                    itemID: rowData.itemID,
                    matchedItemID: rowData?.refPartID,
                    refQuoteID: rowData?.refQuoteID,
                    type: ITEM_PPE_TYPE.SIMILAR,
                  });
                }}
              />
            )}
            {canRocketQuote && (
              <RocketQuoteIconButton
                onClick={() => {
                  updateRocketQuotePopupState({
                    item: rowData,
                    refetchData: getTableData,
                  });
                }}
              />
            )}
          </FlexRow>
        );
      },
      width: 145,
      sortable: false,
    },
    {
      title: 'Status',
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row: rowData }) => {
        const showProject100Icon =
          rowData.repeatOrder === 1 &&
          rowData.project100Items === 1 &&
          rowData.project100TrackerEnabled === 1;

        return (
          <DataGridWrapCell style={{ width: '100%', boxSizing: 'border-box' }}>
            <FlexColumnCenter
              style={{ gap: '0.2rem', boxSizing: 'border-box' }}
            >
              <FlexRowCenter style={{ width: '100%', boxSizing: 'border-box' }}>
                {showProject100Icon && (
                  <Tooltip
                    title={
                      isEmptyValue(rowData.itemAutomationFailuresStatus) ||
                      rowData.itemAutomationFailuresStatus === 1
                        ? 'Project 100 item'
                        : FAILURE_REASON_MAPPING[
                            rowData.itemAutomationFailuresReason
                          ]
                    }
                    arrow
                  >
                    <Project100Icon
                      fontSize='large'
                      style={{
                        color:
                          rowData.itemAutomationFailuresStatus === 0
                            ? colors.red030
                            : colors.green030,
                        backgroundColor: 'white',
                        borderRadius: '100%',
                      }}
                      onClick={() => openInSameTab('/')}
                    />
                  </Tooltip>
                )}
                <StatusButton
                  onClickBtn={() => {
                    handleOpenEditStatusPopup(rowData);
                  }}
                  item={rowData}
                />
              </FlexRowCenter>
              {rowData.rfqTrackingStatus === 'yet-to-rfq' && (
                <FtrTypography
                  fontSize={12}
                  className={classes.italicCenter}
                  style={{ color: colors.red030 }}
                >
                  ⚠️ Yet to RFQ
                </FtrTypography>
              )}
              {rowData.status === ITEM_STATUS_MAPPING.SOURCING &&
                rowData.numOfSourcingQuotations > 0 && (
                  <FtrTypography
                    fontSize={12}
                    className={classes.italicCenter}
                    style={{ color: colors.blue060 }}
                  >
                    Quotes Available: {rowData.numOfSourcingQuotations}
                  </FtrTypography>
                )}
              {rowData.status === ITEM_STAGE_TYPE.CANCELLED &&
                !isEmptyValue(rowData.cancelledDate) && (
                  <FlexColumnCenter className={classes.activityLog}>
                    <span>{dateTzSingapore(rowData.cancelledDate)}</span>
                    <span>{getCancelledItemInfo(rowData)}</span>
                  </FlexColumnCenter>
                )}
            </FlexColumnCenter>
          </DataGridWrapCell>
        );
      },
      width: 180,
      sortable: false,
    },
    {
      title: 'Image',
      headerName: 'Image',
      field: 'image',
      renderCell: (params) => {
        const rowData = params.row;
        const url = rowData.imageFile || rowData.twoDImageUrl;
        return (
          <div key={url} id={rowData.itemID}>
            <ImageWith3DViewer
              key={url}
              itemID={rowData.itemID}
              twoDImageUrl={url}
              cadFile={rowData.cadFile || rowData.originalFiles}
              isGenerateItemImage
              width={80}
              height={80}
              borderRadius={0}
              noBorder={false}
              textRenderStyle={{ fontSize: 10 }}
            />
          </div>
        );
      },
      width: 100,
      sortable: false,
    },
    {
      title: 'Technology',
      headerName: 'Technology',
      field: 'technology',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.technology} />
      ),
      width: 150,
      sortable: false,
    },
    {
      title: 'Qty',
      headerName: 'Qty',
      field: 'quantity',
      width: 60,
      sortable: false,
    },
    {
      title: 'Target Unit Price (S$)',
      headerName: 'Target Unit Price (S$)',
      field: 'expectedPrice',
      renderCell: (params) => {
        const rowData = params.row;
        return rowData.expectedPrice
          ? convertPriceToCurrency(
              {
                price: rowData.expectedPrice,
                currency: CURRENCY_CODE.SGD,
                exchangeRate: 1,
              },
              ''
            )
          : '';
      },
      width: 120,
      sortable: false,
    },
    {
      title: 'Target Delivery Date',
      headerName: 'Target Delivery Date',
      field: 'deadline',
      renderCell: (params) => {
        const rowData = params.row;
        const displayStr = rowData.deadline
          ? rowData.deadline.substring(0, 10)
          : '';
        return <DataGridWrapTextCell text={displayStr} />;
      },
      width: 120,
      sortable: false,
    },
    {
      title: 'Collection Date (from supplier)',
      headerName: 'Collection Date (from partner)',
      field: 'collectionDate',
      renderCell: (params) => {
        const rowData = params?.row;
        const displayDate =
          rowData?.status === ITEM_STATUS_MAPPING.DELIVERED
            ? rowData?.actualCollectionDate
            : rowData?.revisedCollectionDate || rowData?.collectionDate;
        const dateUsed =
          rowData?.status === ITEM_STATUS_MAPPING.DELIVERED
            ? 'Actual Collection Date'
            : rowData?.revisedCollectionDate
              ? 'Revised Collection Date'
              : 'Collection Date';
        return (
          !isEmptyValue(displayDate) && (
            <FlexRow>
              <div>{getDateStrWithMonth(displayDate)}</div>
              <div>{<InfoIcon toolTipText={dateUsed} />}</div>
            </FlexRow>
          )
        );
      },
      width: 120,
      sortable: false,
    },
    {
      title: 'Delivery Date (to customer)',
      headerName: 'Delivery Date (to customer)',
      field: 'deliveryDate',
      renderCell: (params) => {
        const rowData = params.row;
        return <DeliveryDateCell rowData={rowData} forAdmin />;
      },
      width: 150,
      sortable: false,
    },
    {
      title: 'Verified Date',
      headerName: 'Verified Date',
      field: 'verifiedDate',
      renderCell: (params) => {
        const rowData = params.row;
        const displayStr = rowData.verifiedDate
          ? rowData.verifiedDate.substring(0, 10)
          : '';
        return <DataGridWrapTextCell text={displayStr} />;
      },
      width: 100,
      sortable: false,
    },
    {
      title: 'Payment Status',
      headerName: 'Payment Status',
      field: 'paymentStatus',
      renderCell: (params) => {
        const rowData = params.row;
        return rowData.paymentStatus ? (
          <Button
            style={{
              textTransform: 'capitalize',
              background: colors.fontWhite,
              border: `solid 1px ${colors.inputBorderBlue}`,
              borderRadius: '5px',
              fontSize: '9pt',
              whiteSpace: 'nowrap',
              textAlign: 'center',
              width: '100%',
            }}
            variant='outlined'
            onClick={() => {
              if (role === ROLE_TYPES.SALES_CONSULTANT) {
                return;
              }

              handleOpenEditStatusPopup(rowData);
            }}
            size={'small'}
          >
            {rowData.paymentStatus.charAt(0).toUpperCase() +
              rowData.paymentStatus?.slice(1)}
          </Button>
        ) : (
          <div></div>
        );
      },
      width: 180,
      sortable: false,
    },
    {
      title: 'Payment Intent ID',
      headerName: 'Payment Intent ID',
      field: 'paymentIntentID',
      renderCell: (params) => {
        const rowData = params.row;
        return rowData.paymentIntentID ? (
          <Button
            onClick={() => openStripePaymentIntentID(rowData.paymentIntentID)}
          >
            {rowData.paymentIntentID}
          </Button>
        ) : (
          <div>N/A</div>
        );
      },
      width: 200,
      sortable: false,
    },
    {
      title: null,
      headerName: ' ',
      field: 'convertRfq',
      renderCell: (params) => {
        const rowData = params.row;
        // Disabled for item RFQ and some item PPE statuses
        const disabledConvertRfq =
          !isPpeItem(rowData) ||
          // Only enabled if item PPE status is SOURCING or QUOTES_AVAILABLE
          ![
            ITEM_STATUS_MAPPING.SOURCING,
            ITEM_STATUS_MAPPING.QUOTES_AVAILABLE,
          ].includes(rowData.status);
        return (
          <div className={classes.containerLog}>
            <Button
              style={{
                whiteSpace: 'nowrap',
                textAlign: 'center',
                width: '100%',
              }}
              variant='contained'
              color='primary'
              disabled={disabledConvertRfq}
              className={classes.button}
              onClick={() => handleConvertPpeToRfq(rowData)}
            >
              Convert PPE to RFQ
            </Button>
            {rowData.ppeToRfqActionOwnerID &&
              rowData.ppeToRfqActionOwnerName &&
              rowData.ppeToRfqDate && (
                <div className={classes.activityLog}>
                  <span>
                    {dateTzSingapore(rowData.ppeToRfqDate)}{' '}
                    <InfoIcon
                      style={{ cursor: 'pointer' }}
                      toolTipText='View reasons'
                      onClick={() => handleConvertPpeToRfq(rowData)}
                    />
                  </span>
                  <span>
                    {rowData.ppeToRfqActionOwnerName} (
                    {rowData.ppeToRfqActionOwnerID})
                  </span>
                </div>
              )}
          </div>
        );
      },
      width: 185,
      hide: role === ROLE_TYPES.SALES_CONSULTANT,
      sortable: false,
    },
    {
      ...materialWithColorCol,
      sortable: false,
    },
    {
      ...surfaceFinishingWithColorCol,
      sortable: false,
    },
    {
      ...toleranceCol,
      sortable: false,
    },
    {
      title: 'Tolerance Standard',
      headerName: 'Tolerance Standard',
      field: 'toleranceStandard',
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <DataGridWrapTextCell text={rowData.toleranceStandard || 'N.A.'} />
        );
      },
      sortable: false,
    },
    {
      title: 'TDE Coordinate Update Status',
      headerName: 'TDE Coordinate Update Status',
      field: 'tdeCoordinateUpdateStatus',
      renderCell: (params) => {
        const rowData = params.row;
        return getTdeCoordinateUpdateStatusText(rowData);
      },
      width: 120,
      sortable: false,
    },
    {
      ...hubspotCardIdCol,
      sortable: false,
    },
    {
      title: 'Customer (ID)',
      headerName: 'Customer (ID)',
      field: 'customerID',
      renderCell: (params) => {
        const rowData = params.row;
        const displayStr = rowData.buyerName
          ? `${rowData.buyerName} (${rowData.userID})`
          : '';
        return <DataGridWrapTextCell text={displayStr} />;
      },
      width: 150,
      sortable: false,
    },
    {
      title: 'Project 100',
      headerName: 'Project 100',
      field: 'project100Items',
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <DataGridWrapTextCell text={rowData.project100Items ? 'Yes' : 'No'} />
        );
      },
      valueGetter: (params) => (params.row.project100Items ? 'Yes' : 'No'),
      sortable: false,
    },
  ];

  const columnBuffer = useMemo(() => columns.length, [columns]);

  const navigateToCreateProjectPage = () => {
    props.history.push({
      pathname: `/make-order`,
    });
  };

  const handleClose = () => {
    setOpenNewPartDialog(false);
  };

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleChangeFilterStatus = (filterData) => {
    setStatusFilter(filterData);
    const selectedStatuses = filterData
      ?.filter((i) => i.checked && i.type === 'status')
      ?.map((i) => i.value);
    const selectedRequiredRework = filterData?.some(
      (i) => i.checked && i.type === 'requiredRework'
    );
    const selectedProcessing = filterData?.some(
      (i) => i.checked && i.type === 'processing'
    );
    updateTableQueryParams({
      statuses: selectedStatuses?.join(','),
      requiredRework: selectedRequiredRework,
      processing: selectedProcessing,
    });
  };

  const getCustomerToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <FlexRow>
          <CustomToolbar
            buttons={[
              <AddNewButton
                key='add-new-part'
                handleClick={navigateToCreateProjectPage}
                name='Add a new part'
              />,
            ]}
          />
          {headerText && (
            <FtrItalicText fontSize='12'>{headerText}</FtrItalicText>
          )}
        </FlexRow>
        <CustomToolbar
          buttons={[
            <FtrCheckboxDropdown
              key='status'
              id='ftr-checkbox-status-dropdown'
              label='Status'
              items={statusFilter}
              handleChange={handleChangeFilterStatus}
              style={{ borderRadius: '5px' }}
            />,
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
              autoFocus={searchAutoFocus}
              onFocus={() => {
                setSearchAutoFocus(true);
              }}
              onBlur={() => {
                setSearchAutoFocus(false);
              }}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              show={isSuperAdminRole(role)}
              loading={isLoadingCsv}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return [ROLE_TYPES.BUYER, ROLE_TYPES.SUPPLIER].includes(role) ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: props.location },
      }}
    />
  ) : (
    <div className={classes.body}>
      <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
          autoHeight
          paginationMode='server'
          rows={tableData}
          columns={columns}
          getRowId={(row) => row.itemID}
          rowHeight={105}
          headerHeight={80}
          components={{
            Toolbar: getCustomerToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            updateTableQueryParams({ pageSize: newPageSize })
          }
          page={tableQueryParams.page}
          onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
          rowCount={tableQueryParams.totalCount}
          loading={tableQueryParams.loading}
          disableRowSelectionOnClick
          disableSelectionOnClick
          disableColumnMenu
          columnBuffer={columnBuffer}
        />
      </div>
      {openNewPartDialog && (
        <Dialog
          open={openNewPartDialog}
          onClose={handleClose}
          aria-labelledby='form-dialog-title'
        >
          <NewHardwarePart
            onClose={handleClose}
            sendFormData={sendFormData}
            isDialog={true}
          />
        </Dialog>
      )}
      {onEditStatus && (
        <EditItemStatusPopup
          dialog={onEditStatus}
          handleCancel={handleCancelEditStatus}
          item={selectedItem}
          handleEditStatus={handleEditStatus}
        />
      )}
      <WatchingJobPopup
        open={showWatchingJobPopup}
        onClose={() => setShowWatchingJobPopup(false)}
        title={`Watching Part #${selectedItem && selectedItem.itemID}`}
        itemID={selectedItem?.itemID}
        projectID={selectedItem?.projectID}
        data={get(selectedItem, 'watchingJobs', [])}
      />
      <NotInterestedJobPopup
        open={showNotInterestedPopup}
        onClose={() => setShowNotInterestedPopup(false)}
        itemID={selectedItem && selectedItem.itemID}
        notInterestedJobs={get(selectedItem, 'notInterestedJobs', [])}
        onSuccess={() => {
          getTableData();
          setShowNotInterestedPopup(false);
        }}
      />
      {itemAllowedSuppliersPopupState.open && (
        <ViewItemAllowedDeniedSuppliersPopup
          open={itemAllowedSuppliersPopupState.open}
          itemID={itemAllowedSuppliersPopupState.itemID}
          handleClose={() =>
            updateItemAllowedSuppliersPopupState({ open: false })
          }
          locationVisibility={itemAllowedSuppliersPopupState.locationVisibility}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    users: state.users.users,
    role: state.auth.user.role,
    myUserId: state.auth.user.userID,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    submitEditPart: (editedPart) => dispatch(adminEditItem(editedPart)),
    openEditItem: (id) => dispatch(displayEditItemPage(id, props)),
    sendFormData: (data) => dispatch(receiveFormData(data, props)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default compose(
  withRouter,
  withConnect,
  withRocketQuotePopupHOC,
  withRepeatSimilarOrderDetailsPopupHOC,
  withConvertPpeToRfqReasonPopupHOC
)(AllItemsDataGridPagination);
