import React, { useState, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import MultiSelectChipDropdownWithCategoryFuzzySearch from '../components/dropdowns/MultiSelectChipDropdownWithCategoryFuzzySearch';

import { getAllSuppliersGroupByTech } from '../apis/userApi';
import { getOMSBlacklist, updateConfiguration } from '../apis/configurationApi';
import { getUserInfoWithCache } from '../apis/userApi';

import { isEmptyValue } from '../utils/commonUtils';
import { notifyError, notifySuccess } from '../services/notificationService';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '1rem',
  },
}));

function AddBlacklistedSuppliersField(props) {
  const { setTableData } = props;
  const classes = useStyles();

  // Fetch all suppliers
  const { data: allSuppliers } = useQuery(
    'allSuppliers',
    getAllSuppliersGroupByTech
  );

  const [selectedSupplierIds, setSelectedSupplierIds] = useState([]);
  const [enableSendButton, setEnableSendButton] = useState(true);
  const supplierIdsToDateMapping = useRef({});

  useQuery('allBlacklistedSuppliers', getOMSBlacklist, {
    onSuccess: (data) => {
      const mapping = data?.reduce((acc, item) => {
        acc[item.userID.toString()] = item?.dateAdded;
        return acc;
      }, {});
      Object.assign(supplierIdsToDateMapping, mapping);
      setSelectedSupplierIds(Object.keys(mapping));
    },
  });

  // Effect to set supplierList when allSuppliers is fetched

  const memoizedSupplierList = useMemo(() => {
    if (isEmptyValue(allSuppliers)) {
      return [];
    }
    return allSuppliers?.map((category) => {
      return {
        ...category,
        value: category.value?.map((supplier) => {
          const { companyName } = supplier;
          const text = companyName
            ? `${companyName} <${supplier.email}> (${supplier.name})`
            : `${supplier.name} <${supplier.email}>`;
          return {
            key: `${supplier.userID}`,
            text,
          };
        }),
      };
    });
  }, [allSuppliers]);

  // Handle blacklist
  const handleUpdateBlacklist = async () => {
    setEnableSendButton(false);

    try {
      const results = await Promise.all(
        selectedSupplierIds?.map((userId) => getUserInfoWithCache(userId))
      );

      // Handle results if needed
      const filteredResults = results?.filter((result) => result !== undefined);

      let body = filteredResults?.map((result) => ({
        userID: result.userID,
        name: result.name,
        dateAdded: supplierIdsToDateMapping[result.userID] || new Date(),
      }));
      body.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));
      setTableData(body);

      const request = { key: 'OMS_BLACKLIST', value: body };

      await updateConfiguration(request);

      notifySuccess('Blacklisted suppliers have been updated');
    } catch (err) {
      notifyError(err.message || err);
    } finally {
      setEnableSendButton(true);
    }
  };

  return (
    <div className={classes.container}>
      <div style={{ marginTop: '16px' }}>
        <MultiSelectChipDropdownWithCategoryFuzzySearch
          id='multi-select-suppliers'
          label='Select Partners'
          itemList={memoizedSupplierList}
          value={selectedSupplierIds}
          onSelect={setSelectedSupplierIds}
          searchable
        />
      </div>
      <div style={{ marginTop: '20px' }}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleUpdateBlacklist}
          disabled={!enableSendButton}
        >
          Update blacklisted partners
        </Button>
      </div>
    </div>
  );
}

export default AddBlacklistedSuppliersField;
