import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import { colors } from '../../palette';
import SubDesc from '../SubDesc';
import BlueButton from '../buttons/BlueButton';
import WhiteButton from '../buttons/WhiteButton';
import { REJECTED_REASON_ITEMS } from '../../constants/itemRejectedReason';
import CheckAndWarningInfo from '../info/CheckAndWarningInfo';
import { convertPriceToCurrency } from '../../utils/currencyUtils';
import { CURRENCY_CODE } from '../../constants/currencyConstants';
import { getAcceptedQuoteByItemID } from '../../apis/quotationApi';
import {
  getMaterialWithColorText,
  getSurfaceFinishWithCustomizationsText,
} from '../../utils/itemUtils';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
  otherInput: {
    display: 'flex',
    marginLeft: '35px',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginBottom: '10px',
    columnGap: '20px',
  },
  content: {
    display: 'flex',
    padding: '5px 0',
  },
  nameContent: {
    color: colors.projectJob,
    fontWeight: '600',
  },
  containerCheckbox: {
    backgroundColor: colors.lightGray,
    padding: '5px 0 5px 20px',
    borderRadius: '5px',
  },
}));

/**
 *
 * @param {object} props
 * @param {boolean} props.dialog
 * @param {() => void} props.handleClose
 * @param {() => void} props.onOk
 * @param {object} props.rowData
 * @param {object} props.acceptedQuoteItem
 * @param {array} props.dataRejected.ppeQuote
 * @param {array} props.dataRejected.suppliers
 * @param {array} props.dataRejected.quotes
 */
function RejectedReasonPopup(props) {
  const classes = useStyles();

  const { dialog, handleClose, onOk, rowData } = props;

  const [selectedValues, setSelectedValues] = useState(
    rowData.reasonRejected || []
  );
  const [otherValue, setOtherValue] = useState('');
  const [acceptedQuoteItem, setAcceptedQuoteItem] = useState(null);

  useEffect(() => {
    const checkOtherValue = selectedValues?.find((value) =>
      value.includes('Others:')
    );
    if (checkOtherValue) {
      setOtherValue(checkOtherValue.substring(7));
    }
  }, []);

  useEffect(() => {
    if (rowData.itemID) {
      getAcceptedQuoteByItemID(rowData.itemID).then((data) =>
        setAcceptedQuoteItem(data)
      );
    }
  }, [rowData.itemID]);

  const checkStatus = (item) => {
    const mapSelectedValues = selectedValues?.map((value) => {
      if (value.includes('Others:')) {
        return 'Others';
      }
      return value;
    });
    return mapSelectedValues?.find((value) => value === item);
  };

  const handleChangeCheckbox = (event) => {
    let item = event.target.name;
    let otherValueTemp = '';
    const mapSelectedValues = selectedValues?.map((value) => {
      if (value.includes('Others:')) {
        otherValueTemp = value;
        return 'Others';
      }
      return value;
    });
    if (mapSelectedValues.includes(item)) {
      const newSelectedValue = mapSelectedValues
        ?.filter((value) => value !== item)
        ?.map((value) => {
          if (value === 'Others') {
            return otherValueTemp;
          }
          return value;
        });
      setSelectedValues(newSelectedValue);
    } else {
      if (item === 'Others') {
        item = `Others:${otherValue}`;
      }
      setSelectedValues([...selectedValues, item]);
    }
  };

  const handleChangeOtherValue = (inputValue) => {
    setOtherValue(inputValue);
    const checkOtherValue = selectedValues?.find((value) =>
      value.includes('Others:')
    );
    if (checkOtherValue) {
      const filterSelectedValue = selectedValues?.filter(
        (value) => !value.includes('Others:')
      );
      const newSelectedValue = [...filterSelectedValue, `Others:${inputValue}`];
      setSelectedValues(newSelectedValue);
    }
  };

  const handleSubmitButtonClick = () => {
    if (typeof onOk === 'function') {
      const filterEmptyOthersValue = selectedValues?.filter(
        (value) => value !== 'Others:'
      );
      onOk({
        rejectedReason: filterEmptyOthersValue,
        quotationIDs: rowData.quotationID,
      });
    }
  };

  const handle3DP = (metadata) => {
    if (!metadata) return null;
    const threeDTechnology =
      metadata.threeDTechnology && typeof metadata.threeDTechnology === 'string'
        ? metadata.threeDTechnology?.split('(')[1]?.split(')')[0]
        : null;
    const threeDInfill = metadata.threeDInfill
      ? `${metadata.threeDInfill * 100}%`
      : null;
    const threeDLayerThickness = metadata.threeDLayerThickness
      ? metadata.threeDLayerThickness + 'mm'
      : null;
    return [threeDTechnology, threeDInfill, threeDLayerThickness]
      ?.filter((item) => !!item)
      ?.join(', ');
  };

  const handlePriceToCurrency = (priceBidded) => {
    return convertPriceToCurrency(
      {
        price: priceBidded,
        currency: CURRENCY_CODE.SGD,
        exchangeRate: 1,
      },
      ''
    );
  };

  return (
    <Dialog
      maxWidth='xl'
      open={dialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialogks-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <SubDesc content='Rejected Reason' />
      </DialogTitle>
      <div style={{ padding: '10px 0 20px' }}>
        <DialogContent style={{ padding: '0 30px' }}>
          {acceptedQuoteItem && (
            <div style={{ padding: '0 0 5px' }}>
              <h4 style={{ color: colors.successGreen, margin: '5px 0' }}>
                Quote Info
              </h4>
              <div className={classes.container}>
                <div className={classes.content}>
                  <div className={classes.nameContent}>Supplier:&nbsp;</div>
                  <div className={classes.valueContent}>
                    {rowData.supplierName}
                  </div>
                  <CheckAndWarningInfo
                    value={rowData.supplierName}
                    compareValue={acceptedQuoteItem.supplierName}
                    tooltipTextCheck={`The accepted Quote's value is ${acceptedQuoteItem.supplierName}`}
                    tooltipTextWarning={`The accepted Quote's value is ${acceptedQuoteItem.supplierName}`}
                  />
                </div>
                <div className={classes.content}>
                  <div className={classes.nameContent}>Material:&nbsp;</div>
                  <div className={classes.valueContent}>
                    {getMaterialWithColorText(rowData)}
                  </div>
                  <CheckAndWarningInfo
                    value={getMaterialWithColorText(rowData)}
                    compareValue={getMaterialWithColorText(acceptedQuoteItem)}
                    tooltipTextCheck={`The accepted Quote's value is ${getMaterialWithColorText(
                      acceptedQuoteItem
                    )}`}
                    tooltipTextWarning={`The accepted Quote's value is ${getMaterialWithColorText(
                      acceptedQuoteItem
                    )}`}
                  />
                </div>
                <div className={classes.content}>
                  <div className={classes.nameContent}>
                    {`Supplier's Quote:`}&nbsp;
                  </div>
                  <div className={classes.valueContent}>
                    {handlePriceToCurrency(rowData.priceBidded)}
                  </div>
                  <CheckAndWarningInfo
                    value={handlePriceToCurrency(rowData.priceBidded)}
                    compareValue={handlePriceToCurrency(
                      acceptedQuoteItem.priceBidded
                    )}
                    tooltipTextCheck={`The accepted Quote's value is ${handlePriceToCurrency(
                      rowData.priceBidded
                    )}`}
                    tooltipTextWarning={`The accepted Quote's value is ${handlePriceToCurrency(
                      acceptedQuoteItem.priceBidded
                    )}`}
                  />
                </div>
                <div className={classes.content}>
                  <div className={classes.nameContent}>
                    Surface Finish:&nbsp;
                  </div>
                  <div className={classes.valueContent}>
                    {getSurfaceFinishWithCustomizationsText(rowData)}
                  </div>
                  <CheckAndWarningInfo
                    value={getSurfaceFinishWithCustomizationsText(rowData)}
                    compareValue={getSurfaceFinishWithCustomizationsText(
                      acceptedQuoteItem
                    )}
                    tooltipTextCheck={`The accepted Quote's value is ${getSurfaceFinishWithCustomizationsText(
                      acceptedQuoteItem
                    )}`}
                    tooltipTextWarning={`The accepted Quote's value is ${getSurfaceFinishWithCustomizationsText(
                      acceptedQuoteItem
                    )}`}
                  />
                </div>
                <div className={classes.content}>
                  <div className={classes.nameContent}>Lead Time:&nbsp;</div>
                  <div className={classes.valueContent}>
                    {rowData.leadTime} working days
                  </div>
                  <CheckAndWarningInfo
                    value={rowData.leadTime}
                    compareValue={acceptedQuoteItem.leadTime}
                    tooltipTextCheck={`The accepted Quote's value is ${acceptedQuoteItem.leadTime}`}
                    tooltipTextWarning={`The accepted Quote's value is ${acceptedQuoteItem.leadTime}`}
                  />
                </div>
                <div className={classes.content}>
                  <div className={classes.nameContent}>Quantity:&nbsp;</div>
                  <div className={classes.valueContent}>{rowData.quantity}</div>
                  <CheckAndWarningInfo
                    value={rowData.quantity}
                    compareValue={acceptedQuoteItem.quantity}
                    tooltipTextCheck={`The accepted Quote's value is ${acceptedQuoteItem.quantity}`}
                    tooltipTextWarning={`The accepted Quote's value is ${acceptedQuoteItem.quantity}`}
                  />
                </div>
                {handle3DP(rowData.metadata) && (
                  <div className={classes.content}>
                    <div className={classes.nameContent}>3DP type:&nbsp;</div>
                    <div className={classes.valueContent}>
                      {handle3DP(rowData.metadata)}
                    </div>
                    <CheckAndWarningInfo
                      value={handle3DP(rowData.metadata)}
                      compareValue={handle3DP(acceptedQuoteItem.metadata)}
                      tooltipTextCheck={`The accepted Quote's value is ${handle3DP(
                        acceptedQuoteItem.metadata
                      )}`}
                      tooltipTextWarning={`The accepted Quote's value is ${handle3DP(
                        acceptedQuoteItem.metadata
                      )}`}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <FormGroup style={{ paddingBottom: '20px' }}>
            {REJECTED_REASON_ITEMS?.map((item, index) => (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkStatus(item)}
                      onChange={handleChangeCheckbox}
                      name={item}
                    />
                  }
                  label={item}
                />
                {item === 'Others' && checkStatus(item) && (
                  <Input
                    autoFocus
                    className={classes.otherInput}
                    value={otherValue}
                    onChange={({ target }) =>
                      handleChangeOtherValue(target.value)
                    }
                  />
                )}
              </div>
            ))}
          </FormGroup>
          {/* DISAABLE FOR MULTIPLE SELECTION */}
          {/* {dataRejected.quotes.length > 1 && (
            <div className={classes.containerCheckbox}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAllQuote}
                    onChange={(e) => {
                      setIsAllQuote(!isAllQuote);
                    }}
                    name='set-for-all-quotes'
                  />
                }
                label={`If you check this. You will set for all quote projects (${handleQuotes(
                  dataRejected.quotes
                )?.join(', ')})`}
              />
            </div>
          )} */}
          {/* DISAABLE FOR MULTIPLE SELECTION */}
          {/* {dataRejected.suppliers.length > 0 && (
            <div className={classes.containerCheckbox}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAllSuppliers}
                    onChange={(e) => {
                      setisAllSuppliers(!isAllSuppliers);
                    }}
                    name='set-for-all-suppliers'
                  />
                }
                label={`If you check this. You will set for all suppliers (${handleSuppliers(
                  dataRejected.suppliers
                ).supplierNames?.join(', ')})`}
              />
            </div>
          )} */}
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            <WhiteButton
              onBtnClick={handleClose}
              btnContent='Cancel'
              size='small'
            />
            <Box className={classes.space}></Box>
            <BlueButton
              onBtnClick={handleSubmitButtonClick}
              btnContent='Submit'
            />
          </div>
        </DialogContent>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default RejectedReasonPopup;
