import { filter } from './arrayUtils';
import { isEmptyValue } from './commonUtils';
import { extractPriceFromCurrencyString } from './currencyUtils';

/**
 * This function takes a string parameter and returns an object that contains
 * two Dates: from and to. The "from" Date is calculated based on the given
 * parameter. For example, if the parameter is "Last 7 days", then the "from"
 * Date is today's date minus 7 days.
 *
 * @param {string} param - A string that specifies the date range.
 * @return {Object} An object with two properties: from and to. Both are Date
 *   objects.
 */
export const getFromToDateFilter = (param) => {
  const today = new Date();
  const from = new Date();

  if (param === 'Last 7 days') {
    from.setDate(today.getDate() - 7);
  } else if (param === 'Last 14 days') {
    from.setDate(today.getDate() - 14);
  } else if (param === 'This month') {
    from.setDate(1);
  } else if (param === 'Last month') {
    from.setMonth(today.getMonth() - 1);
    from.setDate(1);
  } else if (param === 'Last 3 months') {
    from.setMonth(today.getMonth() - 3);
    from.setDate(1);
  } else if (param === 'Last 6 months') {
    from.setMonth(today.getMonth() - 6);
    from.setDate(1);
  } else if (param === 'This year') {
    from.setMonth(0);
    from.setDate(1);
  } else if (param === 'Last year') {
    from.setFullYear(today.getFullYear() - 1);
    from.setMonth(0);
    from.setDate(1);
  } else {
    return null;
  }

  return {
    from,
    to: today,
  };
};

/**
 * Filters an array to exclude items with a zero value. If the item is an object,
 * the value is extracted using the specified key. If the value is non-zero or an
 * empty value, the item is included in the result.
 *
 * @param {Array} array - The array to filter.
 * @param {string} [keyValue='value'] - The key to extract the value from objects in the array.
 * @returns {Array} - A new array with items that have a non-zero or empty value.
 */
export const filterValueNotEmpty = (array, keyValue = 'value') => {
  return filter(array, (item) => {
    // Extract the value: use keyValue if it exists, otherwise use the item itself
    const value =
      typeof item === 'object' && item && keyValue in item
        ? item[keyValue]
        : item;
    return (
      !isEmptyValue(value) &&
      extractPriceFromCurrencyString(String(value)) !== 0
    );
  });
};
