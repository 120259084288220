import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons';
import { Container } from '@material-ui/core';

import { pdfFlatten } from '../../apis/pdfApi';
import { notifySuccess } from '../../services/notificationService';
import { Link } from 'react-router-dom';
import { downloadS3File } from '../../utils/fileUtils';

import { FLATTENED_PDF_DIR_NAME } from '../../constants';

export default function FlattenPDFs() {
  const [listLinkPdf, setListLinkPdf] = useState([]);

  function handleFilesChange(files) {
    let stringOfLinks = [];
    const toastId = toast('File(s) is uploading', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    let isPdf = false;
    Promise.all(
      Array.from(files)?.map(async (file) => {
        if (file.type === 'application/pdf') {
          isPdf = true;
          const formData = new FormData();
          formData.append('filename', `${FLATTENED_PDF_DIR_NAME}/${file.name}`);
          formData.append('file', file);
          return pdfFlatten(formData)
            .then(({ data }) => {
              stringOfLinks.push(data?.split(' ')?.join('%20'));
            })
            .catch((err) => {
              alert(err);
            });
        }
      })
    ).then(() => {
      setListLinkPdf((prev) => [...prev, ...stringOfLinks]);
      if (isPdf) {
        notifySuccess('PDF(s) flattened successfully!');
        toast.update(toastId, {
          render: 'File(s) is uploaded successfully',
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
      }
    });
  }

  return (
    <Container>
      <div
        style={{
          paddingTop: '2rem',
        }}
      >
        <input
          id='flattenPDFs'
          type='file'
          multiple
          accept='.pdf'
          onClick={(event) => (event.target.value = null)}
          onChange={(evt) => handleFilesChange(evt.target.files)}
          style={{
            display: 'none',
          }}
        />
        <label htmlFor='flattenPDFs'>
          <div
            style={{
              outline: 'dashed grey 2px',
              height: '6.25rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <CloudUploadIcon style={{ margin: '5px' }} />
            Upload Pdf(s)
          </div>
        </label>
      </div>
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1.5rem',
        }}
      >
        Download Link(s):
      </span>
      {listLinkPdf.length > 0 && (
        <ul>
          {listLinkPdf?.map((link) => (
            <li key={link}>
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  downloadS3File(link);
                }}
              >
                {link}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </Container>
  );
}
