export const AUTOMATION_FLOW = {
  RFQ: 'rfq',
  SUPPLIER_TRACKER: 'supplier_tracker',
  QUOTE_VERIFIER: 'quote_verifier',
  PO_ACKNOWLEDGEMENT: 'po_acknowledgement',
};

export const FAILURE_REASON = {
  GROUP_NOT_FOUND: 'group-not-found',
  SUPPLIER_NOT_FOUND: 'supplier-not-found',
  INVALID_ITEM: 'invalid-item',
  OMS_DOWN: 'oms-down',
  EMAIL_SENDING_FAILED: 'rfq-email-sending-failed',
  EMAIL_NOT_RECEIVED: 'rfq-email-not-received',
  RFQ_REMINDER_IGNORED: 'rfq-email-reminder-ignored',
  RFQ_ITEM_REJECTED: 'rfq-item-rejected',
  RFQ_PARTIAL_ACCEPTANCE: 'rfq-partial-acceptance',
  RFQ_UNVERIFIED_SUPPLIER_ACCEPTANCE: 'rfq-unverified-supplier-acceptance',
  RFQ_PRICE_FEEDBACK: 'rfq-price-feedback',
  RFQ_LEADTIME_FEEDBACK: 'rfq-leadtime-feedback',
  QUOTATION_REMARKS_DETECTED: 'quotation-remarks-detected',
  QUOTATION_FEEDBACK_DETECTED: 'quotation-feedback-detected',
  QUOTATION_CUSTOMER_PO_NOT_RECONCILED: 'quotation-customer-po-not-reconciled',
};

export const FAILURE_REASON_MAPPING = {
  [FAILURE_REASON.GROUP_NOT_FOUND]:
    "Suppliers' repeat group is not found for these items",
  [FAILURE_REASON.SUPPLIER_NOT_FOUND]:
    "We can't find any supplier that matches our OMS criteria for these items",
  [FAILURE_REASON.INVALID_ITEM]:
    "Item can't be processed as it is either unpaid or unverified",
  [FAILURE_REASON.OMS_DOWN]: 'OMS was down during the automation process',
  [FAILURE_REASON.EMAIL_SENDING_FAILED]:
    'Failed to send the automated RFQ email',
  [FAILURE_REASON.EMAIL_NOT_RECEIVED]:
    'Supplier failed to receive the RFQ email',
  [FAILURE_REASON.RFQ_REMINDER_IGNORED]:
    'Supplier failed to respond to the quote within 24 hours',
  [FAILURE_REASON.RFQ_ITEM_REJECTED]: 'Supplier rejected the RFQ',
  [FAILURE_REASON.RFQ_PARTIAL_ACCEPTANCE]: 'Supplier omitted some RFQ-ed items',
  [FAILURE_REASON.RFQ_UNVERIFIED_SUPPLIER_ACCEPTANCE]:
    'RFQ was accepted by the wrong supplier',
  [FAILURE_REASON.RFQ_PRICE_FEEDBACK]: 'Supplier has feedback on the RFQ price',
  [FAILURE_REASON.RFQ_LEADTIME_FEEDBACK]:
    'Supplier submitted feedback on the RFQ leadtime',
  [FAILURE_REASON.QUOTATION_REMARKS_DETECTED]:
    'Customer submitted remarks for this quotation',
  [FAILURE_REASON.QUOTATION_FEEDBACK_DETECTED]:
    'Customer submitted feedback for this quotation',
  [FAILURE_REASON.PO_EMAIL_NOT_RECEIVED]:
    'Failed to send the automated PO email',
  [FAILURE_REASON.PO_REMINDER_IGNORED]:
    'Supplier failed to acknowledge the PO within 4 hours',
  [FAILURE_REASON.QUOTATION_CUSTOMER_PO_NOT_RECONCILED]:
    'Customer PO have not been reconciled for the RFQ-ed items',
};

export const AUTOMATION_FAILURE_POPUP = {
  [AUTOMATION_FLOW.RFQ]: {
    heading: 'RFQ',
    action: 'Please RFQ these items manually!',
  },
  [AUTOMATION_FLOW.SUPPLIER_TRACKER]: {
    heading: "Suppliers' Acceptance",
    action: 'Please follow up with the suppliers!',
  },
  [AUTOMATION_FLOW.QUOTE_VERIFIER]: {
    heading: 'Quotes Verifier',
    action: 'Please verify the quotes manually!',
  },
  [AUTOMATION_FLOW.PO_ACKNOWLEDGEMENT]: {
    heading: 'PO Acknowledgement',
    action: 'Please follow up with the suppliers!',
  },
};

export const AUTOMATION_CONFIGURATIONS_KEY = {
  RFQ: 'FEATURE_FLAG_AUTOMATION_RFQ',
  SUPPLIER_TRACKER: 'FEATURE_FLAG_AUTOMATION_SUPPLIER_TRACKER',
  QUOTE_VERIFIER: 'FEATURE_FLAG_AUTOMATION_QUOTE_VERIFIER',
};
