// Import settings
import React, {
  useMemo,
  useState,
  Fragment,
  useEffect,
  useReducer,
} from 'react';
import { nanoid } from 'nanoid';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

// Import material UI components
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { orange, green, red } from '@material-ui/core/colors';
import {
  AttachFile as AttachFileIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { makeStyles, withStyles } from '@material-ui/core/styles/index';

// Import color palette
import { colors } from '../palette';

import InfoIcon from '../assets/icons/info_icon.svg';

// Import customized components
import LoadingBackDropText from '../components/LoadingBackDropText';
import MaterialCategoriesInputField from '../components/inputs/MaterialCategoriesInputField';
import ThreeDPrintingTechnologyInputField from '../components/inputs/ThreeDPrintingTechnologyInputField';
import ThreeDPrintingMaterialField from '../components/inputs/ThreeDPrintingMaterialField';
import ThreeDInfillSelectField from '../components/inputs/ThreeDInfillSelectField';
import ThreeDLayerThicknessField from '../components/inputs/ThreeDLayerThicknessField';
import ColorFtrDropdown from '../components/ftr-components/ColorFtrDropdown';
import { SwitchUnit } from '../components/dropdowns/ToleranceDropdown';
import { FlexColumn, FlexRow } from '../components/layouts/FlexLayouts';
import { FtrDropdown, FtrTypography } from '../components/ftr-components';
import FtrFieldLabel from '../components/ftr-components/FtrFieldLabel';
import { FtrDropdownV2 } from '../components/ftr-components';
import PartUploadDragAndDropV2 from '../components/PartUploadDragAndDrop/PartUploadDragAndDropV2';

import { getConfigByKey, updateConfiguration } from '../apis/configurationApi';
import { getPpePriceForItem, ppeFeedback } from '../apis/ppeApi';
import { getAllSuppliersApi } from '../apis/userApi';

// Import constants
import {
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
  THREE_D_P_FDM_TECH,
  STANDARD_TOLERANCE_OPTIONS,
} from '../constants/NewPartConstants';
import {
  ppe3dpInfillOptions,
  techMapping,
  surfaceFinishMapping,
  ppeParameters,
} from '../constants/PPEConstants';
import { CADPART_DIRNAME } from '../constants';
import { KEY_CONFIGURATION } from '../constants/configurations';
import { DISPLAY_UNIT_OPTIONS, UNIT_TYPES } from '../constants/';
import {
  ANODIZING_TYPE_OPTIONS,
  ANODIZING_TYPE,
} from '../constants/itemConstants';

import { useItemInputConfig } from '../hooks/useItemInputConfig';
import { usePrevious } from '../hooks/usePrevious';

import { getPpeMaterialCode } from '../utils/ppeUtils';
import { hasReviewPermission, isAdminOrHigherRole } from '../utils/roleUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { getDefaultTolerance } from '../utils/toleranceUtils';
import { convertPriceWithQuantityToCurrency } from '../utils/currencyUtils';
import {
  isAnodizingSurfaceFinish,
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology,
} from '../utils/inputUtils';
import {
  convertImperialToMetric,
  convertMetricToImperial,
  showUnitValueFromMetric,
} from '../utils/userUtils';
import { toFixedWithDefault } from '../utils/numberUtils';
import {
  getFileNameFromCadFile,
  getItemMaterial,
  getItemSurfaceFinish,
  getTechnologyDisplayText,
  is3DPTechnology,
} from '../utils/itemUtils';
import { capitalizeString } from '../utils/stringUtils';

import { uploadFileToS3 } from '../services/s3Service';
import { notifyError, notifySuccess } from '../services/notificationService';
import { convert2DImageWithPlaceholder } from '../services/convert2DImageService';

import { getUserCurrencySelector } from '../selectors/userSelector';
import { getExchangeRateSelector } from '../selectors/exchangeRatesSelector';
// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.fontWhite,
  },
  circularProgress: {
    position: 'absolute',
  },
  circularProgressText: {
    position: 'absolute',
    top: '50%',
  },
  body: {
    padding: theme.spacing(8),
    paddingTop: theme.spacing(4),
  },
  paper: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(6),
  },
  accept: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
    width: '100%',
  },
  tooLow: {
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[700],
    },
    width: '100%',
  },
  tooHigh: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
    width: '100%',
  },
  accepted: {
    backgroundColor: green[500],
    width: '100%',
    margin: '5px',
  },
}));

const FieldLabel = withStyles({
  root: {
    padding: '0.3rem 0',
    color: colors.neutral070,
  },
})((props) => <FtrTypography type='heading' fontSize='16' {...props} />);

const parametersOptionsDefaultState = ppeParameters?.reduce((acc, option) => {
  return { ...acc, [option]: '0' };
}, {});

function PricePredictorV2() {
  const classes = useStyles();

  const user = useSelector((state) => state?.auth?.user);
  const currency = useSelector(getUserCurrencySelector);
  const exchangeRate = useSelector(getExchangeRateSelector);

  const { userID, role, unitType: myUnitType } = user || {};

  const { data: suppliers } = useQuery('getAllSuppliersApi', () => {
    if (!hasReviewPermission(role)) {
      return [];
    }
    return getAllSuppliersApi();
  });

  const defaultFormState = {
    name: '',
    technology: '',
    otherTechnology: '',
    material: '',
    surfaceFinish: '',
    otherSurfaceFinish: '',
    anodizingType: ANODIZING_TYPE.GLOSSY,
    threeDTechnology: '',
    threeDInfill: '',
    threeDLayerThickness: '',
    tolerance: '',
    remarks: '',
    qty: 1,
    price: null,
    totalPrice: null,
    partApplication: '',
    materialColor: '',
    customMaterialColor: '',
    color: '',
    customColor: '',
    targetPrice: '',
    originalTargetPrice: '',
    cadFile: '',
    expectedLeadTime: null,
    unitType: myUnitType,
  };

  const [formState, updateFormState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      ...defaultFormState,
    }
  );

  const [
    {
      technologyOptions,
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      loadingStatus,
      isConfigurationLoading,
    },
    {
      loadSelectColorSurfaces,
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      technologyHasChanged,
      materialHasChanged,
      threeDTechnologyHasChanged,
      surfaceFinishHasChanged,
      setThreeDMaterialOptions,
      setMaterialColorOptions,
      setSurfaceFinishColorOptions,
    },
  ] = useItemInputConfig({
    setTechnology: (technology) => {
      const tolerance = getDefaultTolerance({
        technology: technology,
        unitType: formState.unitType,
        threeDTechnology: formState.threeDTechnology,
      });
      updateFormState({ technology, tolerance });
    },
    setMaterial: (material) => {
      updateFormState({ material });
    },
    setThreeDTechnology: (threeDTechnology) => {
      updateFormState({ threeDTechnology });
    },
    setSurfaceFinish: (surfaceFinish) => {
      updateFormState({ surfaceFinish });
    },
    setMaterialColor: (materialColor) => {
      updateFormState({ materialColor });
    },
    setColor: (color) => {
      updateFormState({ color });
    },
  });

  const [fileSize, setFileSize] = useState(0);
  const [predictedPriceObj, setPredictedPriceObj] = useState();
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [missingInputsDialog, setMissingInputsDialog] = useState(false);
  const [feedback, setFeedback] = useState();
  const [supplierResp, setSupplierResp] = useState();
  const [expectedPrice, setExpectedPrice] = useState();
  const [feedbackErrorDialog, setFeedbackErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [supplierID, setSupplierID] = useState(userID);
  // customerMarkup is not here because it is only used in the customer portal
  const [supplierPrice, setSupplierPrice] = useState(null);
  const [customerPrice, setCustomerPrice] = useState(null);
  const [supplierMarkup, setSupplierMarkup] = useState(null);
  const [formattedPrice, setFormattedPrice] = useState('');
  const [formattedSupplierPrice, setFormattedSupplierPrice] = useState('');
  const [formattedCustomerPrice, setFormattedCustomerPrice] = useState('');
  const [parametersState, setParametersState] = useState(
    parametersOptionsDefaultState
  );
  const dimensions = useMemo(
    () => predictedPriceObj?.dimensions || {},
    [predictedPriceObj]
  );

  const techPrev = usePrevious(formState.technology);

  useQuery(
    ['getConfigByKey', KEY_CONFIGURATION.ADMIN_SUPPLIER_PPE_PARAMETERS],
    () => {
      if (!isAdminOrHigherRole(role)) {
        return;
      }
      return getConfigByKey(
        KEY_CONFIGURATION.ADMIN_SUPPLIER_PPE_PARAMETERS
      ).then((response) => {
        setParametersState(response?.value);
      });
    }
  );

  useEffect(() => {
    if (isEmptyValue(formState.technology)) {
      loadTechnologyOptions(true);
      return;
    }
    if (isEmptyValue(techPrev) && !isEmptyValue(formState.technology)) {
      technologyHasChanged(formState.technology);
    }
    loadMaterialCategoryOptions({ technology: formState.technology }, false);
    loadSelectColorSurfaces({ technology: formState.technology }, false);
    if (is3DPTechnology(formState.technology)) {
      load3DTechnologyOptions(false);
      loadThreeDMaterialOptions(
        {
          technology: formState.technology,
          threeDTechnology: formState.threeDTechnology,
        },
        false
      ).catch(() => {
        setThreeDMaterialOptions(['Custom Material']);
      });
    }

    const params = {
      technology: formState.technology,
      threeDTechnology: formState.threeDTechnology,
      material: formState.material,
    };
    loadSurfaceFinishOptions(params, true);
    if (formState.materialColor) {
      loadMaterialColorOptions(params, false);
    }
    if (formState.color) {
      loadSurfaceFinishColorOptions(
        {
          technology: formState.technology,
          surfaceFinish: formState.surfaceFinish,
        },
        false
      );
    }
  }, [formState.technology, formState.material]);

  useEffect(() => {
    if (isEmptyValue(formState.materialColor)) {
      setMaterialColorOptions(null);
    }
  }, [formState.materialColor]);

  useEffect(() => {
    if (isEmptyValue(formState.color)) {
      setSurfaceFinishColorOptions([]);
    }
  }, [formState.color]);

  const handleCadFileChange = async (files) => {
    const file = files[0];
    const splitFilename = file.name?.split('.');
    const preFilename = splitFilename?.slice(0, -1)?.join('.');
    const fileExtension = splitFilename[splitFilename.length - 1];
    const s3ObjectKey = `${CADPART_DIRNAME}/${preFilename}_${file.lastModified}.${fileExtension}`;

    const dataFileToS3 = await uploadFileToS3(file, s3ObjectKey).catch(
      (err) => {
        notifyError(err.message || 'Error uploading file');
      }
    );
    setFileSize(parseInt(file.size / 1000, 10));
    const { s3ObjectUrl } = dataFileToS3 || {};
    updateFormState({ cadFile: s3ObjectUrl });

    const twoDImageUrl = await convert2DImageWithPlaceholder({
      file_url: s3ObjectUrl,
    }).catch((err) => {
      notifyError(err.message || 'Error converting file');
    });
    updateFormState({ twoDImageUrl });
  };

  const handleClose = () => {
    if (role !== 'supplier') {
      setSuccessDialog(false);
    }
  };

  const getUserInputs = () => {
    const technology = getTechnologyDisplayText(formState);
    const materialCode = getPpeMaterialCode({
      technology,
      material: getItemMaterial(formState),
    });
    const material = getItemMaterial(formState);
    const surfaceFinish = getItemSurfaceFinish(formState);
    let parameters = {
      file_location: formState.cadFile,
      material: materialCode || material,
      finish: surfaceFinishMapping[surfaceFinish] || surfaceFinish,
      tolerance: formState.tolerance,
      quantity: Number(formState.qty),
      fileSize: fileSize,
      customer_image_file_location: formState.twoDImageUrl,
      anodizingType: formState.anodizingType,
    };
    if (techMapping[formState.technology] === '3DP') {
      parameters = {
        ...parameters,
        threeDTechnology: formState.threeDTechnology,
        threeDInfill: formState.threeDInfill,
        threeDLayerThickness: formState.threeDLayerThickness,
      };
    }

    return {
      process: techMapping[technology] || technology,
      parameters,
    };
  };

  const requestPricePrediction = async () => {
    if (
      !(
        formState.cadFile &&
        formState.material &&
        formState.tolerance &&
        formState.qty
      )
    ) {
      setMissingInputsDialog(true);
      return;
    }
    setLoading(true);
    let payload = getUserInputs();

    try {
      const data = await getPpePriceForItem({ ...payload, id: nanoid() });
      setLoading(false);
      //Return error if no supplier price is generate due to min/max price/complexity of ppe settings
      if (!isNaN(+data.supplierPrice)) {
        setPredictedPriceObj(data.additionalParameters);
        setCustomerPrice(data.customerPrice);
        setSupplierPrice(data.supplierPrice);
        // setSupplierMarkup(data.supplierMarkup); // this param is out of date
        setFormattedPrice(
          toFixedWithDefault(data.additionalParameters?.price, {
            startWith: 'S$',
          })
        );
        const { totalPriceStr: _supplierPrice } =
          convertPriceWithQuantityToCurrency({
            totalPrice: data.supplierPrice,
            currency,
            exchangeRate,
          });
        setFormattedSupplierPrice(_supplierPrice);

        const { totalPriceStr: _customerPrice } =
          convertPriceWithQuantityToCurrency({
            totalPrice: data.customerPrice,
            currency,
            exchangeRate,
          });
        setFormattedCustomerPrice(_customerPrice);
        setSuccessDialog(true);
      } else {
        setErrorDialog(true);
      }
    } catch (err) {
      setLoading(false);
      setErrorDialog(true);
    }
  };

  const handleReset = () => {
    updateFormState(defaultFormState);
    setPredictedPriceObj(null);
    setSuccessDialog(false);
    setErrorDialog(false);
    setFeedback('');
    setSupplierResp('');
    setExpectedPrice('');
    setCustomerPrice(null);
    setSupplierPrice(null);
    setSupplierMarkup(null);
  };

  const handleSubmitFeedback = async (status) => {
    if (
      !expectedPrice &&
      (supplierResp === 'TOO_HIGH' || supplierResp === 'TOO_LOW')
    ) {
      setFeedbackErrorDialog(true);
    } else {
      const payload = {
        userInputs: getUserInputs(),
        PPEGeneratedParams: predictedPriceObj,
        cadFile: formState.cadFile,
        fileSize: fileSize,
        status,
        expectedPrice,
        feedback,
        dateOfFeedback: new Date()
          .toISOString()
          ?.slice(0, 19)
          ?.replace('T', ' '),
        userID: hasReviewPermission(role) ? supplierID : userID,
        supplierPrice,
        supplierMarkup,
      };
      try {
        const feedbackResp = await ppeFeedback(payload);
        if (feedbackResp?.success) {
          setSupplierResp(status === 'accepted' ? 'ACCEPTED' : 'SUBMITTED');
        } else {
          throw new Error('Failed failed to send feedback');
        }
      } catch (err) {
        setFeedbackErrorDialog(true);
      }
    }
  };

  const handleParametersSwitchChange = (parameter, newValue) => {
    const updatedParameters = {
      ...parametersState,
      [parameter]: newValue,
    };
    setParametersState(updatedParameters);
    updateConfiguration({
      key: KEY_CONFIGURATION.ADMIN_SUPPLIER_PPE_PARAMETERS,
      value: updatedParameters,
    })
      .then(() => {
        notifySuccess('Admin and Supplier PPE settings have been saved!');
      })
      .catch(() => {
        notifyError(
          'Admin and Supplier PPE settings cannot be saved. Please try again'
        );
      });
  };

  const renderQuantityField = () => {
    return (
      <Fragment>
        <FieldLabel>
          <FlexRow>Quantity</FlexRow>
        </FieldLabel>
        <TextField
          size='small'
          type='number'
          margin='none'
          value={formState.qty}
          variant='outlined'
          inputProps={{ min: 1 }}
          InputProps={{
            style: {
              borderRadius: '10px',
            },
            inputProps: { min: 1 },
          }}
          style={{ width: '100%' }}
          onChange={(evt) => {
            let value = evt.target.value;
            updateFormState({ qty: value });
          }}
          // disable change value when mouse scroll
          onWheel={(e) => e.target.blur()}
          error={isEmptyValue(formState.qty) || Number(formState.qty) <= 0}
          helperText={
            isEmptyValue(formState.qty) || Number(formState.qty) <= 0
              ? 'Invalid value'
              : null
          }
        />
      </Fragment>
    );
  };

  const renderTechnologyField = () => {
    return (
      <>
        <FieldLabel>Technology</FieldLabel>
        <FtrDropdown
          key='technology-dropdown'
          fullWidth
          value={formState.technology}
          handleChange={(newTech) => {
            const technology = newTech;
            let tolerance = getDefaultTolerance({
              technology,
              unitType: formState.unitType,
            });
            let updatedFormState = {
              technology,
              tolerance,
              otherTechnology: null,
              otherMaterial: null,
              otherSurfaceFinish: null,
            };
            if (is3DPTechnology(technology)) {
              updatedFormState = {
                ...updatedFormState,
                threeDTechnology: THREE_D_P_FDM_TECH,
                threeDInfill: threeDPrintingInfillDefault,
                threeDLayerThickness: threeDPrintingLayerThicknessDefault,
              };
            } else {
              updatedFormState = {
                ...updatedFormState,
                threeDTechnology: null,
                threeDInfill: null,
                threeDLayerThickness: null,
              };
            }
            updateFormState(updatedFormState);
            technologyHasChanged(technology);
          }}
          items={technologyOptions}
          disabled={isConfigurationLoading}
        />
      </>
    );
  };

  const renderCustomTechnologyField = () => {
    return (
      <>
        <FieldLabel>Custom Technology</FieldLabel>
        <TextField
          size='small'
          type='text'
          margin='none'
          value={formState.otherTechnology}
          variant='outlined'
          InputProps={{
            style: {
              borderRadius: '10px',
            },
          }}
          style={{ width: '100%' }}
          onChange={(evt) =>
            updateFormState({ otherTechnology: evt.target.value })
          }
          error={
            isCustomTechnology(formState.technology) &&
            isEmptyValue(formState.otherTechnology)
          }
          helperText={
            isCustomTechnology(formState.technology) &&
            isEmptyValue(formState.otherTechnology)
              ? 'Required'
              : ''
          }
        />
      </>
    );
  };

  const renderSurfaceFinishField = () => {
    if (
      isEmptyValue(formState.surfaceFinish) ||
      isEmptyValue(surfaceFinishOptions)
    ) {
      return null;
    }

    return (
      <>
        <FieldLabel>
          <FlexRow>Surface Finish</FlexRow>
        </FieldLabel>
        <FtrDropdown
          id='surface-finish-dropdown'
          key='surface-finish-dropdown'
          fullWidth
          value={formState.surfaceFinish}
          handleChange={(newValue) => {
            updateFormState({
              surfaceFinish: newValue,
              otherSurfaceFinish: null,
              customColor: null,
            });
            const params = {
              technology: formState.technology,
              material: formState.material,
              surfaceFinish: newValue,
            };
            surfaceFinishHasChanged(params);
          }}
          items={surfaceFinishOptions}
          loading={
            loadingStatus.loadSurfaceFinishOptions === true ||
            loadingStatus.loadMaterialCategoryOptions === true ||
            loadingStatus.loadThreeDMaterialOptions === true ||
            loadingStatus.loadTechnologyOptions === true
          }
        />
      </>
    );
  };

  const renderMaterialInputField = () => {
    return (
      <MaterialCategoriesInputField
        technology={formState.technology}
        visible={!is3DPTechnology(formState.technology)}
        value={formState.material}
        onSelect={(newValue) => {
          const params = {
            technology: formState.technology,
            threeDTechnology: formState.threeDTechnology,
            material: newValue,
          };
          updateFormState({
            material: newValue,
            otherMaterial: null,
          });
          materialHasChanged(params);
        }}
        materialCategoryOptions={materialCategoryOptions}
        disabled={loadingStatus.loadSurfaceFinishOptions === true}
        loading={
          loadingStatus.loadMaterialCategoryOptions === true ||
          loadingStatus.loadTechnologyOptions === true
        }
        isBuyer={true}
      />
    );
  };

  const renderThreeDPrintingMaterialField = () => {
    return (
      <ThreeDPrintingMaterialField
        bootstrapStyle={false}
        technology={formState.technology}
        threeDTechnology={formState.threeDTechnology}
        visible={is3DPTechnology(formState.technology)}
        value={formState.material}
        onSelect={(_material) => {
          updateFormState({
            material: _material,
            otherMaterial: null,
          });
          const params = {
            technology: formState.technology,
            threeDTechnology: formState.threeDTechnology,
            material: _material,
          };
          materialHasChanged(params);
        }}
        threeDMaterialOptions={threeDMaterialOptions}
        defaultThreeDMaterial={defaultThreeDMaterial}
        disabled={loadingStatus.loadSurfaceFinishOptions === true}
        loading={loadingStatus.loadThreeDMaterialOptions === true}
      />
    );
  };

  const renderCustomMaterialField = () => {
    return (
      isCustomMaterial(formState.material) &&
      !loadingStatus.loadMaterialCategoryOptions && (
        <Grid item>
          <FieldLabel>
            <FlexRow>Custom Material</FlexRow>
          </FieldLabel>
          <div style={{ position: 'relative' }}>
            <TextField
              size='small'
              type='text'
              margin='none'
              value={formState.otherMaterial}
              variant='outlined'
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
              style={{ width: '100%' }}
              onChange={(evt) => {
                updateFormState({
                  otherMaterial: evt.target.value,
                });
              }}
              error={
                isCustomMaterial(formState.material) &&
                isEmptyValue(formState.otherMaterial)
              }
              helperText={
                isCustomMaterial(formState.material) &&
                isEmptyValue(formState.otherMaterial)
                  ? 'Required'
                  : ''
              }
            />
          </div>
        </Grid>
      )
    );
  };

  const renderThreeDPrintingTechnologyInputField = () => {
    return (
      <>
        <FieldLabel>
          <FlexRow>3D Printing Technology</FlexRow>
        </FieldLabel>
        <ThreeDPrintingTechnologyInputField
          bootstrapStyle={false}
          visible={is3DPTechnology(formState.technology)}
          value={formState.threeDTechnology}
          onChange={(newValue) => {
            const threeDTechnology = newValue;
            const tolerance = getDefaultTolerance({
              technology: formState.technology,
              unitType: formState.unitType,
              threeDTechnology,
            });
            let updatedFormState = {
              threeDTechnology,
              tolerance,
            };
            if (newValue === THREE_D_P_FDM_TECH) {
              updatedFormState = {
                ...updatedFormState,
                threeDInfill: threeDPrintingInfillDefault,
                threeDLayerThickness: threeDPrintingLayerThicknessDefault,
              };
            } else {
              updatedFormState = {
                ...updatedFormState,
                threeDInfill: '',
                threeDLayerThickness: '',
              };
            }
            updateFormState(updatedFormState);
            const params = {
              technology: formState.technology,
              threeDTechnology: newValue,
            };
            threeDTechnologyHasChanged(params);
          }}
          threeDTechnologyOptions={threeDTechnologyOptions}
          disabled={
            loadingStatus.loadThreeDMaterialOptions === true ||
            loadingStatus.loadSurfaceFinishOptions === true
          }
          loading={
            loadingStatus.load3DTechnologyOptions === true ||
            loadingStatus.loadTechnologyOptions === true
          }
        />
      </>
    );
  };

  const renderCustomSurfaceFinishField = () => {
    return (
      !isEmptyValue(surfaceFinishOptions) &&
      isCustomSurfaceFinish(formState.surfaceFinish) &&
      loadingStatus.loadSurfaceFinishOptions !== true && (
        <Grid item>
          <FieldLabel>
            <FlexRow>Custom Surface Finish</FlexRow>
          </FieldLabel>
          <div style={{ position: 'relative' }}>
            <TextField
              size='small'
              type='text'
              margin='none'
              value={formState.otherSurfaceFinish}
              variant='outlined'
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
              style={{ width: '100%' }}
              onChange={(evt) => {
                updateFormState({
                  otherSurfaceFinish: evt.target.value,
                });
              }}
              error={
                isCustomSurfaceFinish(formState.surfaceFinish) &&
                isEmptyValue(formState.otherSurfaceFinish)
              }
              helperText={
                isCustomSurfaceFinish(formState.surfaceFinish) &&
                isEmptyValue(formState.otherSurfaceFinish)
                  ? 'Required'
                  : ''
              }
            />
          </div>
        </Grid>
      )
    );
  };

  const renderFinishColorField = () => {
    if (
      isEmptyValue(surfaceFinishOptions) ||
      isCustomSurfaceFinish(formState.surfaceFinish) ||
      isEmptyValue(surfaceFinishColorOptions) ||
      isEmptyValue(formState.color)
    ) {
      return null;
    }

    return (
      <Grid item>
        <FieldLabel>
          <FlexRow>Finish Color</FlexRow>
        </FieldLabel>
        <ColorFtrDropdown
          key='surface-finish-color-dropdown'
          fullWidth
          value={formState.color}
          handleChange={(newValue) => {
            const color = newValue || formState.color;
            updateFormState({
              color,
            });
          }}
          colorPalette={surfaceFinishColorOptions}
        />
      </Grid>
    );
  };

  const renderCustomFinishColorField = () => {
    if (
      (isEmptyValue(surfaceFinishOptions) &&
        !isCustomSurfaceFinish(formState.surfaceFinish)) ||
      isEmptyValue(surfaceFinishColorOptions) ||
      (formState.color !== 'Custom Color' &&
        isEmptyValue(formState.customColor))
    ) {
      return;
    }

    return (
      <Grid item>
        <FieldLabel>
          <FlexRow>Custom Finish Color</FlexRow>
        </FieldLabel>
        <div style={{ position: 'relative' }}>
          <TextField
            size='small'
            type='text'
            margin='none'
            placeholder='e.g. Yellow orange (RAL 2000)'
            value={formState.customColor}
            variant='outlined'
            InputProps={{
              style: {
                borderRadius: '10px',
              },
            }}
            style={{ width: '100%' }}
            onChange={(evt) => {
              const color = evt.target.value;
              updateFormState({
                customColor: color,
              });
            }}
            error={
              formState.color === 'Custom Color' &&
              isEmptyValue(formState.customColor)
            }
            helperText={
              formState.color === 'Custom Color' &&
              isEmptyValue(formState.customColor)
                ? 'Required'
                : ''
            }
          />
        </div>
        <Typography
          variant='body2'
          color='textSecondary'
          style={{ fontStyle: 'italic', fontSize: 'smaller' }}
        >
          Refer{' '}
          <a
            href='https://www.ralcolor.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://www.ralcolor.com/
          </a>{' '}
          for more colors.
        </Typography>
      </Grid>
    );
  };

  const renderMaterialColorField = () => {
    if (
      (isEmptyValue(materialCategoryOptions) &&
        isCustomMaterial(formState.material)) ||
      isEmptyValue(materialColorOptions) ||
      isEmptyValue(formState.materialColor)
    ) {
      return null;
    }

    return (
      <Grid item>
        <FieldLabel>
          <FlexRow>Material Color</FlexRow>
        </FieldLabel>
        <ColorFtrDropdown
          key='material-color-dropdown'
          fullWidth
          value={formState.materialColor}
          handleChange={(newValue) => {
            const materialColor = newValue;
            updateFormState({
              materialColor,
            });
          }}
          colorPalette={materialColorOptions || []}
        />
      </Grid>
    );
  };

  const renderCustomMaterialColorField = () => {
    if (
      (isEmptyValue(materialCategoryOptions) &&
        isCustomMaterial(formState.material)) ||
      isEmptyValue(materialColorOptions) ||
      formState.materialColor !== 'Custom Color'
    ) {
      return null;
    }

    return (
      <Grid item>
        <FieldLabel>
          <FlexRow>Custom Material Color</FlexRow>
        </FieldLabel>
        <TextField
          size='small'
          type='text'
          margin='none'
          placeholder='e.g. Yellow orange (RAL 2000)'
          value={formState.customMaterialColor}
          variant='outlined'
          InputProps={{
            style: {
              borderRadius: '10px',
            },
          }}
          style={{ width: '100%' }}
          onChange={(evt) => {
            const materialColor = evt.target.value;
            updateFormState({
              customMaterialColor: materialColor,
            });
          }}
          error={
            formState.materialColor === 'Custom Color' &&
            isEmptyValue(formState.customMaterialColor)
          }
          helperText={
            formState.materialColor === 'Custom Color' &&
            isEmptyValue(formState.customMaterialColor)
              ? 'Required'
              : ''
          }
        />
        <Typography
          variant='body2'
          color='textSecondary'
          style={{ fontStyle: 'italic', fontSize: 'smaller' }}
        >
          Refer{' '}
          <a
            href='https://www.ralcolor.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://www.ralcolor.com/
          </a>{' '}
          for more colors.
        </Typography>
      </Grid>
    );
  };

  const renderThreeDInfillField = () => {
    return (
      <div>
        <FieldLabel>
          <FlexRow>3D Infill</FlexRow>
        </FieldLabel>
        <ThreeDInfillSelectField
          bootstrapStyle={false}
          visible
          value={formState.threeDInfill}
          onSelect={(newValue) => {
            updateFormState({
              threeDInfill: newValue,
            });
          }}
          ppe3dpInfillOptions={ppe3dpInfillOptions}
        />
      </div>
    );
  };

  const renderThreeDLayerThicknessField = () => {
    return (
      <>
        <FieldLabel>
          <FlexRow>Layer Thickness</FlexRow>
        </FieldLabel>
        <ThreeDLayerThicknessField
          bootstrapStyle={false}
          visible
          value={formState.threeDLayerThickness}
          onSelect={(newValue) => {
            updateFormState({
              threeDLayerThickness: newValue,
            });
          }}
        />
      </>
    );
  };

  const renderAnodizingTypeField = () => {
    return (
      <FlexColumn style={{ gap: 0 }}>
        <FtrFieldLabel>Anodizing Type</FtrFieldLabel>
        <FtrDropdownV2
          id='anodizing-type-dropdown'
          key='anodizing-type-dropdown'
          fullWidth
          value={formState.anodizingType || ANODIZING_TYPE_OPTIONS[0].key}
          handleChange={(newType) =>
            updateFormState({
              anodizingType: newType,
            })
          }
          items={ANODIZING_TYPE_OPTIONS}
        />
      </FlexColumn>
    );
  };

  const renderToleranceField = () => {
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FieldLabel>
            <FlexRow>Tolerance</FlexRow>
          </FieldLabel>
          <Tooltip
            title='Please indicate tightest tolerance in your design.'
            placement='right'
          >
            <img src={InfoIcon} alt='info-icon' />
          </Tooltip>
        </div>
        <FlexRow>
          <FtrDropdown
            key='tolerance-dropdown'
            fullWidth
            value={
              formState.unitType === UNIT_TYPES.IMPERIAL
                ? convertMetricToImperial(formState.tolerance)
                : formState.tolerance
            }
            handleChange={(value) => {
              let tolerance = value;
              if (formState.unitType === UNIT_TYPES.IMPERIAL) {
                tolerance = convertImperialToMetric(tolerance);
              }
              updateFormState({ tolerance });
            }}
            items={STANDARD_TOLERANCE_OPTIONS[formState.unitType]}
            loading={loadingStatus.loadTechnologyOptions}
            disabled={
              loadingStatus.loadTechnologyOptions === true ||
              loadingStatus.loadMaterialCategoryOptions
            }
            renderValue={(selected) => {
              // selected value here is the converted value
              const value = `±${`${selected}${
                DISPLAY_UNIT_OPTIONS[formState.unitType]?.LENGTH
              }`}`;
              return value;
            }}
          />
          <SwitchUnit
            setUnitType={(newUnitType) =>
              updateFormState({ unitType: newUnitType })
            }
            unitType={formState.unitType}
            textStyle={{
              color: colors.blue050,
              width: 'max-content',
              userSelect: 'none',
            }}
          />
        </FlexRow>
      </>
    );
  };

  return (
    <div className={classes.body}>
      <Paper className={classes.paper}>
        <h1 style={{ display: 'inline-block' }}>
          Instant quote (For Factorem Partners)
        </h1>
        <div style={{ display: 'inline-block', fontStyle: 'italic' }}>
          This is for Partner’s internal testing purposes. Uploaded parts will
          not be sent for fabrication.
        </div>
        <div style={{ display: 'flex', marginTop: 10, marginBottom: 20 }}>
          <div style={{ fontStyle: 'italic', marginRight: 6 }}>Objective:</div>
          <div>
            To attain prices close to that of industrial standards
            automatically. The Price Predictor engine is key in achieving
            efficient order placement for our customers and saving your hours in
            quoting and administrative work. We have designed the Factorem Price
            Predictor with in-house machine learning algorithms to attain prices
            close to that of the industrial standards. Now we need your help in
            validating it!
          </div>
        </div>
        <div>{renderTechnologyField()}</div>
        <br />
        <div>
          {` Please drop in any 3D CAD files here, select the appropriate machining
          features below and see if the estimated cost matches.`}
        </div>
        <br />
        <PartUploadDragAndDropV2
          id='ppe-cad-part-file-upload'
          technology={formState.technology}
          handleFiles={handleCadFileChange}
          multiple={false}
        />
        <div style={{ fontStyle: 'italic', marginTop: 3, marginBottom: 20 }}>
          Please upload only 1 file at a time
        </div>
        <List>
          {formState.cadFile && (
            <ListItem
              style={{
                backgroundColor: colors.bgLightGrey,
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  <AttachFileIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                style={{
                  width: 130,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: 12,
                }}
                primary={getFileNameFromCadFile(formState.cadFile)}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => updateFormState({ cadFile: '' })}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
        <Grid container direction='column' spacing={1}>
          <Grid item>{renderQuantityField()}</Grid>
          {is3DPTechnology(formState.technology) && (
            <Grid item>{renderThreeDPrintingTechnologyInputField()}</Grid>
          )}
          {isCustomTechnology(formState.technology) && (
            <Grid item>{renderCustomTechnologyField()}</Grid>
          )}
          {!is3DPTechnology(formState.technology) && (
            <Grid item>{renderMaterialInputField()}</Grid>
          )}
          {is3DPTechnology(formState.technology) && (
            <Grid item>{renderThreeDPrintingMaterialField()}</Grid>
          )}
          {renderCustomMaterialField()}
          {/* No custom material. Material color options are available. */}
          {renderMaterialColorField()}
          {/* No custom material. Material color options are available. */}
          {renderCustomMaterialColorField()}
          {is3DPTechnology(formState.technology) &&
            formState.threeDTechnology === THREE_D_P_FDM_TECH && (
              <Fragment>
                <Grid item>{renderThreeDInfillField()}</Grid>
                <Grid item>{renderThreeDLayerThicknessField()}</Grid>
              </Fragment>
            )}
          <Grid item>{renderSurfaceFinishField()}</Grid>
          {renderCustomSurfaceFinishField()}
          {/* No custom surface. Surface finish color options are available. */}
          {renderFinishColorField()}
          {/* No custom surface. Surface finish color options are available. */}
          {renderCustomFinishColorField()}
          {/* Anodizing type if surface finish is anodizing */}
          {isAnodizingSurfaceFinish(formState.surfaceFinish) && (
            <Grid item>{renderAnodizingTypeField()}</Grid>
          )}
          <Grid item>{renderToleranceField()}</Grid>
          <Grid item>
            {hasReviewPermission(role) && (
              <>
                {isAdminOrHigherRole(role) && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={parametersState?.romSwitch ?? null}
                        onChange={(event) =>
                          handleParametersSwitchChange(
                            event.target.name,
                            event.target.checked
                          )
                        }
                        name='romSwitch'
                        color='primary'
                      />
                    }
                    label='ROM Admin/Supplier'
                  />
                )}
                <div>
                  <Typography
                    variant='body1'
                    style={{ margin: '20px 0px 5px 0px' }}
                  >
                    Assign PPE Feedback To:
                  </Typography>
                  <TextField
                    variant='outlined'
                    id='userID'
                    select
                    fullWidth
                    onChange={(event) => setSupplierID(event.target.value)}
                    value={formState.supplierID}
                    defaultValue={formState.userID}
                    style={{ width: '100%' }}
                  >
                    {suppliers?.map((supplier) => (
                      <MenuItem key={supplier.userID} value={supplier.userID}>
                        {supplier.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </>
            )}
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{ marginTop: '20px' }}
            onClick={requestPricePrediction}
            variant='contained'
            component='span'
            color='secondary'
          >
            Estimate Cost
          </Button>
        </div>
      </Paper>
      <Dialog open={successDialog} onClose={handleClose} fullWidth>
        <DialogTitle>
          Instant quote
          <div
            style={{ marginBottom: 10, fontStyle: 'italic', fontSize: 'small' }}
          >
            This is for internal testing purposes. Uploaded parts will not be
            sent for fabrication.
          </div>
        </DialogTitle>
        <DialogContent>
          {predictedPriceObj && (
            <div>
              <Typography gutterBottom variant='body2'>
                {`Volume: ${toFixedWithDefault(dimensions.volume, {
                  endWith: ' cm3',
                })}`}
              </Typography>
              <Typography gutterBottom variant='body2'>
                {`Total Weight: ${toFixedWithDefault(dimensions.weight, {
                  endWith: ' kg',
                })}`}
              </Typography>
              <Typography gutterBottom variant='body2'>
                {`Surface area: ${toFixedWithDefault(dimensions.surfaceArea, {
                  endWith: ' mm2',
                })}`}
              </Typography>
              <Typography gutterBottom variant='body2'>
                {`Bounding box: ${toFixedWithDefault(dimensions.boundingBoxX, {
                  endWith: 'mm',
                })} x
                  ${toFixedWithDefault(dimensions.boundingBoxY, {
                    endWith: 'mm',
                  })} x
                  ${toFixedWithDefault(dimensions.boundingBoxZ, {
                    endWith: 'mm',
                  })}`}
              </Typography>
              {dimensions.complexity && (
                <Typography gutterBottom variant='body2'>
                  {`Complexity: ${toFixedWithDefault(dimensions.complexity)}`}
                </Typography>
              )}
              <br />
              <Typography gutterBottom variant='body2'>
                {`Material: ${getItemMaterial(formState)}`}
              </Typography>
              <Typography gutterBottom variant='body2'>
                {`Finishing: ${getItemSurfaceFinish(formState)}`}
              </Typography>
              {isAnodizingSurfaceFinish(formState.surfaceFinish) && (
                <Typography gutterBottom variant='body2'>
                  {`Anodizing Type: ${capitalizeString(
                    formState.anodizingType
                  )}`}
                </Typography>
              )}
              <Typography gutterBottom variant='body2'>
                {`Quantity: ${formState.qty}`}
              </Typography>
              {formState.technology === 'CNC Machining' && (
                <Typography gutterBottom variant='body2'>
                  {`Tightest tolerance: ${showUnitValueFromMetric(
                    formState.tolerance,
                    formState.unitType
                  )}`}
                </Typography>
              )}
              {formState.technology === '3D Printing' && (
                <Fragment>
                  <Typography gutterBottom variant='body2'>
                    {`3D Technology: ${formState.threeDTechnology}`}
                  </Typography>
                  <Typography gutterBottom variant='body2'>
                    {`Infill: ${Math.round(formState.threeDInfill * 100)}%`}
                  </Typography>
                  <Typography gutterBottom variant='body2'>
                    {`Layer Thickness: ${toFixedWithDefault(
                      formState.threeDLayerThickness,
                      { endWith: ' mm' }
                    )}`}
                  </Typography>
                </Fragment>
              )}
              {supplierPrice && (
                <div
                  style={{
                    margin: 5,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    style={{
                      backgroundColor: colors.fontBlueBlack,
                      color: 'white',
                      padding: '5px 7px',
                      borderRadius: '5px',
                    }}
                  >
                    {role === 'supplier'
                      ? `Suggested Price: ${formattedSupplierPrice}`
                      : `Suggested Price for Supplier: ${formattedSupplierPrice}`}
                  </Typography>
                </div>
              )}
              {customerPrice && role !== 'supplier' && (
                <div
                  style={{
                    margin: 5,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    style={{
                      backgroundColor: colors.fontBlueBlack,
                      color: 'white',
                      padding: '5px 7px',
                      borderRadius: '5px',
                    }}
                  >
                    {`Suggested Price for Customer: ${formattedCustomerPrice}`}
                  </Typography>
                </div>
              )}
              {predictedPriceObj.price && role !== 'supplier' && (
                <div
                  style={{
                    margin: 5,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    style={{
                      backgroundColor: colors.fontBlueBlack,
                      color: 'white',
                      padding: '5px 7px',
                      borderRadius: '5px',
                    }}
                  >
                    {`Suggested Price by PPE: ${formattedPrice}`}
                  </Typography>
                </div>
              )}
            </div>
          )}
          <div style={{ fontStyle: 'italic', marginTop: 12 }}>
            Please leave your feedback here
          </div>
          <TextField
            variant='outlined'
            margin='dense'
            id='feedback'
            type='text'
            fullWidth
            onChange={(evt) => setFeedback(evt.target.value)}
            value={formState.feedback}
            placeholder='Feedback (optional)'
            multiline
            rows={3}
          />
          {supplierResp &&
          (supplierResp !== 'ACCEPTED' || supplierResp !== 'SUBMITTED') ? (
            <div style={{ fontStyle: 'italic', marginTop: 12 }}>
              Please fill in the expected price
            </div>
          ) : (
            <div style={{ fontStyle: 'italic', marginTop: 12 }}>
              Select one of these options to proceed
            </div>
          )}
          <Grid
            container
            justify='center'
            spacing={3}
            style={{ marginTop: 3, marginBottom: 10 }}
          >
            <Grid item xs={3}>
              {(!supplierResp || supplierResp === 'TOO_LOW') && (
                <Button
                  onClick={() => setSupplierResp('TOO_LOW')}
                  className={classes.tooLow}
                >
                  Too Low
                </Button>
              )}
            </Grid>
            <Grid item xs={3}>
              {!supplierResp ? (
                <Button
                  onClick={() => handleSubmitFeedback('accepted')}
                  className={classes.accept}
                >
                  Accept
                </Button>
              ) : supplierResp === 'ACCEPTED' ? (
                <div
                  style={{
                    margin: 5,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    style={{
                      backgroundColor: green[500],
                      padding: '5px 7px',
                      borderRadius: '5px',
                    }}
                  >
                    Accepted!
                  </Typography>
                </div>
              ) : supplierResp === 'SUBMITTED' ? (
                <div
                  style={{
                    margin: 5,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    style={{
                      backgroundColor: green[500],
                      padding: '5px 7px',
                      borderRadius: '5px',
                    }}
                  >
                    Submitted!
                  </Typography>
                </div>
              ) : (
                <OutlinedInput
                  margin='dense'
                  type='number'
                  min={0}
                  id='expectedPrice'
                  startAdornment={
                    <InputAdornment position='start'>$</InputAdornment>
                  }
                  onChange={(evt) => setExpectedPrice(evt.target.value)}
                  // disable change value when mouse scroll
                  onWheel={(e) => e.target.blur()}
                  value={formState.expectedPrice}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              {(!supplierResp || supplierResp === 'TOO_HIGH') && (
                <Button
                  onClick={() => setSupplierResp('TOO_HIGH')}
                  className={classes.tooHigh}
                >
                  Too High
                </Button>
              )}
            </Grid>
          </Grid>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {supplierResp === 'TOO_LOW' ? (
              <Button
                onClick={() => handleSubmitFeedback('low')}
                variant='contained'
              >
                Submit
              </Button>
            ) : supplierResp === 'TOO_HIGH' ? (
              <Button
                onClick={() => handleSubmitFeedback('high')}
                variant='contained'
              >
                Submit
              </Button>
            ) : (
              (supplierResp === 'ACCEPTED' || supplierResp === 'SUBMITTED') && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography gutterBottom>Thank you for testing</Typography>
                  <Button onClick={handleReset} variant='contained'>
                    Price Another Part!
                  </Button>
                </div>
              )
            )}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={errorDialog}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>Unable to quote</DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialog(false)}>Try again</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={missingInputsDialog}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
      >
        <DialogTitle>Missing inputs</DialogTitle>
        <DialogContent>
          Please make sure you have uploaded a CAD file and indicate necessary
          parameters.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMissingInputsDialog(false)}>Return</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={feedbackErrorDialog}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
      >
        <DialogTitle>Unable to submit feedback</DialogTitle>
        <DialogContent>
          Please fill up the expected price and try again.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFeedbackErrorDialog(false)}>Return</Button>
        </DialogActions>
      </Dialog>
      <LoadingBackDropText
        open={loading}
        text={'Analyzing your file, getting your quote in just a minute...'}
      />
    </div>
  );
}

export default PricePredictorV2;
