import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const theme = createMuiTheme({
  typography: {
    fontFamily: ['"Open Sans"', 'sans-serif']?.join(','),
  },
  palette: {
    primary: {
      main: '#0C5DBC',
    },
    secondary: {
      main: '#7a161a',
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: '$labelcolor',
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: '#62B4FF',
        '&$checked': {
          color: '#0C5DBC',
        },
      },
    },
  },
});

if (module.hot) {
  module.hot.accept();
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Root />
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
