import React, { useRef, useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  ClickAwayListener,
  FormControlLabel,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';

import ClickablePaper from '../../ClickablePaper';
import { FlexRow } from '../../layouts/FlexLayouts';
import {
  FtrBlueCheckbox,
  FtrBoldText,
  FtrTypography,
} from '../../ftr-components';

import { colors } from '../../../palette';

// --------------------------------------------------------------------------

export const DEFAULT_DELIVERY_TIME = {
  option: 'Default',
  deliveryTime: ['9AM - 6PM'],
};

const PREFERRED_TIME_SLOTS = [
  '9AM - 11AM',
  '11AM - 1PM',
  '1PM - 3PM',
  '3PM - 5PM',
  '5PM - 6PM',
];

const useStyles = makeStyles(() => ({
  menuItemRoot: {
    borderRadius: '0.75rem',
    whiteSpace: 'break-spaces',
    margin: '0.5rem',
    padding: '0.5rem 1rem',
    '&:hover': {
      backgroundColor: colors.dropdownHover,
    },
    '&.Mui-selected': {
      backgroundColor: colors.dropdownSelected,
    },
  },
}));

function DeliveryTimeSelection(props) {
  const classes = useStyles();

  const { value = DEFAULT_DELIVERY_TIME, onChange = () => {} } = props;

  const containerRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const [showDropdown, setShowDropdown] = useState(false);

  const showPopover = () => {
    setAnchorEl(containerRef.current);
    setShowDropdown(true);
  };

  return (
    <>
      <div ref={containerRef} onClick={showPopover}>
        <ClickablePaper showEditIcon={false}>
          <FlexRow style={{ justifyContent: 'space-between' }}>
            <FtrTypography
              type='subHeading'
              fontSize='16'
              style={{
                color: colors.neutral070,
                fontWeight: 600,
              }}
            >
              {value.option}
            </FtrTypography>
            {value.deliveryTime.length === 1 && (
              <FtrBoldText style={{ color: colors.neutral060 }}>
                {value.deliveryTime?.join(', ')}
              </FtrBoldText>
            )}
          </FlexRow>
          {value.deliveryTime.length > 1 && (
            <FtrBoldText
              style={{ color: colors.neutral060, marginTop: '0.5rem' }}
            >
              {value.deliveryTime?.join(', ')}
            </FtrBoldText>
          )}
        </ClickablePaper>
      </div>
      <Popper
        open={showDropdown}
        anchorEl={anchorEl}
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: 1,
        }}
        placement='bottom-start'
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
                <MenuList id='menu-list-grow' autoFocusItem={open}>
                  <MenuItem
                    classes={{
                      root: clsx(classes.menuItemRoot, {
                        'Mui-selected': value.option === 'Default',
                      }),
                    }}
                    onClick={() => {
                      onChange(DEFAULT_DELIVERY_TIME);
                      setShowDropdown(false);
                    }}
                  >
                    <FlexRow>
                      <FtrTypography
                        type='subHeading'
                        fontSize='16'
                        style={{
                          color: colors.neutral070,
                          fontWeight: 600,
                        }}
                      >
                        9AM - 6PM
                      </FtrTypography>
                      <FtrTypography
                        type='subHeading'
                        fontSize='16'
                        style={{
                          color: colors.neutral070,
                          fontWeight: 400,
                        }}
                      >
                        (Default)
                      </FtrTypography>
                    </FlexRow>
                  </MenuItem>
                  {PREFERRED_TIME_SLOTS?.map((option) => {
                    const handlePreferredTimeChange = (_option) => {
                      if (
                        value.deliveryTime.length === 1 &&
                        value.deliveryTime[0] === _option
                      ) {
                        onChange(DEFAULT_DELIVERY_TIME);
                        return;
                      }
                      let newTime;
                      if (value.option === 'Default') {
                        newTime = [_option];
                      } else {
                        newTime = value.deliveryTime.includes(_option)
                          ? value.deliveryTime?.filter(
                              (time) => time !== _option
                            )
                          : [...value.deliveryTime, _option];
                      }
                      const newValue = {
                        option: 'Preferred Time',
                        deliveryTime: newTime,
                      };
                      onChange(newValue);
                    };

                    return (
                      <MenuItem
                        key={option}
                        classes={{
                          root: clsx(classes.menuItemRoot, {
                            'Mui-selected': value.deliveryTime.includes(option),
                          }),
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handlePreferredTimeChange(option);
                        }}
                      >
                        <FormControlLabel
                          control={
                            <FtrBlueCheckbox
                              key={option}
                              name={option}
                              checked={value.deliveryTime.includes(option)}
                              style={{ padding: '0px 10px' }}
                            />
                          }
                          style={{
                            width: 'fit-content',
                          }}
                          label={
                            <FtrTypography
                              type='subHeading'
                              fontSize='16'
                              style={{
                                color: colors.neutral070,
                                fontWeight: 600,
                              }}
                            >
                              {option}
                            </FtrTypography>
                          }
                        />
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default DeliveryTimeSelection;
