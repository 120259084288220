// Import settings
import React, { Fragment, useState } from 'react';

// Import images
import AboutFactoremBlueIcon from '../../assets/img/about-factorem-blue.png';
import AboutFactoremGreyIcon from '../../assets/img/about-factorem-grey.png';
import AddPartBlueIcon from '../../assets/img/adding-a-part-blue.png';
import AddPartGreyIcon from '../../assets/img/adding-a-part-grey.png';
import DeliveryBlueIcon from '../../assets/img/delivery-blue.png';
import DeliveryGreyIcon from '../../assets/img/delivery-grey.png';
import ManagingOrdersBlueIcon from '../../assets/img/managing-orders-blue.png';
import ManagingOrdersGreyIcon from '../../assets/img/managing-orders-grey.png';

// Import customised components
import FAQTitleButton from '../../components/FAQTitle';
import { FAQDescription } from '../../components/FAQTexts';
import FAQAccordion from '../../components/FAQAccordion';

// Import material UI components
import { Container, Grid } from '@material-ui/core';

function FAQPage() {
  const [currentPage, setCurrentPage] = useState('FAQ');
  const [expanded, setExpanded] = useState('faq1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [aboutFactoremImage, setAboutFactoremImage] = useState(
    AboutFactoremGreyIcon
  );
  const [addPartImage, setAddPartImage] = useState(AddPartGreyIcon);
  const [managingOrdersImage, setManagingOrdersImage] = useState(
    ManagingOrdersGreyIcon
  );
  const [deliveryImage, setDeliveryImage] = useState(DeliveryGreyIcon);
  return (
    <Grid container direction='column' alignItems='center'>
      <Container
        style={{
          padding: '30px',
          display: 'flex',
          justifyContent: 'spaceBetween',
          overflow: 'scroll',
        }}
      >
        <FAQTitleButton
          faqImage={aboutFactoremImage}
          changeImage={() =>
            setAboutFactoremImage(
              currentPage === 'FAQ'
                ? AboutFactoremBlueIcon
                : AboutFactoremGreyIcon
            )
          }
          faqText='About Factorem'
          isSelected={currentPage === 'FAQ'}
          onClick={() => {
            setCurrentPage('FAQ');
          }}
        />
        <FAQTitleButton
          faqImage={addPartImage}
          changeImage={() =>
            setAddPartImage(
              currentPage === 'AddPart' ? AddPartBlueIcon : AddPartGreyIcon
            )
          }
          faqText='Adding A Part'
          isSelected={currentPage === 'AddPart'}
          onClick={() => setCurrentPage('AddPart')}
        />
        <FAQTitleButton
          faqImage={managingOrdersImage}
          changeImage={() =>
            setManagingOrdersImage(
              currentPage === 'ManageOrders'
                ? ManagingOrdersBlueIcon
                : ManagingOrdersGreyIcon
            )
          }
          faqText='Managing Orders'
          isSelected={currentPage === 'ManageOrders'}
          onClick={() => setCurrentPage('ManageOrders')}
        />
        <FAQTitleButton
          faqImage={deliveryImage}
          changeImage={() =>
            setDeliveryImage(
              currentPage === 'Delivery' ? DeliveryBlueIcon : DeliveryGreyIcon
            )
          }
          faqText='Delivery'
          isSelected={currentPage === 'Delivery'}
          onClick={() => setCurrentPage('Delivery')}
        />
      </Container>
      <Grid item>
        {currentPage === 'FAQ' ? (
          <FAQDescription headingText='ABOUT FACTOREM' />
        ) : currentPage === 'AddPart' ? (
          <FAQDescription headingText='ADDING A PART' />
        ) : currentPage === 'ManageOrders' ? (
          <FAQDescription headingText='MANAGING ORDERS' />
        ) : (
          <FAQDescription headingText='DELIVERY' />
        )}
      </Grid>
      <div style={{ width: '80%', marginBottom: 30 }}>
        <Grid item zeroMinWidth>
          {currentPage === 'FAQ' ? (
            <Fragment>
              <FAQAccordion
                faqQn='Welcome to Factorem'
                faqAns='Factorem is Southeast Asia’s first one-stop portal to custom manufacturing. Backed by a strong supplier partner network, the platform’s vision is to #HelpMakersMake and catalyze hardware innovation within the region. In order to use the services, all you need is a 3D CAD file accompanied by a 2D technical drawing and the platform will find the right suppliers for you to get matched with. Drop in your designs and get a free quote today!'
                expanded={expanded === 'faq1'}
                onChange={handleChange('faq1')}
              />
              <FAQAccordion
                faqQn='Where is Factorem based?'
                faqAns='Factorem offers a platform as a service for hardware designers to get matched with reliable suppliers to help them in making their parts. Factorem owns no facilities of its own while its suppliers are based off of Southeast Asia. The platform allows for designers from both within and outside Southeast Asia to procure the parts that they need.'
                expanded={expanded === 'faq2'}
                onChange={handleChange('faq2')}
              />
            </Fragment>
          ) : currentPage === 'AddPart' ? (
            <Fragment>
              <FAQAccordion
                faqQn='How do I upload my part and get quotations?'
                faqAns='The Factorem platform connects you to reliable suppliers that can make your custom part. All you need is a 3D model (e.g. .step file) for a start. You can upload that on the “Add A Part” page and you will be notified as soon as a quotation is made. You can keep checking the progress of a quotation under the “Quotations” page.'
                expanded={expanded === 'faq3'}
                onChange={handleChange('faq3')}
              />
              <FAQAccordion
                faqQn='What if I am unsure of filling up some of the requested fields when uploading a part?'
                faqAns='Please fill in all fields to your best knowledge and include any uncertainties under the “Remarks” field. In case of any issues, our team will reach out to you to clarify!'
                expanded={expanded === 'faq4'}
                onChange={handleChange('faq4')}
              />
            </Fragment>
          ) : currentPage === 'ManageOrders' ? (
            <Fragment>
              <FAQAccordion
                faqQn='What happens after I submit my part?'
                faqAns='Upon submission,  the Factorem Portal will assess and verify the manufacturability of your designs. Once cleared the job order will be posted and all relevant suppliers will be informed of your request. The suppliers will then review your order and provide a quotation accordingly.'
                expanded={expanded === 'faq5'}
                onChange={handleChange('faq5')}
              />
              <FAQAccordion
                faqQn='Where are the suppliers from? How do you ensure quality?'
                faqAns='We pride ourselves in the quality of our service and ensure that all suppliers go through a rigorous background check. We only accept suppliers that have good rapport with our partners and hold them accountable in case of any errors on their part. All suppliers are within the Southeast Asia region, which helps us provide better costs and lead times. This adds to our mission of #HelpingMakersMake as seamlessly as possible!'
                expanded={expanded === 'faq6'}
                onChange={handleChange('faq6')}
              />
              <FAQAccordion
                faqQn='How do I know when a supplier quotes my part?'
                faqAns='We will notify you each time a quotation is submitted by a supplier for any of your parts. Keep checking your email!'
                expanded={expanded === 'faq7'}
                onChange={handleChange('faq7')}
              />
              <FAQAccordion
                faqQn='How do I accept a quotation?'
                faqAns={`As soon as a supplier quotes for your order, you will be notified via email. You must then go into the “Quotations” page to review and approve the quote if it meets your requirement. Once accepted, the respective supplier will proceed to making your part requested!
                                    Please review and remarks provided by the supplier and address them accordingly as you proceed to complete the order.
                                    You can keep track of the progress of your order under the “Manage My Parts” page.`}
                expanded={expanded === 'faq8'}
                onChange={handleChange('faq8')}
              />
              <FAQAccordion
                faqQn='What if the quotation provided is too high or lead time too long?'
                faqAns='If the current provided quotation does not meet your requirements, you may choose to wait longer as other suppliers review and quote for your order. As long as you have not accepted a quotation that is provided, the Factorem Portal will actively keep searching for suppliers that can make your part.'
                expanded={expanded === 'faq9'}
                onChange={handleChange('faq9')}
              />
            </Fragment>
          ) : (
            <Fragment>
              <FAQAccordion
                faqQn='How do I make payment?'
                faqAns='Once a quotation is accepted by you, the team for Factorem will reach out to you via email with the payment details. Please note that for orders above S$1000, a 50% deposit is required to process the order.'
                expanded={expanded === 'faq10'}
                onChange={handleChange('faq10')}
              />
              <FAQAccordion
                faqQn='Can I collect the part myself?'
                faqAns='Factorem’s supplier network spans across Southeast Asia, therefore, suppliers for your part may be in a geographical location that is far from you. As such we prefer having the parts shipped to you directly.'
                expanded={expanded === 'faq11'}
                onChange={handleChange('faq11')}
              />
              <FAQAccordion
                faqQn='What if there are mistakes in the order I received?'
                faqAns='At Factorem, we pride ourselves for the quality of suppliers and the ethics they maintain in their work. If there is an error on the part received, please contact us directly at alexandra@factorem.co and we will get back to your as soon as possible. We strive to provide quality in the custom parts that are ordered through us and will not let any order go unsatisfied!'
                expanded={expanded === 'faq12'}
                onChange={handleChange('faq12')}
              />
            </Fragment>
          )}
        </Grid>
      </div>
    </Grid>
  );
}

export default FAQPage;
