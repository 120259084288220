import React, { useContext, useReducer } from 'react';

import RequestMoreQuotesPopupV2 from '../components/popups/RequestMoreQuotesPopupV2';
import WarningPopup from '../components/popups/WarningPopup';

import withLoadingBackDropTextHOC from './withLoadingBackDropTextHOC';

import { getInstantQuoteForMultiQuantities } from '../apis/itemApi';

import { isEmptyValue } from '../utils/commonUtils';

import AppContext from '../context/AppContext';

import { ORDER_STATUS } from '../constants';
import { SEVERITY } from '../constants/errorMessageConstants';

// -------------------------------------------------------------------------------------------------

function withRequestMoreQuotesPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const { getItems = () => {} } = props;

    const { setSnackbarMessage = () => {} } = useContext(AppContext);

    const defaultLocalState = {
      open: false,
      showRequestStatusPopup: false,
      showRequestProcessedPopup: false,
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    const [processing, setProcessing] = React.useState(false);
    const [formError, setFormError] = React.useState({});

    const handleRequestQuote = (quantity) => {
      const itemID = localState.selectedItem.itemID;
      const body = {
        quantity,
      };

      setProcessing(true);
      setFormError({});
      getInstantQuoteForMultiQuantities(itemID, body)
        .then((response) => {
          setProcessing(false);
          updateLocalState({
            open: false,
          });
          if (response.status === ORDER_STATUS.WAITING_FOR_QUOTE) {
            // show info pop up
            updateLocalState({
              showRequestStatusPopup: true,
            });
          } else {
            // TODO the below will be sufficient once we don't rerender the whole page
            // setSnackbarMessage(`Quote for new quantity has been added successfully`);
            updateLocalState({
              showRequestProcessedPopup: true,
            });
          }
        })
        .catch((err) => {
          setProcessing(false);
          if (
            err.message &&
            (err.message.includes('Quote exists for') ||
              err.message.includes('original quantity request'))
          ) {
            setFormError({ quantity: err.message });
            return;
          }

          setSnackbarMessage(err.message, {
            severity: SEVERITY.ERROR,
            errorTitle: 'Request more quotes failed',
            body: {
              itemID,
              quantity,
            },
          });
        });
    };

    const handleClosePopup = () => {
      setFormError({});
      updateLocalState({});
      setProcessing(false);
    };

    const resetFormError = () => {
      setFormError({});
    };

    return (
      <>
        <WrappedComponent
          updateRequestQuotePopupState={(data) => {
            updateLocalState({ ...data });
          }}
          {...props}
        />
        {localState.open && (
          <RequestMoreQuotesPopupV2
            open={localState.open}
            itemQuantity={localState.selectedItem?.quantity}
            processing={processing}
            formError={formError}
            handleClose={handleClosePopup}
            handleRequest={handleRequestQuote}
            resetFormError={resetFormError}
          />
        )}
        {localState.showRequestStatusPopup && (
          <WarningPopup
            open={localState.showRequestStatusPopup}
            body='Thank you for your request. We will get back to you within 24 hours!'
            onClose={() => {
              updateLocalState({ showRequestStatusPopup: false });
              handleClosePopup();
              getItems();
            }}
          />
        )}
        {localState.showRequestProcessedPopup && (
          <WarningPopup
            open={localState.showRequestProcessedPopup}
            body={`Thank you for your request! Your quote for the updated quantity has been successfully added. To view it, simply click on your ordered item and navigate to the "All Quotes" tab.`}
            onClose={() => {
              updateLocalState({ showRequestProcessedPopup: false });
              handleClosePopup();
              getItems();
            }}
          />
        )}
      </>
    );
  }

  return withLoadingBackDropTextHOC(WrappedComponentWithHOC);
}

export default withRequestMoreQuotesPopupHOC;
