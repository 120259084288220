import { ceil } from 'lodash';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { DatePicker } from '@material-ui/pickers';

import { DataGrid } from '@mui/x-data-grid';

import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import { RefetchButton } from '../../components/grid-data/buttons/RefetchButton';

import { MONTH_NUMBER_NAME_MAPPING } from '../../constants/dateTimeConstants';

import { getAllUserFeedbackMetrics } from '../../apis/userFeedbackApi';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { exportCSV } from '../../utils/csvExportUtils';
import { getCurrentYear } from '../../utils/dateTimeUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import { colors } from '../../palette';

// ------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    paddingTop: '1rem',
    marginBottom: '2rem',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const DEFAULT_YEAR = getCurrentYear();

function UserFeedbackMetricsDataGrid() {
  const classes = useStyles();

  const role = useSelector(getUserRoleSelector);

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 12,
      totalCount: 0,
      loading: false,
      year: DEFAULT_YEAR,
    }
  );

  const {
    data: allFeedbackMetrics,
    isLoading,
    refetch: refetchFeedbackMetrics,
  } = useQuery('getAllUserFeedbackMetrics', () =>
    getAllUserFeedbackMetrics({ yearFilter: tableQueryParams.year })
  );

  const refetchFeedbackMetricsWithToastNotif = useCallback(() => {
    return refetchFeedbackMetrics()
      .then(() => {
        notifySuccess('User Feedback Metrics fetched successfully');
      })
      .catch(
        (err) =>
          notifyError(
            `User feedback metrics fetch failed. Error: ${err.message}`
          ),
        'error'
      );
  }, [refetchFeedbackMetrics]);

  const [filteredData, { setColumnsDef, setSourceData }] =
    useDataGridFilterHook({
      source: allFeedbackMetrics,
    });

  const defaultColumns = [
    {
      headerName: 'Month',
      field: 'month',
      valueGetter: ({ row: rowData }) => {
        return MONTH_NUMBER_NAME_MAPPING[rowData?.month] || '';
      },
    },
    {
      headerName: '#Answered Feedbacks',
      field: 'answeredFeedbacks',
      valueGetter: ({ row: rowData }) => {
        return rowData?.monthlyFeedbackSummary?.answeredCount || '0';
      },
    },
    {
      headerName: '#Unanswered Feedbacks',
      field: 'unansweredFeedbacks',
      valueGetter: ({ row: rowData }) => {
        return rowData?.monthlyFeedbackSummary?.unansweredCount || '0';
      },
    },
    {
      headerName: '% First Checkout',
      field: 'pctFirstCheckout',
      valueGetter: ({ row: rowData }) => {
        return rowData?.monthlyFeedbackSummary?.pctFirstCheckout || '0.00';
      },
    },
    {
      headerName: '% First Order Shipped',
      field: 'pctOrderShipped',
      valueGetter: ({ row: rowData }) => {
        return rowData?.monthlyFeedbackSummary?.pctFirstOrderShipped || '0.00';
      },
    },
    {
      headerName: '% Order Completion',
      field: 'pctOrderCompletion',
      valueGetter: ({ row: rowData }) => {
        return rowData?.monthlyFeedbackSummary?.pctOrderCompletion || '0.00';
      },
    },
  ];

  const [columns, _setColumns] = useState(defaultColumns);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(allFeedbackMetrics);
  }, [allFeedbackMetrics]);

  useEffect(() => {
    updateTableQueryParams({ loading: isLoading ?? false });
  }, [isLoading]);

  useEffect(() => {
    setColumnsDef(columns);
  }, [columns]);

  useEffect(() => {
    if (tableQueryParams.year !== DEFAULT_YEAR) {
      refetchFeedbackMetricsWithToastNotif();
    }
  }, [tableQueryParams.year]);

  const getCustomerToolbar = () => {
    const refetchButton = (
      <RefetchButton
        key='refetch-user-feedback-metrics'
        handleClick={() => {
          refetchFeedbackMetricsWithToastNotif();
        }}
        disabled={tableQueryParams.loading}
      />
    );
    const yearFilter = (
      <DatePicker
        animateYearScrolling
        clearable
        clearLabel='No Preference'
        disableFuture
        disableToolbar
        emptyLabel='No Preference'
        InputProps={{
          style: {
            borderRadius: '10px',
          },
        }}
        inputVariant='outlined'
        label={'Year'}
        margin='dense'
        onChange={(newYear) =>
          updateTableQueryParams({
            year: new Date(newYear).getFullYear(),
          })
        }
        value={
          tableQueryParams?.year
            ? new Date(tableQueryParams.year, 0)
            : new Date()
        }
        views={['year']}
      />
    );
    const exportButton = (
      <ExportCsvButton
        key='export-csv'
        handleClick={() =>
          exportCSV(columns, filteredData, 'All User Feedback Metrics')
        }
        show={isSuperAdminRole(role)}
      />
    );

    const buttons = [yearFilter, refetchButton, exportButton];

    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar buttons={buttons} />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.body}>
      <DataGrid
        autoHeight
        rows={filteredData ?? []}
        columns={columns?.map((col) => ({
          width: 200,
          sortable: false,
          ...col,
        }))}
        getRowId={(row) => row?.month || allFeedbackMetrics?.indexOf(row)}
        rowHeight={60}
        headerHeight={60}
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        rowsPerPageOptions={[3, 6, 12]}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
        loading={tableQueryParams.loading}
      />
    </div>
  );
}

export default UserFeedbackMetricsDataGrid;
