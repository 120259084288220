import React, { useState, useEffect } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';

import CountryDropDown from './forms/CountryDropDown';
import { FlexColumn } from './layouts/FlexLayouts';
import { FtrTextField } from './ftr-components/FtrField';
import { FtrBoldText } from './ftr-components';

import { COUNTRY_LIST } from '../constants/countryConstants';
import { ADDRESS_COMPONENTS } from '../constants/deliveryInfoConstants';

import { colors } from '../palette';

// ----------------------------------------------------------------------

/**
 *
 * @param {object} props
 * @param {'medium' | 'small'} props.size
 * @param {'normal' | 'none' | 'dense'} props.margin
 * @param {string} props.className
 * @param {string} props.label
 * @param {React.CSSProperties} props.style
 * @param {boolean} props.required
 * @param {({ postalCode, unitNo, address, country}) => void} props.onChange
 * @param {{ postalCode, unitNo, address, country}} props.initialValue
 * @param {React.CSSProperties} props.styleChild
 * @param {boolean} props.showCountry
 */
const AddressAutocompleteV2 = (props) => {
  const {
    name = 'address',
    size = 'medium',
    label = 'Address',
    style = null,
    required = false,
    onChange = () => null,
    initialValue = {
      address: '',
      postalCode: '',
      unitNo: '',
      country: 'Singapore',
    },
    styleChild = null,
    showCountry = true,
    showSubFieldTitle = false,
    error,
  } = props;

  const [address, setAddress] = useState(initialValue.address);
  const [postalCode, setPostalCode] = useState(initialValue.postalCode);
  const [unitNo, setUnitNo] = useState(initialValue.unitNo);
  const [country, setCountry] = useState(initialValue.country);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setTimeout(() => {
      // load html first then load places autocomplete
      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById(name),
        {
          types: ['address'],
        }
      );
      autocomplete.addListener('place_changed', () => {
        const placeResult = autocomplete.getPlace();
        const addressComponents = placeResult?.address_components;
        let _address = [];
        let selectedCountry = '';
        let _postalCode = null;
        addressComponents?.forEach((component) => {
          if (component.types.includes(ADDRESS_COMPONENTS.COUNTRY)) {
            selectedCountry = component?.short_name;
          } else if (component.types.includes(ADDRESS_COMPONENTS.POSTAL_CODE)) {
            _postalCode = component?.long_name;
          } else {
            _address.push(component?.long_name);
          }
        });
        let address_str = _address?.join(', ');
        const [countryName] =
          Object.entries(COUNTRY_LIST)?.find(
            ([_, _country]) => _country.code === selectedCountry
          ) || [];
        setCountry(countryName);
        setPostalCode(_postalCode ?? '');
        setAddress(address_str);
      });
    }, 500);
  }, []);

  useEffect(() => {
    onChange({
      postalCode,
      unitNo,
      address,
      country,
    });
  }, [postalCode, unitNo, address, country]);

  return (
    <>
      <FtrTextField
        name={name}
        title={label}
        variant='outlined'
        fullWidth
        type='text'
        multiline
        onChange={(e) => setAddress(e.target.value)}
        value={address}
        size={size}
        style={style}
        required={required}
        error={error}
      />
      {address && (
        <div
          style={{
            marginBottom: '0.7rem',
            marginTop: '-0.8rem',
            display: 'grid',
            gap: '1rem',
            width: '100%',
            alignItems: 'center',
            gridTemplateColumns: `repeat(${
              isMobile || !showCountry ? 2 : 3
            }, 1fr)`,
            ...styleChild,
          }}
        >
          {showCountry && (
            <FlexColumn
              style={{
                marginBottom: showSubFieldTitle ? '-15px' : '-3px',
                width: '100%',
                gap: '0.3rem',
              }}
            >
              <>
                {showSubFieldTitle && (
                  <FtrBoldText
                    style={{
                      color: colors.neutral070,
                    }}
                  >
                    Country
                  </FtrBoldText>
                )}
                <CountryDropDown
                  valueSelected={country}
                  onSelect={(value) => setCountry(value)}
                />
              </>
            </FlexColumn>
          )}
          <FlexColumn
            style={{
              gap: 0,
              marginTop: showSubFieldTitle ? '1rem' : 0,
            }}
          >
            <FtrTextField
              title={showSubFieldTitle ? 'Zip Code' : null}
              name='zip-code'
              label={showSubFieldTitle ? null : 'Zip Code'}
              id='zip-code'
              onChange={(evt) => setPostalCode(evt.target.value)}
              value={postalCode}
            />
          </FlexColumn>
          <FlexColumn
            style={{
              gap: 0,
              marginTop: showSubFieldTitle ? '1rem' : 0,
            }}
          >
            <FtrTextField
              title={showSubFieldTitle ? 'Unit No' : null}
              name='unitNo'
              label={showSubFieldTitle ? null : 'Unit No'}
              id='unitNo'
              onChange={(evt) => setUnitNo(evt.target.value)}
              value={unitNo}
            />
          </FlexColumn>
        </div>
      )}
    </>
  );
};

export default AddressAutocompleteV2;
