import { useState } from 'react';

import {
  addCustomerPoFilesForMultiCheckoutApi,
  deleteCustomerPoFileForMultiCheckoutApi,
} from '../apis/multiCheckoutApi';

import { getCustomerPoFileS3Key, uploadFileToS3 } from '../services/s3Service';

import { ITEM_FILE_UPLOAD_TYPE } from '../constants/';

// --------------------------------------------------------------------------------------------

export const useCustomerPOHook = ({
  setSnackbarMessage = () => {},
  refetch = () => {},
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const handlePOUploadForMultiCheckout = async (files, mctID) => {
    setIsUploading(true);
    const uploadFilesBody = {
      checkoutID: mctID,
      files: [],
    };

    const promises = Array.from(files)?.map(async (file) => {
      const data = await uploadFileToS3(
        file,
        getCustomerPoFileS3Key(file, mctID)
      );
      const { s3ObjectUrl } = data || {};
      const uploadedFileItem = {
        fileName: file.name,
        url: s3ObjectUrl,
        type: ITEM_FILE_UPLOAD_TYPE.CUSTOMER_PO,
      };
      return uploadedFileItem;
    });

    let hasError = false;
    const fileUrls = await Promise.all(promises).catch(() => {
      setSnackbarMessage('Error uploading PO files');
      setIsUploading(false);
      hasError = true;
    });

    if (hasError) {
      return;
    }

    uploadFilesBody.files = fileUrls;

    await addCustomerPoFilesForMultiCheckoutApi(uploadFilesBody);
    setIsUploading(false);
    setSnackbarMessage('File(s) uploaded successfully!');
    refetch();
  };

  const handleDeletePOFile = async (file, mctID) => {
    setIsUploading(true);
    await deleteCustomerPoFileForMultiCheckoutApi({
      checkoutID: mctID,
      file,
    });
    setIsUploading(false);
    setSnackbarMessage('File deleted successfully!');
    refetch();
  };

  const handleReplacePOFile = async (oldFile, newFile, mctID) => {
    setIsUploading(true);
    await deleteCustomerPoFileForMultiCheckoutApi({
      checkoutID: mctID,
      file: oldFile,
    });
    await handlePOUploadForMultiCheckout([newFile], mctID);
    setIsUploading(false);
    refetch();
  };

  return {
    isUploading,
    handlePOUploadForMultiCheckout,
    handleDeletePOFile,
    handleReplacePOFile,
  };
};
