import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';
import Title from '../Title';
import BlueButton from '../buttons/BlueButton';
import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 400,
    borderRadius: 10,
  },
  space: {
    width: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    flex: '50%',
  },
}));

function JsonInfoDisplayPopup(props) {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { rowData, handleClose, title = 'Info', jsonData } = props;

  const renderBody = () => {
    const maxHeight = 450;
    return (
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          flexWrap: isMobile ? 'wrap' : 'nowrap',
        }}
      >
        <div className={classes.container}>
          <Typography variant='h5'>{title}</Typography>
          <div
            style={{
              whiteSpace: 'pre-wrap',
              textAlign: 'left',
              padding: '1rem',
              boxSizing: 'border-box',
              border: `1px solid ${colors.fontMediumGray}`,
              maxHeight,
              width: '100%',
              overflow: 'auto',
            }}
          >
            {JSON.stringify(jsonData, null, 2)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <Title contentTitle={`Tracking ID ${rowData.id}`} size='small' />
      </DialogTitle>
      <DialogContent
        style={{
          padding: '0 30px',
          textAlign: 'center',
        }}
      >
        <React.Fragment>{renderBody()}</React.Fragment>
      </DialogContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'sticky',
          bottom: 0,
          width: '100%',
          padding: '1rem',
          backgroundColor: 'white',
          boxSizing: 'border-box',
        }}
      >
        <BlueButton
          onBtnClick={() => {
            handleClose();
          }}
          btnContent='OK'
        />
      </div>
    </Dialog>
  );
}

export default JsonInfoDisplayPopup;
