import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  '@keyframes loading': {
    '0%': { opacity: 1 },
    '50%': { opacity: 0.8 },
    '100%': { opacity: 0.3 },
  },
  loading: {
    animation: '$loading 1s infinite alternate',
  },
}));

function TextLoading(props) {
  const { children, className, ...restProps } = props;
  const classes = useStyles();
  return (
    <div {...restProps} className={clsx(className, classes.loading)}>
      {children}
    </div>
  );
}

export default TextLoading;
