import { colors } from '../palette';

export const SUPPLIER_DATA_DASHBOARD_KEY_CONSTANT = {
  userID: 'Factorem User ID',
  companyRegistrationNumber: 'Company Registration Number',
  companyName: 'Partner Company Name',
  country: 'Country',
  revenueEarned: 'Revenue Earned',
  numberOfOrdersTillDate: 'Number of Orders Till Date',
  numberOfPartsProduced: 'Number of Parts Produced',
  partnerTier: 'Partner Tier',
  qualityRate: 'Quality Rate',
  onTimeDeliveryRate: 'On-Time Delivery Rate',
  averageCycleTimeWorking: 'Average Cycle Time (working days)',
  rfqToQuote: 'RFQ-to-Quote %',
  availability: 'Data Dashboard Availability',
  cycleTimeRating: 'Cycle Time Rating',
};

export const TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT = {
  revenueEarned: `Total income generated by your sales at Factorem. It includes the value of all successfully delivered orders since your onboarding.`,
  numberOfOrdersTillDate: `Total count of orders successfully completed and delivered.`,
  numberOfPartsProduced: `Total quantity of parts manufactured by your company since your onboarding with us. It highlights your output and gives you a sensing of your preference towards high mix low volume vs low mix high volume.`,
  partnerTier: `Indicates the level of partnership status your company holds within Factorem's Partner Network. It is a reflection of your performance, reliability, and commitment as a Partner. As you achieve higher performance and consistently meet quality and delivery standards, you can progress to higher tiers, unlocking various benefits and opportunities for collaboration.`,
  qualityRate: `Represents the quality of goods produced as well as packaging and aherence to instructions in delivery of goods to customer. Higher your quality rates, the better the chances of you getting access to high value jobs.`,
  onTimeDeliveryRate: `Represents the percentage of orders delivered by your company on or before the agreed-upon delivery date. A high on-time delivery rate indicates a reliable and punctual service, contributing to enhanced customer satisfaction. Monitoring this metric helps you identify and address any potential delays or inefficiencies in your delivery process.`,
  averageCycleTimeWorking: `Refers to the average duration it takes for your company to complete a manufacturing cycle, from order placement to order delivery. This includes processing, production, and delivery time. A shorter cycle time often indicates increased efficiency and quicker order fulfillment. Monitoring this metric allows you to streamline your production process and optimize resource allocation for faster turnaround times.`,
  rfqToQuote: `Represents the percentage of quote requests received from potential enquiries that your company successfully converted into formal quotes. This metric reflects your ability to promptly respond to inquiries and engage potential clients effectively. Monitoring this data helps you understand your quote conversion efficiency and identify opportunities to improve your sales and customer engagement processes.`,
  cycleTimeRating: `Refers to your company's performance in comparison to other Partners of similar verticals.`,
  averageSpeedTimeToQuote: `Refers to the average duration it takes for your company to respond to requests for quotations (RFQs). Factorem Partner win the most when they quote in <24hrs.`,
  speedToQuoteTimeRating: `Refers to your company's performance in comparison to other Partners of similar verticals.`,
  quoteToPO: `Refers to the number of items that a PO was generated per submitted quotation. A higher quote to PO rate represents better chance of suppliers quote getting accepted.`,
};

export const VIEW_PARTNER_DASHBOARD_CONSTANT = {
  active: {
    color: colors.solidBlue,
    tooltip: 'Partner Data Dashboard',
  },
  inactive: {
    color: colors.errorRed,
    tooltip: 'Partner Data Dashboard (INACTIVE)',
  },
  default: {
    color: colors.bgGrey,
    tooltip: 'No data dashboard',
  },
};

export const TAB_DASHBOARD_KEYS = {
  ALL_TIME: 'All Time',
  PAST_YEAR: 'Past Year',
};

export const TABS_DASHBOARD = [
  TAB_DASHBOARD_KEYS.ALL_TIME,
  TAB_DASHBOARD_KEYS.PAST_YEAR,
];
