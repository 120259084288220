import queryString from 'query-string';

import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

// -------------------------------------------------------------------------------------------------

export const getUnproceededPPELogs = async (params) => {
  let requestUrl = `${BACKEND_SERVICE_URL}/logs/instant-quotes`;
  if (params && typeof params === 'object') {
    requestUrl += `?${queryString.stringify(params)}`;
  }
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then((response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

let adminSearchPPELogsAbortController = new AbortController();
export const adminSearchPPELogsApi = async (params) => {
  if (adminSearchPPELogsAbortController) {
    // stop previous call if it's ongoing, only allow for latest call to update FE
    adminSearchPPELogsAbortController.abort();
  }

  adminSearchPPELogsAbortController = new AbortController();
  const signal = adminSearchPPELogsAbortController.signal;
  const requestUrl = `${BACKEND_SERVICE_URL}/logs/instant-quotes/search?${queryString.stringify(
    params
  )}`;

  const headers = getAuthorizedHeader();
  const requestOptions = {
    method: 'GET',
    headers,
    signal,
  };

  return fetchWithErrorHandling(requestUrl, requestOptions);
};

export const adminExportInstantLogAsCsv = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/logs/instant-quotes/search/csv-export?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    return response;
  });
};

export const updateUserIDForInstantLogs = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/logs/instant-quotes`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    return response;
  });
};
