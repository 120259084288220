import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { Close } from '@material-ui/icons';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';

import Title from '../Title';
import SubDesc from '../SubDesc';
import DetailRow from '../../pages/DetailRow';

import { getDateStrWithMonth } from '../../utils/dateTimeUtils';
import {
  getMaterialWithColorText,
  getSurfaceFinishWithCustomizationsText,
} from '../../utils/itemUtils';
import { convertPriceToCurrency } from '../../utils/currencyUtils';
import { getQuotationExchangeRate } from '../../utils/quotationUtils';

import { TECHNOLOGY_OPTION_TYPE } from '../../constants/NewPartConstants';
import { makeStyles } from '@material-ui/core/styles/index';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 400,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function SupplierQuoteDetailsDisplayPopup(props) {
  const classes = useStyles();

  const { open, rowData, onClose, currency, exchangeRate, user } = props;

  const { techTags } = user;
  const is3DPrintingSupplier =
    techTags &&
    techTags.includes(TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING.toLowerCase());

  const renderCMMPrice = (_rowData) => {
    const price = (_rowData.metadata && _rowData.metadata.cmmPrice) || 0;
    const content = convertPriceToCurrency({
      price: price,
      currency,
      exchangeRate:
        getQuotationExchangeRate(_rowData, currency) || exchangeRate,
    });
    return price > 0 && <DetailRow title='CMM Price' content={content} />;
  };

  const renderRowDetails = (_rowData) => {
    return (
      <div style={{ marginBottom: 15, marginTop: 15, lineHeight: 'normal' }}>
        <DetailRow
          id='acceptedQuoteID'
          title='Quote ID'
          content={_rowData.quotationID}
        />
        <DetailRow
          title='Date of Quote'
          content={
            _rowData.dateOfOrder
              ? getDateStrWithMonth(_rowData.dateOfOrder)
              : ''
          }
        />
        <DetailRow
          title='Customer remarks'
          content={_rowData.customerRemarks || 'N.A'}
        />
        <DetailRow title='Quantity' content={_rowData.quantity} />
        <DetailRow
          title='Lead time'
          content={`${_rowData.leadTime} working ${
            _rowData.leadTime === 1 ? 'day' : 'days'
          }`}
        />
        <DetailRow
          title='Material'
          content={getMaterialWithColorText(_rowData)}
        />
        <DetailRow
          title='Finishing'
          content={getSurfaceFinishWithCustomizationsText(_rowData)}
        />
        {renderCMMPrice(_rowData)}
        <DetailRow
          title='My remarks'
          content={`${_rowData.ppeQuoteRemarks || _rowData.remarks || 'N.A'}`}
        />
        {is3DPrintingSupplier && (
          <DetailRow
            title='3D Printing Technology'
            content={get(_rowData, 'metadata.threeDTechnology') || 'N.A'}
          />
        )}
        {is3DPrintingSupplier && (
          <DetailRow
            title='3D Printing Infill'
            content={
              get(_rowData, 'metadata.threeDInfill')
                ? `${(_rowData.metadata.threeDInfill * 100).toFixed(0)}%`
                : 'N.A'
            }
          />
        )}
        {is3DPrintingSupplier && (
          <DetailRow
            title='3D Layer Thickness'
            content={
              get(_rowData, 'metadata.threeDLayerThickness')
                ? `${get(_rowData, 'metadata.threeDLayerThickness')}mm`
                : 'N.A'
            }
          />
        )}
      </div>
    );
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='quote-details'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <SubDesc content={`Quotation ID: #${rowData?.quotationID}`} />
        <Title contentTitle='Quote Details'></Title>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            width: 'fit-content',
            margin: '0 auto',
          }}
        >
          {renderRowDetails(rowData)}
        </div>
      </DialogContent>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <Close />
      </IconButton>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    userId: state.auth.user.userID,
    currency: state.auth.location.currency,
    role: state.auth.user.role,
    exchangeRate: state.auth.rates[state.auth.location.currency],
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(SupplierQuoteDetailsDisplayPopup);
