import { capitalize, get } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogActions,
  DialogContent,
  TableCell as MuiTableCell,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  withStyles,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';

import FtrButton from '../ftr-components/FtrButton';
import FtrTypography, {
  FtrB2,
  FtrB3,
  FtrH3,
  FtrH6,
} from '../ftr-components/FtrTypography';
import ImageWith3DViewer from '../images/ImageWith3DViewer';
import InfoMatchValue from '../info/InfoMatchValue';
import { FlexColumnCenter, FlexRowCenter } from '../layouts/FlexLayouts';

import { RepeatOrderIcon, SimilarOrderIcon } from '../icons/ItemIcon';

import useItemAllQuotationsInfo from '../../hooks/useItemAllQuotationsInfoHook';
import useItemInfo from '../../hooks/useItemInfoHook';
import useQuotationInfo from '../../hooks/useQuotationInfoHook';
import useUserInfo from '../../hooks/useUserInfoHook';

import { getExchangeRatesSelector } from '../../selectors/exchangeRatesSelector';

import { isEmptyValue } from '../../utils/commonUtils';
import { convertPriceWithQuantityToCurrency } from '../../utils/currencyUtils';
import { formatDate } from '../../utils/dateTimeUtils';
import { getCadFileTo3DRenderer } from '../../utils/fileUtils';
import { getSurfaceFinishWithCustomizationsText } from '../../utils/itemUtils';
import { openInNewTab } from '../../utils/navigationUtils';
import {
  getBackgroundColorByQuoteStatus,
  selectQuotationForItem,
} from '../../utils/quotationUtils';
import { getToleranceDisplayText } from '../../utils/toleranceUtils';

import { colors } from '../../palette';

import { CURRENCY_CODE } from '../../constants/currencyConstants';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  closeIcon: {
    position: 'absolute',
    right: '0.5rem',
    top: '0.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

const ColumnHeader = withStyles({
  root: {
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '0.5rem 2rem',
  },
})(MuiTableCell);

const RowCell = withStyles({
  root: {
    padding: '0.3rem 2rem',
  },
})(MuiTableCell);

export const ITEM_PPE_TYPE = {
  REPEAT: 'repeat',
  SIMILAR: 'similar',
};

function RepeatSimilarOrderDetailsPopup(props) {
  const classes = useStyles();

  const {
    open,
    onClose = () => {},
    itemID,
    requestItem = {},
    matchedItemID,
    refQuoteID,
    type,
    rocketQuoteData = false,
  } = props;

  const history = useHistory();

  const currentExchangeRates = useSelector(getExchangeRatesSelector);

  const { data: item } = useItemInfo(itemID);
  const { data: allItemQuotations } = useItemAllQuotationsInfo(itemID);

  const { data: customer } = useUserInfo(item?.userID);
  const { currency: customerCurrency } = customer || {};

  const { data: matchedItem } = useItemInfo(matchedItemID);
  const { data: matchedQuotation } = useQuotationInfo(refQuoteID);

  const { data: matchedCustomer } = useUserInfo(matchedItem?.userID);

  const tableData = useMemo(() => {
    const quotation = selectQuotationForItem(allItemQuotations);

    return [
      {
        ...(item || requestItem || {}),
        customer,
        quotation: quotation || requestItem?.quotation,
      },
      {
        ...(matchedItem || {}),
        customer: matchedCustomer,
        quotation: matchedQuotation,
      },
    ];
  }, [
    item?.itemID,
    requestItem,
    matchedItem?.itemID,
    customer?.userID,
    matchedCustomer?.userID,
    matchedQuotation?.quotationID,
    allItemQuotations,
  ]);

  const DISPLAY_ROWS = [
    {
      label: 'Item Image',
      render: (_item) => {
        const cadFile = getCadFileTo3DRenderer(
          _item?.cadFile || _item.originalFiles
        );
        const imageUrl = _item.imageFile || _item.twoDImageUrl;

        return (
          <FlexColumnCenter>
            <ImageWith3DViewer
              key={_item.itemID}
              cadFile={cadFile}
              twoDImageUrl={imageUrl}
              width={90}
              height={90}
              noBorder
              isLoading={_item.imageStatus === 'loading'}
              borderRadius={8}
            />
          </FlexColumnCenter>
        );
      },
    },
    {
      label: 'Item Name',
      render: (_item) => {
        return <FtrH3 style={{ fontWeight: 600 }}>{_item?.name}</FtrH3>;
      },
    },
    {
      label: 'Customer Name',
      render: (_item) => {
        const _customer = get(_item, 'customer');
        if (isEmptyValue(_customer)) {
          return;
        }

        const companyName = _customer.cCompanyName || _customer.companyName;

        return (
          <FlexColumnCenter style={{ gap: 0 }}>
            <FtrH3 style={{ fontWeight: 600 }}>
              {_customer.name} ({_customer.userID})
            </FtrH3>
            <FtrB2>{companyName}</FtrB2>
          </FlexColumnCenter>
        );
      },
    },
    {
      label: 'Date Uploaded',
      render: (_item) => {
        // if from rocket quote, this data is irrelevant, because item hasn't been uploaded yet
        if (rocketQuoteData && _item?.itemID !== matchedItem?.itemID) {
          return <FtrB3>N.A.</FtrB3>;
        }

        return (
          <FtrH3 style={{ fontWeight: 600 }}>
            {formatDate(_item.datePosted, 'DD-MM-YYYY')}
          </FtrH3>
        );
      },
    },
    {
      label: 'Quote Status',
      render: (_item) => {
        // if item is not found, or if it's from rocket quote, show N/A cos actually there's no quote to compare
        const irrelevantInformation =
          isEmptyValue(_item.itemID) ||
          (rocketQuoteData && _item?.itemID !== matchedItem?.itemID);

        if (irrelevantInformation) {
          return <FtrB3>N.A.</FtrB3>;
        }

        const quotation = get(_item, 'quotation');

        if (isEmptyValue(quotation) || isEmptyValue(quotation.status)) {
          return <FtrH3>Sourcing</FtrH3>;
        }

        const bgColor = getBackgroundColorByQuoteStatus(quotation.status);

        return (
          <FlexColumnCenter>
            <FtrH3
              style={{
                backgroundColor: bgColor,
                width: 150,
                textTransform: 'uppercase',
                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
            >
              {quotation.status?.split('_')?.join(' ')}
            </FtrH3>
          </FlexColumnCenter>
        );
      },
    },
    {
      label: 'Quote ID',
      render: (_item) => {
        const isRocketQuotePrice =
          rocketQuoteData?.price && _item?.itemID !== matchedItem?.itemID;
        const quotation = get(_item, 'quotation');

        if (
          isRocketQuotePrice ||
          isEmptyValue(quotation) ||
          isEmptyValue(quotation.quotationID)
        ) {
          return <FtrB3>N.A.</FtrB3>;
        }

        const quotationID = `#${quotation?.quotationID}`;

        const urlForQuoteID = `/order/edit/${quotation?.quotationID}`;
        return (
          <Tooltip title='Hold Ctrl/Cmd and click to open in new tab'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (e.ctrlKey || e.metaKey) {
                  openInNewTab(urlForQuoteID);
                  return;
                }

                history.push(urlForQuoteID);
              }}
              to={urlForQuoteID}
            >
              <FtrH3 style={{ fontWeight: 600, color: colors.primary }}>
                {quotationID}
              </FtrH3>
            </Link>
          </Tooltip>
        );
      },
    },
    {
      label: 'Customer Price',
      render: (_item) => {
        const isRocketQuotePrice =
          rocketQuoteData?.price && _item?.itemID !== matchedItem?.itemID;

        const quotation = get(_item, 'quotation');

        const exchangeRates =
          get(quotation, [
            'exchangeRates',
            'exchangeRates',
            'exchange_rates',
          ]) || currentExchangeRates;

        const currency = customerCurrency || CURRENCY_CODE.SGD;

        const exchangeRate = get(exchangeRates, currency) || 1;

        if (
          (isEmptyValue(quotation) ||
            isEmptyValue(quotation.totalPrice) ||
            isNaN(Number(quotation.totalPrice))) &&
          !isRocketQuotePrice
        ) {
          return <FtrB3>N.A.</FtrB3>;
        }

        const totalPrice = isRocketQuotePrice
          ? rocketQuoteData?.price
          : quotation.totalPrice;
        const quantity = isRocketQuotePrice
          ? rocketQuoteData?.quantity
          : quotation.quantity;

        const { unitPriceStr, totalPriceStr } =
          convertPriceWithQuantityToCurrency({
            totalPrice,
            currency,
            exchangeRate,
            quantity,
          });

        return (
          <FlexColumnCenter style={{ gap: 0 }}>
            <FtrH3 style={{ fontWeight: 600, color: colors.primary }}>
              {totalPriceStr}
            </FtrH3>
            <FtrB2 style={{ fontWeight: 400, color: colors.primary }}>
              {unitPriceStr} ea
            </FtrB2>
          </FlexColumnCenter>
        );
      },
    },
    {
      label: 'Quantity',
      valueGetter: (_item) => {
        if (
          rocketQuoteData?.quantity &&
          _item?.itemID !== matchedItem?.itemID
        ) {
          return rocketQuoteData?.quantity;
        }

        const quotation = get(_item, 'quotation');

        const quantity = quotation?.quantity || _item?.quantity;

        return quantity;
      },
      render: (_item) => {
        if (
          rocketQuoteData?.quantity &&
          _item?.itemID !== matchedItem?.itemID
        ) {
          return (
            <FtrH3 style={{ fontWeight: 600 }}>
              {rocketQuoteData?.quantity}
            </FtrH3>
          );
        }

        const quotation = get(_item, 'quotation');

        const quantity = quotation?.quantity || _item?.quantity;

        return <FtrH3 style={{ fontWeight: 600 }}>{quantity}</FtrH3>;
      },
      check: true,
    },
    {
      label: 'Technology',
      valueGetter: (_item) => _item.technology,
      render: (_item) => {
        return <FtrB3>{_item.technology}</FtrB3>;
      },
      check: true,
    },
    {
      label: 'Material',
      valueGetter: (_item) => {
        if (
          rocketQuoteData?.material &&
          _item?.itemID !== matchedItem?.itemID
        ) {
          return rocketQuoteData?.material;
        }

        const quotation = get(_item, 'quotation');

        const material = quotation?.material || _item?.material;

        return material;
      },
      render: (_item) => {
        if (
          rocketQuoteData?.material &&
          _item?.itemID !== matchedItem?.itemID
        ) {
          return <FtrB3>{rocketQuoteData?.material}</FtrB3>;
        }

        const quotation = get(_item, 'quotation');

        const material = quotation?.material || _item?.material;

        return <FtrB3>{material}</FtrB3>;
      },
      check: true,
    },
    {
      label: 'Finishing',
      valueGetter: (_item) => {
        if (
          rocketQuoteData?.surfaceFinish &&
          _item?.itemID !== matchedItem?.itemID
        ) {
          return rocketQuoteData?.surfaceFinish;
        }

        const quotation = get(_item, 'quotation');

        const finish = getSurfaceFinishWithCustomizationsText(
          quotation || _item
        );

        return finish;
      },
      render: (_item) => {
        const quotation = get(_item, 'quotation');
        const object = quotation || _item;
        const finish = getSurfaceFinishWithCustomizationsText(object);

        if (
          rocketQuoteData?.surfaceFinish &&
          _item?.itemID !== matchedItem?.itemID
        ) {
          return (
            <FtrB3>
              {rocketQuoteData.surfaceFinish === object.surfaceFinish
                ? finish
                : rocketQuoteData.surfaceFinish}
            </FtrB3>
          );
        }

        return <FtrB3>{finish}</FtrB3>;
      },
      check: true,
    },
    {
      label: 'Tolerance',
      valueGetter: (_item) => {
        if (isEmptyValue(_item.tolerance)) {
          return 'N.A.';
        }

        const tolerance = getToleranceDisplayText({
          tolerance: _item.tolerance,
          unitType: _item.unitType,
        });

        return tolerance;
      },
      render: (_item) => {
        if (isEmptyValue(_item.tolerance)) {
          return 'N.A.';
        }

        const tolerance = getToleranceDisplayText({
          tolerance: _item.tolerance,
          unitType: _item.unitType,
        });

        return <FtrB3>{tolerance}</FtrB3>;
      },
      check: true,
    },
    {
      label: 'Supplier Name',
      valueGetter: (_item) => {
        const quotation = get(_item, 'quotation');

        const text =
          isEmptyValue(quotation) || isEmptyValue(quotation.userID)
            ? 'N.A.'
            : `${quotation?.supplierName} (${quotation?.userID})`;

        return text;
      },
      render: (_item) => {
        const quotation = get(_item, 'quotation');

        const text =
          isEmptyValue(quotation) || isEmptyValue(quotation.userID)
            ? 'N.A.'
            : `${quotation?.supplierName} (${quotation?.userID})`;

        return <FtrB3>{text}</FtrB3>;
      },
      check: true,
    },
    {
      label: 'PPE CAD Hash',
      valueGetter: (_item) => _item.itemHashcodeCad,
      render: (_item) => {
        const text = _item.itemHashcodeCad || 'N.A.';

        return <FtrB3>{text}</FtrB3>;
      },
      check: true,
    },
    {
      label: 'PPE PDF Hash',
      valueGetter: (_item) => _item.itemHashcodePdf,
      render: (_item) => {
        const text = _item.itemHashcodePdf || 'N.A.';

        return <FtrB3>{text}</FtrB3>;
      },
      check: true,
    },
  ];

  const title =
    type === ITEM_PPE_TYPE.REPEAT
      ? 'Repeat Order Details'
      : 'Similar Order Details';

  const icon =
    type === ITEM_PPE_TYPE.REPEAT ? <RepeatOrderIcon /> : <SimilarOrderIcon />;

  return (
    <Dialog
      open={open}
      width='md'
      maxWidth='xl'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <Close className={classes.closeIcon} onClick={onClose} />
      <DialogContent className={classes.dialogContent}>
        <FlexRowCenter style={{ padding: '0.8rem' }}>
          {icon}
          <FtrH6>{title}</FtrH6>
        </FlexRowCenter>
        <TableContainer component={Paper} style={{ borderRadius: '16px' }}>
          <Table
            size='small'
            style={{ borderColor: `1px solid ${colors.neutral030}` }}
          >
            <TableHead style={{ backgroundColor: colors.neutral030 }}>
              <TableRow>
                <ColumnHeader
                  style={{ textAlign: 'left', paddingLeft: '1rem' }}
                >
                  <FtrH3 style={{ fontWeight: 400 }}>Item ID</FtrH3>
                </ColumnHeader>
                {renderHeaders()}
                <ColumnHeader />
              </TableRow>
            </TableHead>
            <TableBody>{DISPLAY_ROWS?.map((row) => renderRow(row))}</TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      {renderDialogActions()}
    </Dialog>
  );

  function renderHeaders() {
    return tableData?.map((_item) => {
      return (
        <ColumnHeader align='center' key={_item?.itemID}>
          <FlexColumnCenter>{renderItemIDLink(_item?.itemID)}</FlexColumnCenter>
          {!isEmptyValue(_item?.itemID) && _item?.itemID !== itemID && (
            <FtrB2>{`${capitalize(type)} Matched`}</FtrB2>
          )}
        </ColumnHeader>
      );
    });
  }

  function renderItemIDLink(_itemID) {
    if (isEmptyValue(_itemID)) {
      return <FtrB3>N.A.</FtrB3>;
    }

    const urlForItemID = `/item/edit/${_itemID}`;
    return (
      <Tooltip title='Hold Ctrl/Cmd and click to open in new tab'>
        <Link
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (e.ctrlKey || e.metaKey) {
              openInNewTab(urlForItemID);
              return;
            }

            history.push(urlForItemID);
          }}
          to={urlForItemID}
        >
          <FtrTypography type='subHeading' fontSize='16'>
            {_itemID}
          </FtrTypography>
        </Link>
      </Tooltip>
    );
  }

  function renderRow(row) {
    return (
      <TableRow>
        <TableCell>
          <FtrH3 style={{ fontWeight: 400 }}>{row.label}</FtrH3>
        </TableCell>
        {tableData?.map((_item) => {
          return (
            <RowCell align='center' key={`${itemID}-${row.label}`}>
              {row.render(_item)}
            </RowCell>
          );
        })}
        {!row.check && <RowCell />}
        {row.check && (
          <RowCell>
            <InfoMatchValue
              name={row.label}
              valueCustomer={row.valueGetter(tableData[0])}
              valueSupplier={row.valueGetter(tableData[1])}
            />
          </RowCell>
        )}
      </TableRow>
    );
  }

  function renderDialogActions() {
    return (
      <DialogActions style={{ padding: '1rem 1.5rem' }}>
        <FtrButton color='blue' variant='contained' onClick={onClose}>
          OK
        </FtrButton>
      </DialogActions>
    );
  }
}

export default RepeatSimilarOrderDetailsPopup;
