import React, { useEffect, useState } from 'react';

import BlueButton from '../buttons/BlueButton';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';
import Decimal from 'decimal.js';

import Title from '../Title';
import { FtrTextField, FtrDropdown, FtrTypography } from '../ftr-components';
import { FlexRow } from '../layouts/FlexLayouts';
import FtrFieldLabel from '../ftr-components/FtrFieldLabel';

import InfoIcon from '../../assets/icons/info_icon.svg';

import { CURRENCY_SYMBOL_MAPPING } from '../../constants/currencyConstants';

import { extractPriceFromCurrencyString } from '../../utils/currencyUtils';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

/**
 *
 * @param {Object} props
 * @param {Boolean} props.open
 * @param {() => void} props.onClose
 * @param {() => void} props.onSubmit
 * @param {String} props.creditNote
 * @param {String} props.currency
 * @returns
 */
function CreditNotePopup(props) {
  const { open, onClose, onSubmit } = props;

  const classes = useStyles();
  const [creditNoteValue, setCreditNoteValue] = useState('');
  const [currency, setCurrency] = useState(
    CURRENCY_SYMBOL_MAPPING[props?.currency] ?? null
  );
  const [additionalNote, setAdditionalNote] = useState(
    'within the next seven days'
  );

  useEffect(() => {
    if (props?.creditNote) {
      const [currencyValue] = props.creditNote?.split(' ') || [];
      const _creditNoteValue = extractPriceFromCurrencyString(props.creditNote);
      setCreditNoteValue(_creditNoteValue);
      setCurrency(currencyValue);
      return;
    }
    if (props?.currency) {
      setCurrency(CURRENCY_SYMBOL_MAPPING[props?.currency]);
    }
  }, [props?.currency, props?.creditNote]);

  const updateHandler = () => {
    if (typeof onSubmit === 'function') {
      const creditNote = new Decimal(creditNoteValue || 0).toFixed(2);
      onSubmit({
        creditNote: `${currency} ${creditNote}`,
        additionalNote,
      });
      onClose();
    }
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='warning-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='warning-dialog-title'>
        <Title contentTitle='Request Credit Note from Supplier' size='small' />
      </DialogTitle>
      <DialogContent>
        <FtrTypography>
          Please key in the total credit note amount and currency to proceed
        </FtrTypography>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 5fr',
            gap: '0.5rem',
            padding: '1rem 0 0',
          }}
        >
          <div>
            <FlexRow>
              <FtrFieldLabel>Currency</FtrFieldLabel>
              <Tooltip
                title={`Please set currency in the same value as supplier’s currency.${props?.currency ? `The supplier currency is ${CURRENCY_SYMBOL_MAPPING[props?.currency]}` : ''}`}
                placement='right'
              >
                <img src={InfoIcon} alt='info-icon' />
              </Tooltip>
            </FlexRow>

            <FtrDropdown
              items={Object.values(CURRENCY_SYMBOL_MAPPING)}
              title='Currency'
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              style={{ width: '100px' }}
            />
          </div>
          <div>
            <FtrFieldLabel>Credit Note</FtrFieldLabel>
            <FtrTextField
              type='number'
              key={'credit-note-amount'}
              id={'credit-note-amount'}
              value={creditNoteValue}
              onChange={(e) => setCreditNoteValue(e.target.value)}
            />
          </div>
        </div>
        <div>
          <FtrFieldLabel>Additional Note</FtrFieldLabel>
          <FtrTextField
            key={'additional-note'}
            id={'additional-note'}
            value={additionalNote}
            onChange={(e) => setAdditionalNote(e.target.value)}
          />
        </div>
      </DialogContent>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '1rem',
        }}
      >
        <BlueButton onBtnClick={updateHandler} btnContent='Update and Send' />
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default CreditNotePopup;
