import React from 'react';
import { Link } from 'react-router-dom';

import useDeviceCheck from '../../hooks/useDeviceCheck';

import { openInNewTab } from '../../utils/navigationUtils';
import { downloadS3File } from '../../utils/fileUtils';

import { generatePresignedUrl } from '../../services/s3Service';

import { FIVE_MINUTES_IN_SECOND } from '../../constants/dateTimeConstants';

// -------------------------------------------------------------------------------------------------

/**
 * A react component that renders a clickable link which opens the given link if on a desktop, or downloads the file if on a mobile device.
 *
 * @param {Object} props
 * @param {string} props.url the url to open/download
 * @returns {React.ReactElement}
 */
function LinkPreview(props) {
  const { url } = props;
  const [{ isIPad, isMobile, isTablet }] = useDeviceCheck();

  return (
    <Link
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!isMobile && !isTablet && !isIPad) {
          generatePresignedUrl(url, FIVE_MINUTES_IN_SECOND, true).then(
            openInNewTab
          );
        } else {
          downloadS3File(url);
        }
      }}
    >
      Link
    </Link>
  );
}

export default LinkPreview;
