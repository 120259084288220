import * as ppeApi from '../apis/ppeApi';
import { getSupportedFileTypesWithCache } from '../apis/configurationApi';

import { getPpeMaterialCode } from '../utils/ppeUtils';
import {
  getItemMaterial,
  getItemSurfaceFinish,
  is3DPTechnology,
} from '../utils/itemUtils';

import {
  surfaceFinishMapping,
  techMapping,
  toleranceMapping,
} from '../constants/PPEConstants';
import { isCustomTechnology } from '../utils/inputUtils';

import { TWO_D_IMAGE_URLS_KEY } from '../constants';

// Return a customer price for one item
export const generatePPEPrice = async (item, userAgentInfo = null) => {
  const orderAgain = item.orderAgain === true;
  // Find the first valid 3D design file
  let file_location = null;
  let fileSize = 0;

  const { fileLocation: newFileLocation, fileSize: newFileSize } =
    item.onlySingleFile
      ? await geSingleFile()
      : {
          fileLocation: item.cadPart?.join(','),
          fileSize: item.fileSize,
        };

  file_location = newFileLocation;
  fileSize = newFileSize;

  async function geSingleFile() {
    const { supportFileTypes } = await getSupportedFileTypesWithCache({
      allSupportedTypes: true,
    });
    let _newFileSize = 0;
    let _pdfFileSize = 0;
    let _newFileLocation = '';
    let newPdfFileLocation = '';
    for (const partObj of item.cadPartObjectList) {
      const fileExtension = partObj.link?.split('.').pop().toLowerCase();
      if (fileExtension === 'pdf') {
        _pdfFileSize = partObj.fileSize;
        newPdfFileLocation = partObj.link;
      }
      if (
        supportFileTypes.includes(fileExtension) &&
        _newFileSize < partObj.fileSize &&
        fileExtension !== 'pdf'
      ) {
        _newFileSize = partObj.fileSize;
        _newFileLocation = partObj.link;
      }
    }
    if (_newFileSize === 0 && _pdfFileSize) {
      _newFileSize = _pdfFileSize;
      _newFileLocation = newPdfFileLocation;
    }
    return {
      fileSize: _newFileSize,
      fileLocation: _newFileLocation,
    };
  }

  let { technology, otherTechnology } = item;
  if (isCustomTechnology(technology)) {
    technology = otherTechnology;
  }
  const material = getItemMaterial(item);
  const surfaceFinish = getItemSurfaceFinish(item);
  const materialCode = getPpeMaterialCode({ technology, material });
  const tolerance = item.tolerance;
  const toleranceCheck = toleranceMapping(Number(tolerance));
  const parameters = {
    file_location,
    material: materialCode || material,
    finish: surfaceFinishMapping[surfaceFinish] || surfaceFinish,
    color: item.materialColor,
    tolerance,
    toleranceCheck,
    quantity: Number(item.qty),
    fileSize,
    itemID: item.itemID,
    specialThreads: item.specialThreads,
    gdntStatus: item.gdntStatus,
    gdntDetected: item.gdntDetected,
    tdeTolerance: item.tdeTolerance,
    repeatPdf: item.repeatPdf,
  };
  if (is3DPTechnology(technology)) {
    parameters.threeDTechnology =
      item.metadata?.threeDTechnology || item.threeDTechnology;
    parameters.threeDInfill = item.metadata?.threeDInfill || item.threeDInfill;
    parameters.threeDLayerThickness =
      item.metadata?.threeDLayerThickness || item.threeDLayerThickness;
  }

  let payload = {
    id: item.id,
    process: techMapping[technology] || technology,
    parameters,
    orderAgain,
    userAgentInfo,
    asCustomer: true,
    imageUrl:
      (item[TWO_D_IMAGE_URLS_KEY] ? item[TWO_D_IMAGE_URLS_KEY][0] : null) ||
      item.imageFile ||
      item.twoDImageUrl,
    imageConvertingError: item.imageConvertingError,
    rocketQuote: item.rocketQuote || false,
  };

  return ppeApi.getPpePriceForItemWithCache(payload);
};
