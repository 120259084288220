import { extractTechnicalDrawing } from '../../apis/technicalDrawingExtractionApi';
import {
  getDefaultAnodizingTypeWithCache,
  getFeatureFlags,
  getMaterialColorOptionsWithCache,
  getSurfaceFinishColorOptionsWithCache,
} from '../../apis/configurationApi';

import { isAdminOrHigherRole } from '../../utils/roleUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { isAnodizingSurfaceFinish } from '../../utils/inputUtils';
import {
  convertImperialToMetric,
  convertMetricToImperial,
} from '../../utils/userUtils';
import {
  fetchFinishOptionsForTDE,
  fetchMaterialOptionsForTDE,
  isNotFound,
} from '../../utils/tdeUtils';

import { getCookie } from '../../services/cookiesService';

import { techMapping } from '../../constants/PPEConstants';

import { USER_AGENT_INFO_KEY } from '../../constants';
import { FE_FEATURE_FLAGS_CONFIGURATION } from '../../constants/configurations';
import { TECHNOLOGY_OPTION_TYPE } from '../../constants/NewPartConstants';
import { TDE_TYPE } from '../../constants/userConstant';
import { VISIBLE_FIELDS } from '../../constants/technicalDrawingExtractorConstants';
import { TDE_LOCATIONS } from '../../constants/technicalDrawingExtractorConstants';
import { UNIT_TYPES } from '../../constants/unitConstants';
import { ANODIZING_TYPE_OPTIONS } from '../../constants/itemConstants';
import { isPdfFile } from '../../utils/fileUtils';

// -------------------------------------------------------------------------------

const prepareOriginalTdeInformation = (response) => {
  // store the original extracted information and store it in the TDE mongo db
  let {
    material: original_material,
    finish: original_finish,
    matched_unit_type: original_unit_type,
    matched_material: original_matched_material,
    matched_finish: original_matched_finish,
    quantity: original_quantity,
    min_tolerance: original_tolerance,
    color: original_color,
    matched_metric_tolerance: original_metric_tolerance,
  } = response;

  return {
    original_input: {
      original_material,
      original_matched_material,
      original_finish,
      original_matched_finish,
      original_color,
      original_quantity,
      original_tolerance,
      original_metric_tolerance,
      original_unit_type,
    },
  };
};

export const extractTdeForItem = async ({
  part = {},
  user = {},
  pdfUrl = '',
}) => {
  const { id } = part;

  const featureFlags = await getFeatureFlags();
  const isGlobalTdeOff =
    !featureFlags?.config?.[FE_FEATURE_FLAGS_CONFIGURATION.GLOBAL_TDE_SWITCH];

  const customerNotHasTdeType = user?.tdeType !== TDE_TYPE.HAS_TDE;

  const {
    cadPart: uploadedUrls = [],
    threeDTechnology,
    color,
    materialColor,
  } = part ?? {};

  let { technology } = part;

  if (isEmptyValue(technology)) {
    technology = TECHNOLOGY_OPTION_TYPE.CNC_MACHINING;
  }

  if (isEmptyValue(pdfUrl)) {
    const reverseOrderUrls = uploadedUrls?.slice().reverse();
    pdfUrl = reverseOrderUrls?.find((url) => isPdfFile(url));
  }

  const failedTdeSafeguard = isEmptyValue(user)
    ? customerNotHasTdeType || isGlobalTdeOff || !pdfUrl
    : isGlobalTdeOff || !pdfUrl;
  if (failedTdeSafeguard) {
    return {
      id,
      tdeStatus: 'error',
      generatedFields: [],
    };
  }

  const isKeyAccount = user?.keyAccount === 1;
  const userAgentInfo = getCookie(USER_AGENT_INFO_KEY);

  const payload = {
    pdfUrl: pdfUrl,
    isKeyAccount,
    rom_switch: true,
    tdeLocation: isAdminOrHigherRole(user?.role)
      ? TDE_LOCATIONS.ADMIN_PLATFORM_CREATE_PROJECT_FLOW
      : TDE_LOCATIONS.CUSTOMER_PLATFORM_STEP_2_PART_UPLOAD,
    userAgentInfo,
    userID: user.userID,
    tech: techMapping[technology],
  };

  const [response, materialOptions] = await Promise.all([
    extractTechnicalDrawing(payload).catch(() => null),
    fetchMaterialOptionsForTDE(technology),
  ]);

  if (!response) {
    return {
      id,
      tdeStatus: 'error',
      generatedFields: [],
    };
  }

  let {
    matched_finish_status: matchedFinishStatus,
    matched_material_status: matchedMaterialStatus,
    matched_material: material,
    matched_finish: surfaceFinish,
    quantity: qty,
    matched_unit_type: measurementUnit,
    min_tolerance: tolerance,
    color: matchedColor,
    anodizing_type: anodizingType,
    special_threads,
    pdf_hash,
    gdnt_status,
    gdnt_detected,
    repeat_pdf: repeatPdf,
    standard,
    tolerance_standard,
  } = response;

  const _toleranceStandard = isNotFound(tolerance_standard)
    ? null
    : tolerance_standard;
  const _standard = isNotFound(standard) ? null : standard;
  const toleranceStandard = _toleranceStandard || _standard;

  const newCadPart = {
    id,
    tdeLogID: response?.tdeLogID,
  };

  if (!isNotFound(qty) && !isNaN(Number(qty))) {
    newCadPart.qty = Number(qty);
  }

  let { unitType } = part;
  if (!isNotFound(measurementUnit)) {
    unitType =
      measurementUnit === 'Metric' ? UNIT_TYPES.METRIC : UNIT_TYPES.IMPERIAL;
    newCadPart.unitType = unitType;
  }

  // Calculate and set tolerance
  if (!isNotFound(tolerance)) {
    // if it is not found, default behaviour
    if (isNotFound(measurementUnit)) {
      const {
        min_tolerance,
        matched_metric_tolerance,
        matched_imperial_tolerance,
      } = response;

      // The min_tolerance value is a string, and represented in the unit based on the drawing.
      // We convert it to a number for comparison purposes.
      // The condition below ensures that we use the matching tolerance when it equals min_tolerance.
      // If no match is found, min_tolerance will be used as the default value.
      // The newCadPart.tolerance is always in mm
      const parsedMinTolerance = parseFloat(min_tolerance.match(/[\d.]+/)[0]); // we parse this first, in case the min_tolerance has unit (e.g 0.1mm)
      if (unitType === UNIT_TYPES.METRIC) {
        newCadPart.tolerance =
          matched_metric_tolerance === +parsedMinTolerance
            ? matched_metric_tolerance
            : parsedMinTolerance;
      } else {
        newCadPart.tolerance =
          convertImperialToMetric(matched_imperial_tolerance) ===
          +parsedMinTolerance
            ? convertImperialToMetric(matched_imperial_tolerance)
            : convertMetricToImperial(+parsedMinTolerance);
      }
    } else {
      newCadPart.tolerance = response.matched_metric_tolerance;
    }
    newCadPart.tdeTolerance = newCadPart.tolerance;
    newCadPart.repeatPdf = repeatPdf;
  }

  const defaultAnodizingType = await getDefaultAnodizingTypeWithCache();
  newCadPart.anodizingType =
    defaultAnodizingType || ANODIZING_TYPE_OPTIONS[0].key; // default to what's store in the DB, or if not found -> 'glossy'

  if (!isNotFound(material)) {
    // Handle custom material if not found in material options
    if (!materialOptions.includes(material) && matchedMaterialStatus) {
      newCadPart.otherMaterial = material;
      material = 'Custom Material';
    }

    // Fetch finish options based on selected material
    const finishResponse = await fetchFinishOptionsForTDE(technology, material);

    // Handle custom finish if not found in finish options
    if (!isNotFound(surfaceFinish) && matchedFinishStatus) {
      if (!finishResponse.options.includes(surfaceFinish)) {
        newCadPart.otherSurfaceFinish = !isNotFound(matchedColor)
          ? `${matchedColor} ${surfaceFinish}`
          : surfaceFinish;

        surfaceFinish = 'Custom Finish';
      }
    } else {
      surfaceFinish = finishResponse.default;
      newCadPart.otherSurfaceFinish = 'NIL';
    }

    const finishColors = await getSurfaceFinishColorOptionsWithCache(
      {
        technology,
        surfaceFinish,
      },
      {
        withAbort: false,
      }
    ).catch(() => {});

    const materialColors = await getMaterialColorOptionsWithCache({
      technology,
      threeDTechnology,
      material,
    }).catch(() => {});

    newCadPart.materialColor = materialColor || undefined;
    if (materialColors && materialColors.options) {
      const possibleMaterialColors = Object.keys(materialColors.options);
      newCadPart.materialColor = materialColors.default;
      newCadPart.customMaterialColor = undefined;
      if (possibleMaterialColors.includes(matchedColor)) {
        newCadPart.materialColor = matchedColor;
      } else if (
        !isNotFound(matchedColor) &&
        possibleMaterialColors.includes('Custom Color')
      ) {
        newCadPart.materialColor = 'Custom Color';
        newCadPart.customMaterialColor = matchedColor;
      }
    } else {
      newCadPart.materialColor = undefined;
      newCadPart.customMaterialColor = undefined;
    }

    newCadPart.color = color || undefined;
    if (finishColors && finishColors.options) {
      const possibleFinishColors = Object.keys(finishColors.options);
      newCadPart.color = finishColors.default;
      newCadPart.customColor = undefined;
      if (possibleFinishColors.includes(matchedColor)) {
        newCadPart.color = matchedColor;
      } else if (
        !isNotFound(matchedColor) &&
        possibleFinishColors.includes('Custom Color')
      ) {
        newCadPart.color = 'Custom Color';
        newCadPart.customColor = matchedColor;
      }
    } else {
      newCadPart.color = undefined;
      newCadPart.customColor = undefined;
    }
    // Prepare new CAD part object
    newCadPart.material = material;
    newCadPart.surfaceFinish = surfaceFinish;

    if (
      isAnodizingSurfaceFinish(surfaceFinish) &&
      !isNotFound(anodizingType) &&
      !isEmptyValue(anodizingType)
    ) {
      newCadPart.anodizingType = anodizingType;
    }
  }

  // check if the standard tolerance is found
  if (!isNotFound(toleranceStandard)) {
    newCadPart.toleranceStandard = toleranceStandard;
  }

  const validKeys = new Set(
    Object.keys(newCadPart)?.filter((key) => newCadPart[key] !== undefined)
  );
  validKeys.delete('id');
  validKeys.delete('tdeLogID');
  let visible_field_check = false;
  // check if there are any overlap between valid keys and VISIBLE_FIELDS
  if (validKeys.size > 0) {
    for (const field of VISIBLE_FIELDS) {
      if (validKeys.has(field)) {
        visible_field_check = true;
      }
    }
  }
  newCadPart.generatedFields = Array.from(validKeys);
  if (visible_field_check) {
    newCadPart.tdeStatus = 'success';
  } else {
    newCadPart.tdeStatus = 'error';
  }
  newCadPart.id = id;

  // check if the thread is found
  if (!isEmptyValue(special_threads) && !isNotFound(special_threads)) {
    newCadPart.specialThreads = special_threads;
  }

  newCadPart.gdntStatus = gdnt_status;
  newCadPart.gdntDetected = gdnt_detected;

  newCadPart.originalPayload = prepareOriginalTdeInformation(response);
  newCadPart.pdfHash = pdf_hash;

  return newCadPart;
};
