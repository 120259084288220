import React, { useReducer } from 'react';

import { isEmptyValue } from '../utils/commonUtils';

import ConvertPpeToRfqReasonPopup from '../components/popups/ConvertPpeToRfqReasonPopup';

// ---------------------------------------------------------------------------------

function withConvertPpeToRfqReasonPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
      onSubmit: () => {},
      onSuccess: () => {},
      itemID: null,
      isSubmitted: false,
      reasons: [],
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          updateConvertPpeToRfqReasonPopupHOCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <ConvertPpeToRfqReasonPopup
            onClose={() => updateLocalState({ open: false })}
            {...localState}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withConvertPpeToRfqReasonPopupHOC;
