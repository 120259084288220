import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { get, isEmpty } from 'lodash';
import Decimal from 'decimal.js';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import * as formActions from '../../actions/projectBulkQuotationsForm';

import {
  getItemImageUrl,
  getMaterialWithColorText,
  getSurfaceFinishWithCustomizationsText,
} from '../../utils/itemUtils';
import { showUnitValueFromMetric } from '../../utils/userUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { isSupplierRole } from '../../utils/roleUtils';
import { convertPriceWithQuantityToCurrency } from '../../utils/currencyUtils';
import {
  combineDataFromItemAndQuotation,
  getParamDifferencesString,
} from '../../utils/supplierRepeatItemUtils';
import { convertCm3ToMm3 } from '../../utils/unitUtils';

import CornerRibbon from '../decorations/CornerRibbon';
import DownloadAllFilesButton from '../buttons/DownloadAllFilesButton';
import FileDownloadListDisplay from '../labels/FileDownloadListDisplay';
import KeyValueInfoDisplay from '../labels/KeyValueInfoDisplay';
import ProjectItemQuotationForm from '../forms/ProjectItemQuotationForm';
import { WatchingJob } from '../WatchingJob';
import { FlexRow } from '../layouts/FlexLayouts';
import InfoIcon from '../icons/InfoIcon';
import {
  RepeatOrderIcon,
  RepeatOrderWithExclamationIcon,
} from '../icons/ItemIcon';
import { FtrCaption } from '../ftr-components';
import ImageWith3DViewer from '../images/ImageWith3DViewer';

import { useQueryParams } from '../../hooks/useQueryParamsHook';
import useScrollToHash from '../../hooks/useScrollToHash';

import { WATCHING_JOB_STATUS } from '../../constants/watchingJob';
import {
  TECHNOLOGY_OPTION_TYPE,
  THREE_D_P_FDM_TECH,
} from '../../constants/NewPartConstants';
// import { ITEM_QUOTE_TYPE } from '../../constants/projectConstants';
import { colors } from '../../palette';
import { ITEM_QUOTE_TYPE } from '../../constants/projectConstants';

import { getPastQuotesForRepeatItem } from '../../apis/itemApi';

import withViewAsSupplierHOC from '../../hocs/withViewAsSupplierHOC';
import { itemSurfaceFinishProxy } from '../../proxies/itemSurfaceFinishProxy';

const useStyles = makeStyles((theme) => ({
  ignoreLabel: {
    color: 'orange',
  },
  expandButton: {
    border: `1px solid ${colors.lightGray}`,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: `${colors.lightGray}`,
    },
  },
  infoImage: {
    marginTop: '5px',
    fontSize: '0.7rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      wrap: 'no-wrap',
      minWidth: 'max-content',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
    },
  },
}));

const ITEM_IMAGE_SIZE = 85;

const QUOTE_OPTIONS = {
  CAN_DO_JOB: 'CAN_DO_JOB',
  NO_QUOTE: 'NO_QUOTE',
};

function ProjectSingleQuoteItemDisplay({
  item,
  user,
  hasOneItem,
  currency,
  exchangeRate,
}) {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const queryParams = useQueryParams();

  useScrollToHash();

  const { data: pastQuotesInfo } = useQuery(
    ['getPastQuotesForRepeatItem', item.itemID],
    () => {
      if (isEmptyValue(item.itemID)) {
        return [];
      }
      return getPastQuotesForRepeatItem({
        itemID: item.itemID,
        params: { supplierID: user.userID },
      });
    }
  );
  const pastQuotes = pastQuotesInfo?.pastQuotes || [];
  const differentParamsInNewItem =
    pastQuotesInfo?.differentParamsInNewItem || [];

  const [expanded, setExpanded] = useState(true);
  // TODO temporarily remove due to this https://trello.com/c/tun7VTN4/2060-remove-customer-input-target-deadline-from-browse-parts-view
  // const [showExpectLeadTime, setShowExpectLeadTime] = useState(item.deadline && item.itemQuoteType !== ITEM_QUOTE_TYPE.PPE);
  const [isWatching, setIsWatching] = useState(
    item.watchingJobStatus === WATCHING_JOB_STATUS.ACTIVE
  );

  useEffect(() => {
    // setShowExpectLeadTime(item.deadline && item.itemQuoteType !== ITEM_QUOTE_TYPE.PPE);
    setIsWatching(item.watchingJobStatus === WATCHING_JOB_STATUS.ACTIVE);
  }, [item.watchingJobStatus]);

  const handlePpeQuoteRadioBoxChange = (event) => {
    const option = event.target.value;
    const newValue = option === QUOTE_OPTIONS.CAN_DO_JOB ? true : false;
    dispatch(
      formActions.toggleIgnoreItem({
        itemID: item.itemID,
        value: !newValue,
      })
    );
  };

  const handleUpdateWatchingStatus = () => {
    dispatch(
      formActions.updateWatchingJob({
        itemID: item.itemID,
        value: isWatching
          ? WATCHING_JOB_STATUS.INACTIVE
          : WATCHING_JOB_STATUS.ACTIVE,
      })
    );
  };

  const handleSetWatchingStatusActive = () => {
    dispatch(
      formActions.updateWatchingJob({
        itemID: item.itemID,
        value: WATCHING_JOB_STATUS.ACTIVE,
      })
    );
  };

  const render3DPrintingTechnology = () => {
    return (
      <Grid item xs={12} sm={3}>
        <KeyValueInfoDisplay
          name='3D Tech'
          value={
            item.acceptedQuote?.metadata?.threeDTechnology ||
            item.threeDTechnology
          }
          titleTooltip='3D Printing Technology'
        />
      </Grid>
    );
  };

  const render3DInfill = () => {
    const threeDTechnology =
      item.acceptedQuote?.metadata?.threeDTechnology || item.threeDTechnology;
    if (threeDTechnology !== THREE_D_P_FDM_TECH) {
      return;
    }

    return (
      <Grid item xs={12} sm={3}>
        <KeyValueInfoDisplay
          name='Infill'
          value={`${Number(
            (item.acceptedQuote?.metadata?.threeDInfill || item.threeDInfill) *
              100
          ).toFixed(2)}%`}
          titleTooltip='3D Infill'
        />
      </Grid>
    );
  };

  const render3DLayerThickness = () => {
    const threeDTechnology =
      item.acceptedQuote?.metadata?.threeDTechnology || item.threeDTechnology;
    if (threeDTechnology !== THREE_D_P_FDM_TECH) {
      return;
    }

    return (
      <Grid item xs={12} sm={3}>
        <KeyValueInfoDisplay
          name='Layer Thickness'
          value={`${
            item.acceptedQuote?.metadata?.threeDLayerThickness ??
            item.threeDLayerThickness
          }mm`}
          titleTooltip='3D Layer Thickness'
        />
      </Grid>
    );
  };

  const renderTargetUnitPrice = () => {
    if (
      isEmptyValue(item.targetPriceSupplier) ||
      item.targetPriceSupplier <= 0 ||
      item.itemQuoteType !== ITEM_QUOTE_TYPE.RFQ
    ) {
      return null;
    }
    const { unitPriceStr: targetUnitPriceSupplierStr } =
      convertPriceWithQuantityToCurrency({
        totalPrice: item.targetPriceSupplier,
        currency,
        exchangeRate,
      });
    return (
      <Grid item xs={12} sm={3}>
        <KeyValueInfoDisplay
          name='Target Unit Price'
          value={targetUnitPriceSupplierStr}
        />
      </Grid>
    );
  };

  const renderItemInfo = (_item) => {
    // success response instantQuotesLogMeta
    let infoInstantLogQuoteMeta = get(
      _item,
      'instantQuotesLogMeta.additionalParameters'
    );
    if (!infoInstantLogQuoteMeta) {
      // failed response instantQuotesLogMeta
      infoInstantLogQuoteMeta = get(_item, 'instantQuotesLogMeta') || {};
    }
    /**
     * Only use item.ppeInformation as the main ppeInformation.
     * The last instant log is only for the legacy item.
     * @param {*} key
     * @returns
     */
    const getPpeInfoValue = (key) => {
      const ppeInfo = _item?.ppeInformation ?? infoInstantLogQuoteMeta;
      return get(ppeInfo, key) ?? null;
    };

    const boundingBoxX = getPpeInfoValue('boundingBoxX');
    const boundingBoxY = getPpeInfoValue('boundingBoxY');
    const boundingBoxZ = getPpeInfoValue('boundingBoxZ');
    const volumeInfoMm3 = convertCm3ToMm3(getPpeInfoValue('volume'));
    const surfaceAreaInfoMm2 = getPpeInfoValue('surfaceArea');
    const weightInfo = getPpeInfoValue('weight');
    const weight = weightInfo
      ? new Decimal(weightInfo).dividedBy(_item.quantity)
      : null;
    let sizeInfo = null;
    if (boundingBoxX && boundingBoxY && boundingBoxZ) {
      sizeInfo = `${boundingBoxX.toFixed(2)}mm x ${boundingBoxY.toFixed(
        2
      )}mm x ${boundingBoxZ.toFixed(2)}mm`;
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection:
              isMobile && (sizeInfo || volumeInfoMm3 || surfaceAreaInfoMm2)
                ? 'column'
                : 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'row' : 'column',
              columnGap: isMobile ? '1rem' : '',
            }}
          >
            <ImageWith3DViewer
              twoDImageUrl={getItemImageUrl(_item)}
              cadFile={_item.cadFile}
              width={ITEM_IMAGE_SIZE}
              height={ITEM_IMAGE_SIZE}
              noBorder={false}
              borderRadius={0}
            />
            {!_item.ignoreItem && (
              <div className={classes.infoImage}>
                {sizeInfo && (
                  <div>
                    <strong>Size</strong>
                    <span>{sizeInfo}</span>
                  </div>
                )}
                {!!volumeInfoMm3 && (
                  <div>
                    <strong>Volume</strong>
                    <span>{`${volumeInfoMm3.toFixed(2)} mm3`}</span>
                  </div>
                )}
                {_item?.technology === TECHNOLOGY_OPTION_TYPE.TREATMENT
                  ? !!surfaceAreaInfoMm2 && (
                      <div>
                        <strong>Surface Area</strong>
                        <span>{`${surfaceAreaInfoMm2.toFixed(2)} mm2`}</span>
                      </div>
                    )
                  : !!weight && (
                      <div>
                        <FlexRow style={{ gap: '3px' }}>
                          <strong>Weight</strong>
                          <InfoIcon toolTipText='Weight of 1 quantity' />
                        </FlexRow>
                        <span>{`${weight.toFixed(3)} kg`}</span>
                      </div>
                    )}
              </div>
            )}
          </div>
          <div
            style={{
              marginLeft: isMobile && '1.2rem',
              marginBottom: '0.5rem',
              minWidth: 160,
            }}
          >
            <FileDownloadListDisplay
              title='Design files'
              urlList={_item.cadFile?.split(',')}
              trackingInfo={{
                itemID: _item.itemID,
                projectID: _item.projectID,
              }}
              downloadFileTracking={_item.downloadFileTracking}
            />
            <DownloadAllFilesButton
              urlList={_item.cadFile?.split(',')}
              folderName={`Factorem_${_item.referenceName}`}
              trackingInfo={{
                itemID: _item.itemID,
                projectID: _item.projectID,
              }}
            />
          </div>
        </div>
        <Grid
          style={{
            marginLeft: '1rem',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
          }}
          container
          spacing={1}
        >
          <Grid item xs={12} sm={3}>
            <KeyValueInfoDisplay
              name='Quantity'
              value={_item.acceptedQuote?.quantity ?? _item.quantity}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <KeyValueInfoDisplay
              name='Material'
              value={getMaterialWithColorText(_item.acceptedQuote ?? _item)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <KeyValueInfoDisplay
              name='Finishing'
              value={getSurfaceFinishWithCustomizationsText({
                ...(_item.acceptedQuote ?? _item),
                technology: _item.technology,
              })}
              titleTooltip={(function () {
                const itemOrQuote = {
                  ...(_item.acceptedQuote ?? _item),
                  technology: _item.technology,
                };
                const itemProxy = new Proxy(
                  itemOrQuote,
                  itemSurfaceFinishProxy
                );
                const tooltip = itemProxy.getSurfaceFinishTooltip();

                return tooltip;
              })()}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            style={!isMobile ? { maxWidth: '21.7%' } : null}
          >
            <KeyValueInfoDisplay
              name='Tolerance'
              value={showUnitValueFromMetric(_item.tolerance, _item.unitType)}
              titleTooltip='Tightest Tolerance Required'
            />
          </Grid>
          {/* // TODO temporarily remove due to this https://trello.com/c/tun7VTN4/2060-remove-customer-input-target-deadline-from-browse-parts-view */}
          {/* {showExpectLeadTime && (
            <Grid item xs={12} sm={3}>
              <KeyValueInfoDisplay
                name='Deadline'
                value={item.deadline.substring(0, 10)}
                titleTooltip='Use this as a guideline based on client's preference.'
              />
            </Grid>
          )} */}
          {_item.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING &&
            !isEmpty(_item.threeDTechnology) &&
            render3DPrintingTechnology()}
          {_item.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING &&
            _item.threeDTechnology === THREE_D_P_FDM_TECH &&
            render3DInfill()}
          {_item.technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING &&
            _item.threeDTechnology === THREE_D_P_FDM_TECH &&
            render3DLayerThickness()}
          <Grid
            item
            xs={12}
            sm={
              _item.description?.length > 250
                ? 9
                : _item.description?.length > 100
                  ? 6
                  : 3
            }
          >
            <KeyValueInfoDisplay
              name='Description'
              value={_item.description || 'N/A'}
              hideLongValueText
              maxDisplayText={250}
            />
          </Grid>
          {renderTargetUnitPrice()}
        </Grid>
      </div>
    );
  };

  const renderNoQuoteLabel = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography>No Quote</Typography>
      </div>
    );
  };

  const renderQuoteOptionsRadioBox = (_hasOneItem) => {
    return (
      <FormControl component='fieldset'>
        <RadioGroup
          row
          value={
            item.ignoreItem ? QUOTE_OPTIONS.NO_QUOTE : QUOTE_OPTIONS.CAN_DO_JOB
          }
          onChange={handlePpeQuoteRadioBoxChange}
        >
          <FormControlLabel
            value={QUOTE_OPTIONS.CAN_DO_JOB}
            control={<Radio color='primary' />}
            label='Quote'
          />
          {!_hasOneItem && (
            <FormControlLabel
              classes={{
                root: classes.ignoreLabel,
              }}
              value={QUOTE_OPTIONS.NO_QUOTE}
              control={<Radio color='primary' />}
              label='No Quote'
            />
          )}
        </RadioGroup>
      </FormControl>
    );
  };

  const renderRepeatItemIcon = () => {
    if (isEmptyValue(pastQuotes)) {
      return null;
    }
    const { itemID, projectID } = pastQuotes[0];
    const encodedTechnology = encodeURIComponent(queryParams.get('technology'));
    const itemData = combineDataFromItemAndQuotation(item, item.acceptedQuote);
    const paramDifferencesString = getParamDifferencesString(
      differentParamsInNewItem,
      itemData
    );
    return (
      <>
        <div style={{ paddingLeft: '4px', paddingTop: '4px' }}>
          {isEmptyValue(paramDifferencesString) ? (
            <RepeatOrderIcon />
          ) : (
            <RepeatOrderWithExclamationIcon />
          )}
        </div>
        {isSupplierRole(user.role) && (
          <FtrCaption style={{ paddingLeft: '4px' }}>
            Repeat of&nbsp;
            {
              <Link
                to={`/projects/${projectID}/quote?technology=${encodedTechnology}&supplierID=${user.userID}&viewQuote=true#${itemID}`}
                rel='noopener noreferrer'
                target='_blank'
              >
                part #{itemID}
              </Link>
            }
            {`${
              paramDifferencesString ? `, but ${paramDifferencesString}` : ''
            }`}
          </FtrCaption>
        )}
      </>
    );
  };

  const renderExpandButton = () => {
    return (
      <IconButton
        className={classes.expandButton}
        size='small'
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? (
          <ExpandLessIcon style={{ fontSize: '1rem' }} />
        ) : (
          <ExpandMoreIcon style={{ fontSize: '1rem' }} />
        )}
      </IconButton>
    );
  };

  const renderItemQuotedChip = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Chip
          style={{
            color: 'white',
            backgroundColor: colors.quotedLabelBlue,
            backgroundImage: colors.colorBlueGradient,
          }}
          label='QUOTED'
        ></Chip>
      </div>
    );
  };
  const renderItemQuotedRibbon = () => {
    if (expanded && item.supplierQuoted) {
      const text = item.isSupplierQuoteAccepted ? 'Accepted' : 'Quoted';
      const bg = item.isSupplierQuoteAccepted ? colors.greenGradient : null;
      return <CornerRibbon text={text} bg={bg} />;
    }
    return null;
  };

  const renderWatchingButton = () => {
    return (
      <div style={{ marginLeft: '-1rem' }}>
        <WatchingJob
          isWatching={isWatching}
          setIsWatching={setIsWatching}
          itemIDs={[item.itemID]}
          projectID={item.projectID}
          callbackSuccess={handleUpdateWatchingStatus}
        />
      </div>
    );
  };

  return (
    <div
      id={item.itemID}
      style={{
        border: `1px solid ${colors.expansionBackgroundColor}`,
        borderRadius: '3px',
        padding: '0.5em',
        marginBottom: '1.5rem',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '0.5rem',
        }}
      >
        <FlexRow>
          <Typography variant='h6'>{item.itemID}</Typography>
          {renderRepeatItemIcon()}
        </FlexRow>
        <FlexRow>
          {!item.supplierQuoted && renderQuoteOptionsRadioBox(hasOneItem)}
          {!expanded && item.supplierQuoted && renderItemQuotedChip()}
          {!isMobile && <Box style={{ width: '1rem' }}></Box>}
          {renderWatchingButton()}
          {!isMobile && <Box style={{ width: '1rem' }}></Box>}
          {renderExpandButton()}
        </FlexRow>
      </div>
      {expanded && renderItemInfo(item)}
      {renderItemQuotedRibbon()}
      {!item.supplierQuoted &&
        (item.ignoreItem ? (
          renderNoQuoteLabel()
        ) : (
          <ProjectItemQuotationForm
            item={item}
            expanded={expanded}
            isWatching={isWatching}
            setIsWatching={setIsWatching}
            handleSetWatchingStatusActive={handleSetWatchingStatusActive}
          />
        ))}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    formData: state.projectBulkQuotationsForm,
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(
  withViewAsSupplierHOC(ProjectSingleQuoteItemDisplay)
);
