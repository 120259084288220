import { get } from 'lodash';

import { is3DPTechnology } from './itemUtils';
import { toFixed4Or5 } from './numberUtils';

import {
  DISPLAY_UNIT_OPTIONS,
  INC_TO_MM,
  MM_TO_INCH,
  UNIT_TYPES,
} from '../constants/unitConstants';
import {
  STANDARD_TOLERANCE_OPTIONS,
  TECHNOLOGY_OPTION_TYPE,
  THREE_D_P_FDM_TECH,
  THREE_D_TOLERANCE_OPTIONS,
  TOLERANCE_IMPERIAL_TO_METRIC,
  TOLERANCE_METRIC_TO_IMPERIAL,
  TOLERANCE_OPTIONS,
} from '../constants/NewPartConstants';

// -------------------------------------------------------------------------------------------------

export const convertMetricToImperial = (value) => {
  const altValue = toFixed4Or5(value * MM_TO_INCH);
  const currValue = +toFixed4Or5(value);
  value = TOLERANCE_METRIC_TO_IMPERIAL[currValue] || altValue;
  return value;
};

export const convertImperialToMetric = (value) => {
  const altValue = toFixed4Or5(value * INC_TO_MM);
  const currValue = +toFixed4Or5(value);
  value = TOLERANCE_IMPERIAL_TO_METRIC[currValue] || altValue;
  return value;
};

/**
 * Retrieves the default tolerance value based on the given technology and unit type.
 *
 * @param {Object} options - The options object.
 * @param {string} [options.technology=TECHNOLOGY_OPTION_TYPE.CNC_MACHINING] - The technology option.
 * @param {string} [options.threeDTechnology=THREE_D_P_FDM_TECH] - The threeDTechnology option.
 * @param {string} [options.unitType=UNIT_TYPES.METRIC] - The unit type.
 * @return {number} The default tolerance value.
 */
export const getDefaultTolerance = ({
  technology = TECHNOLOGY_OPTION_TYPE.CNC_MACHINING,
  threeDTechnology = THREE_D_P_FDM_TECH,
  unitType = UNIT_TYPES.METRIC,
}) => {
  if (is3DPTechnology(technology)) {
    const defaultTolerance =
      THREE_D_TOLERANCE_OPTIONS[unitType][threeDTechnology];
    const tolerance =
      unitType === UNIT_TYPES.METRIC
        ? defaultTolerance
        : convertImperialToMetric(defaultTolerance);

    return tolerance;
  }

  let defaultTolerance =
    get(TOLERANCE_OPTIONS, [unitType, technology]) ||
    get(STANDARD_TOLERANCE_OPTIONS, [unitType, 3]);
  let tolerance =
    unitType === UNIT_TYPES.METRIC
      ? defaultTolerance
      : convertImperialToMetric(defaultTolerance);

  return tolerance;
};

/**
 * Returns the tolerance value as a formatted display string with unit.
 *
 * @param {Object} options - The options object.
 * @param {string|number} options.tolerance - The tolerance value.
 * @param {'metric'|'imperial'} [options.unitType=UNIT_TYPES.METRIC] - The unit type.
 * @returns {string} The formatted display string of the tolerance.
 */
export const getToleranceDisplayText = ({
  tolerance,
  unitType = UNIT_TYPES.METRIC,
}) => {
  const toleranceValue =
    unitType === UNIT_TYPES.IMPERIAL
      ? convertMetricToImperial(tolerance)
      : tolerance;
  const strValue = `±${`${toleranceValue}${DISPLAY_UNIT_OPTIONS[unitType]?.LENGTH}`}`;
  return strValue;
};
