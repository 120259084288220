import { create } from 'zustand';

import { getShippingPriceWithCache } from '../../../apis/priceApi';

import { isEmptyValue } from '../../../utils/commonUtils';
import { getDefaultShippingModeByCountry } from '../../../utils/deliveryUtils';
import {
  convertPriceToCurrency,
  convertPriceWithQuantityToCurrency,
  getCurrencySymbol,
} from '../../../utils/currencyUtils';
import { isNumber } from '../../../utils/numberUtils';

import { SHIPPING_MODES } from '../../../constants/checkoutConstants';
import { COUNTRY_NAMES } from '../../../constants/countryConstants';

const estimateCostInfoHook = create((set, get) => ({
  shippingMode: SHIPPING_MODES.ONE_THREE_DAYS,
  shippingAddress: '',
  shippingAddressObj: {},
  shippingCost: 0,
  shippingCostOptions: {},
  exchangeRate: 1,
  quotationList: [],
  subTotal: 0,
  currency: '',
  estimatedDeliveryDate: '',
  fetchShippingCost: async () => {
    const quotationList = get().quotationList;
    const exchangeRate = get().exchangeRate || 1;
    const addressObj = get().shippingAddressObj;
    const shippingMode = get().shippingMode;
    const country = addressObj?.country;
    const currency = get().currency;

    if (
      isEmptyValue(quotationList) ||
      isEmptyValue(country) ||
      isEmptyValue(addressObj) ||
      isEmptyValue(shippingMode) ||
      isEmptyValue(currency)
    ) {
      return;
    }

    if (isEmptyValue(quotationList)) {
      return;
    }

    const hasInvalidQuantity = quotationList?.some(
      (q) =>
        !isNumber(q.quantity) ||
        !isNumber(q.itemQuantity) ||
        Number(q.quantity) <= 0 ||
        Number(q.itemQuantity) <= 0
    );
    if (hasInvalidQuantity) {
      return;
    }

    const hasRfqItem =
      !isEmptyValue(quotationList) &&
      quotationList?.some(
        (quote) =>
          isEmptyValue(quote.ppeInformation) || !isNumber(quote.totalPrice)
      );
    if (hasRfqItem) {
      // will not display shipping info if has manual rfq item
      return;
    }

    set(() => ({
      shippingCostStatus: 'loading',
    }));

    const shippingCostDetails = await getShippingPriceWithCache({
      currency,
      exchangeRate,
      quotationList,
      country: addressObj?.country,
      shippingMode,
      deliveryInfoID: addressObj?.deliveryInfoID,
    });

    if (isEmptyValue(shippingCostDetails)) {
      return;
    }

    const defaultShippingMode = getDefaultShippingModeByCountry(country, {
      deliveryModePrices: shippingCostDetails.deliveryModePrices,
    });
    let updatedShippingMode =
      shippingMode in shippingCostDetails.deliveryModePrices
        ? shippingMode
        : defaultShippingMode;

    const shippingCost =
      shippingCostDetails.deliveryModePrices[updatedShippingMode]?.fee;
    const estimatedDeliveryDate = shippingCostDetails.deliveryDate;
    set(() => ({
      shippingCost,
      shippingMode: updatedShippingMode,
      shippingCostOptions: shippingCostDetails.deliveryModePrices,
      estimatedDeliveryDate,
      shippingCostStatus: 'success',
    }));
  },
  updateShippingAddress: async (addressObj) => {
    const address = addressObj?.address
      ? `${addressObj?.address}, ${addressObj?.country}`
      : `${addressObj?.country ?? COUNTRY_NAMES.SINGAPORE}`;
    set(() => ({
      shippingMode: SHIPPING_MODES.ONE_THREE_DAYS,
      shippingAddress: address,
      shippingAddressObj: { ...addressObj },
    }));
    const fetchShippingCost = get().fetchShippingCost;
    fetchShippingCost();
  },
  updateCurrency: (currency) => {
    const currencySymbol = getCurrencySymbol(currency);
    set(() => ({
      currency,
      currencySymbol,
    }));
    get().calculateSubTotal();
  },
  updateExchangeRate: (exchangeRate) => {
    if (isEmptyValue(exchangeRate)) {
      return;
    }
    set(() => ({
      exchangeRate,
    }));
    get().calculateSubTotal();
  },
  calculateSubTotal: () => {
    const currency = get().currency;
    const exchangeRate = get().exchangeRate;
    const quotationList = get().quotationList;
    if (
      isEmptyValue(currency) ||
      isEmptyValue(currency) ||
      isEmptyValue(quotationList)
    ) {
      return;
    }
    const subTotal = quotationList?.reduce((acc, quotation) => {
      const { totalPrice } = convertPriceWithQuantityToCurrency({
        totalPrice: Number(quotation.totalPrice),
        quantity: quotation.quantity,
        currency,
        exchangeRate,
      });
      return (acc += totalPrice ?? 0);
    }, 0);
    set(() => ({
      subTotal,
    }));
  },
  updateQuotationList: (quotationList) => {
    set(() => ({
      quotationList,
    }));
    get().fetchShippingCost();
    get().calculateSubTotal();
  },
  selectShippingOption: (shippingOptionKey) => {
    const shippingOption = get().shippingCostOptions[shippingOptionKey];
    const shippingCost = shippingOption.fee;
    set(() => ({
      shippingMode: shippingOptionKey,
      shippingCost,
      estimatedDeliveryDate: shippingOption.estimatedDeliveryDate,
    }));
  },
  getTotalCost: () => {
    const totalCostStr = convertPriceToCurrency({
      price: get().subTotal,
      currency: get().currency,
      exchangeRate: 1,
    });
    return totalCostStr;
  },
}));

export default estimateCostInfoHook;
