import { createAlertNotification } from './alertApi';

import {
  fetchWithErrorHandling,
  getAuthorizedHeader,
  witchCacheResponse,
} from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';
import { FOURTEEN_MINUTES_IN_SECOND } from '../constants/dateTimeConstants';

// -------------------------------------------------------------------------------------------------

/**
 * @deprecated
 * @param {*} param0
 * @returns
 */
export const verifyToken = async ({ token }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/jwt/verify-token`;
  const data = await fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify({ token }),
  }).catch((error) => {
    const body = {
      title: '[FE] Verify Token failed!',
      errorStack: error.stack,
      additionalInfo: {
        token,
      },
    };
    createAlertNotification(body).catch((_error) => console.error(_error));
    return { valid: false };
  });

  const { valid } = data;
  return valid;
};

const verifyTokenV2Api = async ({ token }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/jwt/v2/verify-token`;

  const data = await fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify({ token }),
  });

  return data;
};

export const verifyTokenV2ApiWithCache = witchCacheResponse(
  verifyTokenV2Api,
  FOURTEEN_MINUTES_IN_SECOND
);

export const generateTokenForGuest = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/jwt/temporary-token/create`;

  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const getTokenForGuest = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/jwt/temporary-token/get`;

  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};
