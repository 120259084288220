// Import settings
import React from 'react';

// Import material UI components
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Divider,
  Typography,
  FormControl,
  FormLabel,
  Input,
  InputAdornment,
} from '@material-ui/core';

// Import color palette
import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  container: {
    margin: '25px, 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '25px',
    padding: '25px',
    border: `3px solid ${colors.bgLightGrey}`,
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '50px',
  },
  inputField: {
    marginTop: '10px',
    fontSize: 15,
    padding: '0px 8px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    width: '100%',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
}));

function PpeSafeguardPriceForm(props) {
  const {
    safeguardPriceState,
    complexityState,
    fileSizeState,
    onSafeguardPriceFormChange,
    csmState,
  } = props;

  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <div>
        <Typography variant='h6'>Price Safeguard Settings</Typography>
        <Divider />
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.columnContainer}>
          {Object.keys(safeguardPriceState)?.map((safeguardPrice, index) => {
            return (
              <FormControl key={index} component='fieldset'>
                <FormLabel component='legend'>{safeguardPrice}</FormLabel>
                <Input
                  className={classes.inputField}
                  disableUnderline={true}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100000,
                    type: 'number',
                  }}
                  value={safeguardPriceState[safeguardPrice]}
                  onChange={(event) => {
                    onSafeguardPriceFormChange.safeguardPrice(
                      safeguardPrice,
                      event.target.value
                    );
                  }}
                  startAdornment={
                    <InputAdornment position='start'>$</InputAdornment>
                  }
                />
              </FormControl>
            );
          })}
        </div>
        <div className={classes.columnContainer}>
          {Object.keys(complexityState)?.map((complexity, index) => {
            return (
              <FormControl key={index} component='fieldset'>
                <FormLabel component='legend'>{complexity}</FormLabel>
                <Input
                  className={classes.inputField}
                  disableUnderline={true}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 5,
                    type: 'number',
                  }}
                  value={complexityState[complexity]}
                  onChange={(event) => {
                    onSafeguardPriceFormChange.complexity(
                      complexity,
                      event.target.value
                    );
                  }}
                />
              </FormControl>
            );
          })}
        </div>
        <div className={classes.columnContainer}>
          {Object.keys(fileSizeState)?.map((fileSize, index) => {
            return (
              <FormControl key={index} component='fieldset'>
                <FormLabel component='legend'>{fileSize} (in kb) </FormLabel>
                <Input
                  className={classes.inputField}
                  disableUnderline={true}
                  inputProps={{
                    step: 10,
                    min: 0,
                    max: 1000,
                    type: 'number',
                  }}
                  value={fileSizeState[fileSize]}
                  onChange={(event) => {
                    onSafeguardPriceFormChange.fileSize(
                      fileSize,
                      event.target.value
                    );
                  }}
                />
              </FormControl>
            );
          })}
        </div>
        <div className={classes.columnContainer}>
          {Object.keys(csmState)?.map((csm, index) => {
            return (
              <FormControl key={index} component='fieldset'>
                <FormLabel component='legend'>{csm}</FormLabel>
                <Input
                  className={classes.inputField}
                  disableUnderline={true}
                  inputProps={{
                    step: 0.1,
                    type: 'number',
                  }}
                  value={csmState[csm]}
                  onChange={(event) => {
                    onSafeguardPriceFormChange.csm(csm, event.target.value);
                  }}
                />
              </FormControl>
            );
          })}
        </div>
      </div>
    </Container>
  );
}

export default PpeSafeguardPriceForm;
