import React, { useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Badge, Tooltip } from '@material-ui/core';

import CustomerItemMuiTableV2Presentational from './CustomerItemMuiTableV2Presentational';
import DateFormatMethod from '../DateFormatMethod';
import ImageWith3DViewer from '../images/ImageWith3DViewer';
import ItemStatusParent from '../ItemStatusParent';
import { FlexColumn } from '../layouts/FlexLayouts';
import { FtrB1, FtrBlueCheckbox, FtrBoldText, FtrH3 } from '../ftr-components';

import withDeletePartConfirmPopupHOC from '../../hocs/withDeletePartConfirmPopupHOC';
import ProjectItemsContext from '../../context/ProjectItemsContext';

import {
  addSelectedQuote,
  removeSelectedQuote,
} from '../../actions/multiCheckoutForm';

import useCustomerSettingsHook from '../../hooks/useCustomerSettingsHook';

import {
  getQuotationExchangeRate,
  isSelectableQuote,
  isUncheckedOutPartWithQuotes,
  isUnverifiedQuote,
  isWaitingQuote,
} from '../../utils/quotationUtils';
import { convertPriceWithQuantityToCurrency } from '../../utils/currencyUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { partIDColumn } from '../../constants/itemTableConstants';
import { ITEM_STATUS_MAPPING } from '../../constants/itemStatus';

import { colors } from '../../palette';

// ------------------------------------------------------------------------------------------------

function CustomerItemMuiTableV2(props) {
  const {
    data,
    getItems,
    currency,
    multiCheckoutForm,
    addQuoteToCheckout,
    removeQuoteFromCheckout,
  } = props;

  const { projectMCTs, isAdminView, customerID } =
    useContext(ProjectItemsContext);

  const { combinedServiceFee } = useCustomerSettingsHook(customerID);

  const [triggerExpand, setTriggerExpand] = useState(false);
  const [allParts, setAllParts] = useState([]);
  const [selectedQuotes, setSelectedQuotes] = useState([]);

  const unCheckedOutParts = useMemo(() => {
    return allParts?.filter((part) =>
      isUncheckedOutPartWithQuotes(part, isAdminView)
    );
  }, [isAdminView, allParts]);

  const firstValidQuoteForEachPart = useMemo(() => {
    return unCheckedOutParts
      ?.map((part) =>
        part.quotations?.find((quote) => isSelectableQuote(quote, isAdminView))
      )
      ?.filter((quote) => !isEmptyValue(quote));
  }, [unCheckedOutParts]);

  const isOneQuoteFromEveryItemSelected = useMemo(() => {
    const uniqueQuotes = selectedQuotes?.reduce((acc, quote) => {
      if (
        !acc?.some(
          (existingQuote) => existingQuote['itemID'] === quote['itemID']
        )
      ) {
        acc.push(quote);
      }
      return acc;
    }, []);
    return uniqueQuotes.length === unCheckedOutParts.length;
  }, [selectedQuotes, unCheckedOutParts]);

  useEffect(() => {
    setSelectedQuotes(multiCheckoutForm.selectedQuotes || []);
  }, [multiCheckoutForm]);

  useEffect(() => {
    setAllParts(data);
  }, [data]);

  useEffect(() => {
    setTriggerExpand(false);
  }, [isAdminView]);

  const handleSelectAllQuotes = (checked) => {
    if (checked) {
      firstValidQuoteForEachPart.forEach((quote) => {
        addQuoteToCheckout(quote);
      });
      setTriggerExpand(true);
    } else {
      selectedQuotes.forEach((quote) => {
        removeQuoteFromCheckout(quote);
      });
      setTriggerExpand(false);
    }
  };
  const nameColumn = {
    title: 'Part Name',
    field: 'name',
    cellStyle: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      cellStyle: {
        width: '10%',
        maxWidth: 20,
      },
    },
    render: (rowData) => {
      return (
        <FlexColumn style={{ alignItems: 'flex-start' }}>
          <FtrBoldText style={{ color: colors.neutral070 }} fontSize={16}>
            {rowData.name}
          </FtrBoldText>
        </FlexColumn>
      );
    },
    width: 200,
  };

  const imageColumn = {
    title: '',
    render: (rowData) => {
      return (
        <ImageWith3DViewer
          twoDImageUrl={rowData.imageFile || rowData.twoDImageUrl}
          cadFile={rowData.cadFile || rowData.originalFiles}
          borderRadius={9}
          width={120}
          height={120}
        />
      );
    },
    width: 120,
  };

  const quantityColumn = {
    title: 'Qty',
    field: 'quantity',
    cellStyle: {
      width: '3%',
      maxWidth: 5,
    },
    width: 50,
  };

  const totalPriceColumn = {
    title: 'Total Price',
    field: 'totalQuote',
    render: (rowData) => {
      const verifiedQuotes = isAdminView
        ? rowData.quotations
        : rowData.quotations
            ?.filter((quote) => !isUnverifiedQuote(quote))
            ?.filter((quote) => !isWaitingQuote(quote));
      if (isEmptyValue(verifiedQuotes)) {
        return 'NA';
      }

      if (verifiedQuotes.length > 1) {
        return 'Multiple quotes';
      }

      const quote = verifiedQuotes[0];
      // const { totalPrice = 0, quantity = 1 } = quote;
      const exchangeRate = getQuotationExchangeRate(quote, currency);
      const totalCost = combinedServiceFee
        ? quote.totalPriceInclPlatformFee || 0
        : quote.totalPrice || rowData.qTotalPrice || 0;

      const { totalPriceStr, unitPriceStr } =
        convertPriceWithQuantityToCurrency({
          totalPrice: totalCost,
          currency,
          exchangeRate,
          quantity: quote.quantity,
        });

      return (
        <FlexColumn>
          <FtrH3
            style={{ color: colors.neutral070, fontWeight: 600 }}
            id='total-quote-price'
          >
            {totalPriceStr}
          </FtrH3>
          <FtrB1 style={{ color: colors.neutral070 }}>
            {unitPriceStr}/unit
          </FtrB1>
        </FlexColumn>
      );
    },
    cellStyle: {
      width: '5%',
      minWidth: 120,
    },
    width: 80,
    minWidth: 80,
  };

  const dateUploadedColumn = {
    title: 'Date Created',
    field: 'datePosted',
    render: (rowData) =>
      rowData.datePosted ? (
        <DateFormatMethod date={rowData.datePosted} monthType='short' />
      ) : (
        ''
      ),
    cellStyle: {
      minWidth: 150,
    },
    width: 100,
  };

  const statusColumn = {
    title: 'Status',
    field: 'status',
    render: (rowData) => {
      const tooltip =
        rowData.status === ITEM_STATUS_MAPPING.QUOTES_AVAILABLE
          ? 'Click to expand all quotes'
          : 'Click to expand item details';

      let isShipping = false;
      if (!isEmptyValue(projectMCTs)) {
        const allAcceptedItems = projectMCTs?.flatMap(
          (project) => project.acceptedItems
        );
        isShipping =
          allAcceptedItems?.find((item) => item.itemID === rowData.itemID)
            ?.shipItem === true;
      }
      rowData.isShipping = isShipping;

      return (
        <Tooltip title={tooltip}>
          <div>
            <ItemStatusParent
              item={rowData}
              getItems={getItems}
              hasQuoteSelected={selectedQuotes
                ?.map((q) => q.itemID)
                .includes(rowData.itemID)}
            />
          </div>
        </Tooltip>
      );
    },
    width: 80,
  };

  const renderSelectAllQuotes = () => {
    return (
      <FtrBlueCheckbox
        data-cy='select-all-quotes-checkbox'
        checked={isOneQuoteFromEveryItemSelected}
        onClick={(event) => handleSelectAllQuotes(event.target.checked)}
      />
    );
  };

  const selectAllQuotesColumn = {
    title: renderSelectAllQuotes(),
    //if visible, there are some items with at least one quote that can be selected
    hidden: isEmptyValue(firstValidQuoteForEachPart),
    width: 50,
  };

  // Declare columns
  const columns = [
    selectAllQuotesColumn,
    {
      ...partIDColumn,
      render: (rowData) => {
        const hasUnverifiedQuote = rowData.quotations?.some(isUnverifiedQuote);
        return hasUnverifiedQuote && isAdminView ? (
          <Tooltip title={'There are unverified quotes for this part'}>
            <Badge
              badgeContent={'!'}
              color='error'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {rowData.itemID}
            </Badge>
          </Tooltip>
        ) : (
          <>{rowData.itemID}</>
        );
      },
      width: 60,
    },
    imageColumn,
    nameColumn,
    quantityColumn,
    totalPriceColumn,
    dateUploadedColumn,
    statusColumn,
  ];

  return (
    <>
      <CustomerItemMuiTableV2Presentational
        columns={columns}
        rows={allParts}
        isAdminView={isAdminView}
        triggerExpand={triggerExpand}
        getItems={getItems}
        multiCheckoutForm={multiCheckoutForm}
        combinedServiceFee={combinedServiceFee}
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    currency: state.auth.location.currency,
    multiCheckoutForm: state.multiCheckoutForm,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    addQuoteToCheckout: (quote) => dispatch(addSelectedQuote(quote)),
    removeQuoteFromCheckout: (quote) => dispatch(removeSelectedQuote(quote)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withRouter(
  withConnect(withDeletePartConfirmPopupHOC(CustomerItemMuiTableV2))
);
