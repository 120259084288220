import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import { IconButton, Tooltip } from '@material-ui/core';

import { Delete, VerifiedUser } from '@material-ui/icons';

import { FtrB2, FtrB3, FtrTooltip } from '../../components/ftr-components';
import FtrCheckboxDropdown from '../../components/ftr-components/FtrCheckboxDropdown';
import FtrIOSSwitch from '../../components/ftr-components/FtrIOSSwitch';
import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import { AddNewButton } from '../../components/grid-data/buttons/AddNewButton';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { FlexColumn, FlexRow } from '../../components/layouts/FlexLayouts';
import DataGridWrapCell from '../../components/tables/cells/DataGridWrapCell';

import withFtrDeletePopupHOC from '../../hocs/withFtrDeletePopupHOC';
import withGenerateAutoBalloonForItemPopupHOC from '../../hocs/withGenerateAutoBalloonForItemPopupHOC';
import withLoadingBackDropTextHOC from '../../hocs/withLoadingBackDropTextHOC';
import withVerifyBalloonPopupHOC from '../../hocs/withVerifyBalloonPopupHOC';

import {
  getAutoBalloonItems,
  updateStatusForAutoBalloonItem,
} from '../../apis/autoBalloonApi';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { isEmptyValue } from '../../utils/commonUtils';
import { exportCSV } from '../../utils/csvExportUtils';
import { formatDateWithTime } from '../../utils/dateTimeUtils';
import { getFileNameFromUrl } from '../../utils/fileUtils';
import { openInNewTab } from '../../utils/navigationUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';
import {
  compareStringsEqualsIgnoreCase,
  snakeCaseToTitleCase,
} from '../../utils/stringUtils';

import { generatePresignedUrl } from '../../services/s3Service';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-menuIcon > button': {
      padding: 0,
    },
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer',
    },
  },
  notInterestedLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: colors.lightGray,
    zIndex: 1,
    border: `2px solid ${colors.lightGray}`,
    borderRadius: '4px',
    minHeight: '1.1rem',
    minWidth: '2rem',
    columnGap: '3px',
    justifyContent: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    cursor: 'pointer',
    width: '2.5rem',
    height: '1.125rem',
  },
}));

const DEFAULT_FILTER_STATUS = [
  {
    label: 'Verified',
    value: 'verified',
    checked: false,
    type: 'status',
  },
  {
    label: 'Pending',
    value: 'pending',
    checked: true,
    type: 'status',
  },
  {
    label: 'Processing',
    value: 'processing',
    checked: true,
    type: 'status',
  },
  {
    label: 'Archived',
    value: 'archived',
    checked: false,
    type: 'status',
  },
];

const DEFAULT_CHECKED_FILTERS = ['pending', 'processing'];

function AllItemBalloonPdfFilesDataGrid(props) {
  const classes = useStyles();

  const {
    tabFilterFunc,
    updateDeletePopupHOCState = () => {},
    updateVerifyBalloonPopupState = () => {},
    updateGenerateAutoBalloonForItemPopupState = () => {},
  } = props;

  const role = useSelector(getUserRoleSelector);
  const [statusFilter, setStatusFilter] = useState(DEFAULT_FILTER_STATUS);
  const [fixedSuppliersFilter, setFixedSuppliersFilter] = useState(true);

  const {
    data: allBalloonItems,
    isLoading,
    isFetching,
    refetch: refetchBalloonItems,
  } = useQuery(['getAutoBalloonItems', fixedSuppliersFilter], () =>
    getAutoBalloonItems({ fixedSuppliersFilter })
  );

  const tabFilteredData = useMemo(() => {
    return isEmptyValue(allBalloonItems)
      ? []
      : tabFilterFunc
        ? tabFilterFunc(allBalloonItems)
        : allBalloonItems?.filter((item) =>
            DEFAULT_CHECKED_FILTERS.includes(item.status)
          );
  }, [allBalloonItems, tabFilterFunc]);

  function handleDeleteClick(itemID, pdfUrl) {
    if (![itemID, pdfUrl]?.some(isEmptyValue)) {
      updateStatusForAutoBalloonItem({
        itemID: itemID,
        pdfUrl: pdfUrl,
        newStatus: 'archived',
      })
        .then(() => {
          notifySuccess('Ballooned item has been archived successfully');
          updateDeletePopupHOCState({
            open: false,
          });
          refetchBalloonItems();
        })
        .catch((error) => {
          notifyError(
            error.message ||
              'Unable to archive ballooned item. Please try again later.'
          );
          updateDeletePopupHOCState({
            open: false,
          });
        });
    }
  }

  const renderDeleteSweepIcon = (itemID, pdfUrl) => {
    return (
      <FlexColumn>
        <Tooltip title={'Archive ballooned item'}>
          <IconButton
            color='inherit'
            size='small'
            aria-label='delete'
            onClick={(evt) => {
              evt.stopPropagation();
              updateDeletePopupHOCState({
                open: true,
                title: `Do you want to archive this ballooned item: ${itemID}?`,
                onConfirm: () => handleDeleteClick(itemID, pdfUrl),
                confirmButtonText: 'Archive',
              });
            }}
          >
            <Delete fontSize='small' />
          </IconButton>
        </Tooltip>
      </FlexColumn>
    );
  };

  const columns = [
    {
      headerName: 'Item ID',
      field: 'itemID',
      align: 'center',
      minWidth: 120,
    },
    {
      headerName: 'Source PDF',
      field: 'sourcePdf',
      renderCell: ({ row: rowData }) => {
        const fileName = getFileNameFromUrl(rowData.pdfUrl);
        return (
          <DataGridWrapCell>
            <Link
              className={classes.forgotPassword}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                generatePresignedUrl(rowData.pdfUrl).then((url) => {
                  openInNewTab(url);
                });
              }}
            >
              {fileName}
            </Link>
          </DataGridWrapCell>
        );
      },
      minWidth: 250,
      exportData: ({ row: rowData }) => rowData.pdfUrl,
    },
    {
      headerName: 'Ballooned PDF',
      field: 'balloonedPdf',
      renderCell: ({ row: rowData }) => {
        const fileName = getFileNameFromUrl(rowData.balloonedPdfUrl);
        return (
          <DataGridWrapCell>
            <Link
              className={classes.forgotPassword}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                generatePresignedUrl(rowData.balloonedPdfUrl).then((url) => {
                  openInNewTab(url);
                });
              }}
            >
              {fileName}
            </Link>
          </DataGridWrapCell>
        );
      },
      minWidth: 250,
      exportData: ({ row: rowData }) => rowData.balloonedPdfUrl,
    },
    {
      headerName: 'Report CSV',
      field: 'reportCsv',
      renderCell: ({ row: rowData }) => {
        const fileName = getFileNameFromUrl(rowData.balloonedCsvUrl);
        return (
          <DataGridWrapCell>
            <Link
              className={classes.forgotPassword}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                generatePresignedUrl(rowData.balloonedCsvUrl).then((url) => {
                  openInNewTab(url);
                });
              }}
            >
              {fileName}
            </Link>
          </DataGridWrapCell>
        );
      },
      minWidth: 300,
      exportData: ({ row: rowData }) => rowData.balloonedCsvUrl,
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row: rowData }) => {
        const status = snakeCaseToTitleCase(rowData.status);

        return (
          <FlexRow>
            {status}
            {compareStringsEqualsIgnoreCase(status, 'pending') && (
              <Tooltip title='Click to verify if PDF and CSV are correct'>
                <span
                  style={{ display: 'inline-block', color: colors.blue060 }}
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      updateVerifyBalloonPopupState({
                        open: true,
                        itemID: rowData.itemID,
                        pdfUrl: rowData.pdfUrl,
                      });
                    }}
                    color='inherit'
                  >
                    <VerifiedUser color='inherit' />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </FlexRow>
        );
      },
      align: 'center',
      minWidth: 150,
    },
    {
      headerName: 'Supplier',
      field: 'supplierName',
      renderCell: ({ row: rowData }) => {
        const { supplierID, supplierName, supplierEmail } = rowData;
        if (isEmptyValue(supplierID)) {
          return <DataGridWrapCell>N.A.</DataGridWrapCell>;
        }

        return (
          <DataGridWrapCell>
            <FlexColumn style={{ gap: 0 }}>
              <FtrB3>
                {supplierName} ({supplierID})
              </FtrB3>
              <FtrB2>{supplierEmail}</FtrB2>
            </FlexColumn>
          </DataGridWrapCell>
        );
      },
      minWidth: 300,
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      align: 'center',
      renderCell: ({ row: rowData }) => {
        return formatDateWithTime(rowData.createdAt);
      },
      minWidth: 150,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapCell>
            {rowData.status !== 'archived' &&
              renderDeleteSweepIcon(rowData.itemID, rowData.pdfUrl)}
          </DataGridWrapCell>
        );
      },
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
      export: false,
      filterable: false,
      sortable: false,
    },
  ];

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      status: [],
      totalCount: 0,
      loading: false,
    }
  );

  const [
    filteredData,
    { filtering, setSearchStr, setColumnsDef, setSourceData },
  ] = useDataGridFilterHook({
    search: '',
    source: tabFilteredData,
  });

  useEffect(() => {
    updateTableQueryParams({ loading: true });

    let _filteredData = allBalloonItems || [];
    if (!isEmptyValue(tableQueryParams.filteredStatuses)) {
      _filteredData = _filteredData?.filter((row) => {
        return tableQueryParams.filteredStatuses?.includes(row?.status);
      });
    }
    updateTableQueryParams({
      loading: false,
      totalCount: _filteredData.length,
    });

    setSourceData(_filteredData);
  }, [tableQueryParams.filteredStatuses]);

  useEffect(() => {
    if (!allBalloonItems) {
      return;
    }

    updateTableQueryParams({
      totalCount: allBalloonItems.length,
    });
  }, [allBalloonItems]);

  useEffect(() => {
    setSourceData(tabFilteredData);
  }, [tabFilteredData]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData.length });
  }, [filteredData]);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    setColumnsDef(columns);
  }, []);

  const handleChangeFilterStatus = (statusFilters) => {
    setStatusFilter(statusFilters);
    const selectedStatuses = statusFilters
      ?.filter((status) => status.checked && status.type === 'status')
      ?.map((status) => status.value);
    updateTableQueryParams({
      filteredStatuses: selectedStatuses,
    });
  };

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const getCustomToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <CustomToolbar
          buttons={[
            <AddNewButton
              key='add-new-balloon-item'
              handleClick={() => {
                updateGenerateAutoBalloonForItemPopupState({ open: true });
              }}
              name='Add Item'
            />,
          ]}
        />
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <FtrTooltip
              key='ftr-ios-switch'
              description='Filter by Fixed Suppliers'
            >
              <FtrIOSSwitch
                checked={fixedSuppliersFilter}
                onChange={() => setFixedSuppliersFilter(!fixedSuppliersFilter)}
                name='fixed-suppliers-switch'
              />
            </FtrTooltip>,
            <FtrCheckboxDropdown
              key='status'
              id='ftr-checkbox-status-dropdown'
              label='Status'
              items={statusFilter}
              handleChange={handleChangeFilterStatus}
              style={{ borderRadius: '5px' }}
            />,
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={() =>
                exportCSV(columns, filteredData, 'All Ballooned Items PDF')
              }
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  const handleRowClick = ({ row: rowData }) => {
    if (compareStringsEqualsIgnoreCase(rowData.status, 'pending')) {
      updateVerifyBalloonPopupState({
        open: true,
        itemID: rowData.itemID,
        pdfUrl: rowData.pdfUrl,
      });
    }
  };

  return (
    <div className={classes.body} style={{ height: '100%', width: '100%' }}>
      <DataGrid
        autoHeight
        headerHeight={90}
        columns={columns}
        rows={isEmptyValue(filteredData) ? [] : filteredData}
        getRowId={(row) => row.itemID + '-' + row.pdfUrl}
        onRowClick={handleRowClick}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        rowsPerPageOptions={[10, 20, 50]}
        loading={
          tableQueryParams.loading || filtering || isLoading || isFetching
        }
        disableSelectionOnClick
        disableRowSelectionOnClick
        components={{
          Toolbar: getCustomToolbar,
          Pagination: () => {
            return (
              <GridDataPagination
                pageCount={Math.ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            );
          },
        }}
        localeText={{
          toolbarExport: 'Export CSV',
        }}
        disableColumnMenu
      />
    </div>
  );
}

export default compose(
  withFtrDeletePopupHOC,
  withLoadingBackDropTextHOC,
  withVerifyBalloonPopupHOC,
  withGenerateAutoBalloonForItemPopupHOC
)(AllItemBalloonPdfFilesDataGrid);
