import React, { useReducer } from 'react';
import DefectVisualizationPopup from './DefectVisualizationPopup';

function withDefectVisualizationPopupHOC(WrappedComponent) {
  return function DefectVisualizationPopupHOC(props) {
    const [localState, updateLocalState] = useReducer(
      (prev, next) => {
        return { ...prev, ...next };
      },
      {
        openPopup: false,
        selectedDefectUrl: '',
      }
    );

    return (
      <>
        <WrappedComponent
          handleView3DDefect={(defectUrl, params) => {
            updateLocalState({
              selectedDefectUrl: defectUrl,
              openPopup: true,
              ...params,
            });
          }}
          {...props}
        />
        {localState.openPopup && (
          <DefectVisualizationPopup
            open={localState.openPopup}
            onClose={() => updateLocalState({ openPopup: false })}
            {...localState}
            {...props}
          />
        )}
      </>
    );
  };
}

export default withDefectVisualizationPopupHOC;
