import React, { useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Badge, Tooltip } from '@material-ui/core';

import CustomerItemMuiTableV2Presentational from './CustomerItemMuiTableV2Presentational';
import DeliveryDateCellV2 from './cells/DeliveryDateCellV2';
import ImageWith3DViewer from '../images/ImageWith3DViewer';
import ItemStatusParent from '../ItemStatusParent';
import PaymentStatusSimpleLabelV2 from '../labels/PaymentStatusSimpleLabelV2';
import { FtrB1, FtrBlueCheckbox, FtrH3 } from '../ftr-components';
import { FlexColumn } from '../layouts/FlexLayouts';

import { getItemShipmentInfoByIDList } from '../../apis/itemApi';

import withDeletePartConfirmPopupHOC from '../../hocs/withDeletePartConfirmPopupHOC';

import ProjectItemsContext from '../../context/ProjectItemsContext';
import ProjectMultiCheckoutInfoContext from '../../context/ProjectMultiCheckoutInfoContext';

import {
  addSelectedQuote,
  removeSelectedQuote,
} from '../../actions/multiCheckoutForm';

import {
  getQuotationExchangeRate,
  isSelectableQuote,
  isUncheckedOutPartWithQuotes,
  isUnverifiedQuote,
} from '../../utils/quotationUtils';

import { convertPriceWithQuantityToCurrency } from '../../utils/currencyUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { filter, map } from '../../utils/arrayUtils';

import { partIDColumn } from '../../constants/itemTableConstants';

import { ITEM_STATUS_MAPPING } from '../../constants/itemStatus';

import { colors } from '../../palette';

// ------------------------------------------------------------------------------------------------

function ProjectMultiCheckoutItemMuiTable(props) {
  const {
    data,
    getItems,
    currency,
    multiCheckoutForm,
    addQuoteToCheckout,
    removeQuoteFromCheckout,
  } = props;

  const { customerSettings = {} } = useContext(ProjectMultiCheckoutInfoContext);

  const { combinedServiceFee } = customerSettings || {};

  const itemIDList = map(data, (part) => part?.itemID)?.filter(
    (itemID) => !isEmptyValue(itemID)
  );

  const { projectMCTs, isAdminView } = useContext(ProjectItemsContext);

  const { data: allParts } = useQuery(
    ['getItemShipmentInfoByIDList', itemIDList],
    async () => {
      if (isEmptyValue(itemIDList)) {
        return undefined;
      }

      const shipmentInfoList = await getItemShipmentInfoByIDList({
        itemIDList,
      });
      return map(data, (part) => ({
        ...part,
        ...(shipmentInfoList?.find(
          (shipmentInfo) => shipmentInfo.itemID === part.itemID
        ) || {}),
      }));
    }
  );
  const { isCalendarView = false } = useContext(
    ProjectMultiCheckoutInfoContext
  );

  const [triggerExpand, setTriggerExpand] = useState(false);
  const [selectedQuotes, setSelectedQuotes] = useState([]);

  const unCheckedOutParts = useMemo(() => {
    return filter(allParts, (part) =>
      isUncheckedOutPartWithQuotes(part, isAdminView)
    );
  }, [isAdminView, allParts]);

  const firstValidQuoteForEachPart = useMemo(() => {
    return unCheckedOutParts
      ?.map((part) =>
        part.quotations?.find((quote) => isSelectableQuote(quote, isAdminView))
      )
      ?.filter((quote) => !isEmptyValue(quote));
  }, [unCheckedOutParts]);

  const isOneQuoteFromEveryItemSelected = useMemo(() => {
    const uniqueQuotes = selectedQuotes?.reduce((acc, quote) => {
      if (
        !acc?.some(
          (existingQuote) => existingQuote['itemID'] === quote['itemID']
        )
      ) {
        acc.push(quote);
      }
      return acc;
    }, []);
    return uniqueQuotes.length === unCheckedOutParts.length;
  }, [selectedQuotes, unCheckedOutParts]);

  useEffect(() => {
    setSelectedQuotes(multiCheckoutForm.selectedQuotes || []);
  }, [multiCheckoutForm]);

  useEffect(() => {
    setTriggerExpand(false);
  }, [isAdminView]);

  const handleSelectAllQuotes = (checked) => {
    if (checked) {
      firstValidQuoteForEachPart.forEach((quote) => {
        addQuoteToCheckout(quote);
      });
      setTriggerExpand(true);
    } else {
      selectedQuotes.forEach((quote) => {
        removeQuoteFromCheckout(quote);
      });
      setTriggerExpand(false);
    }
  };

  const nameColumn = {
    title: 'Part Name',
    field: 'name',
    cellStyle: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      cellStyle: {
        width: '10%',
        maxWidth: 20,
      },
    },
    width: 200,
  };

  const imageColumn = {
    title: '',
    render: (rowData) => {
      return (
        <ImageWith3DViewer
          twoDImageUrl={rowData.imageFile || rowData.twoDImageUrl}
          cadFile={rowData.cadFile || rowData.originalFiles}
          borderRadius={9}
          width={120}
          height={120}
        />
      );
    },
    width: 120,
  };

  const quantityColumn = {
    title: 'Qty',
    field: 'quantity',
    render: (rowData) => {
      const { acceptedQuotationID, quotations } = rowData;
      const acceptedQuote = isEmptyValue(acceptedQuotationID)
        ? {}
        : quotations?.find((q) => q.quotationID === acceptedQuotationID);
      return acceptedQuote.quantity || rowData.quantity;
    },
    cellStyle: {
      width: '3%',
      maxWidth: 5,
    },
    width: 50,
  };

  const totalPriceColumn = {
    title: 'Total Price',
    field: 'totalPrice',
    render: (rowData) => {
      if (isEmptyValue(rowData.quotations) || !rowData.qTotalPrice) {
        return 'NA';
      }

      const { acceptedQuotationID, quotations } = rowData;
      const acceptedQuote = isEmptyValue(acceptedQuotationID)
        ? {}
        : quotations?.find((q) => q.quotationID === acceptedQuotationID);

      const totalCost = combinedServiceFee
        ? acceptedQuote.totalPriceInclPlatformFee || 0
        : acceptedQuote.totalPrice || rowData.qTotalPrice || 0;

      const qExchangeRate = getQuotationExchangeRate(acceptedQuote, currency);
      const { totalPriceStr, unitPriceStr } =
        convertPriceWithQuantityToCurrency({
          totalPrice: totalCost,
          currency,
          exchangeRate: qExchangeRate,
          quantity: acceptedQuote.quantity || rowData.qQuantity || 1,
        });

      return (
        <FlexColumn>
          <FtrH3 style={{ color: colors.neutral070, fontWeight: 600 }}>
            {totalPriceStr}
          </FtrH3>
          <FtrB1 style={{ color: colors.neutral070 }}>
            {unitPriceStr}/unit
          </FtrB1>
        </FlexColumn>
      );
    },
    cellStyle: {
      width: '5%',
      minWidth: 120,
    },
    width: 60,
  };

  const statusColumn = {
    title: 'Status',
    field: 'status',
    render: (rowData) => {
      const tooltip =
        rowData.status === ITEM_STATUS_MAPPING.QUOTES_AVAILABLE
          ? 'Click to expand all quotes'
          : 'Click to expand item details';

      let isShipping = false;
      if (!isEmptyValue(projectMCTs)) {
        const allAcceptedItems = projectMCTs?.flatMap(
          (project) => project.acceptedItems
        );
        isShipping =
          allAcceptedItems?.find((item) => item.itemID === rowData.itemID)
            ?.shipItem === true;
      }
      rowData.isShipping = isShipping;

      return (
        <Tooltip title={tooltip}>
          <div>
            <ItemStatusParent
              item={rowData}
              getItems={getItems}
              hasQuoteSelected={selectedQuotes
                ?.map((q) => q.itemID)
                .includes(rowData.itemID)}
            />
          </div>
        </Tooltip>
      );
    },
    width: 80,
  };

  // eslint-disable-next-line no-unused-vars
  const paymentStatusColumn = {
    title: 'Payment Status',
    field: 'paymentStatus',
    render: (rowData) => {
      return (
        <PaymentStatusSimpleLabelV2 paymentStatus={rowData.paymentStatus} />
      );
    },
    cellStyle: {
      width: '5%',
      maxWidth: 10,
    },
    width: 100,
  };
  const deliveryDateColumn = {
    title: 'Delivery Date',
    field: 'deliveryDate',
    render: (rowData) => <DeliveryDateCellV2 rowData={rowData} />,
    cellStyle: {
      width: '5%',
      minWidth: 150,
    },
    width: 100,
  };

  const renderSelectAllQuotes = () => {
    return (
      <FtrBlueCheckbox
        data-cy='select-all-quotes-checkbox'
        checked={isOneQuoteFromEveryItemSelected}
        onClick={(event) => handleSelectAllQuotes(event.target.checked)}
      />
    );
  };

  const selectAllQuotesColumn = {
    title: renderSelectAllQuotes(),
    //if visible, there are some items with at least one quote that can be selected
    hidden: isEmptyValue(firstValidQuoteForEachPart),
    width: 50,
  };

  // Declare columns
  const columns = [
    selectAllQuotesColumn,
    {
      ...partIDColumn,
      render: (rowData) => {
        const hasUnverifiedQuote = rowData.quotations?.some(isUnverifiedQuote);
        return hasUnverifiedQuote && isAdminView ? (
          <Tooltip title={'There are unverified quotes for this part'}>
            <Badge
              badgeContent={'!'}
              color='error'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {rowData.itemID}
            </Badge>
          </Tooltip>
        ) : (
          <>{rowData.itemID}</>
        );
      },
      width: 60,
    },
    imageColumn,
    nameColumn,
    quantityColumn,
    ...(isCalendarView ? [] : [totalPriceColumn]), // do not render this column if calendar view
    deliveryDateColumn,
    statusColumn,
    // paymentStatusColumn,
  ];

  return (
    <>
      <CustomerItemMuiTableV2Presentational
        columns={columns}
        rows={allParts}
        triggerExpand={triggerExpand}
        getItems={getItems}
        multiCheckoutForm={multiCheckoutForm}
        isMct={true}
        combinedServiceFee={combinedServiceFee}
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    currency: state.auth.location.currency,
    multiCheckoutForm: state.multiCheckoutForm,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    addQuoteToCheckout: (quote) => dispatch(addSelectedQuote(quote)),
    removeQuoteFromCheckout: (quote) => dispatch(removeSelectedQuote(quote)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withRouter(
  withConnect(withDeletePartConfirmPopupHOC(ProjectMultiCheckoutItemMuiTable))
);
