/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tour from './Tour';

import { DrawerButtonsContext } from '../ResponsiveSidebar';

import {
  buyerUploadPartsSummaryTour,
  clearTour as clearTourAction,
} from '../../actions';

import guidedTourState from './guidedTours/guidedTourState';

import useDeviceCheck from '../../hooks/useDeviceCheck';

import { showHiddenPopup } from './guidedTours/tourUtils';

// -------------------------------------------------------------------------------------------------

const withGuidedTour = (WrappedComponent) => {
  return (props) => {
    const { onCompleteOrCancel } = props;

    const dispatch = useDispatch();
    const [{ isMobile }] = useDeviceCheck();

    const userID = useSelector((state) => state.auth?.user?.userID);

    const {
      tourKey,
      tourSteps,
      tourOptions,
      updateTourState,
      userCompletedTourBefore,
      setTourEnded,
      isTourLoading,
    } = guidedTourState((state) => state);

    const [tour, setTour] = useState(null);

    const { closeDrawerButtonRef } = useContext(DrawerButtonsContext);

    useEffect(() => {
      updateTourState({
        userID,
        isMobile,
      });
      return () => {
        if (typeof clearTour === 'function') {
          clearTour();
        }
      };
    }, []);

    // Connects the tour to the redux store, so that getting started uses the correct tour as the page.
    useEffect(() => {
      updateTour(tour);
    }, [tour]);

    function updateTour(_tour) {
      dispatch(buyerUploadPartsSummaryTour(_tour));
    }

    function clearTour() {
      dispatch(clearTourAction());
    }

    function onCompleteOrCancelDefault() {
      closeDrawerButtonRef?.current?.click();
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

      showHiddenPopup();

      setTourEnded();
    }

    return (
      <>
        <WrappedComponent
          {...props}
          userCompletedTourBefore={userCompletedTourBefore}
          setTour={setTour}
          updateTourState={updateTourState}
          setTourEnded={setTourEnded}
          tour={tour}
          tourKey={tourKey}
        />
        {!isTourLoading && (
          <Tour
            setTour={setTour}
            tourOptions={tourOptions}
            tourSteps={tourSteps()}
            onCompleteOrCancel={onCompleteOrCancel || onCompleteOrCancelDefault}
          />
        )}
      </>
    );
  };
};

export default withGuidedTour;
