import React, { useState } from 'react';

import { IconButton, TextField, Tooltip } from '@material-ui/core';

import { AttachFile, Cancel } from '@material-ui/icons';

import { FlexColumn, FlexRow } from '../layouts/FlexLayouts';
import FilesUploadButton from '../FilesUploadButton';

import { REMARKS_STR } from '../../constants/quotationConstants';
import { FtrB2 } from '../ftr-components';

// -------------------------------------------------------------------------------------------------

function RfqRemarksInputWithAttachment(props) {
  const {
    remarksError,
    inputField,
    onInputFieldsChange,
    onSetAttachments = () => {},
  } = props;

  const [files, setFiles] = useState([]);

  const handleUploadFiles = async (_files) => {
    const fileArr = [];
    for (const file of _files) {
      const preview = URL.createObjectURL(file);
      file.preview = preview;
      fileArr.push(file);
    }
    const newFiles = [...files, ...fileArr];
    setFiles(newFiles);
    onSetAttachments(inputField.id, newFiles);
  };

  const removeFile = (file) => {
    const newFiles = files?.filter((f) => f !== file) || [];
    setFiles(newFiles);
    URL.revokeObjectURL(file.preview);
    onSetAttachments(inputField.id, newFiles);
  };

  return (
    <FlexRow style={{ alignItems: 'flex-start' }}>
      <TextField
        required
        label='Remarks / Clarifications'
        placeholder='Please enter any remarks (type NIL for no remarks)'
        multiline={true}
        variant='outlined'
        minRows={1}
        maxRows={2}
        fullWidth
        name={REMARKS_STR}
        error={remarksError}
        helperText={remarksError}
        onChange={(event) =>
          onInputFieldsChange(event, inputField.id, REMARKS_STR)
        }
        value={inputField.remarks}
        inputProps={{
          maxLength: 8000,
        }}
      />
      <FlexColumn style={{ width: 'fit-content' }}>
        <FilesUploadButton
          id={`remarks-attachments-${inputField.id}`}
          showButtonText={false}
          showTooltip={true}
          tooltip='Add Attachments'
          handleUploadFiles={handleUploadFiles}
          accept='.pdf, image/*'
          labelStyle={{ minWidth: 0 }}
          icon={<AttachFile />}
        />
        {files?.map((file, index) => (
          <FlexRow key={index}>
            <FtrB2>{file.name}</FtrB2>
            <Tooltip title='Remove'>
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={() => removeFile(file)}
                size='small'
              >
                <Cancel color='error' style={{ fontSize: '15pt' }} />
              </IconButton>
            </Tooltip>
          </FlexRow>
        ))}
      </FlexColumn>
    </FlexRow>
  );
}

export default RfqRemarksInputWithAttachment;
