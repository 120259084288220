import React from 'react';
import { makeStyles } from '@material-ui/core';
import { QUALITY_ITEM_LABELS } from '../../constants/itemConstants';
import clsx from 'clsx';
import SwitchThumb from './SwichThumb';
import FtrTypography from '../ftr-components/FtrTypography';

const useStyles = makeStyles(() => ({
  container: (props) => ({
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: props?.displayOneColumn ? '1fr' : '1fr 1fr',
  }),
  containerGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  descriptor: {
    padding: '0.5rem 0rem 1rem 0rem',
    fontSize: '16.5px',
    fontWeight: 600,
  },
}));

/**
 *
 * @param {Object} props
 * @param {Object} props.switchState
 * @param {() => void} props.onSwitchChange
 * @param {String} props.className
 * @param {Boolean} props.grouped
 * @param {Boolean} props.displayOneColumn display quality checks as one column instead of in a grid
 * @returns
 */
function QualityChecks(props) {
  const classes = useStyles(props);
  const {
    switchState,
    onSwitchChange = () => null,
    className,
    grouped,
    ...restProps
  } = props;

  const dataCustomer = Object.keys(switchState)?.filter((key) =>
    key.includes('customer')
  );
  const dataPartner = Object.keys(switchState)?.filter((key) =>
    key.includes('partner')
  );
  const data = Object.keys(switchState);

  if (grouped) {
    return (
      <div className={clsx(classes.container, className)} {...restProps}>
        <div className={classes.containerGroup}>
          <FtrTypography className={classes.descriptor}>
            Feedback by Customer (to Factorem)
          </FtrTypography>
          {dataCustomer?.map((option, index) => (
            <SwitchThumb
              key={index}
              option={option}
              isActive={!!+switchState[option]}
              onSwitchChange={onSwitchChange}
              label={QUALITY_ITEM_LABELS[option] ?? option}
            />
          ))}
        </div>
        <div className={classes.containerGroup}>
          <FtrTypography className={classes.descriptor}>
            Feedback by Factorem Internal (to Partner)
          </FtrTypography>
          {dataPartner?.map((option, index) => (
            <SwitchThumb
              key={index}
              option={option}
              isActive={!!+switchState[option]}
              onSwitchChange={onSwitchChange}
              label={QUALITY_ITEM_LABELS[option] ?? option}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={clsx(classes.container, className)} {...restProps}>
      {data?.map((option, index) => (
        <SwitchThumb
          key={index + 'switch-thumb'}
          option={option}
          isActive={!!+switchState[option]}
          onSwitchChange={onSwitchChange}
          label={QUALITY_ITEM_LABELS[option] ?? option}
        />
      ))}
    </div>
  );
}

export default QualityChecks;
