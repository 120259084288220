import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';

import { getCadFileTo3DRenderer, getS3File } from '../../utils/fileUtils';
import { CAD_RENDERER_TO_3D_TYPES } from '../../constants/cadRendererConstants';

/**
 *
 * @param {Object} props
 * @param {Number} props.timeout timeout of hit api
 * @param {Boolean} props.disableInput to disable input
 * @param {File} props.file a file to render
 * @param {string} props.fileUrl a url file to render
 */
function CADRendererAINC(props) {
  const { timeout = 30000, disableInput = false, file, urlFile } = props;
  const rendererRef = useRef(null);

  const [newFile, setNewFile] = useState(file);
  const [unSubscriber, setUnSubscriber] = useState(null);
  const [ready, setReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (newFile && newFile.size && ready) {
      processFile(newFile);
    }
  }, [newFile, ready]);

  useEffect(() => {
    setNewFile(file);
  }, [file]);

  // To convert urlFile to be file
  useEffect(() => {
    const asyncFun = async () => {
      if (!getCadFileTo3DRenderer(urlFile)) {
        console.warn('File is not supported!');
        return;
      }
      const s3File = await getS3File(urlFile);
      setNewFile(s3File);
    };
    if (urlFile) {
      asyncFun();
    }
  }, [urlFile]);

  ///////////////////////////////////////////// Handling Renderer Events ///////////////////////////////////////////////
  /**
   * Because we want the to be able to change the state according to events from the renderer an easy way of making sure
   * this works is to create methods on the component that we will then attach as event listeners.
   * (see https://github.com/AI-NC/renderer-react-demo/wiki/Renderer-Events for details on event handlers)
   */

  /**
   * A function that will be run when the renderer is fully loaded and ready to receive commands
   */
  function onConnect() {
    setReady(true);
  }
  /**
   * A function that will be run when the renderer is disconnected
   */
  function onDisconnect() {
    setReady(false);
  }
  /**
   * A function that will be run every time a model is finished loading in the renderer
   */
  function onModelLoaded() {}
  /**
   * A function that will be called while the renderer is in selection mode whenever a user clicks on a face or edge.
   *
   * @param {Object} event - A selection event
   * @param {(String | undefined)} event.target - The face/edge that was clicked on to trigger the event, or none if
   * nothing was clicked
   * @param {String[]} event.selected - All currently selected faces and edges
   */
  function onSelectionChanged(_event) {}
  /**
   * A function the renderer will use to send logging to this app
   *
   * @param {"info"|"warn"|"error"} level - The severity of the log message
   * @param {String} message - The content of the log message
   */
  function log(_level, _message) {
    // console[level](message);
  }

  useEffect(() => {
    const controller = new window.AbortController();
    setUnSubscriber(controller);
  }, []);

  useEffect(() => {
    const renderer = rendererRef.current;
    if (renderer && unSubscriber) {
      // First we want to create an Unsubscriber that will remove all our listeners when we pack up out component
      let signal = {
        signal: unSubscriber.signal,
      };
      /*
      Here hook up the listener events, you could just create the closures here, although given we want to be changing
      the renderers state it is easier to move the logic into function calls.
      
      We can also avoid any confusion with the context of `this` by explicitly calling methods on `Renderer`.
      */
      renderer.addEventListener(
        'connected',
        () => {
          onConnect();
        },
        signal
      );
      renderer.addEventListener(
        'disconnected',
        () => {
          onDisconnect();
        },
        signal
      );
      renderer.addEventListener(
        'modelLoaded',
        () => {
          onModelLoaded();
        },
        signal
      );
      renderer.addEventListener(
        'selectionChanged',
        (event) => {
          onSelectionChanged(event.detail);
        },
        signal
      );
      renderer.addEventListener(
        'log',
        (event) => {
          log(event.detail.level, event.detail.message);
        },
        signal
      );
    } else {
      console.error('Unable to find renderer when mounting component');
    }
  }, [unSubscriber]);

  /**
   * An function that gets a .golf file from the AI-NC API
   *
   * @param buffer A buffer containing the bytes of the .step file to load
   */
  async function processFile(_newFile) {
    const controller = new window.AbortController();
    const timeoutID = setTimeout(() => {
      controller.abort();
      throw new Error('Timeout');
    }, timeout);
    setIsLoading(true);
    const { REACT_APP_AINC_API_URL, REACT_APP_AINC_API_KEY } = process.env;
    let formData = new FormData();
    formData.append('file', _newFile);
    const response = await fetch(REACT_APP_AINC_API_URL, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: REACT_APP_AINC_API_KEY,
      },
      signal: controller.signal,
    });
    clearTimeout(timeoutID);
    const resArrayBuffer = await response.arrayBuffer();
    rendererRef.current.commands.loadGolf(resArrayBuffer, true);
    setIsLoading(false);
  }

  const rendererConfig = {
    base_color: [255, 255, 255],
    watermark: false,
    cam_sens: 0.5,
  };

  return (
    <div style={{ position: 'relative' }}>
      {!disableInput && (
        <input
          type='file'
          onChange={(e) => {
            if (!e.target.files) return;
            const _newFile = e.target.files[0];
            processFile(_newFile);
          }}
          accept={CAD_RENDERER_TO_3D_TYPES?.map((type) => `.${type}`)?.join(
            ', '
          )}
        />
      )}
      {isLoading && (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '1',
            color: 'white',
            animation: 'none',
          }}
        />
      )}
      <golf-renderer
        style={{
          width: '100%',
          height: '75vh',
        }}
        ref={rendererRef}
        renderer-config={JSON.stringify(rendererConfig)}
      />
    </div>
  );
}

export default CADRendererAINC;
