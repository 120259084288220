import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { get } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import { CircularProgress } from '@material-ui/core';

import CustomerPODisplay from '../components/CustomerPODisplay';
import DateFormatMethod from '../components/DateFormatMethod';
import DownloadCombinedQuotePDFButton from '../components/ftr-components/buttons/DownloadCombinedQuotePDFButton';
import FilesUploadActionButtonV2 from '../components/buttons/FilesUploadActionButtonV2';
import ProjectMultiCheckoutItemMuiTable from '../components/tables/ProjectMultiCheckoutItemMuiTable';
import ProjectNameFieldV2 from '../components/fields/ProjectNameFieldV2';
import ViewXeroInvoiceButton from '../components/ftr-components/buttons/ViewXeroInvoiceButton';
import FtrOrderAgainButton from '../components/ftr-components/buttons/FtrOrderAgainButton';
import {
  FlexColumn,
  FlexRow,
  FlexRowSpaceBetween,
} from '../components/layouts/FlexLayouts';
import { FtrB3, FtrS1, FtrS3, FtrH6 } from '../components/ftr-components';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import withOrderProjectsAgainPopupHOC from '../hocs/withOrderProjectsAgainPopupHOC';

import ProjectMultiCheckoutInfoContext from '../context/ProjectMultiCheckoutInfoContext';
import ViewShipmentsCalendarContext from '../context/ViewShipmentsCalendarContext';

import useCustomerProjectInfo from './manage-project-items/useCustomerProjectInfoHook';
import useCustomerSettingsHook from '../hooks/useCustomerSettingsHook';
import useUserCreditType from '../hooks/useUserCreditTypeHook';
import { useCustomerPOHook } from '../hooks/useCustomerPOHook';

import { isEmptyValue } from '../utils/commonUtils';
import {
  downloadS3File,
  extractAndDecodeFileNameAndExtensionFromUrl,
  extractFileNameWithoutTimestampFromUrl,
} from '../utils/fileUtils';
import { uniqueObjectsByFields } from '../utils/arrayUtils';
import {
  openCustomerOrdersProject,
  openInNewTab,
} from '../utils/navigationUtils';

import { getUserIDSelector } from '../selectors/userSelector';

import AppContext from '../context/AppContext';

import { ACTION_TRACKING_TYPE } from '../constants/actionTrackingConstants';
import { ITEM_FILE_UPLOAD_TYPE, QC_NO_QC_REPORT } from '../constants/';
import { ITEM_STATUS_MAPPING } from '../constants';
import { CUSTOMER_CREDIT_TYPES } from '../constants/customerConstants';

import { colors } from '../palette';

// --------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  '@global': {
    body: {
      backgroundColor: colors.neutral020,
    },
  },
  body: {
    padding: '2rem',
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
}));

function SingleMulticheckoutInfoDisplay(props) {
  const {
    projectID,
    mct = {},
    creditType,
    isUploading = false,
    downloadingQuotationForm = false,
    handlePOUploadForMultiCheckout = () => {},
    handleDeletePOFile = () => {},
    handleReplacePOFile = () => {},
    handleDownloadPOFile = () => {},
    refetchProjectInfoByID = () => {},
    downloadCombinedQuotationsForm = () => {},
    updateOrderProjectAgainPopupHOCState = () => {},
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const items = mct.items || [];
  const quotationFormUrl = mct.quotationFormUrl;
  const xeroInvoiceUrl = mct.xeroInvoiceUrl;
  const acceptedQcReports = mct.acceptedQcReports;
  const orderDate = DateFormatMethod({
    date: mct.createdDate,
    monthType: 'short',
  });

  const customerUploadedFiles =
    items?.flatMap((i) => i.customerUploadFiles || []) || [];

  let customerPOFiles = customerUploadedFiles?.filter(
    (f) => f.type === ITEM_FILE_UPLOAD_TYPE.CUSTOMER_PO
  );

  customerPOFiles = uniqueObjectsByFields(customerPOFiles, ['fileName', 'url']);

  const isOrderCompleted = items?.every(
    (i) => i.status === ITEM_STATUS_MAPPING.DELIVERED
  );
  const invoiceEnabled =
    isOrderCompleted || creditType === CUSTOMER_CREDIT_TYPES.NON_CREDIT;

  const renderPOUploadedFiles = () => {
    if (isEmptyValue(customerPOFiles)) {
      return;
    }

    return (
      <FlexRow style={{ alignItems: 'start' }}>
        <FtrS3 style={{ marginTop: 4 }}>PO file:</FtrS3>
        <div>
          <FlexColumn>
            {customerPOFiles?.map((f) => {
              const [fileName, fileExtension] =
                extractAndDecodeFileNameAndExtensionFromUrl(f.fileName);

              return (
                <CustomerPODisplay
                  key={f.url}
                  handleDeletePOFile={() => {
                    handleDeletePOFile(f, mct.id);
                  }}
                  handleDownloadPOFile={() =>
                    handleDownloadPOFile(f.url, f.fileName)
                  }
                  handleReplacePOFile={(files) =>
                    handleReplacePOFile(f, files[0], mct.id)
                  }
                >
                  <FtrS1 style={{ color: colors.green050 }}>
                    {fileName}.
                    <span style={{ fontWeight: 400 }}>{fileExtension}</span>
                  </FtrS1>
                </CustomerPODisplay>
              );
            })}
          </FlexColumn>
        </div>
      </FlexRow>
    );
  };

  const renderPOUploadButton = () => {
    if (!isEmptyValue(customerPOFiles)) {
      return;
    }

    return (
      <FilesUploadActionButtonV2
        style={{ marginLeft: isMobile ? 0 : '0.25rem' }}
        isMobile={isMobile}
        inputID={`upload-po-${mct.id}`}
        buttonText='Upload PO'
        handleUploadFiles={(files) => {
          handlePOUploadForMultiCheckout(files, mct.id);
        }}
        multiple={false}
        accept='.pdf, image/*'
        disabled={isUploading}
      />
    );
  };

  const renderQcReportInfo = () => {
    const main = get(acceptedQcReports, ['main']);

    if (
      isEmptyValue(acceptedQcReports) ||
      isEmptyValue(main) ||
      main === QC_NO_QC_REPORT
    ) {
      return;
    }

    const addOns = get(acceptedQcReports, ['addOns']) || [];
    const others = get(acceptedQcReports, ['others']) || [];
    const addOnsArr = [...addOns, ...others];

    return (
      <FlexRow>
        <FtrS3>
          Certification: <span style={{ fontWeight: 400 }}>{main}</span>
        </FtrS3>
        {!isEmptyValue(addOnsArr) && (
          <FtrS3>
            | Add-ons:{' '}
            <span style={{ fontWeight: 400 }}>{addOnsArr?.join(', ')}</span>
          </FtrS3>
        )}
      </FlexRow>
    );
  };

  if (isEmptyValue(mct.items)) {
    return null;
  }

  return (
    <FlexColumn style={{ gap: '0.8rem' }}>
      <FlexRowSpaceBetween style={{ flexWrap: 'wrap', alignItems: 'end' }}>
        <FlexColumn style={{ width: 'auto' }}>
          <FtrS3>
            Order Reference:{' '}
            <span style={{ fontWeight: 400 }}>{mct.referenceName}</span>
          </FtrS3>
          <FtrS3>
            Date of Order: <span style={{ fontWeight: 400 }}>{orderDate}</span>
          </FtrS3>
          {renderQcReportInfo()}
          {renderPOUploadedFiles()}
        </FlexColumn>

        <FlexRow
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.25rem',
            flexWrap: 'wrap',
          }}
        >
          <FtrOrderAgainButton
            style={{ marginRight: isMobile ? 0 : '0.25rem' }}
            isMobile={isMobile}
            disabled={false}
            onClick={() => {
              updateOrderProjectAgainPopupHOCState({
                open: true,
                orderAgainItemList: mct.items,
              });
            }}
          />
          {!isEmptyValue(quotationFormUrl) && (
            <DownloadCombinedQuotePDFButton
              actionTrackingKey={ACTION_TRACKING_TYPE.DOWNLOAD_FILE}
              additionalInfo={{
                urls: [quotationFormUrl],
              }}
              trackingInfo={{
                projectID,
              }}
              onClick={() => downloadCombinedQuotationsForm(quotationFormUrl)}
              loading={downloadingQuotationForm}
              disabled={downloadingQuotationForm}
            />
          )}
          <ViewXeroInvoiceButton
            onClick={() => openInNewTab(xeroInvoiceUrl)}
            tooltip={
              invoiceEnabled
                ? 'View Invoice'
                : 'Invoice will be available upon order completion.'
            }
            disabled={!invoiceEnabled}
          />
          {renderPOUploadButton()}
        </FlexRow>
      </FlexRowSpaceBetween>
      <ProjectMultiCheckoutItemMuiTable
        data={items}
        getItems={refetchProjectInfoByID}
        projectID={projectID}
        isMct={true}
      />
    </FlexColumn>
  );
}

function ProjectMultiCheckoutInfo(props) {
  const classes = useStyles();

  const pathParams = useParams();

  const {
    itemsFilterFn = () => true,
    projectID: projectIDFromPopup,
    isCalendarView = false,
  } = useContext(ViewShipmentsCalendarContext);

  const { setSnackbarMessage } = useContext(AppContext);

  const projectID =
    pathParams.projectID ||
    props.location?.state?.projectID ||
    projectIDFromPopup;

  const userID = useSelector(getUserIDSelector);

  const [
    {
      currentProjectDetail,
      allParts: allPartsUnfiltered = [],
      currentProjectName,
      isProjectLoading = false,
      customerID,
    },
    { refetchProjectInfoByID },
  ] = useCustomerProjectInfo(projectID);

  const { customerSettings } = useCustomerSettingsHook(customerID);

  const allParts = useMemo(
    () => allPartsUnfiltered?.filter(itemsFilterFn),
    [allPartsUnfiltered]
  );

  const multipleCheckouts = currentProjectDetail?.multipleCheckouts || [];

  const {
    isUploading,
    handlePOUploadForMultiCheckout,
    handleDeletePOFile,
    handleReplacePOFile,
  } = useCustomerPOHook({
    setSnackbarMessage,
    refetch: refetchProjectInfoByID,
  });

  const { data: creditType } = useUserCreditType(userID);

  const [downloadingQuotationForm, setDownloadingQuotationForm] =
    React.useState(false);

  const handleDownloadPOFile = (url, fileName) => {
    downloadS3File(url, { fileNameParam: fileName });
  };

  const downloadCombinedQuotationsForm = (url) => {
    setDownloadingQuotationForm(true);
    const fileNameParam = extractFileNameWithoutTimestampFromUrl(url);
    downloadS3File(url, { fileNameParam }).then(() =>
      setDownloadingQuotationForm(false)
    );
  };

  const renderHeading = () => {
    return (
      <FlexColumn style={{ marginBottom: '2rem' }}>
        {isCalendarView ? (
          <FtrH6>{currentProjectName}</FtrH6>
        ) : (
          <ProjectNameFieldV2
            projectName={currentProjectName}
            isProjectDeletable={false}
            editable={false}
            style={{
              paddingLeft: 0,
            }}
          />
        )}
        <FtrB3 style={{ fontWeight: 600 }}>
          Project ID:&nbsp;
          <span style={{ color: colors.neutral070, fontWeight: 400 }}>
            {!isCalendarView ? (
              projectID
            ) : (
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  openCustomerOrdersProject(projectID);
                }}
              >
                {projectID}
              </Link>
            )}
          </span>
        </FtrB3>
      </FlexColumn>
    );
  };

  if (isProjectLoading) {
    return <CircularProgress />;
  }

  return (
    <ProjectMultiCheckoutInfoContext.Provider
      value={{
        allParts,
        currentProjectDetail,
        currentProjectName,
        refetchProjectInfoByID,
        customerID,
        customerSettings,
      }}
    >
      <div className={classes.body}>
        {renderHeading()}
        <FlexColumn
          style={{ gap: '3rem', width: '100%', boxSizing: 'border-box' }}
        >
          {multipleCheckouts?.map((mct) => {
            const mctItems = mct.acceptedItems?.reduce((acc, item) => {
              const itemInfo = allParts?.find(
                (part) => part.itemID === item.itemID
              );
              if (itemInfo) {
                acc.push(itemInfo);
              }
              return acc;
            }, []);
            mct.items = mctItems;
            return (
              <SingleMulticheckoutInfoDisplay
                key={mct.id}
                projectID={projectID}
                mct={mct}
                isUploading={isUploading}
                creditType={creditType}
                downloadingQuotationForm={downloadingQuotationForm}
                handlePOUploadForMultiCheckout={handlePOUploadForMultiCheckout}
                handleDeletePOFile={handleDeletePOFile}
                handleReplacePOFile={handleReplacePOFile}
                handleDownloadPOFile={handleDownloadPOFile}
                refetchProjectInfoByID={refetchProjectInfoByID}
                downloadCombinedQuotationsForm={downloadCombinedQuotationsForm}
                updateOrderProjectAgainPopupHOCState={
                  props.updateOrderProjectAgainPopupHOCState
                }
              />
            );
          })}
        </FlexColumn>
      </div>
    </ProjectMultiCheckoutInfoContext.Provider>
  );
}

export default withOrderProjectsAgainPopupHOC(ProjectMultiCheckoutInfo);
