import React, { Fragment, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import RobotIcon from '../../../assets/icons/robot.svg';
import InfoIcon from '../../icons/InfoIcon';

import FtrFieldLabel from '../../ftr-components/FtrFieldLabel';
import {
  FlexColumn,
  FlexColumnCenter,
  FlexRow,
} from '../../layouts/FlexLayouts';
import { FtrBanner, FtrButton, FtrDropdown } from '../../ftr-components';
import FtrTypography, { FtrH5 } from '../../ftr-components/FtrTypography';

import { isEmptyValue } from '../../../utils/commonUtils';

import {
  CUSTOMER_TYPES,
  INDUSTRIES,
} from '../../../constants/customerConstants';

import { colors } from '../../../palette';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      borderRadius: 0,
    },
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxArea: {
    'span.MuiButtonBase-root': {
      padding: '0 !important',
    },
  },
  checkboxRoot: {
    padding: 2,
  },
  formControl: {
    display: 'inline',
    paddingBottom: '20px',
  },
  select: {
    minWidth: '220px',
    margin: '10px',
    width: 'fit-content',
  },
}));

function SummaryClarificationPopup(props) {
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    open,
    handleClose,
    handleSubmit,
    partsNeedClarify,
    hasIndustry,
    hasCustomerType,
    selectedIndustry,
    selectedCustomerType,
    onSameFactoryCheckboxChange = () => {},
    onIndustrySelect = () => {},
    onCustomerTypeSelect = () => {},
  } = props;

  const industry = selectedIndustry;
  const customerType = selectedCustomerType;

  const [activeStep, setActiveStep] = useState(0);

  const components = [];

  if (!hasIndustry || !hasCustomerType) {
    components.push(renderClarificationStep);
  }
  if (!isEmptyValue(partsNeedClarify)) {
    components.push(renderPartsNeedClarify);
  }

  const navigateStep = (step) => {
    let nextStep = activeStep + step;
    if (nextStep < 0) {
      nextStep = 0;
    } else if (nextStep > components.length - 1) {
      handleSubmit();
      handleClose();
      return;
    }
    setActiveStep(nextStep);
  };

  function closePopup() {
    handleClose();
  }

  const renderCheckboxLabel = () => {
    return (
      <FtrTypography
        type='body'
        fontSize='16'
        style={{
          color: colors.neutral070,
        }}
      >
        Use same factory for this order
      </FtrTypography>
    );
  };

  function renderPartsNeedClarify() {
    return partsNeedClarify?.map((part) => (
      <FtrBanner
        key={part.itemID}
        style={{
          marginBottom: '1rem',
          width: '100%',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          boxSizing: 'border-box',
        }}
        type='normal'
      >
        <FtrTypography
          type='subHeading'
          fontSize='16'
          style={{
            color: colors.neutral070,
            marginBottom: '0.5rem',
          }}
        >
          {part.name}
        </FtrTypography>
        <FlexColumn>
          {part.sameRepeatOwner && (
            <Fragment>
              <FtrTypography
                type='body'
                fontSize='16'
                style={{ color: colors.neutral070 }}
              >
                This is a repeat order. Please select the checkbox if you
                require the same factory to make this order.
              </FtrTypography>
              <div
                className={classes.checkboxArea}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: 4,
                }}
              >
                <FlexRow style={{ gap: 2 }}>
                  <FormControlLabel
                    style={{
                      marginLeft: '0.5rem',
                      marginRight: '0.5rem',
                    }}
                    control={
                      <Checkbox
                        classes={{
                          root: classes.checkboxRoot,
                        }}
                        checked={part.requestSameFabricator}
                        onChange={(event) =>
                          onSameFactoryCheckboxChange(
                            part.itemID,
                            event.target.checked
                          )
                        }
                      />
                    }
                    label={renderCheckboxLabel(part.requestSameFabricator)}
                  />
                  <InfoIcon toolTipText='If not selected, we will match your order to any qualified factories that can meet the requirements for this order.' />
                </FlexRow>
                {part.requestSameFabricator && (
                  <Typography
                    style={{
                      color: colors.blue050,
                      fontStyle: 'italic',
                      marginLeft: '2.1rem',
                    }}
                    variant='caption'
                  >
                    You will be informed in case the fabricator is not able to
                    take up the order at this stage.
                  </Typography>
                )}
              </div>
            </Fragment>
          )}
          {part.needsPdf && (
            <FtrTypography
              type='body'
              fontSize='16'
              style={{ color: colors.neutral070 }}
            >
              2D files/technical drawings missing for this part.
            </FtrTypography>
          )}
        </FlexColumn>
      </FtrBanner>
    ));
  }

  function renderClarificationStep() {
    return (
      <FlexColumn
        style={{
          maxWidth: 300,
          width: '100%',
          gap: '1rem',
        }}
      >
        {!hasCustomerType && (
          <FlexColumn style={{ gap: 0 }}>
            <FtrFieldLabel>I am a/an</FtrFieldLabel>
            <FtrDropdown
              key='i-am-dropdown'
              fullWidth
              value={customerType}
              handleChange={(newValue) => {
                // setCustomerType(newValue);
                onCustomerTypeSelect(newValue);
              }}
              items={Object.values(CUSTOMER_TYPES)}
            />
          </FlexColumn>
        )}
        {!hasIndustry && (
          <FlexColumn style={{ gap: 0 }}>
            <FtrFieldLabel>My industry sector is</FtrFieldLabel>
            <FtrDropdown
              key='i-am-dropdown'
              fullWidth
              value={industry}
              handleChange={(newValue) => {
                // setIndustry(newValue);
                onIndustrySelect(newValue);
              }}
              items={Object.values(INDUSTRIES)}
            />
          </FlexColumn>
        )}
      </FlexColumn>
    );
  }

  const renderDialogTitle = () => {
    return (
      <FlexColumnCenter
        style={{
          gap: '1.5rem',
        }}
      >
        <img src={RobotIcon} />
        <FtrH5>Just a few more clarifications</FtrH5>
      </FlexColumnCenter>
    );
  };

  const renderDialogActions = () => {
    return (
      <FlexColumnCenter
        style={{
          gap: '1rem',
          marginTop: '2rem',
          maxWidth: 200,
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <FtrButton
          id='proceed-to-create-project-btn'
          color='blue'
          variant='contained'
          onClick={() => navigateStep(1)}
          autoFocus
          fullWidth
        >
          Continue
        </FtrButton>
        <FtrButton variant='text-black' onClick={closePopup}>
          Go back to order
        </FtrButton>
      </FlexColumnCenter>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={closePopup}
      fullWidth
      maxWidth='sm'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      fullScreen={isMobile}
    >
      <DialogTitle>{renderDialogTitle()}</DialogTitle>
      <DialogContent>
        <FlexColumnCenter>{components[activeStep]()}</FlexColumnCenter>
      </DialogContent>
      {renderDialogActions()}
    </Dialog>
  );
}

export default SummaryClarificationPopup;
