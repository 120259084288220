import React, { useState } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';

import { FtrButton, FtrTypography } from '../components/ftr-components';

import screwIcon from '../assets/icons/screw.svg';

import useSamplePartsHook from '../hooks/useSamplePartsHook';

import { asyncMap } from '../utils/arrayUtils';
import { isEmptyValue } from '../utils/commonUtils';
import {
  getFileExtension,
  getFileNameFromUrl,
  getS3File,
} from '../utils/fileUtils';

// -------------------------------------------------------------------------------------------------

function GetSampleUpload(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isLoading, setIsLoading] = useState(false);

  const { handleCadFilesChange } = props;

  const { data: sampleParts, isLoading: isGettingSampleParts } =
    useSamplePartsHook();

  const handleSamplePartUpload = async () => {
    setIsLoading(true);
    const defaultFiles = await asyncMap(sampleParts, async (obj) => {
      const fileExt = getFileExtension(obj.cadUrl);
      const [cadFile, pdfFile, imageFile] = await Promise.all([
        getS3File(obj.cadUrl, {
          fileNameParam: `${obj.name}.${fileExt}`,
        }),
        !isEmptyValue(obj.pdfUrl)
          ? getS3File(obj.pdfUrl, {
              fileNameParam: `${obj.name}.pdf`,
            })
          : Promise.resolve(undefined),
        !isEmptyValue(obj.imageUrl)
          ? getS3File(obj.imageUrl, {
              fileNameParam: `${getFileNameFromUrl(obj.imageUrl)}`,
            })
          : Promise.resolve(undefined),
      ]);

      return {
        ...obj,
        cadFile,
        pdfFile,
        imageFile,
      };
    });

    handleCadFilesChange(defaultFiles);
  };

  return (
    <div>
      <FtrTypography
        type='heading'
        fontSize={isMobile ? '16' : '18'}
        style={{
          paddingTop: isMobile ? '2rem' : '5rem',
          textAlign: 'center',
        }}
      >
        Get started quickly and see how Factorem works
      </FtrTypography>
      <div
        style={{
          marginTop: 20,
          fontSize: '10pt',
          textAlign: 'center',
          paddingBottom: '2rem',
        }}
      >
        <FtrButton
          id='use-sample-part'
          color='black'
          size='small'
          onClick={handleSamplePartUpload}
          startIcon={<img src={screwIcon} alt='logo' />}
          loading={isLoading}
          disabled={
            isLoading || isEmptyValue(sampleParts) || isGettingSampleParts
          }
        >
          Use sample parts
        </FtrButton>
      </div>
    </div>
  );
}

export default GetSampleUpload;
