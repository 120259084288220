import { isEmptyValue } from './commonUtils';
import { isEqual, sortBy } from 'lodash';

export const join = (arr, separator) => {
  if (typeof arr === 'string') {
    return arr;
  }

  if (isEmptyValue(arr)) {
    return '';
  }

  return arr?.join(separator);
};

/**
 * Check if a given value is an array of strings.
 *
 * @param {Array|string|*} value - The value to check.
 * @return {boolean} Returns true if the value is an array of strings, false otherwise.
 */
export const isArrayString = (value) => {
  return (
    Array.isArray(value) && value?.every((item) => typeof item === 'string')
  );
};

/**
 * Check if two arrays are equal.
 *
 * @param {Array} arr1 - The first array to compare.
 * @param {Array} arr2 - The second array to compare.
 * @return {boolean} Returns true if the arrays are equal, false otherwise.
 */
export const equalArrays = (arr1, arr2) => {
  const deepSort = (arr) => sortBy(arr, (item) => JSON.stringify(item)); // Sort by stringified object

  return isEqual(deepSort(arr1), deepSort(arr2));
};

/**
 * Maps an array to a new array using a given mapper function.
 *
 * If the given array is null or undefined, returns an empty array.
 *
 * @param {Array} arr - The array to map.
 * @param {Function} mapper - The function to use for mapping.
 * @return {Array} The new array.
 */
export const map = (arr, mapper) => {
  if (isEmptyValue(arr)) {
    return [];
  }

  return arr?.map(mapper);
};

/**
 * Asynchronously maps an array to a new array using a given async mapper function.
 *
 * The function applies the async mapper function to each element of the array
 * and returns a Promise that resolves to a new array with the mapped results.
 *
 * @param {Array} arr - The array to map.
 * @param {Function} mapper - The async function to use for mapping.
 * @return {Promise<Array>} A promise that resolves to the new array.
 */
export const asyncMap = async (arr, mapper) => {
  return Promise.all(arr?.map(mapper));
};

/**
 * Converts an array into a string with values separated by a comma.
 *
 * @param {Array} array - The array to convert.
 * @returns {string} - The string with separated values.
 * @example
 * const inputArray = ['red', 'green', 'blue'];
 * const separatedString = arrayToSeparatedComma(inputArray);
 * Output: 'red, green, blue'
 */
export const arrayToSeparatedComma = (array) => {
  return Array.isArray(array) ? array?.join(', ') : array;
};

/**
 * Reduces an array to a single value using a given reducer function.
 *
 * If the given array is null or undefined, returns the initial value.
 *
 * @param {Array} arr - The array to reduce.
 * @param {Function} reducer - The function to use for reduction. Takes two arguments, the accumulator and the current element.
 * @param {any} initialValue - The initial value to use for the accumulator.
 * @return {any} The reduced value.
 */
export const reduce = (arr, reducer, initialValue) => {
  if (isEmptyValue(arr)) {
    return initialValue;
  }

  return arr?.reduce(reducer, initialValue);
};

/**
 * Asynchronously reduces an array to a single value using a given async reducer function.
 *
 * The function applies the async reducer function to each element of the array
 * and returns a Promise that resolves to the reduced value.
 *
 * If the given array is null or undefined, returns the initial value.
 *
 * @param {Array} arr - The array to reduce.
 * @param {Function} reducer - The async function to use for reduction. Takes four arguments, the accumulator, the current element, the index, and the array.
 * @param {any} initialValue - The initial value to use for the accumulator.
 * @return {Promise<any>} A promise that resolves to the reduced value.
 */
export const asyncReduce = async (arr, reducer, initialValue) => {
  let accumulator = initialValue;

  for (let index = 0; index < arr.length; index++) {
    accumulator = await reducer(accumulator, arr[index], index, arr);
  }

  return accumulator;
};

/**
 * Transforms an array into a JavaScript object based on a specified field.
 *
 * @param {Array} arr - The input array.
 * @param {string} field - The field to use as the key in the object.
 * @returns {Object} - The transformed JavaScript object.
 *
 * @example
 * const inputArray = [
 *   { id: 1, name: 'John' },
 *   { id: 2, name: 'Jane' },
 *   { id: 3, name: 'John' },
 *   { id: 4, name: 'Jane' }
 * ];
 *
 * const transformedObject = transformArrayToObject(inputArray, 'id');
 * Output: {
 *   1: { id: 1, name: 'John' },
 *   2: { id: 2, name: 'Jane' },
 *   3: { id: 3, name: 'John' },
 *   4: { id: 4, name: 'Jane' }
 * }
 */
export const transformArrayToMapObject = (arr, field) => {
  const transformedObject = {};
  for (const obj of arr) {
    const key = obj[field];
    transformedObject[key] = obj;
  }
  return transformedObject;
};

/**
 * Filters out duplicate objects in an array based on a list of fields.
 * The duplicate check is done by joining the field values with a '-' and
 * checking if the resulting string is in the Set of unique keys.
 *
 * @param {Array} arr - The input array.
 * @param {Array<string>} fields - The fields to check for uniqueness.
 * @returns {Array} - The filtered array with unique objects.
 * @example
 * const inputArray = [
      { id: 1, name: 'John' },
      { id: 2, name: 'Jane' },
      { id: 3, name: 'John' },
      { id: 4, name: 'Jane' }
    ];

    const uniqueArray = uniqueObjectsByFields(inputArray, ['name']);
    // Output: [
    //   { id: 1, name: 'John' },
    //   { id: 2, name: 'Jane' }
    // ]
 */
export function uniqueObjectsByFields(arr, fields) {
  const uniqueObjects = [];
  const uniqueKeys = new Set();

  for (const obj of arr) {
    const key = fields?.map((field) => obj[field])?.join('-');

    if (!uniqueKeys.has(key)) {
      uniqueKeys.add(key);
      uniqueObjects.push(obj);
    }
  }

  return uniqueObjects;
}

/**
 * Returns a new array with unique values from the given array.
 * The unique check is done by using a Set, which removes duplicate values.
 *
 * @param {Array} arr - The input array.
 * @returns {Array} - The new array with unique values.
 * @example
 * const inputArray = [1, 2, 2, 3, 3, 3];
 * const uniqueArray = uniqueArray(inputArray);
 * Output: [1, 2, 3]
 */
export const uniqueArray = (arr) => {
  return [...new Set(arr)];
};

/**
 * Reducer to create a unique array from an array of values.
 *
 * @param {Array} acc - The accumulator array.
 * @param {*} curr - The current value.
 * @returns {Array} - The new array with the current value if it is not already present.
 */
export const uniqueArrayReducer = (acc, curr) => {
  return uniqueArray([...acc, curr]);
};

/**
 * Finds the first element in the given array that satisfies the given predicate.
 *
 * If the given array is null or undefined, returns undefined.
 *
 * @param {Array} arr - The array to search in.
 * @param {Function} predicate - The function to call for each element.
 * @returns {*} - The first element that satisfies the predicate, or undefined if none does.
 */
export const findInArray = (arr, predicate) => {
  if (isEmptyValue(arr)) {
    return undefined;
  }

  return arr?.find(predicate);
};

/**
 * Filters an array based on a predicate function.
 *
 * @param {Array} arr - The array to filter.
 * @param {Function} predicate - The function invoked per iteration.
 * @returns {Array} - The new filtered array.
 */
export const filter = (arr, predicate) => {
  if (!Array.isArray(arr)) {
    return [];
  }

  return arr?.filter(predicate);
};

/**
 * Selects an object from the given array of objects based on a range.
 * The object is selected if the given value is within the range defined by the `from` and `to` properties of the object.
 * If the array is empty, returns null.
 *
 * @param {Array<Object>} arrObj - The array of objects with `from` and `to` properties.
 * @param {*} value - The value to check against the range.
 * @returns {Object|null} - The selected object, or null if none is found.
 */
export const selectFromArrayObjectRange = (arrObj, value) => {
  if (isEmptyValue(arrObj)) {
    return null;
  }
  for (const obj of arrObj) {
    if (value >= obj.from && value <= obj.to) {
      return obj;
    }
  }
  return null;
};

/**
 * Checks if any of the provided values are empty as per definition in commontUtils.isEmptyValue.
 *
 * @param {Array<Any>}
 * @returns {boolean} - True if any value is empty, False otherwise.
 */
export const isAnyEmptyValue = (...args) => {
  return args.some(isEmptyValue);
};

/**
 * Checks if all of the provided values are empty as per definition in commonUtils.isEmptyValue.
 *
 * @param {Array<Any>}
 * @returns {boolean} - True if all values are empty, False otherwise.
 */
export const isAllEmptyValue = (...args) => {
  return args.every(isEmptyValue);
};
