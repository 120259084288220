import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getUserSelector } from '@selectors/userSelector';

import {
  FtrBoldText,
  FtrButton,
  FtrIconButton,
  FtrTooltip,
} from '../ftr-components';
import GrayBox from '../ftr-components/GrayBox';
import FtrSvgImage from '../images/FtrSvgImage';
import { FlexColumn, FlexRow } from '../layouts/FlexLayouts';
import PartDetailsV2InfoPresentational from '../popups/confirm-order-popup/PartDetailsV2InfoPresentational';
import DfmAnalysisButton from '@components/DfmDefects/DfmAnalysisButton';

import DownloadIcon from '../../assets/icons/download-icon.svg';

import withConfigureEditRfqPartFormHOC from '../../hocs/withConfigureEditRfqPartFormHOC';

import { isEmptyValue } from '../../utils/commonUtils';
import {
  downloadS3File,
  extractAndDecodeFileNameAndExtensionFromUrl,
  getCadFileTo3DRenderer,
} from '../../utils/fileUtils';
import { isPpeItem, isSampleParts } from '../../utils/itemUtils';
import { getTechnicalDrawingFiles } from '../../utils/tdeUtils';
import {
  isSourceUrlAndTechValid,
  showDfmPueMpr,
} from '@utils/dfmExtractionUtils';

import { colors } from '../../palette';

import { ITEM_STATUS_MAPPING } from '../../constants';

// ------------------------------------------------------------------------------------

function FileDisplay(props) {
  const { fileName, fileExtension, fileUrl, downloadable = true } = props;

  const [waiting, setWaiting] = React.useState(false);

  const cursor = useMemo(() => {
    if (!downloadable) {
      return 'auto';
    }

    return !isEmptyValue(fileUrl) ? (waiting ? 'wait' : 'pointer') : 'auto';
  }, [downloadable, fileUrl]);

  return (
    <GrayBox
      style={{
        width: 'fit-content',
        cursor,
      }}
    >
      <FlexRow style={{ gap: 0 }}>
        <FtrBoldText
          style={{ color: colors.neutral060 }}
          onClick={() => {
            if (!downloadable) return;

            if (!isEmptyValue(fileUrl)) {
              setWaiting(true);
              document.body.style.cursor = 'wait';
              downloadS3File(fileUrl, {
                fileNameParam: `${fileName}.${fileExtension}`,
              }).then(() => {
                document.body.style.cursor = 'auto';
                setWaiting(false);
              });
            }
          }}
        >
          {fileName}
          <span style={{ fontWeight: 400 }}>.{fileExtension}</span>
        </FtrBoldText>
      </FlexRow>
    </GrayBox>
  );
}

function FileDisplayWithDownloadIcon(props) {
  const { fileUrl, downloadable = true } = props;

  const [fileName, fileExtension] = useMemo(() => {
    return extractAndDecodeFileNameAndExtensionFromUrl(fileUrl);
  }, [fileUrl]);

  if (isEmptyValue(fileUrl)) {
    return null;
  }

  return (
    <FlexRow>
      <FileDisplay
        fileName={fileName}
        fileExtension={fileExtension}
        fileUrl={fileUrl}
        downloadable={downloadable}
      />
      {renderDownloadIcon()}
    </FlexRow>
  );

  function renderDownloadIcon() {
    if (!downloadable) return;

    return (
      <FtrTooltip description='Click to download'>
        <div>
          <FtrIconButton variant='text' onClick={() => downloadS3File(fileUrl)}>
            <FtrSvgImage
              src={DownloadIcon}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          </FtrIconButton>
        </div>
      </FtrTooltip>
    );
  }
}

function PartDetailsDropdownV2(props) {
  const { partInfo = {}, updateConfigureEditRfqPartState } = props;

  const user = useSelector(getUserSelector);

  const renderDfmAnalysisButton = () => {
    const cadFile = partInfo.cadFile || partInfo.originalFiles;
    const isDfmAnalysisValid = isSourceUrlAndTechValid(
      cadFile?.split(','),
      partInfo.technology
    );
    const isDefectEmptyAndInvalid =
      isEmptyValue(partInfo.dfmDefectOutput) && !isDfmAnalysisValid;
    if (
      isDefectEmptyAndInvalid ||
      !showDfmPueMpr(user.dfmType) ||
      isEmptyValue(partInfo?.dfmDefectOutput)
    ) {
      return null;
    }
    return (
      <DfmAnalysisButton
        showIcon
        dfmDefectOutput={
          partInfo.dfmDefectOutput.defects ?? partInfo.dfmDefectOutput
        }
        isLoading={partInfo.dfmDefectOutput === 'loading'}
        cadFile={partInfo.cadFile || partInfo.originalFiles}
        twoDImageUrl={partInfo.imageFile || partInfo.twoDImageUrl}
        itemID={partInfo.itemID}
        style={{ marginTop: 0 }}
      />
    );
  };

  const renderCadFile = () => {
    const filesStr = partInfo.originalFiles || partInfo.cadFile;
    const filesArr = filesStr?.split(',') || [];
    // if cad file is empty then use the first files
    const cadFileLink = getCadFileTo3DRenderer(filesArr) || filesArr?.[0];

    const isSample = useMemo(() => isSampleParts(cadFileLink), [cadFileLink]);

    return (
      <FlexColumn style={{ gap: '1rem' }}>
        <FtrBoldText style={{ color: colors.headerGrey }}>
          Design/CAD File
        </FtrBoldText>
        <FlexRow>
          <FileDisplayWithDownloadIcon
            fileUrl={cadFileLink}
            downloadable={!isSample}
          />
          {renderDfmAnalysisButton()}
        </FlexRow>
      </FlexColumn>
    );
  };

  const renderTechnicalDrawingFiles = () => {
    const files = partInfo.originalFiles || partInfo.cadFile;
    const drawingFiles = getTechnicalDrawingFiles(files?.split(','));

    if (isEmptyValue(drawingFiles)) {
      return;
    }

    return (
      <FlexColumn style={{ gap: '1rem' }}>
        <FtrBoldText style={{ color: colors.headerGrey }}>
          Technical Drawing
        </FtrBoldText>
        {drawingFiles?.map((drawingFile) => {
          const isSample = useMemo(
            () => isSampleParts(drawingFile),
            [drawingFile]
          );

          return (
            <FileDisplayWithDownloadIcon
              key={drawingFile}
              fileUrl={drawingFile}
              downloadable={!isSample}
            />
          );
        })}
      </FlexColumn>
    );
  };

  const renderEditConfiguration = () => {
    if (
      isPpeItem(partInfo) ||
      partInfo?.status !== ITEM_STATUS_MAPPING.VERIFYING
    ) {
      return null;
    }

    return (
      <FlexRow>
        <FtrButton
          style={{ padding: 0 }}
          variant='text'
          color='blue'
          onClick={() => {
            updateConfigureEditRfqPartState({
              open: true,
              item: partInfo,
            });
          }}
        >
          Edit configurations
        </FtrButton>
      </FlexRow>
    );
  };

  return (
    <FlexRow style={{ padding: '2rem', alignItems: 'flex-start' }}>
      <PartDetailsV2InfoPresentational partInfo={partInfo} />
      <FlexColumn style={{ gap: '1.5rem' }}>
        {renderCadFile()}
        {renderTechnicalDrawingFiles()}
        {renderEditConfiguration()}
      </FlexColumn>
    </FlexRow>
  );
}

export default withConfigureEditRfqPartFormHOC(PartDetailsDropdownV2);
