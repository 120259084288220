import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';
import { PROJECT_STATUS } from '../constants/projectConstants';

export const createProjectGroup = async (body) => {
  return fetch(`${process.env.REACT_APP_BACKEND_SERVICE}/projectgroup/create`, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (res) => {
    if (res.ok) {
      const data = await res.json();
      return data;
    } else {
      throw new Error(
        'There was an error. Please fill in the necessary fields and try again.'
      );
    }
  });
};

/**
 * This is for admin call only
 * @returns
 */
export const getAllProjectsBasicInfo = async () => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/projectgroup/basic-info`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json().then((data) => data.data.projects);
  });
};

export const getProjectInfoByID = async (projectID) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/projectgroup/user/project/${projectID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getAllProjectsByUser = async (userID) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/projectgroup/user/${userID}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const fetchProjects = (await response.json()).value;
    return fetchProjects;
  });
};

export const getAllOpenCompletedProjectsByUser = async (userID) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/projectgroup/user/${userID}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const fetchProjects = (await response.json()).value;
    const openProjects = fetchProjects?.filter((project) => {
      return (
        [PROJECT_STATUS.OPEN, PROJECT_STATUS.COMPLETED].includes(
          project.status
        ) && project.items.length !== 0
      );
    });
    return openProjects;
  });
};

export const getProjectOwners = async (projectID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}/owners`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return (await response.json()).data.owners;
  });
};

export const updateProjectOwner = async (projectID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}/owners`;
  return fetch(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const removeProjectOwner = async (projectID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}/owners`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const getProjectSupplierQuoteStatus = async ({ projectID, userID }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}/suppliers/${userID}/has-quote`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json().then((data) => data.data.hasQuote);
  });
};

export const editMultipleProjects = async (payload) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/edit-multiple`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(payload),
  }).then((resp) => {
    if (!resp.ok) {
      throw Error(resp.statusText);
    }
    return resp.json();
  });
};

export const editSingleProjectApi = async (projectID, payload) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(payload),
  }).then((resp) => {
    if (!resp.ok) {
      throw Error(resp.statusText);
    }
    return resp.json();
  });
};

export const changeProjectAuthor = async ({ projectID, authorID }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}/change-author`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify({ authorID }),
  }).then((resp) => {
    if (!resp.ok) {
      throw Error(resp.statusText);
    }
    return resp.json();
  });
};

export const getProjectBomFiles = async ({ projectID }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}/boms`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (resp) => {
    if (!resp.ok) {
      throw Error(resp.statusText);
    }
    return resp.json().then((data) => data.data.boms);
  });
};

export const updateProjectBomFiles = async ({ projectID, boms }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}/boms`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify({ boms }),
  }).then((resp) => {
    if (!resp.ok) {
      throw Error(resp.statusText);
    }
    return resp.json();
  });
};

export const updateQcReports = async ({ projectID, qcReports }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}/qc-reports`;
  return fetch(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify({ qcReports }),
  }).then((resp) => {
    if (!resp.ok) {
      throw Error(resp.statusText);
    }
    return resp.json();
  });
};

export const deleteProjectBomFile = async ({ projectID, bom }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}/boms`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
    body: JSON.stringify({ ...bom }),
  }).then((resp) => {
    if (!resp.ok) {
      throw Error(resp.statusText);
    }
    return resp.json();
  });
};

export const deleteProject = async ({ projectID }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}`;
  return fetch(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
  }).then((resp) => {
    if (!resp.ok) {
      throw Error(resp.statusText);
    }
    return true;
  });
};

export const listSupplierInProject = async (projectID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}/list-supplier`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then((resp) => {
    if (!resp.ok) {
      throw Error(resp.statusText);
    }
    return resp.json();
  });
};

export const getProjectSharedQuoteReceivers = async (userID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/quote-receiver`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (resp) => {
    if (!resp.ok) {
      throw Error(resp.statusText);
    }
    return resp.json();
  });
};

export const createProjectSharedQuoteReceiver = async (userID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/users/${userID}/quote-receiver`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const shareQuotationForm = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/quote-receiver/share-quote`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const getSharedQuotationLog = async (projectID) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}/shared-quotation-log`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json().then((data) => data.data);
  });
};

export const getAllPastProjectsByUser = async (userID) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/projectgroup/user/past-projects/${userID}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json().then((data) => {
      return data.value;
    });
  });
};

export const updateProjectTimingControl = async (projectID, body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}/timing-control`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const updateProjectLocationVisibilityControl = async (
  projectID,
  body
) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/projectgroup/${projectID}/location-visibility-control`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const customerDisableProject = async (body) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/projectgroup/disable`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};

export const assignItemsToProject = async (body) => {
  const requestUrl = `${process.env.REACT_APP_BACKEND_SERVICE}/projectgroup/assign`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
};
