import { useReducer } from 'react';
import { useQuery } from 'react-query';

const useDataGridPaginationHook = (
  fnApi = () => {},
  { initialQueryParams = {}, queryEnabled = true } = {}
) => {
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return {
        ...prev,
        ...next,
      };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      ...initialQueryParams,
    }
  );

  const {
    data: tableData,
    isFetching,
    refetch,
  } = useQuery(
    [
      fnApi?.name,
      tableQueryParams.page,
      tableQueryParams.pageSize,
      tableQueryParams.search,
    ],
    () =>
      fnApi(tableQueryParams).then((data) => {
        updateTableQueryParams({
          totalCount: data.totalCount,
        });
        return data.rows;
      }),
    {
      enabled: queryEnabled,
    }
  );

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  return [
    {
      tableData: tableData || [],
      isFetching,
      tableQueryParams,
    },
    {
      refetch,
      handleSearch,
      updateTableQueryParams,
    },
  ];
};

export default useDataGridPaginationHook;
