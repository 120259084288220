import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Dialog, DialogContent } from '@material-ui/core';

import { Close } from '@material-ui/icons';

import { FlexColumn } from '../layouts/FlexLayouts';
import { FtrB3, FtrH6 } from '../ftr-components';

import { convertPriceToCurrencyBeautified } from '../../utils/currencyUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { isNumber } from '../../utils/numberUtils';

import { colors } from '../../palette';
import { SHIPPING_MODES } from '../../constants/checkoutConstants';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    minWidth: 350,
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

function MctDeliveryBreakdownPopup(props) {
  const classes = useStyles();

  const { data = {}, onClose = () => {} } = props;

  const { priceDetails = {} } = data;

  const title = `Delivery cost breakdown${
    data.referenceName ? ` for MCT ${data.referenceName}` : ''
  }`;

  const renderDhlContent = (deliveryOption, options) => {
    const {
      dhlShippingFee = 0,
      nonDhlShippingFee = 0,
      markupDhlShippingFee = 0,
      dhlMultiplier = 0,
    } = priceDetails?.dhlResult || {};

    const { showTotalCost = true } = options || {};
    // SHIPPING_MODES.SAME_DATE for legacy DHL shipping
    if (
      ![SHIPPING_MODES.DHL_PRIORITY, SHIPPING_MODES.SAME_DATE].includes(
        deliveryOption
      )
    ) {
      return null;
    }
    const dhlShippingFeeStr = convertPriceToCurrencyBeautified({
      price: dhlShippingFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    const nonDhlShippingFeeStr = convertPriceToCurrencyBeautified({
      price: nonDhlShippingFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    const markupDhlShippingFeeStr = convertPriceToCurrencyBeautified({
      price: markupDhlShippingFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    const originalDeliveryFeeStr = convertPriceToCurrencyBeautified({
      price: priceDetails?.dhlResult?.originalDeliveryFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    return (
      <FlexColumn>
        {showTotalCost && <FtrB3>Total cost: {data.deliveryCost}</FtrB3>}
        {dhlShippingFee > 0 && <FtrB3>DHL fee: {dhlShippingFeeStr}</FtrB3>}
        {nonDhlShippingFee > 0 && (
          <FtrB3>Non DHL fee: {nonDhlShippingFeeStr}</FtrB3>
        )}
        {markupDhlShippingFee !== 0 && isNumber(markupDhlShippingFee) && (
          <FtrB3>Markup: {markupDhlShippingFeeStr}</FtrB3>
        )}
        {dhlMultiplier > 0 && <FtrB3>Multiplier: {dhlMultiplier}</FtrB3>}
        {priceDetails?.dhlResult?.originalDeliveryFee > 0 && (
          <FtrB3>Original fee: {originalDeliveryFeeStr}</FtrB3>
        )}
      </FlexColumn>
    );
  };

  const renderDeliveryContent = (params) => {
    const {
      deliveryOption,
      showTotalCost = true,
      priorityShippingFee = 0,
      economyShippingFee = 0,
      priorityMultiplier = 0,
      economyMultiplier = 0,
      dhlDeliveryFee = 0,
      nonShippingFee = 0,
      markupShippingFee = 0,
      originalDeliveryFee = 0,
      priorityServiceName = '',
      economyServiceName = '',
      name = 'FedEx',
    } = params || {};
    if (
      ![
        SHIPPING_MODES.FEDEX_PRIORITY,
        SHIPPING_MODES.FEDEX_ECONOMY,
        SHIPPING_MODES.UPS_PRIORITY,
        SHIPPING_MODES.UPS_ECONOMY,
      ].includes(deliveryOption)
    ) {
      return null;
    }
    const priorityShippingFeeStr = convertPriceToCurrencyBeautified({
      price: priorityShippingFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });
    const economyShippingFeeStr = convertPriceToCurrencyBeautified({
      price: economyShippingFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    const nonShippingFeeStr = convertPriceToCurrencyBeautified({
      price: nonShippingFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    const markupShippingFeeStr = convertPriceToCurrencyBeautified({
      price: markupShippingFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    const dhlDeliveryFeeStr = convertPriceToCurrencyBeautified({
      price: dhlDeliveryFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    const originalDeliveryFeeStr = convertPriceToCurrencyBeautified({
      price: originalDeliveryFee,
      currency: priceDetails.currency,
      exchangeRate: priceDetails.exchangeRate,
    });

    return (
      <FlexColumn>
        {showTotalCost && <FtrB3>Total cost: {data.deliveryCost}</FtrB3>}
        {data.deliveryOption?.toLowerCase().includes('priority') && (
          <>
            {!isEmptyValue(priorityShippingFee) && (
              <FtrB3>
                {name} fee: {priorityShippingFeeStr}
              </FtrB3>
            )}
            {priorityMultiplier > 0 && (
              <FtrB3>Multiplier: {priorityMultiplier}</FtrB3>
            )}
            {!isEmptyValue(priorityServiceName) && (
              <FtrB3>Service: {priorityServiceName}</FtrB3>
            )}
          </>
        )}
        {data.deliveryOption.toLowerCase().includes('economy') && (
          <>
            {!isEmptyValue(economyShippingFee) && (
              <FtrB3>
                {name} fee: {economyShippingFeeStr}
              </FtrB3>
            )}
            {economyMultiplier > 0 && (
              <FtrB3>Multiplier: {economyMultiplier}</FtrB3>
            )}
            {!isEmptyValue(economyServiceName) && (
              <FtrB3>Service: {economyServiceName}</FtrB3>
            )}
          </>
        )}
        {dhlDeliveryFee > 0 && ( // This is for using DHL delivery cost as a fallback when the API fails
          <>
            <FtrB3>DHL delivery cost: {dhlDeliveryFeeStr}</FtrB3>
            <div style={{ paddingLeft: '1rem' }}>
              {renderDhlContent(SHIPPING_MODES.DHL_PRIORITY, {
                showTotalCost: false,
              })}
            </div>
          </>
        )}
        {originalDeliveryFee > 0 && (
          <FtrB3>Original fee: {originalDeliveryFeeStr}</FtrB3>
        )}
        {nonShippingFee > 0 && (
          <FtrB3>
            Non {name} fee: {nonShippingFeeStr}
          </FtrB3>
        )}
        {markupShippingFee > 0 &&
          dhlDeliveryFee <= 0 &&
          isNumber(markupShippingFee) && (
            <FtrB3>Markup: {markupShippingFeeStr}</FtrB3>
          )}
      </FlexColumn>
    );
  };

  return (
    <Dialog
      open={open}
      width='md'
      maxWidth='xl'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <Close className={classes.closeIcon} onClick={onClose} />
      <DialogContent className={classes.dialogContent}>
        <FtrH6 style={{ marginBottom: '1rem' }}>{title}</FtrH6>
        {renderDhlContent(data?.deliveryOption)}
        {data?.deliveryOption?.toLowerCase()?.includes('fedex') &&
          renderDeliveryContent({
            deliveryOption: data?.deliveryOption,
            priorityShippingFee:
              priceDetails?.fedExResult?.fedExPriorityShippingFee,
            economyShippingFee:
              priceDetails?.fedExResult?.fedExEconomyShippingFee,
            priorityMultiplier:
              priceDetails?.fedExResult?.fedExPriorityMultiplier,
            economyMultiplier:
              priceDetails?.fedExResult?.fedExEconomyMultiplier,
            dhlDeliveryFee: priceDetails?.fedExResult?.dhlDeliveryFeeInSGD,
            nonShippingFee: priceDetails?.fedExResult?.nonFedExShippingFee,
            markupShippingFee:
              priceDetails?.fedExResult?.markupFedExShippingFee,
            originalDeliveryFee: priceDetails?.fedExResult?.originalDeliveryFee,
            priorityServiceName:
              priceDetails?.fedExResult?.fedExPriorityServiceName,
            economyServiceName:
              priceDetails?.fedExResult?.fedExEconomyServiceName,
            name: 'FedEx',
          })}
        {data?.deliveryOption?.toLowerCase()?.includes('ups') &&
          renderDeliveryContent({
            deliveryOption: data?.deliveryOption,
            priorityShippingFee:
              priceDetails?.upsResult?.upsPriorityShippingFee,
            economyShippingFee: priceDetails?.upsResult?.upsEconomyShippingFee,
            priorityMultiplier: priceDetails?.upsResult?.upsPriorityMultiplier,
            economyMultiplier: priceDetails?.upsResult?.upsEconomyMultiplier,
            dhlDeliveryFee: priceDetails?.upsResult?.dhlDeliveryFeeInSGD,
            nonShippingFee: priceDetails?.upsResult?.nonUpsShippingFee,
            markupShippingFee: priceDetails?.upsResult?.markupUpsShippingFee,
            priorityServiceName:
              priceDetails?.upsResult?.upsPriorityServiceName,
            economyServiceName: priceDetails?.upsResult?.upsEconomyServiceName,
            originalDeliveryFee: priceDetails?.upsResult?.originalDeliveryFee,
            name: 'UPS',
          })}
      </DialogContent>
    </Dialog>
  );
}

export default MctDeliveryBreakdownPopup;
