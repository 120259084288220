import React, { useState, useEffect } from 'react';

import { CircularProgress, Tooltip } from '@material-ui/core';

import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';

import { colors } from '../../palette';
import { isEmptyValue } from '../../utils/commonUtils';

import { checkIfImageIsAppropriate } from '../../apis/technicalDrawingExtractionApi';
import { notifyError } from '../../services/notificationService';

function ImageCheckerIcon({ imageFile }) {
  const [isAppropriate, setIsAppropriate] = useState();
  const [reason, setReason] = useState();
  const [loading, setLoading] = useState(false);
  const notChecked = isEmptyValue(isAppropriate);

  useEffect(() => {
    let ignore = false;
    if (notChecked) {
      setLoading(true);
      setIsAppropriate(null);
      setReason('');
      checkIfImageIsAppropriate(imageFile)
        .then(({ is_appropriate, reason: _reason }) => {
          if (!ignore) {
            setIsAppropriate(is_appropriate);
            setReason(_reason);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error('Error checking if image is appropriate:', err);
          notifyError('Failed to check if image is appropriate');
          setLoading(false);
        });
    }
    return () => {
      ignore = true;
    };
  }, [imageFile]);

  const title = notChecked
    ? 'Image not checked by image moderator'
    : isAppropriate
      ? 'Image is appropriate'
      : reason;
  const color = notChecked
    ? colors.blue030
    : isAppropriate
      ? colors.successGreen
      : colors.warningYellow;

  return (
    <Tooltip title={title} placement='top' arrow>
      {loading ? (
        <CircularProgress size={14} style={{ marginLeft: 3 }} />
      ) : (
        <InsertPhotoIcon
          style={{
            fontSize: '1.5rem',
            color,
          }}
        />
      )}
    </Tooltip>
  );
}

export default ImageCheckerIcon;
