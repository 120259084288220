import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';

import { colors } from '../palette';

import CustomerFaqPage from '../containers/Misc/FAQPage';
import SupplierFaqPage from '../containers/Misc/SupplierFAQPage';

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TAB_KEYS = {
  CUSTOMER_FAQS: 'Customer FAQ',
  SUPPLIER_FAQS: 'Partner FAQ',
};

function UserFaqs() {
  const classes = useStyles();

  const TABS = [TAB_KEYS.CUSTOMER_FAQS, TAB_KEYS.SUPPLIER_FAQS];

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTab = () => {
    const tabName = TABS[selectedTab];
    switch (tabName) {
      case TAB_KEYS.CUSTOMER_FAQS:
        return <CustomerFaqPage />;
      case TAB_KEYS.SUPPLIER_FAQS:
        return <SupplierFaqPage />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.body}>
      <h1>User FAQs</h1>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
      >
        {TABS?.map((tab) => {
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
      {renderTab()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps);

export default withRouter(withConnect(UserFaqs));
