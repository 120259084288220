import { ceil } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import HorizontalExpandSpace from '../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../components/grid-data/CustomToolbar';
import GridDataPagination from '../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../components/grid-data/buttons/SearchBar';
import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';
import AddBlacklistedSuppliersField from './AddBlacklistedSuppliersField';

import { getUserRoleSelector } from '../selectors/userSelector';

import { exportCSV } from '../utils/csvExportUtils';
import { formatSimpleDate, getDaysElapsed } from '../utils/dateTimeUtils';
import { isSuperAdminRole } from '../utils/roleUtils';

import { getOMSBlacklist } from '../apis/configurationApi';

import { useDataGridFilterHook } from '../hooks/useDataGridFilterHook';

import { colors } from '../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(2),
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const ROW_HEIGHT = 100;

function BlacklistedSuppliersTab() {
  const classes = useStyles();

  const role = useSelector(getUserRoleSelector);

  const [tableData, setTableData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [filteredData, { setSearchStr, setColumnsDef, setSourceData }] =
    useDataGridFilterHook({
      search: '',
      source: tableData,
    });

  const getTableData = () => {
    updateTableQueryParams({ loading: true });
    getOMSBlacklist()
      .then((data) => {
        setTableData(data);
        updateTableQueryParams({ loading: false });
      })
      .catch(() => updateTableQueryParams({ loading: false }));
  };

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(tableData);
  }, [tableData]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const defaultColumns = [
    {
      headerName: 'User (ID)',
      field: 'userID',
      width: 200,
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 400,
    },
    {
      headerName: 'Date Added',
      field: 'dateAdded',
      width: 200,
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell
            text={formatSimpleDate(new Date(rowData.dateAdded))}
          />
        );
      },
    },
    {
      headerName: 'Time in blacklist / days',
      field: 'daysInBlacklist',
      width: 200,
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell text={getDaysElapsed(rowData.dateAdded)} />
        );
      },
    },
  ];

  const [columns, setColumns] = useState(defaultColumns);

  //Retrieve all ppe feedback when page renders
  useEffect(() => {
    getTableData();
    const _columns = defaultColumns?.filter((col) => !!col);
    setColumns(_columns);
    setColumnsDef(_columns);
  }, []);

  const handleDownloadCsv = async () => {
    for (const row of filteredData) {
      const daysElapsed = getDaysElapsed(new Date(row?.dateAdded));
      row['daysInBlacklist'] = daysElapsed;
    }
    exportCSV(columns, filteredData, `All Blacklisted suppliers`);
  };

  const getToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.body}>
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <div style={{ marginBottom: '5rem' }}>
          <AddBlacklistedSuppliersField setTableData={setTableData} />
        </div>
        <DataGrid
          autoHeight
          rows={filteredData}
          columns={columns?.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => row.userID}
          rowHeight={ROW_HEIGHT}
          headerHeight={80}
          components={{
            Toolbar: getToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          onPageSizeChange={(newPageSize) =>
            updateTableQueryParams({ pageSize: newPageSize })
          }
          rowsPerPageOptions={[10, 20, 50]}
          loading={tableQueryParams.loading}
          disableColumnMenu
          localeText={{
            toolbarExport: 'Export CSV',
          }}
        />
      </div>
    </div>
  );
}

export default BlacklistedSuppliersTab;
