import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { Tab, Tabs } from '@material-ui/core';

import AllJobsWhiteListAccountsControl from './control-panels/AllJobsWhiteListAccountsControl';
import AutomationsPanel from './control-panels/AutomationsPanel';
import DeliveryFee from './control-panels/DeliveryFee';
import ManageCustomerInput from './control-panels/ManageCustomerInputs';
import PartnerDataPanel from './control-panels/PartnerDataPanel';
import PlatformFee from './control-panels/PlatformFee';
import PpeControlPanelV2 from './control-panels/PpeControlPanelV2';
import PromotionCodes from './control-panels/PromotionCodes';
import RegionBasedMarkupsDataGrid from './control-panels/RegionBasedMarkupsDataGrid';
import StripeDiscountDataGrid from './control-panels/StripeDiscountDataGrid';

import { isSuperAdminRole } from '../utils/roleUtils';

import { colors } from '../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TAB_KEYS = {
  ALL_JOB_WHITELIST_ACCOUNTS: 'All Job Whitelist Accounts',
  AUTOMATION_PANEL: 'Automations Control Panel',
  CUSTOMER_INPUTS: 'Customer Inputs',
  DELIVERY_FEE: 'Delivery Fee',
  PARTNER_DATA_PANEL: 'Partner Data Panel',
  PLATFORM_FEE_CONTROL_PANEL: 'Service Fee Control Panel',
  PPE_CONTROL_PANEL: 'PPE Control Panel',
  PROMOTION_CODES: 'Promotion Codes',
  REGION_BASED_MARKUPS: 'Region Based Markups',
  STRIPE_DISCOUNT: 'Stripe Discount',
};

const TABS = [
  TAB_KEYS.PPE_CONTROL_PANEL,
  TAB_KEYS.PLATFORM_FEE_CONTROL_PANEL,
  TAB_KEYS.DELIVERY_FEE,
  TAB_KEYS.CUSTOMER_INPUTS,
  TAB_KEYS.PROMOTION_CODES,
  TAB_KEYS.PARTNER_DATA_PANEL,
  TAB_KEYS.ALL_JOB_WHITELIST_ACCOUNTS,
  TAB_KEYS.AUTOMATION_PANEL,
];

const SUPERADMIN_TABS = [
  TAB_KEYS.PPE_CONTROL_PANEL,
  TAB_KEYS.REGION_BASED_MARKUPS,
  TAB_KEYS.PLATFORM_FEE_CONTROL_PANEL,
  TAB_KEYS.DELIVERY_FEE,
  TAB_KEYS.CUSTOMER_INPUTS,
  TAB_KEYS.PROMOTION_CODES,
  TAB_KEYS.PARTNER_DATA_PANEL,
  TAB_KEYS.ALL_JOB_WHITELIST_ACCOUNTS,
  TAB_KEYS.AUTOMATION_PANEL,
];

function ControlPanel(props) {
  const classes = useStyles();

  const { role } = props;

  const [tabs, setTabs] = useState(TABS);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const tabList = isSuperAdminRole(role) ? SUPERADMIN_TABS : TABS;
    setTabs(tabList);
  }, [role]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTab = () => {
    const tabName = tabs[selectedTab];
    switch (tabName) {
      case TAB_KEYS.PPE_CONTROL_PANEL:
        return <PpeControlPanelV2 />;
      case TAB_KEYS.PLATFORM_FEE_CONTROL_PANEL:
        return <PlatformFee />;
      case TAB_KEYS.DELIVERY_FEE:
        return <DeliveryFee />;
      case TAB_KEYS.CUSTOMER_INPUTS:
        return <ManageCustomerInput />;
      case TAB_KEYS.STRIPE_DISCOUNT:
        return <StripeDiscountDataGrid />;
      case TAB_KEYS.PROMOTION_CODES:
        return <PromotionCodes />;
      case TAB_KEYS.PARTNER_DATA_PANEL:
        return <PartnerDataPanel />;
      case TAB_KEYS.ALL_JOB_WHITELIST_ACCOUNTS:
        return <AllJobsWhiteListAccountsControl />;
      case TAB_KEYS.AUTOMATION_PANEL:
        return <AutomationsPanel />;
      case TAB_KEYS.REGION_BASED_MARKUPS:
        return <RegionBasedMarkupsDataGrid />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.body}>
      <h1>Control Panel</h1>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
        style={{ maxWidth: '75vw' }}
      >
        {tabs?.map((tab) => {
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
      {renderTab()}
    </div>
  );
}
function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(ControlPanel);
