import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Reply as ReplyIcon,
  ReplyAll as ReplyAllIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';

import BlueButton from '../buttons/BlueButton';
import Title from '../Title';
import { colors } from '../../palette';
import { NOT_INTERESTED_STATUS } from '../../constants/notInterestedConstants';
import {
  reverseProjectNotInterestedJob,
  upsertNotInterestedJob,
} from '../../apis/notInterestedJobApi';
import { notifySuccess } from '../../services/notificationService';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '37.5rem',
    minWidth: '12.5rem',
    maxWidth: '50rem',
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
  title: {
    color: colors.errorRed,
  },
  actions: {
    marginLeft: '5px',
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '3px 5px',
  },
  icon: {
    cursor: 'pointer',
  },
}));

function NotInterestedJobPopup(props) {
  const classes = useStyles();

  const { open, onClose, notInterestedJobs = [], itemID, onSuccess } = props;

  if (!open || !itemID || notInterestedJobs.length === 0) return null;

  const handleUpsertNotInterestedJob = (status, supplierID) => {
    upsertNotInterestedJob({
      itemIDs: [itemID],
      status,
      supplierID,
    }).then(() => {
      notifySuccess('Item brought back to supplier job board');
      onSuccess();
    });
  };

  const handleReverseProjectNotInterestedJob = (supplierID) => {
    reverseProjectNotInterestedJob({
      itemID,
      supplierID,
    }).then(() => {
      notifySuccess('Project brought back to supplier job board');
      onSuccess();
    });
  };

  const renderBodyPopup = () => {
    return (
      <ul style={{ textAlign: 'start' }}>
        {notInterestedJobs?.map((notInterested) => {
          return (
            <li key={notInterested.userID}>
              {notInterested.name} ({notInterested.userID}) -{' '}
              <span
                style={{
                  color:
                    notInterested.status === NOT_INTERESTED_STATUS.ACTIVE
                      ? colors.errorRed
                      : colors.fontBlack,
                }}
              >
                {notInterested.status === NOT_INTERESTED_STATUS.ACTIVE
                  ? 'Not interested'
                  : notInterested.status}
              </span>
              <span className={classes.actions}>
                <span style={{ marginRight: '5px' }}>-</span>
                <Tooltip title='Bring back the item for the supplier to see'>
                  <ReplyIcon
                    onClick={() =>
                      handleUpsertNotInterestedJob(
                        NOT_INTERESTED_STATUS.INACTIVE,
                        notInterested.userID
                      )
                    }
                    className={classes.icon}
                  />
                </Tooltip>
                <Tooltip title='Bring back entire project associated with this item for supplier to see'>
                  <ReplyAllIcon
                    onClick={() =>
                      handleReverseProjectNotInterestedJob(notInterested.userID)
                    }
                    className={classes.icon}
                  />
                </Tooltip>
              </span>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='warning-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='warning-dialog-title'>
        <Title
          className={classes.title}
          contentTitle={`Not Interested Part #${itemID}`}
          size='small'
        />
      </DialogTitle>
      <div
        style={{
          paddingBottom: '1rem',
        }}
      >
        <DialogContent
          style={{
            padding: '0 30px',
            paddingBottom: '2rem',
            textAlign: 'center',
          }}
        >
          {renderBodyPopup()}
        </DialogContent>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <BlueButton onBtnClick={onClose} btnContent='OK' />
        </div>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default NotInterestedJobPopup;
