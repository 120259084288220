import React from 'react';
import { Tooltip } from '@material-ui/core';

import { selectFromArrayObjectRange } from '../../utils/arrayUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';
import { METRICS_COLOR_RATE } from '../../constants';

/**
 * Displays a rate metric with a corresponding color based on the value.
 * @param {object} props
 * @param {string} [props.name=Rate Metric] - name of the metric
 * @param {number|string} props.value - value of the metric
 * @param {array} [props.metricColorRanges=METRICS_COLOR_RATE] - color ranges for the metric
 * @param {'percentage' | 'text'} [props.type=percentage] - type of the metric (percentage | text)
 * @param {React.CSSProperties} [props.style] - style object for the component
 * @param {React.CSSProperties} [props.styleValue] - style object for the component value
 * @param {React.CSSProperties} [props.styleName] - style object for the component name
 * @param {string|Node} [props.tooltip] - tooltip text
 */
function RateMetrics(props) {
  const {
    name = 'Rate Metric',
    value,
    metricColorRanges = METRICS_COLOR_RATE,
    type = 'percentage', // percentage | text
    style,
    styleValue,
    styleName,
    tooltip = '',
  } = props;

  if (isEmptyValue(value) || !['string', 'number'].includes(typeof value)) {
    return null;
  }

  const renderValue = () => {
    // Extract numeric value from percentage string (e.g., "100%" → 100)
    // this is to handle some legacy data
    const numericValue = isNaN(+value) ? Number(value.replace('%', '')) : value;

    if (type === 'percentage' && !isNaN(numericValue)) {
      return (
        <span
          style={{
            color:
              selectFromArrayObjectRange(metricColorRanges, numericValue)
                ?.color || colors.neutral080,
            fontWeight: 700,
            ...styleValue,
          }}
        >
          {numericValue}%
        </span>
      );
    }

    return (
      <span
        style={{
          fontWeight: 700,
          color: colors.neutral070,
          ...styleValue,
        }}
      >
        {value}
      </span>
    );
  };

  return (
    <Tooltip title={tooltip} arrow>
      <div style={style}>
        <span style={styleName}>{name}</span> {renderValue()}
      </div>
    </Tooltip>
  );
}

export default RateMetrics;
