export const QUOTATION_VERIFY_STATUS_FILTER_ARRAY = [
  { name: 'Confirmed Jobs', value: 'ppe', group: 'verify-status' },
  { name: 'Manual RFQ', value: 'non-ppe', group: 'verify-status' },
  {
    name: 'Supplier Price Feedback',
    value: 'ppe-feedback',
    group: 'verify-status',
  },
  {
    name: 'Supplier Lead Time Feedback',
    value: 'ppe-lead-time-feedback',
    group: 'verify-status',
  },
];
