import React, { useReducer } from 'react';

import { Dialog, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { isEmptyValue } from '../utils/commonUtils';

import CreateShipmentOrder from '../pages/manage-delivery-order/CreateShipmentOrder';
import Title from '../components/Title';

// ---------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 1000,
    borderRadius: 10,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
}));

function withCreateShipmentOrderPopup(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const classes = useStyles();

    const defaultLocalState = {
      open: false,
      initialItemIDs: [],
      initialQuotationIDs: [],
      initialProjectIDs: [],
      loadOrderReadyInfo: () => {},
      location: '',
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    return (
      <>
        <WrappedComponent
          updateCreateShipmentOrderPopupHOCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <Dialog
            maxWidth='xl'
            open={open}
            onClose={() => updateLocalState({ open: false })}
            aria-labelledby='create-shipment-dialog-title'
            classes={{ paper: classes.paper }}
          >
            <DialogTitle id='create-shipment-dialog-title'>
              <Title contentTitle='Create Shipment Order' size='small' />
            </DialogTitle>
            <CreateShipmentOrder
              createShipmentOrderPopupHOCState={localState}
              location={localState.location}
            />
            <IconButton
              aria-label='close'
              className={classes.closeButton}
              onClick={() => updateLocalState({ open: false })}
            >
              <CloseIcon />
            </IconButton>
          </Dialog>
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withCreateShipmentOrderPopup;
