import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import DisplayFeedbackCard from './cards/DisplayFeedbackCard';
import FtrAdminFeedbackThumbsComponent from './ftr-components/FtrAdminFeedbackThumbsComponent';
import { FlexRow } from './layouts/FlexLayouts';

import withLeadTimeFeedbackSupplierPopupHOC from '../hocs/withLeadTimeFeedbackSupplierPopupHOC';

import useSupplierLeadTimeFeedback from '../hooks/useSupplierLeadTimeFeedbackHook';

import { getUserIDSelector } from '../selectors/userSelector';

import { notifySuccess } from '../services/notificationService';

import { FEEDBACK_STATUS } from '../constants/feedbackConstants';

// -------------------------------------------------------------------------------------------------

function SupplierLeadTimeFeedback(props) {
  const {
    deliveryDate,
    selectedQuotationIDs,
    updateLeadTimeFeedbackSupplierPopupHOCState = () => {},
    isWatching = false,
    setIsWatching = () => {},
    handleSetAllWatchingStatusToActive = () => {},
  } = props;

  const userID = useSelector(getUserIDSelector);

  const { data: feedbackData, upsertSupplierLeadTimeFeedback } =
    useSupplierLeadTimeFeedback(userID, selectedQuotationIDs);

  const [showFeedbackButton, setShowFeedbackButton] = useState(true);

  function showSupplierLeadTimeFeedbackPopup() {
    updateLeadTimeFeedbackSupplierPopupHOCState({
      open: true,
      deliveryDate,
      targetDeliveryDate: feedbackData?.targetDeliveryDate,
      feedbackDetails: feedbackData?.feedbackDetails,
      additionalRemarks: feedbackData?.additionalRemarks,
      upsertSupplierLeadTimeFeedback,
    });
  }

  const setWatchingJobActive = () => {
    setIsWatching(true);
    handleSetAllWatchingStatusToActive();
    notifySuccess(
      'Watching Job: Factorem will notify you in case there is any activity on this job!'
    );
  };

  return (
    <FlexRow style={{ justifyContent: 'end' }}>
      <FtrAdminFeedbackThumbsComponent
        thumbUpTooltip='Lead time is good'
        thumbDownTooltip='Lead time is bad'
        onThumbUpClick={() => {
          if (!isWatching) {
            setWatchingJobActive();
          }
          upsertSupplierLeadTimeFeedback({
            userID,
            feedback: FEEDBACK_STATUS.GOOD,
            selectedQuotationIDs,
            deliveryDate: new Date(deliveryDate),
          });
        }}
        onThumbDownClick={() => {
          if (!isWatching) {
            setWatchingJobActive();
          }
          upsertSupplierLeadTimeFeedback({
            userID,
            feedback: FEEDBACK_STATUS.BAD,
            selectedQuotationIDs,
            deliveryDate: new Date(deliveryDate),
          });

          showSupplierLeadTimeFeedbackPopup();

          setShowFeedbackButton(true);
        }}
        value={feedbackData?.feedback}
      />
      {feedbackData?.feedback === FEEDBACK_STATUS.BAD && showFeedbackButton && (
        <DisplayFeedbackCard
          onClickAction={showSupplierLeadTimeFeedbackPopup}
          onCloseAction={() => {
            setShowFeedbackButton(false);
          }}
          text='Feedback?'
        />
      )}
    </FlexRow>
  );
}

export default withLeadTimeFeedbackSupplierPopupHOC(SupplierLeadTimeFeedback);
