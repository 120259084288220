import React from 'react';
import { connect } from 'react-redux';

import { Close } from '@material-ui/icons';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@material-ui/core';

import Title from '../Title';
import SubDesc from '../SubDesc';
import DetailRow from '../../pages/DetailRow';
import { makeStyles } from '@material-ui/core/styles/index';
import {
  getMaterialWithColorText,
  getSurfaceFinishWithCustomizationsText,
} from '../../utils/itemUtils';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 400,
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function AdminQuoteDetailsDisplayPopup(props) {
  const classes = useStyles();

  const { open, rowData, onClose } = props;

  const renderRowDetails = (_rowData) => {
    return (
      <div style={{ marginBottom: 15, lineHeight: 'normal' }}>
        <DetailRow
          title='Customer ID'
          content={_rowData.buyerID ? _rowData.buyerID : 'N/A'}
        />
        <DetailRow
          title='Customer Name'
          content={_rowData.buyerName ? _rowData.buyerName : 'N/A'}
        />
        <DetailRow
          title='Customer Contact'
          content={_rowData.buyerContact ? _rowData.buyerContact : 'N/A'}
        />
        <DetailRow
          title='Customer Address'
          content={_rowData.buyerAddress ? _rowData.buyerAddress : 'N/A'}
        />
        <Divider style={{ margin: '0.5rem 0' }} />
        <DetailRow
          title='Date of Quotation'
          content={
            _rowData.dateOfOrder ? _rowData.dateOfOrder.substring(0, 10) : 'N/A'
          }
        />
        <DetailRow
          title='Date of part verification'
          content={
            _rowData.verifiedDate
              ? _rowData.verifiedDate.substring(0, 10)
              : 'N/A'
          }
        />
        <DetailRow
          title='Material'
          content={getMaterialWithColorText(_rowData)}
        />
        <DetailRow
          title='Surface Finish'
          content={getSurfaceFinishWithCustomizationsText(_rowData)}
        />
      </div>
    );
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='quote-details'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <SubDesc content={`Quotation ID: #${rowData?.quotationID}`} />
        <Title contentTitle='Quote Details'></Title>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            width: 'fit-content',
            margin: '0 auto',
          }}
        >
          {renderRowDetails(rowData)}
        </div>
      </DialogContent>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <Close />
      </IconButton>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    userId: state.auth.user.userID,
    currency: state.auth.location.currency,
    role: state.auth.user.role,
    exchangeRate: state.auth.rates[state.auth.location.currency],
  };
}

const withConnect = connect(mapStateToProps, null);

export default withConnect(AdminQuoteDetailsDisplayPopup);
