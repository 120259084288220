import { isEmptyValue } from './commonUtils';

import { COUNTRY_LIST } from '../constants/countryConstants';
import { isNumber } from './numberUtils';

export const getCountryNameByPhoneCode = (phoneCode) => {
  if (isEmptyValue(phoneCode)) {
    return '';
  }

  const cleanedPhoneCode = phoneCode?.replace(/\(|\)/g, ''); // Remove "(" and ")"
  const [countryName] =
    Object.entries(COUNTRY_LIST)?.find(
      // eslint-disable-next-line no-unused-vars
      ([_, country]) => country.phone === cleanedPhoneCode
    ) || [];
  return countryName;
};

export const getFormattedContactNumber = (contact, country) => {
  if (contact) {
    const currCountryCode = COUNTRY_LIST[country].phone;

    // e.g. for contacts with (+65)90123456 OR for contacts with +6090123456 but the country has been changed to Singapore
    if (
      contact.startsWith(`(`) ||
      (contact.startsWith('+') && !contact.startsWith(currCountryCode))
    ) {
      return contact;
    } else if (contact.startsWith(currCountryCode)) {
      // e.g. for contacts with +6590123456
      const remainingContact = contact?.slice(currCountryCode.length);
      return `(${currCountryCode}) ${remainingContact}`;
    } else {
      // e.g. for contact with 90123456
      return `(${currCountryCode}) ${contact}`;
    }
  }
  return 'N.A.';
};

export const getContactNumberForCSv = (contact, country) => {
  const contactNumber = getFormattedContactNumber(contact, country);
  const firstCharContactNumber = contactNumber?.[0];
  if (isNumber(firstCharContactNumber) || firstCharContactNumber === '+') {
    return `'${contactNumber}`; // to fix the contact number format on csv
  }
  return contactNumber;
};
