import { createAlertNotification } from '../apis/alertApi';

import { isEmptyValue } from './commonUtils';

import { LOGIN_USER_INFO_KEY } from '../constants/localStorageConstants';

// -------------------------------------------------------------------------------------------------

export const sendErrorToDiscord = ({ message, additional }) => {
  const {
    err,
    errorTitle,
    body,
    sendToDiscord = true,
    ...restAdditional
  } = additional || {};

  if (!sendToDiscord) {
    return;
  }

  const _err = err || new Error(message);
  const _errorTitle = errorTitle ? `${errorTitle}. ` : 'Unexpected error';
  const title = `[FE] ${_errorTitle}`;

  const loginUserInfoCache = localStorage.getItem(LOGIN_USER_INFO_KEY);
  const loginUserInfo = isEmptyValue(loginUserInfoCache)
    ? null
    : JSON.parse(localStorage.getItem(LOGIN_USER_INFO_KEY));

  const alertBody = {
    title,
    message,
    errorStack: _err.stack,
    additionalInfo: {
      loginUser: loginUserInfo,
      body,
      ...restAdditional,
    },
  };
  createAlertNotification(alertBody).catch((_error) => {
    createAlertNotification({
      title: '[FE] Error: Failed to send alert to Discord',
      additionalInfo: {
        error: _error,
        alertBody,
      },
    });
  });
};

export const withSendErrorToDiscord =
  ({ sendToDiscord = true, additional = {} } = {}) =>
  (fn) => {
    return (message) => {
      if (sendToDiscord) {
        sendErrorToDiscord({ additional });
        const err = new Error(message);
        sendErrorToDiscord({
          message,
          additional: {
            err,
            errorTitle: `Something wrong on FE!`,
            ...additional,
          },
        });
      }
      return fn(message);
    };
  };
