import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { InputAdornment, TextField } from '@material-ui/core';

import CheckAndWarningInfo from '../info/CheckAndWarningInfo';
import { FlexRowEnd } from '../layouts/FlexLayouts';
import FeedbackThumbs from '../../components/fields/FeedbackThumbs';
import { FtrButton } from '../ftr-components';
import DfmDefinitionTooltip from '../DfmDefinitionTooltip';

import {
  getDefectFeedbackByID,
  upsertDefectsFeedback,
} from '../../apis/dfmApi';

import { snakeCaseToTitleCase } from '../../utils/stringUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { getUserIDSelector } from '../../selectors/userSelector';

import {
  EXTRACT_DEFECT_KEYS_MAPPING,
  EXTRACT_DEFECT_RULES_MAPPING,
  KEYS_TO_FEEDBACK_MAPPING,
} from '../../constants/dfmConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

function DfmDefectsForm(props) {
  const {
    defectsOutput,
    itemID,
    handleView3DDefect,
    showYesOrNoValue = false,
    showDfmSuccess = true,
    selectedDefectUrl,
    showFeedback = false,
  } = props;

  const userID = useSelector(getUserIDSelector);

  const dfmTrackingID = defectsOutput?.trackingID;

  const { data, refetch } = useQuery(
    ['defectFeedback', { userID, itemID, dfmTrackingID }],
    async () => {
      if (!userID || !dfmTrackingID || !showFeedback) {
        return null;
      }
      const response = await getDefectFeedbackByID({
        userID,
        itemID,
        dfmTrackingID,
      });
      if (!response || response.length === 0) {
        return {};
      } else {
        return response[0];
      }
    }
  );

  const handleClick = (key, rating) => () => {
    if (dfmTrackingID) {
      data[KEYS_TO_FEEDBACK_MAPPING[key]] = rating;
      upsertDefectsFeedback({
        dfmTrackingID,
        userID,
        itemID,
        feedback: data,
      }).then(() => {
        refetch();
      });
    }
  };

  // retrieve feedback based on itemID and userID

  if (isEmptyValue(defectsOutput)) {
    return null;
  }

  const getDisplayValue = (value, compareValue) => {
    let result = value;
    if (!isEmptyValue(compareValue) && showYesOrNoValue) {
      result = compareValue === value || value === 0 ? 'No' : 'Yes';
    }
    return result;
  };

  return (
    <div>
      {Object.entries(defectsOutput)
        ?.filter(
          ([key]) =>
            ![
              EXTRACT_DEFECT_KEYS_MAPPING.dfm_success,
              'trackingID',
              EXTRACT_DEFECT_KEYS_MAPPING.visualization_links,
              'cache',
            ].includes(key)
        )
        ?.map(([key, value], index) => {
          if (Array.isArray(value)) {
            value = value?.join(', ');
          }
          if (['', null, undefined].includes(value)) {
            value = '-';
          }
          let compareValue = EXTRACT_DEFECT_RULES_MAPPING[key];
          if (
            EXTRACT_DEFECT_RULES_MAPPING[key]?.tech === '3DP' &&
            key === EXTRACT_DEFECT_KEYS_MAPPING.part_sizes_too_big
          ) {
            compareValue = EXTRACT_DEFECT_RULES_MAPPING[key].value;
          }

          const displayValue = getDisplayValue(value, compareValue);

          if (showYesOrNoValue && !['Yes', 'No'].includes(displayValue)) {
            return null;
          }

          return (
            <DfmDefinitionTooltip key={key} defectKey={key}>
              <TextField
                label={snakeCaseToTitleCase(key)}
                id={key}
                variant='filled'
                value={displayValue}
                style={index === 0 ? { marginTop: 0 } : null}
                margin='dense'
                fullWidth
                InputLabelProps={{
                  shrink: true,
                  style: { color: colors.neutral080 },
                }}
                disabled
                InputProps={{
                  endAdornment: !isEmptyValue(compareValue) && (
                    <InputAdornment position='end'>
                      <FlexRowEnd>
                        {defectsOutput?.visualization_links?.[key] &&
                          typeof handleView3DDefect === 'function' && (
                            <FtrButton
                              variant='outlined'
                              color='blue'
                              style={{
                                marginLeft: '10px',
                                padding: '3px 9px',
                                backgroundColor: colors.neutral030,
                              }}
                              size='small'
                              onClick={() =>
                                handleView3DDefect(
                                  defectsOutput.visualization_links[key]
                                )
                              }
                            >
                              {selectedDefectUrl ===
                              defectsOutput.visualization_links[key]
                                ? 'HIDE'
                                : 'VIEW'}
                            </FtrButton>
                          )}
                        {data && (
                          <FeedbackThumbs
                            // id + userID will be used to retrieve the previous feedbacks
                            upIsActive={
                              data[KEYS_TO_FEEDBACK_MAPPING[key]] === 'good'
                            }
                            upTooltip={'Extracted defect is correct'}
                            upOnclick={handleClick(key, 'good')}
                            downIsActive={
                              data[KEYS_TO_FEEDBACK_MAPPING[key]] === 'bad'
                            }
                            downTooltip={'Extracted defect is incorrect'}
                            downOnclick={handleClick(key, 'bad')}
                          />
                        )}
                        <CheckAndWarningInfo
                          compareValue={compareValue}
                          value={value}
                        />
                      </FlexRowEnd>
                    </InputAdornment>
                  ),
                }}
              />
            </DfmDefinitionTooltip>
          );
        })}
      {/* Display dfm success on the last list */}
      {!isEmptyValue(defectsOutput?.dfm_success) && showDfmSuccess && (
        <TextField
          label='DFM Success'
          id='dfm-success'
          variant='filled'
          value={defectsOutput?.dfm_success}
          margin='dense'
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <CheckAndWarningInfo
                  compareValue={EXTRACT_DEFECT_RULES_MAPPING.dfm_success}
                  value={defectsOutput?.dfm_success}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    </div>
  );
}

export default DfmDefectsForm;
