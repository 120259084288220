import React from 'react';

import { createCustomTooltip } from '../CustomizedTooltips';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

import { colors } from '../../palette';

function CheckIcon(props) {
  const { toolTipText = '', className, style, iconStyle } = props;

  const CustomTooltip = createCustomTooltip(style);

  return (
    <CustomTooltip
      title={toolTipText}
      placement='top'
      arrow
      className={className}
      disableHoverListener={!toolTipText}
    >
      <CheckCircleOutlineOutlinedIcon
        style={{
          fontSize: '1.5rem',
          color: colors.greenTagColor,
          ...iconStyle,
        }}
      />
    </CustomTooltip>
  );
}

export default CheckIcon;
