import React, { useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';
import { Popover, Typography } from '@material-ui/core';
import InfoTitle from './InfoTitle';
import clsx from 'clsx';
import InfoIcon from '../icons/InfoIcon';
import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  itemPropertyValue: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  bigValueFontSize: {
    fontSize: '1.2rem',
  },
  seeMore: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function KeyValueInfoDisplay(props) {
  const classes = useStyles();

  const {
    name,
    value = '',
    bigValueFontSize = false,
    color = 'black',
    titleTooltip,
    hideLongValueText = false,
    maxDisplayCharacters = 300,
    maxDisplayLines = 3,
  } = props;

  const [valueText, setValueText] = useState('');
  const [isLongValueText, setIsLongValueText] = useState(false);
  const valueAreaRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopOver = Boolean(anchorEl);

  const handleClick = (_) => {
    setAnchorEl(valueAreaRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (typeof value === 'undefined' || value === null) {
      return;
    }
    const strValue = `${value}`;
    const lines = strValue?.split('\n');
    if (
      lines.length > maxDisplayLines ||
      (hideLongValueText && strValue.length > maxDisplayCharacters)
    ) {
      const processedValue = lines?.slice(0, maxDisplayLines)?.join('\n');
      setIsLongValueText(true);
      setValueText(`${processedValue.substring(0, maxDisplayCharacters)}...`);
    } else {
      setValueText(strValue);
    }
  }, [value]);

  const renderInfoTitle = () => {
    if (!titleTooltip) {
      return <InfoTitle title={name} />;
    }
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <InfoTitle title={name} />
        &nbsp;
        <InfoIcon toolTipText={titleTooltip} />
      </div>
    );
  };

  return (
    <div>
      {renderInfoTitle()}
      <div
        style={{
          color: color,
        }}
        ref={valueAreaRef}
      >
        <Typography
          gutterBottom
          variant='body2'
          color='inherit'
          className={clsx(
            classes.itemPropertyValue,
            bigValueFontSize ? classes.bigValueFontSize : null
          )}
        >
          {valueText}
          {hideLongValueText && isLongValueText && (
            <span
              className={classes.seeMore}
              style={{
                color: colors.blue050,
              }}
              onClick={handleClick}
            >
              &nbsp;see more
            </span>
          )}
        </Typography>
      </div>
      <Popover
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography
          style={{
            padding: '0.5rem',
          }}
          variant='body2'
          color='inherit'
          className={clsx(
            classes.itemPropertyValue,
            bigValueFontSize ? classes.bigValueFontSize : null
          )}
        >
          {value}
        </Typography>
      </Popover>
    </div>
  );
}

export default KeyValueInfoDisplay;
