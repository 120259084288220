import React, { useContext } from 'react';

import { capitalize, get } from 'lodash';
import { Link } from 'react-router-dom';

import {
  Button,
  IconButton,
  Link as LinkButton,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { Autorenew, CancelScheduleSend, FileCopy } from '@material-ui/icons';

import DisplayAddress from '../components/DisplayAddress';
import MenuList from '../components/MenuList';
import Row from '../components/Row';
import CircleButton from '../components/buttons/CircleButton';
import {
  MCTCompleteButtonWithText,
  MCTInvoiceButton,
  MCTIssueDOButton,
  MCTItemCollectedButton,
  MCTOrderCancelledButton,
  MCTShipItemsButton,
} from '../components/buttons/actionButtons/MCTActionButton';
import {
  FtrB2,
  FtrButton,
  FtrCaption,
  FtrTooltip,
} from '../components/ftr-components';
import FtrLinkText from '../components/ftr-components/FtrLinkText';
import CheckIcon from '../components/icons/CheckIcon';
import EmailStatusIcon from '../components/icons/EmailStatusIcon';
import InfoIcon from '../components/icons/InfoIcon';
import ProjectOrderPaymentStatusInfo from '../components/info/ProjectOrderPaymentStatusInfo';
import {
  FlexColumn,
  FlexColumnCenter,
  FlexRow,
  FlexRowCenter,
} from '../components/layouts/FlexLayouts';
import DataGridWrapCell from '../components/tables/cells/DataGridWrapCell';
import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';

import {
  generateXeroInvoiceForMultiCheckout,
  reGenerateCombinedQuoteForMultiCheckout,
} from '../apis/multiCheckoutApi';

import { addressTextFormatted } from '../utils/addressUtils';
import { uniqueArray, uniqueObjectsByFields } from '../utils/arrayUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { getDateStrWithMonth } from '../utils/dateTimeUtils';
import { downloadS3File } from '../utils/fileUtils';
import {
  getDiscountAmount,
  getPromotionCodeDetailsForMctTable,
  getTotalPriceBeforeGst,
  uncollectedItemsFilter,
  undeliveredItemsFilter,
  unInvoicedItemsFilter,
  unshippedItemsFilter,
} from '../utils/multiCheckoutTrackingUtils';

import { notifySuccess } from '../services/notificationService';

import { openInNewTab } from '../utils/navigationUtils';
import { getQcReportsList } from '../utils/projectUtils';
import { isSuperAdminRole } from '../utils/roleUtils';

import { generatePresignedUrl } from '../services/s3Service';

import AdminMctPageContext from '../context/AdminMctPageContext';

import { ROLE_TYPES } from '../constants';
import { TAB_KEYS } from '../pages/MultipleCheckoutTracking';
import { FIFTEEN_MINUTES_IN_SECOND } from './dateTimeConstants';
import { CHECKOUT_TYPE, PAYMENT_TYPE } from './paymentConstants';

import { deliveryOptionCol } from './itemTableConstants';

import { colors, FACTOREM_BLUE } from '../palette';

// --------------------------------------------------------------------------------------------------

const displayDeliveryTime = (deliveryTime) => {
  if (!deliveryTime) {
    return 'N.A.';
  }

  const deliveryTimeArr = get(deliveryTime, 'deliveryTime') || [];
  if (isEmptyValue(deliveryTimeArr)) {
    return 'N.A.';
  }

  return deliveryTimeArr?.join(', ');
};

export const getDefaultMultiCheckoutColumns = ({
  updateCheckoutInfoPopup = () => {},
  updateDeliveryOrderFormsPopup = () => {},
  updateInvoiceInfoPopup = () => {},
  updateCollectedInfoPopup = () => {},
  updateShipItemPopup = () => {},
  updateFinanceNotePopup = () => {},
  updateAddressPopup = () => {},
  updateBackdropState = () => {},
  updateCheckoutTypePopupHOCState = () => {},
  refetch = () => {},
  setSelectedDeliverCheckout = () => {},
  setShowDeliverPopup = () => {},
  role,
  classes,
  tabKey,
}) => {
  const renderMenuListActions = (activeActionList) => {
    return (
      <MenuList title='View Actions'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            lineHeight: 'normal',
            alignContent: 'center',
            rowGap: '5px',
            width: '110px',
            padding: '10px',
          }}
        >
          {activeActionList?.map((value, index) => (
            <div key={index}>{value}</div>
          ))}
        </div>
      </MenuList>
    );
  };

  const containerBtnActions = (rowData) => {
    if (rowData.orderCancelled) {
      return <MCTOrderCancelledButton rowData={rowData} />;
    }

    const undeliveredItems = rowData.acceptedItems?.filter(
      undeliveredItemsFilter
    );
    const issueDOButton = isEmptyValue(undeliveredItems) ? null : (
      <MCTIssueDOButton
        onClick={() => {
          if (role === ROLE_TYPES.SALES_CONSULTANT) {
            return;
          }

          setSelectedDeliverCheckout(rowData);
          setShowDeliverPopup(true);
        }}
        acceptedItems={undeliveredItems}
      />
    );

    const uncollectedItems = rowData.acceptedItems?.filter(
      uncollectedItemsFilter
    );

    const itemCollectedButton = isEmptyValue(uncollectedItems) ? null : (
      <MCTItemCollectedButton
        onClick={() => {
          if (role === ROLE_TYPES.SALES_CONSULTANT) {
            return;
          }

          updateCollectedInfoPopup({
            open: true,
            selectedData: rowData,
          });
        }}
        acceptedItems={uncollectedItems}
      />
    );

    const unshippedItems = rowData.acceptedItems?.filter(unshippedItemsFilter);
    const shipItemsButton = isEmptyValue(unshippedItems) ? null : (
      <MCTShipItemsButton
        onClick={() => {
          if (role === ROLE_TYPES.SALES_CONSULTANT) {
            return;
          }

          updateShipItemPopup({
            open: true,
            selectedData: rowData,
          });
        }}
        acceptedItems={unshippedItems}
      />
    );

    const unInvoicedItems = rowData.acceptedItems?.filter(
      unInvoicedItemsFilter
    );
    const invoiceButton = isEmptyValue(unInvoicedItems) ? null : (
      <MCTInvoiceButton
        onClick={() => {
          if (role === ROLE_TYPES.SALES_CONSULTANT) {
            return;
          }

          updateInvoiceInfoPopup({
            open: true,
            selectedData: rowData,
          });
        }}
        acceptedItems={unInvoicedItems}
      />
    );

    const actions = {
      issueDO: issueDOButton,
      itemCollected: itemCollectedButton,
      shipItem: shipItemsButton,
      invoice: invoiceButton,
    };
    const activeActionListWithoutCompleted = Object.values(actions)?.filter(
      (value) => value
    );
    // Add a red counter bubble if there are items still not completed on MCT row
    const showBadgeCounter = activeActionListWithoutCompleted.length > 0;
    actions.completed = (
      <MCTCompleteButtonWithText
        rowData={rowData}
        showBadgeCounter={showBadgeCounter}
      />
    );

    const activeActionList = Object.values(actions)?.filter((value) => value);
    if (activeActionList.length > 3) {
      return renderMenuListActions(activeActionList);
    }

    return (
      <>
        {actions.issueDO}
        {actions.itemCollected}
        {actions.shipItem}
        {actions.invoice}
        {actions.completed}
      </>
    );
  };

  return [
    {
      headerName: 'Action',
      field: 'action',
      renderCell: ({ row: rowData }) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 'normal',
              rowGap: '5px',
            }}
          >
            {containerBtnActions(rowData)}
          </div>
        );
      },
      export: false,
      width: 130,
    },
    // this email status is for completed orders
    {
      headerName: 'Email status',
      field: 'emailStatusInvoice',
      renderCell: ({ row: rowData }) => {
        if (rowData.status === 'Failed') {
          return (
            <EmailStatusIcon
              status='failed'
              emailType={rowData?.type}
              date={rowData?.createdAt}
            />
          );
        }
        return (
          <FlexRow>
            {rowData.brevoInvoiceEmail && (
              <EmailStatusIcon
                status={rowData.brevoInvoiceEmail?.lastStatus}
                emailType={rowData.brevoInvoiceEmail?.type}
                date={rowData.brevoInvoiceEmail?.updatedAt}
              />
            )}
          </FlexRow>
        );
      },
      export: false,
      hide: tabKey !== TAB_KEYS.COMPLETED_ORDERS,
    },
    {
      headerName: 'Tracking ID',
      field: 'id',
    },
    {
      headerName: 'Reference Name',
      field: 'referenceName',
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapCell>
            <FlexRow>
              <FtrB2
                style={{
                  color: rowData.orderCancelled ? colors.red040 : 'inherit',
                }}
              >
                {rowData.referenceName}
              </FtrB2>
              {Boolean(rowData.orderCancelled) && (
                <FtrTooltip description='Order Cancelled'>
                  <CancelScheduleSend style={{ color: colors.red040 }} />
                </FtrTooltip>
              )}
            </FlexRow>
          </DataGridWrapCell>
        );
      },
      width: 120,
    },
    {
      headerName: 'User',
      field: 'user',
      valueGetter: ({ row: rowData }) => {
        return `${rowData.name} (${rowData.email})`;
      },
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={`${rowData.name} (${rowData.email})`} />
      ),
      width: 180,
    },
    {
      headerName: 'Project ID',
      field: 'projectID',
      renderCell: ({ row: rowData }) => {
        return (
          <Button
            className={classes.linkButton}
            href={`/manage-projects/${rowData.projectID}`}
          >
            {rowData.projectID}
          </Button>
        );
      },
    },
    {
      headerName: 'Checkout Info',
      field: 'checkoutInfo',
      valueGetter: ({ row: rowData }) => {
        // sort by itemID asc
        const displayItems = rowData.acceptedItems?.sort(
          (a, b) => a.itemID - b.itemID
        );
        return displayItems
          ?.map((item) => {
            return `ItemID: ${item.itemID} - QuotationID: ${item.quotationID}`;
          })
          ?.join(', ');
      },
      renderCell: ({ row: rowData }) => {
        const length = rowData.acceptedItems.length;
        // sort by itemID asc
        let displayItems = rowData.acceptedItems?.sort(
          (a, b) => a.itemID - b.itemID
        );
        if (length > 3) {
          displayItems = rowData.acceptedItems?.slice(0, 3);
        }

        const hasCustomerFinalRemarks = rowData.itemQuotationInfoList?.some(
          (i) => Boolean(i.customerFinalRemarks)
        );

        return (
          <div
            style={{
              lineHeight: 'normal',
            }}
            onClick={() => {
              if (length > 3) {
                updateCheckoutInfoPopup({
                  open: true,
                  selectedData: rowData,
                });
              }
            }}
          >
            {displayItems?.map((item) => {
              return (
                <div
                  style={{
                    display: 'flex',
                  }}
                  key={item.itemID}
                >
                  <Typography>
                    ItemID:&nbsp;
                    <Link to={{ pathname: `/item/edit/${item.itemID}` }}>
                      {item.itemID}
                    </Link>
                    &nbsp;-&nbsp;QuotationID:&nbsp;
                    <Link to={{ pathname: `/order/edit/${item.quotationID}` }}>
                      {item.quotationID}
                    </Link>
                  </Typography>
                </div>
              );
            })}
            {(length > 3 || hasCustomerFinalRemarks) && (
              <FlexRow
                onClick={() => {
                  updateCheckoutInfoPopup({
                    open: true,
                    selectedData: rowData,
                  });
                }}
                style={{
                  gap: '5px',
                }}
              >
                {hasCustomerFinalRemarks && (
                  <InfoIcon
                    toolTipText='View customer final remarks'
                    style={{ color: colors.warningYellow }}
                  />
                )}
                <LinkButton className={classes.seeMoreButton} variant='link'>
                  see more...
                </LinkButton>
              </FlexRow>
            )}
          </div>
        );
      },
      width: 300,
    },
    {
      headerName: 'Total Price (before GST) For Hubspot Entry',
      field: 'totalPrice',
      valueGetter: ({ row: rowData }) =>
        getTotalPriceBeforeGst(rowData.priceDetails),
      width: 120,
    },
    {
      headerName: 'Discount Amount',
      field: 'discountAmount',
      valueGetter: ({ row: rowData }) => getDiscountAmount(rowData),
      hide: true,
      export: true,
    },
    {
      headerName: 'Total Price Paid (incl. GST)',
      field: 'totalPricePaid',
      valueGetter: ({ row: rowData }) => rowData.totalPrice,
      width: 120,
    },
    {
      headerName: 'Promotion Code',
      field: 'promotionCode',
      valueGetter: ({ row: rowData }) => {
        const discountDetails = getPromotionCodeDetailsForMctTable(rowData);

        if (typeof discountDetails === 'string') {
          return discountDetails;
        }

        const { promotionCode, percentOff, discountAmount } = discountDetails;

        return `${promotionCode} (${percentOff}%: ${discountAmount})`;
      },
      renderCell: ({ row: rowData }) => {
        const discountDetails = getPromotionCodeDetailsForMctTable(rowData);

        if (typeof discountDetails === 'string') {
          return discountDetails;
        }

        const { promotionCode, percentOff, discountAmount } =
          getPromotionCodeDetailsForMctTable(rowData);

        return (
          <DataGridWrapCell>
            <FtrB2>{promotionCode}</FtrB2>
            <FtrB2>
              ({percentOff}%:{' '}
              <span style={{ color: colors.errorRed }}>{discountAmount}</span>)
            </FtrB2>
          </DataGridWrapCell>
        );
      },
      width: 130,
    },
    {
      ...deliveryOptionCol,
    },
    {
      headerName: 'Subtotal',
      field: 'subtotal',
      valueGetter: ({ row: rowData }) => rowData.priceDetails.totalCost,
      width: 120,
    },
    {
      headerName: 'Service Fee',
      field: 'serviceFee',
      valueGetter: ({ row: rowData }) => {
        const [currencySymbol] =
          rowData.priceDetails.totalCost?.split(' ') || [];
        if (rowData.priceDetails.platformFeeStr) {
          return rowData.priceDetails.platformFeeStr;
        }
        return currencySymbol + ' 0.00';
      },
    },
    {
      headerName: 'QC Report Cost',
      field: 'qcReportCost',
      valueGetter: ({ row: rowData }) => {
        const [currencySymbol] =
          rowData.priceDetails.totalCost?.split(' ') || [];
        if (rowData.priceDetails.qcReportFeeStr) {
          return rowData.priceDetails.qcReportFeeStr;
        }
        return currencySymbol + ' 0.00';
      },
    },
    {
      headerName: 'Delivery Cost',
      field: 'deliveryCost',
      valueGetter: ({ row: rowData }) => {
        return rowData.deliveryCost;
      },
      renderCell: ({ row: rowData }) => {
        const { updateMctDeliveryBreakdownPopupHOCState = () => {} } =
          useContext(AdminMctPageContext);

        const { deliveryCost, priceDetails = {} } = rowData;

        const { dhlResult = {} } = priceDetails;
        const { dhlShippingFee, dhlMultiplier } = dhlResult || {};

        const showViewBreakdown = dhlShippingFee > 0 || dhlMultiplier > 0;
        return (
          <DataGridWrapCell>
            <FtrB2>{deliveryCost}</FtrB2>
            {showViewBreakdown && (
              <LinkButton
                className={classes.seeMoreButton}
                variant='link'
                onClick={() => {
                  updateMctDeliveryBreakdownPopupHOCState({
                    open: true,
                    data: rowData,
                    mctID: rowData.id,
                  });
                }}
              >
                View breakdown
              </LinkButton>
            )}
          </DataGridWrapCell>
        );
      },
      width: 130,
    },
    {
      headerName: 'Estimate Delivery Date',
      field: 'estimateDeliveryDate',
      valueGetter: ({ row: rowData }) => {
        return getDateStrWithMonth(rowData.estimateDeliveryDate);
      },
      renderCell: ({ row: rowData }) =>
        rowData.estimateDeliveryDate ? (
          <DataGridWrapTextCell
            text={getDateStrWithMonth(rowData.estimateDeliveryDate)}
          />
        ) : (
          ''
        ),
      width: 120,
    },
    {
      headerName: 'Delivery Time',
      field: 'deliveryTime',
      valueGetter: ({ row: rowData }) => {
        return displayDeliveryTime(rowData.deliveryTime);
      },
      renderCell: ({ row: rowData }) =>
        rowData.estimateDeliveryDate ? (
          <DataGridWrapTextCell
            text={displayDeliveryTime(rowData.deliveryTime)}
          />
        ) : (
          ''
        ),
      width: 120,
    },
    {
      headerName: 'QC Reports',
      field: 'qcReports',
      valueGetter: ({ row: rowData }) => {
        return getQcReportsList(rowData.acceptedQcReports)?.join(', ');
      },
      renderCell: ({ row: rowData }) => {
        const qcReports = getQcReportsList(rowData.acceptedQcReports)?.join(
          ', '
        );
        return <DataGridWrapTextCell text={qcReports} />;
      },
      width: 150,
    },
    {
      headerName: 'Quotation Form',
      field: 'quotationForm',
      valueGetter: ({ row: rowData }) => rowData.quotationFormUrl || 'N.A.',
      renderCell: ({ row: rowData }) =>
        rowData.quotationFormUrl ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginRight: '40px',
              whiteSpace: 'normal',
              lineHeight: 'normal',
              alignItems: 'center',
            }}
          >
            <Link
              data-cy='download-quotation-btn'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                downloadS3File(rowData.quotationFormUrl);
              }}
            >
              Download Quotation
            </Link>
            <IconButton
              edge='end'
              onClick={() => {
                navigator.clipboard.writeText(rowData.quotationFormUrl);
                notifySuccess('Url Copied');
              }}
            >
              <FileCopy />
            </IconButton>
          </div>
        ) : (
          <Row>
            <div>N.A.</div>
            <Tooltip title='Generate Quotation Form'>
              <div>
                <CircleButton
                  size='small'
                  style={{
                    backgroundColor: FACTOREM_BLUE,
                  }}
                  onClick={() => {
                    updateBackdropState({
                      open: true,
                      title: 'Generating Quotation Form PDF...',
                    });
                    const body = {
                      multiCheckoutID: rowData.id,
                      updateDB: true,
                    };
                    reGenerateCombinedQuoteForMultiCheckout(body)
                      .then(refetch)
                      .then(() => {
                        updateBackdropState({ open: false });
                        notifySuccess(
                          `Quotation form has been updated successfully`
                        );
                      });
                  }}
                >
                  <Autorenew style={{ color: 'white' }} />
                </CircleButton>
              </div>
            </Tooltip>
          </Row>
        ),
      width: 120,
    },
    {
      headerName: 'Customer PO',
      field: 'customerPO',
      valueGetter: ({ row: rowData }) => {
        const itemQuotationInfoList = rowData.itemQuotationInfoList || [];
        let customerPOList = itemQuotationInfoList
          ?.map((item) => item.customerUploadFiles || [])
          ?.flat()
          ?.filter((item) => item.type === 'CUSTOMER_PO')
          ?.map((item) => item.fileName);

        customerPOList = uniqueArray(customerPOList);

        return customerPOList?.join(', ');
      },
      renderCell: ({ row: rowData }) => {
        const itemQuotationInfoList = rowData.itemQuotationInfoList || [];
        let customerPOList = itemQuotationInfoList
          ?.map((item) => item.customerUploadFiles || [])
          ?.flat()
          ?.filter((item) => item.type === 'CUSTOMER_PO');

        customerPOList = uniqueObjectsByFields(customerPOList, ['url']);

        return (
          <DataGridWrapCell>
            <FlexRow
              style={{
                gap: '5px',
              }}
            >
              <FlexColumn style={{ padding: 0 }}>
                {customerPOList?.map((item) => (
                  <FtrLinkText
                    key={item.fileName}
                    text={item.fileName}
                    onClick={() => {
                      generatePresignedUrl(
                        item.url,
                        FIFTEEN_MINUTES_IN_SECOND,
                        true
                      ).then(openInNewTab);
                    }}
                  />
                ))}
              </FlexColumn>
              {rowData.customerPOUrl &&
                (rowData.isPOReconciled ? (
                  <CheckIcon
                    toolTipText='The most recent customer PO is reconciled'
                    iconStyle={{ fontSize: '1.1rem' }}
                  />
                ) : (
                  <InfoIcon
                    toolTipText='The most recent customer PO is not reconciled'
                    style={{ color: colors.warningYellow }}
                  />
                ))}
            </FlexRow>
          </DataGridWrapCell>
        );
      },
      width: 150,
    },
    {
      headerName: 'Delivery Order Form(s)',
      field: 'deliveryOrderFormUrl',
      valueGetter: ({ row: rowData }) => rowData.deliveryOrderFormUrl || 'N.A.',
      renderCell: ({ row: rowData }) => {
        let urls = rowData.deliveryOrderFormUrl
          ? rowData.deliveryOrderFormUrl?.split(';')
          : [];
        const length = urls.length;
        if (isEmptyValue(urls)) {
          return 'N.A.';
        }
        if (length > 2) {
          urls = urls?.slice(0, 2);
        }
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 'normal',
            }}
          >
            {urls?.map((url) => {
              return (
                <div
                  key={url}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    lineHeight: 'normal',
                  }}
                >
                  <Link
                    key={url}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      downloadS3File(url);
                    }}
                  >
                    {url?.split('/').pop()}
                  </Link>
                  <IconButton
                    edge='end'
                    onClick={() => {
                      navigator.clipboard.writeText(url);
                      notifySuccess('Url Copied');
                    }}
                    style={{
                      padding: 6,
                    }}
                  >
                    <FileCopy />
                  </IconButton>
                </div>
              );
            })}
            {length > 2 && (
              <LinkButton
                className={classes.seeMoreButton}
                variant='link'
                onClick={() => {
                  updateDeliveryOrderFormsPopup({
                    open: true,
                    selectedData: rowData,
                  });
                }}
              >
                see more...
              </LinkButton>
            )}
          </div>
        );
      },
      width: 220,
    },
    {
      headerName: 'Checkout Type',
      field: 'checkoutType',
      renderCell: ({ row: rowData }) => {
        const { paymentType } = rowData;
        let result = rowData.checkoutType;
        if (result === CHECKOUT_TYPE.STRIPE) {
          if (paymentType === PAYMENT_TYPE.STRIPE_PAYNOW) {
            result = `${result} (paynow)`;
          } else if (paymentType === PAYMENT_TYPE.STRIPE_CREDIT_CARD) {
            result = `${result} (credit card)`;
          }
        }
        return (
          <Tooltip title='Click to edit' arrow>
            <div
              onClick={() => {
                updateCheckoutTypePopupHOCState({
                  open: true,
                  selectedData: rowData,
                  refetch: refetch,
                });
              }}
            >
              {result}
            </div>
          </Tooltip>
        );
      },
      width: 140,
    },
    {
      headerName: 'Xero Invoice ID',
      field: 'xeroInvoiceID',
      renderCell: ({ row: rowData }) => {
        if (rowData.xeroInvoiceID) {
          return rowData.xeroInvoiceID;
        }
        return (
          <Row>
            <div>N.A.</div>
            {isSuperAdminRole(role) && (
              <Tooltip title='Generate Xero Invoice'>
                <div>
                  <CircleButton
                    size='small'
                    style={{
                      backgroundColor: FACTOREM_BLUE,
                    }}
                    onClick={() => {
                      updateBackdropState({
                        open: true,
                        title: 'Generating Xero Invoice...',
                      });
                      const body = {
                        multiCheckoutID: rowData.id,
                        updateDB: true,
                      };
                      generateXeroInvoiceForMultiCheckout(body)
                        .then(refetch)
                        .then(() => {
                          updateBackdropState({ open: false });
                          notifySuccess(
                            `Xero Invoice has been updated successfully`
                          );
                        });
                    }}
                  >
                    <Autorenew style={{ color: 'white' }} />
                  </CircleButton>
                </div>
              </Tooltip>
            )}
          </Row>
        );
      },
      width: 300,
    },
    {
      headerName: 'Xero Invoice URL',
      field: 'xeroInvoiceUrl',
      valueGetter: ({ row: rowData }) => rowData.xeroInvoiceUrl || 'N.A.',
      renderCell: ({ row: rowData }) =>
        rowData.xeroInvoiceUrl ? (
          <a href={rowData.xeroInvoiceUrl} target='_blank' rel='noreferrer'>
            Link
          </a>
        ) : (
          <Row>
            <div>N.A.</div>
            {isSuperAdminRole(role) && (
              <Tooltip title='Generate Xero Invoice'>
                <div>
                  <CircleButton
                    size='small'
                    style={{
                      backgroundColor: FACTOREM_BLUE,
                    }}
                    onClick={() => {
                      updateBackdropState({
                        open: true,
                        title: 'Generating Xero Invoice...',
                      });
                      const body = {
                        multiCheckoutID: rowData.id,
                        updateDB: true,
                      };
                      generateXeroInvoiceForMultiCheckout(body)
                        .then(refetch)
                        .then(() => {
                          updateBackdropState({ open: false });
                          notifySuccess(
                            `Xero Invoice has been updated successfully`
                          );
                        });
                    }}
                  >
                    <Autorenew style={{ color: 'white' }} />
                  </CircleButton>
                </div>
              </Tooltip>
            )}
          </Row>
        ),
    },
    {
      headerName: 'Invoice Payment Status',
      field: 'xeroPaymentStatus',
      cellAlign: 'center',
      valueGetter: ({ row: rowData }) => rowData.xeroPaymentStatus || 'N.A.',
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapCell style={{ width: '100%' }}>
            <FlexColumnCenter>
              <FlexRowCenter>
                <ProjectOrderPaymentStatusInfo
                  text={capitalize(rowData.xeroPaymentStatus) || 'N.A.'}
                  status={rowData.xeroPaymentStatus}
                />
              </FlexRowCenter>
              {!!rowData.dateOfReconciliation && (
                <FlexColumnCenter style={{ gap: 0 }}>
                  <FtrCaption>Reconciliation date:</FtrCaption>
                  <FtrCaption>
                    {getDateStrWithMonth(rowData.dateOfReconciliation)}
                  </FtrCaption>
                </FlexColumnCenter>
              )}
            </FlexColumnCenter>
          </DataGridWrapCell>
        );
      },
      width: 150,
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      valueGetter: ({ row: rowData }) => {
        return getDateStrWithMonth(rowData.createdDate);
      },
      width: 120,
    },
    {
      headerName: 'Shipping Address',
      field: 'shippingAddress',
      renderCell: ({ row: rowData }) => {
        return rowData.shippingAddress ? (
          <Tooltip title='Click to edit' arrow>
            <div
              style={{
                whiteSpace: 'normal',
                lineHeight: 'normal',
              }}
              onClick={() => {
                updateAddressPopup({
                  open: true,
                  selectedData: rowData,
                  addressType: 'shipping',
                });
              }}
            >
              <DisplayAddress data={rowData.shippingAddress} />
            </div>
          </Tooltip>
        ) : (
          'N.A.'
        );
      },
      valueGetter: ({ row: rowData }) =>
        addressTextFormatted(rowData.shippingAddress),
      width: 300,
    },
    {
      headerName: 'Billing Address',
      field: 'billingAddress',
      renderCell: ({ row: rowData }) => {
        return rowData.billingAddress ? (
          <Tooltip title='Click to edit' arrow>
            <div
              style={{
                whiteSpace: 'normal',
                lineHeight: 'normal',
              }}
              onClick={() => {
                updateAddressPopup({
                  open: true,
                  selectedData: rowData,
                  addressType: 'billing',
                });
              }}
            >
              <DisplayAddress data={rowData.billingAddress} />
            </div>
          </Tooltip>
        ) : (
          'N.A.'
        );
      },
      valueGetter: ({ row: rowData }) =>
        addressTextFormatted(rowData.billingAddress),
      width: 300,
    },
    {
      headerName: 'Note for Finance',
      field: 'financeNote',
      renderCell: ({ row: rowData }) => {
        if (rowData.financeNote) {
          return (
            <Tooltip title='Click to edit' arrow>
              <div
                style={{
                  overflowY: 'auto',
                  maxHeight: 120,
                  cursor: 'pointer',
                  minWidth: 160,
                  whiteSpace: 'normal',
                  lineHeight: 'normal',
                }}
                onClick={() =>
                  updateFinanceNotePopup({ open: true, selectedData: rowData })
                }
              >
                {rowData.financeNote}
              </div>
            </Tooltip>
          );
        }
        return (
          <Tooltip title='Click to add' arrow>
            <div style={{ display: 'flex' }}>
              <FtrButton
                variant='text'
                color='blue'
                onClick={() =>
                  updateFinanceNotePopup({ open: true, selectedData: rowData })
                }
                style={{ padding: 0 }}
              >
                + Add note
              </FtrButton>
            </div>
          </Tooltip>
        );
      },
      minWidth: 160,
    },
    // this email status is for all orders and in progress orders
    {
      headerName: 'Email status',
      field: 'emailStatusOrder',
      renderCell: ({ row: rowData }) => {
        if (rowData.status === 'Failed') {
          return (
            <EmailStatusIcon
              status='failed'
              emailType={rowData?.type}
              date={rowData?.createdAt}
            />
          );
        }
        return (
          <FlexRow>
            {rowData.brevoOrdersConfirmation && (
              <EmailStatusIcon
                status={rowData.brevoOrdersConfirmation?.lastStatus}
                emailType={rowData.brevoOrdersConfirmation?.type}
                date={rowData.brevoOrdersConfirmation?.updatedAt}
              />
            )}
          </FlexRow>
        );
      },
      hide: tabKey === TAB_KEYS.COMPLETED_ORDERS,
    },
    {
      headerName: 'Order Cancelled',
      field: 'orderCancelled',
      valueGetter: ({ row: rowData }) => {
        return rowData.orderCancelled ? 'Yes' : 'No';
      },
      hide: true,
    },
    {
      headerName: 'Exchange Rate',
      field: 'exchangeRate',
      valueGetter: ({ row: rowData }) => {
        return rowData.exchangeRate || 1;
      },
      hide: true,
    },
  ];
};
