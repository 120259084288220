import React, { useReducer } from 'react';

import CreditNotePopup from '../components/popups/CreditNotePopup';
import { CURRENCY_CODE } from '../constants/currencyConstants';
import { updateProjectOrderReadyInfo } from '../apis/orderReadyApi';
import { notifyError, notifySuccess } from '../services/notificationService';

function withCreditNotePopupHoC(WrappedComponent) {
  return function WrappedComponentWithCreditNotePopupHoC(props) {
    const [localState, updateLocalState] = useReducer(
      (prev, next) => {
        return { ...prev, ...next };
      },
      {
        open: false,
        loading: false,
        creditNote: '',
        currency: CURRENCY_CODE.SGD,
        onSuccess: () => {},
        onSubmit: () => {},
        projectOrderReadyID: null,
      }
    );

    const handleUpdateCreditNote = ({ creditNote, additionalNote }) => {
      updateProjectOrderReadyInfo(localState?.projectOrderReadyID, {
        creditNote,
        additionalProps: { additionalNote },
      })
        .then(() => {
          localState.onSuccess();
          notifySuccess(`Credit Note has been updated successfully.`);
        })
        .catch((err) => {
          notifyError(`Credit Note failed to update. Error: ${err.message}`);
        });
    };

    return (
      <>
        <WrappedComponent
          updateCreditNotePopupHoCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <CreditNotePopup
            open={localState.open}
            onClose={() => updateLocalState({ open: false })}
            onSubmit={handleUpdateCreditNote}
            loading={localState.loading}
            creditNote={localState.creditNote}
            currency={localState.currency}
          />
        )}
      </>
    );
  };
}

export default withCreditNotePopupHoC;
