import React, { useEffect, useMemo, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty, isDate, cloneDeep } from 'lodash';
import isURL from 'validator/lib/isURL';
import Decimal from 'decimal.js';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';

import { DatePicker } from '@material-ui/pickers';

import WhiteButton from '../buttons/WhiteButton';
import BlueButton from '../buttons/BlueButton';
import SubDesc from '../SubDesc';
import QualityAccordion from '../accordions/QualityAccordion';
import { FtrTypography } from '../ftr-components';
import { FlexRow } from '../layouts/FlexLayouts';
import DatePickerWithIcon from '../DatePickerWithIcon';
import DeliveryMessageDisplay from '../DeliveryMessageDisplay';
import FtrCheckbox from '@components/ftr-components/FtrCheckbox';
import { CenterPositionAbsolute } from '@components/ftr-components/FixedPosition';

import { colors } from '../../palette';

import { SHIPMENT_PROVIDERS } from '../../constants/orderReadyConstants';
import { FIVE_MINUTES_IN_SECOND } from '../../constants/dateTimeConstants';
import { CURRENCY_CODE } from '../../constants/currencyConstants';

import { notifyError } from '../../services/notificationService';
import { generatePresignedUrl } from '../../services/s3Service';

import useDeviceCheck from '../../hooks/useDeviceCheck';
import useQualityMetrics from '../../hooks/useQualityMetrics';

import {
  convertPriceToCurrencyBeautified,
  convertPriceWithQuantityToCurrency,
  extractPriceFromCurrencyString,
  getCurrencySymbol,
} from '../../utils/currencyUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { downloadS3File } from '../../utils/fileUtils';

import { getTrackingByID } from '../../apis/multiCheckoutApi';

import DescriptiveTooltip from '../tooltips/DescriptiveTooltip';

const useStyles = makeStyles(() => ({
  dialog: {
    style: { padding: '70px' },
  },
  label: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '20px',
  },
  container: {
    display: 'flex',
  },
  datePickerError: {
    '& .Mui-error': {
      color: 'blue',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'blue',
    },
  },
  issueDoButton: {
    fontSize: '9pt',
    padding: '10px 15px',
    borderRadius: '5px',
  },
  shipItemButton: {
    background: colors.purpleGradient,
    color: colors.fontWhite,
    letterSpacing: '0.15em',
    fontSize: '9pt',
    padding: '10px 15px',
    borderRadius: '5px',
    '&:hover': {
      filter: 'brightness(0.8)',
    },
  },
  updateAllActualDeliveryDate: {
    fontSize: '10px',
    padding: '8px 8px',
  },
  linkIcon: {
    verticalAlign: 'middle',
    height: '1rem',
    width: '1rem',
    cursor: 'pointer',
    mixBlendMode: 'multiply',
  },
}));

function ShipItemPopup(props) {
  const classes = useStyles();

  const { handleOk, open, handleCancel, rowData } = props;

  const [{ isIPad, isMobile, isTablet }] = useDeviceCheck();
  const loggedInUser = useSelector((state) => state.auth.user);

  const { data: rowDataInfo, isLoading } = useQuery(
    ['getTrackingByID', rowData.id],
    () => getTrackingByID(rowData.id)
  );

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      shipmentProvider: null,
      otherShipmentProvider: null,
      isShipmentNotApplicable: false,
      shipmentDate: new Date(),
      isShipmentNumberNotAvailable: false,
      shipmentTrackingNumber: null,
      dropOffUserID: null,
      isSendOrderOnItsWayEmail: true,
      items: [],
      errors: {},
    }
  );

  useEffect(() => {
    if (!isEmptyValue(rowDataInfo?.acceptedItemsInfo)) {
      const deliveredItemIDs = rowData?.acceptedItems
        ?.filter((item) => item.collected && !item.shipItem)
        ?.map((item) => item.itemID);
      const initialItems = rowDataInfo?.acceptedItemsInfo
        ?.filter((item) => deliveredItemIDs.includes(item.itemID))
        ?.map((item) => {
          return {
            ...item,
            actualDeliveryDate: item.actualDeliveryDate
              ? new Date(item.actualDeliveryDate)
              : new Date(),
          };
        });
      updateLocalState({
        items: initialItems,
        dropOffUserID: rowDataInfo.userID,
      });
    }
  }, [rowDataInfo]);

  const [
    { combinedItemsQuality },
    { updateQualityInformation, getQualityProperties },
  ] = useQualityMetrics(localState?.items);

  if (!rowData) {
    return null;
  }

  const {
    qcReportFee,
    qcReportFeeStr,
    gstInclAllFee,
    platformFee,
    platformFeeStr,
    exchangeRate,
    currency: multiCheckoutTrackingCurrency,
    deliveryFee,
    deliveryFeeInNumber,
  } = rowDataInfo?.priceDetails || {};

  const gstFee = extractPriceFromCurrencyString(gstInclAllFee);

  const filteredItems =
    combinedItemsQuality?.filter((item) => item.checked) ?? [];

  const currency = useMemo(() => {
    return (
      multiCheckoutTrackingCurrency ??
      filteredItems[0]?.qCurrency ??
      CURRENCY_CODE.SGD
    );
  }, [filteredItems, multiCheckoutTrackingCurrency]);

  const currencySymbol = getCurrencySymbol(currency);

  const totalWeightValueForSelectedItems = useMemo(() => {
    return filteredItems?.reduce(
      (acc, curr) => {
        if (curr.ppeInformation?.weight) {
          // similar to how weight is calculated by dividing by item.quantity in ProjectSingleQuoteItemDisplay.jsx, InternationalShippingCalculator and priceUtils.js
          acc.sum = acc.sum
            .plus(curr.ppeInformation.weight) // in kg
            .dividedBy(curr.itemQuantity)
            .times(curr.quantity);
        } else {
          acc.IDsOfItemsWithUnknownWeights.push(curr.itemID);
        }
        return acc;
      },
      { sum: new Decimal(0), IDsOfItemsWithUnknownWeights: [] }
    );
  }, [filteredItems]);

  const totalInvoiceValueForSelectedItems = useMemo(() => {
    if (isEmptyValue(filteredItems)) {
      return {
        totalDisplayStr: `0.00`,
        sgdStr: `0.00`,
      };
    }
    const invoiceItems = filteredItems?.reduce((acc, curr) => {
      const { totalPrice } = convertPriceWithQuantityToCurrency({
        totalPrice: curr.priceForCustomer,
        currency,
        quantity: curr.quantity,
        exchangeRate: exchangeRate ?? filteredItems[0]?.qExchangeRate,
      });
      return new Decimal(acc).plus(totalPrice).toNumber();
    }, 0);
    const priceValue = new Decimal(platformFee)
      .plus(deliveryFeeInNumber)
      .plus(qcReportFee)
      .plus(gstFee)
      .toNumber();
    const checkedPriceValue = new Decimal(priceValue)
      .times(filteredItems.length)
      .dividedBy(rowData.acceptedItems?.length)
      .toNumber();
    const totalInvoiceValue = new Decimal(invoiceItems)
      .plus(checkedPriceValue)
      .toNumber();

    const customerCurrencyStr = convertPriceToCurrencyBeautified({
      price: totalInvoiceValue,
      currency,
    });
    if (currency === CURRENCY_CODE.SGD) {
      return {
        totalDisplayStr: customerCurrencyStr,
        sgdStr: customerCurrencyStr,
      };
    }
    const { totalPriceStr: sgdStr } = convertPriceWithQuantityToCurrency({
      totalPrice: totalInvoiceValue,
      currency: CURRENCY_CODE.SGD,
      quantity: 1,
      exchangeRate: new Decimal(exchangeRate).pow(-1),
    });
    return {
      totalDisplayStr: `${customerCurrencyStr} (${sgdStr})`,
      sgdStr,
    };
  }, [filteredItems]);

  const handleChangeDate = (date, index) => {
    const itemsStateClone = cloneDeep(localState?.items);
    itemsStateClone[index].actualDeliveryDate = date;
    updateLocalState({ items: itemsStateClone });
  };
  const handleChangeCheckbox = (event, index) => {
    const checked = event.target.checked;
    const itemsStateClone = cloneDeep(localState?.items);
    itemsStateClone[index].checked = checked;
    updateLocalState({ items: itemsStateClone });
  };

  // will update all actualDeliveryDates to current date
  const updateActualDeliveryDate = () => {
    const itemsStateClone = cloneDeep(localState?.items);
    itemsStateClone.forEach((item) => {
      item.actualDeliveryDate = new Date();
    });
    updateLocalState({ items: itemsStateClone });
  };

  const handleUpdateAllDate = (date) => {
    const newItems = localState?.items?.map((item) => ({
      ...item,
      actualDeliveryDate: date,
    }));
    updateLocalState({ items: newItems });
  };

  const handleSubmit = () => {
    const params = {
      shipmentProvider: 'N.A.',
      shipmentTrackingNumber: 'N.A.',
      shipmentDate: localState?.shipmentDate,
      itemsData: filteredItems,
      isSendOrderOnItsWayEmail: localState.isSendOrderOnItsWayEmail,
    };
    // If Shipment Date is not date
    if (!isDate(localState?.shipmentDate)) {
      const message = 'Shipment Date is not valid';
      updateLocalState({
        errors: {
          shipmentDate: message,
        },
      });
      notifyError(message);
      return;
    }

    // If shipment applicable
    if (!localState?.isShipmentNotApplicable) {
      params.shipmentProvider =
        localState?.shipmentProvider === 'Other'
          ? localState?.otherShipmentProvider
          : localState?.shipmentProvider;
      if (!localState?.shipmentProvider) {
        const message = 'Shipment Provider is required';
        updateLocalState({
          errors: {
            shipmentProvider: message,
          },
        });
        notifyError(message);
        return;
      }
      if (
        localState?.shipmentProvider === 'Other' &&
        !localState?.otherShipmentProvider
      ) {
        const message = 'Other Shipment Provider is required';
        updateLocalState({
          errors: {
            otherShipmentProvider: message,
          },
        });
        notifyError(message);
        return;
      }

      // If shipment shipment number available
      if (!localState?.isShipmentNumberNotAvailable) {
        params.shipmentTrackingNumber = localState?.shipmentTrackingNumber;
        if (!params.shipmentTrackingNumber) {
          const message = 'Shipment Tracking Number is required';
          updateLocalState({
            errors: {
              shipmentTrackingNumber: message,
            },
          });
          notifyError(message);
          return;
        }
        if (isURL(params.shipmentTrackingNumber)) {
          const message = `Shipment Tracking Number is not an URL`;
          updateLocalState({
            errors: {
              shipmentTrackingNumber: message,
            },
          });
          notifyError(message);
          return;
        }
      }
    }
    handleOk(params);
    handleCancel();
  };

  const renderActionButtons = () => {
    return (
      <FlexRow
        style={{
          margin: '1rem 1.5rem',
          position: 'relative',
        }}
      >
        <FtrCheckbox
          checked={localState.isSendOrderOnItsWayEmail}
          label='Notify Customer Via Email'
          onChange={(e) =>
            updateLocalState({
              isSendOrderOnItsWayEmail: e.target.checked,
            })
          }
        />
        <CenterPositionAbsolute>
          <FlexRow>
            <WhiteButton
              onBtnClick={handleCancel}
              btnContent='Cancel'
              size='small'
            />
            <Button
              onClick={handleSubmit}
              disabled={isEmpty(filteredItems)}
              className={classes.shipItemButton}
            >
              Ship Item
            </Button>
          </FlexRow>
        </CenterPositionAbsolute>
      </FlexRow>
    );
  };

  const renderFieldText = (field, text) => {
    return (
      <FlexRow>
        <div style={{ width: '90px' }}>{field}</div>
        <span>: {text}</span>
      </FlexRow>
    );
  };

  const renderDetailsPriceInfoForSelectedItems = () => {
    return (
      <div
        style={{
          minWidth: '200px',
          color: colors.fontBlack,
          fontSize: '14px',
          lineHeight: '1.4',
        }}
      >
        <FtrTypography style={{ fontWeight: 'bold' }}>
          Details price info
        </FtrTypography>
        {filteredItems?.map((item) => {
          const { totalPriceStr } = convertPriceWithQuantityToCurrency({
            totalPrice: item.priceForCustomer,
            currency,
            quantity: item.quantity,
            exchangeRate: exchangeRate ?? filteredItems[0]?.qExchangeRate,
          });
          return (
            <div key={item.itemID}>
              {renderFieldText(`ItemID ${item.itemID}`, totalPriceStr)}
            </div>
          );
        })}
        {renderFieldText(`Delivery`, deliveryFee)}
        {renderFieldText(`Service`, platformFeeStr)}
        {renderFieldText(`QC Report`, qcReportFeeStr)}
        {renderFieldText(
          `GST`,
          `${currencySymbol} ${Number(gstFee)?.toFixed(2)}`
        )}
        {renderFieldText(
          `Selected`,
          `${filteredItems.length}/${rowData.acceptedItems?.length}`
        )}
        {renderFieldText(
          `Total Invoice`,
          totalInvoiceValueForSelectedItems.totalDisplayStr
        )}
      </div>
    );
  };

  const renderWeightInfoForSelectedItems = () => {
    return (
      <div
        style={{
          minWidth: '200px',
          color: colors.fontBlack,
          fontSize: '14px',
          lineHeight: '1.4',
        }}
      >
        <FtrTypography style={{ fontWeight: 'bold' }}>
          Item Weights
        </FtrTypography>
        {filteredItems?.map((item) => {
          const weight = item.ppeInformation?.weight ?? 'Unknown';
          return (
            <div key={item.itemID}>
              {renderFieldText(`ItemID ${item.itemID}`, `${weight} kg`)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderItems = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '0.7rem',
        }}
      >
        <div
          className={classes.container}
          style={{
            justifyContent: 'space-between',
            gap: '0.5rem',
            alignItems: 'center',
            marginBottom: '0.7rem',
          }}
        >
          <span style={{ fontSize: '1rem' }}>
            Please select items being shipped and set actual delivery dates
          </span>
          <BlueButton
            onBtnClick={updateActualDeliveryDate}
            size='small'
            btnContent='Update all with today’s date'
            className={classes.updateAllActualDeliveryDate}
          />
        </div>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            {combinedItemsQuality?.map((item, index) => {
              return (
                <div key={item.itemID}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '0.5rem',
                    }}
                  >
                    <FormControlLabel
                      style={{ marginRight: '0' }}
                      control={
                        <Checkbox
                          checked={item.checked}
                          onChange={(event) =>
                            handleChangeCheckbox(event, index)
                          }
                        />
                      }
                    />
                    <Typography>
                      ItemID:&nbsp;
                      <Link to={{ pathname: `/item/edit/${item.itemID}` }}>
                        {item.itemID}
                      </Link>
                    </Typography>
                    <span>-</span>
                    <DatePickerWithIcon
                      label='Actual Delivery Date'
                      value={item.actualDeliveryDate}
                      onChange={(date) => handleChangeDate(date, index)}
                      animateYearScrolling
                      inputVariant='outlined'
                      margin='dense'
                      onIconClick={(date) => handleUpdateAllDate(date)}
                    />
                  </div>
                  <QualityAccordion
                    style={{ margin: '0 0 0.5rem 2.5rem' }}
                    properties={getQualityProperties(item, [
                      'partnerDimQuality',
                      'partnerVisualQuality',
                      'partnerPackagingQuality',
                      'partnerSFQuality',
                    ])}
                    updateQualityInformation={updateQualityInformation}
                    itemID={item.itemID}
                    displayOneColumn={true}
                  />
                </div>
              );
            })}
          </Grid>
          <Grid item sm={6} alignItems='flex-end'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <FtrTypography
                style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                fontSize='14'
              >
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (rowData.quotationFormUrl) {
                      if (!isMobile && !isTablet && !isIPad) {
                        generatePresignedUrl(
                          rowData.quotationFormUrl,
                          FIVE_MINUTES_IN_SECOND,
                          true
                        ).then((presignedUrl) => {
                          window.open(presignedUrl);
                        });
                      } else {
                        downloadS3File(rowData.quotationFormUrl);
                      }
                    }
                  }}
                >
                  Invoice
                </Link>{' '}
                value to declare for customs declaration
              </FtrTypography>
              <FlexRow>
                <FtrTypography
                  style={{
                    color: colors.blue060,
                    fontWeight: 'bold',
                  }}
                >
                  Total Invoice Value:{' '}
                  {totalInvoiceValueForSelectedItems.totalDisplayStr}
                </FtrTypography>
                <DescriptiveTooltip
                  tooltipContent={renderDetailsPriceInfoForSelectedItems()}
                />
              </FlexRow>
              <FlexRow>
                <FtrTypography
                  style={{
                    color: isEmptyValue(
                      totalWeightValueForSelectedItems.IDsOfItemsWithUnknownWeights
                    )
                      ? colors.blue060
                      : colors.red040,
                    fontWeight: 'bold',
                  }}
                >
                  Total Weight:{' '}
                  {totalWeightValueForSelectedItems.sum.lessThan(1)
                    ? `${totalWeightValueForSelectedItems.sum
                        .times(1000)
                        .toNumber()
                        .toFixed(0)} g`
                    : `${totalWeightValueForSelectedItems.sum
                        .toNumber()
                        .toFixed(2)} kg`}
                </FtrTypography>
                <DescriptiveTooltip
                  tooltipContent={renderWeightInfoForSelectedItems()}
                />
              </FlexRow>
              <DeliveryMessageDisplay
                selectedItemsIDs={filteredItems?.map((item) => item.itemID)}
                dropOffUserID={localState.dropOffUserID}
                pickUpUserID={loggedInUser.userID}
                trackingInfo={rowDataInfo}
                isShippingToCustomer={true}
                invoiceAmountStr={totalInvoiceValueForSelectedItems.sgdStr}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderShipmenInfo = () => {
    return (
      <>
        <div
          className={classes.container}
          style={{ justifyContent: 'space-between' }}
        >
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) =>
                  updateLocalState({
                    isShipmentNotApplicable: event.target.checked,
                  })
                }
                checked={localState?.isShipmentNotApplicable}
              />
            }
            label={'Shipment not applicable'}
          />
          <DatePicker
            label='Shipment Date'
            value={localState?.shipmentDate}
            onChange={(date) =>
              updateLocalState({ shipmentDate: date, errors: {} })
            }
            animateYearScrolling
            inputVariant='outlined'
            margin='dense'
            error={!isDate(localState?.shipmentDate)}
            helperText={localState?.errors?.shipmentDate}
          />
        </div>
        {!localState?.isShipmentNotApplicable && (
          <>
            <TextField
              label='Shipment Provider'
              labelId='shipment-provider'
              id='shipment-provider'
              variant='outlined'
              onChange={(evt) =>
                updateLocalState({
                  shipmentProvider: evt.target.value,
                  errors: {},
                })
              }
              value={localState?.shipmentProvider}
              margin='dense'
              fullWidth
              select
              error={!!localState.errors?.shipmentProvider}
              helperText={localState.errors?.shipmentProvider}
            >
              {SHIPMENT_PROVIDERS?.map((shipment) => {
                return (
                  <MenuItem key={shipment} value={shipment}>
                    {shipment}
                  </MenuItem>
                );
              })}
            </TextField>
            {localState?.shipmentProvider === 'Other' && (
              <TextField
                id='other-shipment-provider'
                label='Other Shipment Provider'
                variant='outlined'
                fullWidth
                size='small'
                margin='dense'
                onChange={(e) =>
                  updateLocalState({
                    otherShipmentProvider: e.target.value,
                    errors: {},
                  })
                }
                error={!!localState?.errors?.otherShipmentProvider}
                helperText={localState?.errors?.otherShipmentProvider}
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) =>
                    updateLocalState({
                      isShipmentNumberNotAvailable: event.target.checked,
                    })
                  }
                  checked={localState?.isShipmentNumberNotAvailable}
                />
              }
              label={'Shipment number not available'}
            />
            {!localState?.isShipmentNumberNotAvailable && (
              <TextField
                id='shipment-tracking-number'
                label='Shipment Tracking Number'
                variant='outlined'
                fullWidth
                size='small'
                margin='dense'
                onChange={(e) =>
                  updateLocalState({
                    shipmentTrackingNumber: e.target.value,
                    errors: {},
                  })
                }
                error={!!localState?.errors?.shipmentTrackingNumber}
                helperText={localState?.errors?.shipmentTrackingNumber}
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Dialog
      maxWidth='md'
      aria-labelledby='ship-items-popup'
      open={open}
      onClose={handleCancel}
      className={classes.dialog}
      fullWidth
    >
      <DialogTitle id='ship-items-popup'>
        <SubDesc content='SHIP ITEMS' />
      </DialogTitle>
      {isLoading ? (
        <CircularProgress style={{ padding: '1rem 2rem' }} />
      ) : (
        <DialogContent dividers>
          {renderShipmenInfo()}
          {renderItems()}
        </DialogContent>
      )}
      {renderActionButtons()}
    </Dialog>
  );
}

export default ShipItemPopup;
