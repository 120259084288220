import React from 'react';
import { FtrTooltip } from './ftr-components';
import { EXTRACT_DEFECT_DEFINITIONS_DISPLAY } from '@constants/dfmConstants';

function DfmDefinitionTooltip(props) {
  const { defectKey, children } = props;

  return (
    <FtrTooltip
      description={EXTRACT_DEFECT_DEFINITIONS_DISPLAY[defectKey] ?? ''}
      arrow
    >
      {children}
    </FtrTooltip>
  );
}

export default DfmDefinitionTooltip;
