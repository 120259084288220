import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';

import Title from '../Title';
import BlueButton from '../buttons/BlueButton';
import WhiteButton from '../buttons/WhiteButton';

import { isEmptyValue } from '../../utils/commonUtils';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 600,
    minWidth: 200,
    maxWidth: 800,
    borderRadius: 0,
  },
  space: {
    width: theme.spacing(2),
  },
}));

function AddItemsOrQuotesPopup(props) {
  const classes = useStyles();

  const { open, handleClose = () => {}, handleAdd = () => {} } = props;

  const [itemIDs, setItemIDs] = useState([]);
  const [quotationIDs, setQuotationIDs] = useState([]);
  const [projectIDs, setProjectIDs] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleAddButtonClick = () => {
    setLoading(true);
    const items = isEmptyValue(itemIDs)
      ? []
      : itemIDs?.split(',')?.map((itemID) => Number(itemID.trim()));
    const quotations = isEmptyValue(quotationIDs)
      ? []
      : quotationIDs
          ?.split(',')
          ?.map((quotationID) => Number(quotationID.trim()));
    const projects = isEmptyValue(projectIDs)
      ? []
      : projectIDs?.split(',')?.map((projectID) => Number(projectID.trim()));
    const body = {
      items,
      quotations,
      projects,
    };
    handleAdd(body)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  const renderBody = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <TextField
          variant='outlined'
          margin='dense'
          id='name'
          label='Project IDs'
          type='text'
          fullWidth
          placeholder='eg: 2001,2002'
          onChange={(evt) => {
            setProjectIDs(evt.target.value);
          }}
          value={projectIDs}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          variant='outlined'
          margin='dense'
          id='name'
          label='Item IDs'
          type='text'
          fullWidth
          placeholder='eg: 2014,2015'
          onChange={(evt) => {
            setItemIDs(evt.target.value);
          }}
          value={itemIDs}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          variant='outlined'
          margin='dense'
          id='name'
          label='Quotation IDs'
          type='text'
          fullWidth
          placeholder='eg: 3009,3010'
          onChange={(evt) => {
            setQuotationIDs(evt.target.value);
          }}
          value={quotationIDs}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <Dialog
        maxWidth='xl'
        open={open}
        onClose={handleClose}
        aria-labelledby='confirmation-dialog-title'
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id='confirmation-dialog-title'>
          <Title contentTitle='Add Projects, Items or Quotes' size='small' />
        </DialogTitle>
        <div
          style={{
            paddingBottom: '1rem',
          }}
        >
          <DialogContent
            style={{
              padding: '0 30px',
              paddingBottom: '2rem',
              textAlign: 'center',
            }}
          >
            <React.Fragment>{renderBody()}</React.Fragment>
          </DialogContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              paddingRight: '1rem',
              gap: '1rem',
            }}
          >
            <WhiteButton
              onBtnClick={handleClose}
              btnContent='Cancel'
              disabled={loading}
            />
            <BlueButton
              onBtnClick={handleAddButtonClick}
              btnContent='Add'
              disabled={
                loading ||
                (isEmptyValue(itemIDs) &&
                  isEmptyValue(quotationIDs) &&
                  isEmptyValue(projectIDs))
              }
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default AddItemsOrQuotesPopup;
