import React, { useEffect, useReducer } from 'react';

import { useSelector } from 'react-redux';

import FtrSnackbar from '../components/ftr-components/FtrSnackbar';

import { isEmptyValue } from '../utils/commonUtils';
import { sendErrorToDiscord } from '../utils/errorReportingUtils';

import { getUserIDSelector } from '../selectors/userSelector';

import useSnackbarStore from '../zustandStores/useSnackbarStore';

import useLoginUserInfo from '../hooks/useLoginUserInfoHook';

import { SEVERITY } from '../constants/errorMessageConstants';

// ---------------------------------------------------------------------------

function withSnackbarHOC(WrappedComponent) {
  return function WrappedComponentWithLoadingBackDropTextHOC(props) {
    const userID = useSelector(getUserIDSelector);

    useLoginUserInfo(userID);

    const defaultLocalState = {
      open: false,
      severity: SEVERITY.INFO,
      sendToDiscord: true,
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    const { snackbarStore } = useSnackbarStore((state) => state);

    useEffect(() => {
      if (
        !isEmptyValue(snackbarStore.message) &&
        snackbarStore.message !== localState.message
      ) {
        const newLocalState = {
          ...defaultLocalState,
          open: true,
          message: snackbarStore.message,
          ...(snackbarStore.additional || {}),
        };
        updateLocalState(newLocalState);
      }
    }, [snackbarStore.message]);

    useEffect(() => {
      if (
        localState.open &&
        !isEmptyValue(localState.message) &&
        localState.severity === SEVERITY.ERROR &&
        localState.sendToDiscord
      ) {
        sendErrorToDiscord({
          message: localState.message,
          additional: { ...localState },
        });
      }
    }, [
      localState.open,
      localState.message,
      localState.severity,
      localState.sendToDiscord,
    ]);

    return (
      <>
        <WrappedComponent
          setSnackbarMessage={(message, additional = {}) => {
            const newLocalState = {
              ...defaultLocalState,
              open: true,
              message,
              ...additional,
            };
            updateLocalState(newLocalState);
          }}
          {...props}
        />
        <FtrSnackbar
          {...localState}
          {...props}
          handleClose={() => updateLocalState({ open: false })}
        />
      </>
    );
  };
}

export default withSnackbarHOC;
