import React, { useReducer } from 'react';

import { Grid } from '@material-ui/core';
import { Add as AddIcon, FastForward as MagicIcon } from '@material-ui/icons';

import { useModifyPdfCanvasContext } from '../../../context/ModifyPdfCanvasContext';

import {
  getMaterialWithColorText,
  getSurfaceFinishWithCustomizationsText,
} from '../../../utils/itemUtils';
import { isEmptyValue } from '../../../utils/commonUtils';

import { PROJECTION_ANGLES } from '../../../constants/projectionAngleConstants';

import { FlexRow } from '../../layouts/FlexLayouts';
import FtrButton from '../../ftr-components/FtrButton';

import TitleplateFormV2 from './TitleplateFormV2';

const TitleplateDisplayV2 = (props) => {
  const { titleplatePayload, revision, setRevision, canvasData } = props;
  const { addTitleplate, addPreviousCanvas } = useModifyPdfCanvasContext();
  const {
    finish: surfaceFinish,
    otherSurfaceFinish,
    color,
    anodizingType,
    material,
    technology,
    otherMaterial,
    materialColor,
  } = titleplatePayload;

  const colorSurfaceFinish = isEmptyValue(surfaceFinish)
    ? 'NONE'
    : getSurfaceFinishWithCustomizationsText({
        surfaceFinish,
        otherSurfaceFinish,
        color,
        material,
        technology,
        anodizingType,
      }).toUpperCase();

  const displayMaterial = getMaterialWithColorText({
    material,
    otherMaterial,
    materialColor,
  });

  const defaultTitleplateState = {
    ...titleplatePayload,
    rev: revision || 1,
    colorSurfaceFinish,
    material: displayMaterial,
    projectionAngle: PROJECTION_ANGLES.THIRD_ANGLE,
    toleranceStandard:
      titleplatePayload.toleranceStandard ||
      '3.2 ISO 2768/1 TOLERANCE CLASS "MEDIUM"',
  };

  const [titleplateState, updateTitleplateState] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    defaultTitleplateState
  );

  const handleChange = (field) => (event) => {
    updateTitleplateState({
      [field]: event.target.value,
    });

    if (field === 'rev') {
      setRevision(event.target.value);
    }
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <FlexRow style={{ marginBottom: '1rem' }}>
          {canvasData && (
            <FtrButton
              color='blue'
              startIcon={<MagicIcon />}
              onClick={() =>
                addPreviousCanvas({
                  canvasData,
                  titleplateContent: titleplateState,
                })
              }
              fullWidth
            >
              Add previous Titleplate
            </FtrButton>
          )}
          <FtrButton
            color='blue'
            startIcon={<AddIcon />}
            onClick={() =>
              addTitleplate({
                orientation: 'landscape',
                content: titleplateState,
              })
            }
            fullWidth
            variant='outlined'
          >
            Add Landscape Titleplate
          </FtrButton>
          <FtrButton
            color='blue'
            startIcon={<AddIcon />}
            onClick={() =>
              addTitleplate({
                orientation: 'portrait',
                content: titleplateState,
              })
            }
            fullWidth
            variant='outlined'
          >
            Add Portrait Titleplate
          </FtrButton>
        </FlexRow>
      </Grid>
      <TitleplateFormV2
        handleChange={handleChange}
        titleplateState={titleplateState}
        updateTitleplateState={updateTitleplateState}
      />
    </Grid>
  );
};

export default TitleplateDisplayV2;
