import React, { useReducer } from 'react';

import { useQueryClient } from 'react-query';

import AddEditRegionBasedMarkupPopup from '../pages/control-panels/AddEditRegionBasedMarkupPopup';

import { upsertRegionBasedMarkupApi } from '../apis/regionBasedMarkupApi';

import { isEmptyValue } from '../utils/commonUtils';

import { notifyError, notifySuccess } from '../services/notificationService';

// -------------------------------------------------------------------------------------------------

const defaultLocalState = {
  open: false,
  id: null,
};

const withAddEditRegionBasedMarkupPopupHOC = (WrappedComponent) => {
  function WrappedComponentWithHOC(props) {
    const queryClient = useQueryClient();

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...defaultLocalState, ...next };
    }, defaultLocalState);

    const handleSave = async (values) => {
      return upsertRegionBasedMarkupApi(values)
        .then(() => {
          notifySuccess('Region-based markup saved successfully');
          queryClient.invalidateQueries('allRegionBasedMarkups');
        })
        .catch((err) => {
          notifyError(err.message, {
            additional: {
              err: err,
              values,
            },
          });
          throw err;
        });
    };

    return (
      <>
        <WrappedComponent
          updateAddEditRegionBasedMarkupPopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <AddEditRegionBasedMarkupPopup
            open={localState.open}
            handleClose={() => updateLocalState({ open: false })}
            onSave={handleSave}
            id={localState.id}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
};

export default withAddEditRegionBasedMarkupPopupHOC;
