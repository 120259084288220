import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

import BootstrapInput from './BootstrapInput';

import { colors } from '../../palette';
import BootstrapInputSmall from './BootstrapInputSmall';
import inputsStyles from './inputsStyles';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: 16,
    marginBottom: 7,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
    // width: '30%',
    width: 150,
    minWidth: 150,
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    width: '100%',
  },
}));

function TechnologyInputField(props) {
  const classes = useStyles();
  const inputStyles = inputsStyles();

  // Defining input styles
  classes.emptyField = inputStyles.emptyField;
  classes.v2 = inputStyles.v2;

  const {
    value,
    onChange,
    technologyOptions = [],
    rowDisplay = false,
    bootstrapStyle = true,
    id,
    disableLabelOnBootstrapStyle = false,
    v2 = false,
  } = props;

  const handleChange = (evt) => {
    const newTech = evt.target.value;
    onChange(newTech);
  };

  if (bootstrapStyle) {
    return (
      <div
        data-cy={id}
        className={rowDisplay ? classes.rowDisplay : classes.container}
      >
        {!disableLabelOnBootstrapStyle && (
          <InputLabel
            className={
              rowDisplay ? classes.inputLabelRowDisplay : classes.inputLabel
            }
          >
            Technology
          </InputLabel>
        )}
        <Select
          id='technology'
          className={`${classes.selectField} ${v2 ? classes.v2 : ''} ${
            !value ? classes.emptyField : ''
          }`}
          input={rowDisplay ? <BootstrapInputSmall /> : <BootstrapInput />}
          value={value ?? null}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => selected ?? 'Technology'}
        >
          {technologyOptions?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  return (
    <TextField
      select
      label='Technology'
      variant='outlined'
      onChange={handleChange}
      value={value}
      margin='dense'
      InputLabelProps={{
        shrink: true,
      }}
    >
      {technologyOptions?.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default TechnologyInputField;
