import queryString from 'query-string';

import {
  fetchWithErrorHandling,
  getAuthorizedHeader,
  witchCacheResponse,
} from '../utils/apiUtils';
import { sendErrorToDiscord } from '../utils/errorReportingUtils';

import { BACKEND_SERVICE_URL } from '../constants';

// -------------------------------------------------------------------------------------------------

export const uploadFileToS3Api = async (
  file,
  s3Key,
  customFileName = null,
  isPublic = false
) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/s3/upload`;
  const body = new FormData();
  body.append('file', file);
  body.append('s3Key', encodeURIComponent(s3Key));
  if (customFileName) {
    body.append('customFileName', customFileName);
  }

  if (isPublic) {
    body.append('isPublic', isPublic);
  }

  const headers = getAuthorizedHeader();
  delete headers['Content-Type'];
  const data = await fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers,
    body,
  });

  const s3ObjectUrl = data;

  return {
    Location: data,
    s3ObjectUrl,
  };
};

export const deleteObject = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/s3/file`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(params),
  });
};

export const generatePresignedUrlApi = async (
  s3ObjUrl,
  expirationTime,
  inlineView = false
) => {
  const params = {
    s3ObjUrl,
    expirationTime,
    inlineView,
  };

  if (typeof s3ObjUrl !== 'string') {
    sendErrorToDiscord({
      message: 's3ObjUrl is not a string',
      additional: {
        s3ObjUrl,
      },
    });
  }

  const requestUrl = `${BACKEND_SERVICE_URL}/s3/generate-presigned-url?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const generatePresignedUrlForImage = witchCacheResponse(
  generatePresignedUrlApi,
  1 * 60 * 60 // 1 hour in seconds
);

export const downloadWatermarkedCadFileAPI = async (s3ObjUrl) => {
  const params = {
    s3ObjUrl,
  };
  const requestUrl = `${BACKEND_SERVICE_URL}/s3/download-cad-file-watermark?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  }).then(async (response) => {
    if (!response.ok) {
      return response.json().then((err) => {
        throw new Error(err.detail);
      });
    }
    return response.blob();
  });
};
