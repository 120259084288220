import { ceil, get } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Badge,
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
} from '@material-ui/core';

import { Edit as EditIcon, FileCopy, Info, Publish } from '@material-ui/icons';

import { DataGrid } from '@mui/x-data-grid';

import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import AdminQuoteDetailsDisplayPopup from '../../components/popups/AdminQuoteDetailsDisplayPopup';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';

import { displayEditOrderPage } from '../../actions';

import {
  materialWithColorCol,
  surfaceFinishingWithColorCol,
  threeDInfillCol,
  threeDLayerThicknessCol,
  threeDTechnologyCol,
} from '../../constants/itemTableConstants';
import {
  dateOfConfirmationCol,
  markupPercentCol,
  originalQuoteColumn,
  platformFeeCol,
  poAcknowledgeColumn,
  purchaseOrderAdminCol,
  quoteValidTillCol,
  repeatOrderInfoCol,
  rocketQuoteInfoCol,
  supplierRemarksCol,
  totalPriceShownToCustomerCol,
} from '../../constants/quotationTableConstants';

import {
  adminExportQuotationsAsCsv,
  adminGetAllQuotations,
  updateReasonQuotations,
} from '../../apis/quotationApi';
import RejectedReasonPopup from '../../components/popups/RejectedReasonPopup';

import { generateCurrentCustomDateTimeString } from '../../util';
import { convertPriceToCurrency } from '../../utils/currencyUtils';
import { downloadS3File } from '../../utils/fileUtils';
import {
  getBackgroundColorByQuoteStatus,
  reasonsTextUtil,
} from '../../utils/quotationUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';

import { notifySuccess } from '../../services/notificationService';

import { DEFAULT_FACTOREM_GST, ORDER_STATUS } from '../../constants';
import { CURRENCY_CODE } from '../../constants/currencyConstants';
import { DATE_FORMAT, dateTzSingapore } from '../../utils/dateTimeUtils';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  cardDisplay: {
    minWidth: 275,
    variant: 'elevated',
  },
  header: {
    fontSize: 14,
    paddingBottom: theme.spacing(1),
  },
  body: {
    paddingTop: '1rem',
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    marginBottom: '2rem',
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      margin: '0 auto',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'center',
      justifyContent: 'center !important',
    },
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      transform: 'scale(0.8) translate(75%, 20%)',
    },
  },
  formControl: {
    marginTop: '10px',
    width: '150px',
    '& .MuiPopover-paper': {
      width: '330px !important',
    },
    '& .MuiSelect-selectMenu': {
      whiteSpace: 'normal',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      fontSize: '12px',
      fontWeight: '600',
    },
  },
  buttonReasonRejected: {
    display: 'flex',
    margin: '0 auto',
    alignItems: 'center',
    width: 'fit-content',
    color: 'black',
    padding: '0.4rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#00000011',
    },
    backgroundColor: '#00000008',
    borderRadius: 4,
    textTransform: 'uppercase',
    fontSize: '9px',
    lineHeight: '13px',
    whiteSpace: 'nowrap',
  },
  formRejected: {
    '& .MuiTypography-body1': {
      fontSize: '13px',
    },
    '& .PrivateSwitchBase-root-40': {
      padding: '5px',
    },
  },
  listReasonRejected: {
    fontSize: '11px',
    fontWeight: '500',
    lineHeight: 'normal',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
  otherInput: {
    display: 'flex',
    marginLeft: '35px',
  },
  buttonSubmitReasonRejected: {
    '& .makeStyles-submitBtn-44': {
      fontSize: '13px',
      padding: '10px 15px',
    },
  },
  uploadIcon: {
    color: 'gray',
    marginRight: '5px',
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
    fontSize: '16px',
  },
  tooltipBadge: {
    top: -10,
  },
}));

export const DOWNLOAD_PO_LINK = 'download-po-link';

export function AllQuotesPagination(props) {
  const classes = useStyles();

  const { openEditOrder, role } = props;

  const [showRejectedReasonPopup, setShowRejectedReasonPopup] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [openQuoteDetails, setOpenQuoteDetails] = useState(false);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
      status: ORDER_STATUS.REJECTED,
      rejectedReason: false,
    }
  );

  const getTableData = () => {
    updateTableQueryParams({ loading: true });
    adminGetAllQuotations(tableQueryParams)
      .then((data) => {
        setTableData(data.rows);
        updateTableQueryParams({
          totalCount: data.totalCount,
        });
      })
      .finally(() => updateTableQueryParams({ loading: false }));
  };

  let timer;
  useEffect(() => {
    if (!tableQueryParams.search) {
      getTableData();
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        getTableData();
      }, 300);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [
    tableQueryParams.search,
    tableQueryParams.page,
    tableQueryParams.pageSize,
    tableQueryParams.rejectedReason,
  ]);

  const renderCellYes = () => {
    return <span style={{ color: 'green' }}>Yes</span>;
  };

  const renderCellNo = () => {
    return <span style={{ color: 'orange' }}>No</span>;
  };

  const renderCellPpeQuoteBadgeTooltip = (numOfPpeQuotes) => {
    const supplierText = numOfPpeQuotes > 1 ? 'Suppliers' : 'Supplier';
    return (
      <div
        style={{
          lineHeight: 'normal',
        }}
      >
        <Tooltip
          title={`${numOfPpeQuotes} ${supplierText} quoted`}
          arrow
          placement='top'
        >
          <Badge
            badgeContent={numOfPpeQuotes}
            color='error'
            classes={{
              badge: classes.tooltipBadge,
            }}
          >
            {renderCellYes()}
          </Badge>
        </Tooltip>
      </div>
    );
  };

  const handlePORevisedUploadCallback = async () => {
    getTableData();
  };

  const handleRejectedReason = async ({ rejectedReason, quotationIDs }) => {
    updateReasonQuotations({ rejectedReason, quotationIDs }).then(() => {
      getTableData();
      notifySuccess('Reason rejected has been updated successfully');
      setShowRejectedReasonPopup(false);
    });
  };

  const handleEnterReason = (rowData) => {
    setSelectedQuotation(rowData);
    setShowRejectedReasonPopup(true);
  };

  const handleDownloadCsv = async () => {
    // Call the API to generate the CSV and get the download URL
    const response = await adminExportQuotationsAsCsv(tableQueryParams);
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);

    // Download the CSV file using the download URL
    const link = document.createElement('a');
    link.href = downloadUrl;
    const fileName = `All Quotes ${generateCurrentCustomDateTimeString()}.csv`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderEditButton = (rowData) => {
    if (
      (rowData.status === 'disabled' || rowData.status === 'rejected') &&
      role === 'reviewer'
    ) {
      return (
        <IconButton
          aria-label='edit'
          onClick={() => openEditOrder(rowData.quotationID)}
          disabled
        >
          <EditIcon />
        </IconButton>
      );
    } else {
      return (
        <Tooltip title='Hold Ctrl/Cmd and click to edit in new tab'>
          <IconButton
            aria-label='edit'
            onClick={(e) => {
              e.stopPropagation();
              if (e.ctrlKey || e.metaKey) {
                window.open(`/order/edit/${rowData.quotationID}`);
                return;
              }
              openEditOrder(rowData.quotationID);
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      );
    }
  };

  const columns = [
    {
      headerName: ' ',
      renderCell: ({ row: rowData }) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title='View quote details'>
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={() => {
                  setOpenQuoteDetails(true);
                  setSelectedQuotation(rowData);
                }}
              >
                <Info color='primary' style={{ fontSize: '15pt' }} />
              </IconButton>
            </Tooltip>
            {renderEditButton(rowData)}
          </div>
        );
      },
      field: 'actions',
    },
    {
      headerName: 'Quote ID',
      field: 'quotationID',
    },
    {
      headerName: 'Project ID',
      field: 'projectID',
      renderCell: ({ row: rowData }) => {
        return (
          <Button
            className={classes.linkButton}
            href={`/manage-projects/${rowData.projectID}`}
          >
            {rowData.projectID}
          </Button>
        );
      },
    },
    {
      headerName: 'Part ID',
      field: 'itemID',
      renderCell: ({ row: rowData }) => {
        return (
          <Button
            className={classes.linkButton}
            href={`/item/edit/${rowData.itemID}`}
          >
            {rowData.itemID}
          </Button>
        );
      },
    },
    { headerName: 'Name', field: 'name' },
    {
      headerName: 'Customer',
      field: 'buyerName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={`${rowData.buyerName} (${rowData.buyerID})`}
        />
      ),
      width: 150,
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: ({ row: rowData }) => {
        const bgColor = getBackgroundColorByQuoteStatus(rowData.status);
        const reasonRejectedWithSymbol =
          rowData.reasonRejected &&
          !!rowData.reasonRejected.length &&
          reasonsTextUtil(rowData.reasonRejected);
        const conditionalEnterRejectedReason =
          rowData.numOfPpeQuotes > 1 ||
          rowData.status === ORDER_STATUS.REJECTED;
        return (
          <div>
            <div
              style={{
                backgroundColor: bgColor,
                display: 'flex',
                width: '150px',
                justifyContent: 'center',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                lineHeight: 'normal',
              }}
            >
              {rowData.status}
            </div>
            {rowData.status === ORDER_STATUS.ACCEPTED && (
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '0.75rem',
                  whiteSpace: 'nowrap',
                  lineHeight: 'normal',
                }}
              >
                {rowData.dateOfConfirmation
                  ? dateTzSingapore(rowData.dateOfConfirmation)
                  : ''}
              </div>
            )}
            {conditionalEnterRejectedReason && (
              <Button
                className={classes.buttonReasonRejected}
                onClick={(e) => {
                  handleEnterReason(rowData);
                  e.stopPropagation();
                }}
              >
                <Publish className={classes.uploadIcon} />
                Enter Reason
              </Button>
            )}
            {reasonRejectedWithSymbol && (
              <div className={classes.listReasonRejected}>
                {reasonRejectedWithSymbol}
              </div>
            )}
          </div>
        );
      },
      width: 150,
    },
    {
      headerName: 'Date of Quote',
      field: 'dateOfOrder',
      renderCell: ({ row: rowData }) =>
        rowData.dateOfOrder ? rowData.dateOfOrder.substring(0, 10) : '',
      minWidth: 150,
    },
    {
      headerName: 'Expiry date',
      field: 'dateOfExpiry',
      renderCell: ({ row: rowData }) =>
        rowData.dateOfExpiry
          ? dateTzSingapore(rowData.dateOfExpiry, DATE_FORMAT)
          : '',
      minWidth: 150,
    },
    { headerName: 'Quantity', field: 'quantity' },
    { headerName: 'Technology', field: 'technology', width: 150 },
    {
      ...materialWithColorCol,
    },
    {
      ...surfaceFinishingWithColorCol,
    },
    {
      ...originalQuoteColumn,
    },
    {
      headerName: 'Price from supplier (excl. GST) in SGD',
      field: 'priceFromSupplierExclGstInSGD',
      renderCell: ({ row: rowData }) => 'S$' + rowData.priceBidded,
      minWidth: 200,
    },
    {
      headerName: 'GST',
      field: 'gst',
      renderCell: ({ row: rowData }) =>
        'S$' + (rowData.priceBidded * (rowData.gst / 100)).toFixed(2),
    },
    {
      headerName: 'Delivery Fees',
      field: 'deliveryFees',
      renderCell: ({ row: rowData }) => 'S$' + rowData.deliveryFee,
    },
    {
      headerName: 'CMM Price',
      field: 'cmmPrice',
      renderCell: ({ row: rowData }) => {
        return convertPriceToCurrency({
          price: get(rowData, 'metadata.cmmPrice', 0),
          currency: CURRENCY_CODE.SGD,
          exchangeRate: 1,
        });
      },
      minWidth: 120,
    },
    {
      headerName: 'CMM Price for Customer',
      field: 'cmmPriceForCustomer',
      renderCell: ({ row: rowData }) => {
        return convertPriceToCurrency({
          price:
            get(rowData, 'metadata.cmmPriceCustomer') ||
            get(rowData, 'metadata.cmmPrice') ||
            0,
          currency: CURRENCY_CODE.SGD,
          exchangeRate: 1,
        });
      },
      width: 150,
    },
    {
      ...totalPriceShownToCustomerCol,
    },
    {
      ...platformFeeCol,
    },
    {
      headerName: 'Total Price (incl. GST)',
      field: 'totalPriceIncGst',
      exportData: ({ row: rowData }) => {
        return rowData.totalPrice
          ? convertPriceToCurrency(
              {
                price: rowData.totalPrice * (1 + DEFAULT_FACTOREM_GST),
                currency: CURRENCY_CODE.SGD,
                exchangeRate: 1,
              },
              ''
            )
          : '';
      },
      renderCell: function ({ row: rowData }) {
        return rowData.totalPrice ? (
          <div>
            {convertPriceToCurrency(
              {
                price: rowData.totalPrice * (1 + DEFAULT_FACTOREM_GST),
                currency: CURRENCY_CODE.SGD,
                exchangeRate: 1,
              },
              ''
            )}
          </div>
        ) : (
          <div></div>
        );
      },
      width: 130,
    },
    {
      headerName: 'Lead time (original + markup)',
      field: 'leadTimeOriginalMarkup',
      renderCell: ({ row: rowData }) =>
        `${rowData.leadTime} + ${rowData.markupLeadTime} working days`,
      minWidth: 150,
    },
    {
      headerName: 'Supplier name',
      field: 'supplierName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell
          text={`${rowData.supplierName} (${rowData.userID})`}
        />
      ),
      minWidth: 150,
    },
    {
      headerName: 'Rejected Reason',
      field: 'reasonRejected',
      exportData: ({ row: rowData }) => {
        return rowData.reasonRejected && !!rowData.reasonRejected.length
          ? reasonsTextUtil(rowData.reasonRejected)
          : '';
      },
      hide: true,
      export: true,
    },
    {
      ...dateOfConfirmationCol,
    },
    {
      headerName: 'Verification Date',
      field: 'verifiedDate',
      renderCell: ({ row: rowData }) =>
        rowData.verifiedDate ? rowData.verifiedDate.substring(0, 10) : '',
      width: 120,
    },
    {
      headerName: 'Customer remarks',
      field: 'customerRemarks',
      exportData: ({ row: rowData }) => rowData.customerRemarks || 'N.A.',
      renderCell: ({ row: rowData }) => rowData.customerRemarks || 'N.A.',
      maxWidth: 280,
      width: 130,
    },
    {
      headerName: 'Quotation Form',
      field: 'quotationForm',
      exportData: ({ row: rowData }) => rowData.quotationForm || 'N.A.',
      renderCell: ({ row: rowData }) =>
        rowData.quotationForm ? (
          <div
            style={{
              display: 'flex',
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              lineHeight: 'normal',
            }}
          >
            <Link
              onClick={(e) => {
                e.stopPropagation();
                downloadS3File(rowData.quotationForm);
              }}
            >
              Download Quotation
            </Link>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(rowData.quotationForm);
              }}
            >
              <FileCopy />
            </IconButton>
          </div>
        ) : (
          <div>N.A.</div>
        ),
      width: 135,
    },
    {
      ...purchaseOrderAdminCol({
        uploadCallback: handlePORevisedUploadCallback,
      }),
    },
    {
      headerName: 'PPE Quote',
      field: 'ppeQuote',
      exportData: ({ row: rowData }) => {
        const isPpeQuote =
          rowData.rfqQuote == null ? !!rowData.price : !rowData.rfqQuote;
        if (!isPpeQuote) {
          return 'No';
        }
        return 'Yes';
      },
      renderCell: ({ row: rowData }) => {
        const isPpeQuote =
          rowData.rfqQuote == null ? !!rowData.price : !rowData.rfqQuote;
        const isRocketQuote = rowData.rocketQuote;
        if (!isPpeQuote) {
          return renderCellNo();
        }
        if (!isRocketQuote) {
          const { numOfPpeQuotes } = rowData;
          return numOfPpeQuotes
            ? renderCellPpeQuoteBadgeTooltip(numOfPpeQuotes)
            : renderCellYes();
        }
        return renderCellYes();
      },
      textAlign: 'center',
    },
    {
      ...rocketQuoteInfoCol,
    },
    {
      headerName: 'Admin Creator Email',
      field: 'adminCreatorEmail',
      exportData: ({ row: rowData }) => {
        return rowData.adminEmail || 'N.A.';
      },
      hide: true,
      export: true,
    },
    {
      headerName: 'Admin Creator ID',
      field: 'adminCreatorID',
      exportData: ({ row: rowData }) => {
        return rowData.adminID || 'N.A.';
      },
      hide: true,
      export: true,
    },
    {
      ...quoteValidTillCol,
    },
    {
      ...supplierRemarksCol,
    },
    {
      ...markupPercentCol,
    },
    {
      ...threeDTechnologyCol,
    },
    {
      ...threeDInfillCol,
    },
    {
      ...threeDLayerThicknessCol,
    },
    {
      ...poAcknowledgeColumn,
    },
    {
      ...repeatOrderInfoCol,
    },
  ];

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const getCustomerToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <FormControlLabel
          control={
            <Switch
              checked={tableQueryParams.rejectedReason}
              onChange={(event) =>
                updateTableQueryParams({ rejectedReason: event.target.checked })
              }
              color='primary'
            />
          }
          label='Rejected Reason'
        />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return role === 'buyer' || role === 'supplier' ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: props.location },
      }}
    />
  ) : (
    <div className={classes.body}>
      <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
          autoHeight
          paginationMode='server'
          rows={tableData}
          columns={columns?.map((col) => ({
            ...col,
            sortable: false,
          }))}
          getRowId={(row) => row.quotationID}
          rowHeight={100}
          headerHeight={80}
          components={{
            Toolbar: getCustomerToolbar,
            Pagination: () => (
              <GridDataPagination
                pageCount={ceil(
                  tableQueryParams.totalCount / tableQueryParams.pageSize
                )}
              />
            ),
          }}
          pageSize={tableQueryParams.pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          onPageSizeChange={(newPageSize) =>
            updateTableQueryParams({ pageSize: newPageSize })
          }
          page={tableQueryParams.page}
          onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
          rowCount={tableQueryParams.totalCount}
          loading={tableQueryParams.loading}
          disableRowSelectionOnClick
          disableSelectionOnClick
          disableColumnMenu
        />
      </div>
      {showRejectedReasonPopup && (
        <RejectedReasonPopup
          dialog={showRejectedReasonPopup}
          handleClose={() => setShowRejectedReasonPopup(false)}
          onOk={handleRejectedReason}
          rowData={selectedQuotation}
        />
      )}
      {openQuoteDetails && (
        <AdminQuoteDetailsDisplayPopup
          open={openQuoteDetails}
          rowData={selectedQuotation}
          onClose={() => setOpenQuoteDetails(false)}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

function matchDispatchToProps(dispatch, props) {
  return {
    openEditOrder: (id) => dispatch(displayEditOrderPage(id, props)),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withRouter(withConnect(AllQuotesPagination));
