import AutomationFailuresPopup from '../components/popups/AutomationFailuresPopup';

import withPopupHOC from './withPopupHOC';

// -------------------------------------------------------------------------------------------------

export const withAutomationFailuresHOC = (WrappedComponent) =>
  withPopupHOC({
    WrappedComponent,
    ModalComponent: AutomationFailuresPopup,
    defaultLocalState: {
      open: false,
      rfqFailures: [],
    },
    statePropName: 'automationFailuresPopupState',
    updaterPropName: 'updateAutomationFailuresPopupState',
  });
