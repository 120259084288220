import { get, set } from 'lodash';

import { isEmptyValue } from './commonUtils';

// ------------------------------------------------------------------------------------------------

/**
 * Removes properties with empty values from the given object.
 * An empty value is defined by the `isEmptyValue` utility function.
 *
 * @param {Object} obj - The object to process.
 * @return {Object} - A new object with only the non-empty properties.
 */
export const removeEmptyProperties = (obj) => {
  let newObj = {};
  for (const key in obj) {
    if (!isEmptyValue(obj[key])) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

/**
 * Copies the specified fields from the given object to a new object.
 *
 * @param {Object} obj - The object to copy from.
 * @param {Array<string>} [fields=[]] - The fields to copy. If fields is empty then clone the whole object.
 * @return {Object} - The new object with the copied fields.
 */
export const copyObjectByFields = (obj, fields = []) => {
  if (isEmptyValue(obj)) {
    return {};
  }

  if (isEmptyValue(fields)) {
    return JSON.parse(JSON.stringify(obj));
  }

  return fields?.reduce((acc, field) => {
    const value = get(obj, field);
    set(acc, field, value);
    return acc;
  }, {});
};

/**
 * Returns the first non-empty value from the given object.
 * If all values are empty or the object is empty, return false.
 *
 * @param {Object} obj - The object to check.
 * @return {*} - The first non-empty value or false if all values are empty.
 */
export const getNotEmptyValueFromObject = (obj) => {
  if (isEmptyValue(obj)) {
    return null;
  }
  for (const key in obj) {
    if (!isEmptyValue(obj[key])) {
      return obj[key];
    }
  }
  return null;
};

/**
 * Finds the key in the object that has the given value.
 * If the object is empty or no key has the given value, return null.
 *
 * @param {Object} obj - The object to search in.
 * @param {*} value - The value to search for.
 * @return {string|null} - The key with the given value, or null.
 */
export const findKeyObjectByValue = (obj, value) => {
  if (isEmptyValue(obj)) {
    return null;
  }
  return Object.keys(obj)?.find((key) => obj[key] === value);
};

/**
 * Reverses the keys and values of a given object.
 *
 * @param {Object} obj - The object to reverse.
 * @return {Object} - A new object with keys and values swapped.
 */
export const reverseMapObject = (obj) => {
  if (isEmptyValue(obj)) {
    return {};
  }
  return Object.entries(obj)?.reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});
};

export const groupByObjectProperty = (objectList, property) => {
  if (isEmptyValue(objectList)) {
    return {};
  }
  return objectList?.reduce((acc, item) => {
    const keyValue = item[property];
    if (!acc[keyValue]) {
      acc[keyValue] = [];
    }
    acc[keyValue].push(item);
    return acc;
  }, {});
};

export const isObjectIncludes = (sourceObj, targetObj = {}) => {
  if (isEmptyValue(targetObj)) {
    return true;
  }

  return Object.keys(targetObj)?.every((key) => {
    return sourceObj[key] === targetObj[key];
  });
};

/**
 * Merge one or more objects into one. If there are duplicate keys, the keys
 * in the later objects will override the keys in the earlier objects.
 *
 * @param {...Object} objects - The objects to merge.
 * @return {Object} The merged object.
 */
export const mergeObjects = (...objects) => {
  if (!objects.length) {
    return null;
  }

  const merged = {};
  objects.forEach((obj) => {
    if (obj == null) {
      return;
    }
    Object.entries(obj).forEach(([key, value]) => {
      if (value && typeof value === 'object') {
        merged[key] = mergeObjects(merged[key], value);
      } else {
        merged[key] = value;
      }
    });
  });

  return merged;
};
