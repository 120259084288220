import React from 'react';

import { InputAdornment, TextField } from '@material-ui/core';

import { FlexColumn } from '../layouts/FlexLayouts';
import { FtrCaption } from '../ftr-components';

import {
  countDecimalsUtil,
  getDecimal,
  handleDigitDecimalUtil,
} from '../../utils/numberUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

function SupplierTotalPriceBiddedField(props) {
  const {
    value = 0,
    quantity = 1,
    showCaption = false,
    disabled = false,
    helperText,
    error,
    className = '',
    style = {},
    onChange = () => {},
    onFocus = () => {},
  } = props;

  return (
    <FlexColumn style={{ gap: 0 }}>
      <TextField
        className={className}
        disabled={disabled}
        label='Total Price Bidded'
        variant='outlined'
        type='number'
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        error={error}
        helperText={helperText}
        margin='dense'
        InputProps={{
          startAdornment: <InputAdornment position='start'>S$</InputAdornment>,
        }}
        fullWidth
        // disable change value when mouse scroll
        onWheel={(e) => e.target.blur()}
        style={{ ...style }}
      />
      {renderCaption()}
    </FlexColumn>
  );

  function renderCaption() {
    if (!showCaption) {
      return null;
    }

    if (isEmptyValue(value) || isEmptyValue(quantity)) {
      return;
    }

    let supplierUnitQuote =
      isNaN(getDecimal(value)) || isNaN(getDecimal(quantity))
        ? ''
        : getDecimal(value)
            .dividedBy(getDecimal(quantity) || 1)
            .toFixed();
    supplierUnitQuote = isEmptyValue(supplierUnitQuote)
      ? 'N.A.'
      : handleDigitDecimalUtil(supplierUnitQuote);
    const decimalCount = countDecimalsUtil(supplierUnitQuote);

    return (
      <FtrCaption>
        Unit quote for supplier:{' '}
        <span
          style={{
            color: decimalCount <= 2 ? colors.green050 : colors.errorRed,
          }}
        >
          S${supplierUnitQuote}
        </span>
      </FtrCaption>
    );
  }
}

export default SupplierTotalPriceBiddedField;
