import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQueries } from 'react-query';
import { Decimal } from 'decimal.js';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

import SubDesc from '../SubDesc';
import { FtrTypography, FtrCaption } from '../ftr-components';
import { FlexColumn } from '../layouts/FlexLayouts';
import ShipmentTrackingIcon from '../icons/ShipmentTrackingIcon';

import { formatTitleDate } from '../../utils/dateTimeUtils';

import { getPoNumber } from '../../utils/quotationUtils';
import {
  convertPriceToCurrencyBeautified,
  convertPriceWithQuantityToCurrency,
  extractCurrencyFromCurrencyString,
  extractPriceFromCurrencyString,
} from '../../utils/currencyUtils';
import { reciprocal } from '../../utils/numberUtils';

import { CURRENCY_CODE } from '../../constants/currencyConstants';
import {
  DEFAULT_FACTOREM_GST,
  DEFAULT_SUPPLIER_GST_PERCENT,
} from '../../constants';

import { notifyError } from '../../services/notificationService';

import { getProjectOrderReadyInfo } from '../../apis/orderReadyApi';

import { getExchangeRatesSelector } from '../../selectors/exchangeRatesSelector';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
  },
  space: {
    width: theme.spacing(2),
  },
  shipmentInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  shipmentRow: {
    display: 'flex',
    columnGap: '2px',
    justifyContent: 'flex-start',
  },
}));

function PorDisplayTable({ porDisplayInfo }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FtrTypography>PO Number</FtrTypography>
            </TableCell>
            <TableCell>
              <FtrTypography>PO Amount</FtrTypography>
            </TableCell>
            <TableCell>
              <FtrTypography>GST %</FtrTypography>
            </TableCell>
            <TableCell>
              <FtrTypography>GST Amount</FtrTypography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {porDisplayInfo?.map((por) => {
            return (
              <TableRow key={por.projectOrderReadyID}>
                <TableCell>
                  <FtrTypography>
                    PO&nbsp;
                    <Link
                      to={{
                        pathname: `/projects/${por.projectID}/order-ready?supplierID=${por.supplierID}&version=${por.poAcknowledgedVersion}`,
                      }}
                    >
                      {getPoNumber(
                        por.supplierID,
                        por.projectID,
                        por.poAcknowledgedVersion
                      )}
                    </Link>
                  </FtrTypography>
                </TableCell>

                <TableCell>
                  <FtrTypography>
                    {por.firstPoAmountString} {por.secondPoAmountString}
                  </FtrTypography>
                </TableCell>

                <TableCell>
                  <FtrTypography>{DEFAULT_SUPPLIER_GST_PERCENT}%</FtrTypography>
                </TableCell>

                <TableCell>
                  <FtrTypography>
                    {por.firstGstAmountString} {por.secondGstAmountString}
                  </FtrTypography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ShipmentInfoPopup(props) {
  const classes = useStyles();

  const { open, handleClose = () => {}, data = { porList: [] } } = props;

  const exchangeRates = useSelector(getExchangeRatesSelector);

  const firstPor =
    (data.porList && data.porList.length > 0 && data.porList[0]) || {};
  const { trackingNumber, shipmentDate, shipmentProvider } = firstPor;

  const porInfoList = useQueries(
    data.porList?.map(({ supplierID, projectID, poAcknowledgedVersion }) => ({
      queryKey: [
        'getProjectOrderReadyInfo',
        supplierID,
        projectID,
        poAcknowledgedVersion,
      ],
      queryFn: () =>
        getProjectOrderReadyInfo({
          projectID,
          supplierID,
          poAcknowledgedVersion,
        }),
    }))
  );

  useEffect(() => {
    const porInfoWithError = porInfoList?.find((porInfo) => porInfo.isError);
    if (porInfoWithError) {
      notifyError(porInfoWithError.error.message);
      handleClose();
    }
  }, [porInfoList, handleClose]);

  const renderShipmentInfo = () => {
    const shipmentDateDisplayString = shipmentDate
      ? formatTitleDate(shipmentDate)
      : 'N.A.';
    return (
      <div className={classes.shipmentInfo}>
        <div className={classes.shipmentRow}>
          <span>Shipment Date:</span>
          <strong>{shipmentDateDisplayString}</strong>
        </div>
        <div className={classes.shipmentRow}>
          <span>Shipment Provider:</span>
          <strong>{shipmentProvider || 'N.A.'}</strong>
        </div>
        <div
          style={{ display: 'inline-flex', alignItems: 'center' }}
          className={classes.shipmentRow}
        >
          <span>Shipment Tracking Number:</span>
          <strong>{trackingNumber || 'N.A.'}</strong>
          <ShipmentTrackingIcon
            shipmentInfo={firstPor}
            iconStyle={{ fontSize: '16px' }}
          />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    const porDisplayInfo = porInfoList?.map(({ data: porInfo }) => {
      const por = porInfo.projectOrderReady.info;
      const {
        projectOrderReadyID,
        projectID,
        supplierID,
        poAcknowledgedVersion,
      } = por;
      const iorList = porInfo.itemOrderReady;
      const { quantity, poAmount } = iorList[0].info;

      const currency =
        extractCurrencyFromCurrencyString(poAmount) || CURRENCY_CODE.SGD;
      const poPriceWithoutCurrency = extractPriceFromCurrencyString(poAmount);
      const exchangeRate = reciprocal(exchangeRates[currency]);

      const {
        totalPrice: sgdPriceWithoutCurrency,
        totalPriceStr: sgdPriceStr,
      } = convertPriceWithQuantityToCurrency({
        totalPrice: poPriceWithoutCurrency,
        currency: CURRENCY_CODE.SGD,
        exchangeRate,
        quantity,
      });

      const firstGstAmount = new Decimal(poPriceWithoutCurrency)
        .times(DEFAULT_FACTOREM_GST)
        .toNumber();
      const firstGstAmountString = convertPriceToCurrencyBeautified({
        price: firstGstAmount,
        currency,
      });

      const gstInSgdDecimal = new Decimal(sgdPriceWithoutCurrency).times(
        DEFAULT_FACTOREM_GST
      );

      const secondGstAmountString =
        currency === CURRENCY_CODE.SGD
          ? ''
          : `(${convertPriceToCurrencyBeautified({
              price: gstInSgdDecimal.toNumber(),
              currency: CURRENCY_CODE.SGD,
            })})`;
      return {
        gstInSgdDecimal,
        firstPoAmountString: poAmount,
        secondPoAmountString:
          currency === CURRENCY_CODE.SGD ? '' : `(${sgdPriceStr})`,
        firstGstAmountString,
        secondGstAmountString,
        projectOrderReadyID,
        projectID,
        supplierID,
        poAcknowledgedVersion,
      };
    });

    const totalAmount = porDisplayInfo?.reduce(
      (acc, current) => acc.plus(current.gstInSgdDecimal),
      new Decimal(0)
    );
    const totalAmountString = convertPriceToCurrencyBeautified({
      price: totalAmount,
      currency: CURRENCY_CODE.SGD,
    });

    const renderTotalAmount = () => {
      return (
        <>
          {`The total GST amount is ${totalAmountString}`}
          {totalAmount < 400 ? (
            <FtrCaption>
              Note that IRAS only imposes GST on goods of value $400 or less
              that are coming in by air or post. Please verify that these goods
              are coming in by air or post.
            </FtrCaption>
          ) : null}
        </>
      );
    };

    return (
      <FlexColumn>
        {renderShipmentInfo()}
        <PorDisplayTable porDisplayInfo={porDisplayInfo} />
        {renderTotalAmount()}
      </FlexColumn>
    );
  };

  if (porInfoList?.some((porInfo) => porInfo.isLoading)) {
    return <CircularProgress style={{ padding: '1rem 2rem' }} />;
  }

  return (
    <div>
      <Dialog
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby='confirmation-dialog-title'
        classes={{ paper: classes.paper }}
        fullWidth
      >
        <DialogTitle id='confirmation-dialog-title'>
          <SubDesc content={`Shipment Info`} />
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            padding: '1rem 2rem',
            textAlign: 'center',
          }}
        >
          {renderBody()}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ShipmentInfoPopup;
