export const RFQ_EMAIL_SCHEDULE_CONFIG_STATUS = {
  NEW: 'new',
  ON_GOING: 'on_going',
  DONE: 'done',
};

export const RFQ_EMAIL_SCHEDULE_STATUS = {
  SCHEDULED: 'scheduled',
  SUCCESS: 'success',
  FAILED: 'failed',
  STOP: 'stop',
  CANCELLED: 'cancelled',
};

export const SCHEDULE_NOTE_TYPES = {
  SUPPLIER_REMOVE_ITEM: 'SUPPLIER_REMOVE_ITEM',
  AUTO_TRIGGER_NEXT_SCHEDULE: 'AUTO_TRIGGER_NEXT_SCHEDULE',
  MANUAL_TRIGGERED: 'MANUAL_TRIGGERED',
  PROJECT_100: 'PROJECT_100',
};
