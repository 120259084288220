import React, { useContext, useEffect, useReducer } from 'react';

import { makeStyles, Typography } from '@material-ui/core';

import FtrButton from '../ftr-components/FtrButton';
import { FlexRow } from '@components/layouts/FlexLayouts';
import FtrInfoIconTooltip from '@components/ftr-components/FtrInfoIconTooltip';
import TextLoading from '@components/decorations/TextLoading';

import { defectsStatus } from '../../utils/dfmExtractionUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

import withDfmAnalysisPopupHOC from '@hocs/withDfmAnalysisPopupHOC';

import AppContext from '../../context/AppContext';

import { FE_FEATURE_FLAGS_CONFIGURATION } from '../../constants/configurations';

const useStyles = makeStyles(() => ({
  dfmButton: {
    fontSize: '14px',
    marginLeft: '-6px',
    fontWeight: 600,
    textTransform: 'none',
    '&:hover': {
      filter: 'brightness(0.9)',
    },
  },
}));

/**
 * DefectButton is a React component that displays a button with an optional icon indicating
 * the defect status of a DFM (Design for Manufacturability) analysis.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.dfmDefect - The defect object containing the output of the DFM analysis.
 * @param {boolean} props.isLoading - Flag indicating whether the DFM analysis is currently loading.
 * @param {function} props.onClick - Callback function to handle button click events.
 * @param {boolean} [props.showIcon=false] - Flag to determine whether to display the defect status icon.
 * @param {string} [props.text='Defect'] - The text to display on the button.
 */
function DfmAnalysisButton(props) {
  const {
    dfmDefectOutput,
    isLoading,
    onClick,
    customText,
    updateDfmAnalysisPopupState = () => {},
    cadFile = '',
    twoDImageUrl = '',
    style,
    forPUE = false,
    ...restProps
  } = props;

  const { featureFlags } = useContext(AppContext);
  const classes = useStyles();

  if (
    !featureFlags?.config?.[FE_FEATURE_FLAGS_CONFIGURATION.DFM_AUTOMATION_BUYER]
  ) {
    return null;
  }

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      displayText: 'Get DFM Analysis',
      color: colors.blue060,
    }
  );

  const displayText = customText || localState.displayText;

  useEffect(() => {
    if (isEmptyValue(dfmDefectOutput) || !dfmDefectOutput?.dfm_success) {
      return;
    }

    const _defectsStatus = defectsStatus(dfmDefectOutput);
    const allDefects = _defectsStatus?.filter((defect) => defect.defect);

    if (!isEmptyValue(allDefects)) {
      updateLocalState({
        displayText: 'View DFM issues detected',
        color: colors.warningYellow,
      });
      return;
    }

    updateLocalState({
      displayText: 'DFM checks passed',
      color: colors.green040,
    });
  }, [dfmDefectOutput]);

  if (isLoading) {
    return (
      <FlexRow style={{ marginTop: '7px', ...style }}>
        <TextLoading
          style={{
            fontWeight: 600,
            color: colors.blackTint070,
            ...style,
          }}
        >
          Detecting potential defects
        </TextLoading>
        <FtrInfoIconTooltip
          toolTipText={`Analyzing for potential defects - this may take a few minutes.${forPUE ? ' Feel free to continue configuring and upload your enquiry while we process your file.' : ''}`}
        />
      </FlexRow>
    );
  }

  if (dfmDefectOutput?.dfm_success === false) {
    return (
      <FlexRow style={{ marginTop: '7px', ...style }}>
        <Typography
          style={{
            fontWeight: 600,
            color: colors.blackTint060,
            fontSize: '14px',
          }}
        >
          DFM analysis failed
        </Typography>
        <FtrInfoIconTooltip
          toolTipText={`There was an error in analyzing your CAD model for manufacturability. Our engineers will assess to make sure everything is in order.`}
        />
      </FlexRow>
    );
  }

  return (
    <FtrButton
      variant='text'
      onClick={(event) => {
        if (typeof onClick === 'function') {
          onClick(event);
          return;
        }
        event.preventDefault();
        event.stopPropagation();
        updateDfmAnalysisPopupState({
          open: true,
          cadFile,
          twoDImageUrl,
          dfmDefectOutput,
          isLoading,
        });
      }}
      disabled={isLoading}
      style={{
        color: localState.color,
        ...style,
      }}
      className={classes.dfmButton}
      {...restProps}
    >
      {displayText}
    </FtrButton>
  );
}

export default withDfmAnalysisPopupHOC(DfmAnalysisButton);
