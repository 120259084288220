import { CsvBuilder } from 'filefy';
import { ceil } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { makeStyles } from '@material-ui/core/styles/index';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Icon, IconButton, Tooltip } from '@material-ui/core';
import { Info, Repeat as RepeatIcon } from '@material-ui/icons';

import PpeYesIcon from '../../assets/icons/ppe_yes.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';

import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import {
  RocketQuoteIconButton,
  NonPPEIcon,
} from '../../components/icons/ItemIcon';
import ImageWith3DViewer from '../../components/images/ImageWith3DViewer';
import { FlexColumn } from '../../components/layouts/FlexLayouts';
import InstantQuoteDetailsDisplayRequestQuoteReferencePopup from '../../components/popups/InstantQuoteDetailsDisplayRequestQuoteReferencePopup';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';
import RQAdvisoryText from '../../components/tooltips/RQAdvisoryText';

import withDeleteQuotePopupHOC from '../../hocs/withDeleteQuotePopupHOC';
import withRocketQuotePopupHOC from '../../hocs/withRocketQuotePopupHOC';

import { getTotalRequestQuote } from '../../actions';

import { getDeleteOrderSelector } from '../../selectors/orderSelector';
import { getUserRoleSelector } from '../../selectors/userSelector';

import { getAllRequestQuotes } from '../../apis/quotationApi';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import { generateCurrentCustomDateTimeString } from '../../util';
import { transformCsvExportDataGrid } from '../../utils/csvExportUtils';
import { dateTzSingapore } from '../../utils/dateTimeUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';
import { getRQAdvisoryMessages } from '../../utils/itemUtils';

import { itemProxy } from '../../proxies/itemProxy';

import { ITEM_STAGE_TYPE } from '../../constants';
import { FEATURE_FLAG_ROCKET_QUOTE } from '../../constants/featureFlagConstants';

import {
  materialWithColorCol,
  surfaceFinishingWithColorCol,
  toleranceCol,
} from '../../constants/itemTableConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    paddingTop: '1rem',
    marginBottom: '2rem',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: `invert(41%) sepia(90%) saturate(5329%) hue-rotate(203deg) brightness(96%) contrast(86%);`,
  },
  manualRfqIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: '#ffa500',
  },
  ppeIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    filter: '##008000',
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
  },
  status: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 'normal',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
}));

function AllRequestQuotesDataGrid(props) {
  const classes = useStyles();

  const {
    updateRocketQuotePopupState = () => {},
    onDeleteQuotePopup = () => {},
  } = props;

  const dispatch = useDispatch();

  const role = useSelector(getUserRoleSelector);

  const {
    data: allRequests,
    isLoading,
    refetch,
  } = useQuery('getAllRequestQuotes', getAllRequestQuotes);

  const { deleteOrderSuccess } = useSelector(getDeleteOrderSelector);

  useEffect(() => {
    if (deleteOrderSuccess) {
      dispatch(getTotalRequestQuote());
      refetch();
    }
  }, [deleteOrderSuccess]);

  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );
  const [filteredData, { setSearchStr, setColumnsDef, setSourceData }] =
    useDataGridFilterHook({
      search: '',
      source: allRequests ?? [],
    });

  const [viewReferenceQuotePopupState, updateViewReferenceQuotePopupState] =
    useReducer(
      (prev, next) => {
        return { ...prev, ...next };
      },
      {
        open: false,
        itemID: null,
      }
    );

  const renderPPE = () => {
    return (
      <Tooltip title='Instant / Rocket Quoted'>
        <Icon>
          <img className={classes.ppeIcon} src={PpeYesIcon} alt='' />
        </Icon>
      </Tooltip>
    );
  };

  const renderRepeatOrder = () => (
    <Tooltip title='Repeat Order'>
      <RepeatIcon style={{ color: colors.repeatItemColor }} />
    </Tooltip>
  );

  const defaultColumns = [
    {
      headerName: ' ',
      renderCell: ({ row: rowData }) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title='View reference quote details'>
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={() => {
                  updateViewReferenceQuotePopupState({
                    open: true,
                    itemID: rowData.itemID,
                  });
                }}
              >
                <Info color='primary' style={{ fontSize: '15pt' }} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
      field: 'actions',
      width: 60,
    },
    {
      headerName: 'Requested by',
      field: 'requester',
      renderCell: (params) => {
        const rowData = params.row;
        const { requesterName, dateOfOrder, isAdminRequested } = rowData;

        return (
          <FlexColumn>
            <DataGridWrapTextCell
              text={`${
                isAdminRequested ? 'Admin' : 'Customer'
              }: ${requesterName}`}
            />
            <DataGridWrapTextCell text={dateTzSingapore(dateOfOrder)} />
          </FlexColumn>
        );
      },
      width: 200,
    },
    {
      headerName: 'Item Type',
      field: 'itemType',
      renderCell: (params) => {
        const rowData = params.row;

        const itemProxyObj = new Proxy(rowData, itemProxy);

        const { status } = rowData;
        const listStatusCanRocketQuote = [
          ITEM_STAGE_TYPE.VERIFYING,
          ITEM_STAGE_TYPE.SOURCING,
          ITEM_STAGE_TYPE.QUOTES_AVAILABLE,
        ];
        const canRocketQuote =
          FEATURE_FLAG_ROCKET_QUOTE === 'true' &&
          listStatusCanRocketQuote.includes(status);

        const advisoryText = () => {
          const { category, messages } = getRQAdvisoryMessages(rowData);
          return <RQAdvisoryText category={category} messages={messages} />;
        };

        return (
          <div
            style={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}
          >
            {rowData.price ? (
              renderPPE()
            ) : (
              <NonPPEIcon tooltipText={advisoryText()} />
            )}
            {itemProxyObj.isRepeatItem() && renderRepeatOrder()}
            {canRocketQuote && (
              <RocketQuoteIconButton
                onClick={() => {
                  updateRocketQuotePopupState({
                    item: rowData,
                    refetchData: () => {
                      dispatch(getTotalRequestQuote());
                      refetch();
                    },
                  });
                }}
              />
            )}
          </div>
        );
      },
      width: 120,
    },
    {
      headerName: 'Request Qty',
      field: 'quantity',
      width: 100,
    },
    {
      headerName: 'Original Qty',
      field: 'originalQuantity',
      width: 100,
    },
    {
      title: 'Project ID',
      headerName: 'Project ID',
      field: 'projectID',
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <Button
            className={classes.linkButton}
            href={`/manage-projects/${rowData.projectID}`}
          >
            {rowData.projectID}
          </Button>
        );
      },
      width: 80,
    },
    {
      title: '(Part ID) Part Name',
      headerName: '(Part ID) Part Name',
      field: 'partName',
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.6rem',
              justifyContent: 'space-between',
              width: '100%',
              padding: '0 0.5rem',
            }}
          >
            <span>
              ({rowData.itemID}) {rowData.referenceName}
            </span>
          </div>
        );
      },
      width: 180,
    },
    {
      title: 'Customer Part Name',
      headerName: 'Customer Part Name',
      field: 'name',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.name} />
      ),
      width: 150,
      minWidth: 150,
      flex: 1,
    },
    {
      title: 'Date Uploaded',
      headerName: 'Date Uploaded',
      field: 'datePosted',
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <Tooltip title='Date and time displayed in SGT'>
            <div>
              {rowData.datePosted ? dateTzSingapore(rowData.datePosted) : ''}
            </div>
          </Tooltip>
        );
      },
      width: 150,
    },
    {
      title: 'Image',
      headerName: 'Image',
      field: 'image',
      renderCell: (params) => {
        const rowData = params.row;
        const url = rowData.imageFile || rowData.twoDImageUrl;
        return (
          <ImageWith3DViewer
            key={rowData.itemID}
            itemID={rowData.itemID}
            twoDImageUrl={url}
            cadFile={rowData.cadFile || rowData.originalFiles}
            isGenerateItemImage
            width={70}
            height={70}
            noBorder={false}
            borderRadius={0}
            textRenderStyle={{ fontSize: '9px' }}
          />
        );
      },
      width: 100,
    },
    {
      title: 'Technology',
      headerName: 'Technology',
      field: 'technology',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.technology} />
      ),
      width: 150,
    },
    {
      ...materialWithColorCol,
    },
    {
      ...surfaceFinishingWithColorCol,
    },
    {
      ...toleranceCol,
    },
    {
      title: 'Delete',
      field: 'Delete',
      renderCell: (params) => {
        const { quotationID: deletedOrderId } = params.row;
        return (
          <div className={classes.containerLog}>
            <TrashIcon
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                onDeleteQuotePopup({ deletedOrderId });
              }}
            />
          </div>
        );
      },
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(allRequests ?? []);
  }, [allRequests]);

  useEffect(() => {
    updateTableQueryParams({ loading: isLoading ?? false });
  }, [isLoading]);

  useEffect(() => {
    setColumnsDef(columns);
  }, [columns]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleDownloadCsv = () => {
    const fileName = `All Request Quotes ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  const getCustomerToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.body}>
      <DataGrid
        autoHeight
        rows={filteredData ?? []}
        columns={columns?.map((col) => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.quotationID}
        rowHeight={80}
        headerHeight={80}
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        rowsPerPageOptions={[10, 20, 50]}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
        loading={tableQueryParams.loading}
      />
      {viewReferenceQuotePopupState.open && (
        <InstantQuoteDetailsDisplayRequestQuoteReferencePopup
          open={viewReferenceQuotePopupState.open}
          itemID={viewReferenceQuotePopupState.itemID}
          onClose={() => updateViewReferenceQuotePopupState({ open: false })}
        />
      )}
    </div>
  );
}

export default compose(
  withRocketQuotePopupHOC,
  withDeleteQuotePopupHOC
)(AllRequestQuotesDataGrid);
