import React from 'react';

import { Box } from '@material-ui/core';

import HtmlContentDefect from '../HtmlContentDefect';
import { FtrButton, FtrTooltip, FtrTypography } from '../ftr-components';
import { FlexRowCenter } from '../layouts/FlexLayouts';
import SingleImage from '../images/SingleImage';

import withDefectVisualizationPopupHOC from '../popups/withDefectVisualizationPopupHOC';
import withMouseHoverHOC from '../images/withMouseHoverHOC';

import { isEmptyValue } from '../../utils/commonUtils';
import { snakeCaseToTitleCase } from '../../utils/stringUtils';

import { colors } from '../../palette';
import { EXTRACT_DEFECT_DEFINITIONS_DISPLAY } from '@constants/dfmConstants';

const SingleImageWithMouseEnter = withMouseHoverHOC(SingleImage);

function DefectThumbnail(props) {
  const {
    visualizationLinks,
    defectKey,
    handleView3DDefect,
    twoDImageUrl,
    cadFile,
    stateIndicator,
    ...restProps
  } = props;

  if (['failed', 'loading'].includes(stateIndicator)) {
    return null;
  }

  const defectUrl = visualizationLinks?.[defectKey];

  // If defectUrl is empty then render twoDImageUrl and native renderer
  if (isEmptyValue(defectUrl)) {
    return (
      <SingleImageWithMouseEnter
        url={twoDImageUrl}
        noBorder
        width={180}
        height={180}
        borderRadius={10}
        textStyle={{ color: 'white' }}
        text='Render 3D CAD'
        cadFile={cadFile}
        onClick={() =>
          handleView3DDefect(null, {
            showDefaultDefect: false,
            showDfmForm: false,
            title: '3D CAD Renderer',
          })
        }
        style={{ border: '1px solid' }}
        {...restProps}
      />
    );
  }

  const renderTextContent = () => {
    if (!EXTRACT_DEFECT_DEFINITIONS_DISPLAY[defectKey]) {
      return null;
    }
    return (
      <FtrTypography
        fontSize='11'
        style={{
          width: '182px',
          border: `1px solid ${colors.blue050}`,
          padding: '5px',
          borderRadius: '5px',
          marginTop: '0.5rem',
          boxSizing: 'border-box',
          lineHeight: '1.2',
          textAlign: 'center',
          color: colors.neutral070,
          backgroundColor: colors.blue010,
        }}
      >
        {EXTRACT_DEFECT_DEFINITIONS_DISPLAY[defectKey]}
      </FtrTypography>
    );
  };

  return (
    <div>
      <Box
        style={{
          border: `1px solid ${colors.neutral060}`,
          borderRadius: '10px',
          width: 180,
          height: 180,
        }}
      >
        <HtmlContentDefect url={defectUrl} borderRadius='10px' />
      </Box>
      <FlexRowCenter style={{ marginTop: '8px' }}>
        <FtrTooltip
          description={`View Defect ${snakeCaseToTitleCase(defectKey)}`}
          arrow
        >
          <div>
            <FtrButton
              variant='outlined'
              color='blue'
              style={{
                marginLeft: '10px',
                padding: '3px 9px',
                minWidth: 'max-content',
              }}
              size='small'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleView3DDefect(defectUrl, {
                  showDfmForm: false,
                  showDefectTitle: false,
                  title: `Defect: ${snakeCaseToTitleCase(defectKey)}`,
                });
              }}
              {...restProps}
            >
              {snakeCaseToTitleCase(defectKey)}
            </FtrButton>
          </div>
        </FtrTooltip>
      </FlexRowCenter>
      {renderTextContent()}
    </div>
  );
}

export default withDefectVisualizationPopupHOC(DefectThumbnail);
