import React from 'react';
import { Button, CircularProgress, Tooltip } from '@material-ui/core';

import { FlexColumn } from '../layouts/FlexLayouts';
import { getFileNameFromCadFile } from '../../utils/itemUtils';
import { dateTzSingapore } from '../../utils/dateTimeUtils';
import { ROLE_TYPES } from '../../constants';

function ExtractButton(props) {
  const {
    disabled,
    onClick,
    tooltipText,
    loading,
    name,
    createdAt,
    sourceUrl,
    buttonText = 'Extract',
    buttonStyle,
    textStyle,
    style,
    role,
    ...rest
  } = props;

  const renderLastExtractedName = () => {
    if (role === ROLE_TYPES.BUYER) {
      return <strong>automatically</strong>;
    }
    return (
      <>
        by <strong>{name}</strong>
      </>
    );
  };

  return (
    <FlexColumn style={{ alignItems: 'start', ...style }} {...rest}>
      <Tooltip title={tooltipText}>
        <Button
          variant='outlined'
          component='span'
          color='primary'
          disabled={disabled}
          onClick={onClick}
          style={{
            minWidth: 220,
            textAlign: 'center',
            fontSize: 12,
            ...buttonStyle,
          }}
        >
          {buttonText}
          {loading && (
            <CircularProgress size={20} style={{ marginLeft: '10px' }} />
          )}
        </Button>
      </Tooltip>
      <div style={{ fontSize: 12, wordBreak: 'break-word', ...textStyle }}>
        {name && createdAt ? (
          <div>
            Last extracted {renderLastExtractedName()} on&nbsp;
            <strong>{dateTzSingapore(createdAt)}</strong>
            &nbsp;using&nbsp;
            <strong>
              {getFileNameFromCadFile(sourceUrl?.split('/').pop())}
            </strong>
          </div>
        ) : (
          'No previous extraction attempt.'
        )}
      </div>
    </FlexColumn>
  );
}

export default ExtractButton;
