import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

// import { ROLE_TYPES } from '../constants';

import { isAdminOrHigherRole, isSuperAdminRole } from '../utils/roleUtils';

import { colors } from '../palette';

import RepeatOrderDashboard from './admin-dashboards/RepeatOrderDashboard';
import DailyDashboard from './admin-dashboards/DailyDashboard';

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TAB_KEYS = {
  DAILY_DASHBOARD: 'Daily Dashboard',
  REPEAT_ORDER_DASHBOARD: 'Repeat Order Dashboard',
};

const TABS = [TAB_KEYS.DAILY_DASHBOARD, TAB_KEYS.REPEAT_ORDER_DASHBOARD];

function AdminDashboard(props) {
  const { role } = props;
  const classes = useStyles();

  const [viewableTabs, setViewableTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (isSuperAdminRole(role)) {
      setViewableTabs(TABS);
    }

    if (isAdminOrHigherRole(role)) {
      setViewableTabs(TABS);
    }

    // at the moment we don't have other roles that can access this page
  }, [role]);

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTab = () => {
    const tabName = viewableTabs[selectedTab];
    switch (tabName) {
      case TAB_KEYS.DAILY_DASHBOARD:
        return <DailyDashboard />;
      case TAB_KEYS.REPEAT_ORDER_DASHBOARD:
        return <RepeatOrderDashboard />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.body}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
      >
        {viewableTabs?.map((tab) => {
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
      {renderTab()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(AdminDashboard);
