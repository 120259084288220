import React, { useEffect, useState } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { get } from 'lodash';

import LoadingBackDropText from '../components/LoadingBackDropText';

import { executeEmailTriggerAction } from '../apis/emailTriggerActionsApi';
import { createAlertNotification } from '../apis/alertApi';

import { FACTOREM_BLUE, colors } from '../palette';
import { notifySuccess } from '../services/notificationService';
import { getUserAgentInfo } from '../utils/userUtils';
import { EMAIL_TRIGGER_ACTION_NAMES } from '../constants/emailConstants';
import UserFeedbackPopupV2 from '../components/popups/UserFeedbackPopupV2';
import { USER_FEEDBACK_RATING_QUESTION_LIST } from '../constants/userFeedbackConstants';

const useStyles = makeStyles(() => ({
  container: {
    margin: '1rem',
  },
  title: {
    fontWeight: '600',
    color: colors.fontDarkGrey,
  },
  subtitle: {
    display: 'flex',
    color: colors.fontGrey,
    alignItems: 'end',
  },
  link: {
    color: FACTOREM_BLUE,
    cursor: 'pointer',
  },
}));

function EmailTriggerActions() {
  const params = useParams();
  const classes = useStyles();
  const [emailTriggerDetail, setEmailTriggerDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showSuccessDefault, setShowSuccessDefault] = useState(false);

  const loadExecuteEmailTriggerAction = async () => {
    setIsLoading(true);
    const userAgentInfo = await getUserAgentInfo().catch((error) => {
      const body = {
        title: '[FE] Get User Agent Info Failed!',
        message: error?.message,
        errorStack: error?.stack,
      };
      createAlertNotification(body);
    });
    executeEmailTriggerAction(params?.actionID, { userAgentInfo })
      .then(setEmailTriggerDetail)
      .catch(() => {
        setEmailTriggerDetail(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (params?.actionID) {
      loadExecuteEmailTriggerAction();
    }
  }, []);

  if (isLoading) {
    return <LoadingBackDropText open={isLoading} text='Loading...' />;
  }

  const renderResSuccessWatchItems = () => {
    return (
      <Redirect
        to={{
          pathname: `/projects/${get(
            emailTriggerDetail,
            'properties.projectItemList[0].projectID'
          )}/quote`,
        }}
      />
    );
  };

  const renderResSuccessDefault = () => {
    return (
      <Alert severity='success' className={classes.container}>
        <AlertTitle className={classes.title}>
          Response noted successfully!
        </AlertTitle>
        <div className={classes.subtitle}>
          Click&nbsp;
          <Link
            className={classes.link}
            to={{
              pathname: '/',
            }}
          >
            here
          </Link>
          &nbsp;to go to portal.
        </div>
      </Alert>
    );
  };

  const renderFeedbackPopup = () => {
    return (
      <UserFeedbackPopupV2
        open
        questionList={USER_FEEDBACK_RATING_QUESTION_LIST}
        userID={emailTriggerDetail?.properties?.userID}
        submitLocation='Order Completion'
        checkoutID={emailTriggerDetail?.properties?.checkoutID}
        handleClose={() => {
          setShowSuccessDefault(true);
        }}
      />
    );
  };

  const renderInvoiceDownloaded = () => {
    window.location?.replace(
      emailTriggerDetail?.properties?.presignedInvoiceUrl
    );
    return (
      <Alert severity='success' className={classes.container}>
        <AlertTitle className={classes.title}>
          Invoice downloaded successfully!
        </AlertTitle>
        <div className={classes.subtitle}>
          Project Order Ready ID:{' '}
          {emailTriggerDetail?.properties?.projectOrderReadyID}
        </div>
      </Alert>
    );
  };

  if (showSuccessDefault) {
    return renderResSuccessDefault();
  }

  if (
    emailTriggerDetail?.status === 'success' &&
    emailTriggerDetail?.properties?.userID
  ) {
    switch (emailTriggerDetail?.actionName) {
      case EMAIL_TRIGGER_ACTION_NAMES.WATCH_ITEMS: {
        notifySuccess('Response noted successfully!');
        return renderResSuccessWatchItems();
      }
      case EMAIL_TRIGGER_ACTION_NAMES.FEEDBACK_ORDER_COMPLETION: {
        return renderFeedbackPopup();
      }
      case EMAIL_TRIGGER_ACTION_NAMES.GET_SUPPLIER_INVOICE: {
        return renderInvoiceDownloaded();
      }
      default: {
        return renderResSuccessDefault();
      }
    }
  }

  return emailTriggerDetail?.actionName ===
    EMAIL_TRIGGER_ACTION_NAMES.GET_SUPPLIER_INVOICE ? (
    <Alert severity='error' className={classes.container}>
      <AlertTitle className={classes.title}>
        Unable to find the supplier invoice.
      </AlertTitle>
      <div className={classes.subtitle}>
        This is most likely because the supplier has not uploaded the invoice
        yet.
      </div>
      <div className={classes.subtitle}>
        Project Order Ready ID:{' '}
        {emailTriggerDetail?.properties?.projectOrderReadyID}
      </div>
    </Alert>
  ) : (
    <Alert severity='error' className={classes.container}>
      <AlertTitle className={classes.title}>
        Unexpected error in noting your response.
      </AlertTitle>
      <div className={classes.subtitle}>
        Please submit your response via the&nbsp;
        <Link className={classes.link} to={{ pathname: `/` }}>
          platform
        </Link>
        &nbsp;instead.
      </div>
    </Alert>
  );
}

export default EmailTriggerActions;
