import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { useMediaQuery, useTheme } from '@material-ui/core';

import { FtrTypography } from './ftr-components';

import redAlertIcon from '../assets/icons/red_alert.svg';
import uploadFileIcon from '../assets/icons/upload_file.svg';
import { ReactComponent as UploadIcon } from '../assets/icons/upload_icon.svg';

import { getSupportedFileTypesWithCache } from '../apis/configurationApi';

import { isEmptyValue } from '../utils/commonUtils';
import { getFileExtension } from '../utils/fileUtils';

import {
  ALL_ACCEPTED_FILES_TYPES,
  ALL_FILES_TYPES,
  TECHNOLOGY_OPTION_TYPE,
} from '../constants/NewPartConstants';

import { colors } from '../palette';

/**
 * WhiteDragDrop component
 *
 * @param {object} props - The properties of the WhiteDragDrop component.
 * @param {bool} props.fullWidth - Occupies 100% width of the parent container.
 * @param {string} props.technology - Technology of the part, used to determine the supported file types.
 * @param {function} props.handleFiles - Callback function to handle the files.
 * @param {bool} props.isThreeDCADFileAvailable - Whether 3D CAD file is available.
 * @param {function} props.handleSupportedFileTypes - Callback function to handle the supported file types.
 * @param {array} props.unsupportedFileTypes - The unsupported file types.
 * @param {function} props.handleUnsupportedFileType - Callback function to handle the unsupported file types.
 * @param {bool} props.isAdditionalInfoShown - Whether to show additional information.
 * @param {bool} props.multiple - to handle single or multiple files
 * @param {bool} props.simpleLabel - To show simple label
 * @param {string} props.simpleLabelStyle - custom for css simple label
 * @param {bool} props.startIcon - start icon for simple label
 * @param {string} props.draggingLabelText - dragging label text for simple label
 * @param {string} props.labelText - label text for simple label
 *
 * @returns {React.ReactNode} The rendered WhiteDragDrop component.
 */
const WhiteDragDropV2 = forwardRef((props, ref) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    id = 'cad-part-file',
    fullWidth,
    technology = TECHNOLOGY_OPTION_TYPE.CNC_MACHINING,
    handleFiles,
    handleSupportedFileTypes = () => {},
    handleUnsupportedFileType = () => {},
    isAdditionalInfoShown = false,
    isThreeDCADFileAvailable = true,
    multiple = true,
    simpleLabel = false,
    startIcon = <UploadIcon />,
    draggingLabelText = 'Drop design files here',
    labelText = 'Drag or browse to upload',
    simpleLabelStyle,
    ...rest
  } = props;

  const allFileTypes = useSelector(
    (state) => state.pageSettings?.allFileTypesSupport
  );

  const [dragging, setDragging] = useState(false);
  const [isEnter, setIsEnter] = useState(false);
  const [unsupportedFileType, setUnsupportedFileType] = useState([]);
  const [error, setError] = useState('');

  const { data: supportedFileTypesByTech } = useQuery(
    ['getSupportedFileTypes', technology],
    () => {
      if (isEmptyValue(technology)) {
        return;
      }
      return getSupportedFileTypesWithCache({ technology });
    }
  );

  const supportFilesText = useMemo(() => {
    handleUnsupportedFileType([]);

    if (isEmptyValue(supportedFileTypesByTech)) {
      return '';
    }

    if (!isThreeDCADFileAvailable) {
      return '.pdf';
    }

    const { supportFileTypes } = supportedFileTypesByTech;

    const fileTypes = isEqual(supportFileTypes, ALL_FILES_TYPES)
      ? allFileTypes?.supportFileTypes
      : supportFileTypes;
    const text = isEqual(fileTypes, ALL_FILES_TYPES)
      ? allFileTypes?.supportFileTypes?.map((o) => `.${o}`)?.join(', ')
      : fileTypes?.map((o) => `.${o}`)?.join(', ');
    return text;
  }, [supportedFileTypesByTech, isThreeDCADFileAvailable, allFileTypes]);

  useEffect(() => {
    // If the supportFilesText change, make the unsupported list to be empty
    setUnsupportedFileType([]);
  }, [supportFilesText]);

  useEffect(() => {
    handleUnsupportedFileType([]);

    if (isEmptyValue(supportedFileTypesByTech)) {
      return;
    }

    if (!isThreeDCADFileAvailable) {
      handleSupportedFileTypes((prev) => ({
        ...prev,
        fileTypes: ['pdf'],
        description:
          'Please upload 1 file for each component. Additional files can be added at next stage',
        oldFileTypes: prev.fileTypes,
        oldDescription: prev.description,
      }));
      return;
    }

    const { supportFileTypes, description } = supportedFileTypesByTech;
    const fileTypes = isEqual(supportFileTypes, ALL_FILES_TYPES)
      ? ALL_ACCEPTED_FILES_TYPES
      : supportFileTypes;
    handleSupportedFileTypes((prev) => ({
      ...prev,
      fileTypes: fileTypes,
      description,
    }));
  }, [supportedFileTypesByTech, isThreeDCADFileAvailable]);

  useEffect(() => {
    if (!isEmptyValue(unsupportedFileType)) {
      const message =
        unsupportedFileType.length > 1
          ? `These file types aren't supported: ${unsupportedFileType?.join(
              ', '
            )}`
          : `This file type isn't supported: ${unsupportedFileType?.join(', ')}`;
      setError(message);
    } else {
      setError('');
    }
  }, [unsupportedFileType]);

  const handleUploadFiles = (files) => {
    if (files.length > 1 && multiple === false) {
      setError('Multiple files uploading is not allowed.');
      return;
    }

    const { supportFileTypes } = supportedFileTypesByTech;
    if (
      isEqual(supportFileTypes, ALL_FILES_TYPES) &&
      isThreeDCADFileAvailable
    ) {
      handleFiles(files);
      return;
    }

    const unSupportedFileTypes = new Set();
    const uploadFiles = [];
    for (const file of files) {
      const fileExtension = getFileExtension(file.name);
      const isSupported = isThreeDCADFileAvailable
        ? supportFileTypes.includes(fileExtension)
        : fileExtension === 'pdf';
      if (isSupported) {
        uploadFiles.push(file);
      } else {
        unSupportedFileTypes.add(fileExtension);
      }
    }
    setUnsupportedFileType(Array.from(unSupportedFileTypes));

    if (isEmptyValue(uploadFiles)) {
      return;
    }
    handleFiles(uploadFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEnter(true);
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEnter(false);
    setDragging(false);
  };
  const handleDrop = (e) => {
    setUnsupportedFileType([]);
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    setIsEnter(false);
    handleUploadFiles(e.dataTransfer.files);
  };

  const renderMainText = () => {
    return (
      <div
        style={{
          color: colors.fontGrey,
          fontSize: isMobile ? 14 : 16,
          lineHeight: '21px',
          textAlign: 'center',
        }}
      >
        <FtrTypography
          type='body'
          fontSize='16'
          style={{ padding: '0.5rem 0', color: colors.fontBlack }}
        >
          Drop files here or
          <span style={{ color: colors.blue060, fontWeight: 700 }}>
            {' '}
            Browse{' '}
          </span>
          to upload more parts
        </FtrTypography>
        <FtrTypography
          type='body'
          fontSize='14'
          style={{ color: colors.fontBlack }}
        >
          Supports&nbsp;
          {isEqual(
            supportFilesText,
            allFileTypes?.supportFileTypes?.map((o) => `.${o}`)?.join(', ')
          )
            ? 'All File Types'
            : supportFilesText}
        </FtrTypography>
        {isAdditionalInfoShown && (
          <FtrTypography
            type='body'
            fontSize='14'
            style={{ padding: '0.5rem 0', color: colors.neutral060 }}
          >
            Each upload is recognised as a separate part. Technical drawings can
            be added later.
          </FtrTypography>
        )}
        {!isEmptyValue(error) && (
          <FtrTypography
            type='body'
            fontSize='14'
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '0.5rem 0',
              color: colors.neutral070,
            }}
          >
            <img src={redAlertIcon} alt='logo' />
            &nbsp;{error}
          </FtrTypography>
        )}
      </div>
    );
  };

  const renderLabel = () => {
    if (simpleLabel) {
      return (
        <div
          style={{
            backgroundColor:
              isEnter || dragging ? colors.blue010 : 'transparent',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            border: `1px solid ${colors.blue050}`,
            padding: '0.5rem 1rem',
            borderRadius: '10px',
            ...simpleLabelStyle,
          }}
        >
          {startIcon}
          <FtrTypography
            type='body'
            fontSize='14'
            style={{
              color: colors.blue050,
              paddingLeft: '8px',
              fontWeight: 600,
            }}
          >
            {isEnter || dragging ? draggingLabelText : labelText}
          </FtrTypography>
        </div>
      );
    }

    return (
      <div
        data-cy='upload-multi-cad-files'
        style={{
          borderRadius: '10px',
          border: `dashed #A9A9A9 1.5px`,
          width: fullWidth ? 'auto' : !isMobile && '55rem',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          verticalAlign: 'middle',
          alignItems: 'center',
          cursor: 'pointer',
          opacity: isEnter ? 0.8 : 1,
          padding: isMobile ? '20px 10px' : '40px',
        }}
      >
        <img
          src={uploadFileIcon}
          style={isMobile ? { height: 40 } : null}
          alt='logo'
        />
        {dragging && 'Release to upload files'}
        {!dragging && renderMainText()}
      </div>
    );
  };

  return (
    <div ref={ref} {...rest}>
      <input
        id={id}
        type='file'
        accept={supportFilesText}
        multiple={multiple}
        onChange={(evt) => {
          const files = evt.target.files;
          setUnsupportedFileType([]);
          setError('');
          handleUploadFiles(files);
        }}
        onClick={(event) => {
          setUnsupportedFileType([]);
          setError('');
          event.target.value = null;
        }}
        style={{
          display: 'none',
        }}
      />
      <label
        htmlFor={id}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {renderLabel()}
      </label>
    </div>
  );
});

WhiteDragDropV2.displayName = 'WhiteDragDropV2';

export default WhiteDragDropV2;
