import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell as MuiTableCell,
  withStyles,
  TableBody,
  TableCell,
  DialogTitle,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';

import FtrButton from '../ftr-components/FtrButton';
import FtrTypography, {
  FtrB2,
  FtrB3,
  FtrH3,
  FtrH6,
} from '../ftr-components/FtrTypography';
import ImageWith3DViewer from '../images/ImageWith3DViewer';
import InfoMatchValue from '../info/InfoMatchValue';
import SingleImage from '../images/SingleImage';
import Thumb from '../fields/Thumb';
import {
  FlexColumnCenter,
  FlexRow,
  FlexRowCenter,
} from '../layouts/FlexLayouts';

import { RepeatOrderIcon } from '../icons/ItemIcon';

import useItemAllQuotationsInfo from '../../hooks/useItemAllQuotationsInfoHook';
import useUserInfo from '../../hooks/useUserInfoHook';

import { formatDate } from '../../utils/dateTimeUtils';
import { getCadFileTo3DRenderer } from '../../utils/fileUtils';
import { getSurfaceFinishWithCustomizationsText } from '../../utils/itemUtils';
import { getToleranceDisplayText } from '../../utils/toleranceUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { map } from '../../utils/arrayUtils';
import { openInNewTab } from '../../utils/navigationUtils';
import { getPurchaseOrderID } from '../../utils/quotationUtils';

import { QUALITY_ITEM_LABELS } from '../../constants/itemConstants';

import { colors } from '../../palette';
import FileDownloadListDisplay from '../labels/FileDownloadListDisplay';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  closeIcon: {
    position: 'absolute',
    right: '0.8rem',
    top: '0.8rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

const ColumnHeader = withStyles({
  root: {
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '0.5rem 2rem',
  },
})(MuiTableCell);

const RowCell = withStyles({
  root: {
    padding: '0.3rem 2rem',
  },
})(MuiTableCell);

const DISPLAY_ROWS = [
  {
    label: 'Item Image',
    render: (item) => {
      const cadFile = getCadFileTo3DRenderer(
        item?.cadFile || item.originalFiles
      );
      const imageUrl = item.imageFile || item.twoDImageUrl;

      return (
        <FlexColumnCenter>
          <ImageWith3DViewer
            key={item.itemID}
            cadFile={cadFile}
            twoDImageUrl={imageUrl}
            width={90}
            height={90}
            noBorder
            isLoading={item.imageStatus === 'loading'}
            borderRadius={8}
          />
        </FlexColumnCenter>
      );
    },
  },
  {
    label: 'Item Name',
    valueGetter: (item) => item.name || 'N.A.',
    render: (item) => {
      return <FtrH3 style={{ fontWeight: 600 }}>{item?.name || 'N.A.'}</FtrH3>;
    },
    check: true,
  },
  {
    label: 'Customer Name',
    render: (item) => {
      const customer = get(item, 'customer');
      if (isEmptyValue(customer)) {
        return <FtrH3 style={{ fontWeight: 600 }}>N.A.</FtrH3>;
      }

      const companyName = customer.cCompanyName || customer.companyName;

      return (
        <FlexColumnCenter style={{ gap: 0 }}>
          <FtrH3 style={{ fontWeight: 600 }}>
            {customer.name} ({customer.userID})
          </FtrH3>
          <FtrB2>{companyName}</FtrB2>
        </FlexColumnCenter>
      );
    },
  },
  {
    label: 'Date Uploaded',
    render: (item) => {
      if (isEmptyValue(item.datePosted)) {
        return <FtrH3 style={{ fontWeight: 600 }}>N.A.</FtrH3>;
      }

      return (
        <FtrH3 style={{ fontWeight: 600 }}>
          {formatDate(item.datePosted, 'DD-MM-YYYY')}
        </FtrH3>
      );
    },
  },
  {
    label: 'Supplier Name',
    valueGetter: (item) => {
      const quotation = get(item, 'quotation');

      const text =
        isEmptyValue(quotation) || isEmptyValue(quotation.userID)
          ? 'N.A.'
          : `${quotation?.supplierName} (${quotation?.userID})`;

      return text;
    },
    render: (item) => {
      const quotation = get(item, 'quotation');

      const text =
        isEmptyValue(quotation) || isEmptyValue(quotation.userID)
          ? 'N.A.'
          : `${quotation?.supplierName} (${quotation?.userID})`;

      return <FtrB3 style={{ fontWeight: 600 }}>{text}</FtrB3>;
    },
    check: true,
  },
  {
    label: 'ORM Images',
    render: (item) => {
      return (
        <FlexRow style={{ justifyContent: 'center' }}>
          {map(item.images, (imageUrl) => {
            return (
              <SingleImage
                url={imageUrl}
                width={80}
                height={80}
                noBorder={true}
                borderRadius={10}
              />
            );
          })}
        </FlexRow>
      );
    },
  },
  {
    label: 'ORM Link',
    render: (item) => {
      const poNumber = getPurchaseOrderID(item.quotation?.combinedPOsForm);
      if (isEmptyValue(poNumber) || isEmptyValue(item.itemOrderReady)) {
        return <FtrH3>N.A.</FtrH3>;
      }

      return (
        <FlexRow style={{ justifyContent: 'center' }}>
          <FtrTypography type='subHeading' fontSize='16'>
            ORM
          </FtrTypography>
          <Link
            onClick={() =>
              openInNewTab(
                `/projects/${item.projectID}/order-ready?supplierID=${item.quotation?.userID}&version=${item.quotation?.poAcknowledgedVersion}`
              )
            }
          >
            <FtrTypography type='subHeading' fontSize='16'>
              #{poNumber}
            </FtrTypography>
          </Link>
        </FlexRow>
      );
    },
  },
  {
    label: 'QC Reports',
    render: (item) => {
      const qcReportsResources = item.qcReportsResources || [];
      if (isEmptyValue(qcReportsResources)) {
        return <FtrH3>N.A.</FtrH3>;
      }

      const urlList = map(qcReportsResources, (resource) => {
        return resource.fileURL;
      });

      return (
        <FlexColumnCenter>
          <FileDownloadListDisplay title='' urlList={urlList} />
        </FlexColumnCenter>
      );
    },
  },
  {
    label: 'Additional Media',
    render: (item) => {
      const otherMediaResources = item.otherMediaResources || [];
      if (isEmptyValue(otherMediaResources)) {
        return <FtrH3>N.A.</FtrH3>;
      }

      const urlList = map(otherMediaResources, (resource) => {
        return resource.fileURL;
      });

      return (
        <FlexColumnCenter>
          <FileDownloadListDisplay title='' urlList={urlList} />
        </FlexColumnCenter>
      );
    },
  },
  {
    label: 'Quality Metrics',
    valueGetter: (item) => {
      const qualityMetric = get(item, 'qualityMetric') || {};
      if (isEmptyValue(qualityMetric)) {
        return 'N.A.';
      }

      delete qualityMetric.itemID;

      return Object.values(qualityMetric)?.join(',');
    },
    render: (item) => {
      const qualityMetric = get(item, 'qualityMetric') || {};
      if (isEmptyValue(qualityMetric)) {
        return <FtrH3 style={{ fontWeight: 600 }}>N.A.</FtrH3>;
      }

      return (
        <FlexRow style={{ justifyContent: 'center' }}>
          {Object.entries(qualityMetric)?.map(([key, value]) => {
            if (!key.toLowerCase().includes('quality')) {
              return null;
            }

            return (
              <Thumb
                key={key}
                variant={value === 1 ? 'up' : 'down'}
                isActive={true}
                tooltipText={QUALITY_ITEM_LABELS[key]}
              />
            );
          })}
        </FlexRow>
      );
    },
    check: true,
  },
  {
    label: 'Quantity',
    valueGetter: (item) => {
      const quotation = get(item, 'quotation');

      const quantity = quotation?.quantity || item?.quantity || 'N.A.';

      return quantity;
    },
    render: (item) => {
      const quotation = get(item, 'quotation');

      const quantity = quotation?.quantity || item?.quantity || 'N.A.';

      return <FtrH3 style={{ fontWeight: 600 }}>{quantity}</FtrH3>;
    },
    check: true,
  },
  {
    label: 'Technology',
    valueGetter: (item) => item.technology || 'N.A.',
    render: (item) => {
      return <FtrB3>{item.technology || 'N.A.'}</FtrB3>;
    },
    check: true,
  },
  {
    label: 'Material',
    valueGetter: (item) => {
      const quotation = get(item, 'quotation');

      const material = quotation?.material || item?.material || 'N.A.';

      return material;
    },
    render: (item) => {
      const quotation = get(item, 'quotation');

      const material = quotation?.material || item?.material || 'N.A.';

      return <FtrB3>{material}</FtrB3>;
    },
    check: true,
  },
  {
    label: 'Finishing',
    valueGetter: (item) => {
      const quotation = get(item, 'quotation');

      const finish = getSurfaceFinishWithCustomizationsText(quotation || item);

      return finish;
    },
    render: (item) => {
      const quotation = get(item, 'quotation');

      const finish = getSurfaceFinishWithCustomizationsText(quotation || item);

      return <FtrB3>{finish}</FtrB3>;
    },
    check: true,
  },
  {
    label: 'Tolerance',
    valueGetter: (item) => {
      if (isEmptyValue(item.tolerance)) {
        return 'N.A.';
      }

      const tolerance = getToleranceDisplayText({
        tolerance: item.tolerance,
        unitType: item.unitType,
      });

      return tolerance;
    },
    render: (item) => {
      if (isEmptyValue(item.tolerance)) {
        return 'N.A.';
      }

      const tolerance = getToleranceDisplayText({
        tolerance: item.tolerance,
        unitType: item.unitType,
      });

      return <FtrB3>{tolerance}</FtrB3>;
    },
    check: true,
  },
  {
    label: 'PPE CAD Hash',
    valueGetter: (item) => item.itemHashcodeCad || 'N.A.',
    render: (item) => {
      const text = item.itemHashcodeCad || 'N.A.';

      return <FtrB3>{text}</FtrB3>;
    },
    check: true,
  },
  {
    label: 'PPE PDF Hash',
    valueGetter: (item) => item.itemHashcodePdf || 'N.A.',
    render: (item) => {
      const text = item.itemHashcodePdf || 'N.A.';

      return <FtrB3>{text}</FtrB3>;
    },
    check: true,
  },
];

export const ITEM_PPE_TYPE = {
  REPEAT: 'repeat',
  SIMILAR: 'similar',
};

function RepeatItemOrmDetailsPopup(props) {
  const classes = useStyles();

  const { open, data = {}, itemID, onClose = () => {} } = props;

  const item = data?.item || {};
  const matchedItem = data?.repeatItem || {};

  const { data: allItemQuotations } = useItemAllQuotationsInfo(itemID);

  const { data: customer } = useUserInfo(item?.userID);

  const { data: matchedCustomer } = useUserInfo(matchedItem?.userID);

  const tableData = useMemo(() => {
    const quotation = item?.quotation;

    return [
      {
        ...(item || {}),
        customer,
        quotation,
      },
      {
        ...(matchedItem || {}),
        customer: matchedCustomer,
        quotation: matchedItem?.quotation,
      },
    ];
  }, [
    item?.itemID,
    matchedItem?.itemID,
    customer?.userID,
    matchedCustomer?.userID,
    allItemQuotations,
  ]);

  return (
    <Dialog
      open={open}
      width='md'
      maxWidth='xl'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <Close className={classes.closeIcon} onClick={onClose} />
      <DialogTitle>
        <FlexRowCenter style={{ paddingTop: '0.5rem' }}>
          <RepeatOrderIcon />
          <FtrH6>Repeat ORM Details</FtrH6>
        </FlexRowCenter>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TableContainer component={Paper} style={{ borderRadius: '16px' }}>
          <Table
            size='small'
            style={{ borderColor: `1px solid ${colors.neutral030} ` }}
          >
            <TableHead style={{ backgroundColor: colors.neutral030 }}>
              <TableRow>
                <ColumnHeader
                  style={{ textAlign: 'left', paddingLeft: '1rem' }}
                >
                  <FtrH3 style={{ fontWeight: 400 }}>Item ID</FtrH3>
                </ColumnHeader>
                {renderHeaders()}
                <ColumnHeader />
              </TableRow>
            </TableHead>
            <TableBody>{DISPLAY_ROWS?.map((row) => renderRow(row))}</TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      {renderDialogActions()}
    </Dialog>
  );

  function renderHeaders() {
    return tableData?.map((_item, index) => {
      return (
        <ColumnHeader align='center' key={_item?.itemID}>
          <FlexColumnCenter>{renderItemIDLink(_item?.itemID)}</FlexColumnCenter>
          {!isEmptyValue(_item?.itemID) &&
            _item?.itemID !== itemID &&
            index > 1 && <FtrB2>Most Recently Accepted Repeat Order</FtrB2>}
        </ColumnHeader>
      );
    });
  }

  function renderItemIDLink(_itemID) {
    if (isEmptyValue(_itemID)) {
      return <FtrB3>N.A.</FtrB3>;
    }

    return (
      <Link onClick={() => openInNewTab(`/item/edit/${_itemID}`)}>
        <FtrTypography type='subHeading' fontSize='16'>
          {_itemID}
        </FtrTypography>
      </Link>
    );
  }

  function renderRow(row) {
    return (
      <TableRow>
        <TableCell>
          <FtrH3 style={{ fontWeight: 400 }}>{row.label}</FtrH3>
        </TableCell>
        {tableData?.map((_item) => {
          return (
            <RowCell align='center' key={`${itemID} -${row.label} `}>
              {row.render(_item)}
            </RowCell>
          );
        })}
        {!row.check && <RowCell />}
        {row.check && (
          <RowCell>
            <InfoMatchValue
              name={row.label}
              valueCustomer={row.valueGetter(tableData[0])}
              valueSupplier={row.valueGetter(tableData[1])}
            />
          </RowCell>
        )}
      </TableRow>
    );
  }

  function renderDialogActions() {
    return (
      <DialogActions style={{ padding: '1rem 1.5rem' }}>
        <FtrButton color='blue' variant='contained' onClick={onClose}>
          OK
        </FtrButton>
      </DialogActions>
    );
  }
}

export default RepeatItemOrmDetailsPopup;
