import { isEqual } from 'lodash';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import PartUploadDragAndDropPresentation from './PartUploadDragAndDropPresentation';

import { updatePartUploadFormState } from '../../actions';

import { getSupportedFileTypesWithCache } from '../../apis/configurationApi';

import { isEmptyValue } from '../../utils/commonUtils';
import { isFileTooBig } from '../../utils/fileUploadUtils';
import { getFileExtension } from '../../utils/fileUtils';

import {
  ALL_FILES_TYPES,
  ALLOWED_DEFAULT_FILE_TYPES,
  TECHNOLOGY_OPTION_TYPE,
} from '../../constants/NewPartConstants';
import { ERROR_MESSAGE } from '../../constants/errorMessageConstants';

// --------------------------------------------------------------------------------

/**
 * PartUploadDragAndDropV2
 * This will allow PDF upload for every technology
 *
 * @param {object} props - The properties of the WhiteDragDrop component.
 * @param {bool} props.fullWidth - Occupies 100% width of the parent container.
 * @param {string} props.technology - Technology of the part, used to determine the supported file types.
 * @param {function} props.handleFiles - Callback function to handle the files.
 * @param {bool} props.isThreeDCADFileAvailable - Whether 3D CAD file is available.
 * @param {function} props.handleSupportedFileTypes - Callback function to handle the supported file types.
 * @param {array} props.unsupportedFileTypes - The unsupported file types.
 * @param {function} props.handleUnsupportedFileType - Callback function to handle the unsupported file types.
 * @param {bool} props.isAdditionalInfoShown - Whether to show additional information.
 * @param {bool} props.multiple - to handle single or multiple files
 * @param {bool} props.simpleLabel - To show simple label
 * @param {string} props.simpleLabelStyle - custom for css simple label
 * @param {bool} props.startIcon - start icon for simple label
 * @param {string} props.draggingLabelText - dragging label text for simple label
 * @param {string} props.labelText - label text for simple label
 *
 * @returns {React.ReactNode} The rendered WhiteDragDrop component.
 */
const PartUploadDragAndDropV2 = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const {
    technology = TECHNOLOGY_OPTION_TYPE.CNC_MACHINING,
    handleFiles,
    // handleSupportedFileTypes = () => { },
    handleUnsupportedFileType = () => {},
    multiple = true,
    deniedFileTypes = '',
    // isThreeDCADFileAvailable = true,
    ...rest
  } = props;

  const allFileTypes =
    useSelector((state) => state.pageSettings?.allFileTypesSupport) || {};

  const [unsupportedFileType, setUnsupportedFileType] = useState([]);
  const [error, setError] = useState('');

  const { data: supportedFileTypesByTech } = useQuery(
    ['getSupportedFileTypes', technology],
    () => {
      if (isEmptyValue(technology)) {
        return null;
      }
      return getSupportedFileTypesWithCache({ technology });
    }
  );

  const { supportFilesText, supportFileExtensions } = useMemo(() => {
    if (isEmptyValue(supportedFileTypesByTech)) {
      return '';
    }

    const { supportFileTypes } = supportedFileTypesByTech;

    const isAllFileTypesSupport = isEqual(supportFileTypes, ALL_FILES_TYPES);

    let fileTypes = isAllFileTypesSupport
      ? allFileTypes?.supportFileTypes
      : [...supportFileTypes, ...ALLOWED_DEFAULT_FILE_TYPES];

    if (!isEmptyValue(deniedFileTypes)) {
      const deniedFileTypeArray = deniedFileTypes
        ?.split(',')
        ?.map((type) => type.trim());
      fileTypes = fileTypes?.filter((o) => !deniedFileTypeArray.includes(o));
    }

    const _supportFileExtensions = fileTypes?.map((o) => `.${o}`)?.join(', ');

    const _supportFilesText = isAllFileTypesSupport
      ? 'All File Types'
      : _supportFileExtensions;

    return {
      supportFilesText: _supportFilesText,
      supportFileExtensions: _supportFileExtensions,
    };
  }, [supportedFileTypesByTech, allFileTypes, deniedFileTypes]);

  // Move the dispatch to an effect
  useEffect(() => {
    if (supportedFileTypesByTech) {
      const { supportFileTypes } = supportedFileTypesByTech;
      dispatch(
        updatePartUploadFormState({
          supportedFileTypes: isEqual(supportFileTypes, ALL_FILES_TYPES)
            ? allFileTypes?.supportFileTypes
            : [...supportFileTypes, ...ALLOWED_DEFAULT_FILE_TYPES],
        })
      );
    }
  }, [supportedFileTypesByTech, allFileTypes, dispatch]);

  useEffect(() => {
    setUnsupportedFileType([]);
    handleUnsupportedFileType([]);
  }, [supportFilesText]);

  useEffect(() => {
    if (!isEmptyValue(unsupportedFileType)) {
      const message =
        unsupportedFileType.length > 1
          ? `These file types aren't supported: ${unsupportedFileType?.join(
              ', '
            )}`
          : `This file type isn't supported: ${unsupportedFileType?.join(', ')}`;
      setError(message);
    }
  }, [unsupportedFileType]);

  const handleUploadFiles = (files) => {
    if (files.length > 1 && multiple === false) {
      setError('Multiple files uploading is not allowed.');
      return;
    }

    for (const file of files) {
      if (isFileTooBig(file.size)) {
        setError(ERROR_MESSAGE.FILE_SIZE_TOO_LARGE);
        return;
      }
    }

    const { supportFileTypes } = supportedFileTypesByTech;
    if (isEqual(supportFileTypes, ALL_FILES_TYPES)) {
      handleFiles(files);
      return;
    }

    const unSupportedFileTypes = new Set();
    const uploadFiles = [];
    for (const file of files) {
      const fileExtension = getFileExtension(file.name);
      const isSupported =
        supportFileTypes.includes(fileExtension) ||
        ALLOWED_DEFAULT_FILE_TYPES.includes(fileExtension);
      if (isSupported) {
        uploadFiles.push(file);
      } else {
        unSupportedFileTypes.add(fileExtension);
      }
    }
    setUnsupportedFileType(Array.from(unSupportedFileTypes));

    if (isEmptyValue(uploadFiles)) {
      return;
    }

    handleFiles(uploadFiles);
  };

  return (
    <PartUploadDragAndDropPresentation
      ref={ref}
      error={error}
      multiple={multiple}
      supportFilesText={supportFilesText}
      supportFileExtensions={supportFileExtensions}
      isAdditionalInfoShown
      setUnsupportedFileType={setUnsupportedFileType}
      handleUploadFiles={handleUploadFiles}
      setError={setError}
      {...rest}
    />
  );
});

PartUploadDragAndDropV2.displayName = 'PartUploadDragAndDropV2';

export default PartUploadDragAndDropV2;
