import { CsvBuilder } from 'filefy';
import { ceil } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';

import { makeStyles } from '@material-ui/core';

import { DataGrid } from '@mui/x-data-grid';

import CustomToolbar from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import DataGridWrapTextCell from '../../components/tables/cells/DataGridWrapTextCell';

import { getLoginHistoryInfo } from '../../apis/loginHistoryInfo';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import { generateCurrentCustomDateTimeString } from '../../util';
import { transformCsvExportDataGrid } from '../../utils/csvExportUtils';
import { formatDateWithTime } from '../../utils/dateTimeUtils';
import { convertCamelCaseToTitle } from '../../utils/stringUtils';

import { USER_AGENT_KEYS } from '../../constants/userConstant';

import { colors } from '../../palette';
import { FtrB2, FtrBanner } from '../../components/ftr-components';
import { FlexColumn, FlexRow } from '../../components/layouts/FlexLayouts';
import InfoIcon from '../../components/icons/InfoIcon';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    paddingTop: '1rem',
    marginBottom: '2rem',
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'center',
      whiteSpace: 'normal',
      justifyContent: 'center !important',
      maxHeight: '500px !important',
      lineHeight: 'normal !important',
    },
  },
}));

function LoginHistoryInfo() {
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [filteredData, { setSearchStr, setColumnsDef, setSourceData }] =
    useDataGridFilterHook({
      search: '',
      source: tableData,
    });

  const defaultColumns = [
    {
      headerName: 'Login Date Time',
      field: 'loginDateTime',
      renderCell: ({ row: rowData }) =>
        formatDateWithTime(rowData.loginDateTime),
      valueGetter: ({ row: rowData }) =>
        formatDateWithTime(rowData.loginDateTime),
      width: 140,
    },
    {
      headerName: 'Name',
      field: 'name',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={`${rowData.name} (${rowData.userID})`} />
      ),
      width: 140,
    },
    {
      headerName: 'Country',
      field: 'country',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.country} />
      ),
      width: 120,
    },
    {
      headerName: 'Region',
      field: 'region',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.region} />
      ),
      width: 120,
    },
    {
      headerName: 'IP Address',
      field: 'ipAddress',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.ipAddress} />
      ),
      width: 150,
    },
    {
      headerName: 'User Agent',
      field: 'userAgent',
      renderCell: ({ row: rowData }) => {
        return (
          <div
            style={{
              whiteSpace: 'pre-wrap',
              lineHeight: 'normal',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {Object.keys(rowData.userAgent || {})?.map((key) => {
              const value = rowData.userAgent[key];
              if ([USER_AGENT_KEYS.BROWSER, USER_AGENT_KEYS.OS].includes(key)) {
                return (
                  <span key={key}>
                    {convertCamelCaseToTitle(key)}: {value.name}, v.
                    {value.version}
                  </span>
                );
              }
              if (key === USER_AGENT_KEYS.DEVICE) {
                return (
                  <span key={key}>
                    {convertCamelCaseToTitle(key)}: {value.model} {value.vendor}
                  </span>
                );
              }
            })}
          </div>
        );
      },
      valueGetter: ({ row: rowData }) => {
        let result = [];
        Object.keys(rowData.userAgent || {})?.map((key) => {
          const value = rowData.userAgent[key];
          if ([USER_AGENT_KEYS.BROWSER, USER_AGENT_KEYS.OS].includes(key)) {
            result.push(
              `${convertCamelCaseToTitle(key)}: ${value.name}, v.${
                value.version
              }`
            );
          }
          if (key === USER_AGENT_KEYS.DEVICE) {
            result.push(
              `${convertCamelCaseToTitle(key)}: ${value.model}, ${value.vendor}`
            );
          }
        });
        return result?.join(' | ');
      },
      cellClassName: classes.customCell,
      width: 250,
    },
  ];

  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    getLoginHistoryInfo()
      .then((data) => setTableData(data))
      .catch((err) => console.log(err));
    const _columns = defaultColumns?.filter((col) => !!col);
    setColumns(_columns);
    setColumnsDef(_columns);
  }, []);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(tableData);
  }, [tableData]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleExportCSV = () => {
    const fileName = `Login History Info ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  const getToolbar = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          padding: '0.2rem 0.8rem',
          borderBottom: `1px solid ${colors.inputBorderGrey}`,
          flexWrap: 'wrap',
          gap: '1rem',
        }}
      >
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
          ]}
        />
        <ExportCsvButton handleClick={handleExportCSV} />
      </div>
    );
  };

  return (
    <div className={classes.body}>
      <FlexColumn style={{ gap: '1rem' }}>
        <FtrBanner type='info'>
          <FlexRow>
            <InfoIcon />
            <FtrB2>
              Query limit to last 3 months and max 10,000 rows for better
              performance. If you want to extract all data then contact dev
              team.
            </FtrB2>
          </FlexRow>
        </FtrBanner>
        {renderTable()}
      </FlexColumn>
    </div>
  );

  function renderTable() {
    return (
      <DataGrid
        autoHeight
        rows={filteredData}
        columns={columns?.map((col) => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.id}
        rowHeight={80}
        headerHeight={80}
        components={{
          Toolbar: getToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        rowsPerPageOptions={[10, 20, 50]}
        loading={tableQueryParams.loading}
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
      />
    );
  }
}

export default LoginHistoryInfo;
