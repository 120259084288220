import React, { useEffect, useRef, useState } from 'react';
import { FormControlLabel, Switch, makeStyles } from '@material-ui/core';

import PartUploadDragAndDropV2 from '../../PartUploadDragAndDrop/PartUploadDragAndDropV2';
import WhiteDragDropV2 from '../../WhiteDragDropV2';
import { FlexRow, FlexRowSpaceBetween } from '../../layouts/FlexLayouts';
import { FtrButton, FtrSmallButton, FtrTypography } from '../../ftr-components';

import secureUploadIcon from '../../../assets/icons/secure_upload.svg';
import TrashSvgIcon from '../../svg-icons/TrashSvgIcon';

import { FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING } from '../../../constants/featureFlagConstants';

// --------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  confidential: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
}));

function UploadFilesField(props) {
  const {
    handleFiles,
    isAdditionalInfoShown,
    fullWidth,
    handleUnsupportedFileType,
    id,
    multiple,
    handleSupportedFileTypes = () => {},
    technology,
    showRestorePartsButton,
    data,
    handleRestoreCachePartsUpload,
    handleClearAllParts,
    simpleLabel,
    draggingLabelText,
    labelText,
    simpleLabelStyle,
    deniedFileTypes = '',
    // unsupportedFileType = [],
    ...rest
  } = props;

  const classes = useStyles();
  const whiteDragDropRef = useRef(null);

  const [isThreeDCadFileAvailable, setIsThreeDCadFileAvailable] =
    useState(true);

  const [whiteDragDropWidth, setWhiteDragDropWidth] = useState(0);

  useEffect(() => {
    const _whiteDragDropWidth =
      whiteDragDropRef?.current?.getBoundingClientRect()?.width;
    setWhiteDragDropWidth(_whiteDragDropWidth ?? 0);
  }, [whiteDragDropRef?.current?.getBoundingClientRect()?.width]);

  const renderPartUploadComponent = () => {
    if (FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING !== 'true') {
      return (
        <WhiteDragDropV2
          id={id}
          ref={whiteDragDropRef}
          handleFiles={handleFiles}
          handleUnsupportedFileType={handleUnsupportedFileType}
          handleSupportedFileTypes={handleSupportedFileTypes}
          fullWidth={fullWidth}
          isAdditionalInfoShown={isAdditionalInfoShown}
          isThreeDCADFileAvailable={isThreeDCadFileAvailable}
          multiple={multiple}
          technology={technology}
          simpleLabel={simpleLabel}
          draggingLabelText={draggingLabelText}
          labelText={labelText}
          simpleLabelStyle={simpleLabelStyle}
        />
      );
    }

    return (
      <PartUploadDragAndDropV2
        id={id}
        ref={whiteDragDropRef}
        handleFiles={handleFiles}
        handleUnsupportedFileType={handleUnsupportedFileType}
        handleSupportedFileTypes={handleSupportedFileTypes}
        fullWidth={fullWidth}
        isAdditionalInfoShown={isAdditionalInfoShown}
        isThreeDCADFileAvailable={isThreeDCadFileAvailable}
        multiple={multiple}
        technology={technology}
        simpleLabel={simpleLabel}
        draggingLabelText={draggingLabelText}
        labelText={labelText}
        simpleLabelStyle={simpleLabelStyle}
        deniedFileTypes={deniedFileTypes}
      />
    );
  };

  const render3DCADFileSwitch = () => {
    return (
      <FormControlLabel
        id='cad-file-not-available'
        control={
          <Switch
            checked={!isThreeDCadFileAvailable}
            onChange={(e) => setIsThreeDCadFileAvailable(!e.target.checked)}
            name='threeDCadFile'
            color='primary'
          />
        }
        label='3D CAD file not available'
        style={{
          display:
            FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING === 'true'
              ? 'none'
              : 'block',
        }}
      />
    );
  };

  return (
    <div {...rest}>
      {renderPartUploadComponent()}
      {!simpleLabel && (
        <>
          <div className={classes.confidential}>
            <img
              src={secureUploadIcon}
              style={{ padding: '0 0.3rem' }}
              alt='logo'
            />
            <FtrTypography type='body' fontSize='14'>
              All your files are secure and confidential.
            </FtrTypography>
          </div>
          <FlexRowSpaceBetween
            style={{
              marginTop: whiteDragDropWidth > 850 ? '-1.75rem' : 0,
            }}
          >
            {render3DCADFileSwitch()}
            <FlexRow style={{ marginLeft: 'auto' }}>
              {showRestorePartsButton && (
                <FtrButton
                  variant='text'
                  color='blue'
                  onClick={handleRestoreCachePartsUpload}
                >
                  Restore last upload
                </FtrButton>
              )}
              {data?.length > 1 && (
                <FtrSmallButton
                  color='black'
                  variant='outlined'
                  startIcon={<TrashSvgIcon />}
                  onClick={handleClearAllParts}
                >
                  Clear all
                </FtrSmallButton>
              )}
            </FlexRow>
          </FlexRowSpaceBetween>
        </>
      )}
    </div>
  );
}

export default UploadFilesField;
