import { Cookies } from 'react-cookie';
import { get } from 'lodash';

import formulas from '../formulas/formulas';

import {
  getIsRepeatOrderFromIQLogMeta,
  getIsSimilarOrderFromIQLogMeta,
} from './itemUtils';
import { isEmptyValue } from './commonUtils';
import { reverseMapObject } from './objectUtils';

import { generatePPEPrice } from '../services/ppePriceService';

import { CNC_MATERIAL_CODE_MAPPING } from '../constants/PPEConstants';
import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';
import {
  DEFAULT_LEAD_TIME,
  DEFAULT_MARKUP_LEAD_TIME,
  USER_AGENT_INFO_KEY,
} from '../constants';

// -------------------------------------------------------------------------------------------------

export const getPpeMaterialCode = ({ technology, material }) => {
  let mapping = {};

  // remove 3DP material mapping due to PPE service update
  if (technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
    return material;
  }

  switch (technology) {
    case TECHNOLOGY_OPTION_TYPE.CNC_MACHINING: {
      mapping = CNC_MATERIAL_CODE_MAPPING;
      break;
    }
    default: {
      mapping = CNC_MATERIAL_CODE_MAPPING;
      break;
    }
  }
  return mapping[material];
};

export const getReverseMaterialFromPpeMaterialCode = ({
  technology,
  material,
}) => {
  let mapping = {};

  if (technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING) {
    return material;
  }

  switch (technology) {
    case TECHNOLOGY_OPTION_TYPE.CNC_MACHINING: {
      mapping = reverseMapObject(CNC_MATERIAL_CODE_MAPPING);
      break;
    }
    default: {
      mapping = reverseMapObject(CNC_MATERIAL_CODE_MAPPING);
      break;
    }
  }
  return mapping[material];
};

export const getPPEDimensions = (additionalParameters) => {
  const dimensions = additionalParameters.dimensions || additionalParameters;

  return {
    boundingBoxX: dimensions.boundingBoxX,
    boundingBoxY: dimensions.boundingBoxY,
    boundingBoxZ: dimensions.boundingBoxZ,
    weight: dimensions.weight,
    volume: dimensions.volume,
    surfaceArea: dimensions.surfaceArea,
  };
};

export const getItemHashcodeCad = (additionalParameters) => {
  const hashcode = get(
    additionalParameters,
    ['item_hashcode', 'cad'],
    []
  )?.join(',');

  return hashcode;
};
export const getItemHashcodePdf = (additionalParameters) => {
  const hashcode = get(
    additionalParameters,
    ['item_hashcode', 'pdf'],
    []
  )?.join(',');

  return hashcode;
};

export const getPpePriceForItem = async (item) => {
  let response = {};
  const cookie = new Cookies();
  try {
    const userAgentInfo = cookie.get(USER_AGENT_INFO_KEY);
    const ppePriceResponse = await generatePPEPrice(item, userAgentInfo);
    if (ppePriceResponse) {
      const totalPrice = isEmptyValue(ppePriceResponse.customerPrice)
        ? null
        : formulas.calculateTotalPrice(
            Number(ppePriceResponse.customerPrice),
            0,
            0,
            0
          );
      response = {
        ...response,
        price: isEmptyValue(ppePriceResponse.supplierPrice)
          ? null
          : Number(ppePriceResponse.supplierPrice),
        markupPercent: 0,
        totalPrice,
        originalPrice: isEmptyValue(
          get(ppePriceResponse, ['additionalParameters', 'price'])
        )
          ? null
          : Number(ppePriceResponse.additionalParameters.price),
        ppePriceLogId: ppePriceResponse.ppePriceLogId,
        ...getPPEDimensions(ppePriceResponse.additionalParameters),
        rmpa: ppePriceResponse.rmpa,
        rmlta: ppePriceResponse.rmlta,
      };

      // api v2 processing
      const { additionalParameters } = ppePriceResponse;
      if (
        Object.keys(additionalParameters).includes('rom_order') ||
        Object.keys(additionalParameters).includes('match_type')
      ) {
        response = {
          ...response,
          itemHashcodeCad: getItemHashcodeCad(additionalParameters),
          itemHashcodePdf: getItemHashcodePdf(additionalParameters),
          repeatOrder: getIsRepeatOrderFromIQLogMeta(additionalParameters),
          similarOrder: getIsSimilarOrderFromIQLogMeta(additionalParameters),
          refPartID: Number(get(additionalParameters, ['ref_part_id'])),
          refQuoteID: Number(get(additionalParameters, ['ref_quote_id'])),
          complexity: Number(get(additionalParameters, ['complexity'])),
          leadTime: Number(
            get(additionalParameters, ['lead_time']) || DEFAULT_LEAD_TIME
          ),
          markupLeadTime: Number(
            get(additionalParameters, ['markup_lead_time']) ||
              DEFAULT_MARKUP_LEAD_TIME
          ),
          bulkPricing: get(additionalParameters, ['price_qty']),
          sameRepeatOwner:
            ppePriceResponse.sameRepeatOwner ?? item.sameRepeatOwner,
          error: get(additionalParameters, ['error']),
          success: get(additionalParameters, ['success']),
          fallOutReasons: ppePriceResponse.fallOutReasons,
          mappedFallOutReasons: ppePriceResponse.mappedFallOutReasons,
          mappedPpeErrors: ppePriceResponse.mappedPpeErrors,
        };
      }
    }
  } catch (e) {
    // do nothing
    console.error(e);
  }

  return response;
};

export const makePpeItemRfq = (item) => {
  item.initialPrice = item.price;
  item.initialMarkupPercent = item.markupPercent;
  item.initialTotalPrice = item.totalPrice;
  item.initialOriginalPrice = item.originalPrice;
  item.price = null;
  item.markupPercent = null;
  item.totalPrice = null;
  item.originalPrice = null;
  return item;
};

export const revertRfqToPpeItem = (item) => {
  item.price = item.initialPrice;
  item.markupPercent = item.initialMarkupPercent;
  item.totalPrice = item.initialTotalPrice;
  item.originalPrice = item.initialOriginalPrice;
  return item;
};
