import React, { useMemo } from 'react';

import { FtrWarningIconTooltip } from '../ftr-components/FtrTooltip';

import { isEmptyValue } from '@utils/commonUtils';
import { getToleranceDisplayText } from '@utils/toleranceUtils';

import {
  CONFIG_FIELDS_NAME_MAPPING,
  CONFIG_FIELDS_ORIGIN,
} from '@constants/itemConstants';

// -------------------------------------------------------------------------------------------------

/**
 * @param {object} props
 * @param {string} props.field
 * @param {string} props.recommendedValue
 * @param {string} props.userInputValue
 * @param {number} props.iconSize
 */
function DifferentConfigWarningIcon(props) {
  const {
    field = '',
    recommendedConfigsOrigin = '',
    recommendedValue = '',
    userInputValue = '',
    technologyMatch = true,
    unitType = 'mm',
  } = props;

  const toolTipTitle = useMemo(() => {
    let displayUserInputValue = userInputValue;
    let displayRecommendedValue = recommendedValue;

    switch (field) {
      case CONFIG_FIELDS_NAME_MAPPING.TOLERANCE:
        displayUserInputValue = getToleranceDisplayText({
          tolerance: userInputValue,
          unitType: unitType,
        });
        displayRecommendedValue = getToleranceDisplayText({
          tolerance: recommendedValue,
          unitType: unitType,
        });
        break;
      case CONFIG_FIELDS_NAME_MAPPING.THREED_INFILL:
        displayUserInputValue = `${Math.round(userInputValue * 100)}%`;
        displayRecommendedValue = `${Math.round(recommendedValue * 100)}%`;
        break;
      case CONFIG_FIELDS_NAME_MAPPING.THREE_D_LAYER_THICKNESS:
        displayUserInputValue = `${userInputValue}mm`;
        displayRecommendedValue = `${recommendedValue}mm`;
        break;
    }

    let recommendedConfigsOriginString = '';

    switch (recommendedConfigsOrigin) {
      case CONFIG_FIELDS_ORIGIN.TDE:
        recommendedConfigsOriginString = "extractor's analysis";
        break;
      case CONFIG_FIELDS_ORIGIN.ORDER_AGAIN:
        recommendedConfigsOriginString = 'previously saved configs';
        break;
    }

    return `Selected ${field} (${displayUserInputValue}) does not match our ${recommendedConfigsOriginString} (${displayRecommendedValue}) of your technical drawing. Please verify this change before finalizing part.`;
  }, [field, recommendedConfigsOrigin, userInputValue, recommendedValue]);

  return (
    !isEmptyValue(recommendedValue) &&
    recommendedValue !== userInputValue &&
    !!technologyMatch && (
      <FtrWarningIconTooltip description={toolTipTitle} arrow />
    )
  );
}

export default DifferentConfigWarningIcon;
