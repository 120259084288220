import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { uniq } from 'lodash';
import clsx from 'clsx';
import { useQueryClient } from 'react-query';

import { Card, CardActionArea, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';

import { getDaysElapsed } from '../../utils/dateTimeUtils';

import DaysElapsed from '../labels/DaysElapsed';
import { colors } from '../../palette';
import DisplayItemName from './DisplayItemName';
import DisplayItemTech from './DisplayItemTech';
import ProjectImageCollection from '../images/ProjectImageCollection';
import { ITEM_QUOTE_TYPE } from '../../constants/projectConstants';
import { WATCHING_JOB_STATUS } from '../../constants/watchingJob';
import { WatchingJob } from '../WatchingJob';
import { NotInterestedJob } from '../NotInterestedJob';

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: '21.6rem',
    maxWidth: '21.6rem',
    minHeight: '26.2rem',
    maxHeight: '26.2rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: '18.8rem',
      maxWidth: '18.8rem',
      minHeight: '25.3rem',
      maxHeight: '25.3rem',
    },
  },
  quotedTextWrapper: {
    zIndex: 10,
    position: 'absolute',
    top: 30,
    right: 0,
    backgroundColor: colors.quotedLabelBlue,
    padding: '10px 20px 10px 20px',
    fontSize: 16,
    color: colors.fontWhite,
    boxShadow: `5px 4px 8px ${colors.bgGrey}`,
    fontWeight: 'bold',
    backgroundImage: colors.colorBlueGradient,
    borderRadius: '8px 0px 0px 8px',
  },
  newLabelWrapper: {
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid ${colors.hotRed}`,
    borderRadius: '4px',
  },
  newTextWrapper: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: colors.hotRed,
  },
  cardItemNameWrapper: {
    width: '265px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
    columnGap: '2px',
  },
  cardTechWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    alignItems: 'center',
    columnGap: '2px',
  },
  cardItemContainer: {
    maxWidth: '340px',
  },
  cardDisplay: {
    display: 'flex',
  },
  projectLabel: {
    padding: '5px 0px 5px 0px',
    fontSize: '10pt',
    color: colors.fontBlackGrey,
    borderRadius: 8,
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  projectJob: {
    backgroundColor: colors.projectJob,
    color: 'white',
  },
  confirmedProjectJob: {
    backgroundColor: colors.confirmedProjectJob,
    color: 'white',
  },
  watchLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: colors.lightGray,
    zIndex: 1,
    border: `2px solid ${colors.lightGray}`,
    borderRadius: '4px',
    width: '40px',
    height: '18px',
    justifyContent: 'center',
    margin: '5px 0',
  },
  watchLabelActive: {
    borderColor: colors.blue050,
  },
  containerLabel: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px',
    justifyContent: 'end',
    width: '100%',
    flex: 1,
  },
}));

function JobLabelDisplay(props) {
  const classes = useStyles();

  const { items, noOfComponents } = props;
  const isConfirmedProjectJob = items?.every(
    (item) => item.itemQuoteType === ITEM_QUOTE_TYPE.PPE
  );
  const title = isConfirmedProjectJob ? `Confirmed Project Job` : `Project Job`;
  const styleClass = isConfirmedProjectJob
    ? classes.confirmedProjectJob
    : classes.projectJob;

  return (
    <div>
      <div className={clsx(classes.projectLabel, styleClass)}>
        <span>{title}:&nbsp;</span>
        <b>
          {noOfComponents} {noOfComponents > 1 ? 'Components' : 'Component'}
        </b>
      </div>
    </div>
  );
}

function ProjectItemDisplay(props) {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { project } = props;
  const { projectID, projectType, items } = project || {};
  const item = items[0];
  const { verifiedDate } = item || {};
  const itemIDs = items?.map((_item) => _item.itemID);

  const [isWatching, setIsWatching] = useState(false);

  useEffect(() => {
    const watchingJobs = uniq(items?.map((_item) => _item.watchingJobStatus));
    setIsWatching(
      watchingJobs.length === 1 &&
        watchingJobs[0] === WATCHING_JOB_STATUS.ACTIVE
    );
  }, [items]);

  const daysElapsed = getDaysElapsed(verifiedDate);

  return (
    <div className={classes.cardItemContainer}>
      <div className={classes.cardDisplay}>
        <div className={classes.cardItemNameWrapper}>
          <DisplayItemName text={`Project ${projectID}`} />
          {daysElapsed <= 3 && (
            <div className={classes.newLabelWrapper}>
              <div className={classes.newTextWrapper}>new</div>
            </div>
          )}
        </div>
        <div className={classes.containerLabel}>
          <WatchingJob
            isWatching={isWatching}
            setIsWatching={setIsWatching}
            itemIDs={itemIDs}
            projectID={projectID}
          />
        </div>
      </div>
      <div className={classes.cardTechWrapper}>
        <DisplayItemTech text={item.technology} />
        <div className={classes.containerLabel}>
          <NotInterestedJob
            itemIDs={itemIDs}
            projectID={projectID}
            isWatching={isWatching}
            callbackSuccess={async () => {
              await queryClient.invalidateQueries('projectItems');
              queryClient.refetchQueries('projectItems');
            }}
          />
        </div>
      </div>
      <DaysElapsed daysElapsed={daysElapsed} />
      <JobLabelDisplay
        items={items}
        projectType={projectType}
        noOfComponents={project.items.length}
      />
    </div>
  );
}

function ProjectItemDisplayCard(props) {
  const classes = useStyles();

  const { project } = props;
  const { items } = project;
  const technology = items[0].technology;
  const isCuratedTask = items?.[0]?.isCuratedTask;
  const hasQuote = items?.some((item) => item.hasQuote);

  const renderQuotedLabel = () => {
    return <div className={classes.quotedTextWrapper}>Quoted</div>;
  };

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <Link
          to={{
            pathname: `/projects/${project.projectID}/quote`,
            search: `${queryString.stringify({
              technology,
              curatedForYou: isCuratedTask,
            })}`,
          }}
          style={{ textDecoration: 'none' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ProjectImageCollection items={items} />
            {hasQuote && renderQuotedLabel()}
          </div>
          <CardContent>
            <ProjectItemDisplay project={project} />
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
}

export default ProjectItemDisplayCard;
