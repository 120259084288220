import React, { useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Chip } from '@material-ui/core';

import { Close } from '@material-ui/icons';

import { FlexColumn, FlexRow } from '../layouts/FlexLayouts';
import { FtrBoldText, FtrDropdownV2, FtrErrorText } from '../ftr-components';

import { colors } from '../../palette';

// ------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    boxSizing: 'border-box',
  },
  field: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    boxSizing: 'border-box',
  },
  chip: {
    margin: 2,
    backgroundColor: colors.blue020,
    fontSize: 16,
    color: colors.blue050,
    fontWeight: 600,
    padding: 5,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  menuItem: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: colors.menuItemHover,
    },
  },
  menuItemSelected: {
    '&.Mui-selected': {
      backgroundColor: colors.menuItemSelected,
      '&:hover': {
        backgroundColor: colors.menuItemSelected,
      },
    },
  },
  chipDeleteIcon: {
    color: colors.blue040,
    '&:hover': {
      color: colors.blue040,
    },
  },
  chipDeletable: {
    '&:focus': {
      backgroundColor: colors.menuItemSelected,
    },
  },
  closeButton: {
    marginLeft: '0.3rem',
    marginRight: '0.3rem',
    '&:hover': {
      backgroundColor: colors.expansionBackgroundColor,
      color: colors.blue050,
    },
  },
}));

const DEFAULT_ITEM_LIST = [
  {
    key: 'Oliver Hansen',
    text: 'Oliver Hansen',
  },
  {
    key: 'Van Henry',
    text: 'Van Henry',
  },
  {
    key: 'April Tucker',
    text: 'April Tucker',
  },
];

function FtrMultiSelectChipDropdown(props) {
  const classes = useStyles();
  const inputRef = useRef();

  const {
    id = 'multi-select-chip-dropdown',
    itemList = DEFAULT_ITEM_LIST,
    selectedItems = [],
    unselectedItemList = [],
    showUnselectedItemList = false,
    label = 'Select',
    onSelect = () => {},
    customMenuItem,
    placeHolder = 'Select',
    error,
    errorMessage,
  } = props;

  const displayedItems = showUnselectedItemList ? unselectedItemList : itemList;

  const handleChange = (newItem) => {
    const newSelectedItems = [...selectedItems, newItem.key];
    if (typeof onSelect === 'function') {
      onSelect(newSelectedItems);
    }

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleChipDelete = (key) => {
    const newSelectedItems = selectedItems?.filter((item) => item !== key);
    if (typeof onSelect === 'function') {
      onSelect(newSelectedItems);
    }
  };

  const renderSelectedItemList = () => {
    return (
      <FlexRow style={{ flexWrap: 'wrap' }}>
        {selectedItems?.map((key) => {
          const item = itemList?.find((_item) => _item.key === key);
          const _label = item ? item.text : '';

          return (
            <Chip
              classes={{
                deleteIcon: classes.chipDeleteIcon,
                deletable: classes.chipDeletable,
              }}
              className={classes.chip}
              key={key}
              label={_label}
              onMouseDown={(event) => event.stopPropagation()}
              onDelete={() => handleChipDelete(key)}
              deleteIcon={<Close />}
              size='medium'
              style={{
                height: 40,
                borderRadius: 20,
              }}
            />
          );
        })}
      </FlexRow>
    );
  };

  return (
    <FlexColumn id={id}>
      {label && (
        <FtrBoldText
          style={{
            color: colors.neutral070,
          }}
        >
          {label}
        </FtrBoldText>
      )}
      <FtrDropdownV2
        items={displayedItems}
        customMenuItem={customMenuItem}
        handleChange={handleChange}
        placeholder={placeHolder}
      />
      {renderSelectedItemList()}
      {error && errorMessage && <FtrErrorText>{errorMessage}</FtrErrorText>}
    </FlexColumn>
  );
}

export default FtrMultiSelectChipDropdown;
