import React, { useEffect, useReducer } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { Cancel } from '@material-ui/icons';

import { Alert } from '@material-ui/lab';

import { adminAllowedDeniedSuppliersForItem } from '../../apis/itemApi';
import { updateAllowedDeniedSuppliersForItems } from '../../apis/viewJobsControlApi';

import { groupUsersByKeyForDropDown } from '../../utils/selectUserUtils';

import { notifySuccess } from '../../services/notificationService';

import InfoIcon from '../icons/InfoIcon';

import BlueButton from '../buttons/BlueButton';
import Title from '../Title';
import UpdateAllowedDeniedSuppliersPopup from './UpdateAllowedDeniedSuppliersPopup';
import YesNoPopup from './YesNoPopup';

import { colors } from '../../palette';
import DeleteWithConfirmButton from '../buttons/DeleteWithConfirmButton';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 200,
    borderRadius: 0,
  },
  space: {
    width: theme.spacing(2),
  },
}));

function ViewItemAllowedDeniedSuppliersPopup(props) {
  const classes = useStyles();

  const { open, handleClose = () => {}, itemID, locationVisibility } = props;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      allowedSuppliers: [],
      deniedSuppliers: [],
      availableToAllow: [],
      availableToDeny: [],
    }
  );
  const [
    updateAllowedDeniedSuppliersPopupState,
    updateUpdateAllowedDeniedSuppliersPopupState,
  ] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      updateMode: 'ADD',
    }
  );
  const [removeSupplierPopupState, updateRemoveSupplierPopupState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      supplierID: null,
      supplierName: null,
    }
  );
  const [selectForRemoveState, updateSelectForRemoveState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      removeFromAllowList: [],
      removeFromDenyList: [],
    }
  );

  const loadData = () => {
    adminAllowedDeniedSuppliersForItem(itemID).then((data) => {
      updateLocalState({
        ...data,
      });
    });
  };

  useEffect(() => {
    loadData();
  }, [itemID]);

  const createRequestBody = (updateMode, selectedAccountIds) => ({
    itemID,
    updateMode,
    selectedAccountIds,
  });

  const renderBody = () => {
    return (
      <div>
        <div
          style={{
            paddingBottom: '1rem',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'start',
            gap: '1rem',
          }}
        >
          <Alert
            style={{
              boxSizing: 'border-box',
              flex: '1 1 auto',
              minWidth: 500,
            }}
          >
            All Category 0 suppliers can view this part
          </Alert>
          {locationVisibility && (
            <div
              style={{
                // paddingBottom: '1rem',
                boxSizing: 'border-box',
                display: 'flex',
                flex: '1 1 auto',
                maxWidth: 500,
              }}
            >
              <Typography variant='subtitle2' style={{ fontStyle: 'italic' }}>
                Location Visibility:&nbsp;
              </Typography>
              <Typography
                variant='subtitle2'
                style={{
                  color: colors.blue050,
                  flexWrap: 'wrap',
                  maxWidth: 250,
                  fontStyle: 'italic',
                  textAlign: 'left',
                }}
              >
                {locationVisibility?.split(',')?.join(', ')}
              </Typography>
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            gap: '2rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '0.5rem',
              minWidth: 300,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  style={{
                    fontWeight: 600,
                    color: colors.successGreen,
                  }}
                  variant='h6'
                >
                  Allowed Suppliers
                </Typography>
                &nbsp;
                <InfoIcon toolTipText='Suppliers in this list are allowed to view this part regardless of RFQ status' />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                <Tooltip title='Add supplier to allowed list'>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      updateUpdateAllowedDeniedSuppliersPopupState({
                        open: true,
                        updateMode: 'ADD_ALLOWED',
                      });
                    }}
                    data-cy='add-supplier-to-allowed-list'
                  >
                    Add
                  </Button>
                </Tooltip>
                {localState.allowedSuppliers?.filter(
                  (supplier) => supplier !== 'All suppliers pool'
                ).length > 0 && (
                  <DeleteWithConfirmButton
                    label={
                      selectForRemoveState.removeFromAllowList.length > 0
                        ? 'Delete'
                        : 'Delete All'
                    }
                    onClick={() => {
                      const removeAllowedSuppliers = createRequestBody(
                        'REMOVE_ALLOWED',
                        selectForRemoveState.removeFromAllowList.length > 0
                          ? selectForRemoveState.removeFromAllowList
                              ?.filter(
                                (supplier) =>
                                  supplier.allowedType !== 'Same Vertical'
                              )
                              ?.map((supplier) => supplier.userID)
                          : localState.allowedSuppliers
                              ?.filter(
                                (supplier) => supplier !== 'All suppliers pool'
                              )
                              ?.filter(
                                (supplier) =>
                                  supplier.allowedType !== 'Same Vertical'
                              )
                              ?.map((supplier) => supplier.userID)
                      );
                      const addDeniedSuppliers = createRequestBody(
                        'ADD_DENIED',
                        selectForRemoveState.removeFromAllowList.length > 0
                          ? selectForRemoveState.removeFromAllowList
                              ?.filter(
                                (supplier) =>
                                  supplier.allowedType === 'Same Vertical'
                              )
                              ?.map((supplier) => supplier.userID)
                          : localState.allowedSuppliers
                              ?.filter(
                                (supplier) => supplier !== 'All suppliers pool'
                              )
                              ?.filter(
                                (supplier) =>
                                  supplier.allowedType === 'Same Vertical'
                              )
                              ?.map((supplier) => supplier.userID)
                      );

                      Promise.all([
                        updateAllowedDeniedSuppliersForItems(
                          removeAllowedSuppliers
                        ),
                        updateAllowedDeniedSuppliersForItems(
                          addDeniedSuppliers
                        ),
                      ]).then(() => {
                        notifySuccess(
                          `Allow/Deny Suppliers List has been updated successfully`
                        );
                        updateSelectForRemoveState({
                          removeFromAllowList: [],
                          removeFromDenyList: [],
                        });
                        loadData();
                      });
                    }}
                    width={110}
                    toolTip='Remove all from Allowed List'
                  />
                )}
              </div>
            </div>
            <Divider style={{ width: '100%' }} />
            {localState.allowedSuppliers.length <= 0 && (
              <Alert
                style={{
                  width: '100%',
                  boxSizing: 'border-box',
                }}
              >
                Supplier list is empty
              </Alert>
            )}
            {localState.allowedSuppliers.includes('All suppliers pool') && (
              <Alert
                style={{
                  width: '100%',
                  boxSizing: 'border-box',
                }}
              >
                All suppliers pool
              </Alert>
            )}
            {localState.allowedSuppliers?.filter(
              (supplier) => supplier !== 'All suppliers pool'
            ).length > 0 && (
              <div
                style={{
                  maxHeight: '50vh', // Set the maximum height for the container
                  overflowY: 'auto', // Enable vertical scrolling
                  border: '1px solid #ccc', // Optional: add a border for visual indication
                  padding: '1rem', // Optional: add some padding
                  marginTop: localState.allowedSuppliers.includes(
                    'All suppliers pool'
                  )
                    ? '0px'
                    : '55px',
                }}
              >
                {localState.allowedSuppliers
                  ?.filter((supplier) => supplier !== 'All suppliers pool')
                  ?.map((supplier) => {
                    return (
                      <div
                        key={supplier.userID}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectForRemoveState.removeFromAllowList.includes(
                                supplier
                              )}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  updateSelectForRemoveState({
                                    removeFromAllowList: [
                                      ...selectForRemoveState.removeFromAllowList,
                                      supplier,
                                    ],
                                  });
                                } else {
                                  updateSelectForRemoveState({
                                    removeFromAllowList: [
                                      ...(selectForRemoveState.removeFromAllowList?.filter(
                                        (user) =>
                                          user.userID !== supplier.userID
                                      ) || []),
                                    ],
                                  });
                                }
                              }}
                              style={{
                                padding: 0,
                                marginRight: 6,
                              }}
                              name='checkedDifferentAddress'
                            />
                          }
                          label={`${supplier.name} <${supplier.email}> (${supplier.allowedType})`}
                          style={{
                            margin: '0 6px',
                          }}
                        />
                        {['Same Vertical'].includes(supplier.allowedType) && (
                          <Tooltip title='Add to Denied Suppliers'>
                            <IconButton
                              edge='end'
                              aria-label='delete'
                              onClick={() => {
                                const body = createRequestBody('ADD_DENIED', [
                                  supplier.userID,
                                ]);
                                updateAllowedDeniedSuppliersForItems(body).then(
                                  () => {
                                    notifySuccess(
                                      `Allow/Deny Suppliers List has been updated successfully`
                                    );
                                    updateSelectForRemoveState({
                                      removeFromAllowList: [],
                                      removeFromDenyList: [],
                                    });
                                    loadData();
                                  }
                                );
                              }}
                              size='small'
                            >
                              <Cancel
                                color='error'
                                style={{ fontSize: '15pt' }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        {[
                          'Added Manually',
                          'RFQed',
                          'RfqVerifiedAuto',
                        ].includes(supplier.allowedType) && (
                          <Tooltip title='Remove'>
                            <IconButton
                              edge='end'
                              aria-label='delete'
                              onClick={() => {
                                updateRemoveSupplierPopupState({
                                  open: true,
                                  supplierID: supplier.userID,
                                  updateMode: 'REMOVE_ALLOWED',
                                  supplierName: `${supplier.name} <${supplier.email}>`,
                                });
                              }}
                              size='small'
                            >
                              <Cancel
                                color='error'
                                style={{ fontSize: '15pt' }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '0.5rem',
              minWidth: 300,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  style={{
                    fontWeight: 600,
                    color: colors.errorRed,
                  }}
                  variant='h6'
                >
                  Denied Suppliers
                </Typography>
                &nbsp;
                <InfoIcon toolTipText='Suppliers in this list are not allowed to view this part permanently' />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                <Tooltip title='Add supplier to denied list'>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      updateUpdateAllowedDeniedSuppliersPopupState({
                        open: true,
                        updateMode: 'ADD_DENIED',
                      });
                    }}
                  >
                    Add
                  </Button>
                </Tooltip>
                {localState.deniedSuppliers.length > 0 && (
                  <DeleteWithConfirmButton
                    label={
                      selectForRemoveState.removeFromDenyList.length > 0
                        ? 'Delete'
                        : 'Delete All'
                    }
                    onClick={() => {
                      let body;
                      if (selectForRemoveState.removeFromDenyList.length > 0) {
                        body = createRequestBody(
                          'REMOVE_DENIED',
                          selectForRemoveState.removeFromDenyList?.map(
                            (supplier) => supplier.userID
                          )
                        );
                      } else {
                        body = createRequestBody(
                          'REMOVE_DENIED',
                          localState.deniedSuppliers?.map(
                            (supplier) => supplier.userID
                          )
                        );
                      }
                      updateAllowedDeniedSuppliersForItems(body).then(() => {
                        notifySuccess(
                          `Allow/Deny Suppliers List has been updated successfully`
                        );
                        updateSelectForRemoveState({
                          removeFromAllowList: [],
                          removeFromDenyList: [],
                        });
                        loadData();
                      });
                    }}
                    width={110}
                    toolTip='Remove all from Denied List'
                  />
                )}
              </div>
            </div>
            <Divider style={{ width: '100%' }} />
            {localState.deniedSuppliers.length <= 0 && (
              <Alert
                style={{
                  width: '100%',
                  boxSizing: 'border-box',
                }}
              >
                Supplier list is empty
              </Alert>
            )}
            {localState.deniedSuppliers.length > 0 && (
              <div
                style={{
                  maxHeight: '50vh', // Set the maximum height for the container
                  overflowY: 'auto', // Enable vertical scrolling
                  border: '1px solid #ccc', // Optional: add a border for visual indication
                  padding: '1rem', // Optional: add some padding
                  marginTop: '55px',
                }}
              >
                {localState.deniedSuppliers?.map((supplier) => {
                  const supplierNameText = `${supplier.name} <${
                    supplier.email
                  }> (${supplier.allowedType ?? 'Added Manually'})`;

                  const renderCheckBox = (_supplier) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectForRemoveState.removeFromDenyList.includes(
                              _supplier
                            )}
                            onChange={(e) => {
                              if (e.target.checked) {
                                updateSelectForRemoveState({
                                  removeFromDenyList: [
                                    ...selectForRemoveState.removeFromDenyList,
                                    _supplier,
                                  ],
                                });
                              } else {
                                updateSelectForRemoveState({
                                  removeFromDenyList: [
                                    ...(selectForRemoveState.removeFromDenyList?.filter(
                                      (user) => user.userID !== _supplier.userID
                                    ) || []),
                                  ],
                                });
                              }
                            }}
                            style={{
                              padding: 0,
                              marginRight: 6,
                            }}
                            name='checkedDifferentAddress'
                          />
                        }
                        label={supplierNameText}
                        style={{
                          margin: '0 6px',
                        }}
                      />
                    );
                  };

                  return (
                    <div
                      key={supplier.userID}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {['Added Manually', 'RFQed', 'RfqVerifiedAuto'].includes(
                        supplier.allowedType
                      ) ? (
                        renderCheckBox(supplier)
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box style={{ width: 36 }} />
                          <Typography>{supplierNameText}</Typography>
                        </div>
                      )}
                      {['Added Manually', 'RFQed', 'RfqVerifiedAuto'].includes(
                        supplier.allowedType
                      ) && (
                        <Tooltip title='Remove'>
                          <IconButton
                            edge='end'
                            aria-label='delete'
                            onClick={() => {
                              updateRemoveSupplierPopupState({
                                open: true,
                                supplierID: supplier.userID,
                                updateMode: 'REMOVE_DENIED',
                                supplierName: `${supplier.name} <${supplier.email}>`,
                              });
                            }}
                            size='small'
                          >
                            <Cancel
                              color='error'
                              style={{ fontSize: '15pt' }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Dialog
        maxWidth='xl'
        open={open}
        onClose={handleClose}
        aria-labelledby='confirmation-dialog-title'
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id='confirmation-dialog-title'>
          <Title
            contentTitle={`Supplier visibility for Item ID ${itemID}`}
            size='small'
          />
        </DialogTitle>
        <div
          style={{
            paddingBottom: '1rem',
          }}
        >
          <DialogContent
            style={{
              padding: '0 30px',
              paddingBottom: '2rem',
              textAlign: 'center',
            }}
          >
            <React.Fragment>{renderBody()}</React.Fragment>
          </DialogContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <BlueButton
              onBtnClick={() => {
                handleClose();
              }}
              btnContent='OK'
              id='add-supplier-visibility-button'
            />
          </div>
        </div>
      </Dialog>
      {updateAllowedDeniedSuppliersPopupState.open && (
        <UpdateAllowedDeniedSuppliersPopup
          open={updateAllowedDeniedSuppliersPopupState.open}
          itemID={itemID}
          updateMode={updateAllowedDeniedSuppliersPopupState.updateMode}
          handleClose={() =>
            updateUpdateAllowedDeniedSuppliersPopupState({ open: false })
          }
          availableToAllow={groupUsersByKeyForDropDown(
            localState.availableToAllow,
            'role'
          )}
          availableToDeny={groupUsersByKeyForDropDown(
            localState.availableToDeny,
            'role'
          )}
          handleAddCallback={loadData}
        />
      )}
      {removeSupplierPopupState.open && (
        <YesNoPopup
          open={removeSupplierPopupState.open}
          handleNo={() => updateRemoveSupplierPopupState({ open: false })}
          handleYes={() => {
            const body = createRequestBody(
              removeSupplierPopupState.updateMode,
              [removeSupplierPopupState.supplierID]
            );
            updateAllowedDeniedSuppliersForItems(body).then(() => {
              notifySuccess(
                `Allow/Deny Suppliers List has been updated successfully`
              );
              loadData();
            });
            updateRemoveSupplierPopupState({ open: false });
          }}
          body={`${removeSupplierPopupState.supplierName}`}
          title='Do you want to remove this supplier?'
        />
      )}
    </div>
  );
}

export default ViewItemAllowedDeniedSuppliersPopup;
