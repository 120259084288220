import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';
import { Dialog, DialogContent } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FtrH6, FtrTypography } from '../ftr-components';

import { colors } from '../../palette';
import { FlexColumn } from '../layouts/FlexLayouts';
import {
  FAILURE_REASON,
  FAILURE_REASON_MAPPING,
  AUTOMATION_FAILURE_POPUP,
  AUTOMATION_FLOW,
} from '../../constants/automationConstants';
import { Link } from 'react-router-dom';
import { isEmptyValue } from '../../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    borderRadius: '1.5rem',
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

const AutomationFailuresPopup = ({ open, failures, type, onClose }) => {
  const classes = useStyles();

  const getActionPath = (_type, projectID) => {
    if (isEmptyValue(projectID)) {
      return '/';
    }

    let path;
    switch (_type) {
      case AUTOMATION_FLOW.RFQ:
        path = `/manage-projects/${projectID}/rfq-email-schedules`;
        break;
      case AUTOMATION_FLOW.SUPPLIER_TRACKER:
        path = `/projects/${projectID}/quote`;
        break;
      case AUTOMATION_FLOW.QUOTE_VERIFIER:
        path = `/verify-quotes`;
        break;
      case AUTOMATION_FLOW.PO_ACKNOWLEDGEMENT:
        path = `/accepted-orders`;
        break;
      default:
        path = `/manage-projects/${projectID}`;
        break;
    }

    return path;
  };

  const getActionLabel = (_type, failure) => {
    let label;

    if (isEmptyValue(failure)) {
      return '';
    }

    switch (failure.reason) {
      // only show project ID for this failure reason
      case FAILURE_REASON.INVALID_ITEM:
      case FAILURE_REASON.OMS_DOWN:
      case FAILURE_REASON.EMAIL_SENDING_FAILED:
        label = `
          Project ID: ${failure.projectID} -
          ${FAILURE_REASON_MAPPING[failure.reason]}
        `;
        break;
      // also show the associated item ids for this case
      case FAILURE_REASON.GROUP_NOT_FOUND:
      case FAILURE_REASON.SUPPLIER_NOT_FOUND:
      case FAILURE_REASON.EMAIL_NOT_RECEIVED:
      case FAILURE_REASON.PO_EMAIL_NOT_RECEIVED:
        label = `
          Project ID: ${failure.projectID} -
          ${FAILURE_REASON_MAPPING[failure.reason]}

          (Item IDs: ${failure?.relatedRecords?.itemIDs?.join(', ')})
        `;
        break;
      // also show the associated item ids + supplier name for this case
      case FAILURE_REASON.RFQ_PARTIAL_ACCEPTANCE:
      case FAILURE_REASON.RFQ_ITEM_REJECTED:
      case FAILURE_REASON.RFQ_UNVERIFIED_SUPPLIER_ACCEPTANCE:
      case FAILURE_REASON.RFQ_LEADTIME_FEEDBACK:
      case FAILURE_REASON.RFQ_PRICE_FEEDBACK:
      case FAILURE_REASON.QUOTATION_CUSTOMER_PO_NOT_RECONCILED:
        label = `
          Project ID: ${failure.projectID} -
          ${FAILURE_REASON_MAPPING[failure.reason]}

          (Item IDs: ${failure?.relatedRecords?.itemIDs?.join(', ')} | Supplier: ${failure.supplierName})
        `;
        break;
      case FAILURE_REASON.QUOTATION_FEEDBACK_DETECTED:
        label = `
          Project ID: ${failure.projectID} -
          ${FAILURE_REASON_MAPPING[failure.reason]}

          (Quotation ID: ${failure?.relatedRecords?.quotationID})
        `;
        break;
      // show remarks and the quotation id
      case FAILURE_REASON.QUOTATION_REMARKS_DETECTED:
        label = `
          Project ID: ${failure.projectID} -
          ${FAILURE_REASON_MAPPING[failure.reason]}

          (Quotation ID: ${failure?.relatedRecords?.quotationID} | Remarks: ${failure.relatedRecords?.remarks})
        `;
        break;
      case FAILURE_REASON.PO_REMINDER_IGNORED:
        label = `
          Project ID: ${failure.projectID} -
          ${FAILURE_REASON_MAPPING[failure.reason]}

          (Quotation IDs: ${failure?.relatedRecords?.quotationIDs?.join(', ')})
        `;
        break;
      default:
        label = `Project ID: ${failure.projectID}`;
        break;
    }
    return label;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'md'}
      fullWidth={true}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
    >
      <Close className={classes.closeIcon} onClick={onClose} />
      <DialogContent
        style={{
          margin: '3rem 0',
        }}
      >
        <FlexColumn>
          <FtrH6 type='heading'>
            Automation {AUTOMATION_FAILURE_POPUP[type].heading} Issues
          </FtrH6>
          <FtrTypography type='body'>
            There are issues with the automation flow for these repeat orders.
            <br />
            {AUTOMATION_FAILURE_POPUP[type]?.action}
          </FtrTypography>
          <ul>
            {failures?.map((failure) => {
              if (isEmptyValue(failure)) {
                return;
              }

              return (
                <li key={failure.projectID}>
                  <Link
                    to={{
                      pathname: getActionPath(type, failure.projectID),
                    }}
                  >
                    {getActionLabel(type, failure)}
                  </Link>
                </li>
              );
            })}
          </ul>
        </FlexColumn>
      </DialogContent>
    </Dialog>
  );
};

export default AutomationFailuresPopup;
