import React, { useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import PpeControlPanelCommonTab from './PpeControlPanelCommonTab';
import PpeControlPanelMaterialSurfaceTab from './PpeControlPanelMaterialSurfaceTab';

import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  twoColumns: {
    display: 'flex',
    columnGap: '25px',
    padding: '0',
  },
  saveButtonWrapper: {},
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TAB_KEYS = {
  PPE_CONTROL_PANEL_COMMON: 'Common Control',
  PPE_CONTROL_PANEL_MATERIAL_SURFACE: 'Materials & Surface Finishes',
};

const TABS = [
  TAB_KEYS.PPE_CONTROL_PANEL_COMMON,
  TAB_KEYS.PPE_CONTROL_PANEL_MATERIAL_SURFACE,
];

function PpeControlPanelV2() {
  const classes = useStyles();

  const [tabs, _] = useState(TABS);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTab = () => {
    const tabName = tabs[selectedTab];
    switch (tabName) {
      case TAB_KEYS.PPE_CONTROL_PANEL_COMMON:
        return <PpeControlPanelCommonTab />;
      case TAB_KEYS.PPE_CONTROL_PANEL_MATERIAL_SURFACE:
        return <PpeControlPanelMaterialSurfaceTab />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.body}>
      <Tabs value={selectedTab} onChange={handleChange}>
        {tabs?.map((tab) => {
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
      {renderTab()}
    </div>
  );
}

export default PpeControlPanelV2;
