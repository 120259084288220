import React, { useContext, useState } from 'react';

import { CircularProgress, Grid, makeStyles } from '@material-ui/core';

import {
  FlexColumn,
  FlexColumnCenter,
  FlexRow,
  FlexRowEnd,
} from '../layouts/FlexLayouts';
import DefectThumbnail from './DefectThumbnail';

import FailedIcon from '../icons/FailedIcon';
import WarningIcon from '../icons/WarningIcon';
import CheckIcon from '../icons/CheckIcon';
import DfmDefinitionTooltip from '../DfmDefinitionTooltip';
import { FtrButton, FtrNormalText } from '../ftr-components';

import AppContext from '../../context/AppContext';

import { snakeCaseToTitleCase } from '../../utils/stringUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { defectsStatus } from '../../utils/dfmExtractionUtils';

import { FE_FEATURE_FLAGS_CONFIGURATION } from '../../constants/configurations';
import { EXTRACT_DEFECT_KEYS_MAPPING } from '../../constants/dfmConstants';
import { colors } from '../../palette';

import useDeviceCheck from '../../hooks/useDeviceCheck';

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('xs')]: {
    statuses: {
      order: 1,
      marginTop: '1rem',
    },
  },
}));

/**
 * A React component that displays a DFM (Design for Manufacturability) analysis
 * of a given CAD file. The component shows a table of defects and their status,
 * and a 2D thumbnail of the CAD file. The status of each defect is displayed as
 * a colored circle, with red indicating a failure, yellow indicating a warning,
 * and green indicating a success. The component also includes a visibility toggle
 * for each defect, which allows the user to view the 3D visualization of the defect
 * in the CAD file.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.dfmDefectOutput - The output of the DFM analysis, which
 * includes the defects and their status.
 * @param {string} props.twoDImageUrl - The URL of the 2D image of the CAD file.
 * @param {Object} props.cadFile - The CAD file object.
 * @param {boolean} props.isLoading - Flag indicating whether the DFM analysis is
 * currently loading.
 */
function DfmAnalysis(props) {
  const {
    dfmDefectOutput,
    twoDImageUrl,
    cadFile,
    isLoading = false,
    ...restProps
  } = props;
  const { featureFlags } = useContext(AppContext);

  const [show3dDfmFeedback, setShow3dDfmFeedback] = useState({});
  const classes = useStyles();
  const [{ isMobile }] = useDeviceCheck();

  if (
    !featureFlags?.config?.[
      FE_FEATURE_FLAGS_CONFIGURATION.DFM_AUTOMATION_BUYER
    ] ||
    dfmDefectOutput === undefined
  ) {
    return null;
  }

  const allowedDefects = [
    EXTRACT_DEFECT_KEYS_MAPPING.deep_holes,
    EXTRACT_DEFECT_KEYS_MAPPING.small_holes,
    EXTRACT_DEFECT_KEYS_MAPPING.holes_close_to_edge,
    EXTRACT_DEFECT_KEYS_MAPPING.thin_walls,
    EXTRACT_DEFECT_KEYS_MAPPING.sharp_edges,
    EXTRACT_DEFECT_KEYS_MAPPING.part_sizes_too_big,
    EXTRACT_DEFECT_KEYS_MAPPING.overhangs,
    EXTRACT_DEFECT_KEYS_MAPPING.fine_features,
  ];
  const FEEDBACK_STATUS_INDICATOR = {
    loading: 'blue',
    failed: 'red',
    Yes: 'yellow',
    No: 'green',
  };

  const handleViewDefect = (defectKey) => {
    setShow3dDfmFeedback((prev) => {
      // hide the current view
      if (show3dDfmFeedback?.[defectKey]) {
        return {
          ...prev,
          [defectKey]: false,
        };
      }

      // Create a new object with all keys set to false
      const prevValues = Object.keys(prev)?.reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});

      // Set the specific defectKey to true
      return {
        ...prevValues,
        [defectKey]: true,
      };
    });
  };

  const renderDfmFeedbackStatus = ({
    text = 'dfm feedback',
    color = 'green',
    defectKey,
  }) => {
    const COLORS_FEEDBACK_STATUS = {
      green: {
        backgroundColor: colors.green010,
        color: colors.green050,
      },
      red: {
        backgroundColor: colors.red010,
        color: colors.red050,
      },
      blue: {
        backgroundColor: colors.blue010,
        color: colors.blue050,
      },
      neutral: {
        backgroundColor: colors.neutral030,
        color: colors.neutral070,
      },
      yellow: {
        backgroundColor: colors.paleYellow,
        color: colors.orange,
      },
    };

    return (
      <FlexRow>
        {color === 'blue' && (
          <CircularProgress
            style={{
              color: colors.neutral040,
            }}
            size={20}
          />
        )}
        {color === 'red' && <FailedIcon toolTipText='DFM Analysis Failed' />}
        {color === 'yellow' && <WarningIcon toolTipText='Defect found' />}
        {color === 'green' && <CheckIcon toolTipText='No defect found' />}
        <DfmDefinitionTooltip defectKey={defectKey}>
          <div>
            <FlexColumnCenter
              style={{
                padding: '1rem',
                width: '12rem',
                borderRadius: '10px',
                backgroundColor: COLORS_FEEDBACK_STATUS[color].backgroundColor,
              }}
            >
              <FtrNormalText
                style={{ color: COLORS_FEEDBACK_STATUS[color].color }}
              >
                {text}
              </FtrNormalText>
            </FlexColumnCenter>
          </div>
        </DfmDefinitionTooltip>
      </FlexRow>
    );
  };

  let stateIndicator = null;
  if (dfmDefectOutput === 'loading' || isLoading) {
    stateIndicator = 'loading';
  } else if (!dfmDefectOutput?.dfm_success) {
    stateIndicator = 'failed';
  }

  let textAnalysis = '';
  if (stateIndicator === 'loading') {
    textAnalysis = 'DFM Analysis Processing...';
  } else if (stateIndicator === 'failed') {
    textAnalysis = 'DFM Analysis Failed';
  }

  return (
    <div>
      <Grid container className={classes.dfmAnalysisContainer}>
        <Grid item xs={12} sm={6} className={classes.statuses}>
          <FlexColumn>
            {stateIndicator &&
              renderDfmFeedbackStatus({
                text: textAnalysis,
                color: FEEDBACK_STATUS_INDICATOR[stateIndicator],
              })}
            {!stateIndicator &&
              allowedDefects?.map((defectKey) => {
                if (isEmptyValue(dfmDefectOutput[defectKey])) {
                  return null;
                }

                const type = snakeCaseToTitleCase(defectKey);
                const defectOutputStatus = defectsStatus(dfmDefectOutput)?.find(
                  (i) => i.key === defectKey
                );

                const valueText = defectOutputStatus?.defect ? 'Yes' : 'No';
                textAnalysis = `${type}: ${valueText}`;
                return (
                  <FlexRow key={defectKey}>
                    {renderDfmFeedbackStatus({
                      text: textAnalysis,
                      color: FEEDBACK_STATUS_INDICATOR[valueText],
                      defectKey,
                    })}
                    {dfmDefectOutput.visualization_links?.[defectKey] && (
                      <FtrButton
                        color='blue'
                        variant='outlined'
                        style={{
                          marginLeft: '5px',
                          padding: '3px 9px',
                          minWidth: 'max-content',
                        }}
                        size='small'
                        onClick={() => handleViewDefect(defectKey)}
                      >
                        {show3dDfmFeedback?.[defectKey] ? 'Hide' : 'View'}
                      </FtrButton>
                    )}
                  </FlexRow>
                );
              })}
          </FlexColumn>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FlexRowEnd style={{ ...(isMobile && { justifyContent: 'center' }) }}>
            <DefectThumbnail
              visualizationLinks={dfmDefectOutput.visualization_links}
              defectKey={Object.keys(show3dDfmFeedback)?.find(
                (key) => show3dDfmFeedback[key]
              )}
              defectsOutput={dfmDefectOutput}
              showYesOrNoValue
              showDfmSuccess={false}
              twoDImageUrl={twoDImageUrl}
              cadFile={cadFile}
              stateIndicator={stateIndicator}
              {...restProps}
            />
          </FlexRowEnd>
        </Grid>
      </Grid>
    </div>
  );
}

export default DfmAnalysis;
