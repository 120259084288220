import queryString from 'query-string';

import { BACKEND_SERVICE_URL } from '../constants';
import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

export const getDhlRates = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/shipment/dhl/rates`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      return response.json().then((err) => {
        throw new Error(err.message);
      });
    }
    return (await response.json()).data;
  });
};

export const getFedExRates = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/shipment/fedex/rates`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      return response.json().then((err) => {
        throw new Error(err.message);
      });
    }
    return (await response.json()).data;
  });
};

export const getJntRates = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/shipment/jnt/rates`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const createOrderJnt = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/shipment/jnt/create-order`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const cancelOrderJnt = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/shipment/jnt/cancel-order`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const getTrackingStatusJnt = async (reference_number) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/shipment/jnt/tracking/${reference_number}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getJntWaybill = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/shipment/jnt/waybill`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

let adminGetAllShipmentOrderLogsController = new AbortController();
export const adminGetAllShipmentOrderLogs = async (params) => {
  if (adminGetAllShipmentOrderLogsController) {
    // stop previous call if it's ongoing, only allow for latest call to update FE
    adminGetAllShipmentOrderLogsController.abort();
  }
  adminGetAllShipmentOrderLogsController = new AbortController();
  const signal = adminGetAllShipmentOrderLogsController.signal;
  const requestUrl = `${BACKEND_SERVICE_URL}/shipment/order-logs/search?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
    signal,
  });
};

export const adminExportAllShipmentOrderLogs = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/shipment/order-logs/search/csv-export?${queryString.stringify(
    params
  )}`;
  return fetch(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getUpsRates = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/shipment/ups/rates`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};
