import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Paper } from '@material-ui/core';

import CircleButton from './buttons/CircleButton';

import EditIcon from '../assets/icons/edit_pencil_icon.svg';

import useDeviceCheck from '../hooks/useDeviceCheck';

import { colors } from '../palette';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  hovered: {
    backgroundColor: colors.neutral020,
  },
  disabled: {
    backgroundColor: colors.neutral020,
  },
}));

function ClickablePaper(props) {
  const classes = useStyles();

  const {
    ref,
    children,
    showEditIcon = true,
    onEditClick = () => {},
    style = {},
    disabled = false,
  } = props;

  const [{ isTablet }] = useDeviceCheck();

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Paper
      ref={ref}
      className={`${isHovered ? classes.hovered : null} ${
        disabled && classes.disabled
      }`}
      variant='outlined'
      style={{
        borderRadius: 8,
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        cursor: isHovered ? 'pointer' : 'default',
        ...style,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => onEditClick()}
    >
      {children}
      {(isHovered === true || isTablet) && showEditIcon && (
        <CircleButton
          position='end'
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            right: 0,
          }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onEditClick();
          }}
        >
          <img src={EditIcon} alt='Edit' />
        </CircleButton>
      )}
    </Paper>
  );
}

export default ClickablePaper;
