import { TextField } from '@material-ui/core';

import { FtrBoldText, FtrDropdownV2 } from '../../ftr-components';
import { FlexRow } from '../../layouts/FlexLayouts';
import { isEmptyValue } from '../../../utils/commonUtils';

export const ConsigneeDetailsForm = (props) => {
  const {
    title = 'Consignee Details',
    onChangeState = () => {},
    consigneeContactName = 'Factorem Pte Ltd',
    consigneePhoneNumber = '+65 8925 8615',
    consigneeAddress = '81 Ayer Rajah Crescent, #01-54, Singapore',
    consigneePostalCode = '139967',
    consigneeInstructions = null,
    allAddresses = [],
  } = props;

  const selectedValue = allAddresses?.find(
    (a) => a.name === consigneeContactName
  );

  const handleSelectAddressChange = (e) => {
    const value = e?.target?.value;
    const selectedAddress = allAddresses?.find((a) => a.key === value);
    if (!isEmptyValue(selectedAddress)) {
      onChangeState({
        consigneeContactName: selectedAddress?.name,
        consigneePhoneNumber: selectedAddress?.contact,
        consigneeAddress: selectedAddress?.address,
        consigneePostalCode: selectedAddress?.postalCode,
      });
    }
  };

  return (
    <div>
      <FlexRow>
        <FtrBoldText fontSize='16'>{title}</FtrBoldText>
        <FtrDropdownV2
          items={allAddresses}
          value={selectedValue?.key}
          onChange={handleSelectAddressChange}
        />
      </FlexRow>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          columnGap: '1rem',
          placeItems: 'baseline',
          marginTop: '1rem',
        }}
      >
        <TextField
          label='Contact Name'
          value={consigneeContactName || ''}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            onChangeState({
              consigneeContactName: evt.target.value,
            });
          }}
        />
        <TextField
          label='Phone Number'
          value={consigneePhoneNumber || ''}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            onChangeState({
              consigneePhoneNumber: evt.target.value,
            });
          }}
        />
        <TextField
          label='Address'
          value={consigneeAddress || ''}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            onChangeState({
              consigneeAddress: evt.target.value,
            });
          }}
        />
        <TextField
          label='Postcode'
          value={consigneePostalCode || ''}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          fullWidth
          required
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            onChangeState({
              consigneePostalCode: evt.target.value,
            });
          }}
        />
        <TextField
          label='Instructions'
          value={consigneeInstructions || ''}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          onChange={(evt) => {
            onChangeState({
              consigneeInstructions: evt.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};
