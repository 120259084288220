import React, { useEffect, useRef, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import Fuse from 'fuse.js';

import { makeStyles } from '@material-ui/core/styles/index';

import { MenuItem, FormControl, TextField } from '@material-ui/core';

import SearchInputDropdown from '../dropdowns/SearchInputDropdown';

import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

const useStyles = makeStyles(() => ({
  selectDropdown: {
    borderRadius: 10,
    '& .MuiSelect-select': {
      color: '#4D4D4D',
      fontWeight: 400,
      textAlign: 'left',
      whiteSpace: 'break-spaces',
    },
    '& .MuiInputBase-root': {
      borderRadius: 10,
    },
  },
  menuItemRoot: {
    borderRadius: '0.75rem',
    whiteSpace: 'break-spaces',
    margin: '0.5rem 0',
    padding: '0.5rem 1rem',
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
    '&.Mui-selected': {
      backgroundColor: colors.fontWhite,
    },
  },
  paper: {
    borderRadius: '0.8rem',
    padding: '0.5rem',
  },
}));

const fuseOptions = {
  isCaseSensitive: false,
  keys: ['key', 'display'],
};

/**
 * Customised Dropdown component
 *
 * @param {object} props - The properties of the Dropdown component.
 * @param {String} props.id - A unique identifier for the dropdown.
 * @param {String} props.value - The value of the dropdown.
 * @param {Boolean} props.fullWidth - If true, the dropdown will take up the full width of the parent div.
 * @param {Function} props.handleChange - Callback function to be executed when the dropdown value is changed.
 * @param {Array.<{key: String, display: String=}>} props.items - The items to be displayed in the dropdown.
 *
 * @returns {React.ReactNode} The rendered Dropdown component.
 */
function FtrDropdownWithSearch(props) {
  const classes = useStyles();

  const {
    id,
    value = '',
    fullWidth = false,
    handleChange,
    items = [],
    menuItemColor,
    placeholder = '',
  } = props;

  const searchSupplierRef = useRef(null);

  const [displayItems, setDisplayItems] = useState(items);
  const [filterText, setFilterText] = useState('');
  const [open, setOpen] = useState(false);

  const selectedItem = items?.find((item) => item.key === value);

  const menuProps = {
    classes: {
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
    open: open,
    onClose: handleClose,
    onEnter: handleOpen,
  };

  useEffect(() => {
    const fuse = new Fuse(items, fuseOptions);
    if (isEmptyValue(filterText)) {
      setDisplayItems(items);
      return;
    }
    const filteredItems = fuse.search(filterText)?.map((result) => result.item);
    setDisplayItems(filteredItems);
  }, [items, filterText]);

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  function MenuItemRenderer({ index, style, data }) {
    const item = data[index];

    return (
      <MenuItem
        key={item.key ?? item}
        classes={{
          root: classes.menuItemRoot,
        }}
        value={item.key ?? item}
        style={{ ...style, color: menuItemColor }}
      >
        {item.display ?? item.key ?? item}
      </MenuItem>
    );
  }

  return (
    <FormControl style={{ width: fullWidth && '100%' }}>
      <TextField
        select
        classes={{
          root: classes.selectDropdown,
        }}
        id={id}
        variant='outlined'
        value={value}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();

          if (!open) {
            setOpen(true);
            return;
          }

          handleChange(event.target.value);
          setTimeout(() => handleClose(), 0);
        }}
        margin='dense'
        fullWidth
        label={value ? '' : placeholder}
        displayEmpty
        SelectProps={{
          MenuProps: menuProps,
        }}
        InputLabelProps={{ shrink: false }}
        {...props}
      >
        <SearchInputDropdown
          key='search-input'
          id='search-input'
          inputRef={searchSupplierRef}
          setFilterText={(_value) => {
            setFilterText(_value);
          }}
          filterText={filterText}
        />
        <List
          height={400}
          itemCount={displayItems.length}
          itemSize={35}
          itemData={displayItems}
          width='100%'
        >
          {MenuItemRenderer}
        </List>

        {/* This is a hack for displaying selected item while using react-window */}
        {selectedItem && (
          <MenuItem
            key={selectedItem.key ?? selectedItem}
            value={selectedItem.key ?? selectedItem}
            style={{ display: 'none' }}
          >
            {selectedItem.display ?? selectedItem.key ?? selectedItem}
          </MenuItem>
        )}
      </TextField>
    </FormControl>
  );
}

export default FtrDropdownWithSearch;
