export const ONE_HOUR_IN_MS = 1 * 60 * 60 * 1000;
export const FIVE_MINUTES_IN_SECOND = 5 * 60;
export const FIFTEEN_MINUTES_IN_SECOND = 15 * 60;
export const FOURTEEN_MINUTES_IN_SECOND = 14 * 60;
export const MONTH_NUMBER_NAME_MAPPING = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};
