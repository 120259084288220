import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { setCustomerSettings } from '../actions/customerSettings';

import { getCustomerSettings } from '../apis/customerSettingsApi';

import { isEmptyValue } from '../utils/commonUtils';
import { USER_FEE_TYPE } from '../constants/userConstant';

// --------------------------------------------------------------------------------------------

const useCustomerSettingsHook = (userID) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data: customerSettings } = useQuery(
    ['getCustomerSettings', userID],
    () => getCustomerSettings(userID),
    {
      enabled: !isEmptyValue(userID),
    }
  );

  const combinedServiceFee =
    customerSettings?.combinedServiceFee === 1 &&
    customerSettings?.feeType === USER_FEE_TYPE.HAS_FEE;

  useEffect(() => {
    if (customerSettings) {
      dispatch(setCustomerSettings(customerSettings));
    }
  }, [customerSettings]);

  const invalidateQuery = () => {
    queryClient.invalidateQueries('getCustomerSettings');
  };

  return {
    customerSettings,
    combinedServiceFee,
    invalidateQuery,
  };
};

export default useCustomerSettingsHook;
