import React from 'react';

import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';
import { ROLE_TYPES, YES_NO_TEXT_MAPPING } from '../constants';
import { CUSTOMER_CREDIT_TYPES } from './customerConstants';

export const defaultPaymentTerms = '30 day credit from date of order delivery';

export const keyAccountCol = {
  headerName: 'Key Account',
  field: 'keyAccount',
  editable: true,
  type: 'singleSelect',
  renderCell: ({ row: rowData }) =>
    YES_NO_TEXT_MAPPING[rowData.keyAccount] || 'N.A.',
  valueGetter: ({ row: rowData }) =>
    YES_NO_TEXT_MAPPING[rowData.keyAccount] || 'N.A.', // it will change the main data
  valueOptions: [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ],
};

export const paymentTermCol = {
  headerName: 'Payment Terms',
  field: 'paymentTerms',
  renderCell: ({ row: rowData }) => (
    <DataGridWrapTextCell
      text={
        rowData.role === ROLE_TYPES.BUYER &&
        rowData.creditType === CUSTOMER_CREDIT_TYPES.CREDIT
          ? rowData.paymentTerms || defaultPaymentTerms
          : ''
      }
    />
  ),
  editable: true,
  renderEditCell: ({ row: rowData, value, id, api, field }) => {
    if (!(rowData.role === ROLE_TYPES.BUYER)) {
      return null;
    }

    const handleChange = (_value) => {
      api.setEditCellValue({ id, field, value: _value });
    };

    return (
      <input
        style={{
          width: '100%',
          border: 'none',
        }}
        type='text'
        defaultValue={value || defaultPaymentTerms}
        onChange={(e) => handleChange(e.target.value)}
      />
    );
  },
  width: 200,
};

export const creditTypeCol = {
  headerName: 'Credit Type',
  field: 'creditType',
  lookup: { 'non-credit': 'non-credit', credit: 'credit' },
  editable: true,
  type: 'singleSelect',
  valueOptions: [
    { value: 'non-credit', label: 'non-credit' },
    { value: 'credit', label: 'credit' },
  ],
  width: 120,
};
