import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

// --------------------------------------------------------------------------------------------

export const getUnresolvedAutomationFailures = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/project-100/automation-failures/unresolved`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};
