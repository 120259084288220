import { Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';
import { DataGrid } from '@mui/x-data-grid';
import { addDays } from 'date-fns';
import { ceil } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';

import HorizontalExpandSpace from '../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../components/grid-data/CustomToolbar';
import GridDataPagination from '../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../components/grid-data/buttons/SearchBar';
import CheckIcon from '../components/icons/CheckIcon';
import { FlexRow } from '../components/layouts/FlexLayouts';
import NoQuoteReasonPopup from '../components/popups/NoQuoteReasonPopup';
import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';

import { Publish } from '@material-ui/icons';

import EmailStatusIcon from '../components/icons/EmailStatusIcon';

import {
  exportCsvRFQEmailsTrackingItems,
  getGenerateRFQEmailsTrackingItems,
  getTrackingBrevo,
  updateGenerateRFQEmailsTrackingItems,
} from '../apis/generateRfqEmailsTrackingApi';

import { getUserRoleSelector } from '../selectors/userSelector';

import { exportCsvApi } from '../utils/csvExportUtils';
import {
  compareDate,
  dateTzSingapore,
  getMinDate,
} from '../utils/dateTimeUtils';
import { reasonsTextUtil } from '../utils/quotationUtils';
import { isSuperAdminRole } from '../utils/roleUtils';
import { convertToDigits } from '../utils/stringUtils';

import { notifyError, notifySuccess } from '../services/notificationService';

import { EMAIL_BREVO_TYPES } from '../constants/emailConstants';

import { colors } from '../palette';
import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '1rem',
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'center',
      whiteSpace: 'normal',
      justifyContent: 'center !important',
    },
  },
  enterReasonBtn: {
    display: 'flex',
    margin: '0 auto',
    alignItems: 'center',
    width: 'fit-content',
    color: 'black',
    padding: '0.4rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#00000011',
    },
    backgroundColor: '#00000008',
    borderRadius: 4,
    textTransform: 'uppercase',
    fontSize: '9px',
    lineHeight: '13px',
    whiteSpace: 'nowrap',
  },
  uploadIcon: {
    color: 'gray',
    marginRight: '5px',
  },
  listReasons: {
    fontSize: '11px',
    fontWeight: '500',
    lineHeight: 'normal',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
}));

function GenerateRFQEmailsTrackingTab() {
  const classes = useStyles();

  const role = useSelector(getUserRoleSelector);

  const [selectedTracking, setSelectedTracking] = useState(null);
  const [showNoQuoteReasonPopup, setShowNoQuoteReasonPopup] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [brevoData, setBrevoData] = useState([]);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
      quoteInfo: true,
    }
  );

  const loadTableData = () => {
    updateTableQueryParams({ loading: true });
    getGenerateRFQEmailsTrackingItems(tableQueryParams)
      .then((data) => {
        setTableData(data.rows);
        updateTableQueryParams({ totalCount: data.totalCount });
        const trackingIDs = data?.rows?.map((rfq) => rfq.id);
        getTrackingBrevo({ trackingIDs }).then((resBrevo) => {
          setBrevoData(resBrevo);
        });
      })
      .finally(() => {
        updateTableQueryParams({ loading: false });
      });
  };

  let timer;
  useEffect(() => {
    if (!tableQueryParams.search) {
      loadTableData();
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        loadTableData();
      }, 300);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [
    tableQueryParams.search,
    tableQueryParams.page,
    tableQueryParams.pageSize,
  ]);

  const getRfqID = (rowData) => {
    const partnerAccountID = convertToDigits(rowData.partnerAccountID);
    const projectID = convertToDigits(rowData.projectID);
    const itemProjectID = convertToDigits(rowData.itemProjectID);
    const itemID = convertToDigits(rowData.itemID);
    // If there is itemID, set 00000000
    if (partnerAccountID === '') {
      return '00000000';
    }
    let result = partnerAccountID + projectID;
    if (rowData.itemProjectID) {
      result += itemProjectID + itemID;
    }
    return result;
  };

  const handleNoQuoteReason = async ({ noQuoteReason }) => {
    const selectedRfqID = getRfqID(selectedTracking);
    const filteredData = tableData?.filter(
      (data) =>
        getRfqID(data) === selectedRfqID &&
        isEmptyValue(data.quotationIDs) &&
        isEmptyValue(data.ppeQuotationIDs)
    );
    const trackingIDs = filteredData?.map((data) => data.id);
    if (isEmptyValue(trackingIDs)) {
      notifyError('No data to update');
    }
    updateGenerateRFQEmailsTrackingItems({ noQuoteReason, trackingIDs }).then(
      () => {
        loadTableData();
        notifySuccess('No Quote Reason has been updated successfully');
        setShowNoQuoteReasonPopup(false);
      }
    );
  };

  const getAllQuotations = (rowData) => {
    const allQuotations = [];
    if (!isEmptyValue(rowData.quotations)) {
      allQuotations.push(...rowData.quotations);
    }
    if (!isEmptyValue(rowData.ppeQuotations)) {
      allQuotations.push(...rowData.ppeQuotations);
    }
    return allQuotations;
  };

  const renderQuoteTracker = (rowData) => {
    if (
      isEmptyValue(rowData.quotations) &&
      isEmptyValue(rowData.ppeQuotations)
    ) {
      return (
        <div style={{ lineHeight: 'normal' }}>
          <span>No Quote</span>
          <Button
            className={classes.enterReasonBtn}
            onClick={() => {
              setSelectedTracking(rowData);
              setShowNoQuoteReasonPopup(true);
            }}
          >
            <Publish className={classes.uploadIcon} />
            Enter Reason
          </Button>
          <div className={classes.listReasons}>
            {reasonsTextUtil(rowData.noQuoteReason)}
          </div>
        </div>
      );
    }
    const allQuotations = getAllQuotations(rowData);
    return (
      <div
        style={{
          lineHeight: 'normal',
          wordBreak: 'break-word',
          whiteSpace: 'normal',
        }}
      >
        <CheckIcon toolTipText={'Quoted'} />
        <div
          style={{
            display: 'flex',
            gap: '3px',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {allQuotations?.map((quotation) => (
            <Tooltip
              arrow
              key={quotation.quotationID}
              title={`Date of Quote ${dateTzSingapore(
                quotation.createdAt,
                'DD MMM YYYY HH:mm'
              )}`}
            >
              <div style={{ textDecoration: 'underline' }}>
                {quotation.quotationID}
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    );
  };

  const renderDate = (dateParam) => {
    const dateTimeStr = dateTzSingapore(dateParam, 'DD MMM YYYY HH:mm');
    const splitStr = dateTimeStr?.split(' ');
    const date = splitStr?.slice(0, 3)?.join(' ');
    const time = splitStr[3];
    return (
      <Tooltip title='Date and time displayed in SGT'>
        <div style={{ lineHeight: 'normal' }}>
          {date}
          <br />
          {time}
        </div>
      </Tooltip>
    );
  };

  const columns = [
    {
      headerName: 'RFQ ID',
      field: 'rfqID',
      renderCell: ({ row: rowData }) => getRfqID(rowData),
      valueGetter: ({ row: rowData }) => getRfqID(rowData),
      width: 120,
    },
    {
      headerName: 'Sent Date',
      field: 'sentDate',
      width: 150,
      renderCell: ({ row: rowData }) => renderDate(rowData.createdAt),
      valueGetter: ({ row: rowData }) =>
        dateTzSingapore(rowData?.createdAt, 'DD MMM YYYY HH:mm'),
    },
    {
      headerName: 'Email status',
      field: 'emailStatus',
      renderCell: ({ row: rowData }) => {
        if (rowData.status === 'Failed') {
          return (
            <EmailStatusIcon
              status='failed'
              emailType={rowData?.type}
              date={rowData?.createdAt}
            />
          );
        }
        const brevo = brevoData?.find((tracking) => tracking.id === rowData.id);
        if (!brevo) {
          return null;
        }
        const scheduledDate = addDays(new Date(brevo?.ebtCreatedAt), 1);
        const showScheduled =
          compareDate(new Date(), scheduledDate) < 0 &&
          [EMAIL_BREVO_TYPES.RFQ_ITEM, EMAIL_BREVO_TYPES.RFQ_PROJECT].includes(
            brevo.type
          );
        return (
          <FlexRow>
            <EmailStatusIcon
              status={brevo?.lastStatus}
              emailType={brevo?.type}
              date={brevo?.ebtUpdatedAt}
            />
            {showScheduled && (
              <EmailStatusIcon
                status='scheduled'
                emailType={`${brevo?.type}-reminder`}
                date={scheduledDate}
              />
            )}
          </FlexRow>
        );
      },
    },
    {
      headerName: 'Technology',
      field: 'technology',
      width: 120,
    },
    {
      headerName: 'Type',
      field: 'type',
    },
    {
      headerName: 'Project ID',
      field: 'projectID',
      renderCell: ({ row: rowData }) => {
        return rowData.projectID || rowData.itemProjectID;
      },
      valueGetter: ({ row: rowData }) => {
        return rowData.projectID || rowData.itemProjectID;
      },
    },
    {
      headerName: 'Part ID',
      field: 'itemID',
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell
            text={rowData.itemID || rowData.itemIDs?.join(', ')}
          />
        );
      },
      valueGetter: ({ row: rowData }) => {
        return rowData.itemID || rowData.itemIDs?.join(', ');
      },
      width: 150,
    },
    {
      headerName: 'Partner Name',
      field: 'partnerName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.partnerName} />
      ),
      width: 150,
    },
    {
      headerName: 'Partner Email',
      field: 'partnerEmail',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.partnerEmail} />
      ),
      width: 150,
    },
    {
      headerName: 'Additional Remarks',
      field: 'remarks',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.remarks} />
      ),
      width: 180,
    },
    {
      headerName: 'Design Files Sent',
      field: 'designFilesSent',
      renderCell: ({ row: rowData }) => {
        return rowData.hasAttachment ? 'Yes' : 'No';
      },
      valueGetter: ({ row: rowData }) => {
        return rowData.hasAttachment ? 'Yes' : 'No';
      },
    },
    {
      headerName: 'RFQ Status',
      field: 'status',
    },
    {
      headerName: 'Admin Sent by',
      field: 'adminName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.adminName} />
      ),
      width: 150,
    },
    {
      headerName: 'Quote Tracker',
      field: 'quoteTracker',
      renderCell: ({ row: rowData }) => renderQuoteTracker(rowData),
      valueGetter: ({ row: rowData }) => {
        if (
          isEmptyValue(rowData.quotations) &&
          isEmptyValue(rowData.ppeQuotations)
        ) {
          return 'No Quote';
        }
        return 'Quoted';
      },
      width: 150,
    },
    {
      headerName: 'Quote Tracker Info',
      field: 'quoteTrackerInfo',
      hide: true,
      valueGetter: ({ row: rowData }) => {
        if (
          isEmptyValue(rowData.quotations) &&
          isEmptyValue(rowData.ppeQuotations)
        ) {
          return 'N.A.';
        }
        const allQuotations = getAllQuotations(rowData);
        const showData = allQuotations?.map((quotation) => ({
          quotationID: quotation.quotationID,
          dateOfOrder: dateTzSingapore(
            quotation.createdAt,
            'DD MMM YYYY HH:mm'
          ),
        }));
        return JSON.stringify(showData);
      },
      width: 150,
    },
    {
      headerName: 'No Quote Reason',
      field: 'noQuoteReason',
      valueGetter: ({ row: rowData }) => reasonsTextUtil(rowData.noQuoteReason),
      hide: true,
    },
    {
      headerName: 'Date of First Quote',
      field: 'dateOfOrder',
      renderCell: ({ row: rowData }) => {
        if (
          isEmptyValue(rowData.quotations) &&
          isEmptyValue(rowData.ppeQuotations)
        ) {
          return 'N.A.';
        }
        const allQuotations = getAllQuotations(rowData);
        const minDate = getMinDate(allQuotations, 'createdAt');
        return renderDate(minDate);
      },
      valueGetter: ({ row: rowData }) => {
        if (
          isEmptyValue(rowData.quotations) &&
          isEmptyValue(rowData.ppeQuotations)
        ) {
          return 'N.A.';
        }
        const allQuotations = getAllQuotations(rowData);
        const minDate = getMinDate(allQuotations, 'createdAt');
        return dateTzSingapore(minDate, 'DD MMM YYYY HH:mm');
      },
      width: 150,
    },
  ];

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleExportCSV = () => {
    const fileName = `All Generate RFQ Emails Tracking Items`;
    exportCsvApi(exportCsvRFQEmailsTrackingItems(tableQueryParams), fileName);
  };

  const getToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleExportCSV}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.container}>
      <DataGrid
        autoHeight
        paginationMode='server'
        rows={tableData ?? []}
        columns={columns?.map((col) => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.id}
        rowHeight={120}
        headerHeight={80}
        components={{
          Toolbar: getToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        pageSize={tableQueryParams.pageSize}
        rowsPerPageOptions={[10, 20, 50]}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        page={tableQueryParams.page}
        onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
        rowCount={tableQueryParams.totalCount}
        loading={tableQueryParams.loading}
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
      />
      {showNoQuoteReasonPopup && (
        <NoQuoteReasonPopup
          dialog={showNoQuoteReasonPopup}
          handleClose={() => setShowNoQuoteReasonPopup(false)}
          onOk={handleNoQuoteReason}
          rowData={selectedTracking}
        />
      )}
    </div>
  );
}

export default GenerateRFQEmailsTrackingTab;
