const SUPPLIER_FEEDBACK = {
  TAKE_ORDER_WISH_PRICE_TO_REVISE:
    'I will take on this order but wish to see price revised next time.',
  TAKE_ORDER_IF_PRICE_IS_REVISED:
    'I will take on this order if the price is revised for this order.',
  NO_ACTIONS: 'I just wish to leave feedback. No further action required.',
};

const SUPPLIER_FEEDBACK_TOOLTIPS_FOR_ADMIN = {
  [SUPPLIER_FEEDBACK.NO_ACTIONS]:
    'Supplier has feedback but no immediate action required.',
  [SUPPLIER_FEEDBACK.TAKE_ORDER_WISH_PRICE_TO_REVISE]:
    'Supplier will take the order but wishes to see a price revision next time.',
  [SUPPLIER_FEEDBACK.TAKE_ORDER_IF_PRICE_IS_REVISED]:
    'Supplier will not take the order until the price is revised.',
};

const LEAD_TIME_SUPPLIER_FEEDBACK = {
  TAKE_ORDER_WISH_TO_REVISE:
    'I will take on this order but wish to see delivery date revised next time.',
  TAKE_ORDER_IF_REVISED:
    'I will take on this order if the delivery date is revised for this order.',
  NO_ACTIONS: 'I just wish to leave feedback. No further action required.',
};

const LEAD_TIME_SUPPLIER_FEEDBACK_TOOLTIPS_FOR_ADMIN = {
  [LEAD_TIME_SUPPLIER_FEEDBACK.NO_ACTIONS]:
    'Supplier has feedback but no immediate action required.',
  [LEAD_TIME_SUPPLIER_FEEDBACK.TAKE_ORDER_WISH_TO_REVISE]:
    'Supplier will take the order but wishes to see a date revision next time.',
  [LEAD_TIME_SUPPLIER_FEEDBACK.TAKE_ORDER_IF_REVISED]:
    'Supplier will not take the order until the date is revised.',
};

const ADMIN_FEEDBACK = {
  REVISED_PRICE: 'Revised price for this order',
};

module.exports = {
  SUPPLIER_FEEDBACK,
  SUPPLIER_FEEDBACK_TOOLTIPS_FOR_ADMIN,
  ADMIN_FEEDBACK,
  LEAD_TIME_SUPPLIER_FEEDBACK,
  LEAD_TIME_SUPPLIER_FEEDBACK_TOOLTIPS_FOR_ADMIN,
};
