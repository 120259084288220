import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';

import { colors } from '../../palette';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Tooltip,
  TextField,
} from '@material-ui/core';

import { updateUserCountryAndCurrency } from '../../actions';

import { renderFlag } from '../images/CountryFlag';
import CountryDropDown from '../forms/CountryDropDown';

import { CURRENCY_CODE } from '../../constants/currencyConstants';

// Style the components
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    minWidth: 250,
    borderRadius: 0,
  },
  buttonStyle: {
    padding: 0,
    color: colors.fontLightGrey,
    fontSize: '9pt',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  flagStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
  },
  title: {
    fontSize: '13pt',
    color: colors.blue060,
    fontWeight: 600,
  },
}));

function CountrySelectorPopup(props) {
  const classes = useStyles();

  const {
    currency: currentCurrency,
    country: currentCountry,
    userID,
    onClickSubmit,
    disabled = true,
  } = props;

  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState(currentCountry);
  const [currency, setCurrency] = useState(currentCurrency);

  useEffect(() => {
    setCountry(currentCountry);
  }, [currentCountry]);

  useEffect(() => {
    setCurrency(currentCurrency);
  }, [currentCurrency]);

  const handleChangeCurrency = (event) => {
    setCurrency(event.target.value ? event.target.value : '');
  };

  const handleClickOpen = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    onClickSubmit(userID, { country, currency });
    setOpen(false);
  };

  const handleCountryChange = (value) => {
    setCountry(value);
  };

  const renderLocationCurrencyInfo = () => {
    return (
      <div style={{ display: 'flex', textTransform: 'none' }}>
        <div className={classes.flagStyle}>{renderFlag(currentCountry)}</div>
        <div>
          | {capitalize(currentCountry)} | {currentCurrency}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Tooltip
        title={
          disabled === true
            ? 'Please contact us if you want to change your country or currency'
            : ''
        }
        arrow
      >
        <Button className={classes.buttonStyle} onClick={handleClickOpen}>
          {renderLocationCurrencyInfo()}
        </Button>
      </Tooltip>
      {open === true && (
        <Dialog
          disableEscapeKeyDown
          open={open}
          onClose={handleClose}
          maxWidth='sm'
          classes={{ paper: classes.paper }}
        >
          <DialogTitle style={{ paddingBottom: 0 }}>
            <div className={classes.title}>Select Country and Currency</div>
          </DialogTitle>
          <DialogContent>
            <form className={classes.container}>
              <CountryDropDown
                id='country'
                isAdmin
                onSelect={handleCountryChange}
                valueSelected={country}
              />
              <TextField
                labelId='currency-dropdown'
                label='Currency'
                id='currency-dropdown'
                variant='outlined'
                select
                value={currency}
                onChange={handleChangeCurrency}
                fullWidth
              >
                {Object.entries(CURRENCY_CODE)?.map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Ok</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    currency: state.auth.location.currency,
    userID: state.auth.user.userID,
    country: state.auth.user.country,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    onClickSubmit: (userID, { country, currency }) =>
      dispatch(updateUserCountryAndCurrency(userID, { country, currency })),
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(CountrySelectorPopup);
