import React from 'react';

import { DatePicker } from '@material-ui/pickers';

import { FlexColumn, FlexRow } from '@components/layouts/FlexLayouts';
import { FtrTypography, FtrCaption } from '@components/ftr-components';

import {
  addBusinessDays,
  formatDeliveryDateWithCheck,
  isSameOrAfterDate,
} from '@utils/dateTimeUtils';

import { ORDER_READY_STATUS } from '@constants/orderReadyConstants';

// -------------------------------------------------------------------------------------------------

const getDateDetails = ({ isDelivered, baseDate, revisedDate, actualDate }) => {
  const baseDateStr = formatDeliveryDateWithCheck(baseDate);
  const revisedDateStr = formatDeliveryDateWithCheck(revisedDate);
  const actualDateStr = formatDeliveryDateWithCheck(actualDate);

  const displayDateStr = isDelivered
    ? actualDateStr || revisedDateStr || baseDateStr
    : revisedDateStr || baseDateStr;
  return { baseDateStr, revisedDateStr, actualDateStr, displayDateStr };
};

const getDateColor = ({ isDelivered, baseDate }) => {
  if (isDelivered) return 'inherit';
  if (isSameOrAfterDate(new Date(), new Date(baseDate))) return 'red';
  if (isSameOrAfterDate(addBusinessDays(new Date(), 5), new Date(baseDate)))
    return '#ff9800';
  return 'inherit';
};

function OrmDateCell({
  type,
  dates,
  rowData,
  pickerState,
  handleCloseDatePicker,
  handleOpenDatePicker,
  onUpdateDate,
  emailIcons,
}) {
  const isDelivered = rowData.status === ORDER_READY_STATUS.DELIVERED;
  const { baseDate, revisedDate, actualDate } = dates;
  const { baseDateStr, revisedDateStr, displayDateStr } = getDateDetails({
    isDelivered,
    baseDate,
    revisedDate,
    actualDate,
  });

  const color = getDateColor({ isDelivered, baseDate });

  const isPickerOpen =
    pickerState.open &&
    pickerState.type === type &&
    pickerState.projectOrderReadyID === rowData.projectOrderReadyID;

  return (
    <FlexRow>
      {rowData.status === ORDER_READY_STATUS.NOT_STARTED && emailIcons}
      {!isDelivered && isPickerOpen && (
        <DatePicker
          value={revisedDate || baseDate}
          open={pickerState.open}
          onChange={onUpdateDate}
          animateYearScrolling
          margin='dense'
          clearable
          clearLabel='No Preference'
          onClose={handleCloseDatePicker}
        />
      )}
      <div
        style={{ color, cursor: isDelivered ? 'default' : 'pointer' }}
        onClick={(e) => handleOpenDatePicker(e, type, rowData)}
      >
        <FlexColumn style={{ width: 'auto' }}>
          <FtrTypography fontSize={14} style={{ color }}>
            {displayDateStr}
          </FtrTypography>
          {!isDelivered && revisedDateStr && (
            <FtrCaption fontSize={10} style={{ color }}>
              {`Orig: ${baseDateStr}`}
            </FtrCaption>
          )}
        </FlexColumn>
      </div>
    </FlexRow>
  );
}

export default OrmDateCell;
