import React, { useState, useEffect } from 'react';
import { isEmpty, isDate } from 'lodash';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';
import { Link } from 'react-router-dom';
import { DatePicker } from '@material-ui/pickers';
import Decimal from 'decimal.js';

import SubDesc from '../SubDesc';
import BlueButton from '../buttons/BlueButton';
import WhiteButton from '../buttons/WhiteButton';
import QualityAccordion from '../accordions/QualityAccordion';
import { FtrErrorText, FtrNormalText } from '../ftr-components';
import {
  FlexRowSpaceBetween,
  FlexRowCenter,
  FlexColumn,
  FlexRow,
} from '../layouts/FlexLayouts';
import DeliveryMessageDisplay from '../DeliveryMessageDisplay';
import CheckAndWarningInfo from '../info/CheckAndWarningInfo';

import { ITEM_FILE_UPLOAD_TYPE } from '../../constants/itemConstants';

import { downloadS3File } from '../../utils/fileUtils';
import { getDateStrWithMonth } from '../../utils/dateTimeUtils';
import { undeliveredItemsFilter } from '../../utils/multiCheckoutTrackingUtils';
import { isEmptyValue } from '../../utils/commonUtils';

import { getItemDetailsApi } from '../../apis/itemApi';
import { deliverItems, getTrackingByID } from '../../apis/multiCheckoutApi';
import { getPackageOptimizationInfo } from '../../apis/internationalShippingApi';

import useQualityMetrics from '../../hooks/useQualityMetrics';

import { notifyInfo } from '../../services/notificationService';

const useStyles = makeStyles(() => ({
  dialog: {
    style: { padding: '70px' },
  },
  label: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '20px',
  },
  container: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  datePickerError: {
    '& .Mui-error': {
      color: 'blue',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'blue',
    },
  },
  issueDoButton: {
    fontSize: '9pt',
    padding: '10px 15px',
    borderRadius: '5px',
  },
}));

const handleSubmitDeliveredItems = ({
  itemsData,
  deliveryDate,
  refClientPO,
  checkoutID,
  setShowDeliverPopup,
  dataRefetchFunc,
}) => {
  const body = {
    checkoutID,
    itemsData,
    deliveryDate,
    refClientPO,
  };
  deliverItems(body).then(dataRefetchFunc);
  setShowDeliverPopup(false);
};

const MAX_TOTAL_WEIGHT_IN_KG = 5;
const MAX_DIMENSION_IN_CM = 50;

function MultiCheckoutDeliverPopup(props) {
  const classes = useStyles();

  const {
    open,
    handleCancel,
    initialEstimateDeliveryDate,
    checkoutID,
    setShowDeliverPopup,
    dataRefetchFunc,
    acceptedItems = [],
  } = props;

  const [unDeliveredItems, setUnDeliveredItems] = useState([]);
  const [estimatedDeliveryDate, setEstimatedDeliveryDate] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [customerPoFiles, setCustomerPoFiles] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [refClientPO, setRefClientPO] = useState(null);
  const [pickUpUserID, setPickUpUserID] = useState(null);
  const [weighDimensionInfo, setWeighDimensionInfo] = useState({});

  const loggedInUser = useSelector((state) => state.auth.user);

  const { data: checkoutItemInfo, isLoading } = useQuery(
    ['getTrackingByID', checkoutID],
    () => getTrackingByID(checkoutID)
  );

  useEffect(() => {
    if (isEmpty(checkoutItemInfo?.acceptedItemsInfo)) {
      return;
    }
    const unDeliveredItemIDs = acceptedItems
      ?.filter(undeliveredItemsFilter)
      ?.map((item) => item.itemID);

    const _unDeliveredItems = checkoutItemInfo.acceptedItemsInfo?.reduce(
      (acc, item) => {
        if (unDeliveredItemIDs?.includes(item.itemID)) {
          const findMatchingTrackingItem = acceptedItems?.find(
            (acceptedItem) => acceptedItem.itemID === item.itemID
          );
          acc.push({
            itemID: item.itemID,
            label: `ItemID: ${item.itemID} - QuotationID: ${item.quotationID}`,
            partnerDimQuality: item.partnerDimQuality,
            partnerVisualQuality: item.partnerVisualQuality,
            isChecked: findMatchingTrackingItem.isVerified,
            delivered: findMatchingTrackingItem.delivered,
          });
        }
        return acc;
      },
      []
    );
    setUnDeliveredItems(_unDeliveredItems);
    const _selectedItems = _unDeliveredItems
      ?.filter((item) => item.isChecked)
      ?.map((item) => item.itemID);
    setSelectedItems(_selectedItems);
    setEstimatedDeliveryDate(initialEstimateDeliveryDate);
  }, [acceptedItems, checkoutItemInfo]);

  useEffect(() => {
    if (isEmpty(unDeliveredItems)) {
      return;
    }
    const firstItemID = unDeliveredItems[0].itemID;
    getItemDetailsApi(firstItemID).then(async (itemDetails) => {
      const { customerUploadFiles } = itemDetails;
      const _customerPoFiles = (customerUploadFiles || [])?.filter(
        (item) => item.type === ITEM_FILE_UPLOAD_TYPE.CUSTOMER_PO
      );
      setCustomerPoFiles(_customerPoFiles);
    });
  }, [unDeliveredItems]);

  useEffect(() => {
    if (isEmptyValue(selectedItems)) {
      return;
    }
    const itemsData = checkoutItemInfo.acceptedItemsInfo?.filter((item) =>
      selectedItems.includes(item.itemID)
    );
    const supplierIDsOfSelectedItems = [
      ...new Set(itemsData?.map((item) => item.supplierID)),
    ];
    if (supplierIDsOfSelectedItems.length === 1) {
      setPickUpUserID(supplierIDsOfSelectedItems[0]);
    } else {
      notifyInfo(
        'The selected items have been manufactured by different suppliers. The delivery message can not be generated.'
      );
    }
  }, [selectedItems]);

  useEffect(() => {
    if (isEmptyValue(selectedItems)) {
      setWeighDimensionInfo({});
      return;
    }

    const itemsData = checkoutItemInfo.acceptedItemsInfo?.filter((item) =>
      selectedItems.includes(item.itemID)
    );

    const totalWeight = itemsData?.reduce((acc, item) => {
      // Default 1kg if item.ppeInfo.weight is 0, null, or undefined
      const weight = parseFloat(item?.ppeInformation?.weight || 1);
      if (item.itemQuantity !== item.quantity) {
        return (acc += new Decimal(weight)
          .dividedBy(item.itemQuantity)
          .times(item.quantity)
          .toNumber());
      }
      return (acc += weight);
    }, 0);

    const body = {
      ppeInfoList: itemsData?.map((item) => ({
        ...item.ppeInformation,
        quantity: item.quantity,
      })),
    };

    getPackageOptimizationInfo(body).then((data) => {
      setWeighDimensionInfo({
        length: new Decimal(data.length).toFixed(2), // cm
        width: new Decimal(data.width).toFixed(2), // cm
        height: new Decimal(data.height).toFixed(2), // cm
        totalWeight: new Decimal(totalWeight).toFixed(2), // in kg
        quantity: 1,
      });
    });
  }, [selectedItems]);

  const [{ combinedItemsQuality }, { updateQualityInformation }] =
    useQualityMetrics(unDeliveredItems);

  const handleOkButtonClick = () => {
    const itemsData = combinedItemsQuality?.filter((item) =>
      selectedItems.includes(item.itemID)
    );
    handleSubmitDeliveredItems({
      itemsData,
      deliveryDate,
      refClientPO,
      checkoutID,
      setShowDeliverPopup,
      dataRefetchFunc,
    });
  };

  const onCheckboxChange = (event, itemID) => {
    const selected = event.target.checked;
    const _selectedItems = selected
      ? [...selectedItems, itemID]
      : selectedItems?.filter((item) => item !== itemID);
    setSelectedItems(_selectedItems);
  };

  const renderActionButtons = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '1rem',
        }}
      >
        <WhiteButton
          onBtnClick={handleCancel}
          btnContent='Cancel'
          size='small'
        />
        <Box style={{ width: '1rem' }}></Box>
        <BlueButton
          onBtnClick={handleOkButtonClick}
          btnContent='Issue DO'
          disabled={isEmpty(selectedItems) || !isDate(deliveryDate)}
          className={classes.issueDoButton}
        />
      </div>
    );
  };

  const renderItemQualityActions = () => {
    return combinedItemsQuality?.map((item) => {
      return (
        <div key={item.itemID}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => onCheckboxChange(event, item.itemID)}
                checked={selectedItems.includes(item.itemID)}
                name={item.label}
              />
            }
            label={item.label}
          />
          <QualityAccordion
            style={{ margin: '0 0 0 2rem' }}
            properties={{
              partnerDimQuality: item?.partnerDimQuality,
              partnerVisualQuality: item?.partnerVisualQuality,
            }}
            updateQualityInformation={updateQualityInformation}
            itemID={item.itemID}
            displayOneColumn={true}
          />
        </div>
      );
    });
  };

  const renderDeliveryMessage = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        <DeliveryMessageDisplay
          selectedItemsIDs={selectedItems}
          dropOffUserID={loggedInUser.userID}
          pickUpUserID={pickUpUserID}
          trackingInfo={checkoutItemInfo}
          isShippingToCustomer={false}
        />
      </div>
    );
  };

  const renderWightDimensionInfo = () => {
    if (isEmptyValue(weighDimensionInfo)) {
      return null;
    }

    /**
     * Valid if dimensions exceeding the max are counted as less than 2
     */
    const isDimensionValid = () => {
      let countDimensionAboveMax = 0;
      ['width', 'height', 'length'].forEach((dim) => {
        if (
          weighDimensionInfo[dim] &&
          Number(weighDimensionInfo[dim]) > MAX_DIMENSION_IN_CM
        ) {
          countDimensionAboveMax++;
        }
      });
      return countDimensionAboveMax < 2;
    };

    return (
      <FlexColumn style={{ marginBottom: '0.5rem' }}>
        <FlexRow style={{ gap: 0 }}>
          <FtrNormalText fontSize='16'>
            Dimensions: {weighDimensionInfo.length}x{weighDimensionInfo.width}x
            {weighDimensionInfo.height}cm
          </FtrNormalText>
          <CheckAndWarningInfo
            isCheck={isDimensionValid()}
            tooltipTextCheck={`Dimensions below ${MAX_DIMENSION_IN_CM}cm`}
            tooltipTextWarning={`Dimensions above ${MAX_DIMENSION_IN_CM}cm`}
          />
        </FlexRow>

        <FlexRow style={{ gap: 0 }}>
          <FtrNormalText fontSize='16'>
            Total Weight: {weighDimensionInfo.totalWeight}kg
          </FtrNormalText>
          <CheckAndWarningInfo
            isCheck={weighDimensionInfo.totalWeight < MAX_TOTAL_WEIGHT_IN_KG}
            tooltipTextCheck={`Total weight below ${MAX_TOTAL_WEIGHT_IN_KG}kgs`}
            tooltipTextWarning={`Total weight above ${MAX_TOTAL_WEIGHT_IN_KG}kgs`}
          />
        </FlexRow>
      </FlexColumn>
    );
  };

  return (
    <Dialog
      maxWidth='md'
      aria-labelledby='create-project-dialog-title'
      open={open}
      onClose={handleCancel}
      className={classes.dialog}
      fullWidth={true}
    >
      <DialogTitle id='create-project-dialog-title'>
        <SubDesc content='ISSUE DELIVERY ORDER (DO)' />
      </DialogTitle>
      {isLoading ? (
        <CircularProgress style={{ padding: '1rem 2rem' }} />
      ) : (
        <DialogContent dividers>
          <div className={classes.container}>
            {renderWightDimensionInfo()}
            <DatePicker
              label='Actual Delivery Date (to customer)'
              value={deliveryDate}
              onChange={setDeliveryDate}
              animateYearScrolling
              inputVariant='outlined'
              margin='dense'
              error={!isDate(deliveryDate)}
              className={classes.datePickerError}
            />
            <p>
              Estimated Delivery Date to customer for this order is{' '}
              {getDateStrWithMonth(estimatedDeliveryDate)}
            </p>
            <TextField
              label="Ref. Customer's PO"
              variant='outlined'
              onChange={(evt) => setRefClientPO(evt.target.value)}
              value={refClientPO}
              margin='dense'
              fullWidth
            />
            <Box style={{ height: '1rem' }} />
            <FlexRowSpaceBetween>
              {!isEmpty(customerPoFiles) ? (
                customerPoFiles?.map((item) => {
                  return (
                    <Typography key={item.fileName}>
                      Customer PO:&nbsp;
                      <Link
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadS3File(item.url);
                        }}
                      >
                        {item.fileName}
                      </Link>
                    </Typography>
                  );
                })
              ) : (
                <Typography>
                  Customer has not uploaded any PO for this checkout.
                </Typography>
              )}
            </FlexRowSpaceBetween>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                {renderItemQualityActions()}
              </Grid>
              <Grid item sm={6} alignItems='flex-end'>
                {renderDeliveryMessage()}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      )}
      {combinedItemsQuality
        ?.filter((item) => selectedItems.includes(item.itemID))
        ?.some((item) => item.delivered) && (
        <FlexRowCenter>
          <FtrErrorText style={{ mx: '10px', marginTop: '1rem' }}>
            Warning, you have selected items for which DOs have been previously
            issued. Note that the old DOs may be overwritten.
          </FtrErrorText>
        </FlexRowCenter>
      )}
      {renderActionButtons()}
    </Dialog>
  );
}

export default MultiCheckoutDeliverPopup;
