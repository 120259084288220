import React from 'react';
import { makeStyles, MenuItem, TextField } from '@material-ui/core';
import { isDate } from 'lodash';

import { DatePicker } from '@material-ui/pickers';
import { FlexColumn, FlexRowAlignStart } from '../../layouts/FlexLayouts';
import RequestedCard from '../../cards/RequestedCard';

const useStyles = makeStyles(() => ({
  reworkDeliveryDate: {
    '& .Mui-error': {
      color: 'blue',
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'blue',
    },
  },
}));

/**
 * @function
 * @param {Object} props - Component props
 * @param {boolean} [props.requiredRework] - Whether the part requires rework
 * @param {function} [props.setRequiredRework] - Function to update the value of requiredRework
 * @param {React.MutableRefObject} [props.reworkDeliveryDateFieldRef] - Ref to the rework delivery date input field
 * @param {Date} [props.reworkDeliveryDate] - The rework delivery date
 * @param {Date} [props.reworkActualDeliveryDate] - The rework actual delivery date
 * @param {function} [props.setReworkReportedDate] - Function to update the value of requiredReworkTriggerAt
 * @param {function} [props.setReworkDeliveryDate] - Function to update the value of reworkDeliveryDate
 * @param {function} [props.setReworkActualDeliveryDate] - Function to update the value of reworkActualDeliveryDate
 * @param {Date} [props.requiredReworkTriggerAt] - Timestamp when the required rework was triggered
 * @param {string} [props.requiredReworkTriggerByName] - Name of the user who triggered the required rework
 * @param {string} [props.requiredReworkTriggerByID] - ID of the user who triggered the required rework
 * @param {Object} [props.datePickerProps] - Props to be passed to the DatePicker component
 * @param {Object} [props.requiredReworkProps] - Props to be passed to the TextField component for the required rework field
 * @param {Object} [props.rest] - Additional props to be passed to the FlexRowAlignStart component
 *
 * @returns {React.ReactElement} - The RequiredReworkField component
 */
function RequiredReworkField(props) {
  const {
    requiredRework,
    setRequiredRework = () => {},
    setReworkReportedDate = () => {},
    reworkDeliveryDateFieldRef,
    reworkReportedDateRef,
    reworkDeliveryDate,
    reworkActualDeliveryDate,
    reworkReportedDate,
    setReworkDeliveryDate = () => {},
    setReworkActualDeliveryDate = () => {},
    requiredReworkTriggerAt,
    requiredReworkTriggerByName,
    requiredReworkTriggerByID,
    datePickerProps,
    requiredReworkProps,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <FlexRowAlignStart style={{ gap: '1rem' }} {...rest}>
      <FlexColumn style={{ alignItem: 'flex-end' }}>
        <TextField
          id='required-rework'
          label='Required Rework'
          select
          value={requiredRework}
          onChange={(evt) => setRequiredRework(evt.target.value)}
          variant='outlined'
          margin='dense'
          {...requiredReworkProps}
        >
          <MenuItem value={1}>Yes</MenuItem>
          <MenuItem value={0}>No</MenuItem>
        </TextField>
        {requiredRework === 1 && requiredReworkTriggerAt && (
          <RequestedCard
            requestedAt={requiredReworkTriggerAt}
            requestedByName={requiredReworkTriggerByName}
            requestedByID={requiredReworkTriggerByID}
            style={{ marginBottom: '1rem' }}
          />
        )}
      </FlexColumn>
      {requiredRework === 1 && (
        <DatePicker
          label='Rework Reported Date'
          value={reworkReportedDate}
          inputRef={reworkReportedDateRef}
          onChange={setReworkReportedDate}
          animateYearScrolling
          inputVariant='outlined'
          margin='dense'
          clearable
          clearLabel='No Preference'
          fullWidth={true}
          className={classes.reworkDeliveryDate}
          error={!isDate(reworkReportedDate)}
          {...datePickerProps}
        />
      )}
      {requiredRework === 1 && (
        <DatePicker
          inputRef={reworkDeliveryDateFieldRef}
          label='Rework Delivery Date'
          value={reworkDeliveryDate}
          onChange={setReworkDeliveryDate}
          animateYearScrolling
          inputVariant='outlined'
          margin='dense'
          clearable
          clearLabel='No Preference'
          fullWidth={true}
          className={classes.reworkDeliveryDate}
          error={!isDate(reworkDeliveryDate)}
          {...datePickerProps}
        />
      )}
      {requiredRework === 1 && (
        <DatePicker
          label='Rework Actual Delivery Date'
          value={reworkActualDeliveryDate}
          onChange={setReworkActualDeliveryDate}
          animateYearScrolling
          inputVariant='outlined'
          margin='dense'
          clearable
          clearLabel='No Preference'
          fullWidth={true}
          className={classes.reworkDeliveryDate}
          error={!isDate(reworkActualDeliveryDate)}
          {...datePickerProps}
        />
      )}
    </FlexRowAlignStart>
  );
}

export default RequiredReworkField;
