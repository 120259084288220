export const ERROR_MESSAGE = {
  INTERNET_CONNECTION:
    'Unable to reach the server. Please check your connection and try again.',
  FILE_SIZE_TOO_LARGE:
    'File size too large, please contact us directly at sales@factorem.co with your enquiries',
};

export const SEVERITY = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info',
};
