import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import CheckIcon from '../icons/CheckIcon';
import WarningIcon from '../icons/WarningIcon';

import { isNumber } from '../../utils/numberUtils';
import { isNotFound } from '../../utils/tdeUtils';

const useStyles = makeStyles(() => ({
  infoIcon: {
    marginLeft: '10px',
  },
}));

/**
 *
 * @param {object} props
 * @param {string | number} props.value
 * @param {string | number} props.compareValue
 * @param {string | number} props.defaultValue
 * @param {string} props.className
 * @param {boolean} props.isCheck
 */
function CheckAndWarningInfoForThreeWayValueComparison(props) {
  const {
    value = '',
    compareValue = '',
    defaultValue = '',
    isCheck = false,
    className = '',
    ...rest
  } = props;
  const classes = useStyles();

  const isFinalValueEqualToCompareValue = isNumber(compareValue)
    ? +value === +compareValue
    : value === compareValue;

  const toolTipDescription = useMemo(() => {
    return (
      <ul>
        <li>Platform (default) value: {defaultValue || 'NIL'}</li>
        <li>
          TDE value:{' '}
          {!compareValue || isNotFound(compareValue) ? 'NIL' : compareValue}
        </li>
        <li>Customer submitted value: {value || 'NIL'}</li>
      </ul>
    );
  });

  const shouldRenderCheckValue =
    (isFinalValueEqualToCompareValue && isCheck === undefined) || isCheck;

  if (shouldRenderCheckValue) {
    return (
      <CheckIcon
        className={`${classes.infoIcon} ${className}`}
        {...rest}
        toolTipText={toolTipDescription}
      />
    );
  }

  return (
    <WarningIcon
      className={`${classes.infoIcon} ${className}`}
      {...rest}
      toolTipText={toolTipDescription}
    />
  );
}

export default CheckAndWarningInfoForThreeWayValueComparison;
