/* eslint-disable react/display-name */
import React, { memo } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Replay } from '@material-ui/icons';

import { IconButton, Tooltip } from '@material-ui/core';

import { colors } from '../../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  iconButton: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
    },
  },
}));

export const RefetchButton = memo(
  ({ handleClick = () => {}, disabled = false }) => {
    const classes = useStyles();

    return (
      <Tooltip title='Refetch'>
        <IconButton
          className={classes.iconButton}
          aria-label='regenerate'
          size='small'
          onClick={handleClick}
          disabled={disabled}
        >
          <Replay color='primary' style={{ fontSize: '1.5rem' }} />
        </IconButton>
      </Tooltip>
    );
  }
);
