import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Cookies } from 'react-cookie';

import { colors } from '../../palette';

import { Tooltip } from '@material-ui/core';
import { Info as InfoIcon } from '@material-ui/icons';

import withModifyPdfPopupHOC from '../../hocs/withModifyPdfHOC';

import { isEmptyValue } from '../../utils/commonUtils';
import { isPdfFile } from '../../utils/fileUtils';

import { notifyError } from '../../services/notificationService';
import { uploadFileToS3 } from '../../services/s3Service';

import { getItemTitleplatePayload } from '../../apis/itemApi';

import { updateItemCanvasData } from '../../actions';

import { USER_AGENT_INFO_KEY } from '../../constants';
import { MODIFY_PDF_S3_FOLDER } from '../../constants/s3Constants';
import { MAX_PDF_FILE_SIZE } from '../../constants/fileConstants';
import { TDE_LOCATIONS } from '../../constants/technicalDrawingExtractorConstants';
import { techMapping } from '../../constants/PPEConstants.js';

import {
  FlexColumn,
  FlexRow,
  FlexRowCenter,
  // FlexColumnCenter,
} from '../../components/layouts/FlexLayouts';
import { FtrTextField, FtrButton } from '../../components/ftr-components';
import FilesUploadButton from '../../components/FilesUploadButton';

/**
 * Tool for modifying PDF files with canvas data
 * @param {Object} props
 * @param {Function} props.openPdfEditorPopup - Function to open PDF editor
 * @param {Function} props.submitCanvasData - Function to submit canvas data
 */
export function ModifyPdfsTool(props) {
  const cookies = new Cookies();

  const { openPdfEditorPopup, submitCanvasData } = props;

  const [pdfUrl, setPdfUrl] = useState('');
  const [itemID, setItemID] = useState(null);
  const [item, setItem] = useState(null);
  const [loadingItemInfo, setLoadingItemInfo] = useState(false);
  const [titleplatePayload, setTitleplatePayload] = useState(null);
  const [canvasData, setCanvasData] = useState(null);

  const handleLoadItemInfo = () => {
    const parsedItemId = parseInt(itemID);
    if (isEmptyValue(itemID) || isNaN(parsedItemId) || parsedItemId <= 0) {
      notifyError('Please enter a valid item ID');
      return;
    }

    setLoadingItemInfo(true);
    getItemTitleplatePayload(parsedItemId)
      .then((data) => {
        setLoadingItemInfo(false);
        setItem(data?.item);

        const itemPdfFile = data?.customerCadFileUploadInfo?.find((file) =>
          isPdfFile(file?.s3ObjectUrl)
        );

        if (!isEmptyValue(itemPdfFile)) {
          setPdfUrl(itemPdfFile?.s3ObjectUrl);
        }

        setTitleplatePayload(data?.titleplatePayload);

        if (!isEmptyValue(data?.canvasData)) {
          setCanvasData(data.canvasData);
        }
      })
      .catch((err) => {
        setLoadingItemInfo(false);
        notifyError(err.message || 'Load item info failed');
      });
  };

  const handleUploadPdfFile = async (files) => {
    const file = files[0];

    if (file.size > MAX_PDF_FILE_SIZE) {
      notifyError('File size exceeds maximum limit of 100MB');
      return;
    }

    const fileName = file.name;
    const data = await uploadFileToS3(
      file,
      `${MODIFY_PDF_S3_FOLDER}/${fileName}`,
      fileName
    );

    const s3ObjectUrl = data.Location;

    if (isEmptyValue(s3ObjectUrl)) {
      notifyError('Upload pdf failed');
      return;
    }

    setPdfUrl(s3ObjectUrl);
  };

  const buildUpdateOptions = () => {
    const userAgentInfo = cookies.get(USER_AGENT_INFO_KEY);

    return {
      tdeLocation: TDE_LOCATIONS.ADMIN_PLATFORM_MODIFY_PDFS_TOOL,
      userAgentInfo,
      tech: techMapping[item?.technology] || 'unknown',
      tdeDbUpdate: true,
    };
  };

  const handleCanvasDataUpdate = (data) => {
    if (!isEmptyValue(data?.canvasData)) {
      let updatedData = {
        ...data,
        pdfUrl,
      };
      submitCanvasData(itemID, updatedData, buildUpdateOptions());
    }
  };

  const renderModifyPdfButton = () => {
    const disabledButton =
      isEmptyValue(pdfUrl) || isEmptyValue(titleplatePayload);
    return (
      <>
        {disabledButton && (
          <Tooltip title='Please upload PDF and select titleplate'>
            <InfoIcon style={{ color: colors.neutral050 }} />
          </Tooltip>
        )}
        <FtrButton
          color='blue'
          disabled={disabledButton}
          style={{ minWidth: 150 }}
          onClick={() => {
            openPdfEditorPopup({
              pdfUrl,
              titleplatePayload,
              downloadPdf: true,
              canvasData,
              setCanvasData: handleCanvasDataUpdate,
            });
          }}
        >
          Modify PDF
        </FtrButton>
      </>
    );
  };

  const renderItemSelection = () => {
    return (
      <FlexRowCenter>
        <FlexRowCenter
          style={{
            width: '100%',
            margin: 'auto',
            boxSizing: 'border-box',
          }}
        >
          <FlexRow style={{ flexGrow: 1 }}>
            <FtrTextField
              label='Item ID'
              value={itemID}
              onChange={(e) => setItemID(e.target.value)}
              fullWidth
              style={{
                marginBottom: 0,
              }}
            />
            <FtrButton
              size='small'
              color='blue'
              variant='outlined'
              onClick={handleLoadItemInfo}
              disabled={isEmptyValue(itemID) || loadingItemInfo}
              loading={loadingItemInfo}
            >
              Load Item Info
            </FtrButton>
          </FlexRow>
        </FlexRowCenter>
      </FlexRowCenter>
    );
  };

  const renderPdfUrlField = () => {
    return (
      <FlexRowCenter
        style={{
          width: '100%',
          margin: 'auto',
          boxSizing: 'border-box',
        }}
      >
        <FlexRow style={{ flexGrow: 1 }}>
          <FtrTextField
            label='PDF URL'
            value={pdfUrl}
            onChange={(e) => setPdfUrl(e.target.value)}
            fullWidth
            style={{
              marginBottom: 0,
            }}
          />
        </FlexRow>
        <FilesUploadButton
          buttonText='Upload PDF'
          handleUploadFiles={handleUploadPdfFile}
          multiple={false}
          disabled={!isEmptyValue(pdfUrl)}
          accept='.pdf'
        />
        {renderModifyPdfButton()}
      </FlexRowCenter>
    );
  };

  return (
    <FlexColumn
      style={{
        margin: '2rem 0',
        padding: '0 2rem',
        boxSizing: 'border-box',
        gap: '1rem',
        marginBottom: '1rem',
      }}
    >
      {renderItemSelection()}
      {renderPdfUrlField()}
    </FlexColumn>
  );
}

function matchDispatchToProps(dispatch) {
  return {
    submitCanvasData: (itemID, data, options) =>
      dispatch(updateItemCanvasData(itemID, data, options)),
  };
}

const withConnect = connect(null, matchDispatchToProps);

export default compose(withConnect, withModifyPdfPopupHOC)(ModifyPdfsTool);
