import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { Tab, Tabs } from '@material-ui/core';

import AllQuotesDataGridPagination from './manage-quotes/AllQuotesDataGridPagination';
import RejectedQuotesDataGrid from './manage-quotes/RejectedQuotesDataGrid';
import PpeDataAllOrdersDataGrid from './manage-quotes/PpeDataAllOrdersDataGrid';
import DisabledQuotesDataGrid from './manage-quotes/DisabledQuotesDataGrid';

import { isAdminOrHigherRole } from '../utils/roleUtils';

import { getUserRoleSelector } from '../selectors/userSelector';

import { ROLE_TYPES } from '../constants';

import { colors } from '../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TAB_KEYS = {
  ALL_QUOTES: 'All Quotes',
  REJECTED_QUOTES: 'Rejected Quotes',
  DISABLED_QUOTES: 'Disabled Quotes',
  PPE_QUOTE_INFO: 'PPE Quote Info',
};

const TABS = [
  TAB_KEYS.ALL_QUOTES,
  TAB_KEYS.REJECTED_QUOTES,
  TAB_KEYS.DISABLED_QUOTES,
  TAB_KEYS.PPE_QUOTE_INFO,
];

function ManageQuotes() {
  const classes = useStyles();

  const role = useSelector(getUserRoleSelector);

  const [viewableTabs, setViewableTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (isAdminOrHigherRole(role)) {
      setViewableTabs(TABS);
      return;
    }
    if ([ROLE_TYPES.SALES_CONSULTANT].includes(role)) {
      setViewableTabs([TAB_KEYS.ALL_QUOTES]);
    }
  }, [role]);

  const renderTab = () => {
    const tabName = TABS[selectedTab];
    switch (tabName) {
      case TAB_KEYS.ALL_QUOTES:
        return <AllQuotesDataGridPagination />;
      case TAB_KEYS.REJECTED_QUOTES:
        return <RejectedQuotesDataGrid />;
      case TAB_KEYS.DISABLED_QUOTES:
        return <DisabledQuotesDataGrid />;
      case TAB_KEYS.PPE_QUOTE_INFO:
        return <PpeDataAllOrdersDataGrid />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.body}>
      <h1>Manage Quotes</h1>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
      >
        {viewableTabs?.map((tab) => {
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
      {renderTab()}
    </div>
  );
}

export default ManageQuotes;
