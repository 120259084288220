import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';

import { Tab, Tabs } from '@material-ui/core';

import CreateInvoiceMuiTable from './manage-invoices/CreateInvoiceMuiTable';
import ViewInvoicesMuiTable from './manage-invoices/ViewInvoicesMuiTable';
import XeroInvoices from './manage-invoices/XeroInvoicesItemsDataGrid';

import { colors } from '../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TAB_KEYS = {
  XERO_INVOICES: 'Xero Invoices',
  VIEW_INVOICES: 'View Invoices (Old)',
  VIEW_INVOICES_MUI_TABLE: 'View Invoices MUI',
  CREATE_INVOICE: 'Create Invoice (Old)',
  CREATE_INVOICE_MUI_TABLE: 'Create Invoice MUI',
};

const TABS = [
  TAB_KEYS.XERO_INVOICES,
  TAB_KEYS.VIEW_INVOICES,
  TAB_KEYS.CREATE_INVOICE,
];

function ManageInvoices() {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTab = () => {
    const tabName = TABS[selectedTab];
    switch (tabName) {
      case TAB_KEYS.VIEW_INVOICES:
        return <ViewInvoicesMuiTable />;
      case TAB_KEYS.CREATE_INVOICE:
        return <CreateInvoiceMuiTable />;
      case TAB_KEYS.XERO_INVOICES:
        return <XeroInvoices />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.body}>
      <h1>Manage Invoices</h1>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
      >
        {TABS?.map((tab) => {
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
      {renderTab()}
    </div>
  );
}

export default ManageInvoices;
