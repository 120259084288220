import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { FormControl, TextField } from '@material-ui/core';

import { FtrS3 } from './FtrTypography';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 10,
    '.MuiOutlinedInput-root': {
      borderRadius: 10,
    },
  },
}));

function FtrTextArea(props) {
  const classes = useStyles();

  const { title, fullWidth = false } = props;

  return (
    <FormControl style={{ width: fullWidth && '100%' }}>
      {title && (
        <FtrS3 style={{ color: colors.neutral070, marginBottom: 4 }}>
          {title}
        </FtrS3>
      )}
      <TextField
        classes={{
          root: classes.root,
        }}
        multiline
        minRows={3}
        variant='outlined'
        InputProps={{
          style: {
            borderRadius: 10,
          },
        }}
        {...props}
      />
    </FormControl>
  );
}

export default FtrTextArea;
