import React from 'react';

export const TopRightPositionFixed = (props) => {
  return (
    <div
      style={{
        position: 'fixed',
        right: 0,
        top: 0,
        ...(props.style || {}),
      }}
    >
      {props.children}
    </div>
  );
};

export const TopRightPositionAbsolute = (props) => {
  return (
    <div
      style={{
        position: 'absolute',
        right: 0,
        top: 0,
        ...(props.style || {}),
      }}
    >
      {props.children}
    </div>
  );
};

export const RightBottomPositionAbsolute = (props) => {
  return (
    <div
      style={{
        position: 'absolute',
        right: 0,
        bottom: 0,
        ...(props.style || {}),
      }}
    >
      {props.children}
    </div>
  );
};

export const CenterPositionAbsolute = ({ children, style, ...rest }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        ...(style || {}),
      }}
      {...rest}
    >
      {children}
    </div>
  );
};
