import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { colors } from '../../palette';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';

import { ColorPalette } from 'material-ui-color';

// Import constants
import { itemColorPalette } from '../../constants/itemColorPalette';
import { get, isEmpty } from 'lodash';

// Style components
const useStyles = makeStyles(() => ({
  colorButton: {
    padding: '5px 14px',
    width: '100%',
    justifyContent: 'left',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: colors.fontGrey,
    marginBottom: '13px',
    textTransform: 'none',
    fontSize: '16px',
  },
  dialogTitle: {
    fontSize: 16,
    marginBottom: 7,
    color: colors.fontGrey,
    fontWeight: 600,
  },
}));

const PREDEFINED_COLORS_OPTION = 'PREDEFINED_COLORS';
const CUSTOM_COLOR_OPTION = 'CUSTOM_COLOR';

function ItemColorPalettePopup(props) {
  const classes = useStyles();

  const {
    dialogOpen,
    handleDialogClose,
    handleSubmitColor,
    colorPalette,
    value,
  } = props;

  const [dialogTitle, setDialogTitle] = useState('Pantone Color Picker');
  const [optionMode, setOptionMode] = useState(PREDEFINED_COLORS_OPTION);
  const [colorPaletteMapping, setColorPaletteMapping] = useState({});
  const [selectedColor, setSelectedColor] = useState('');
  const [hasCustomColor, setHasCustomColor] = useState(false);
  const [customColor, setCustomColor] = useState('');
  const [customColorError, setCustomColorError] = useState(null);

  useEffect(() => {
    const _colorPaletteMapping = isEmpty(colorPalette)
      ? itemColorPalette
      : { ...colorPalette };
    setHasCustomColor(!!get(_colorPaletteMapping, 'Custom Color'));
    delete _colorPaletteMapping['Custom Color'];
    const isRalColor = Object.keys(_colorPaletteMapping)?.some((key) =>
      key.includes('RAL')
    );
    if (isRalColor) {
      setDialogTitle('RAL Color Picker');
    }
    setColorPaletteMapping(_colorPaletteMapping);
    if (get(_colorPaletteMapping, value)) {
      setOptionMode(PREDEFINED_COLORS_OPTION);
      setSelectedColor(value);
    } else {
      setOptionMode(CUSTOM_COLOR_OPTION);
      setCustomColor(value);
    }
  }, [colorPalette, value]);

  const handleOkButtonClick = () => {
    if (optionMode === PREDEFINED_COLORS_OPTION) {
      handleSubmitColor(selectedColor);
    } else {
      if (isEmpty(customColor)) {
        setCustomColorError(`Custom color is empty`);
        return;
      }
      handleSubmitColor(customColor);
    }
  };

  const renderColorPalette = () => {
    return (
      <ColorPalette
        palette={colorPaletteMapping}
        onSelect={(color) => setSelectedColor(color)}
        value={value}
      />
    );
  };

  const renderColorPaletteWithCustomColor = () => {
    return (
      <FormControl component='fieldset'>
        <RadioGroup
          value={optionMode}
          onChange={(event) => {
            setOptionMode(event.target.value);
            setCustomColorError(null);
            setCustomColor('');
          }}
        >
          <FormControlLabel
            value={PREDEFINED_COLORS_OPTION}
            control={<Radio color='primary' />}
            label='Colors'
          />
          <div
            style={
              optionMode !== PREDEFINED_COLORS_OPTION
                ? { pointerEvents: 'none', opacity: '0.4' }
                : {}
            }
          >
            {renderColorPalette()}
          </div>
          <FormControlLabel
            classes={{
              root: classes.ignoreLabel,
            }}
            value={CUSTOM_COLOR_OPTION}
            control={<Radio color='primary' />}
            label={
              <span>
                Custom Color&nbsp;
                <span
                  style={{
                    fontSize: '0.8rem',
                    color: colors.blue050,
                  }}
                >
                  <i>
                    eg: <b>Yellow orange (RAL 2000)</b>
                  </i>
                </span>
              </span>
            }
          />
          <TextField
            inputRef={(input) => input && input.focus()}
            variant='outlined'
            autoFocus={optionMode === CUSTOM_COLOR_OPTION}
            margin='dense'
            id='custom-color'
            label='Custom Color'
            type='text'
            fullWidth
            onChange={(evt) => {
              setCustomColor(evt.target.value);
              setCustomColorError(null);
            }}
            value={customColor}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={optionMode !== CUSTOM_COLOR_OPTION}
            error={customColorError}
            helperText={customColorError}
            placeholder='eg: Yellow orange (RAL 2000)'
          />
          <Typography variant='caption'>
            <i>
              Refer{' '}
              <a
                href='https://www.ralcolor.com/'
                target='_blank'
                rel='noreferrer'
              >
                https://www.ralcolor.com/
              </a>{' '}
              for more colors
            </i>
          </Typography>
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle className={classes.dialogTitle}>{dialogTitle}</DialogTitle>
        <DialogContent dividers>
          {hasCustomColor && renderColorPaletteWithCustomColor()}
          {!hasCustomColor && renderColorPalette()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleOkButtonClick}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function mapStateToProps() {
  return {};
}

function matchDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(ItemColorPalettePopup);
