import { useState } from 'react';

import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';

import {
  addOrUpdateCadPart,
  addTechnicalDrawing,
  autoMatchingTechnicalDrawingFiles,
  extractTdeForItemAndGetPrice,
  generateDefectAction,
  generateImageAndGetPriceAction,
  generateImageAndGetPriceExcludePartHasTechnicalDrawing,
  generateImageForItem,
  GET_PPE_PRICE,
  getPpePriceForCadPart,
  removeCadPartAction,
  updateCadPartIfExists,
  updatePartUploadFormState,
} from '../actions';

import { getFormDataAvailableSelector } from '../selectors/formDataAvailableSelector';
import { getPartUploadFormSelector } from '../selectors/partUploadFormSelector';
import {
  getUserIDSelector,
  getUserUnitTypeSelector,
} from '../selectors/userSelector';

import { separateZipAndNonZipFiles } from '../actions/utils/cadPartUploadUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { filterFilesByExtension } from '../utils/fileUtils';
import { getItemNameFromCadFile } from '../utils/itemUtils';
import { extractAllZipFiles } from '../utils/zipUtils';

import {
  get2DImageS3Key,
  uploadCadFileToS3,
  uploadPublicFileToS3,
} from '../services/s3Service';

import useSnackbarStore from '../zustandStores/useSnackbarStore';

import { TWO_D_IMAGE_URLS_KEY } from '../constants';
import { SEVERITY } from '../constants/errorMessageConstants';
import { FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING } from '../constants/featureFlagConstants';
import { DEFAULT_TOLERANCE_STANDARD } from '../constants/itemConstants';
import {
  MAX_UPLOAD_FILES,
  MAX_UPLOAD_FILES_ERROR_MESSAGE,
  TECHNOLOGY_OPTION_TYPE,
} from '../constants/NewPartConstants';
import { IS_SAMPLE_PART } from '../constants/samplePartsConstants';

// ------------------------------------------------------------------------------

const useCadUpload = () => {
  const userID = useSelector(getUserIDSelector);
  const unitType = useSelector(getUserUnitTypeSelector);
  const formDataAvailable = useSelector(getFormDataAvailableSelector);
  const partUploadForm = useSelector(getPartUploadFormSelector);

  const dispatch = useDispatch();

  const [technologyParamsInfo, setTechnologyParamsInfo] = useState({
    technology: TECHNOLOGY_OPTION_TYPE.CNC_MACHINING,
  });

  const { setSnackbarStore } = useSnackbarStore((state) => state);

  const updateTechnologyParamsInfo = (params) => {
    setTechnologyParamsInfo(params);
  };

  const updateTechnologyParamsInfoForAllParts = (params) => {
    setTechnologyParamsInfo(params);
    for (const part of formDataAvailable) {
      dispatch(
        updateCadPartIfExists({
          id: part.id,
          ...params,
        })
      );
      dispatch(getPpePriceForCadPart(part.id));
    }
  };

  const uploadCadFilesBase = async (files = [], isSampleParts = false) => {
    const { zipFiles, normalFiles } = separateZipAndNonZipFiles(files);

    const fullFilesList =
      isEmptyValue(zipFiles) ||
      FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING !== 'true'
        ? normalFiles
        : [...normalFiles, ...(await extractAllZipFiles(zipFiles))];

    let processFiles = fullFilesList;

    if (FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING === 'true') {
      const supportedFileTypes = partUploadForm.supportedFileTypes || [];
      if (!isEmptyValue(supportedFileTypes) && !isSampleParts) {
        processFiles = filterFilesByExtension(
          fullFilesList,
          supportedFileTypes
        );
        const countFilteredFiles = fullFilesList.length - processFiles.length;

        if (countFilteredFiles > 0) {
          setSnackbarStore(
            `${countFilteredFiles} ${countFilteredFiles > 1 ? 'files are' : 'file is'} not supported due to incompatible file types.`,
            {
              severity: SEVERITY.WARNING,
            }
          );
        }
      }
    }

    if (processFiles.length + formDataAvailable.length > MAX_UPLOAD_FILES) {
      setSnackbarStore(MAX_UPLOAD_FILES_ERROR_MESSAGE, {
        severity: SEVERITY.WARNING,
      });
      return;
    }

    const newItemList = [];
    for (const file of processFiles) {
      const id = nanoid();
      newItemList.push(id);
      let cadPart = {
        id,
        uploadStatus: 'loading',
        imageStatus: 'loading',
        ppePricingStatus: 'loading',
        ...technologyParamsInfo,
        status: 1,
        qty: 1,
        userID,
        remarks: '',
        checked: true,
        deleted: false,
        imageFile: '',
        deliveryPreference: 'on_premise',
        price: null,
        markupPercent: null,
        totalPrice: null,
        originalPrice: null,
        expectedLeadTime: null,
        unitType,
        toleranceStandard: DEFAULT_TOLERANCE_STANDARD,
      };
      dispatch(addOrUpdateCadPart(cadPart));
      try {
        const { s3ObjectUrl, fileSize } = await uploadCadFileToS3(
          file,
          isSampleParts
        );
        cadPart = {
          id,
          fileSize,
          s3ObjectUrl,
          uploadStatus: 'success',
          cadPart: [s3ObjectUrl],
          name: getItemNameFromCadFile(s3ObjectUrl),
        };
        dispatch(addOrUpdateCadPart(cadPart));
      } catch (error) {
        const message = `Failed to upload file ${file.name}. Error: ${error.message}`;
        setSnackbarStore(message, {
          severity: SEVERITY.ERROR,
          errorTitle: '[WARNING] Failed to upload file',
          additionalInfo: {
            userID,
            file: {
              name: file.name,
              size: file.size,
            },
          },
        });
        dispatch(removeCadPartAction(id));
      }
    }

    return newItemList;
  };

  const uploadCadFiles = async (files = []) => {
    const newItemList = await uploadCadFilesBase(files);
    for (const itemID of newItemList) {
      dispatch(generateImageAndGetPriceAction(itemID));
    }

    if (!userID) {
      dispatch(
        updatePartUploadFormState({
          totalUploadCountGuestUser:
            (partUploadForm?.totalUploadCountGuestUser || 0) + files.length,
        })
      );
    }
  };

  const uploadCadFilesStep1 = async (files = []) => {
    if (FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING !== 'true') {
      return uploadCadFiles(files);
    }

    const newItemList = await uploadCadFilesBase(files);

    // this should be fast and a sync dispatch
    dispatch(autoMatchingTechnicalDrawingFiles());

    for (const itemID of newItemList) {
      dispatch(generateImageAndGetPriceExcludePartHasTechnicalDrawing(itemID));
      dispatch(generateDefectAction(itemID));
    }
  };

  const uploadCadFilesStep2 = async (files = [], isSampleParts = false) => {
    if (!userID) {
      dispatch(
        updatePartUploadFormState({
          totalUploadCountGuestUser:
            (partUploadForm?.totalUploadCountGuestUser || 0) + files.length,
        })
      );
    }

    if (FEATURE_FLAG_TECHNICAL_DRAWING_AUTO_MATCHING !== 'true') {
      return uploadCadFiles(files);
    }

    const newItemList = isSampleParts
      ? await processSampleParts(files)
      : await uploadCadFilesBase(files);

    for (const itemID of newItemList) {
      // call this so that image generation and tde can be run in parallel
      dispatch(generateImageForItem(itemID));
    }

    dispatch(
      autoMatchingTechnicalDrawingFiles({
        getPpePrice: GET_PPE_PRICE.YES,
      })
    );

    for (const itemID of newItemList) {
      dispatch(extractTdeForItemAndGetPrice(itemID));
      dispatch(generateDefectAction(itemID));
    }
  };

  const removeCadPart = (id) => {
    dispatch(removeCadPartAction(id));
  };

  /**
   * sample files will contain some additional data like pdf file, part config, etc
   *
   * @param {*} files
   */
  async function processSampleParts(files) {
    const cadFileArr = files.map((o) => o.cadFile);
    const isSampleParts = IS_SAMPLE_PART.YES;

    const newItemList = await uploadCadFilesBase(cadFileArr, isSampleParts);

    for (const [index, itemID] of newItemList.entries()) {
      // update config like technology, material,...
      const partConfig = files[index]?.partConfig;
      if (!isEmptyValue(partConfig)) {
        dispatch(
          updateCadPartIfExists({
            id: itemID,
            ...files[index]?.partConfig,
          })
        );
      }

      // add the technical drawing pdf file if it has
      const pdfFile = files[index]?.pdfFile;
      if (pdfFile) {
        const { s3ObjectUrl } = await uploadCadFileToS3(pdfFile, isSampleParts);
        dispatch(addTechnicalDrawing(itemID, [s3ObjectUrl]));
      }

      // add pregenerated image for SMF part
      const imageFile = files[index]?.imageFile;
      if (imageFile) {
        const s3ImageKey = get2DImageS3Key(imageFile);
        const { s3ObjectUrl } = await uploadPublicFileToS3(
          imageFile,
          s3ImageKey
        ).catch((err) => {
          setSnackbarStore(
            `Failed to upload file ${imageFile.name}. Error: ${err.message}`,
            {
              severity: SEVERITY.ERROR,
              additional: {
                imageFile,
                s3ImageKey,
              },
            }
          );
        });

        dispatch(
          updateCadPartIfExists({
            id: itemID,
            [TWO_D_IMAGE_URLS_KEY]: [s3ObjectUrl],
            usePreImage: true, // this to prevent image generation for sample part
            imageStatus: 'success',
          })
        );
      } else {
        dispatch(generateImageForItem(itemID));
      }
    }

    return newItemList;
  }

  return [
    {
      data: formDataAvailable,
    },
    {
      uploadCadFiles,
      uploadCadFilesStep1,
      uploadCadFilesStep2,
      removeCadPart,
      updateTechnologyParamsInfo,
      updateTechnologyParamsInfoForAllParts,
    },
  ];
};

export default useCadUpload;
