import { jsPDF } from 'jspdf';

import { asyncMap } from './arrayUtils';

// -------------------------------------------------------------------------------------------------

export async function convertImagesToPDF(images) {
  let pdf;

  const imgArr = await asyncMap(images, async (imageUrl) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Avoid CORS issues
      img.src = imageUrl;

      img.onload = () => {
        resolve(img);
      };
    });
  });

  imgArr.forEach((img, index) => {
    const imgAspectRatio = img.width / img.height;

    // Determine orientation based on aspect ratio
    const isLandscape = imgAspectRatio > 1;

    if (!pdf) {
      pdf = new jsPDF(isLandscape ? 'landscape' : 'portrait');
    }

    // Set PDF page size dynamically based on orientation
    const pageWidth = isLandscape
      ? pdf.internal.pageSize.getWidth()
      : pdf.internal.pageSize.getHeight();
    const pageHeight = isLandscape
      ? pdf.internal.pageSize.getHeight()
      : pdf.internal.pageSize.getWidth();

    // Scale image to fit the page dimensions
    let imgWidth, imgHeight;
    if (isLandscape) {
      imgWidth = pageWidth;
      imgHeight = imgWidth / imgAspectRatio;
    } else {
      imgHeight = pageHeight;
      imgWidth = imgHeight * imgAspectRatio;
    }

    pdf.addImage(img, 'JPEG', 0, 0, imgWidth, imgHeight);

    if (images.length - 1 !== index) {
      pdf.addPage('a4', isLandscape ? 'landscape' : 'portrait');
    }
  });

  return pdf;
}

export async function exportImagesToPDF(images, fileName = 'images.pdf') {
  const pdf = await convertImagesToPDF(images);
  pdf.save(fileName);
}
