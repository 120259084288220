import React, { useEffect, useState } from 'react';
import { Edit as EditIcon } from '@material-ui/icons';
import { IconButton, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index';
import { useSelector } from 'react-redux';

import BlueButton from '../../components/buttons/BlueButton';
import WhiteButton from '../../components/buttons/WhiteButton';

import { isAdminOrHigherRole } from '../../utils/roleUtils';
import { addressInfoText } from '../../utils/addressUtils';

import { getUserSelector } from '../../selectors/userSelector';

import useUserShippingAddress from '../../hooks/useUserShippingAddressHook';

const useStyles = makeStyles(() => ({
  editShipmentNotes: {
    marginRight: '10px',
  },
}));

const ShipmentNotes = ({
  isEditMode,
  shipmentNotes,
  onEditIconClick,
  onSubmitEdit,
  onCancelEdit,
  supplierID,
  isNonSgpSupplier,
}) => {
  const classes = useStyles();
  const { role: userRole } = useSelector(getUserSelector);

  const [{ addresses: supplierAddressInfo }] =
    useUserShippingAddress(supplierID);
  const shippingAddress = supplierAddressInfo?.shipping;

  const text = isNonSgpSupplier ? (
    ''
  ) : (
    <>
      Collection from{' '}
      <b>
        {shippingAddress?.contactName}, {shippingAddress?.contactNumber},{' '}
        {addressInfoText(shippingAddress)}
      </b>
      . Please contact us in case of any changes.
    </>
  );

  const [newShipmentNotes, setNewShipmentNotes] = useState(shipmentNotes);

  useEffect(() => {
    // set default shipment notes
    if (isEditMode) {
      const notes = isNonSgpSupplier
        ? `Ship to Howely Logistics, Union Building, 3 Lorong Bakar Batu, #04-05, Singapore 348741.\n\nPlease ensure all billing or tax payment addresses are 81 Ayer Rajah Crescent, #01-54, Singapore 139967.`
        : 'Self-collection by courier on (date), (time range)';
      setNewShipmentNotes(shipmentNotes || notes);
    }
  }, [isEditMode, isNonSgpSupplier, shipmentNotes]);

  const renderShipmentText = () => {
    return (
      <div style={{ marginTop: '15px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ fontWeight: 'bold' }}>Shipment Notes</div>
          {isAdminOrHigherRole(userRole) && (
            <IconButton
              aria-label='edit'
              size='small'
              onClick={onEditIconClick}
            >
              <EditIcon />
            </IconButton>
          )}
        </div>
        <div
          style={{
            maxWidth: '600px',
            wordWrap: 'break-word',
            marginTop: '5px',
          }}
        >
          {shipmentNotes || 'N.A.'}
        </div>
      </div>
    );
  };

  const renderEditShipmentNotesArea = () => {
    return (
      <div style={{ marginTop: '15px' }}>
        <TextField
          style={{ width: '50%' }}
          label='Shipping Notes (if any)'
          variant='outlined'
          multiline={true}
          minRows={6}
          maxRows={6}
          value={newShipmentNotes}
          onChange={(e) => {
            setNewShipmentNotes(e.target.value);
          }}
          margin='dense'
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            margin: '10px',
          }}
        >
          <BlueButton
            size='small'
            onBtnClick={() => onSubmitEdit(newShipmentNotes)}
            btnContent='Save Edit'
            className={classes.editShipmentNotes}
          />
          <WhiteButton
            size='small'
            onBtnClick={() => {
              setNewShipmentNotes(shipmentNotes);
              onCancelEdit();
            }}
            btnContent='Cancel'
            type='cancelEdit'
          />
        </div>
      </div>
    );
  };

  return (
    <div style={{ marginBottom: '15px' }}>
      {isEditMode ? renderEditShipmentNotesArea() : renderShipmentText()}
      <div
        style={{
          maxWidth: '600px',
          wordWrap: 'break-word',
          marginTop: '10px',
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default ShipmentNotes;
