import { TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import { FtrBoldText, FtrDropdownV2 } from '../../ftr-components';

import { formatSimpleDate } from '../../../utils/dateTimeUtils';
import { isEmptyValue } from '../../../utils/commonUtils';
import { FlexRow } from '../../layouts/FlexLayouts';

export const PickupDetailsForm = (props) => {
  const {
    title = 'Pickup Details',
    onChangeState = () => {},
    allAddresses = [],
    pickupAddress = '',
    pickupPostalCode = '',
    pickupContactName = '',
    pickupPhoneNumber = '',
    pickupInstructions = null,
    plannedPickupDate = formatSimpleDate(new Date()),
  } = props;

  const selectedValue = allAddresses?.find((a) => a.name === pickupContactName);

  const handleSelectAddressChange = (e) => {
    const value = e?.target?.value;
    const selectedAddress = allAddresses?.find((a) => a.key === value);
    if (!isEmptyValue(selectedAddress)) {
      onChangeState({
        pickupContactName: selectedAddress?.name,
        pickupPhoneNumber: selectedAddress?.contact,
        pickupAddress: selectedAddress?.address,
        pickupPostalCode: selectedAddress?.postalCode,
      });
    }
  };

  return (
    <div>
      <FlexRow>
        <FtrBoldText fontSize='16'>{title}</FtrBoldText>
        <FtrDropdownV2
          items={allAddresses}
          value={selectedValue?.key}
          onChange={handleSelectAddressChange}
        />
      </FlexRow>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          columnGap: '1rem',
          placeItems: 'baseline',
          marginTop: '1rem',
        }}
      >
        <TextField
          label='Contact Name'
          value={pickupContactName || ''}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            onChangeState({
              pickupContactName: evt.target.value,
            });
          }}
        />
        <TextField
          label='Phone Number'
          value={pickupPhoneNumber || ''}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            onChangeState({
              pickupPhoneNumber: evt.target.value,
            });
          }}
        />
        <TextField
          label='Address'
          value={pickupAddress || ''}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            onChangeState({
              pickupAddress: evt.target.value,
            });
          }}
        />
        <TextField
          label='Postcode'
          value={pickupPostalCode || ''}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          fullWidth
          required
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(evt) => {
            onChangeState({
              pickupPostalCode: evt.target.value,
            });
          }}
        />
        <DatePicker
          label={'Pickup Date'}
          value={plannedPickupDate || ''}
          onChange={(date) => onChangeState({ plannedPickupDate: date })}
          animateYearScrolling
          inputVariant='outlined'
          margin='dense'
          clearable
          clearLabel='No Preference'
          fullWidth
          required
        />
        <TextField
          label='Instructions'
          value={pickupInstructions || ''}
          variant='outlined'
          margin='dense'
          style={{ marginRight: '1.25rem' }}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          onChange={(evt) => {
            onChangeState({
              pickupInstructions: evt.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};
