import React, { useEffect, useState } from 'react';

import CarouselImageV2 from './CarouselImageV2';
import FtrLeftArrowButton from '../ftr-components/buttons/FtrLeftArrowButton';
import FtrRightArrowButton from '../ftr-components/buttons/FtrRightArrowButton';
import { FlexColumn, FlexRow } from '../layouts/FlexLayouts';

import { isEmptyValue } from '../../utils/commonUtils';

// --------------------------------------------------------------------------------------------

const URLS = [
  'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/2dImgFiles/harry-test_20220310212418.png',
  'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/2dImgFiles/harry-test_20220310212418.png',
  'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/2dImgFiles/harry-test_20220310212418.png',
  'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/2dImgFiles/harry-test_20220310212418.png',
  'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/2dImgFiles/harry-test_20220310212418.png',
];

const DEFAULT_DISPLAY_AMOUNT = 3;

function HorizontalCarouselV2(props) {
  const { urls = URLS, displayAmount = DEFAULT_DISPLAY_AMOUNT } = props;

  if (isEmptyValue(urls)) {
    return null;
  }

  const [currentIndex, setCurrentIndex] = useState(0);
  const [carouselUrls, setCarouselUrls] = useState(
    urls?.slice(0, displayAmount)
  );
  const [leftArrowEnable, setLeftArrowEnable] = useState(true);
  const [rightArrowEnable, setRightArrowEnable] = useState(true);

  useEffect(() => {
    setCarouselUrls(urls?.slice(currentIndex, currentIndex + displayAmount));
    setLeftArrowEnable(currentIndex > 0);
    setRightArrowEnable(currentIndex + displayAmount < urls.length);
  }, [currentIndex, displayAmount]);

  const handleLeftArrowClick = () => {
    const newCurrentIndex =
      currentIndex - displayAmount <= 0 ? 0 : currentIndex - displayAmount;
    setCurrentIndex(newCurrentIndex);
  };

  const handleRightArrowClick = () => {
    const newCurrentIndex =
      currentIndex + displayAmount <= urls.length
        ? currentIndex + displayAmount
        : currentIndex;
    setCurrentIndex(newCurrentIndex);
  };

  const renderLeftArrowButton = () => {
    return (
      <FtrLeftArrowButton
        onClick={(event) => {
          event.stopPropagation();
          handleLeftArrowClick();
        }}
        disabled={!leftArrowEnable}
      />
    );
  };

  const renderRightArrowButton = () => {
    return (
      <FtrRightArrowButton
        onClick={(event) => {
          event.stopPropagation();
          handleRightArrowClick();
        }}
        disabled={!leftArrowEnable}
      />
    );
  };

  return (
    <FlexColumn>
      <FlexRow style={{ marginLeft: leftArrowEnable ? '-1rem' : 0 }}>
        {leftArrowEnable === true && renderLeftArrowButton()}
        <FlexRow>
          {carouselUrls?.map((url) => (
            <CarouselImageV2 key={url} url={url} />
          ))}
        </FlexRow>
        {rightArrowEnable === true && renderRightArrowButton()}
      </FlexRow>
    </FlexColumn>
  );
}

export default HorizontalCarouselV2;
