import { BACKEND_SERVICE_URL } from '../constants';

import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

export const sendSupplierCreditNoteToHubdoc = async (
  supplierCreditNoteInfoID,
  body
) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/supplier/credit-note/hubdoc/${supplierCreditNoteInfoID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const updateSupplierCreditNoteInfo = async (
  supplierCreditNoteInfoID,
  body
) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/supplier/credit-note/${supplierCreditNoteInfoID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PATCH',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const uploadSupplierCreditNote = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/supplier/credit-note/upload`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const deleteSupplierCreditNote = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/supplier/credit-note/delete`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'PUT',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};
