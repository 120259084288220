import React, { useEffect, useReducer } from 'react';
import { nanoid } from 'nanoid';
import { useQuery } from 'react-query';

import { makeStyles } from '@material-ui/core/styles/index';
import { Container, CircularProgress, Button } from '@material-ui/core';

import DownloadIcon from '../../assets/icons/download-icon.svg';

import ShippingForm, {
  ShipmentInfo,
} from '../../components/forms/shipping-form/ShippingForm';
import {
  FtrBanner,
  FtrButton,
  FtrTypography,
} from '../../components/ftr-components';
import { PickupDetailsForm } from '../../components/forms/shipping-form/PickupDetailsForm';
import { ConsigneeDetailsForm } from '../../components/forms/shipping-form/ConsigneeDetailsForm';
import {
  FlexColumn,
  FlexRowAlignStart,
} from '../../components/layouts/FlexLayouts';
import Row from '../../components/Row';
import FtrSvgImage from '../../components/images/FtrSvgImage';
import FtrCheckbox from '../../components/ftr-components/FtrCheckbox';

import RedWarningSvgIcon from '../../assets/icons/red_warning.svg';

import { getJntRates } from '../../apis/shipmentApi';
import { getConfigByKey } from '../../apis/configurationApi';

import { isEmptyValue } from '../../utils/commonUtils';
import { convertPriceToCurrencyBeautified } from '../../utils/currencyUtils';
import { formatSimpleDate } from '../../utils/dateTimeUtils';
import { downloadS3File } from '../../utils/fileUtils';

import withCreateOrderJntPopupHoC from '../../hocs/withCreateOrderJntPopupHOC';

import { colors } from '../../palette';
import { COUNTRY_LIST, COUNTRY_NAMES } from '../../constants/countryConstants';
import { CURRENCY_CODE } from '../../constants/currencyConstants';
import { KEY_CONFIGURATION } from '../../constants/configurations';
import { SHIPMENT_ADDRESS_KEYS } from '../../constants/shippingContants';

// --------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  buttonUpdate: {
    padding: '6px 16px',
    fontSize: '0.8rem',
  },
  editIcon: {
    fontSize: '0.9rem',
    '&:hover': {
      backgroundColor: colors.blue050,
      color: 'white',
      cursor: 'pointer',
      borderRadius: '50%',
    },
    padding: 2,
  },
}));

/**
 * this will support multiple items and quotes
 *
 * @returns
 */
function CreateShipmentOrder(props) {
  const {
    updateCreateOrderJntPopupHoCState = () => {},
    createShipmentOrderPopupHOCState = {},
    location = '',
  } = props;
  const classes = useStyles();

  const {
    initialItemIDs,
    initialQuotationIDs,
    initialProjectIDs,
    loadOrderReadyInfo = () => {},
  } = createShipmentOrderPopupHOCState;

  const defaultLocalState = {
    jntPrice: '',
    isGetJntRatesProcessing: false,
    isSuccessGetJntRates: false,
    isCreateOrderJntProcessing: false,
    isSuccessJntCreateOrderRates: false,
    showConsigneeDetailsForm: false,
    skipIsSupplierIDSame: false,
    uploadToORM: true,
    pickupInstructions: '',
    consigneeInstructions: '',
    plannedPickupDate: formatSimpleDate(new Date()),
  };

  const defaultConsigneeForm = {
    consigneeContactName: 'Factorem Pte Ltd',
    consigneePhoneNumber: '+65 8925 8615',
    consigneeAddress: '81 Ayer Rajah Crescent, #01-54, Singapore',
    consigneePostalCode: '139967',
    consigneeInstructions: null,
    plannedDeliveryDate: formatSimpleDate(new Date()),
  };

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { ...defaultConsigneeForm, ...defaultLocalState }
  );

  useQuery(
    'getDefaultShipmentAddresses',
    () => getConfigByKey(KEY_CONFIGURATION.DEFAULT_SHIPMENT_ADDRESSES),
    {
      onSuccess: (data) => {
        const factoremHqAddress = data?.value?.find(
          (address) => address?.key === SHIPMENT_ADDRESS_KEYS.FACTOREM_HQ
        );

        const params = {
          defaultShipmentAddresses: data?.value,
          allAddresses: data?.value,
          ...(!isEmptyValue(factoremHqAddress) && {
            consigneeContactName: factoremHqAddress?.name,
            consigneePhoneNumber: factoremHqAddress?.contact,
            consigneeAddress: factoremHqAddress?.address,
            consigneePostalCode: factoremHqAddress?.postalCode,
          }),
        };

        updateLocalState(params);
      },
    }
  );

  useEffect(() => {
    // check for supplier address first
    if (localState.supplierAddress) {
      updateLocalState({
        pickupContactName: localState.supplierContactName,
        pickupPhoneNumber: localState.supplierPhoneNumber,
        pickupAddress: localState.supplierAddress,
        pickupPostalCode: localState.supplierPostalCode,
      });
      return;
    }
    if (localState.customerAddress) {
      updateLocalState({
        pickupContactName: localState.customerContactName,
        pickupPhoneNumber: localState.customerPhoneNumber,
        pickupAddress: localState.customerAddress,
        pickupPostalCode: localState.customerPostalCode,
      });
    }
  }, [localState.customerAddress, localState.supplierAddress]);

  const paramsRates = {
    weight: localState.totalWeight,
  };

  const paramsCreateOrder = {
    ...paramsRates,
    contact_name: localState.consigneeContactName,
    consigneePhoneNumber: localState.consigneePhoneNumber,
    consigneeAddress: localState.consigneeAddress,
    consigneePostalCode: localState.consigneePostalCode,
    pickupContactName: localState.pickupContactName,
    pickupPhoneNumber: localState.pickupPhoneNumber,
    pickupAddress: localState.pickupAddress,
    pickupPostalCode: localState.pickupPostalCode,
  };

  const validateParams = (params) => {
    let isFilled = true;
    for (const state in params) {
      if (isEmptyValue(params[state])) {
        isFilled = false;
      }
    }
    return isFilled;
  };

  const handleGetJNTShipmentFee = () => {
    updateLocalState({
      isGetJntRatesProcessing: true,
      isSuccessGetJntRates: false,
      isSuccessCreateOrderJnt: false,
      skipIsSupplierIDSame: false,
    });
    const body = {
      weight: Number(localState.totalWeight),
      length: Number(localState.length),
      width: Number(localState.width),
      height: Number(localState.height),
      country_code: COUNTRY_LIST[COUNTRY_NAMES.SINGAPORE].code,
    };
    getJntRates(body)
      .then((data) => {
        updateLocalState({
          isSuccessGetJntRates: true,
          price: convertPriceToCurrencyBeautified({
            currency: CURRENCY_CODE.SGD,
            price: data.rate,
          }),
          shipmentFee: data.rate,
        });
      })
      .finally(() => updateLocalState({ isGetJntRatesProcessing: false }));
  };

  const handleJntCreateOrder = () => {
    updateLocalState({
      isCreateOrderJntProcessing: true,
      isSuccessCreateOrderJnt: false,
    });
    const body = {
      reference_number: `FACTOREM_DO_${localState.referenceDONumber}_${nanoid(3)}`,
      itemIDsStr: localState.itemIDsStr,
      projectID: localState.projectID,
      supplierID: localState.supplierID,
      uploadToORM: localState.uploadToORM,
      shipmentFee: localState.shipmentFee,
      location,
      consignee_details: {
        contact_name: localState.consigneeContactName,
        phone_number: localState.consigneePhoneNumber,
        address: localState.consigneeAddress,
        postcode: localState.consigneePostalCode,
        instructions: localState.consigneeInstructions,
      },
      pickup_details: {
        contact_name: localState.pickupContactName,
        phone_number: localState.pickupPhoneNumber,
        address: localState.pickupAddress,
        postcode: localState.pickupPostalCode,
        instructions: localState.pickupInstructions,
        date: formatSimpleDate(localState.plannedPickupDate),
        time_window: {
          from: 9,
          to: 18,
        },
      },
      item_details: [
        {
          weight: Number(localState.totalWeight),
          length: Number(localState.length),
          width: Number(localState.width),
          height: Number(localState.height),
          weight_unit: 'KG',
          dimension_unit: 'CM',
          description: 'Custom Mechanical Parts',
        },
      ],
    };

    updateCreateOrderJntPopupHoCState({
      open: true,
      dataOrder: body,
      updateMainState: updateLocalState,
      onSuccessCreatingOrder: () => {
        loadOrderReadyInfo();
      },
    });
  };

  const renderOrderDetails = () => {
    if (!localState.isSuccessCreateOrderJnt) {
      return null;
    }
    return (
      <>
        <FtrTypography>
          <FtrTypography>
            Reference Number:{' '}
            <span style={{ color: colors.blue060 }}>
              {localState.reference_number}
            </span>
          </FtrTypography>
        </FtrTypography>
        {localState.waybill_s3_url && (
          <FtrTypography>
            Waybill URL:{' '}
            <FtrButton
              color='yellow'
              endIcon={
                <FtrSvgImage
                  src={DownloadIcon}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
              }
              onClick={(e) => {
                e.stopPropagation();
                downloadS3File(localState.waybill_s3_url);
              }}
            >
              Download Waybill
            </FtrButton>
          </FtrTypography>
        )}
      </>
    );
  };

  return (
    <Container style={{ padding: '1rem' }}>
      <div>
        <ShippingForm
          onChangeState={(objStates) => updateLocalState(objStates)}
          isLocal
          initialItemIDs={initialItemIDs}
          initialQuotationIDs={initialQuotationIDs}
          initialProjectIDs={initialProjectIDs}
          defaultShipmentAddresses={localState.defaultShipmentAddresses}
        />
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={() => {
            handleGetJNTShipmentFee();
          }}
          disabled={
            localState.isGetJntRatesProcessing || !validateParams(paramsRates)
          }
        >
          {localState.isGetJntRatesProcessing && (
            <CircularProgress className={classes.circularProgress} size={20} />
          )}
          &nbsp;Get shipment fee
        </Button>
      </div>
      <div style={{ margin: '1rem 0 1rem' }}>
        <ShipmentInfo
          price={localState.price}
          service='Next Day Express Delivery'
          title='J&T Shipment'
          isLoading={localState.isGetJntRatesProcessing}
          isSuccess={localState.isSuccessGetJntRates}
        />
      </div>
      {localState.isSuccessGetJntRates && (
        <FlexColumn>
          <PickupDetailsForm
            onChangeState={updateLocalState}
            supplierID={localState.supplierID}
            pickupAddress={localState.pickupAddress}
            pickupPostalCode={localState.pickupPostalCode}
            pickupContactName={localState.pickupContactName}
            pickupPhoneNumber={localState.pickupPhoneNumber}
            pickupInstructions={localState.pickupInstructions}
            plannedPickupDate={localState.plannedPickupDate}
            allAddresses={localState.allAddresses}
          />
          <ConsigneeDetailsForm
            onChangeState={updateLocalState}
            consigneeContactName={localState.consigneeContactName}
            consigneePhoneNumber={localState.consigneePhoneNumber}
            consigneeAddress={localState.consigneeAddress}
            consigneePostalCode={localState.consigneePostalCode}
            consigneeInstructions={localState.consigneeInstructions}
            allAddresses={localState.allAddresses}
            title='Delivery Details'
          />
          <FlexColumn style={{ paddingTop: '1rem' }}>
            {!localState.isSupplierIDSame &&
              !localState.skipIsSupplierIDSame &&
              validateParams(paramsCreateOrder) && (
                <FlexColumn>
                  <FtrBanner type='warning'>
                    <Row>
                      <img src={RedWarningSvgIcon} />
                      <FtrTypography
                        type='body'
                        style={{ color: colors.red030 }}
                        fontSize='14'
                      >
                        The suppliers for the selected items are different!
                      </FtrTypography>
                      <FtrButton
                        variant='text-black'
                        onClick={() =>
                          updateLocalState({ skipIsSupplierIDSame: true })
                        }
                        style={{ fontWeight: 400 }}
                      >
                        Click here to continue creating the order.
                      </FtrButton>
                    </Row>
                  </FtrBanner>
                </FlexColumn>
              )}
            <FlexRowAlignStart>
              <FtrCheckbox
                checked={localState.uploadToORM}
                onChange={(e) =>
                  updateLocalState({ uploadToORM: e.target.checked })
                }
                label='Upload to ORM'
              />
            </FlexRowAlignStart>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={() => {
                handleJntCreateOrder();
              }}
              disabled={
                localState.isGetJntRatesProcessing ||
                !validateParams(paramsCreateOrder) ||
                (!localState.isSupplierIDSame &&
                  !localState.skipIsSupplierIDSame)
              }
            >
              {localState.isCreateOrderJntProcessing && (
                <CircularProgress
                  className={classes.circularProgress}
                  size={20}
                />
              )}
              &nbsp;Create Order
            </Button>
            {renderOrderDetails()}
          </FlexColumn>
        </FlexColumn>
      )}
    </Container>
  );
}

export default withCreateOrderJntPopupHoC(CreateShipmentOrder);
