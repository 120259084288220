import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, sortBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles/index';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import BlueButton from '../buttons/BlueButton';
import CompareQuotesMultiCheckoutRemarksPopup from '../popups/CompareQuotesMultiCheckoutRemarksPopup';
import DateFormatMethod from '../DateFormatMethod';
import EditQuoteDialog from '../../pages/EditQuoteDialog';
import GreyButton from '../buttons/GreyButton';
import {
  FtrB1,
  FtrBlueCheckbox,
  FtrButton,
  FtrH3,
  FtrS3,
} from '../ftr-components';
import { FlexRow, FlexColumn } from '../layouts/FlexLayouts';
import { RocketQuoteIconButton } from '../icons/ItemIcon';
import RateMetrics from '../fields/RateMetrics';

import withRocketQuotePopupHOC from '../../hocs/withRocketQuotePopupHOC';

import { isEmptyValue } from '../../utils/commonUtils';
import { isDateInThePast } from '../../utils/dateTimeUtils';
import {
  convertPriceToCurrency,
  convertPriceWithQuantityToCurrency,
} from '../../utils/currencyUtils';

import {
  addSelectedQuote,
  removeSelectedQuote,
} from '../../actions/multiCheckoutForm';

import { isPpeItem } from '../../utils/itemUtils';
import {
  getQuotationExchangeRate,
  isUncheckedOutPartWithQuotes,
  isUnverifiedQuote,
  isWaitingQuote,
} from '../../utils/quotationUtils';
import { compareStringsEqualsIgnoreCase } from '../../utils/stringUtils';
import { filterValueNotEmpty } from '../../utils/filterUtils';

import { getUserCurrencySelector } from '../../selectors/userSelector';
import { getMultiCheckoutFormSelector } from '../../selectors/multiCheckoutFormSelector';

import ProjectItemsContext from '../../context/ProjectItemsContext';

import {
  materialWithColorCol,
  surfaceFinishingWithColorCol,
} from '../../constants/itemTableConstants';

import { TECHNOLOGY_OPTION_TYPE } from '../../constants/NewPartConstants';
import { ORDER_STATUS } from '../../constants';
import { COUNTRY_NAMES } from '../../constants/countryConstants';

import { colors } from '../../palette';
import { TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT } from '../../constants/partnerDataDashboardConstants';

// ------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  tableRowRoot: {
    verticalAlign: 'top',
    backgroundColor: colors.fontWhite,
    position: 'relative',
  },
  compareQuoteTableContainer: {
    '& tbody>.MuiTableRow-root.Mui-selected': {
      background: `${colors.blue020} !important`,
    },
    '& tbody>.MuiTableRow-root:hover': {
      background: `${colors.neutral020} !important`,
      cursor: 'pointer',
    },
  },
  unverifiedQuote: {
    background: `${colors.bgUnverifiedQuote}33 !important`,
  },
}));

const isRowSelectionDisabled = ({
  expiredQuoteIDList,
  rowData,
  isAdminView,
}) => {
  const selectableOrderStatuses = [ORDER_STATUS.PENDING];
  if (isAdminView) {
    selectableOrderStatuses.push(ORDER_STATUS.VERIFYING);
  }
  return (
    expiredQuoteIDList.includes(rowData.quotationID) ||
    rowData.totalPrice === 0 ||
    isEmptyValue(rowData.totalPrice) ||
    !selectableOrderStatuses.includes(rowData.status)
  );
};

function CompareQuotesMultiCheckoutMuiTableV2(props) {
  const classes = useStyles();

  const { isAdminView, customerCountry } = useContext(ProjectItemsContext);

  const dispatch = useDispatch();

  const {
    quotations = [],
    item = {},
    combinedServiceFee = false,
    getItems = () => {},
    updateRocketQuotePopupState = () => {},
  } = props;

  const [selectedQuoteIDList, setSelectedQuoteIDList] = useState([]);
  const [openEditQuoteDialog, setOpenEditQuoteDialog] = useState(false);
  const [quotationID, setQuotationID] = useState(null);
  const [isVerifyQuote, setIsVerifyQuote] = useState(false);

  const [showRemarksPopup, setShowRemarksPopup] = useState(false);
  const [remarksPopupContent, setRemarksPopupContent] = useState('');

  const isUncheckedOutPart = useMemo(
    () => isUncheckedOutPartWithQuotes(item, isAdminView),
    [item, isAdminView]
  );

  const currency = useSelector(getUserCurrencySelector);
  const multiCheckoutForm = useSelector(getMultiCheckoutFormSelector);

  const cmmPrice = quotations?.reduce(
    (acc, quote) =>
      acc +
      (get(quote, 'metadata.cmmPriceCustomer') ||
        get(quote, 'metadata.cmmPrice') ||
        0),
    0
  );

  const tableData = useMemo(() => {
    return quotations
      ? sortBy(
          quotations?.filter(
            (quote) =>
              isAdminView ||
              (quote.status !== ORDER_STATUS.DISABLED &&
                !isEmptyValue(quote.verifiedDate))
          ),
          (quote) => quote.quantity
        )
      : [];
  }, [quotations]);

  const expiredQuoteIDList = useMemo(() => {
    return tableData
      ?.filter(
        (data) =>
          data.status === ORDER_STATUS.EXPIRED ||
          (data.status !== ORDER_STATUS.ACCEPTED &&
            data.dateOfExpiry &&
            isDateInThePast(data.dateOfExpiry))
      )
      ?.map((data) => data.quotationID);
  }, [tableData]);

  useEffect(() => {
    const _selectedQuoteIDList = multiCheckoutForm.selectedQuotes?.map(
      (quote) => quote.quotationID
    );
    setSelectedQuoteIDList(_selectedQuoteIDList);
  }, [multiCheckoutForm.selectedQuotes]);

  const handleSelectQuote = (event, rowData) => {
    const { checked } = event.target;
    if (checked) {
      dispatch(addSelectedQuote(rowData));
    } else {
      dispatch(removeSelectedQuote(rowData));
    }
  };

  const handleQuotationRowClick = (rowData) => {
    if (isRowSelectionDisabled({ rowData, expiredQuoteIDList, isAdminView })) {
      return;
    }
    if (selectedQuoteIDList.includes(rowData.quotationID)) {
      dispatch(removeSelectedQuote(rowData));
    } else {
      dispatch(addSelectedQuote(rowData));
    }
  };

  const renderQuotationPrices = (rowData, price, dataCyStr) => {
    if (rowData.status === ORDER_STATUS.WAITING_FOR_QUOTE || !price) {
      return '-';
    }
    const qExchangeRate = getQuotationExchangeRate(rowData, currency);
    const { unitPriceStr, totalPriceStr } = convertPriceWithQuantityToCurrency({
      totalPrice: price,
      quantity: rowData.quantity,
      currency,
      exchangeRate: qExchangeRate,
    });

    return (
      <div data-cy={`${dataCyStr}-${rowData.itemID}`} style={{ width: '90px' }}>
        <FtrH3 style={{ color: 'inherit' }}>{totalPriceStr}</FtrH3>
        <FtrB1>{unitPriceStr}/unit</FtrB1>
      </div>
    );
  };

  const renderExcludesShipping = () => {
    if (
      !compareStringsEqualsIgnoreCase(customerCountry, COUNTRY_NAMES.SINGAPORE)
    ) {
      return null;
    }
    return <FtrB1 style={{ color: 'inherit' }}>(excludes shipping)</FtrB1>;
  };

  const renderActionButton = (label, onClickHandler) => (
    <FtrButton
      size='small'
      color='blue'
      variant='contained'
      onClick={onClickHandler}
      autoFocus
      disabled={false}
    >
      {label}
    </FtrButton>
  );

  const unfilteredColumns = [
    isUncheckedOutPart
      ? {
          title: '',
          render: (rowData) => {
            return isRowSelectionDisabled({
              rowData,
              expiredQuoteIDList,
              isAdminView,
            }) ? null : (
              <FtrBlueCheckbox
                checked={selectedQuoteIDList.includes(rowData.quotationID)}
                onChange={(event) => handleSelectQuote(event, rowData)}
                style={{ paddingTop: 0 }}
              />
            );
          },
        }
      : null,
    isAdminView && isUncheckedOutPart
      ? {
          title: 'Action',
          render: (rowData) => {
            return (
              <FlexRow>
                {renderActionButton(
                  isUnverifiedQuote(rowData) ? 'Verify' : 'Edit',
                  () => {
                    setOpenEditQuoteDialog(true);
                    setQuotationID(rowData.quotationID);
                    setIsVerifyQuote(isUnverifiedQuote(rowData));
                  }
                )}
                <RocketQuoteIconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    updateRocketQuotePopupState({
                      item: rowData,
                      copyQuotation: true,
                      refetchData: getItems,
                    });
                  }}
                />
              </FlexRow>
            );
          },
        }
      : null,
    isUncheckedOutPart && isEmptyValue(expiredQuoteIDList)
      ? null
      : {
          title: null,
          render: (rowData) => {
            if (
              rowData.status === ORDER_STATUS.ACCEPTED ||
              (isPpeItem(item) &&
                rowData.status === ORDER_STATUS.VERIFYING &&
                rowData.quotationID === item.acceptedQuotationID)
            ) {
              return (
                <BlueButton
                  btnContent='Accepted'
                  size='small'
                  disabled={true}
                />
              );
            } else if (rowData.status === ORDER_STATUS.REJECTED) {
              return (
                <GreyButton
                  btnContent='Rejected'
                  size='small'
                  disabled={true}
                />
              );
            } else if (
              rowData.status === ORDER_STATUS.EXPIRED ||
              (rowData.dateOfExpiry && isDateInThePast(rowData.dateOfExpiry))
            ) {
              return (
                <GreyButton btnContent='Expired' size='small' disabled={true} />
              );
            } else {
              return '';
            }
          },
        },
    { title: 'Quote ID', field: 'quotationID', hidden: !isAdminView },
    { title: 'Quantity', field: 'quantity' },
    {
      ...materialWithColorCol,
    },
    {
      ...surfaceFinishingWithColorCol,
    },
    item.technology !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING
      ? null
      : {
          title: 'Technology',
          render: (rowData) => {
            const metadata = rowData.metadata || {};
            return !isEmptyValue(metadata.threeDTechnology)
              ? metadata.threeDTechnology
              : 'N.A.';
          },
        },
    item.technology !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING
      ? null
      : {
          title: 'Infill',
          render: (rowData) => {
            const metadata = rowData.metadata || {};
            return isEmptyValue(metadata.threeDInfill)
              ? 'N.A.'
              : `${(metadata.threeDInfill * 100).toFixed(0)}%`;
          },
        },
    item.technology !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING
      ? null
      : {
          title: 'Layer Height',
          render: (rowData) => {
            const metadata = rowData.metadata || {};
            return isEmptyValue(metadata.threeDLayerThickness)
              ? 'N.A.'
              : `${metadata.threeDLayerThickness}mm`;
          },
        },
    { title: 'Supplier name', field: 'supplierName', hidden: !isAdminView },
    {
      title: 'Supplier metrics',
      field: 'supplierMetrics',
      hidden: !isAdminView,
      render: (rowData) => {
        if (isEmptyValue(rowData?.supplierMetrics)) {
          return 'N.A.';
        }
        const {
          qualityRate,
          onTimeDeliveryRate,
          averageCycleTimeWorking,
          quoteToPO,
        } = rowData.supplierMetrics;
        const renderTooltipText = (title, subTitle) => {
          return (
            <div>
              <div style={{ fontSize: 12 }}>{title}</div>
              <div>{subTitle}</div>
            </div>
          );
        };

        const supplierMetrics = [
          {
            name: 'QR',
            value: qualityRate,
            tooltip: renderTooltipText(
              'Quality Rate',
              TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.qualityRate
            ),
          },
          {
            name: 'OTD',
            value: onTimeDeliveryRate,
            tooltip: renderTooltipText(
              'On-Time Delivery Rate',
              TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.onTimeDeliveryRate
            ),
          },
          {
            name: 'Q-PO',
            value: quoteToPO,
            tooltip: renderTooltipText(
              'Quote to PO Rate',
              TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.quoteToPO
            ),
          },
          {
            name: 'ACT',
            value: averageCycleTimeWorking,
            displayValue: averageCycleTimeWorking + ' days',
            type: 'text',
            tooltip: renderTooltipText(
              'Average Cycle Time',
              TOOLTIP_SUPPLIER_DATA_DASHBOARD_CONSTANT.averageCycleTimeWorking
            ),
          },
        ];

        const validMetrics = filterValueNotEmpty(supplierMetrics);
        if (isEmptyValue(validMetrics)) {
          return 'N.A.';
        }

        return (
          <div
            style={{
              width: '175px',
              fontSize: 10,
              display: 'grid',
              gridTemplateColumns: '80px 95px',
            }}
          >
            {validMetrics.map((metric) => {
              return (
                <RateMetrics
                  name={metric.name}
                  key={metric.name}
                  value={metric.displayValue || metric.value}
                  type={metric.type}
                  tooltip={metric.tooltip}
                  styleName={{
                    width: '28px',
                    display: 'inline-block',
                  }}
                />
              );
            })}
          </div>
        );
      },
    },
    {
      title: 'Price from supplier',
      hidden: !isAdminView,
      render: (rowData) => {
        return renderQuotationPrices(
          rowData,
          rowData.priceBidded,
          'supplier-price-itemID'
        );
      },
    },
    {
      title: (
        <div style={{ minWidth: '120px' }}>
          <div>Total Price</div>
        </div>
      ),
      render: (rowData) => {
        const totalPrice = combinedServiceFee
          ? rowData.totalPriceInclPlatformFee
          : rowData.totalPrice;

        return renderQuotationPrices(rowData, totalPrice, 'total-price-itemID');
      },
    },
    {
      title: `Lead Time`,
      field: 'leadTime',
      render: (rowData) => {
        if (
          rowData.status === ORDER_STATUS.WAITING_FOR_QUOTE ||
          !rowData.totalPrice
        ) {
          return '-';
        }

        const leadTime =
          parseInt(rowData.leadTime, 10) + rowData.markupLeadTime;

        return (
          <FlexColumn style={{ gap: 0, width: '100px' }}>
            <FlexRow>
              <FtrS3 style={{ color: 'inherit' }}>{leadTime}</FtrS3>
              <FtrB1 style={{ color: 'inherit' }}>working days</FtrB1>
            </FlexRow>
            {isAdminView &&
              `(${rowData.leadTime}${
                rowData.markupLeadTime ? ' + ' + rowData.markupLeadTime : ''
              })`}
            {renderExcludesShipping()}
          </FlexColumn>
        );
      },
      cellStyle: {
        minWidth: 150,
      },
    },
    {
      title: 'CMM Price',
      render: (rowData) => {
        return convertPriceToCurrency({
          price:
            get(rowData, 'metadata.cmmPriceCustomer') ||
            get(rowData, 'metadata.cmmPrice') ||
            0,
          currency: currency,
          exchangeRate: getQuotationExchangeRate(rowData, currency),
        });
      },
      cellStyle: {
        minWidth: 150,
      },
      hidden: cmmPrice === 0,
    },
    {
      title: 'Remarks',
      field: 'remarks',
      cellStyle: {
        maxWidth: '280px',
      },
      render: (rowData) => {
        if (
          rowData.status !== ORDER_STATUS.WAITING_FOR_QUOTE &&
          !rowData.totalPrice
        ) {
          return '-';
        }
        if (rowData.remarks?.length > 180) {
          return (
            <>
              {rowData.remarks?.slice(0, 180) + '... '}
              <span
                style={{
                  color: colors.buttonBlue,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setRemarksPopupContent(rowData.remarks);
                  setShowRemarksPopup(true);
                }}
              >
                See more
              </span>
            </>
          );
        }
        return rowData.remarks;
      },
    },
    {
      title: 'Validity',
      field: 'dateOfExpiry',
      render: (rowData) =>
        rowData.dateOfExpiry ? (
          <DateFormatMethod date={rowData.dateOfExpiry} monthType='short' />
        ) : (
          ''
        ),
      cellStyle: {
        minWidth: 150,
      },
    },
  ];

  const columns = unfilteredColumns?.filter((col) => !!col);

  return (
    <div className={classes.compareQuoteTableContainer}>
      <TableContainer style={{ borderRadius: '16px' }}>
        <Table aria-label='table'>
          <TableHead style={{ backgroundColor: colors.neutral020 }}>
            <TableRow>
              {columns?.map((column) => {
                if (column.hidden) {
                  return null;
                }

                return (
                  <TableCell
                    key={column.title}
                    style={{
                      position: 'sticky',
                      top: 0,
                      color: colors.neutral060,
                      fontSize: '16px',
                      fontWeight: 600,
                      borderBottom: 'none',
                    }}
                  >
                    {column.title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((row) => {
              if (!isAdminView && isUnverifiedQuote(row)) {
                return null;
              }

              if (isWaitingQuote(row) && !row.userRqRequested) {
                return null;
              }

              return (
                <TableRow
                  selected={selectedQuoteIDList.includes(row.quotationID)}
                  className={
                    isUnverifiedQuote(row) ? classes.unverifiedQuote : ''
                  }
                  key={row.quotationID}
                  onClick={() => handleQuotationRowClick(row)}
                  classes={{
                    root: classes.tableRowRoot,
                  }}
                >
                  {columns?.map((column) => {
                    if (column.hidden) {
                      return null;
                    }

                    const renderFunc = column.render;
                    return (
                      <TableCell
                        key={column.field}
                        style={{
                          borderBottom: 'none',
                          color: colors.neutral060,
                          fontSize: '16px',
                          fontWeight: 600,
                        }}
                      >
                        {typeof renderFunc === 'function'
                          ? renderFunc(row)
                          : row[column.field]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {showRemarksPopup && (
        <CompareQuotesMultiCheckoutRemarksPopup
          open={showRemarksPopup}
          handleClose={() => setShowRemarksPopup(false)}
          dialogContent={remarksPopupContent}
        />
      )}
      {openEditQuoteDialog && (
        <EditQuoteDialog
          key={quotationID}
          open={openEditQuoteDialog}
          setOpen={setOpenEditQuoteDialog}
          quotationID={quotationID}
          refreshDataFunc={getItems}
          isVerifyQuote={isVerifyQuote}
        />
      )}
    </div>
  );
}

export default compose(
  withRouter,
  withRocketQuotePopupHOC
)(CompareQuotesMultiCheckoutMuiTableV2);
