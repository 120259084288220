import { Grid } from '@material-ui/core';
import { colors } from '../../../palette';
import MDEditor from '@uiw/react-md-editor';
// No import is required in the WebPack.
import '@uiw/react-md-editor/markdown-editor.css';
// No import is required in the WebPack.
import '@uiw/react-markdown-preview/markdown.css';

import { FtrDropdownV2, FtrTextField } from '../../ftr-components';
import FtrFieldLabel from '../../ftr-components/FtrFieldLabel';
import FtrNumberField from '../../ftr-components/FtrNumberField';
import CloseIcon from '@material-ui/icons/Close';

import { PROJECTION_ANGLES } from '../../../constants/projectionAngleConstants';

import DragDrop from '../../DragDrop';

const TitleplateFormV2 = (props) => {
  const { updateTitleplateState, handleChange, titleplateState } = props;

  const handleToleranceTableUpload = (files) => {
    Promise.all(
      Array.from(files).map((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          updateTitleplateState({
            toleranceTable: reader.result,
          });
        };
        reader.readAsDataURL(file);
      })
    );
  };

  return (
    <Grid
      item
      xs={12}
      style={{ padding: 10, maxHeight: '800px', overflowY: 'scroll' }}
    >
      <FtrTextField
        name='title'
        label={'Title'}
        id='title'
        onChange={handleChange('title')}
        value={titleplateState.title}
      />
      <FtrTextField
        name='itemID'
        label={'Part ID'}
        id='itemID'
        onChange={handleChange('itemID')}
        value={titleplateState.itemID}
      />
      <FtrTextField
        name='material'
        label={'Material'}
        id='material'
        onChange={handleChange('material')}
        value={titleplateState.material}
      />
      <FtrTextField
        name='color-surface-finish'
        label={'Finish'}
        id='color-surface-finish'
        onChange={handleChange('colorSurfaceFinish')}
        value={titleplateState.colorSurfaceFinish}
      />
      <FtrNumberField
        name='quantity'
        label={'Quantity'}
        id='quantity'
        onChange={handleChange('quantity')}
        value={titleplateState.quantity}
        minValue={1}
      />
      <FtrNumberField
        name='tolerance'
        label={'Tightest tolerance'}
        id='tolerance'
        onChange={handleChange('tolerance')}
        value={titleplateState.tolerance}
        minValue={0}
      />
      <FtrTextField
        name='toleranceStandard'
        label={'Tolerance Standard'}
        id='toleranceStandard'
        onChange={handleChange('toleranceStandard')}
        value={titleplateState.toleranceStandard}
      />
      <FtrTextField
        name='name'
        label={'POC Name'}
        id='name'
        onChange={handleChange('name')}
        value={titleplateState.name}
      />
      <FtrTextField
        name='contact'
        label={'POC Number'}
        id='contact'
        onChange={handleChange('contact')}
        value={titleplateState.contact}
      />
      <FtrTextField
        name='scale'
        label={'Scale'}
        id='scale'
        onChange={handleChange('scale')}
        value={titleplateState.scale}
      />
      <FtrTextField
        name='paperSize'
        label={'Size'}
        id='paperSize'
        onChange={handleChange('paperSize')}
        value={titleplateState.paperSize}
        style={{ marginBottom: 6 }}
      />

      <FtrDropdownV2
        fullWidth
        value={titleplateState.projectionAngle}
        items={[
          { key: PROJECTION_ANGLES.THIRD_ANGLE, label: 'Third Angle' },
          { key: PROJECTION_ANGLES.FIRST_ANGLE, label: 'First Angle' },
        ]}
        handleChange={(newItem) => {
          updateTitleplateState({ projectionAngle: newItem });
        }}
        placeholder='Select Projection Angle'
      />
      <div style={{ margin: '2rem 0' }}>
        {titleplateState.toleranceTable && (
          <div style={{ textAlign: 'center' }}>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <CloseIcon
                style={{
                  position: 'absolute',
                  top: '-20px',
                  right: '-20px',
                  cursor: 'pointer',
                  color: colors.buttonRed,
                }}
                onClick={() => updateTitleplateState({ toleranceTable: null })}
                className='delete-icon'
              />
              <img
                style={{ maxHeight: '180px' }}
                src={titleplateState.toleranceTable}
              />
            </div>
          </div>
        )}
        <DragDrop
          text='Upload Tolerance Table'
          handleFiles={handleToleranceTableUpload}
          multiple={false}
        />

        <FtrFieldLabel>Description</FtrFieldLabel>
        <div data-color-mode='light'>
          <MDEditor
            height={200}
            value={titleplateState.description}
            onChange={(value) => {
              updateTitleplateState({
                description: value,
              });
            }}
            preview='edit'
          />
        </div>
      </div>
    </Grid>
  );
};

export default TitleplateFormV2;
