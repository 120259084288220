import React, { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';

import {
  FtrButton,
  FtrTextField,
  FtrTypography,
} from '../../components/ftr-components';
import {
  FlexColumnCenter,
  FlexRow,
  FlexRowCenter,
} from '../../components/layouts/FlexLayouts';

import useDeviceCheck from '../../hooks/useDeviceCheck';

import { generateAutoBalloonForItem } from '../../apis/autoBalloonApi';
import { getItemDetailsApi } from '../../apis/itemApi';

import { isEmptyValue } from '../../utils/commonUtils';
import { isPdfFile } from '../../utils/fileUtils';
import { getFileNameFromCadFile } from '../../utils/itemUtils';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: colors.neutral020,
    },
  },
  paper: {
    borderRadius: '30px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
    maxWidth: '1000px',
  },
  container: {
    width: '100% !important',
    margin: '0 !important',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {},
  },
  summaryItemName: {
    width: '100%',
    fontSize: '14px',
    display: 'block',
    fontWeight: 600,
    lineHeight: '19.07px',
    paddingLeft: '0.75rem',
    paddingBottom: '5px',
    color: '#565656',
  },
  summaryText: {
    paddingLeft: '0.75rem',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.07px',
    color: colors.neutral060,
    alignItems: 'left',
    justifyContent: 'left',
    wordBreak: 'break-word',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

function GenerateAutoBalloonForItemPopup(props) {
  const classes = useStyles();

  const { open, handleClose = () => {} } = props;

  const queryClient = useQueryClient();

  const [{ isMobile }] = useDeviceCheck();

  const [itemID, setItemID] = React.useState(null);
  const [itemInfo, setItemInfo] = React.useState(null);
  const [loadingItemInfo, setLoadingItemInfo] = React.useState(false);
  const [selectedTechnicalDrawingFile, setSelectedTechnicalDrawingFile] =
    React.useState(null);

  const [autoBallooning, setAutoBallooning] = React.useState(false);

  const processing = loadingItemInfo || autoBallooning;

  const technicalDrawings = useMemo(() => {
    if (isEmptyValue(itemInfo)) {
      return [];
    }

    const originalFiles = isEmptyValue(itemInfo.originalFiles)
      ? []
      : itemInfo.originalFiles?.split(',');

    const cadFile = isEmptyValue(itemInfo.cadFile)
      ? []
      : itemInfo.cadFile?.split(',');

    const _techFiles = [...cadFile, ...originalFiles]?.filter((fileUrl) =>
      isPdfFile(fileUrl)
    );

    if (!isEmptyValue(_techFiles)) {
      setSelectedTechnicalDrawingFile(_techFiles[0]);
    }

    return _techFiles;
  }, [itemInfo]);

  const { mutate: generateAutoBalloonForItemMutate } = useMutation(
    ({ itemID: _itemID, pdfUrl }) =>
      generateAutoBalloonForItem({ itemID: _itemID, pdfUrl }),
    {
      onSuccess: () => {
        notifySuccess('Generate Auto Balloons for item successfully');
        queryClient.invalidateQueries('getAutoBalloonItems');
        queryClient.invalidateQueries('getPendingAutoBalloonItems');
        handleClose();
      },
      onError: () => {
        notifyError('Generate Auto Balloons for item failed');
      },
    }
  );

  const handleLoadItemInfo = () => {
    if (isEmptyValue(itemID)) {
      return;
    }

    setLoadingItemInfo(true);
    getItemDetailsApi(itemID)
      .then((info) => {
        setItemInfo(info);
        setLoadingItemInfo(false);
      })
      .catch((err) => {
        setLoadingItemInfo(false);
        notifyError(err.message || 'Load item info failed');
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      scroll='paper'
      width='md'
      fullWidth
      fullScreen={isMobile}
    >
      <Close className={classes.closeIcon} onClick={handleClose} />
      <FtrTypography
        type='heading'
        fontSize='22'
        style={{ padding: '30px 0px 20px 40px' }}
      >
        Generate Auto Balloon for Item
      </FtrTypography>
      <DialogContent>
        <FlexColumnCenter
          style={{
            width: 500,
            maxWidth: 500,
            margin: 'auto',
            marginBottom: '2rem',
          }}
        >
          <FlexRowCenter
            style={{
              width: '100%',
              margin: 'auto',
              boxSizing: 'border-box',
            }}
          >
            <FlexRow style={{ flexGrow: 1 }}>
              <FtrTextField
                label='Item ID'
                value={itemID}
                onChange={(e) => setItemID(e.target.value)}
                fullWidth
                style={{
                  marginBottom: 0,
                }}
                autoFocus
              />
              <FtrButton
                size='small'
                color='blue'
                variant='outlined'
                onClick={handleLoadItemInfo}
                disabled={isEmptyValue(itemID) || loadingItemInfo}
                loading={loadingItemInfo}
              >
                Load Item Info
              </FtrButton>
            </FlexRow>
          </FlexRowCenter>
          {!isEmptyValue(technicalDrawings) && (
            <FlexRow
              style={{ width: '100%', margin: 'auto', boxSizing: 'border-box' }}
            >
              <TextField
                id='select-technical-drawing'
                select
                label='Technical Drawing Files'
                value={selectedTechnicalDrawingFile}
                onChange={(evt) =>
                  setSelectedTechnicalDrawingFile(evt.target.value)
                }
                fullWidth
                variant='outlined'
                margin='dense'
                style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
              >
                {technicalDrawings?.map((url, index) => {
                  const encodedFileName = url?.split('/').pop();
                  const fileName = getFileNameFromCadFile(encodedFileName);
                  return (
                    <MenuItem key={index} value={url}>
                      {fileName}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FlexRow>
          )}
        </FlexColumnCenter>
      </DialogContent>
      <DialogActions
        style={{ padding: '1rem', borderTop: '1px solid #e0e0e0' }}
      >
        <FlexRowCenter
          style={{ width: '100%', gap: '1rem', boxSizing: 'border-box' }}
        >
          <FtrButton color='gray' variant='contained' onClick={handleClose}>
            Cancel
          </FtrButton>
          <FtrButton
            color='blue'
            variant='contained'
            onClick={handleGenerateAutoBalloonForItem}
            disabled={
              processing ||
              isEmptyValue(itemID) ||
              isEmptyValue(selectedTechnicalDrawingFile)
            }
            loading={autoBallooning}
          >
            Generate Auto Balloon
          </FtrButton>
        </FlexRowCenter>
      </DialogActions>
    </Dialog>
  );

  async function handleGenerateAutoBalloonForItem() {
    if (isEmptyValue(itemID) || isEmptyValue(selectedTechnicalDrawingFile)) {
      return;
    }

    setAutoBallooning(true);
    return generateAutoBalloonForItemMutate({
      itemID,
      pdfUrl: selectedTechnicalDrawingFile,
    });
  }
}

export default GenerateAutoBalloonForItemPopup;
