import React, { createContext, useState, useEffect } from 'react';
import { useMediaQuery, createTheme } from '@material-ui/core';
import { debounce } from 'lodash';

const DeviceContext = createContext();

export const DeviceProvider = ({ children }) => {
  const [isIPad, setIsIPad] = useState(false);
  const [isIPhone, setIsIPhone] = useState(false);
  const [heightWindow, setHeightWindow] = useState(window.innerHeight);
  const [widthWindow, setWidthWindow] = useState(window.innerWidth);

  const theme = createTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const isiPad =
      typeof window !== 'undefined' &&
      navigator.platform === 'MacIntel' &&
      navigator.maxTouchPoints > 1;

    setIsIPad(isiPad);

    const userAgent = window.navigator.userAgent;
    // Test if the device is an iPhone
    const iPhone = /iPhone/i.test(userAgent);
    setIsIPhone(iPhone);
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidthWindow(window.innerWidth);
      setHeightWindow(window.innerHeight);
    };

    const debouncedUpdate = debounce(updateWindowDimensions, 100); // Adjust delay as needed
    window.addEventListener('resize', debouncedUpdate);

    return () => {
      window.removeEventListener('resize', debouncedUpdate);
      debouncedUpdate.cancel(); // Cancel any pending debounced calls
    };
  }, []);

  return (
    <DeviceContext.Provider
      value={[
        {
          isIPad,
          isMobile,
          isSmallScreen,
          isTablet,
          isIPhone,
          heightWindow,
          widthWindow,
        },
      ]}
    >
      {children}
    </DeviceContext.Provider>
  );
};

export { DeviceContext };
