import { useQuery } from 'react-query';

import { getItemDetailsApi } from '../apis/itemApi';

import { isEmptyValue } from '../utils/commonUtils';

// -------------------------------------------------------------------------------------------------

const useItemInfo = (itemID) => {
  const { data, refetch } = useQuery(
    ['getItemDetailsApi', itemID],
    () => getItemDetailsApi(itemID),
    {
      enabled: !isEmptyValue(itemID),
    }
  );

  return {
    data,
    refetch,
  };
};

export default useItemInfo;
