import { useQuery } from 'react-query';

import { checkUserCanSeePromotionCodesApi } from '../apis/factoremPromotionCodesApi';

import { DISCOUNT_CODES } from '../constants/promoCodeConstants';

// -------------------------------------------------------------------------------------------------

const useSeeExtraPromoCodesHook = (customerID) => {
  const codeList = [DISCOUNT_CODES.FCTRM5MORE];

  const { data: canSeePromoCodes = false, refetch } = useQuery(
    ['checkUserCanSeePromotionCodesApi', customerID, codeList],
    () => {
      const body = {
        customerID,
        codeList,
      };

      return checkUserCanSeePromotionCodesApi(body);
    },
    {
      enabled: !!customerID,
    }
  );

  return {
    canSeePromoCodes,
    refetch,
  };
};

export default useSeeExtraPromoCodesHook;
