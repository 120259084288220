import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { cloneDeep, isEmpty } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import BlueButton from '../buttons/BlueButton';
import WhiteButton from '../buttons/WhiteButton';
import SubDesc from '../SubDesc';

import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 0,
  },
  space: {
    width: theme.spacing(2),
  },
  itemCollectedButton: {
    background: colors.orangeGradient,
    color: colors.fontWhite,
    padding: '0.625rem 0.9375rem',
    borderRadius: '0.3125rem',
    fontSize: '9pt',
    letterSpacing: '0.15em',
    '&:hover': {
      filter: 'brightness(0.8)',
    },
  },
}));

function ItemCollectionNoticePopup(props) {
  const classes = useStyles();

  const {
    open = false,
    handleClose = () => {},
    handleSubmit = () => {},
    itemsData = {},
    projectID = -1,
    projectOrderReadyInfo = {},
  } = props;
  const { supplierID, poAcknowledgedVersion } = projectOrderReadyInfo;

  const [itemsState, setItemsState] = useState(itemsData);

  useEffect(() => {
    if (itemsState) {
      setItemsState((prevItemsState) => {
        return prevItemsState?.map((item) => ({
          ...item,
          actualCollectionDate: item.actualCollectionDate
            ? new Date(item.actualCollectionDate)
            : new Date(),
          checked: false,
        }));
      });
    }
  }, []);

  const filteredItemsIDsAndCollectionDates =
    itemsState
      ?.filter((item) => item.checked)
      ?.map(({ itemID, actualCollectionDate }) => ({
        itemID,
        actualCollectionDate,
      })) || [];

  const handleChangeDate = (date, index) => {
    const itemsStateClone = cloneDeep(itemsState);
    itemsStateClone[index].actualCollectionDate = date;
    setItemsState(itemsStateClone);
  };
  const handleChangeCheckbox = (event, index) => {
    const checked = event.target.checked;
    const itemsStateClone = cloneDeep(itemsState);
    itemsStateClone[index].checked = checked;
    setItemsState(itemsStateClone);
  };

  // will update all actualCollectionDates to current date
  const updateActualCollectionDate = () => {
    const itemsStateClone = cloneDeep(itemsState);
    itemsStateClone.forEach((item) => {
      item.actualCollectionDate = new Date();
    });
    setItemsState(itemsStateClone);
  };

  const renderBody = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {itemsState?.map((item, index) => {
          return (
            <div key={item.itemID}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '0.5rem',
                }}
              >
                <FormControlLabel
                  style={{ marginRight: '0' }}
                  control={
                    <Checkbox
                      checked={item.checked}
                      onChange={(event) => handleChangeCheckbox(event, index)}
                    />
                  }
                />
                <Typography>
                  ItemID:&nbsp;
                  <Link
                    to={{
                      pathname: `/item/edit/${item.itemID}`,
                    }}
                  >
                    {item.itemID}
                  </Link>
                </Typography>
                <span>-</span>
                <Typography>
                  QuotationID:&nbsp;
                  <Link
                    to={{
                      pathname: `/order/edit/${item.quotationID}`,
                    }}
                  >
                    {item.quotationID}
                  </Link>
                </Typography>
                <span>-</span>
                <DatePicker
                  label='Actual Collection Date'
                  value={item.actualCollectionDate}
                  onChange={(date) => handleChangeDate(date, index)}
                  animateYearScrolling
                  inputVariant='outlined'
                  margin='dense'
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <Dialog
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby='confirmation-dialog-title'
        classes={{ paper: classes.paper }}
        fullWidth
      >
        <DialogTitle id='confirmation-dialog-title'>
          <SubDesc
            content={`Generate New Item Collection Notice for Project ID ${projectID}`}
          />
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            padding: '1rem 2rem',
            paddingBottom: '2rem',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              marginBottom: '1rem',
            }}
          >
            <BlueButton
              onBtnClick={updateActualCollectionDate}
              size='small'
              btnContent='Update all with today’s date'
            />
          </div>
          {renderBody()}
        </DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
            margin: '1rem',
          }}
        >
          <WhiteButton
            onBtnClick={handleClose}
            btnContent='Cancel'
            size='small'
          />
          <Button
            className={classes.itemCollectedButton}
            variant='contained'
            disabled={isEmpty(filteredItemsIDsAndCollectionDates)}
            onClick={() => {
              const payload = {
                supplierID,
                poAcknowledgedVersion,
                filteredItemsIDsAndCollectionDates,
              };
              handleSubmit(payload);
              handleClose();
            }}
          >
            Generate
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default ItemCollectionNoticePopup;
