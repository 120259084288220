import React, { useState } from 'react';
import { Container, makeStyles, Tab, Tabs } from '@material-ui/core';
import { useQuery } from 'react-query';

import InputPartnerData from '../../components/partnerDataPanel/InputPartnerData';
import UploadPartnerDataHistory from '../../components/partnerDataPanel/UploadPartnerDataHistory';
import CurrentVersionPartnerData from '../../components/partnerDataPanel/CurrentVersionPartnerData';

import { getSupplierDashboardUploadHistory } from '../../apis/supplierDataDashboard';

import {
  TAB_DASHBOARD_KEYS,
  TABS_DASHBOARD,
} from '../../constants/partnerDataDashboardConstants';
import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  twoColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr  1fr',
    gap: '25px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    padding: '0',
  },
  tabRoot: {
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

function PartnerDataPanel() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(TAB_DASHBOARD_KEYS.ALL_TIME);
  const { data: listUploadHistories, isSuccess } = useQuery(
    ['getSupplierDashboardUploadHistory', selectedTab],
    () => getSupplierDashboardUploadHistory({ type: selectedTab })
  );

  return (
    <div className={classes.body}>
      <Container className={classes.container}>
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => setSelectedTab(newValue)}
        >
          {TABS_DASHBOARD?.map((tab) => {
            return (
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.selectedTab,
                }}
                key={tab}
                value={tab}
                label={tab}
              />
            );
          })}
        </Tabs>
        {isSuccess && (
          <CurrentVersionPartnerData
            data={listUploadHistories}
            type={selectedTab}
          />
        )}
        <div className={classes.twoColumns}>
          <InputPartnerData type={selectedTab} />
          {isSuccess && (
            <UploadPartnerDataHistory
              data={listUploadHistories}
              type={selectedTab}
            />
          )}
        </div>
      </Container>
    </div>
  );
}

export default PartnerDataPanel;
