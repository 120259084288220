import { useEffect, useReducer, useState } from 'react';
import { cloneDeep } from 'lodash';

import { upsertScheduleConfig } from '../apis/rfqEmailSchedulesApi';

import { validateEmail } from '../utils/validators/emailValidator';

import { isEmptyValue } from '../utils/commonUtils';

// There will be 4 slot categories which different offset hours
const SCHEDULE_SLOTS_CATEGORIES = [
  {
    offsetHours: 0,
  },
  {
    offsetHours: 4,
  },
  {
    offsetHours: 8,
  },
  {
    offsetHours: 12,
  },
];

const NUMBER_OF_SUPPLIERS_PER_SLOT = 3;

const INIT_FORM_ERROR = {
  selectItems: null,
  emptySuppliers: null,
  invalidEmail: null,
};

const getInitSlots = () => {
  const slots = [];
  let slotID = 0;
  for (const slot of SCHEDULE_SLOTS_CATEGORIES) {
    for (let index = 0; index < NUMBER_OF_SUPPLIERS_PER_SLOT; index++) {
      slots.push({
        slotID: slotID,
        suppliers: [],
        ...slot,
      });
      slotID++;
    }
  }
  return slots;
};

/**
 * 
 * @example allSuppliers
 * [
    {
        "key": "3d printing",
        "name": "3D Printing",
        "value": [
            {
                "userID": 46,
                "name": "FactoremPartner",
                "email": "partner@factorem.co",
                ...
            },
        ]
    },
    {
        "key": "cnc machining",
        "name": "CNC Machining",
        "value": [
            {
                "userID": 46,
                "name": "FactoremPartner",
                "email": "partner@factorem.co",
                ...
            },
        ]
    },
]
 */
export const useRfqEmailSchedulesConfigForm = () => {
  const [cacheConfig, setCacheConfig] = useState(null);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [allSupplierIDAndEmails, setAllSupplierIDAndEmails] = useState([]);
  const [availableSuppliers, setAvailableSuppliers] = useState([]);
  const [config, setConfig] = useState({
    itemIDs: [],
    details: {
      slots: getInitSlots(),
      remarks: '',
      attachFiles: false,
    },
  });

  const [formErrorState, updateFormErrorState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      ...INIT_FORM_ERROR,
    }
  );

  useEffect(() => {
    const _allSupplierIDAndEmails = allSuppliers?.flatMap((category) => {
      return category.value;
    });
    setAllSupplierIDAndEmails(_allSupplierIDAndEmails);
  }, [allSuppliers]);

  useEffect(() => {
    const slots = config?.details?.slots ?? [];
    const allSelectedEmails =
      slots?.flatMap((s) => s.suppliers?.map((supplier) => supplier.email)) ??
      [];
    const _availableSuppliers = allSuppliers
      ?.map((category) => {
        const supplierList = category.value;
        const remainSuppliers = supplierList?.filter(
          (s) => !allSelectedEmails?.includes(s.email)
        );
        return {
          ...category,
          value: remainSuppliers,
        };
      })
      ?.filter((category) => {
        const supplierList = category.value;
        return !isEmptyValue(supplierList);
      });
    setAvailableSuppliers(_availableSuppliers);
  }, [allSuppliers, config]);

  const updateConfig = (newConfig) => {
    setConfig({
      ...config,
      ...newConfig,
    });
  };

  const setConfigExport = (_config) => {
    if (isEmptyValue(_config)) {
      return;
    }
    const newConfig = cloneDeep(_config);
    const _cacheConfig = cloneDeep(_config);
    setConfig(newConfig);
    setCacheConfig(_cacheConfig);
  };

  const updateSelectedItem = (itemID) => {
    let newItemIDs = [...config.itemIDs];
    if (newItemIDs.includes(itemID)) {
      newItemIDs = newItemIDs?.filter((i) => i !== itemID);
    } else {
      newItemIDs = [...newItemIDs, itemID];
    }
    updateConfig({ itemIDs: newItemIDs });
    resetFormError();
  };

  const addSelectedItemList = (itemIDs) => {
    let newItemIDs = [...config.itemIDs, ...itemIDs];
    updateConfig({ itemIDs: newItemIDs });
    resetFormError();
  };

  const setSelectedItemList = (itemIDs) => {
    let newItemIDs = [...itemIDs];
    updateConfig({ itemIDs: newItemIDs });
    resetFormError();
  };

  const resetSelectedItemList = () => {
    updateConfig({ itemIDs: [] });
    resetFormError();
  };

  const setAttachFiles = (value) => {
    updateConfig({
      details: {
        ...config.details,
        attachFiles: value,
      },
    });
    resetFormError();
  };

  const setRemarks = (value) => {
    updateConfig({
      details: {
        ...config.details,
        remarks: value,
      },
    });
    resetFormError();
  };

  const setSuppliers = (newValues, slotID) => {
    resetFormError();
    const slot = config.details.slots?.find((s) => s.slotID === slotID);
    if (!slot) {
      return;
    }
    if (isEmptyValue(newValues)) {
      slot.suppliers = [];
    } else {
      const emailArr = Array.from(
        new Set(
          allSupplierIDAndEmails
            ?.filter((o) => newValues.includes(`${o.userID}`))
            ?.map((o) => o.email)
        )
      );
      if (
        emailArr?.every((email) => !isEmptyValue(email) && validateEmail(email))
      ) {
        slot.suppliers = emailArr?.map((email) => ({ email }));
      } else {
        updateFormErrorState({
          invalidEmail: {
            slotID,
            error: 'Invalid email address',
          },
        });
      }
    }
    updateConfig({
      details: {
        ...config.details,
      },
    });
  };

  const hasConfigError = (_config) => {
    if (isEmptyValue(_config.itemIDs)) {
      updateFormErrorState({
        selectItems: 'No item is selected',
      });
      return true;
    }
    if (_config.details.slots?.every((s) => isEmptyValue(s.suppliers))) {
      updateFormErrorState({
        emptySuppliers: 'Supplier is required',
      });
      return true;
    }
    return false;
  };

  const createScheduleConfig = async (configParam) => {
    const newConfig = configParam ?? config;
    if (
      (!isEmptyValue(formErrorState) &&
        Object.values(formErrorState)?.some((e) => !isEmptyValue(e))) ||
      hasConfigError(newConfig)
    ) {
      throw new Error(`Form error`);
    }
    return upsertScheduleConfig(newConfig);
  };

  const updateScheduleConfig = async (configParam) => {
    const newConfig = configParam ?? config;
    if (
      (!isEmptyValue(formErrorState) &&
        Object.values(formErrorState)?.some((e) => !isEmptyValue(e))) ||
      hasConfigError(newConfig)
    ) {
      throw new Error(`Form error`);
    }
    return upsertScheduleConfig(newConfig).then(() => {
      updateConfig(newConfig);
    });
  };

  const cancelEditScheduleConfig = () => {
    setConfig(cacheConfig);
  };

  function resetFormError() {
    updateFormErrorState({
      ...INIT_FORM_ERROR,
    });
  }

  return [
    {
      config,
      availableSuppliers,
      formErrorState,
    },
    {
      setConfig: setConfigExport,
      updateSelectedItem,
      addSelectedItemList,
      setSelectedItemList,
      resetSelectedItemList,
      setAttachFiles,
      setRemarks,
      setSuppliers,
      setAllSuppliers,
      createScheduleConfig,
      updateScheduleConfig,
      cancelEditScheduleConfig,
      resetFormError,
    },
  ];
};
