import React from 'react';

import { first } from 'lodash';

import { Icon, IconButton, Tooltip } from '@material-ui/core';

import { FlexRow } from '@components/layouts/FlexLayouts';
import OrmDateCell from '@pages/order-ready-management/OrmDateCell';

import CheckIcon from '@components/icons/CheckIcon';
import EmailStatusIcon from '@components/icons/EmailStatusIcon';
import FactCheckIcon from '../assets/icons/fact_check_blue.svg';

import { batchEditItemsApi } from '../apis/itemApi';

import {
  getCollectionDatesFromItemList,
  getLatestCollectionDateStr,
} from '@utils/itemUtils';
import { isEmptyValue } from '@utils/commonUtils';
import {
  formatDeliveryDateWithCheck,
  getCurrentDateStr,
} from '@utils/dateTimeUtils';
import { isSupplierRole } from '@utils/roleUtils';
import { compareStringsEqualsIgnoreCase } from '@utils/stringUtils';

import { notifyError, notifySuccess } from '@services/notificationService';

// -------------------------------------------------------------------------------------------------

const renderCollectionDateChangeAcknowledgedIcon = (
  rowData = {},
  collectionDate = '',
  revisedCollectionDate = '',
  refetch = () => {},
  classes = {}
) => {
  const handleCollectionDateChangeAcknowledgement = (
    e,
    changedBy,
    itemIDList
  ) => {
    e.preventDefault();
    e.stopPropagation();

    batchEditItemsApi({
      itemIDs: itemIDList ?? [],
      queryParams: {
        ['collectionDateChangeAcknowledgementDetails']: JSON.stringify({
          changedBy: changedBy ?? '',
          acknowledged: true,
          acknowledgedAt: getCurrentDateStr(),
        }),
      },
    })
      .then(() => {
        notifySuccess(`Acknowledged successfully`);
        refetch();
      })
      .catch(() => {
        notifyError(`Acknowledgement failed`);
      });
  };

  // All items in the project should have the same collectionDateChangeAcknowledgementDetails so just using the first one is sufficient
  const { changedBy, acknowledged, acknowledgedAt } =
    first(rowData?.itemOrderReadyList)
      ?.collectionDateChangeAcknowledgementDetails ?? {};

  const formattedDates = {
    formattedCollectionDate: formatDeliveryDateWithCheck(collectionDate),
    formattedRevisedCollectionDate: formatDeliveryDateWithCheck(
      revisedCollectionDate
    ),
    formattedCollectionDateChangeAcknowledgeDate: formatDeliveryDateWithCheck(
      acknowledgedAt ?? ''
    ),
  };

  if (
    compareStringsEqualsIgnoreCase(rowData.status, 'delivered') ||
    isEmptyValue(revisedCollectionDate) ||
    !isSupplierRole(changedBy)
  ) {
    return <CheckIcon toolTipText={'No further action required.'} />;
  }

  if (acknowledged) {
    return (
      <CheckIcon
        toolTipText={`Collection date changed ${changedBy ? 'by ' + changedBy : ''} from ${formattedDates.formattedCollectionDate} to
              ${formattedDates.formattedRevisedCollectionDate} for ALL items in Project #${rowData.projectID}, and acknowledged on
              ${formattedDates.formattedCollectionDateChangeAcknowledgeDate}.`}
      />
    );
  }
  return (
    <Tooltip
      title={`Click to acknowledge collection date change  ${changedBy ? 'by ' + changedBy : ''} from ${formattedDates.formattedCollectionDate} to
          ${formattedDates.formattedRevisedCollectionDate} for ALL items in Project #${rowData.projectID}.`}
    >
      <IconButton
        color='primary'
        onClick={(e) =>
          handleCollectionDateChangeAcknowledgement(
            e,
            changedBy,
            rowData?.itemOrderReadyList?.map(
              (itemDetails) => itemDetails.itemID
            )
          )
        }
      >
        <Icon>
          <img
            className={classes.imageIcon}
            src={FactCheckIcon}
            color='primary'
            alt=''
          />
        </Icon>
      </IconButton>
    </Tooltip>
  );
};

export const ormCollectionDateColumn = ({
  datePickerState,
  handleOpenDatePicker,
  handleCloseDatePicker,
  onUpdateDate,
  refetch = () => {},
  classes = {},
}) => ({
  headerName: 'Collection Date',
  field: 'collectionDate',
  valueGetter: (params) => {
    return getLatestCollectionDateStr(params.row.itemOrderReadyList);
  },
  renderCell: ({ row: rowData }) => {
    const { collectionDate, revisedCollectionDate, actualCollectionDate } =
      getCollectionDatesFromItemList(rowData.itemOrderReadyList);

    const emailIcons = (
      <>
        <EmailStatusIcon
          status={rowData.scheduleOrderDeliveryDueSoon?.status}
          emailType={rowData.scheduleOrderDeliveryDueSoon?.type}
          date={rowData.scheduleOrderDeliveryDueSoon?.date}
        />
        <EmailStatusIcon
          status={rowData.brevoReminderOrderDeliveryDueSoon?.lastStatus}
          emailType={rowData.brevoReminderOrderDeliveryDueSoon?.type}
          date={rowData.brevoReminderOrderDeliveryDueSoon?.updatedAt}
        />
      </>
    );

    return (
      <FlexRow>
        <div>
          <OrmDateCell
            type='collection'
            dates={{
              baseDate: collectionDate,
              revisedDate: revisedCollectionDate,
              actualDate: actualCollectionDate,
            }}
            rowData={rowData}
            pickerState={datePickerState}
            handleOpenDatePicker={handleOpenDatePicker}
            handleCloseDatePicker={handleCloseDatePicker}
            onUpdateDate={onUpdateDate}
            emailIcons={emailIcons}
          />
        </div>
        <div>
          {renderCollectionDateChangeAcknowledgedIcon(
            rowData,
            collectionDate,
            revisedCollectionDate,
            refetch,
            classes
          )}
        </div>
      </FlexRow>
    );
  },
  align: 'center',
  minWidth: 200,
});
