import { ceil } from 'lodash';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles, Tooltip } from '@material-ui/core';
import {
  Edit as EditNeededIcon,
  SpeakerNotes as NotesGivenIcon,
} from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';

import FactCheckIcon from '../assets/icons/fact_check_blue.svg';

import Thumb from '../components/fields/Thumb';
import HorizontalExpandSpace from '../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../components/grid-data/CustomToolbar';
import SearchBar from '../components/grid-data/buttons/SearchBar';
import GridDataPagination from '../components/grid-data/GridDataPagination';
import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';
import DataGridWrapCell from '../components/tables/cells/DataGridWrapCell';

import { ROLE_TYPES } from '../constants';
import { FEEDBACK_STATUS } from '../constants/feedbackConstants';
import {
  SUPPLIER_FEEDBACK,
  SUPPLIER_FEEDBACK_TOOLTIPS_FOR_ADMIN,
} from '../constants/ppeFeedbackConstants';

import useDataGridPaginationHook from '../hooks/useDataGridPaginationHook';

import { isEmptyValue } from '../utils/commonUtils';
import { formatDateWithTime, withDateValidation } from '../utils/dateTimeUtils';
import { includesIgnoreCase } from '../utils/stringUtils';
import { openInNewTab } from '../utils/navigationUtils';
import { getAllPPEPriceQuoteFeedbackWithParams } from '../apis/ppeFeedbackApi';

const ALLOWED_ITEM_ORDER_STATUSES = [1, 3];
const ALLOWED_FEEDBACK_ROLES = [
  ROLE_TYPES.ADMIN,
  ROLE_TYPES.SUPER_ADMIN,
  ROLE_TYPES.SUPPLIER,
];

const useStyles = makeStyles(() => ({
  body: {
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const ItemSupplierPriceFeedbackDataGrid = (props) => {
  const { preloadedSupplierFeedback = [], isEditQuotePopup = false } = props;

  const classes = useStyles();

  const [searchAutoFocus, setSearchAutoFocus] = useState(false);
  const [filteredPreloadedData, setFilteredPreloadedData] = useState(
    preloadedSupplierFeedback
  );

  const [
    { tableData, tableQueryParams, isFetching },
    { handleSearch, updateTableQueryParams },
  ] = useDataGridPaginationHook(getAllPPEPriceQuoteFeedbackWithParams, {
    initialQueryParams: {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      statuses: ALLOWED_ITEM_ORDER_STATUSES,
      roles: ALLOWED_FEEDBACK_ROLES,
    },
    queryEnabled: !isEditQuotePopup && isEmptyValue(preloadedSupplierFeedback),
  });

  // This hook watches for changes to the preloaded data passed as prop arg from EditOrder page
  useEffect(() => {
    if (!isEmptyValue(preloadedSupplierFeedback)) {
      updateTableQueryParams({
        totalCount: filteredPreloadedData?.length,
      });
    }
  }, [filteredPreloadedData]);

  useEffect(() => {
    if (!isEmptyValue(preloadedSupplierFeedback)) {
      setFilteredPreloadedData(
        preloadedSupplierFeedback?.filter((row) => {
          return (
            includesIgnoreCase(
              '' + row?.userFeedbackEmail,
              tableQueryParams.search
            ) || includesIgnoreCase('' + row?.itemID, tableQueryParams.search)
          );
        })
      );
    }
  }, [tableQueryParams.search]);

  const columns = [
    {
      field: 'supplierFeedbackType',
      headerName: 'Type',
      width: 100,
      sortable: false,
      renderCell: ({ row }) => {
        return row?.feedback === FEEDBACK_STATUS.GOOD ? (
          <Thumb variant='up' isActive={true} tooltipText='Price is good' />
        ) : (
          <Thumb variant='down' isActive={true} tooltipText='Price is bad' />
        );
      },
      align: 'center',
    },
    {
      field: 'supplierFeedbackActionItem',
      headerName: 'Action Item',
      width: 100,
      sortable: false,
      renderCell: ({ row }) => {
        const feedbackDetails = row?.feedbackDetails || {};
        const feedbackType = Object.keys(feedbackDetails)?.filter(
          (key) => feedbackDetails[key]
        )[0];
        if (isEmptyValue(feedbackType)) {
          return '-';
        }
        return (
          <Tooltip
            title={SUPPLIER_FEEDBACK_TOOLTIPS_FOR_ADMIN[feedbackType] || ''}
          >
            {SUPPLIER_FEEDBACK.NO_ACTIONS === feedbackType ? (
              <NotesGivenIcon color='primary' style={{ fill: 'green' }} />
            ) : SUPPLIER_FEEDBACK.TAKE_ORDER_WISH_PRICE_TO_REVISE ===
              feedbackType ? (
              <img src={FactCheckIcon} />
            ) : (
              <EditNeededIcon style={{ fill: 'orange' }} />
            )}
          </Tooltip>
        );
      },
      align: 'center',
    },
    {
      headerName: 'Item ID',
      field: 'itemID',
      hide: isEditQuotePopup,
      renderCell: ({ row }) => {
        const itemURL = row?.quotationID
          ? `/order/edit/${row?.quotationID}`
          : `/verify-quotes`;
        return (
          <DataGridWrapCell
            style={{
              lineHeight: 'normal',
            }}
          >
            <Tooltip title={'Go to order page for this item'}>
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  openInNewTab(itemURL);
                }}
                to={itemURL}
              >
                {row.itemID}
              </Link>
            </Tooltip>
          </DataGridWrapCell>
        );
      },
      width: 200,
      sortable: false,
      align: 'center',
    },
    {
      field: 'feedbackEmail',
      headerName: 'Feedback Email',
      width: 250,
      sortable: false,
      valueGetter: ({ row }) => row?.userFeedbackEmail || '-',
    },
    {
      field: 'supplierPPEUnitPrice',
      headerName: 'Requested Price (PPE Price)',
      width: 200,
      sortable: false,
      renderCell: ({ row }) => (
        <DataGridWrapCell>
          {`S$${row?.targetPrice || ' -'} (S$${row?.unitPrice || ' - '})`}
        </DataGridWrapCell>
      ),
      align: 'center',
    },
    {
      field: 'supplierFeedbackRemarks',
      headerName: 'Feedback Remarks',
      sortable: false,
      width: 200,
      renderCell: ({ row }) => (
        <DataGridWrapTextCell text={row?.additionalRemarks || '-'} />
      ),
      align: 'center',
    },
    {
      field: 'feedbackCreatedAt',
      headerName: 'Created At',
      width: 150,
      hide: isEditQuotePopup,
      sortable: false,
      renderCell: ({ row }) => (
        <DataGridWrapCell>
          {withDateValidation(formatDateWithTime)(row?.createdAt) || '-'}
        </DataGridWrapCell>
      ),
      align: 'center',
    },
  ];

  const getCustomToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
              autoFocus={searchAutoFocus}
              onFocus={() => setSearchAutoFocus(true)}
              onBlur={() => setSearchAutoFocus(false)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes?.body}>
      <DataGrid
        autoHeight
        columns={columns}
        components={{
          Toolbar: getCustomToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        disableColumnMenu
        disableSelectionOnClick
        getRowId={(row) => row.createdAt}
        loading={isFetching}
        page={tableQueryParams.page}
        pageSize={tableQueryParams.pageSize}
        paginationMode='server'
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
        rowCount={tableQueryParams.totalCount}
        rows={
          !isEmptyValue(filteredPreloadedData)
            ? filteredPreloadedData
            : tableData || []
        }
        rowsPerPageOptions={[5, 10, 20, 50]}
      />
    </div>
  );
};

export default ItemSupplierPriceFeedbackDataGrid;
