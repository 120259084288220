import React from 'react';

import { FormControlLabel } from '@material-ui/core';

import FtrTypography from './FtrTypography';
import FtrBlueCheckbox from './FtrBlueCheckbox';

/**
 * A material-ui Checkbox component with a blue tick and a label
 *
 * The component will render a blue checkbox with a label.
 * The label will be rendered using FtrTypography.
 * The component will also render a FtrBlueCheckbox.
 *
 * @param {Object} props The props for the component
 * @param {string} [props.label=''] The label for the checkbox
 * @param {function} [props.onChange] The function to call when the checkbox is changed
 * @param {boolean} [props.checked=false] If the checkbox is checked or not
 * @param {boolean} [props.disabled=false] If the checkbox is disabled or not
 * @param {string} [props.labelPadding='0px 10px'] The padding for the label
 * @param {Object} [props.style] The style object for the component
 * @param {Object} [props.classes] The classes object for the component
 *
 * @return {ReactElement}
 */
function FtrCheckbox(props) {
  const {
    label = '',
    onChange,
    checked,
    disabled = false,
    labelPadding = '0px 10px',
    ...rest
  } = props;

  return (
    <FormControlLabel
      control={
        <FtrBlueCheckbox
          onChange={onChange}
          checked={checked}
          style={{ padding: labelPadding }}
          disabled={disabled}
        />
      }
      style={{
        width: 'fit-content',
      }}
      label={
        <FtrTypography type='body' fontSize='14' style={{ userSelect: 'none' }}>
          {label}
        </FtrTypography>
      }
      {...rest}
    />
  );
}

export default FtrCheckbox;
