import React from 'react';

import FtrTypography from './FtrTypography';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

export const TECHNICAL_DRAWING_UN_SUPPORT_MESSAGE =
  'Only PDF/Images/AI files accepted';

function TechnicalDrawingUploadFileTypeMessage() {
  return (
    <FtrTypography
      type='body'
      fontSize='14'
      style={{ padding: '0.3rem 0', color: colors.neutral080 }}
    >
      {TECHNICAL_DRAWING_UN_SUPPORT_MESSAGE}
    </FtrTypography>
  );
}

export default TechnicalDrawingUploadFileTypeMessage;
