import React from 'react';
import { Link } from 'react-router-dom';

import DataGridWrapTextCell from './DataGridWrapTextCell';
import { FIVE_MINUTES_IN_SECOND } from '../../../constants/dateTimeConstants';
import useDeviceCheck from '../../../hooks/useDeviceCheck';
import { openInNewTab } from '../../../utils/navigationUtils';
import {
  downloadS3File,
  extractFileNameWithoutTimestampFromUrl,
} from '../../../utils/fileUtils';

import { generatePresignedUrl } from '../../../services/s3Service';

// -------------------------------------------------------------------------------------------------

/**
 * A DataGrid cell that renders a clickable link which opens the given link if on a desktop, or downloads the file if on a mobile device.
 *
 * @param {Object} props
 * @param {string} props.url the url to open/download
 * @param {string} props.name the name to be displayed instead of the url text
 * @returns {React.ReactElement}
 */
function DataGridLinkCell(props) {
  const { url, name, ...restProps } = props;

  const [{ isIPad, isMobile, isTablet }] = useDeviceCheck();

  return (
    <DataGridWrapTextCell
      text={
        <Link
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (!isMobile && !isTablet && !isIPad) {
              generatePresignedUrl(url, FIVE_MINUTES_IN_SECOND, true).then(
                (presignedUrl) => {
                  openInNewTab(presignedUrl);
                }
              );
            } else {
              downloadS3File(url);
            }
          }}
        >
          {name || extractFileNameWithoutTimestampFromUrl(url)}
        </Link>
      }
      {...restProps}
    />
  );
}

export default DataGridLinkCell;
