//Import settings
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';

import { colors } from '../../palette';

import { completeAnswerQuestion } from '../../actions';

import unfilledGreyCheck from '../../assets/icons/unfilled_grey_check.svg';
import filledGreyCheck from '../../assets/icons/filled_grey_check.svg';

// Import customized component
import { HOW_YOU_HEAR_ANSWERS_MAPPING } from '../../constants/signupConstants';
import CountryDropDown from './CountryDropDown';
import QuestionAnswerDropdown from './QuestionAnswerDropdown';
import { validatePassword } from '../../utils/passwordUtils';
import {
  CUSTOMER_STATUS_TYPES,
  CUSTOMER_TYPES,
  INDUSTRIES,
} from '../../constants/customerConstants';
import { PASSWORD_RULES } from '../../constants/passwordConstants';
import AddressAutocomplete from '../AddressAutocomplete';
import { GOOGLE_API_KEY } from '../../constants/apiConstants';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 5,
    display: 'flex',
    minWidth: '450px',
    padding: '56px 48px',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.20)',
    [theme.breakpoints.down('sm')]: {
      minWidth: '95%',
      padding: '28px 15px',
    },
  },
  title: {
    fontSize: '22pt',
    lineHeight: '1.4',
    fontWeight: 'bold',
    textAlign: 'center',
    letterSpacing: '-1pt',
    paddingBottom: '15pt',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16pt',
    },
  },
  inputField: {
    width: '100%',
    marginBottom: '13px',
    textTransform: 'none',
    color: colors.fontGrey,
  },
  inputLabel: {
    fontSize: 16,
    width: '100%',
    fontWeight: 600,
    color: colors.fontBlack,
  },
  skipNextButtonsContainer: {
    display: 'flex',
    gap: 8,
  },
  skipForLaterButton: {
    marginTop: 10,
    fontWeight: 600,
    fontSize: '14pt',
    textTransform: 'none',
    color: colors.fontGrey,
    borderRadius: 5,
  },
  nextButton: {
    marginTop: 10,
    fontWeight: 600,
    fontSize: '14pt',
    textTransform: 'none',
    color: colors.fontWhite,
    background: colors.buttonBlue,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: colors.blue050,
    },
  },
  rules: {
    display: 'flex',
    lineHeight: 1.5,
    color: colors.neutral060,
    [theme.breakpoints.down('sm')]: {
      fontSize: '10pt',
    },
  },
}));

export function AccountSignUpForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { defaultCountry, continueSignup, completeSignup, verificationCode } =
    props;

  const sections = [
    'Set an account password that you can easily remember',
    'Set up your Factorem account',
    'Help Factorem understand and serve you more effectively',
  ];
  const [currentSection, setCurrentSection] = useState(0);

  // variables for section 0
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordRulesFailed, setPasswordRulesFailed] = useState(
    Object.keys(PASSWORD_RULES)
  );
  const [sectionZeroNextButtonEnabled, setSectionZeroNextButtonEnabled] =
    useState(false);

  // variables for section 1
  const [companyName, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState(defaultCountry);
  const [postalCode, setPostalCode] = useState('');
  const [unitNo, setUnitNo] = useState('');

  // variables for section 2
  const [customerType, setCustomerType] = useState('');
  const [industrySector, setIndustry] = useState('');
  const [questionAnswer, setQuestionAnswer] = useState({
    question: 'How did you hear about us?',
  });

  useEffect(() => {
    const passwordValidated = isEmpty(passwordRulesFailed);

    setSectionZeroNextButtonEnabled(passwordValidated);
  }, [passwordRulesFailed]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCountryChange = (value) => {
    setCountry(value);
  };

  const handleNext = () => {
    const userData = {
      password,
      companyName,
      industrySector,
      customerType,
      address,
      questionAnswer,
      verificationCode,
      country,
      unitNo,
      postalCode,
    };
    continueSignup(userData);
    setCurrentSection(currentSection + 1);
  };

  const handleSubmit = () => {
    const userData = {
      password,
      companyName,
      industrySector,
      customerType,
      address,
      questionAnswer,
      verificationCode,
      country,
      status: CUSTOMER_STATUS_TYPES.QUALIFIED,
      unitNo,
      postalCode,
    };
    if (questionAnswer.answer) {
      dispatch(completeAnswerQuestion(userData));
    }
    completeSignup(userData);
  };

  return (
    <Paper className={classes.paper} elevation={1}>
      <Typography className={classes.title}>
        {sections[currentSection]}
      </Typography>
      {currentSection === 0 && (
        <Grid item xs={10} style={{ width: '80%' }}>
          <Typography className={classes.inputLabel}>Password</Typography>
          <TextField
            id='password'
            name='password'
            type={showPassword ? 'text' : 'password'}
            className={classes.inputField}
            value={password}
            onChange={({ target }) => {
              setPasswordRulesFailed(validatePassword(target.value));
              setPassword(target.value);
            }}
            variant='outlined'
            size='small'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {Object.entries(PASSWORD_RULES)?.map(([key, value]) => {
            return (
              <div
                key={`${key}-rules`}
                // style={{ fontSize: 13 }}
                className={classes.rules}
              >
                {passwordRulesFailed.includes(key) ? (
                  <img
                    src={unfilledGreyCheck}
                    alt='icon'
                    style={{ marginRight: '0.5rem' }}
                  />
                ) : (
                  <img
                    src={filledGreyCheck}
                    alt='icon'
                    style={{ marginRight: '0.5rem' }}
                  />
                )}
                {value.message}
              </div>
            );
          })}
          <Box style={{ width: '2rem' }}></Box>
          <Button
            id='next'
            type='button'
            fullWidth
            variant='contained'
            className={classes.nextButton}
            onClick={() => handleNext()}
            disabled={!sectionZeroNextButtonEnabled}
          >
            Next
          </Button>
        </Grid>
      )}
      {currentSection === 1 && (
        <Grid item xs={10} style={{ width: '80% ' }}>
          <Typography className={classes.inputLabel}>Company Name</Typography>
          <TextField
            id='companyName'
            name='companyName'
            className={classes.inputField}
            value={companyName}
            onChange={({ target }) => {
              setCompanyName(target.value);
            }}
            placeholder='Company / Education Instituition / Hobbyist, etc.'
            variant='outlined'
            size='small'
          />
          <Typography className={classes.inputLabel}>Address</Typography>
          {GOOGLE_API_KEY ? (
            <AddressAutocomplete
              showCountry={false}
              className={classes.inputField}
              margin='none'
              size='small'
              label={null}
              onChange={({
                postalCode: _postalCode,
                unitNo: _unitNo,
                address: _address,
                country: _country,
              }) => {
                setAddress(_address);
                setPostalCode(_postalCode);
                setUnitNo(_unitNo);
                setCountry(_country);
              }}
              initialValue={{
                address: '',
                postalCode: '',
                unitNo: '',
                country: defaultCountry,
              }}
            />
          ) : (
            <TextField
              id='address'
              name='address'
              className={classes.inputField}
              value={address}
              onChange={({ target }) => setAddress(target.value)}
              variant='outlined'
              size='small'
            />
          )}
          <Typography className={classes.inputLabel}>Country</Typography>
          <CountryDropDown
            id='country'
            onSelect={handleCountryChange}
            valueSelected={country}
          />
          <Box style={{ width: '2rem' }}></Box>
          <Box className={classes.skipNextButtonsContainer}>
            <Button
              id='skip'
              type='button'
              variant='text'
              className={classes.skipForLaterButton}
              fullWidth
              onClick={() => handleSubmit()}
            >
              Skip for later
            </Button>
            <Button
              id='next'
              type='button'
              variant='contained'
              className={classes.nextButton}
              fullWidth
              onClick={() => handleNext()}
            >
              Next
            </Button>
          </Box>
        </Grid>
      )}
      {currentSection === 2 && (
        <Grid item xs={10} style={{ width: '80% ' }}>
          <FormControl fullWidth size='small'>
            <Typography className={classes.inputLabel}>
              What do you do?
            </Typography>
            <Select
              className={classes.inputField}
              value={customerType}
              onChange={(event) => {
                setCustomerType(event.target.value);
              }}
              variant='outlined'
            >
              {Object.values(CUSTOMER_TYPES)?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size='small'>
            <Typography className={classes.inputLabel}>
              Where are you from?
            </Typography>
            <Select
              className={classes.inputField}
              value={industrySector}
              onChange={(event) => {
                setIndustry(event.target.value);
              }}
              variant='outlined'
            >
              {Object.values(INDUSTRIES)?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <QuestionAnswerDropdown
            id='how-did-you-hear-about-us'
            question={questionAnswer.question}
            answerOptions={HOW_YOU_HEAR_ANSWERS_MAPPING}
            updateAnswer={setQuestionAnswer}
          />
          <Box style={{ width: '2rem' }}></Box>
          <Box className={classes.skipNextButtonsContainer}>
            <Button
              id='skip'
              type='button'
              variant='text'
              className={classes.skipForLaterButton}
              fullWidth
              onClick={() => handleSubmit()}
            >
              Skip for later
            </Button>
            <Button
              id='signup'
              type='button'
              fullWidth
              variant='contained'
              className={classes.nextButton}
              onClick={() => handleSubmit()}
            >
              Create Account
            </Button>
          </Box>
        </Grid>
      )}
    </Paper>
  );
}

function mapStateToProps() {
  return {};
}

function matchDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(AccountSignUpForm);
