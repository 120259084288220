import React, { useReducer } from 'react';

import { useQuery } from 'react-query';

import MctDeliveryBreakdownPopup from '../components/popups/MctDeliveryBreakdownPopup';

import { isEmptyValue } from '../utils/commonUtils';
import { getTrackingByID } from '../apis/multiCheckoutApi';

// -------------------------------------------------------------------------------------------------

function withMctDeliveryBreakdownPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
      data: {},
      mctID: null,
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    useQuery(
      ['getTrackingByID', localState.mctID],
      () => getTrackingByID(localState.mctID),
      {
        enabled: !isEmptyValue(localState.mctID),
        onSettled: (data, error) => {
          if (error) {
            // do nothing
            return;
          }

          updateLocalState({
            data,
          });
        },
      }
    );

    return (
      <>
        <WrappedComponent
          updateMctDeliveryBreakdownPopupHOCState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <MctDeliveryBreakdownPopup
            open={localState.open}
            data={localState.data}
            onClose={() => updateLocalState({ open: false })}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withMctDeliveryBreakdownPopupHOC;
