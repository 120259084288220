import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { updateAllowedDeniedSuppliersForItems } from '../../apis/viewJobsControlApi';

import { isEmptyValue } from '../../utils/commonUtils';

import BlueButton from '../buttons/BlueButton';
import MultiSelectChipDropdownWithCategory from '../dropdowns/MultiSelectChipDropdownWithCategory';
import Title from '../Title';
import WhiteButton from '../buttons/WhiteButton';
import { notifySuccess } from '../../services/notificationService';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 200,
    borderRadius: 0,
  },
  space: {
    width: theme.spacing(2),
  },
}));

function UpdateAllowedDeniedSuppliersPopup(props) {
  const classes = useStyles();

  const {
    open,
    handleClose = () => {},
    handleAddCallback = () => {},
    itemID,
    updateMode,
    availableToAllow = [],
    availableToDeny = [],
  } = props;

  const [selectedAccountIds, setSelectedAccountIds] = useState([]);
  const [applyAll, setApplyAll] = useState(false);
  const [addAllSuppliers, setAddAllSuppliers] = useState(false);
  const [emailListText, setEmailListText] = useState('');

  const onSupplierSelectChange = (newValues) => {
    setSelectedAccountIds(newValues);
  };

  useEffect(() => {
    if (addAllSuppliers) {
      let itemList =
        updateMode === 'ADD_ALLOWED' ? availableToAllow : availableToDeny;
      itemList = itemList?.map((item) => item.value)?.flat();
      const newSelectedIDList = itemList?.map((item) => item.key);
      setSelectedAccountIds(newSelectedIDList);
    } else {
      setSelectedAccountIds([]);
    }
  }, [addAllSuppliers]);

  const handleAddEmailList = () => {
    if (isEmptyValue(emailListText)) {
      return;
    }
    let itemList =
      updateMode === 'ADD_ALLOWED' ? availableToAllow : availableToDeny;
    itemList = itemList?.map((item) => item.value)?.flat();
    const emailList = emailListText
      .toLowerCase()
      ?.split(',')
      ?.map((email) => email.trim());
    const newSelectedIDList = itemList
      ?.filter((item) => {
        for (const email of emailList) {
          if (item.text.toLowerCase().includes(email)) {
            return true;
          }
        }
      })
      ?.map((item) => item.key)
      ?.filter((key) => !selectedAccountIds.includes(key));
    const selectedIDList = [...selectedAccountIds, ...newSelectedIDList];
    onSupplierSelectChange(selectedIDList);
    setEmailListText('');
  };

  const handleAdd = () => {
    const body = {
      selectedAccountIds,
      applyAll,
      itemID,
      updateMode,
    };
    updateAllowedDeniedSuppliersForItems(body).then(() => {
      notifySuccess(`Allow/Deny Suppliers List has been updated successfully`);
      handleAddCallback();
    });
  };

  const renderBody = () => {
    return (
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
          }}
        >
          <Tooltip title='Check this if you want to add all suppliers'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addAllSuppliers}
                  onChange={(e) => setAddAllSuppliers(e.target.checked)}
                  name='applyAll'
                />
              }
              label='Add all suppliers'
              data-cy='add-all-suppliers'
            />
          </Tooltip>
          {!addAllSuppliers && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '1rem',
                gap: '0.5rem',
              }}
            >
              <div
                style={{
                  width: '100%',
                }}
              >
                <Typography
                  variant='subtitle1'
                  style={{ fontStyle: 'italic', textAlign: 'left' }}
                >
                  * Select suppliers manually via dropdown below
                </Typography>
                <div style={{ marginLeft: '1rem' }}>
                  <MultiSelectChipDropdownWithCategory
                    id='multi-select-suppliers'
                    label='Select Account(s)'
                    itemList={
                      updateMode === 'ADD_ALLOWED'
                        ? availableToAllow
                        : availableToDeny
                    }
                    value={selectedAccountIds}
                    onSelect={onSupplierSelectChange}
                    searchable
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  flexWrap: 'wrap',
                }}
              >
                <Typography variant='subtitle1' style={{ fontStyle: 'italic' }}>
                  * Or paste the list of emails below, separated by commas
                </Typography>
                <TextField
                  style={{ marginLeft: '1rem' }}
                  variant='outlined'
                  autoFocus
                  margin='dense'
                  id='name'
                  label='Email list'
                  type='text'
                  fullWidth
                  onChange={(evt) => {
                    setEmailListText(evt.target.value);
                  }}
                  onBlur={() => {
                    handleAddEmailList();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      handleAddEmailList();
                    }
                  }}
                  placeholder='supplier@factorem.co,supplier@factorem.co'
                  value={emailListText}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ style: { textTransform: 'none' } }}
                />
              </div>
            </div>
          )}
        </div>
        <Tooltip title='Check this if you want to apply to all items in the same project that contains this item'>
          <FormControlLabel
            control={
              <Checkbox
                checked={applyAll}
                onChange={(e) => setApplyAll(e.target.checked)}
                name='applyAll'
              />
            }
            label='Add to all items in this project'
            data-cy='add-to-all-items-in-this-project'
          />
        </Tooltip>
      </div>
    );
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <Title
          contentTitle={`Add Supplier to ${
            updateMode === 'ADD_ALLOWED' ? 'Allowed' : 'Denied'
          } list for Item ID ${itemID}`}
          size='small'
        />
      </DialogTitle>
      <div
        style={{
          paddingBottom: '1rem',
        }}
      >
        <DialogContent
          style={{
            padding: '0 30px',
            paddingBottom: '2rem',
            textAlign: 'center',
          }}
        >
          <React.Fragment>{renderBody()}</React.Fragment>
        </DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
          }}
        >
          <WhiteButton
            onBtnClick={handleClose}
            btnContent='Cancel'
            type='cancelEdit'
          />
          <BlueButton
            onBtnClick={() => {
              handleAdd();
              handleClose();
            }}
            id='add-supplier-to-allowed-list-button'
            btnContent='Add'
            disabled={isEmptyValue(selectedAccountIds)}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default UpdateAllowedDeniedSuppliersPopup;
