import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { FtrButton, FtrTypography, FtrDropdownV2 } from '../ftr-components';
import { FtrBoldText } from '../ftr-components/FtrTypography';
import {
  FlexRow,
  FlexRowSpaceBetween,
  FlexColumn,
} from '../layouts/FlexLayouts';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
// import DeleteIcon from '@material-ui/icons/Delete'; // omit delete flow for now

import { validateEmail } from '../../utils/validators/emailValidator';

import { isEmptyValue } from '../../utils/commonUtils';

import { getUserRoleSelector } from '../../selectors/userSelector';

import { ROLE_TYPES } from '../../constants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

// Style the components
const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '30px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
    overflow: 'hidden',
    position: 'relative',
    width: '800px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  inputField: {
    width: '100%',
    marginBottom: '0.95rem',
    background: colors.fontWhite,
    '& .MuiOutlinedInput-root': {
      color: colors.neutral070,
      borderRadius: 10,
    },
    '& .MuiOutlinedInput-root .Mui-disabled': {
      color: colors.neutral050,
      borderRadius: 10,
    },
  },
}));

function EditProjectOwnerPopupV2(props) {
  const classes = useStyles();

  const {
    dialog,
    handleClose,
    onOwnerChange,
    onAssociatedOwnerUpdate,
    // onDeleteItem, // omit delete flow for now
    associatedOwners = [],
    currentOwnerEmail,
    // creatorEmail, // omit delete flow for now
  } = props;

  const userRole = useSelector(getUserRoleSelector);

  const [selectedOwnerEmail, setSelectedOwnerEmail] =
    useState(currentOwnerEmail);
  const [addOwnerName, setAddOwnerName] = useState('');
  const [addOwnerNameError, setAddOwnerNameError] = useState(null);
  const [addOwnerEmail, setAddOwnerEmail] = useState('');
  const [addOwnerEmailError, setAddOwnerEmailError] = useState(null);

  const handleAddButtonClick = () => {
    if (isEmptyValue(addOwnerName)) {
      setAddOwnerNameError('Name is required');
      return;
    }
    if (isEmptyValue(addOwnerEmail)) {
      setAddOwnerEmailError('Email is required');
      return;
    }
    if (!validateEmail(addOwnerEmail)) {
      setAddOwnerEmailError(`Email is not valid`);
      return;
    }
    if (associatedOwners?.find((owner) => owner.ownerEmail === addOwnerEmail)) {
      setAddOwnerEmailError(`Email has been used`);
      return;
    }
    onAssociatedOwnerUpdate(addOwnerName, addOwnerEmail);
    setAddOwnerName('');
    setAddOwnerNameError(null);
    setAddOwnerEmail('');
    setAddOwnerEmailError(null);
    setSelectedOwnerEmail(addOwnerEmail);
  };

  const handleSelectOwnership = (newOwnerEmail) => {
    setSelectedOwnerEmail(newOwnerEmail);
  };

  const handleChangeOwnership = () => {
    const newOwner = associatedOwners?.find(
      (owner) => owner.ownerEmail === selectedOwnerEmail
    );
    onOwnerChange(newOwner.ownerName, newOwner.ownerEmail);
    handleClose();
  };

  const AssociatedOwnerList = ({ ownerEmail, ownerName }) => {
    return (
      <MenuItem key={ownerEmail ?? ownerName} value={ownerEmail}>
        <FlexRow>
          <FtrBoldText fontSize='16' style={{ fontWeight: 700 }}>
            {ownerName}
          </FtrBoldText>
          |<FtrTypography fontSize='16'>{ownerEmail}</FtrTypography>
        </FlexRow>
      </MenuItem>
    );
  };

  const renderAddOwnerField = () => {
    return (
      <>
        <FtrTypography
          type='subHeading'
          fontSize='16'
          style={{ marginBottom: '2rem' }}
        >
          Add new owner
        </FtrTypography>
        <FlexRowSpaceBetween style={{ alignItems: 'flex-start' }}>
          <FlexColumn style={{ width: '240px' }}>
            <FtrBoldText style={{ color: colors.neutral070 }}>Name</FtrBoldText>
            <TextField
              name='name'
              variant='outlined'
              onChange={(e) => setAddOwnerName(e.target.value)}
              onFocus={() => setAddOwnerNameError(null)}
              value={addOwnerName}
              required={true}
              size='small'
              className={classes.inputField}
              error={addOwnerNameError}
              helperText={addOwnerNameError}
            />
          </FlexColumn>
          <FlexColumn style={{ width: '360px' }}>
            <FtrBoldText style={{ color: colors.neutral070 }}>
              Enter email
            </FtrBoldText>
            <TextField
              name='email'
              variant='outlined'
              onChange={(e) => setAddOwnerEmail(e.target.value)}
              onFocus={() => setAddOwnerEmailError(null)}
              value={addOwnerEmail}
              required={true}
              size='small'
              className={classes.inputField}
              error={!!addOwnerEmailError}
              helperText={addOwnerEmailError}
            />
          </FlexColumn>
          <FtrButton
            color='blue'
            variant='outlined'
            onClick={handleAddButtonClick}
            style={{
              position: 'relative',
              top: '28px',
            }}
            disabled={userRole === ROLE_TYPES.SALES_CONSULTANT}
          >
            Add Owner
          </FtrButton>
        </FlexRowSpaceBetween>
      </>
    );
  };

  const renderSelectOwnerList = () => {
    return (
      <div style={{ marginBottom: '2.5rem' }}>
        <FtrTypography
          type='subHeading'
          fontSize='14'
          style={{
            color: colors.neutral070,
          }}
        >
          Select owner
        </FtrTypography>
        <FtrDropdownV2
          key='owners-dropdown'
          fullWidth
          value={selectedOwnerEmail}
          customMenuItem={AssociatedOwnerList}
          items={associatedOwners}
          handleChange={handleSelectOwnership}
        />
      </div>
    );
  };

  const renderBody = () => {
    return (
      <React.Fragment>
        {!isEmptyValue(associatedOwners) && renderSelectOwnerList()}
        {renderAddOwnerField()}
      </React.Fragment>
    );
  };

  return (
    <Dialog
      maxWidth='md'
      open={dialog}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title' style={{ marginTop: '1rem' }}>
        <FtrTypography type='heading' fontSize='24'>
          Project Owner
        </FtrTypography>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        className={classes.closeButton}
      >
        <CloseIcon />
      </IconButton>
      <div
        style={{
          paddingBottom: '1rem',
        }}
      >
        <DialogContent style={{ paddingTop: '0', marginBottom: '3rem' }}>
          <React.Fragment>
            <FtrTypography
              type='body'
              fontSize='16'
              style={{
                marginBottom: '2rem',
              }}
            >
              Project owners will receive all notifications and communication
              regarding order issues.
            </FtrTypography>
            {renderBody()}
          </React.Fragment>
        </DialogContent>
        <DialogActions
          style={{
            paddingLeft: '24px',
            paddingRight: '24px',
          }}
        >
          <FtrButton
            color='blue'
            onClick={handleChangeOwnership}
            disabled={
              currentOwnerEmail === selectedOwnerEmail ||
              userRole === ROLE_TYPES.SALES_CONSULTANT
            }
          >
            Save
          </FtrButton>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default EditProjectOwnerPopupV2;
