import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';

import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  AddToPhotos as AddToPhotosIcon,
} from '@material-ui/icons';

import { FtrTextField } from '../ftr-components/FtrField';
import Title from '../Title';
import { FtrButton, FtrTooltip } from '../ftr-components';
import {
  FlexColumn,
  FlexColumnCenter,
  FlexRow,
  FlexRowAlignStart,
  FlexRowCenter,
} from '../layouts/FlexLayouts';

import { isEmptyValue } from '../../utils/commonUtils';

import { getItemsByIDList } from '../../apis/itemApi';
import { createXeroInvoiceItemsV2 } from '../../apis/xeroApi';

import { notifyError } from '../../services/notificationService';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 700,
    borderRadius: 10,
    overflowX: 'clip',
    boxSizing: 'border-box',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  closeFieldIcon: {
    position: 'absolute',
    top: 22,
    right: 0,
  },
}));

const DEFAULT_ADDITIONAL_NOTES = {
  HSS_CODE: 'HS CODE: 8487.90.00.80',
  MATERIAL: 'Material: Aluminum 6061',
  USAGE: 'Usage: General Use Machinery Parts',
  COUNTRY_OF_ORIGIN: 'Country of Origin: Singapore',
};

const ItemInvoiceForm = (props) => {
  const { item, setItem, deleteItem, isLoading } = props;

  const classes = useStyles();

  const renderDefaultOptionalNote = () => {
    if (!isEmptyValue(item.notes)) {
      return null;
    }
    return (
      <FlexRow>
        <Tooltip
          title={
            <div>
              {Object.entries(DEFAULT_ADDITIONAL_NOTES)?.map(([key, note]) => (
                <div key={key}>{note}</div>
              ))}
            </div>
          }
          arrow
        >
          <div>
            <FtrButton
              color='blue'
              variant='text'
              startIcon={<AddToPhotosIcon />}
              onClick={() =>
                setItem({
                  notes: Object.values(DEFAULT_ADDITIONAL_NOTES)?.join('\n'),
                })
              }
              loading={isLoading}
            >
              Add Default Additional Note
            </FtrButton>
          </div>
        </Tooltip>
      </FlexRow>
    );
  };

  return (
    <FlexRowAlignStart key={item.itemID}>
      <FtrTextField
        title='Item ID'
        value={item.itemID}
        disabled
        style={{ width: '100px' }}
      />
      <FtrTextField
        title='Quantity'
        value={item.quantity}
        onChange={(e) => setItem({ quantity: e.target.value })}
        style={{ width: '100px' }}
        type='number'
        disabled={isLoading}
      />
      <FlexRow style={{ width: '100%' }}>
        <FlexColumn style={{ gap: '0' }}>
          <div style={{ position: 'relative', width: '100%' }}>
            <FtrTextField
              title='Notes'
              value={item.notes}
              onChange={(e) => setItem({ notes: e.target.value })}
              multiline
              fullWidth
              disabled={isLoading}
            />
            <div className={classes.closeFieldIcon}>
              <FtrTooltip description='Clear Text' arrow>
                <IconButton
                  aria-label='close'
                  onClick={() => setItem({ notes: '' })}
                >
                  <CloseIcon />
                </IconButton>
              </FtrTooltip>
            </div>
          </div>
          {renderDefaultOptionalNote()}
        </FlexColumn>
      </FlexRow>
      <FtrTooltip description='Delete Item' arrow>
        <IconButton
          edge='start'
          aria-label='delete'
          onClick={() => deleteItem(item.itemID)}
          style={{ paddingTop: '2rem' }}
        >
          <DeleteIcon />
        </IconButton>
      </FtrTooltip>
    </FlexRowAlignStart>
  );
};

/**
 * A React functional component that renders a popup dialog for creating Xero invoice items.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onClose - A callback function to close the popup dialog.
 * @param {Function} [props.onSuccess] - An optional callback function to call after successfully creating Xero invoice items.
 * @param {Boolean} [props.open=false] - A boolean indicating whether the popup dialog is open.
 * @param {Boolean} [props.autoFocus=true] - A boolean indicating whether the input field should autofocus.
 * @return {JSX.Element} The JSX element representing the popup dialog.
 */
function CreateXeroInvoiceItemsPopupV2(props) {
  const { onClose, onSuccess = () => {}, open = false } = props;

  const classes = useStyles();

  const [itemIDs, seItemIDs] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);

  const hasItemsNote = items?.some((item) => !!item.notes);

  const updateItem = (itemID, params) => {
    if (isLoading) {
      return;
    }
    setItems((prevItems) => {
      return prevItems?.map((item) => {
        if (item.itemID === itemID) {
          return {
            ...item,
            ...params,
          };
        }
        return item;
      });
    });
  };

  const deleteItem = (itemID) => {
    if (isLoading) {
      return;
    }
    setItems((prevItems) =>
      prevItems?.filter((prevItem) => prevItem.itemID !== itemID)
    );
  };

  const handleCreateXeroInvoiceItems = () => {
    if (isEmptyValue(items)) {
      return;
    }
    setIsLoading(true);
    createXeroInvoiceItemsV2({ items })
      .then(() => {
        onClose();
        onSuccess();
      })
      .catch((err) => {
        notifyError(err.message || 'Something went wrong!');
      })
      .finally(() => setIsLoading(false));
  };

  const handleGetAcceptedQuoteForItems = () => {
    setIsLoading(true);
    getItemsByIDList(itemIDs?.split(','))
      .then((data) => {
        if (isEmptyValue(data)) {
          notifyError('No items found!');
          return;
        }
        const newItems = data?.map((item) => ({
          itemID: item.itemID,
          material: item.qMaterial || item.material,
          quantity: item.qQuantity || item.quantity,
          notes: Object.values(DEFAULT_ADDITIONAL_NOTES)?.join('\n'),
        }));
        setItems((prevItems) => {
          const newItemsFiltered = newItems?.filter(
            (item) =>
              !prevItems?.find((prevItem) => prevItem.itemID === item.itemID)
          );
          return [...prevItems, ...newItemsFiltered];
        });
        seItemIDs('');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>
        <div style={{ padding: '0 1rem' }}>
          <Title contentTitle={'Create Xero Invoice Items'} size='small' />
        </div>
      </DialogTitle>
      <DialogContent>
        <FlexColumn>
          <div style={{ position: 'relative', width: '100%' }}>
            <FtrTextField
              value={itemIDs}
              onChange={(e) => seItemIDs(e.target.value)}
              multiline
              placeholder='e.g. 2300,2301,2302'
              autoFocus={true}
              title='Item IDs* (comma separated)'
            />
            <div className={classes.closeFieldIcon}>
              <FtrTooltip description='Clear Text' arrow>
                <IconButton aria-label='close' onClick={() => seItemIDs('')}>
                  <CloseIcon />
                </IconButton>
              </FtrTooltip>
            </div>
          </div>

          <FlexRowCenter style={{ marginBottom: '1rem' }}>
            <FtrTooltip
              description={
                isEmptyValue(itemIDs) ? 'Please input Item ID(s)' : ''
              }
              arrow
            >
              <div>
                <FtrButton
                  color='black'
                  onClick={handleGetAcceptedQuoteForItems}
                  disabled={isLoading || isEmptyValue(itemIDs)}
                  loading={isLoading}
                >
                  Add Items
                </FtrButton>
              </div>
            </FtrTooltip>
            {!isEmptyValue(items) && (
              <FtrButton
                color='red'
                onClick={() => setItems([])}
                loading={isLoading}
              >
                Delete All Items
              </FtrButton>
            )}
            {hasItemsNote && (
              <FtrButton
                color='yellow'
                loading={isLoading}
                onClick={() => {
                  setItems((prevItems) =>
                    prevItems?.map((prevItem) => {
                      return {
                        ...prevItem,
                        notes: '',
                      };
                    })
                  );
                }}
              >
                Clear All Notes
              </FtrButton>
            )}
          </FlexRowCenter>
          {items.map((item) => {
            return (
              <ItemInvoiceForm
                key={item.itemID}
                item={item}
                isLoading={isLoading}
                setItem={(params) => updateItem(item.itemID, params)}
                deleteItem={deleteItem}
              />
            );
          })}
        </FlexColumn>
      </DialogContent>
      {!isEmptyValue(items) && (
        <FlexColumnCenter
          style={{
            width: '100%',
            padding: '0.5rem 0 1rem 0',
          }}
        >
          <FtrButton
            disabled={isEmptyValue(items)}
            onClick={handleCreateXeroInvoiceItems}
            color='blue'
            loading={isLoading}
          >
            Create
          </FtrButton>
        </FlexColumnCenter>
      )}
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        style={{ position: 'absolute', top: 0, right: 0 }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default CreateXeroInvoiceItemsPopupV2;
