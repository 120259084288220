/* eslint-disable no-shadow */
import React from 'react';
import {
  Tooltip,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { PieChart, Pie, Cell, LabelList } from 'recharts';
import averageCycleIcon from '../../assets/icons/average_cycle_time.svg';

const useStyles = makeStyles((theme) => ({
  cycleChart: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '12px',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '287px',
    position: 'relative',
    '& .recharts-surface': {
      overflow: 'visible',
    },
    [theme.breakpoints.down('xs')]: {
      height: '220px',
    },
  },
  chart: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
  },
  averageCycle: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '1rem',
    left: '1.2rem',
    fontWeight: '700',
    fontSize: '18px',
    color: '#284AA9',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  logo: {
    position: 'absolute',
    left: '50%',
    bottom: '2rem',
    zIndex: '-1',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('xs')]: {
      bottom: '-2.5rem',
      height: '2rem',
    },
  },
  averageCycleTime: {
    fontSize: '18px',
    textAlign: 'end',
    position: 'absolute',
    right: '1rem',
    top: '2.5rem',
    borderBottom: '1px solid #284AA9',
    zIndex: '1',
    lineHeight: '1rem',
    color: '#284AA9',
    fontWeight: '600',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
}));

/**
 *
 * @param {object} props
 * @param {Number} props.cycleTimeRating
 * @param {String} props.averageCycleTime
 */
const PieCustomChart = (props) => {
  const classes = useStyles();
  const {
    cycleTimeRating = 76,
    averageCycleTime = '5.24',
    title,
    tooltipTitle,
    tooltipChart,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const RADIAN = Math.PI / 180;
  const data = [
    { name: 'Low', value: 20, color: '#e72c4b' },
    { name: 'Poor', value: 20, color: '#efa87d' },
    { name: 'Fair', value: 20, color: '#fcd258' },
    { name: 'Good', value: 20, color: '#a0cb85' },
    { name: 'Great', value: 20, color: '#66a644' },
  ];
  const cx = isMobile ? 60 : 120;
  const cy = isMobile ? 127 : 255;
  const iR = isMobile ? 60 : 90;
  const oR = isMobile ? 120 : 170;

  const needle = (value, data, cx, cy, iR, oR, color) => {
    let total = 0;
    data.forEach((v) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return (
      <>
        <circle cx={x0} cy={y0} r={r} fill={color} stroke='none' />
        <path
          d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
          stroke='#none'
          fill={color}
        />
      </>
    );
  };

  return (
    <div className={classes.cycleChart}>
      <div className={classes.averageCycle}>
        <Tooltip title={tooltipTitle}>
          <span>{title}</span>
        </Tooltip>
      </div>
      <span className={classes.averageCycleTime}>{averageCycleTime}</span>
      <Tooltip title={tooltipChart}>
        <div className={classes.chart}>
          <PieChart width={isMobile ? 140 : 250} height={isMobile ? 100 : 290}>
            <Pie
              dataKey='value'
              startAngle={180}
              endAngle={0}
              data={data}
              cx={cx}
              cy={cy}
              innerRadius={iR}
              outerRadius={oR}
              fill='#8884d8'
              stroke='none'
              labelLine=''
              label={({ x, y, name }) => {
                if (isMobile) {
                  return null;
                }
                return (
                  <text
                    x={name === 'Fair' ? x - 12 : x}
                    y={y}
                    fill='black'
                    textAnchor={x > cx ? 'start' : 'end'}
                    dominantBaseline='central'
                    style={{ fontWeight: 'bold' }}
                  >
                    {name}
                  </text>
                );
              }}
            >
              {data?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              {isMobile && (
                <LabelList
                  dataKey='name'
                  position='inside'
                  fill='white' // Label text color
                />
              )}
            </Pie>
            {needle(cycleTimeRating, data, cx, cy, iR, oR, '#284aa9')}
          </PieChart>
          <img src={averageCycleIcon} alt='logo' className={classes.logo} />
        </div>
      </Tooltip>
    </div>
  );
};

export default PieCustomChart;
