import React, { useEffect, useState, useReducer } from 'react';
import { isDate } from 'lodash';

import { makeStyles, useTheme } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  useMediaQuery,
} from '@material-ui/core';

import {
  FtrButton,
  FtrTypography,
  FtrTooltip,
} from '../../components/ftr-components';

import { FormGroup } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { FtrCheckboxHeader } from '../../components/ftr-components/table/FtrCheckboxHeader';
import FtrCheckboxRow from '../../components/ftr-components/table/FtrCheckboxRow';
import SingleImage from '../../components/images/SingleImage';
import { FlexRow } from '../../components/layouts/FlexLayouts';

import { isEmptyValue } from '../../utils/commonUtils';
import { isCustomMaterial } from '../../utils/inputUtils';
import {
  getSurfaceFinishWithCustomizationsText,
  is3DPTechnology,
  isPpeItem,
} from '../../utils/itemUtils';

import { ITEM_STATUS_MAPPING } from '../../constants/itemStatus';

import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: colors.neutral020,
    },
  },
  paper: {
    borderRadius: '30px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
    maxWidth: '580px',
  },
  container: {
    width: '100% !important',
    margin: '0 !important',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {},
  },
  summaryItemName: {
    width: '100%',
    fontSize: '14px',
    display: 'block',
    fontWeight: 600,
    lineHeight: '19.07px',
    paddingLeft: '0.75rem',
    paddingBottom: '5px',
    color: '#565656',
  },
  summaryText: {
    paddingLeft: '0.75rem',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.07px',
    color: colors.neutral060,
    alignItems: 'left',
    justifyContent: 'left',
    wordBreak: 'break-word',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

function DeletePartsPopup(props) {
  const classes = useStyles();

  const {
    open,
    items = [],
    handleClose = () => {},
    onDeleteItems = () => {},
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [localItemsState, updateLocalItemsState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      availableItems: [],
      unavailableItems: [],
    }
  );

  const [allFieldsSelected, setAllFieldsSelected] = useState(false);
  const [selectedParts, setSelectedParts] = useState([]);

  useEffect(() => {
    // separate available and unavailable items
    const { availableItems, unavailableItems } =
      items?.reduce(
        (acc, item) => {
          if (
            [
              ITEM_STATUS_MAPPING.QUOTES_AVAILABLE,
              ITEM_STATUS_MAPPING.SOURCING,
            ].includes(item.status) ||
            (item.status === ITEM_STATUS_MAPPING.VERIFYING && !isPpeItem(item))
          ) {
            acc.availableItems.push(item);
          } else {
            acc.unavailableItems.push(item);
          }
          return acc;
        },
        {
          availableItems: [],
          unavailableItems: [],
        }
      ) || {};
    updateLocalItemsState({ availableItems, unavailableItems });
  }, [items]);

  const handleAllFieldsCheck = () => {
    const newValue = !allFieldsSelected;
    setAllFieldsSelected(newValue);
    if (newValue) {
      setSelectedParts(localItemsState.availableItems);
    } else {
      setSelectedParts([]);
    }
  };

  const handleItemCheck = (item) => {
    let newSelectedParts;

    if (selectedParts?.find((part) => part.itemID === item.itemID)) {
      newSelectedParts = [
        ...(selectedParts?.filter((part) => part.itemID !== item.itemID) || []),
      ];
    } else {
      newSelectedParts = [
        ...(selectedParts?.filter((part) => part.itemID !== item.itemID) || []),
        item,
      ];
    }

    setSelectedParts(newSelectedParts);
    setAllFieldsSelected(
      newSelectedParts.length === localItemsState.availableItems.length &&
        localItemsState.availableItems.length > 0
    );
  };

  const render2DImage = (item) => {
    const imageUrl = item.imageFile || item.twoDImageUrl;
    return <SingleImage url={imageUrl} width={70} height={70} noBorder />;
  };

  const renderItemInfo = (item) => {
    return (
      <Grid
        container
        justifyContent='flex-start'
        alignItems='flex-start'
        border='2px solid grey'
        style={{ display: 'flex', flexWrap: 'wrap' }}
      >
        <div className={classes.summaryItemName}>{item.name}</div>
        <Grid item xs={12} sm={5} md='auto' className={classes.summaryText}>
          {`${item.technology}`}
          {is3DPTechnology(item.technology) && `, ${item.threeDTechnology}`}
          {`, ${item.material}`}
          {isCustomMaterial(item.material) && `, ${item.otherMaterial}`}
          {!isEmptyValue(item.materialColor) && `, ${item.materialColor}`}
          {is3DPTechnology(item.technology) &&
            !isEmptyValue(item.threeDInfill) &&
            `, ${Number(item.threeDInfill * 100).toFixed(0)}%`}
          {is3DPTechnology(item.technology) &&
            !isEmptyValue(item.threeDLayerThickness) &&
            `, ${item.threeDLayerThickness.toFixed(2)}mm`}
          {getSurfaceFinishWithCustomizationsText(item) !== 'NIL' &&
            `, ${getSurfaceFinishWithCustomizationsText(item)}`}
          {`, Qty: ${item.quantity}`}
          {`, +/-${item.tolerance}mm`}
          {!isEmptyValue(item.remarks) && `, ${item.remarks}`}
          {`, ${
            isDate(item.expectedLeadTime)
              ? item.expectedLeadTime.toDateString()
              : 'No Preference'
          }`}
          {!isEmptyValue(item.partApplication) && `, ${item.partApplication}`}
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      scroll='paper'
      width='md'
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle id='confirmation-dialog-title' style={{ marginTop: '1rem' }}>
        <FtrTypography type='heading' fontSize='24'>
          Delete Parts
        </FtrTypography>
      </DialogTitle>
      <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      <div
        style={{
          paddingBottom: '1rem',
          overflow: 'visible',
        }}
      >
        <DialogContent>
          <FtrTypography
            type='body'
            fontSize='16'
            style={{
              marginBottom: '1.5rem',
            }}
          >
            Select parts to delete from the project. The action cannot be
            undone. Alternatively, you can move selected parts to the Parts
            Library for future reference.
          </FtrTypography>
          <Grid
            container
            style={{
              borderRadius: '20px',
              marginBottom: isMobile ? '1.9rem' : '1.5rem',
            }}
          >
            <FtrCheckboxHeader
              checked={allFieldsSelected}
              onChange={handleAllFieldsCheck}
              title='Select Parts'
              disabled={localItemsState.availableItems.length === 0}
            />
            <FormGroup>
              {localItemsState.availableItems?.map((item, index) => {
                const isLastItem = index === items.length - 1;
                const checked = selectedParts?.find(
                  (part) => part.itemID === item.itemID
                );
                const key = `${item.itemID}-${checked}`;

                return (
                  <FtrCheckboxRow
                    key={key}
                    onChange={() => handleItemCheck(item)}
                    checked={checked}
                    isLastItem={isLastItem}
                  >
                    <FlexRow style={{ width: '100%', padding: '0.5rem 0' }}>
                      {render2DImage(item)}
                      {renderItemInfo(item)}
                    </FlexRow>
                  </FtrCheckboxRow>
                );
              })}
              {localItemsState.unavailableItems?.map((item, index) => {
                const isLastItem =
                  index === localItemsState.unavailableItems.length - 1;
                const checked = selectedParts.includes(item.itemID);
                const key = `${item.itemID}-${checked}`;

                return (
                  <FtrTooltip
                    key={key}
                    description='This item cannot be moved as it is in a different stage.'
                  >
                    <div>
                      <FtrCheckboxRow
                        onChange={() => handleItemCheck(item.itemID)}
                        isLastItem={isLastItem}
                        disabled
                      >
                        <FlexRow style={{ width: '100%', padding: '0.5rem 0' }}>
                          {render2DImage(item)}
                          {renderItemInfo(item)}
                        </FlexRow>
                      </FtrCheckboxRow>
                    </div>
                  </FtrTooltip>
                );
              })}
            </FormGroup>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            paddingLeft: '24px',
            paddingRight: '24px',
          }}
        >
          <FtrButton
            color='blue'
            onClick={() => onDeleteItems(selectedParts)}
            disabled={isEmptyValue(selectedParts)}
          >
            Proceed
          </FtrButton>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default DeletePartsPopup;
