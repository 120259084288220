import { CsvBuilder } from 'filefy';
import { ceil } from 'lodash';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import HorizontalExpandSpace from '../../components/ftr-components/HorizontalExpandSpace';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import GridDataPagination from '../../components/grid-data/GridDataPagination';
import LoadingBackDropText from '../../components/LoadingBackDropText';
import CollectedInfoPopup from '../../components/popups/CollectedInfoPopup';
import AddEditCustomerAddressPopup from '../../components/popups/confirm-order-popup/AddEditCustomerAddressPopup';
import ConfirmEditMultipleCheckoutPopup from '../../components/popups/ConfirmEditMultipleCheckoutPopup';
import InvoiceInfoPopup from '../../components/popups/InvoiceInfoPopup';
import MultiCheckoutDeliverPopup from '../../components/popups/MultiCheckoutDeliverPopup';
import MultiCheckoutDeliveryOrderFormsPopup from '../../components/popups/MultiCheckoutDeliveryOrderFormsPopup';
import MultiCheckoutInfoPopup from '../../components/popups/MultiCheckoutInfoPopup';
import NotePopup from '../../components/popups/NotePopup';
import ShipItemsPopup from '../../components/popups/ShipItemsPopup';

import withEditCheckoutTypePopupHoC from './withUpdateCheckoutTypePopupHoC';

import {
  getAllTrackings,
  invoiceItems,
  reGenerateCombinedQuoteForMultiCheckout,
  shipItems,
  updateMultipleCheckout,
} from '../../apis/multiCheckoutApi';

import { useDataGridFilterHook } from '../../hooks/useDataGridFilterHook';

import { generateCurrentCustomDateTimeString } from '../../util';
import { isEmptyValue } from '../../utils/commonUtils';
import { transformCsvExportDataGrid } from '../../utils/csvExportUtils';
import { isSuperAdminRole } from '../../utils/roleUtils';

import { notifySuccess } from '../../services/notificationService';

import { getDefaultMultiCheckoutColumns } from '../../constants/multiCheckoutTrackingTableConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    padding: '1.5rem 0',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      whiteSpace: 'normal',
      lineHeight: 'normal',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-menuIcon > button': {
      padding: 0,
    },
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer',
    },
  },
}));

function MultipleCheckoutTrackingDataGrid(props) {
  const classes = useStyles();

  const {
    role,
    fileName,
    tabFilterFunc,
    updateCheckoutTypePopupHOCState,
    tabKey,
  } = props;

  const {
    data: allTrackings,
    isLoading,
    isFetching,
    refetch,
  } = useQuery('getAllTracking', () => getAllTrackings());

  const tabFilteredData = useMemo(() => {
    return isEmptyValue(allTrackings)
      ? []
      : tabFilterFunc
        ? tabFilterFunc(allTrackings)
        : allTrackings;
  }, [allTrackings, tabFilterFunc]);

  const [columns, setColumns] = useState([]);
  const [selectedDeliverCheckout, setSelectedDeliverCheckout] = useState(null);
  const [showDeliverPopup, setShowDeliverPopup] = useState(false);
  const [checkoutInfoPopup, updateCheckoutInfoPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      selectedData: {},
    }
  );
  const [deliveryOrderFormsPopup, updateDeliveryOrderFormsPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      selectedData: {},
    }
  );
  const [invoiceInfoPopup, updateInvoiceInfoPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      selectedData: {},
    }
  );
  const [collectedInfoPopup, updateCollectedInfoPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      selectedData: {},
    }
  );
  const [shipItemPopup, updateShipItemPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      selectedData: {},
    }
  );
  const [financeNotePopup, updateFinanceNotePopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      selectedData: null,
      open: false,
    }
  );
  const [addressPopup, updateAddressPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      selectedData: null,
      open: false,
      addressType: null,
      loading: false,
    }
  );
  const [confirmEditPopup, updateConfirmEditPopup] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      selectedData: {},
      body: {},
    }
  );
  const [backdropState, updateBackdropState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open: false,
      title: '',
    }
  );
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );
  const [
    filteredData,
    { filtering, setSearchStr, setColumnsDef, setSourceData },
  ] = useDataGridFilterHook({
    search: '',
  });

  const [searchAutoFocus, setSearchAutoFocus] = useState(false);

  const handleSubmitInvoiceItems = (params) => {
    const body = {
      checkoutID: invoiceInfoPopup?.selectedData?.id,
      ...params,
    };
    invoiceItems(body).then(refetch);
  };

  const handleSubmitShipItems = (params) => {
    const body = {
      checkoutID: shipItemPopup?.selectedData?.id,
      ...params,
    };
    shipItems(body).then(refetch);
  };

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleUpdateNote = (newFinanceNote) => {
    updateMultipleCheckout(financeNotePopup.selectedData?.id, {
      financeNote: newFinanceNote,
    }).then(() => {
      notifySuccess(
        `Note for Finance for Checkout ${financeNotePopup.selectedData?.referenceName} has been updated successfully.`
      );
      updateFinanceNotePopup({ open: false });
      refetch();
    });
  };

  const handleUpdateAddress = (address, addressType) => {
    delete address.defaultBilling;
    delete address.defaultShipping;
    updateConfirmEditPopup({
      body: {
        ...confirmEditPopup.body,
        [addressType === 'billing' ? 'billingAddress' : 'shippingAddress']:
          address,
      },
      open: true,
      selectedData: addressPopup.selectedData,
    });
    updateAddressPopup({ open: false });
  };

  const handleConfirmSubmit = async ({ regenerateQuotationForm }) => {
    await updateMultipleCheckout(
      confirmEditPopup.selectedData?.id,
      confirmEditPopup.body
    );
    notifySuccess(
      `Checkout ${confirmEditPopup.selectedData?.referenceName} has been updated successfully.`
    );
    updateConfirmEditPopup({ open: false });

    // Regenerate Quotation Form
    if (regenerateQuotationForm) {
      updateBackdropState({
        open: true,
        title: 'Generating Quotation Form PDF...',
      });
      await reGenerateCombinedQuoteForMultiCheckout({
        multiCheckoutID: confirmEditPopup.selectedData?.id,
        updateDB: true,
      }).finally(() => {
        updateBackdropState({ open: false });
      });
      notifySuccess(`Quotation form has been updated successfully`);
    }

    refetch();
  };

  useEffect(() => {
    if (isEmptyValue(role)) {
      return;
    }
    refetch();
    const _columns = getDefaultMultiCheckoutColumns({
      updateCheckoutInfoPopup,
      updateDeliveryOrderFormsPopup,
      updateInvoiceInfoPopup,
      updateCollectedInfoPopup,
      updateShipItemPopup,
      updateFinanceNotePopup,
      updateAddressPopup,
      updateBackdropState,
      updateCheckoutTypePopupHOCState,
      setSelectedDeliverCheckout,
      setShowDeliverPopup,
      refetch,
      role,
      classes,
      tabKey,
    });
    if (!isEmptyValue(_columns)) {
      setColumns(_columns);
    }
  }, [role]);

  useEffect(() => {
    setColumnsDef(columns);
  }, [columns]);

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData?.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(tabFilteredData);
  }, [tabFilteredData]);

  const handleDownloadCsv = () => {
    const _fileName = `${fileName} ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredData
    );

    const builder = new CsvBuilder(_fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  const getCustomerToolbar = () => {
    const searchBar = (
      <SearchBar
        key='search'
        onSearch={handleSearch}
        searchTerm={tableQueryParams.search}
        autoFocus={searchAutoFocus}
        onFocus={() => {
          setSearchAutoFocus(true);
        }}
        onBlur={() => {
          setSearchAutoFocus(false);
        }}
      />
    );

    const exportButton = (
      <ExportCsvButton
        key='export-csv'
        handleClick={handleDownloadCsv}
        show={isSuperAdminRole(role)}
      />
    );

    const buttons = [searchBar];

    if (isSuperAdminRole(role)) {
      buttons.push(exportButton);
    }

    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar buttons={buttons} />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.body}>
      <DataGrid
        autoHeight
        rows={filteredData ?? []}
        columns={columns?.map((col) => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.id}
        rowHeight={120}
        headerHeight={120}
        components={{
          Toolbar: getCustomerToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        rowsPerPageOptions={[10, 20, 50]}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        disableSelectionOnClick
        disableColumnMenu
        loading={
          tableQueryParams.loading || isLoading || isFetching || filtering
        }
      />
      {showDeliverPopup && (
        <MultiCheckoutDeliverPopup
          open={showDeliverPopup}
          handleCancel={() => setShowDeliverPopup(false)}
          checkoutID={selectedDeliverCheckout.id}
          setShowDeliverPopup={setShowDeliverPopup}
          dataRefetchFunc={refetch}
          acceptedItems={selectedDeliverCheckout.acceptedItems}
          initialEstimateDeliveryDate={
            selectedDeliverCheckout.estimateDeliveryDate
          }
        />
      )}
      {checkoutInfoPopup.open && (
        <MultiCheckoutInfoPopup
          open={checkoutInfoPopup.open}
          handleClose={() => updateCheckoutInfoPopup({ open: false })}
          rowData={checkoutInfoPopup.selectedData}
        />
      )}
      {deliveryOrderFormsPopup.open && (
        <MultiCheckoutDeliveryOrderFormsPopup
          open={deliveryOrderFormsPopup.open}
          handleClose={() => updateDeliveryOrderFormsPopup({ open: false })}
          rowData={deliveryOrderFormsPopup.selectedData}
        />
      )}
      {invoiceInfoPopup.open && (
        <InvoiceInfoPopup
          open={invoiceInfoPopup.open}
          handleClose={() => updateInvoiceInfoPopup({ open: false })}
          rowData={invoiceInfoPopup.selectedData}
          handleSubmit={handleSubmitInvoiceItems}
        />
      )}
      {collectedInfoPopup.open && (
        <CollectedInfoPopup
          open={collectedInfoPopup.open}
          handleClose={() => updateCollectedInfoPopup({ open: false })}
          rowData={collectedInfoPopup.selectedData}
          dataRefetchFunc={refetch}
        />
      )}
      {shipItemPopup.open && (
        <ShipItemsPopup
          open={shipItemPopup.open}
          handleCancel={() => updateShipItemPopup({ open: false })}
          rowData={shipItemPopup.selectedData}
          handleOk={handleSubmitShipItems}
        />
      )}
      {backdropState.open && (
        <LoadingBackDropText
          open={backdropState.open}
          text={backdropState.title}
        />
      )}
      {financeNotePopup.open && (
        <NotePopup
          title={`Note for Finance for Checkout ${financeNotePopup.selectedData?.referenceName}`}
          key={financeNotePopup.selectedData?.id}
          open={financeNotePopup.open}
          value={financeNotePopup.selectedData?.financeNote}
          onClose={() => updateFinanceNotePopup({ open: false })}
          updateHandler={handleUpdateNote}
          label='Note for Finance'
        />
      )}
      {addressPopup.open && (
        <AddEditCustomerAddressPopup
          open={addressPopup.open}
          userID={addressPopup.userID}
          type={addressPopup.addressType}
          mode='edit'
          editAddressObj={
            addressPopup.selectedData[
              addressPopup.addressType === 'billing'
                ? 'billingAddress'
                : 'shippingAddress'
            ]
          }
          handleClose={() => updateAddressPopup({ open: false })}
          customUpdateDeliveryInfo={handleUpdateAddress}
          isLoading={addressPopup.loading}
          showDefaultAddressCheckbox={false}
        />
      )}
      {confirmEditPopup.open && (
        <ConfirmEditMultipleCheckoutPopup
          key={confirmEditPopup.selectedData?.id}
          open={confirmEditPopup.open}
          onClose={() => updateConfirmEditPopup({ open: false })}
          updateHandler={handleConfirmSubmit}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth.user.role,
  };
}

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
  withEditCheckoutTypePopupHoC
)(MultipleCheckoutTrackingDataGrid);
