import { Decimal } from 'decimal.js';

import { isEmptyValue } from './commonUtils';

export const countDecimalsUtil = (value) => {
  let text = value.toString();
  // verify if number 0.000005 is represented as "5e-6"
  if (text.indexOf('e-') > -1) {
    // eslint-disable-next-line no-unused-vars
    let [base, trail] = text?.split('e-') || [];
    let deg = parseInt(trail, 10);
    return deg;
  }
  // count decimals for number in representation like "0.123456"
  if (Math.floor(value) !== value) {
    const valueDec = value.toString()?.split('.')[1];
    return valueDec ? valueDec.length : 0;
  }
  return 0;
};

/**
 *
 * @param {number} n
 * @returns ex: "1234" - Four digit value
 */
export const fourDigitUtil = (n) => {
  let s = '000' + n;
  return s.substring(s.length - 4);
};

/**
 *
 * @param {Number|String} value number
 * @param {Boolean} isToFixed2 when count Decimals < 2, default true
 * @returns
 * - "0.12345678" -> "0.12345"
 * - "0.1" -> "0.10"
 */
export const handleDigitDecimalUtil = (value, isToFixed2 = true) => {
  if (isToFixed2 === true && countDecimalsUtil(value) < 2) {
    return Number(value).toFixed(2);
  }
  if (countDecimalsUtil(value) >= 5) {
    return Number(value).toFixed(5);
  }
  return value;
};

/**
 * eg: num=0.999998765, places =2
 * return 0.99
 * @param {*} num
 * @param {*} places
 * @returns
 */
export const truncateDecimals = (num, places = 2) => {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
};

/**
 * toFixed(3), but if the value is have bigger decimal points, then we display more decimal points
 * @param {number|string} value
 */
export const toFixed3Or4 = (value) => {
  let result = Number(value).toFixed(3);
  if (Number(value) < 0.001) {
    result = Number(value).toFixed(4);
  }

  return result;
};

/**
 * toFixed(4), but if the value is have bigger decimal points, then we display more decimal points
 * @param {number|string} value
 */
export const toFixed4Or5 = (value) => {
  let result = Number(value).toFixed(4);
  if (Number(value) < 0.0001) {
    result = Number(value).toFixed(5);
  }

  return result;
};

/**
 *
 * @param {String|Number} value - number value
 * @param {Object} params - options
 * @param {Number} params.numToFixed - default 2
 * @param {String} params.defaultValue - default 'N.A.'
 * @param {String} params.startWith - default empty
 * @param {String} params.endWith - default empty
 * @returns
 */
export const toFixedWithDefault = (value, params) => {
  const {
    numToFixed = 2,
    defaultValue = 'N.A.',
    startWith = '',
    endWith = '',
  } = params || {};
  return !isNaN(+value)
    ? `${startWith}${Number(value).toFixed(numToFixed)}${endWith}`
    : defaultValue;
};

/**
 * Check if the input value is a number (or can be converted to a number)
 * @example
 * null -> false
 * undefined -> false
 * '' -> false
 * '   ' -> false
 * 'abcd1234' -> false
 * '1234' -> true
 * 1234 -> true
 * @param {*} value
 * @returns
 */
export const isNumber = (value) => {
  if (isEmptyValue(value)) {
    return false;
  }
  return !isNaN(Number(value));
};

/**
 * Finds the nearest number that is still smaller than the targetNumber
 * @param {Number} targetNumber
 * @param {Array} numberArray
 * @returns {Number} closest number
 */

export const findNearestSmallerNumber = (targetNumber, numberArray) => {
  // sort in descending order
  numberArray.sort((a, b) => a - b);

  if (isEmptyValue(numberArray)) {
    return targetNumber;
  }

  let closest = numberArray[0];
  for (let num of numberArray) {
    if (num <= targetNumber) {
      closest = num;
    } else {
      break;
    }
  }

  return closest;
};

export const reciprocal = (number) => {
  if (number === 0) {
    throw new Error('Division by zero is not allowed.');
  }
  return 1 / number;
};

/**
 * Checks if the difference between two numbers is within a specified tolerance.
 *
 * @param {number} number1 - The first number to compare.
 * @param {number} number2 - The second number to compare.
 * @param {number} [tolerance=0.02] - The maximum allowed difference between the two numbers.
 * @return {boolean} True if the difference is within the tolerance, false otherwise.
 */
export const inToleranceNumber = (number1, number2, tolerance = 0.02) => {
  return Math.abs(number1 - number2) <= tolerance;
};

export const getDecimal = (num) => {
  const number = Number(num);

  return new Decimal(number);
};
