import React from 'react';
import { Link, Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import FilesUploadButton from '../../components/FilesUploadButton';
import { FtrTypography } from '../../components/ftr-components';
import SupplierInvoiceDisplay from '../../components/SupplierInvoiceDisplay';

import {
  downloadS3File,
  removeFirstSlashIfExists,
} from '../../utils/fileUtils';
import { getPurchaseOrderID } from '../../utils/quotationUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { isAdminOrHigherRole } from '../../utils/roleUtils';
import { fourDigitUtil } from '../../utils/numberUtils';
import { convertToDigits } from '../../utils/stringUtils';

import { getUserSelector } from '../../selectors/userSelector';

import {
  createS3KeyForSupplierInvoiceFile,
  deleteFileFromS3,
  uploadFileToS3,
} from '../../services/s3Service';
import { notifyError } from '../../services/notificationService';

import {
  deleteSupplierInvoice,
  uploadSupplierInvoice,
} from '../../apis/quotationApi';

const isLegacyInvoice = (supplierInvoiceUrl, supplierInvoicesInfo) => {
  return !supplierInvoicesInfo?.some(
    (supplierInvoiceInfo) =>
      supplierInvoiceInfo.supplierInvoiceUrl === supplierInvoiceUrl
  );
};

const ManageSupplierInvoice = ({
  supplierID,
  projectID,
  poAcknowledgedVersion,
  refreshFunc,
  classes,
  itemOrderReadyList,
  supplierInvoicesInfo,
  invoiceSentToHubdoc,
  isOrderDelivered = false,
}) => {
  const { role: userRole } = useSelector(getUserSelector);

  const isUserAdminOrHigher = isAdminOrHigherRole(userRole);

  if (isEmptyValue(itemOrderReadyList)) {
    return null;
  }
  const { supplierInvoiceUrl, combinedPOsForm, poAmount } =
    itemOrderReadyList[0]?.info || {};
  const hasUploadedAtLeastOneInvoice = !isEmptyValue(supplierInvoiceUrl);
  const supplierInvoiceInfoSorted = [...supplierInvoicesInfo].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  const mostRecentInvoiceInfo = isEmptyValue(supplierInvoicesInfo)
    ? {}
    : supplierInvoiceInfoSorted[0];
  const { createdAt } = mostRecentInvoiceInfo;
  // check the value of invoiceSentToHubdoc for new invoices or
  // if the invoice is legacy invoice without invoice extraction API calls, mark as sent to Hubdoc by default
  const isInvoiceLegacyInvoice = isLegacyInvoice(
    supplierInvoiceUrl,
    supplierInvoicesInfo
  );
  const isAnyInvoiceSentToHubdoc =
    hasUploadedAtLeastOneInvoice &&
    (!isEmptyValue(invoiceSentToHubdoc) || isInvoiceLegacyInvoice);
  const isInvoiceExtractionRunning =
    !isEmptyValue(createdAt) && Date.now() - new Date(createdAt) < 10000;

  // code below needed to handle legacy issue where the most recent invoice is stored in the quotations table and supplierInvoiceInfo is not populated
  // so that all invoice urls have the same structure as a supplierInvoiceInfo object
  if (isEmptyValue(supplierInvoiceInfoSorted)) {
    supplierInvoiceInfoSorted.push({
      supplierInvoiceUrl: itemOrderReadyList[0]?.info?.supplierInvoiceUrl,
      apiResponse: '',
      responseError: '',
      isInvoiceReconciled: false,
      invoiceExtractionApiCallID: null,
      createdAt: null,
    });
  }
  const mostRecentSupplierInvoiceInfo = supplierInvoiceInfoSorted?.slice(0, 1);
  const supplierInvoicesInfoByUserRole = !isUserAdminOrHigher
    ? mostRecentSupplierInvoiceInfo
    : supplierInvoiceInfoSorted;

  const handleUploadSupplierInvoice = async (file) => {
    const quotationIDList = itemOrderReadyList?.map(
      (item) => item.info.quotationID
    );
    const toastId = toast('Uploading invoice...', {
      type: toast.TYPE.INFO,
      autoClose: true,
    });
    const combinedPoNumber =
      fourDigitUtil(supplierID) +
      fourDigitUtil(projectID) +
      convertToDigits(poAcknowledgedVersion, 2);
    const fileExtension = file.name?.split('.').pop();
    try {
      if (fileExtension?.toLowerCase() !== 'pdf') {
        throw new Error('File format must be in PDF');
      }
      const data = await uploadFileToS3(
        file,
        createS3KeyForSupplierInvoiceFile(combinedPoNumber)
      );
      const { s3ObjectUrl } = data || {};
      const body = {
        newSupplierInvoiceUrl: s3ObjectUrl,
        quotationIDList,
      };
      await uploadSupplierInvoice(body);
      toast.update(toastId, {
        render: `Invoice uploaded successfully and relabelled to Invoice_${combinedPoNumber}!`,
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      refreshFunc();
      // Set a timeout to load the supplier invoice info again, as the call to the invoice extraction service
      // is made asynchronously
      setTimeout(() => {
        refreshFunc();
      }, 10000);
    } catch (err) {
      refreshFunc();
      notifyError(err.message);
    }
  };

  const handleDeleteSupplierInvoice = async (invoiceToDelete) => {
    if (!isUserAdminOrHigher) {
      notifyError('You do not have admin rights to delete the invoice.');
      return;
    }
    const quotationIDList = itemOrderReadyList?.map(
      (item) => item.info.quotationID
    );
    try {
      const toastId = toast('Deleting...', {
        type: toast.TYPE.INFO,
        autoClose: true,
      });
      const { pathname } = new URL(invoiceToDelete);
      await deleteFileFromS3(removeFirstSlashIfExists(pathname));
      await deleteSupplierInvoice({ quotationIDList, invoiceToDelete });
      toast.update(toastId, {
        render: 'Invoice deleted successfully!',
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      refreshFunc();
    } catch (err) {
      notifyError('Error deleting Invoice');
    }
  };

  const renderUploadSupplierInvoiceButton = () => {
    return (
      !isAnyInvoiceSentToHubdoc && (
        <div
          style={{
            marginTop: '1rem',
            marginBottom: '1rem',
            width: 'fit-content',
          }}
        >
          <FilesUploadButton
            id='upload-supplier-invoice'
            buttonText={
              hasUploadedAtLeastOneInvoice
                ? isUserAdminOrHigher
                  ? 'Add A New Invoice'
                  : 'Replace Invoice'
                : 'Upload Invoice'
            }
            handleUploadFiles={(files) => handleUploadSupplierInvoice(files[0])}
            multiple={false}
            note={'Please upload it in PDF format.'}
          />
        </div>
      )
    );
  };

  const renderSupplierInvoiceList = () => {
    const isModifiable = !isAnyInvoiceSentToHubdoc && isUserAdminOrHigher;
    return (
      hasUploadedAtLeastOneInvoice &&
      supplierInvoicesInfoByUserRole?.map((supplierInvoiceInfo) => (
        <SupplierInvoiceDisplay
          key={supplierInvoiceInfo.supplierInvoiceUrl}
          supplierInvoiceInfo={supplierInvoiceInfo}
          handleDeleteSupplierInvoice={handleDeleteSupplierInvoice}
          showDeleteButton={isModifiable}
          isLoading={isInvoiceExtractionRunning}
          isInvoiceSentToHubdoc={
            isInvoiceLegacyInvoice ||
            supplierInvoiceInfo.supplierInvoiceInfoID === invoiceSentToHubdoc
          }
          isAnyInvoiceSentToHubdoc={isAnyInvoiceSentToHubdoc}
          allowSendToHubdoc={!isInvoiceLegacyInvoice && isModifiable} // legacy invoices were automatically sent to Hubdoc upon upload, so should be disallowed for this
          refreshFunc={refreshFunc}
          isOrderDelivered={isOrderDelivered}
        />
      ))
    );
  };

  const renderBasedOnHubdocStatus = () => {
    return isAnyInvoiceSentToHubdoc ? (
      <FtrTypography>
        Your invoice has been successfully reconciled with our records and has
        been processed for payment. In case you need to make further
        modifications, please contact us.
      </FtrTypography>
    ) : (
      <FtrTypography>
        There may be an issue with your invoice. Please ensure that your invoice
        value matches the Factorem PO. If the invoice value is correct, you will
        receive confirmation within one working day.
      </FtrTypography>
    );
  };

  const renderBasedOnOrderDeliveredStatus = () => {
    if (isOrderDelivered) {
      return renderBasedOnHubdocStatus();
    }
    return mostRecentInvoiceInfo.isInvoiceReconciled ? (
      <FtrTypography>
        Your invoice has been successfully reconciled with our records.
      </FtrTypography>
    ) : (
      <FtrTypography>
        There may be an issue with your invoice. Please ensure that your invoice
        value matches the Factorem PO.
      </FtrTypography>
    );
  };

  const renderInvoiceUploadWarningHeading = () => {
    return (
      <FtrTypography>This order is pending successful delivery.</FtrTypography>
    );
  };

  const renderExtractingInvoice = () => {
    return (
      <FtrTypography>
        We are processing your invoice and reconciling with our records. Please
        wait for a short while.
      </FtrTypography>
    );
  };

  const renderUploadInvoiceHeading = () => {
    return (
      <FtrTypography>
        This project has been delivered. Please upload your invoice here.
      </FtrTypography>
    );
  };

  const renderSupplierInvoiceHeading = () => {
    if (hasUploadedAtLeastOneInvoice) {
      return isInvoiceExtractionRunning
        ? renderExtractingInvoice()
        : renderBasedOnOrderDeliveredStatus();
    } else if (isOrderDelivered) {
      return renderUploadInvoiceHeading();
    } else {
      return renderInvoiceUploadWarningHeading();
    }
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <FtrTypography className={classes.heading}>
        Invoice for PO #
        <Link
          onClick={() => downloadS3File(combinedPOsForm)}
          style={{ cursor: 'pointer' }}
        >
          {getPurchaseOrderID(combinedPOsForm)}
          {poAmount && (
            <Tooltip title='PO value' arrow>
              <span> ({poAmount})</span>
            </Tooltip>
          )}
        </Link>
      </FtrTypography>
      {renderSupplierInvoiceHeading()}
      {renderSupplierInvoiceList()}
      {renderUploadSupplierInvoiceButton()}
      {!isOrderDelivered && (
        <FtrTypography style={{ marginTop: '1rem' }}>
          Do note that we will only process your invoice once all items have
          been delivered.
        </FtrTypography>
      )}
    </div>
  );
};

export default ManageSupplierInvoice;
