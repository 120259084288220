import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { makeStyles } from '@material-ui/core/styles/index';

import { Badge, Tab, Tabs, Tooltip } from '@material-ui/core';

import { PageTitle } from '../../components/PageTitle';
import { FlexColumn } from '../../components/layouts/FlexLayouts';
import AllOrderReadyManagementDataGrid from '../AllOrderReadyManagementDataGrid';
import AllItemBalloonPdfFilesDataGrid from './AllItemBalloonPdfFilesDataGrid';

import { getAutoBalloonItems } from '../../apis/autoBalloonApi';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    padding: '1rem',
    paddingTop: 0,
    boxSizing: 'border-box',
  },
  tabRoot: {
    opacity: 1,
    '&:hover': {
      color: 'white',
      backgroundColor: colors.blue050,
    },
  },
  selectedTab: {
    opacity: 1,
    '&:hover': {
      backgroundColor: colors.secondaryBlue,
      color: colors.blue050,
    },
    backgroundColor: colors.secondaryBlue,
    color: colors.blue050,
  },
}));

const TAB_KEYS = {
  ALL_ORDERS_READY: 'All Project Order Ready',
  ALL_ITEM_BALLOONED_PDF: 'Verify Ballooned PDF',
};

const TABS = [TAB_KEYS.ALL_ORDERS_READY, TAB_KEYS.ALL_ITEM_BALLOONED_PDF];

function OrderReadyManagement() {
  const classes = useStyles();

  const { data: allPendingBalloonItems = [] } = useQuery(
    'getPendingAutoBalloonItems',
    () => getAutoBalloonItems({ status: 'pending' })
  );

  const [viewableTabs] = useState(TABS);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <PageTitle title='Order Ready Management' />
      <FlexColumn className={classes.body} style={{ gap: '1rem' }}>
        {renderTabBar()}
        {renderTab()}
      </FlexColumn>
    </>
  );

  function renderTabBar() {
    if (viewableTabs.length <= 1) {
      return null;
    }

    return (
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
      >
        {viewableTabs?.map((tab) => {
          if (tab === TAB_KEYS.ALL_ITEM_BALLOONED_PDF) {
            const noOfPending = allPendingBalloonItems?.length || 0;

            return (
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.selectedTab,
                }}
                key={tab}
                label={
                  <Tooltip title={`Pending approval: ${noOfPending}`}>
                    <Badge badgeContent={noOfPending} color='error'>
                      {tab}
                    </Badge>
                  </Tooltip>
                }
              />
            );
          }
          return (
            <Tab
              classes={{
                root: classes.tabRoot,
                selected: classes.selectedTab,
              }}
              key={tab}
              label={tab}
            />
          );
        })}
      </Tabs>
    );
  }

  function renderTab() {
    const tabName = viewableTabs[selectedTab];

    switch (tabName) {
      case TAB_KEYS.ALL_ORDERS_READY:
        return <AllOrderReadyManagementDataGrid />;
      case TAB_KEYS.ALL_ITEM_BALLOONED_PDF:
        return <AllItemBalloonPdfFilesDataGrid />;
      default:
        return null;
    }
  }
}

export default OrderReadyManagement;
