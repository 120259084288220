import React, { useEffect, useReducer, useState } from 'react';
import { ceil, get } from 'lodash';
import { connect } from 'react-redux';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import { Delete as DeleteIcon } from '@material-ui/icons';

import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';

import { makeStyles } from '@material-ui/core/styles/index';

import { PageTitle } from '../components/PageTitle';
import HorizontalExpandSpace from '../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../components/grid-data/CustomToolbar';
import GridDataPagination from '../components/grid-data/GridDataPagination';
import SearchBar from '../components/grid-data/buttons/SearchBar';
import ImageWith3DViewer from '../components/images/ImageWith3DViewer';
import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';

import { deleteSupplierOrder, getAllMyOrders } from '../actions';

import { useDataGridFilterHook } from '../hooks/useDataGridFilterHook';

import { generateCurrentCustomDateTimeString } from '../util';
import { isEmptyValue } from '../utils/commonUtils';
import { convertPriceToCurrency } from '../utils/currencyUtils';
import { getCsvExportColumns } from '../utils/dataGridUtils';
import {
  getQuotationExchangeRate,
  getSupplierQuoteID,
  reasonsTextUtil,
} from '../utils/quotationUtils';

import {
  materialWithColorCol,
  surfaceFinishingWithColorCol,
} from '../constants/itemTableConstants';
import {
  projectIDCol,
  quotationStatusColumn,
  supplierPriceStrDisplayCol,
} from '../constants/quotationTableConstants';

import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';

import { colors } from '../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  body: {
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      lineHeight: 'normal',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
    },
  },
  linkButton: {
    color: colors.blue050,
    textDecoration: 'underline',
    background: colors.paleBlue,
  },
}));

export function MyOrdersDataGrid(props) {
  const classes = useStyles();

  const {
    user,
    orders,
    onPageLoad = () => {},
    deleteQuote,
    currency,
    exchangeRate,
    isMyordersLoading,
  } = props;

  const techTags = user?.techTags;
  const is3DPrintingSupplier =
    techTags &&
    techTags.includes(TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING.toLowerCase());

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [quoteToDelete, setQuoteToDelete] = useState(null);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [filteredData, { setSearchStr, setColumnsDef, setSourceData }] =
    useDataGridFilterHook({
      search: '',
      source: orders,
    });

  useEffect(() => {
    setSearchStr(tableQueryParams.search);
  }, [tableQueryParams.search]);

  useEffect(() => {
    updateTableQueryParams({ totalCount: filteredData.length });
  }, [filteredData]);

  useEffect(() => {
    setSourceData(orders);
  }, [orders]);

  const handleClose = () => {
    setOpenDeleteDialog(false);
    setQuoteToDelete(null);
  };

  const confirmDelete = () => {
    if (quoteToDelete != null) {
      deleteQuote(quoteToDelete);
    }
    setQuoteToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleClickOpen = (quoteID) => {
    setOpenDeleteDialog(true);
    setQuoteToDelete(quoteID);
  };

  const defaultColumns = [
    {
      title: 'Quote ID',
      headerName: 'Quote ID',
      field: 'quotationID',
      valueGetter: ({ row }) => {
        return `'${getSupplierQuoteID(user.userID, row.quotationID)}`;
      },
      renderCell: ({ row }) => {
        return getSupplierQuoteID(user.userID, row.quotationID);
      },
    },
    {
      title: 'Date of Quote',
      headerName: 'Date of Quote',
      field: 'dateOfOrder',
      valueGetter: (params) => {
        const rowData = params.row;
        return rowData.dateOfOrder ? rowData.dateOfOrder.substring(0, 10) : '';
      },
      renderCell: (params) => {
        const rowData = params.row;
        return rowData.dateOfOrder ? rowData.dateOfOrder.substring(0, 10) : '';
      },
      width: 125,
    },
    {
      title: 'Image',
      headerName: 'Image',
      field: 'image',
      renderCell: ({ row: rowData }) => {
        const url = rowData.imageFile || rowData.twoDImageUrl;
        return (
          <div key={url} id={rowData.itemID}>
            <ImageWith3DViewer
              key={url}
              itemID={rowData.itemID}
              twoDImageUrl={url}
              cadFile={rowData.cadFile || rowData.originalFiles}
              width={65}
              height={65}
              borderRadius={0}
              noBorder={false}
              textRenderStyle={{ fontSize: 9 }}
            />
          </div>
        );
      },
      width: 87,
    },
    {
      title: 'Part ID',
      headerName: 'Item ID',
      field: 'itemID',
      renderCell: (params) => {
        const rowData = params.row;
        const projectPath = `projects/${rowData.projectID}/quote?technology=${rowData.technology}&viewQuote=true&supplierID=${rowData.userID}`;
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Tooltip title='View part details'>
              <Button
                className={classes.linkButton}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  window.open(projectPath, '_blank');
                }}
              >
                {rowData.itemID}
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      ...projectIDCol,
    },
    {
      title: 'Quantity',
      headerName: 'Qty',
      field: 'quantity',
      width: 65,
      renderCell: ({ row }) => {
        return <DataGridWrapTextCell text={row.quantity?.toLocaleString()} />;
      },
    },
    {
      ...supplierPriceStrDisplayCol({ currency, exchangeRate }),
    },
    {
      ...quotationStatusColumn,
    },
    {
      title: 'Lead time',
      headerName: 'Lead time',
      field: 'leadTime',
      valueGetter: (params) => {
        const rowData = params.row;
        return `${rowData.leadTime} working day${
          +rowData.leadTime > 1 ? 's' : ''
        }`;
      },
      renderCell: (params) => {
        const rowData = params.row;
        return `${rowData.leadTime} working day${
          +rowData.leadTime > 1 ? 's' : ''
        }`;
      },
      width: 150,
    },
    {
      ...materialWithColorCol,
    },
    {
      ...surfaceFinishingWithColorCol,
    },
    is3DPrintingSupplier
      ? {
          title: '3D Printing Technology',
          headerName: '3D Printing Technology',
          field: 'threeDTechnology',
          valueGetter: (params) => {
            const rowData = params.row;
            return get(rowData, 'metadata.threeDTechnology') || '';
          },
          renderCell: (params) => {
            const rowData = params.row;
            return (
              <DataGridWrapTextCell
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
                text={get(rowData, 'metadata.threeDTechnology') || ''}
              />
            );
          },
          width: 150,
        }
      : null,
    is3DPrintingSupplier
      ? {
          title: '3D Infill',
          headerName: '3D Infill',
          field: 'threeDInfill',
          valueGetter: (params) => {
            const rowData = params.row;
            const infill = get(rowData, 'metadata.threeDInfill');
            return isEmptyValue(infill) ? '' : `${(infill * 100).toFixed(0)}%`;
          },
          renderCell: (params) => {
            const rowData = params.row;
            const infill = get(rowData, 'metadata.threeDInfill');
            return isEmptyValue(infill) ? '' : `${(infill * 100).toFixed(0)}%`;
          },
        }
      : null,
    is3DPrintingSupplier
      ? {
          title: '3D Layer Thickness',
          headerName: '3D Layer Thickness',
          field: 'threeDLayerThickness',
          valueGetter: (params) => {
            const rowData = params.row;
            const thickness = get(rowData, 'metadata.threeDLayerThickness');
            return isEmptyValue(thickness) ? '' : `${thickness}mm`;
          },
          renderCell: (params) => {
            const rowData = params.row;
            const thickness = get(rowData, 'metadata.threeDLayerThickness');
            return isEmptyValue(thickness) ? '' : `${thickness}mm`;
          },
        }
      : null,
    {
      title: 'My remarks',
      headerName: 'My remarks',
      field: 'myRemarks',
      valueGetter: (params) => {
        const rowData = params.row;
        return `${rowData.ppeQuoteRemarks || rowData.remarks || ''}`;
      },
      renderCell: (params) => {
        const rowData = params.row;
        return `${rowData.ppeQuoteRemarks || rowData.remarks || ''}`;
      },
      width: 200,
    },
    {
      title: 'Rejected Reason',
      headerName: 'Rejected Reason',
      field: 'reasonRejected',
      valueGetter: (params) => {
        const rowData = params.row;
        return reasonsTextUtil(rowData.reasonRejected);
      },
      hide: true,
      width: 150,
    },
    {
      title: 'Customer remarks',
      headerName: 'Customer remarks',
      field: 'customerRemarks',
      width: 200,
    },
    {
      title: 'CMM Price',
      headerName: 'CMM Price',
      field: 'ccmPrice',
      valueGetter: (params) => {
        const rowData = params.row;
        return convertPriceToCurrency({
          price: (rowData.metadata && rowData.metadata.cmmPrice) || 0,
          currency,
          exchangeRate:
            getQuotationExchangeRate(rowData, currency) || exchangeRate,
        });
      },
      renderCell: (params) => {
        const rowData = params.row;
        return convertPriceToCurrency({
          price: (rowData.metadata && rowData.metadata.cmmPrice) || 0,
          currency,
          exchangeRate:
            getQuotationExchangeRate(rowData, currency) || exchangeRate,
        });
      },
      width: 120,
    },
    {
      title: 'Expiry date',
      headerName: 'Expiry date',
      field: 'dateOfExpiry',
      valueGetter: (params) => {
        const rowData = params.row;
        return rowData.dateOfExpiry
          ? rowData.dateOfExpiry.substring(0, 10)
          : '';
      },
      renderCell: (params) => {
        const rowData = params.row;
        return rowData.dateOfExpiry
          ? rowData.dateOfExpiry.substring(0, 10)
          : '';
      },
      width: 150,
    },
    {
      title: 'Delete quote',
      headerName: 'Delete quote',
      field: 'deleteQuote',
      disableExport: true,
      export: false,
      renderCell: ({ row: rowData }) => {
        if (rowData.status === 'verifying') {
          return (
            <IconButton
              aria-label='delete'
              onClick={() => handleClickOpen(rowData.quotationID)}
            >
              <DeleteIcon fontSize='small' />
            </IconButton>
          );
        } else {
          return (
            <IconButton aria-label='delete' disabled>
              <DeleteIcon fontSize='small' />
            </IconButton>
          );
        }
      },
      width: 150,
    },
  ];

  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    onPageLoad();
    const _columns = defaultColumns?.filter((col) => !!col);
    setColumns(_columns);
    setColumnsDef(_columns);
  }, [currency]);

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const getCustomerToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <GridToolbarExport
              key='export'
              csvOptions={{
                fileName: `All My Quotes ${generateCurrentCustomDateTimeString()}`,
                delimiter: ';',
                utf8WithBom: true,
                fields: getCsvExportColumns(columns),
              }}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.body}>
      <PageTitle title='Quotes submitted' />
      {isMyordersLoading ? (
        <CircularProgress
          color='primary'
          className={classes.circularProgress}
        />
      ) : (
        <div
          style={{
            padding: '0 1rem',
          }}
        >
          <DataGrid
            autoHeight
            rows={filteredData}
            columns={columns?.map((col) => ({
              ...col,
              sortable: false,
            }))}
            getRowId={(row) => row.quotationID}
            rowHeight={80}
            headerHeight={80}
            components={{
              Toolbar: getCustomerToolbar,
              Pagination: () => (
                <GridDataPagination
                  pageCount={ceil(
                    tableQueryParams.totalCount / tableQueryParams.pageSize
                  )}
                />
              ),
            }}
            rowsPerPageOptions={[10, 20, 50]}
            pageSize={tableQueryParams.pageSize}
            onPageSizeChange={(newPageSize) =>
              updateTableQueryParams({ pageSize: newPageSize })
            }
            disableRowSelectionOnClick
            disableSelectionOnClick
            disableColumnMenu
          />
        </div>
      )}
      {openDeleteDialog && (
        <Dialog
          open={openDeleteDialog}
          onClose={handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {'Delete quote?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              You are deleting a quote that you have submitted previously.
              Please ensure that you are deleting the correct quote as this
              action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='primary'>
              Cancel
            </Button>
            <Button onClick={confirmDelete} color='primary'>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    orders: state.myorders.myorders,
    isMyordersLoading: state.myorders.getMyordersLoading,
    currency: state.auth.location.currency,
    exchangeRate: state.auth.rates[state.auth.location.currency],
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    onPageLoad: () => dispatch(getAllMyOrders()),
    deleteQuote: (quoteID) => dispatch(deleteSupplierOrder(quoteID, props)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(MyOrdersDataGrid);
