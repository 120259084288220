import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  MenuItem,
  FormControl,
  TextField,
  Typography,
} from '@material-ui/core';

import { colors } from '../../palette';

// ------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  selectDropdown: {
    borderRadius: 10,
    '& .MuiSelect-select': {
      color: colors.neutral070,
      fontWeight: 400,
      textAlign: 'left',
      whiteSpace: 'break-spaces',
    },
    '& .MuiInputBase-root': {
      borderRadius: 10,
    },
  },
  menuItemRoot: {
    borderRadius: '0.75rem',
    whiteSpace: 'break-spaces',
    margin: '0.5rem 0',
    padding: '0.5rem 1rem',
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
    '&.Mui-selected': {
      backgroundColor: '#EDEDED',
    },
  },
  paper: {
    borderRadius: '0.8rem',
    padding: '0.5rem',
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: 600,
    color: colors.neutral070,
  },
}));

const DefaultMenuItem = (item) => {
  const classes = useStyles();

  return (
    <MenuItem
      key={item.key ?? item}
      classes={{
        root: classes.menuItemRoot,
      }}
      value={item.value ?? item.key ?? item}
      style={{
        color: colors.neutral070,
      }}
    >
      {item.label ?? item.key ?? item}
    </MenuItem>
  );
};

/**
 * Customised Dropdown component
 *
 * @param {object} props - The properties of the Dropdown component.
 * @param {String} props.id - A unique identifier for the dropdown.
 * @param {String} props.value - The value of the dropdown.
 * @param {Boolean} props.fullWidth - If true, the dropdown will take up the full width of the parent div.
 * @param {Function} props.handleChange - Callback function to be executed when the dropdown value is changed.
 * @param {Array.<{key: String, display: String=}>} props.items - The items to be displayed in the dropdown.
 * @param {Boolean} props.error - The error to be displayed.
 *
 * @returns {React.ReactNode} The rendered Dropdown component.
 */
function FtrDropdownV2(props) {
  const classes = useStyles();

  const {
    id,
    value = '',
    title,
    fullWidth = false,
    handleChange,
    items = [],
    placeholder = '',
    customMenuItem,
  } = props;

  const menuItemComp = customMenuItem || DefaultMenuItem;

  const menuProps = {
    classes: {
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
  };

  return (
    <FormControl style={{ width: fullWidth && '100%' }}>
      {title && <Typography className={classes.inputLabel}>{title}</Typography>}
      <TextField
        select
        classes={{
          root: classes.selectDropdown,
        }}
        id={id}
        variant='outlined'
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        margin='dense'
        fullWidth
        label={value ? '' : placeholder}
        displayEmpty
        SelectProps={{
          MenuProps: menuProps,
        }}
        InputLabelProps={{ shrink: false }}
        {...props}
      >
        {items?.map(menuItemComp)}
      </TextField>
    </FormControl>
  );
}

export default FtrDropdownV2;
