import React, { useReducer } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Radio,
  Tooltip,
} from '@material-ui/core';

import ChatSvgIcon from '../../assets/icons/chat.svg';

import DatePickerWithIcon from '../DatePickerWithIcon';
import FtrTextArea from '../ftr-components/FtrTextArea';
import FtrFieldLabel from '../ftr-components/FtrFieldLabel';
import KeyValueInfoDisplay from '../labels/KeyValueInfoDisplay';
import {
  FtrBoldText,
  FtrButton,
  FtrH6,
  FtrTypography,
} from '../ftr-components';
import {
  FlexColumn,
  FlexColumnCenter,
  FlexRowSpaceBetween,
} from '../layouts/FlexLayouts';

import { colors } from '../../palette';

import { isEmptyValue } from '../../utils/commonUtils';

import { LEAD_TIME_SUPPLIER_FEEDBACK } from '../../constants/ppeFeedbackConstants';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
  option: {
    padding: '0 0 0 1rem',
    '&:hover': {
      backgroundColor: colors.neutral020,
      borderRadius: 12,
      cursor: 'pointer',
    },
  },
  optionSelected: {
    backgroundColor: colors.blue010,
    borderRadius: 12,
    '&:hover': {
      backgroundColor: colors.blue010,
      borderRadius: 12,
      cursor: 'pointer',
    },
  },
}));

const OPTIONS = [
  {
    text: LEAD_TIME_SUPPLIER_FEEDBACK.TAKE_ORDER_WISH_TO_REVISE,
    displayComponent: (
      <FtrTypography>
        I will take on this order but wish to see delivery date&nbsp;
        <span style={{ fontWeight: 'bold' }}>revised next time.</span>
      </FtrTypography>
    ),
  },
  {
    text: LEAD_TIME_SUPPLIER_FEEDBACK.TAKE_ORDER_IF_REVISED,
    displayComponent: (
      <FtrTypography>
        I will take on this order if the delivery date is&nbsp;
        <span style={{ fontWeight: 'bold' }}>revised for this order.</span>
      </FtrTypography>
    ),
  },
  {
    text: LEAD_TIME_SUPPLIER_FEEDBACK.NO_ACTIONS,
    displayComponent: (
      <FtrTypography>
        <span style={{ fontWeight: 'bold' }}>
          I just wish to leave feedback.
        </span>
        &nbsp; No further action required.
      </FtrTypography>
    ),
  },
];

const DEFAULT_FEEDBACK_DETAILS = OPTIONS?.reduce((acc, opt) => {
  acc[opt.text] = false;
  return acc;
}, {});

function LeadTimeFeedbackSupplierPopup(props) {
  const classes = useStyles();

  const {
    open,
    deliveryDate,
    targetDeliveryDate,
    onClose = () => {},
    onSubmit = () => {},
    feedbackDetails,
    additionalRemarks,
  } = props;

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      targetDeliveryDate: targetDeliveryDate || null,
      feedbackDetails: feedbackDetails || DEFAULT_FEEDBACK_DETAILS,
      additionalRemarks: additionalRemarks || null,
    }
  );

  const handleOptionClick = (option) => {
    const currentState = localState.feedbackDetails[option];
    const _feedbackDetails = { ...localState.feedbackDetails };
    for (const feedbackOption in _feedbackDetails) {
      // single selection
      _feedbackDetails[feedbackOption] =
        feedbackOption === option ? !currentState : false;
    }
    updateLocalState({
      feedbackDetails: _feedbackDetails,
    });
  };

  const handleSubmit = () => {
    onSubmit(localState);
  };

  const renderAdditionalRemarks = () => {
    return (
      <FlexColumn style={{ gap: '1rem', padding: '0 1rem', marginTop: '1rem' }}>
        <FtrBoldText>Tell us more (optional)</FtrBoldText>
        <FtrTextArea
          value={localState.additionalRemarks}
          onChange={(e) =>
            updateLocalState({ additionalRemarks: e.target.value })
          }
        />
      </FlexColumn>
    );
  };

  const renderDialogActions = () => {
    return (
      <FlexColumnCenter
        style={{
          gap: '1rem',
          marginTop: '2rem',
          maxWidth: 200,
          width: '100%',
          alignSelf: 'center',
        }}
      >
        <Tooltip
          title={
            isEmptyValue(localState.targetDeliveryDate)
              ? 'Please select target delivery date'
              : ''
          }
        >
          <div style={{ width: '100%' }}>
            <FtrButton
              id='proceed-to-create-project-btn'
              color='blue'
              variant='contained'
              onClick={() => {
                handleSubmit();
                onClose();
              }}
              autoFocus
              fullWidth
              disabled={
                Object.values(localState.feedbackDetails)?.every(
                  (v) => v === false
                ) || isEmptyValue(localState.targetDeliveryDate)
              }
            >
              Submit
            </FtrButton>
          </div>
        </Tooltip>
        <FtrButton variant='text-black' onClick={onClose}>
          Go back to quote
        </FtrButton>
      </FlexColumnCenter>
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth='xs'
      fullWidth
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <DialogTitle disableTypography className={classes.title}>
        <img style={{ padding: '1rem 1rem' }} src={ChatSvgIcon} alt='icon' />
      </DialogTitle>
      <DialogContent>
        <FtrH6 style={{ marginBottom: '1rem', textAlign: 'center' }}>
          How would you like to proceed?
        </FtrH6>
        <FlexRowSpaceBetween
          style={{ paddingLeft: '2rem', alignItems: 'start' }}
        >
          <KeyValueInfoDisplay
            name='Delivery Date'
            value={deliveryDate}
            color={colors.blue050}
          />
          <FlexColumn style={{ gap: 0, width: 200 }}>
            <FtrFieldLabel style={{ padding: 0 }}>
              Proposed Delivery Date
            </FtrFieldLabel>
            <DatePickerWithIcon
              label=''
              value={localState.targetDeliveryDate}
              onChange={(date) => {
                updateLocalState({ targetDeliveryDate: date });
              }}
              animateYearScrolling
              inputVariant='outlined'
              margin='dense'
              endIcon={null}
            />
          </FlexColumn>
        </FlexRowSpaceBetween>
        <FlexColumn
          style={{
            width: '100%',
            gap: '1rem',
            padding: '1rem',
            boxSizing: 'border-box',
          }}
        >
          {OPTIONS?.map((option) => {
            return (
              <FlexRowSpaceBetween
                key={option.text}
                className={clsx(
                  classes.option,
                  localState.feedbackDetails[option.text]
                    ? classes.optionSelected
                    : null
                )}
                onClick={() => handleOptionClick(option.text)}
              >
                {option.displayComponent}
                <Radio
                  color='primary'
                  checked={localState.feedbackDetails[option.text]}
                  onClick={() => handleOptionClick(option.text)}
                />
              </FlexRowSpaceBetween>
            );
          })}
          {renderAdditionalRemarks()}
        </FlexColumn>
        <FlexColumnCenter>{renderDialogActions()}</FlexColumnCenter>
      </DialogContent>
    </Dialog>
  );
}

export default LeadTimeFeedbackSupplierPopup;
