import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import DownloadableFileWithActions from './list-items/DownloadableFileWithActions';
import CreditNoteReconciledInfo from './info/CreditNoteReconciledInfo';
import FtrTooltip from './ftr-components/FtrTooltip';
import { ReactComponent as InfoIcon } from '../assets/icons/info_icon_blue.svg';
import { FlexRow } from './layouts/FlexLayouts';
import { FtrButton } from './ftr-components';

import withSendSupplierCreditNoteToHubdocPopupHOC from '../hocs/withSendSupplierCreditNoteToHubdocPopupHOC';

import {
  extractFileNameFromUrlFull,
  extractFileNameWithoutTimestampFromUrl,
  extractTimestampStringFromUrl,
  isFileNameWithTimestamp,
} from '../utils/fileUtils';
import { dateTzSingapore } from '../utils/dateTimeUtils';
import { isAdminOrHigherRole } from '../utils/roleUtils';
import { isEmptyValue } from '../utils/commonUtils';

import { getUserSelector } from '../selectors/userSelector';

import { colors } from '../palette';

function SupplierCreditNoteDisplay({
  supplierCreditNoteInfo,
  updateSupplierCreditNoteInfoState,
  handleDeleteSupplierCreditNote = () => {},
  showDeleteButton = false,
  isLoading = false,
  isCreditNoteSentToHubdoc = false,
  isAnyCreditNoteSentToHubdoc = false,
  allowSendToHubdoc = false,
  isOrderDelivered = false,
}) {
  const { role: userRole } = useSelector(getUserSelector);

  const {
    supplierCreditNoteInfoID,
    supplierCreditNoteUrl,
    creditNoteExtractionApiCallID,
    isCreditNoteReconciled,
    apiMessage,
  } = supplierCreditNoteInfo;
  const hasValidTimestamp = isFileNameWithTimestamp(supplierCreditNoteUrl);

  const renderDownloadableCreditNote = () => {
    return (
      <FlexRow>
        <DownloadableFileWithActions
          key={`${supplierCreditNoteUrl}`}
          fileUrl={supplierCreditNoteUrl}
          fileName={
            hasValidTimestamp
              ? extractFileNameWithoutTimestampFromUrl(supplierCreditNoteUrl)
              : extractFileNameFromUrlFull(supplierCreditNoteUrl)
          }
          showDeleteButton={showDeleteButton}
          onDelete={() => handleDeleteSupplierCreditNote(supplierCreditNoteUrl)}
          noteMessage={`Upload Date: ${
            hasValidTimestamp
              ? dateTzSingapore(
                  Number(extractTimestampStringFromUrl(supplierCreditNoteUrl))
                )
              : 'No upload date found'
          }`}
        />
      </FlexRow>
    );
  };

  const renderWithCreditNoteExtractionInfo = () => {
    return (
      <CreditNoteReconciledInfo
        isCreditNoteSentToHubdoc={isCreditNoteSentToHubdoc}
        showCheckIconOnly={isAnyCreditNoteSentToHubdoc}
        isOrderDelivered={isOrderDelivered}
      >
        {renderDownloadableCreditNote()}
        {isAdminOrHigherRole(userRole) && (
          <FtrTooltip description={apiMessage} theme={'light'}>
            <InfoIcon style={{ color: colors.neutral040 }} />
          </FtrTooltip>
        )}
        {allowSendToHubdoc && (
          <FtrButton
            color='blue'
            variant='contained'
            onClick={() => {
              updateSupplierCreditNoteInfoState({
                open: true,
                supplierCreditNoteInfoID,
                isCreditNoteReconciled,
              });
            }}
            autoFocus
            disabled={false}
          >
            Send to Finance
          </FtrButton>
        )}
      </CreditNoteReconciledInfo>
    );
  };

  if (isLoading) {
    return (
      <FlexRow>
        {renderDownloadableCreditNote()}
        <CircularProgress size={35} />
      </FlexRow>
    );
  }

  return isEmptyValue(creditNoteExtractionApiCallID) &&
    !isCreditNoteSentToHubdoc
    ? renderDownloadableCreditNote()
    : renderWithCreditNoteExtractionInfo();
}

export default withSendSupplierCreditNoteToHubdocPopupHOC(
  SupplierCreditNoteDisplay
);
