import React, { useContext, useMemo, useState } from 'react';

import { FtrTypography } from './ftr-components';
import { FlexRowCenter } from './layouts/FlexLayouts';
import TechnicalDrawingUploadFileTypeMessage, {
  TECHNICAL_DRAWING_UN_SUPPORT_MESSAGE,
} from './ftr-components/TechnicalDrawingUploadFileTypeMessage';

import { isTechnicalDrawingFile } from '../utils/fileUtils';

import AppContext from '../context/AppContext';

import { TECHNICAL_DRAWING_FILE_TYPES } from '../constants/fileConstants';

import { colors } from '../palette';

// -------------------------------------------------------------------------------------------------

function DrawingDragDrop(props) {
  const { id, handleFiles, dragging, setDragging, contentStyle, ...rest } =
    props;

  const { setSnackbarMessage } = useContext(AppContext);

  const [isFilesValid, setIsFilesValid] = useState(true);

  const acceptedFiles = useMemo(
    () => TECHNICAL_DRAWING_FILE_TYPES.join(','),
    []
  );

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);

    const notValid = files?.some((file) => !isTechnicalDrawingFile(file.name));

    if (notValid) {
      setIsFilesValid(false);
      setSnackbarMessage(TECHNICAL_DRAWING_UN_SUPPORT_MESSAGE, {
        severity: 'error',
        errorTitle:
          'Customer uploaded unsupported file type for technical drawing',
        body: {
          files,
        },
      });
      setTimeout(() => setIsFilesValid(true), 2000);
      return;
    }

    handleFiles(files);
  };

  const renderErrorText = () => {
    return <TechnicalDrawingUploadFileTypeMessage />;
  };

  const renderContentDragging = () => {
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
          backgroundColor: colors.blue010,
          opacity: 0.8,
        }}
        onDragLeave={handleDragLeave}
      >
        <FlexRowCenter
          style={{ height: '100%', color: colors.blue060, fontWeight: 700 }}
        >
          Release to upload file
        </FlexRowCenter>
      </div>
    );
  };

  const renderDefaultText = () => {
    return (
      <FtrTypography
        type='body'
        fontSize='14'
        style={{ color: colors.neutral080 }}
      >
        Drag or
        <span style={{ color: colors.blue060, fontWeight: 700 }}> upload </span>
        technical drawing
      </FtrTypography>
    );
  };

  return (
    <div id={id} {...rest}>
      <input
        id={`cad-part-file-${id}`}
        type='file'
        accept={acceptedFiles}
        multiple
        onChange={(evt) => handleFiles(evt.target.files)}
        onClick={(event) => (event.target.value = null)}
        style={{
          display: 'none',
        }}
      />
      <label
        htmlFor={`cad-part-file-${id}`}
        onDrop={handleDrop}
        data-cy={`upload-multi-cad-files-${id}`}
      >
        <div
          data-cy={`upload-multi-cad-files-${id}`}
          id='drawing-drag-drop'
          style={{
            borderRadius: '10px',
            border: `dashed ${colors.neutral050} 1.5px`,
            width: '160px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            verticalAlign: 'middle',
            alignItems: 'center',
            cursor: 'pointer',
            textAlign: 'center',
            padding: '0.75rem 0.5rem',
            boxSizing: 'border-box',
            ...contentStyle,
          }}
        >
          {isFilesValid && renderDefaultText()}
          {!isFilesValid && renderErrorText()}
        </div>
        {dragging && renderContentDragging()}
      </label>
    </div>
  );
}

export default DrawingDragDrop;
