export const PROMO_CODE_PREFIX = 'FCTRM';

export const PROMO_CODE_TYPE = {
  SINGLE_USE: 'single_use',
  MULTI_USE: 'multi_use',
};

export const PROMO_CODE_TYPES_DROPDOWN = [
  {
    label: 'Single Use (Code can be used once per account)',
    value: PROMO_CODE_TYPE.SINGLE_USE,
  },
  {
    label: 'Multi Use (Code can be used multiple times by the same account)',
    value: PROMO_CODE_TYPE.MULTI_USE,
  },
];

export const DISCOUNT_CODES = {
  FCTRM50OFF: 'FCTRM50OFF',
  FCTRM5MORE: 'FCTRM5MORE',
};
