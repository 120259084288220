import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { FlexColumn } from '../../components/layouts/FlexLayouts';
import AutoBalloonPresentational from '../generation-tools/AutoBalloonPresentational';
import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='left' ref={ref} {...props} />;
});

function VerifyBalloonPopup(props) {
  const {
    open,
    handleClose,
    originalImageUrls = [],
    annotationData = [],
    totalPages = 0,
    annotatedImageUrls = [],
    autoBallooning = false,
    manualBallooning = false,
    deletingBalloon = false,
    downloadingReport = false,
    downloadingPdf = false,
    verifying = false,
    saving = false,
    handleDeleteBalloon = () => {},
    addManualBalloons = () => {},
    setAnnotationData = () => {},
    handleDownloadReportCsv = () => {},
    handleDownloadPdf = () => {},
    verifyItemBalloon = () => {},
    saveItemBalloon = () => {},
  } = props;

  const processing =
    verifying ||
    saving ||
    deletingBalloon ||
    manualBallooning ||
    downloadingReport ||
    downloadingPdf;

  const renderActionButtons = () => {
    return (
      <>
        <Button
          variant='contained'
          color='secondary'
          disabled={processing}
          onClick={handleClose}
          fullWidth
        >
          Cancel
        </Button>
        {!isEmptyValue(annotationData) && (
          <Button
            fullWidth
            variant='outlined'
            color='primary'
            onClick={handleDownloadReportCsv}
            disabled={processing}
            loading={downloadingReport}
          >
            Download excel/xlsx
          </Button>
        )}
        {!isEmptyValue(annotatedImageUrls) && (
          <Button
            fullWidth
            variant='outlined'
            color='primary'
            onClick={handleDownloadPdf}
            disabled={processing}
            loading={downloadingPdf}
          >
            Download PDF
          </Button>
        )}
        <Button
          fullWidth
          variant='contained'
          color='primary'
          onClick={saveItemBalloon}
          loading={saving}
          disabled={processing}
          style={{
            marginLeft: '10px',
          }}
        >
          Save
        </Button>
        <Button
          fullWidth
          variant='contained'
          color='primary'
          onClick={verifyItemBalloon}
          style={{
            backgroundColor: '#4caf50',
          }}
          disabled={processing}
          loading={verifying}
        >
          Verify Balloon
        </Button>
      </>
    );
  };

  return (
    <Dialog
      fullScreen
      open={open}
      aria-labelledby='form-dialog-title'
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle style={{ padding: 0 }}>
        <IconButton
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: 20,
            top: 10,
            color: 'black',
            backgroundColor: colors.lightGray,
            padding: '0.3rem',
            zIndex: 9,
          }}
          size='small'
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: 'white' }}>
        <FlexColumn
          style={{
            padding: '1rem 2rem',
            boxSizing: 'border-box',
            backgroundColor: 'white',
          }}
        >
          <AutoBalloonPresentational
            autoBallooning={autoBallooning}
            manualBallooning={manualBallooning}
            processing={processing}
            originalImageUrls={originalImageUrls}
            annotationData={annotationData}
            totalPages={totalPages}
            annotatedImageUrls={annotatedImageUrls}
            handleDeleteBalloon={handleDeleteBalloon}
            addManualBalloons={addManualBalloons}
            setAnnotationData={setAnnotationData}
          />
        </FlexColumn>
      </DialogContent>
      <DialogActions
        style={{
          display: 'inline-flex',
          justifyContent: 'space-evenly',
          padding: '1rem',
          borderTop: '1px solid #e0e0e0',
        }}
      >
        {renderActionButtons()}
      </DialogActions>
    </Dialog>
  );
}

export default VerifyBalloonPopup;
