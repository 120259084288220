import React, { useMemo, useReducer } from 'react';
import { useQuery } from 'react-query';

import { MenuItem, TextField, Tooltip, makeStyles } from '@material-ui/core';

import FailedIcon from '../icons/FailedIcon';
import DfmDefectsForm from '../forms/DfmDefectsForm';
import ExtractButton from '../buttons/ExtractButton';
import {
  FlexColumn,
  FlexRow,
  FlexRowSpaceBetween,
} from '../layouts/FlexLayouts';
import CheckAndWarningInfo from '../info/CheckAndWarningInfo';
import { FtrButton } from '../ftr-components';
import withDefectVisualizationPopupHOC from '../popups/withDefectVisualizationPopupHOC';

import { getFileNameFromCadFile } from '../../utils/itemUtils';
import { defectsStatus } from '../../utils/dfmExtractionUtils';
import { snakeCaseToTitleCase } from '../../utils/stringUtils';

import {
  extractDfmDefectByItemID,
  getLatestDfmDefectsByItemID,
} from '../../apis/dfmDefectExtractionApi';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { techMapping } from '../../constants/PPEConstants';
import { EXTRACT_DEFECT_KEYS_MAPPING } from '../../constants/dfmConstants';
import FtrAccordion from '../ftr-components/FtrAccordion';
import { getNotEmptyValueFromObject } from '../../utils/objectUtils';
import { getCadFileTo3DRenderer } from '../../utils/fileUtils';
import { isEmptyValue } from '../../utils/commonUtils';

const useStyles = makeStyles(() => ({
  icon: {
    marginLeft: 0,
  },
}));

const View3DButton = (props) => {
  const { handleView3DDefect, defectUrl, cadFile } = props;
  return (
    <FtrButton
      variant='outlined'
      color='blue'
      style={{
        marginLeft: '10px',
        padding: '3px 9px',
        minWidth: 'max-content',
      }}
      size='small'
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleView3DDefect(defectUrl);
      }}
      cadFile={cadFile}
    >
      VIEW 3D
    </FtrButton>
  );
};

const DfmDefectFormWithVisualizationPopup =
  withDefectVisualizationPopupHOC(DfmDefectsForm);
const View3DVisualizationButton = withDefectVisualizationPopupHOC(View3DButton);

function DfmDefectsExtractionAccordion(props) {
  const { itemID, cadFile, originalFiles, technology } = props;
  const classes = useStyles();

  const listFiles = useMemo(() => {
    return [...new Set([...cadFile, ...originalFiles])];
  }, [cadFile, originalFiles]);

  const [localState, updateLocalState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      selectedFile: null,
      output: null,
      extractLoading: false,
      open3DViewerPopup: false,
    }
  );

  const { isLoading, refetch } = useQuery(
    ['getLatestDfmDefectsByItemID', itemID],
    () => {
      if (isEmptyValue(itemID)) {
        return;
      }

      return getLatestDfmDefectsByItemID(itemID).then((res) => {
        if (res?.output === 'loading') {
          updateLocalState({ extractLoading: true });
          return;
        }

        const output = res?.output?.defects
          ? { ...res.output.defects, trackingID: res.output.trackingID }
          : res?.output;
        updateLocalState({
          ...res,
          output,
          selectedFile: res?.sourceUrl,
          trackingID: res?.output?.trackingID,
        });
      });
    }
  );

  const handleExtractDfmDefects = async () => {
    try {
      const body = {
        sourceUrl: localState.selectedFile,
        process: techMapping[technology] ?? technology,
      };
      updateLocalState({ extractLoading: true });
      const data = await extractDfmDefectByItemID(itemID, body).finally(() => {
        updateLocalState({ extractLoading: false });
      });
      notifySuccess('3D DFM extracted successfully!');
      updateLocalState({ output: data?.output?.defects ?? data?.output });
      refetch();
    } catch (err) {
      notifyError(err.message);
    }
  };

  const renderDefectIconStatus = () => {
    const _defectsStatus = defectsStatus(localState.output);
    return (
      <FlexRowSpaceBetween style={{ gap: '0' }}>
        <FlexRow style={{ gap: 0, marginLeft: '5px' }}>
          {_defectsStatus?.map((defect, index) => {
            if (
              defect.ruleValue === undefined ||
              (defect?.key === EXTRACT_DEFECT_KEYS_MAPPING.dfm_success &&
                defect.value)
            ) {
              return null;
            }
            if (!localState.output?.dfm_success) {
              return (
                <FailedIcon
                  key={index}
                  toolTipText={localState.output?.error ?? 'No DFM Automation'}
                />
              );
            }
            return (
              <CheckAndWarningInfo
                key={index}
                compareValue={defect.ruleValue}
                value={defect.value}
                tooltipTextCheck={`${snakeCaseToTitleCase(
                  defect.key
                )}: no defects`}
                tooltipTextWarning={`${snakeCaseToTitleCase(defect.key)}: ${
                  defect.value
                }`}
                className={classes.icon}
              />
            );
          })}
        </FlexRow>
      </FlexRowSpaceBetween>
    );
  };

  const renderView3DVisualizationButton = () => {
    const defectUrl = getNotEmptyValueFromObject(
      localState.output?.visualization_links
    );
    const _cadFile = getCadFileTo3DRenderer(listFiles);
    if (!defectUrl && !_cadFile) {
      return null;
    }
    return (
      <View3DVisualizationButton
        defectsOutput={localState.output}
        itemID={itemID}
        defectUrl={defectUrl}
        cadFile={_cadFile}
        showFeedback
      />
    );
  };

  return (
    <>
      <FtrAccordion
        accordionKey={'dfm-defects-extraction'}
        header={
          <FlexRowSpaceBetween style={{ width: '100%' }}>
            <FlexColumn>
              <FtrAccordion.Header>3D DFM Extraction</FtrAccordion.Header>
              <FlexRow>
                {renderDefectIconStatus()}
                {renderView3DVisualizationButton()}
              </FlexRow>
            </FlexColumn>
          </FlexRowSpaceBetween>
        }
        details={
          <DfmDefectFormWithVisualizationPopup
            defectsOutput={localState.output}
            itemID={itemID}
            showFeedback
          />
        }
      />

      {listFiles?.length > 0 && (
        <Tooltip title='Select the customer cad file for the next extraction, triggered by the button below.'>
          <TextField
            id='select-dfm-cad-file'
            select
            label='Cad File for Extraction'
            value={localState.selectedFile ?? null}
            onChange={(evt) =>
              updateLocalState({
                selectedFile: evt.target.value,
              })
            }
            fullWidth
            variant='outlined'
            margin='dense'
            style={{ marginTop: '1rem', marginBottom: '0.5rem' }}
            InputLabelProps={{
              shrink: true,
            }}
          >
            {listFiles?.map((url, index) => {
              const encodedFileName = url?.split('/').pop();
              const fileName = getFileNameFromCadFile(encodedFileName);
              return (
                <MenuItem key={index} value={url}>
                  {fileName}
                </MenuItem>
              );
            })}
          </TextField>
        </Tooltip>
      )}
      <ExtractButton
        disabled={
          listFiles?.length === 0 || isLoading || localState.extractLoading
        }
        onClick={handleExtractDfmDefects}
        tooltipText={
          listFiles?.length === 0
            ? 'Cad file is empty'
            : 'Clicking this will start a new attempt to extract defect, based on the above selected file.'
        }
        loading={isLoading || localState.extractLoading}
        hasExtractionAttempt={localState.hasExtractionAttempt}
        name={localState.name}
        createdAt={localState.createdAt}
        sourceUrl={localState.sourceUrl}
        role={localState.role}
        buttonText='Extract 3D Dfm Defects'
      />
    </>
  );
}

export default DfmDefectsExtractionAccordion;
