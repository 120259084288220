import { useQuery } from 'react-query';

import { getUserInfo } from '../apis/userApi.js';

import { isEmptyValue } from '../utils/commonUtils.js';

import { setCache } from '../services/localStorageCacheService.js';

import { ONE_HOUR_IN_MS } from '../utils/dateTimeUtils.js';
import { LOGIN_USER_INFO_KEY } from '../constants/localStorageConstants.js';

// -------------------------------------------------------------------------------------------------

const useLoginUserInfo = (customerID) => {
  const { data, isLoading, isFetching } = useQuery(
    ['getUserInfo', customerID],
    () => getUserInfo(customerID),
    {
      staleTime: ONE_HOUR_IN_MS,
      cacheTime: ONE_HOUR_IN_MS,
      enabled: !isEmptyValue(customerID),
      onSettled: (_data) => {
        const cacheData = JSON.stringify({
          userID: customerID,
          role: _data?.role,
          email: _data?.email,
          name: _data?.name,
        });
        setCache(LOGIN_USER_INFO_KEY, cacheData);
      },
    }
  );

  return {
    data,
    isLoading,
    isFetching,
  };
};

export default useLoginUserInfo;
