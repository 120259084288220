import React, { useEffect, useMemo, useState } from 'react';

import { connect, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  CircularProgress,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

import { CuratedProjects, OtherProjects } from '../components/ProjectList';
import ViewAsSupplierPopupButton from '../components/ViewAsSupplierPopupButton';
import TechFiltersBar from '../components/filters/TechFiltersBar';

import withViewAsSupplierHOC from '../hocs/withViewAsSupplierHOC';

import { getReadyForQuoteItemsGroupByProject } from '../apis/itemApi';

import { getAllItems, getAllMyOrders } from '../actions';
import {
  updateFilterBrowse,
  updateKeywordBrowse,
} from '../actions/pageSettings';

import { EXCLUDE_USER_EMAILS } from '../constants';
import { TECHNOLOGY_OPTION_TYPE } from '../constants/NewPartConstants';

import { isEmptyValue } from '../utils/commonUtils';

import { useIntersectionSelector } from '../hooks/useIntersectionSelector';

const useStyles = makeStyles((theme) => ({
  browseOption: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const FILTER_TECH_MAPPING = {
  checkedCNC: 'CNC Machining',
  checked3d: '3D Printing',
  checkedSheetMetal: 'Sheet Metal Fabrication',
  checkedDesignService: 'Design Service',
  checkedOthers: 'Others',
};

function BrowseProjectDisplay(props) {
  const classes = useStyles();

  const { user, filterStatus, searchKeyword } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  const [curatedProjects, setCuratedProjects] = useState([]);
  const [otherProjects, setOtherProjects] = useState([]);
  const [numberDataToDisplay, setNumberDataToDisplay] = useState(40);

  const { status: loadingStatus, data: projects } = useQuery(
    ['projectItems', user?.userID, user?.role],
    () =>
      getReadyForQuoteItemsGroupByProject({
        userID: user?.userID,
        userRole: user?.role,
      })
  );

  const otherProjectDisplay = useMemo(() => {
    return otherProjects?.slice(0, numberDataToDisplay);
  }, [otherProjects, numberDataToDisplay]);

  useEffect(() => {
    if (loadingStatus !== 'success') {
      return;
    }

    const selectedTechs = Object.entries(filterStatus)?.reduce(
      (acc, [key, value]) => {
        if (value) {
          acc.push(FILTER_TECH_MAPPING[key]);
        }
        return acc;
      },
      []
    );
    let filteredByUsersProjects = EXCLUDE_USER_EMAILS.includes(user.email)
      ? projects?.filter((project) =>
          [
            TECHNOLOGY_OPTION_TYPE.NOT_SURE,
            TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING,
          ].includes(project.projectTechnologies[0])
        )
      : projects;

    filteredByUsersProjects = isEmptyValue(selectedTechs)
      ? filteredByUsersProjects
      : filteredByUsersProjects?.filter((project) =>
          selectedTechs.includes(project.projectTechnologies[0])
        );

    if (!isEmptyValue(searchKeyword)) {
      filteredByUsersProjects = filteredByUsersProjects?.filter((project) => {
        const searchFields = [
          `${project.projectID}`,
          ...(project.items?.flatMap((item) => [`${item.itemID}`]) || []),
        ];
        return searchFields?.some((item) =>
          item.toLowerCase().includes(searchKeyword.toLowerCase())
        );
      });
    }

    let _curatedProjects = [];
    let _otherProjects = [];
    for (const project of filteredByUsersProjects) {
      const curatedItems = project.items?.filter((item) => item.isCuratedTask);
      const otherItems = project.items?.filter((item) => !item.isCuratedTask);
      if (!isEmptyValue(curatedItems)) {
        _curatedProjects.push({ ...project, items: curatedItems });
      }
      if (!isEmptyValue(otherItems)) {
        _otherProjects.push({ ...project, items: otherItems });
      }
    }
    setCuratedProjects(_curatedProjects);
    setOtherProjects(_otherProjects);
  }, [projects, filterStatus, searchKeyword, numberDataToDisplay]);

  // should show other projects
  useIntersectionSelector(
    `#browse-parts-index-${numberDataToDisplay - 1}`, // last index
    (entry) => {
      if (entry?.isIntersecting) {
        setNumberDataToDisplay((prev) => prev + 20); // show the next 20 data
      }
    }
  );

  const handleFilterChange = (event) => {
    const selected = event.target.name;
    dispatch(
      updateFilterBrowse({
        ...filterStatus,
        [selected]: !filterStatus[selected],
      })
    );
  };

  const renderLoading = () => {
    return (
      <CircularProgress
        style={{
          width: '2rem',
          height: '2rem',
        }}
        color='primary'
      />
    );
  };

  return (
    <div>
      <div>
        <div className={classes.browseOption}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {!EXCLUDE_USER_EMAILS.includes(user.email) && (
              <TechFiltersBar
                filterStatus={filterStatus}
                handleFilterChange={handleFilterChange}
              />
            )}
            <ViewAsSupplierPopupButton
              showResetButton={loadingStatus === 'success'}
            />
          </div>
          <TextField
            fullWidth={isMobile}
            data-cy='search-project-item'
            variant='outlined'
            className={classes.searchBar}
            input='text'
            id='searchBar'
            placeholder='Search'
            onChange={(e) => dispatch(updateKeywordBrowse(e.target.value))}
            value={searchKeyword}
            InputProps={{
              endAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {loadingStatus === 'loading' && renderLoading()}
        {loadingStatus === 'success' && (
          <>
            <CuratedProjects data={curatedProjects} />
            <OtherProjects
              data={otherProjectDisplay}
              style={{ marginTop: '2rem' }}
            />
          </>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    filterStatus: state.pageSettings.filterStatusBrowse,
    searchKeyword: state.pageSettings.searchKeywordBrowse,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    onPageLoad: () => {
      dispatch(getAllItems());
      dispatch(getAllMyOrders());
    },
  };
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default withConnect(withViewAsSupplierHOC(BrowseProjectDisplay));
