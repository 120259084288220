import { capitalize } from 'lodash';

import { reduce } from './arrayUtils';

// -------------------------------------------------------------------------------------------------

export const groupUsersByKeyForDropDown = (userList = [], key = 'role') => {
  const mapByRoleResults = reduce(
    userList,
    (acc, user) => {
      let obj = acc?.find((item) => item.key === user[key]);
      if (!obj) {
        obj = {
          key: user[key],
          name: capitalize(user[key]?.replace(/_/g, ' ')),
          value: [],
        };
        acc.push(obj);
      }
      obj.value.push({
        key: user.userID,
        text: `${user.name} <${user.email}>`,
      });
      return acc;
    },
    []
  );

  return mapByRoleResults;
};
