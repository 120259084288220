import React, { useReducer } from 'react';

import { CUSTOMER_CANCEL_ORDER_REASON_MAPPING } from '../../constants/itemConstants';

import { deleteItemsApi } from '../../apis/itemApi';

import { isEmptyValue } from '../../utils/commonUtils';

import DeleteWarningPopupV3 from '../../components/popups/DeleteWarningPopupV3';

import { notifyError, notifySuccess } from '../../services/notificationService';

// -------------------------------------------------------------------------------------------------

function withDeleteSinglePartPopupHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const defaultLocalState = {
      open: false,
      deleting: false,
      closeEditRFQFormOnDelete: () => {},
      getItems: () => {},
    };

    const [localState, updateLocalState] = useReducer((prev, next) => {
      if (isEmptyValue(next)) {
        return defaultLocalState;
      }
      return { ...prev, ...next };
    }, defaultLocalState);

    const handleSubmitDeleteReason = async ({
      feedback,
      additionalRemarks,
    }) => {
      const body = {
        projectID: localState.item?.projectID,
        itemIDs: [localState.item?.itemID], // Singleton array since only deleting 1 item
        feedback: CUSTOMER_CANCEL_ORDER_REASON_MAPPING[feedback] || feedback,
        additionalRemarks,
      };
      updateLocalState({ deleting: true });
      try {
        await deleteItemsApi(body);
        localState.closeEditRFQFormOnDelete();
        localState.refreshProjectItemsOnDelete();
        notifySuccess(`Part ${localState.item?.itemID} has been deleted`);
      } catch (error) {
        notifyError('Unable to delete part(s). Please try again.');
      }
      updateLocalState({ deleting: false, open: false });
    };

    return (
      <>
        <WrappedComponent
          updateDeletePartsPopupState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <DeleteWarningPopupV3
            deletedItems={[localState.item]}
            open={localState.open}
            deleting={localState.deleting}
            onConfirm={handleSubmitDeleteReason}
            onCancel={() => updateLocalState({ open: false })}
            onClose={() => updateLocalState({ open: false })}
          />
        )}
      </>
    );
  }

  return WrappedComponentWithHOC;
}

export default withDeleteSinglePartPopupHOC;
