import React from 'react';

import { Tooltip } from '@material-ui/core';

import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';

import { colors } from '../../palette';

/**
 *
 * @param {Object} props
 * @param {String} props.toolTipText
 * @param {React.CSSProperties} props.style
 * @returns
 */
function InfoIcon(props) {
  const { toolTipText, style, ...rest } = props;
  return (
    <Tooltip title={toolTipText} placement='top' arrow>
      <InfoOutlinedIcon
        style={{
          fontSize: '1.1rem',
          color: colors.solidBlue,
          verticalAlign: 'middle',
          ...style,
        }}
        {...rest}
      />
    </Tooltip>
  );
}

export default InfoIcon;
