import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  Tooltip,
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import { colors } from '../../palette';
import {
  CONVERT_PPE_TO_RFQ_REASONS_OPTIONS,
  CONVERT_PPE_TO_RFQ_REASONS,
  ITEM_ACTION_TYPES,
} from '../../constants/itemConstants';
import { isEmptyValue } from '../../utils/commonUtils';
import { FtrButton } from '../ftr-components';
import { FlexRowCenter } from '../layouts/FlexLayouts';
import Title from '../Title';

import { trackActionApi } from '../../apis/actionTrackingApi';
import { notifyError, notifySuccess } from '../../services/notificationService';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 700,
    borderRadius: 10,
    overflowX: 'clip',
    boxSizing: 'border-box',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
  otherInput: {
    display: 'flex',
    marginLeft: '35px',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginBottom: '10px',
    columnGap: '20px',
  },
  content: {
    display: 'flex',
    padding: '5px 0',
  },
  nameContent: {
    color: colors.projectJob,
    fontWeight: '600',
  },
  containerCheckbox: {
    backgroundColor: colors.lightGray,
    padding: '5px 0 5px 20px',
    borderRadius: '5px',
  },
}));

const OTHER_PREFIX = CONVERT_PPE_TO_RFQ_REASONS.OTHERS + ': ';

/**
 * A Dialog component for user to select reasons for converting PPE to RFQ
 * @param {Object} props
 * @param {boolean} props.open - Whether the dialog is open
 * @param {Function} props.onClose - Called when the user closes the dialog
 * @param {Array<string>} props.reasons - The reasons that are initially selected
 * @param {string} props.itemID - The item ID
 * @param {Function} props.onSuccess - Called when the user successfully submits the reasons
 * @param {Function} props.onSubmit - Called when the user submits the reasons
 * @param {boolean} props.isSubmitted - Whether the reasons have already been submitted
 */
function ConvertPpeToRfqReasonPopup(props) {
  const classes = useStyles();

  const {
    open,
    onClose = () => {},
    reasons,
    itemID,
    onSuccess = () => {},
    onSubmit = () => {},
    isSubmitted,
  } = props;

  const [selectedValues, setSelectedValues] = useState(reasons || []);
  const initialOtherValue =
    reasons
      ?.find((v) => v.startsWith(OTHER_PREFIX))
      ?.substring(OTHER_PREFIX.length) || '';
  const [otherValue, setOtherValue] = useState(initialOtherValue);
  const [isLoading, setIsLoading] = useState(false);

  const isOtherValue = (item) =>
    item?.startsWith(CONVERT_PPE_TO_RFQ_REASONS.OTHERS);
  const hasOthersValue = selectedValues?.some((value) =>
    value.startsWith(CONVERT_PPE_TO_RFQ_REASONS.OTHERS)
  );
  const hasSubmittedWithReason = isSubmitted && !isEmptyValue(reasons);

  const disableMessage = () => {
    if (isEmptyValue(selectedValues)) {
      return 'Please select at least one reason';
    }
    if (hasOthersValue && !otherValue) {
      return 'Please fill in other reason';
    }
    if (hasSubmittedWithReason) {
      return 'You have already submitted the reasons. Please let the dev team know if you want to make any changes.';
    }
    return '';
  };

  const checkStatus = (item) => {
    return selectedValues?.some((value) => {
      if (value?.startsWith(OTHER_PREFIX)) {
        value = CONVERT_PPE_TO_RFQ_REASONS.OTHERS;
      }
      return value === item;
    });
  };

  const handleChangeCheckbox = (event) => {
    let item = event.target.name;
    const isOtherItem = isOtherValue(item);

    setSelectedValues((prev) => {
      const isSelected = prev.some((val) =>
        isOtherValue(val)
          ? item === CONVERT_PPE_TO_RFQ_REASONS.OTHERS
          : val === item
      );
      if (isSelected) {
        // Remove the item
        return prev.filter((val) =>
          isOtherValue(val)
            ? item !== CONVERT_PPE_TO_RFQ_REASONS.OTHERS
            : val !== item
        );
      }
      // Add the item
      const newValue = isOtherItem ? `${OTHER_PREFIX}${otherValue}` : item;
      return [...prev, newValue];
    });
  };

  const handleChangeOtherValue = (inputValue) => {
    setOtherValue(inputValue);
    if (hasOthersValue) {
      const filterSelectedValue = selectedValues?.filter(
        (value) => !value.startsWith(CONVERT_PPE_TO_RFQ_REASONS.OTHERS)
      );
      const newSelectedValue = [
        ...filterSelectedValue,
        `${OTHER_PREFIX}${inputValue}`,
      ];
      setSelectedValues(newSelectedValue);
    }
  };

  const submitWithTracking = async (reasonValues) => {
    await trackActionApi({
      actionInfo: { reasons: reasonValues },
      actionTrackingKey: ITEM_ACTION_TYPES.CONVERT_PPE_TO_RFQ,
      itemID,
    });
    onSuccess();
    notifySuccess('Successfully submitted convert PPE to RFQ reasons');
  };

  const handleSubmitButtonClick = () => {
    setIsLoading(true);
    const submitPromise = isSubmitted
      ? submitWithTracking(selectedValues)
      : Promise.resolve(onSubmit(selectedValues));

    submitPromise
      .then(() => onClose())
      .catch((err) => {
        notifyError(
          err?.message || 'Failed to submit convert PPE to RFQ reasons'
        );
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='confirmation-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='confirmation-dialog-title'>
        <Title
          contentTitle={'Reason for Converting to Manual RFQ'}
          size='small'
        />
      </DialogTitle>
      <div style={{ padding: '10px 0 20px' }}>
        <DialogContent style={{ padding: '0 30px' }}>
          <FormGroup style={{ paddingBottom: '20px' }}>
            {CONVERT_PPE_TO_RFQ_REASONS_OPTIONS?.map((option, index) => (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkStatus(option.label)}
                      onChange={handleChangeCheckbox}
                      name={option.label}
                    />
                  }
                  label={option.label}
                  disabled={hasSubmittedWithReason}
                />
                {option.label === CONVERT_PPE_TO_RFQ_REASONS.OTHERS &&
                  checkStatus(option.label) && (
                    <Input
                      autoFocus
                      className={classes.otherInput}
                      value={otherValue}
                      onChange={({ target }) =>
                        handleChangeOtherValue(target.value)
                      }
                      disabled={hasSubmittedWithReason}
                    />
                  )}
              </div>
            ))}
          </FormGroup>
          <FlexRowCenter>
            <FtrButton onClick={onClose} color='red'>
              Cancel
            </FtrButton>
            <Tooltip title={disableMessage()} arrow>
              <div>
                <FtrButton
                  onClick={handleSubmitButtonClick}
                  disabled={disableMessage() || hasSubmittedWithReason}
                  color='blue'
                  loading={isLoading}
                >
                  Submit
                </FtrButton>
              </div>
            </Tooltip>
          </FlexRowCenter>
        </DialogContent>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default ConvertPpeToRfqReasonPopup;
