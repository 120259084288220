import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { get, cloneDeep, set } from 'lodash';

import { makeStyles } from '@material-ui/core/styles/index';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Checkbox, FormControlLabel, List } from '@material-ui/core';

import BlueButton from '../../components/buttons/BlueButton';
import YesNoPopup from '../../components/popups/YesNoPopup';
import VersioningCustomerInputPopup from '../../components/popups/VersioningCustomerInputPopup';
import {
  AddNewInput,
  AddNewInput2Options,
  AddNewInputCheckbox,
  CustomerInput,
} from '../../components/ManageInputs';
import {
  getAllConfigurations,
  updateConfiguration,
} from '../../apis/configurationApi';

import { is3DTechnologyUtil } from '../../utils/customerInputUtils';
import { isNilString } from '../../utils/stringUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { is3DPTechnology } from '../../utils/itemUtils';

import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from '../../services/notificationService';

import { KEY_CONFIGURATION } from '../../constants/configurations';
import { PART_APPLICATION_TECHS } from '../../constants/NewPartConstants';
import { TECH_DESCRIPTION_MAP } from '../../constants/projectConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(3),
  },
  flex: {
    display: 'flex',
    flexWrap: 'noWrap',
    whiteSpace: 'nowrap',
    gap: '10px 15px',
    maxWidth: '100%',
    overflowX: 'auto',
  },
  list: {
    minWidth: '200px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
  },
  extraWidth: {
    minWidth: '210px',
  },
  item: {
    border: '1px solid #000',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: colors.factoremBlue,
      color: 'white !important',
    },
    padding: '8px 16px',
    color: '#000 !important',
  },
  active: {
    backgroundColor: colors.factoremBlue,
    color: 'white !important',
  },
  materialCategory: {
    '&:hover': {
      color: 'white !important',
      backgroundColor: colors.blue060,
      '& .MuiFormLabel-root ': {
        color: 'white !important',
      },
    },
  },
  activeMaterialCategory: {
    color: 'white !important',
    backgroundColor: colors.blue060,
  },
  optionMaterial: {
    '&:hover': {
      color: 'black',
      backgroundColor: colors.boxShadowBlue,
    },
  },
  activeOptionMaterial: {
    color: 'black',
    backgroundColor: colors.boxShadowBlue,
  },
  detailMaterial: {
    color: 'black !important',
    backgroundColor: colors.secondaryBlue,
    '&:hover': {
      color: 'black !important',
      backgroundColor: colors.secondaryBlue,
    },
  },
  activeDetailMaterial: {
    '&:hover': {
      color: 'black !important',
      backgroundColor: colors.secondaryBlue,
    },
  },
  surfaceFinishOptions: {
    '&:hover': {
      color: 'black !important',
      backgroundColor: colors.statusLightBlue,
    },
  },
  activeSurfaceFinishOptions: {
    color: 'black !important',
    backgroundColor: colors.statusLightBlue,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    columnGap: '1rem',
  },
  containerMenu: {
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      borderBottom: `1px solid ${colors.lightGray}`,
    },
  },
  itemMenu: {
    padding: '5px',
    textDecoration: 'none',
    color: colors.fontGrey,
    fontSize: '0.8125rem',
    cursor: 'pointer',
    '&:is(:hover, :active)': {
      backgroundColor: colors.solidBlue,
      color: colors.fontWhite,
    },
  },
  iconCheckbox: {
    '& .MuiCheckbox-root': {
      paddingLeft: '0',
    },
    '& .MuiCheckbox-colorSecondary': {
      color: 'black',
    },
    '&:hover': {
      color: 'white !important',
      '& .MuiCheckbox-colorSecondary': {
        color: 'white',
      },
    },
  },
}));

function ManageCustomerInputs() {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [surfaceColorsConfiguration, setSurfaceColorsConfiguration] = useState(
    []
  );
  const [surfaceFinish3DPConfiguration, setSurfaceFinish3DPConfiguration] =
    useState([]);
  const [surfaceFinishConfiguration, setSurfaceFinishConfiguration] = useState(
    []
  );
  const [fileTypesConfiguration, setFileTypesConfiguration] = useState([]);
  const [
    verifyItemsChecklistConfiguration,
    setVerifyItemsChecklistConfiguration,
  ] = useState([]);
  const [selectedDataPopup, setSelectedDataPopup] = useState({
    data,
    type: '',
    name: '',
    api: KEY_CONFIGURATION.SURFACE_FINISH_COLORS_CONFIGURATION,
  });
  const [selectedTechnology, setSelectedTechnology] = useState(0);
  const [selectedMaterialCategory, setSelectedMaterialCategory] = useState(0);
  const [selectedMaterial, setSelectedMaterial] = useState(0);
  const [selectedSurfaceFinish, setSelectedSurfaceFinish] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [showSupportFileTypes, setShowSupportFileTypes] = useState(false);
  const [showVerifyItemsChecklist, setShowVerifyItemsChecklist] =
    useState(false);
  const [showVersioningPopup, setShowVersioningPopup] = useState(false);
  const [listMaterialByTechnology, setListMaterialByTechnology] = useState({});

  let listMaterialByTechnologyTemp = {};

  const forOptionList = ({ options, technology }) => {
    for (const option of options) {
      // Handle list materialByTechnology
      if (!listMaterialByTechnologyTemp[technology]) {
        listMaterialByTechnologyTemp[technology] = [option.material];
      } else {
        listMaterialByTechnologyTemp[technology].push(option.material);
      }
      // Standardization of General Technology to have surface finish
      if (
        technology !== '3D Printing' &&
        option.defaultSurfaceFinish === undefined &&
        option.surfaceFinishOptions === undefined
      ) {
        option.defaultSurfaceFinish = '';
        option.surfaceFinishOptions = [];
      }
      // Standardization of all material
      if (option.description === undefined) {
        option.description = '';
      }
      if (option.datasheetUrl === undefined) {
        option.datasheetUrl = '';
      }
      if (option.recommended === undefined) {
        option.recommended = false;
      }
    }
    setListMaterialByTechnology(listMaterialByTechnologyTemp);
  };

  const forConfigList = ({ configs, technology }) => {
    for (const config of configs) {
      forOptionList({ options: config.options, technology });
    }
  };

  const forTechList = (techs) => {
    for (const tech of techs) {
      // Add a new property partApplication. For the PART_APPLICATION_TECHS CONSTANT, the default is true else false
      if (tech.partApplication === undefined) {
        tech.partApplication = PART_APPLICATION_TECHS.includes(tech.technology)
          ? true
          : false;
      }
      // Add a new property technologyDesc
      if (tech.technologyDesc === undefined) {
        tech.technologyDesc =
          TECH_DESCRIPTION_MAP[tech.technology].description ?? '';
      }
      // Add a new property isOtherTechnology
      if (tech.isOtherTechnology === undefined) {
        tech.isOtherTechnology =
          TECH_DESCRIPTION_MAP[tech.technology].others ?? true;
      }
      // Add a new property technologyLink
      if (tech.technologyLink === undefined) {
        tech.technologyLink = TECH_DESCRIPTION_MAP[tech.technology].link ?? '';
      }
      forConfigList({ configs: tech.config, technology: tech.technology });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAllConfigurations()
      .then((result) => {
        result.forEach((config) => {
          switch (config.id) {
            case KEY_CONFIGURATION.ITEM_USER_INPUT_CONFIGURATION: {
              setData(config.value);
              //Updated standardization options on ITEM_USER_INPUT_CONFIGURATION
              forTechList(config.value);
              break;
            }
            case KEY_CONFIGURATION.SURFACE_FINISH_COLORS_CONFIGURATION: {
              setSurfaceColorsConfiguration(config.value);
              break;
            }
            case KEY_CONFIGURATION[
              '3DP_SURFACE_FINISH_OPTIONS_CONFIGURATION'
            ]: {
              setSurfaceFinish3DPConfiguration(config.value);
              break;
            }
            case KEY_CONFIGURATION.SURFACE_FINISH_OPTIONS_BY_TECHNOLOGY_CONFIGURATION: {
              setSurfaceFinishConfiguration(config.value);
              break;
            }
            case KEY_CONFIGURATION.FILE_TYPES_CONFIGURATION: {
              setFileTypesConfiguration(config.value);
              break;
            }
            case KEY_CONFIGURATION.VERIFY_ITEMS_CHECKLIST_CONFIGURATION: {
              setVerifyItemsChecklistConfiguration(config.value);
              break;
            }
            default:
              break;
          }
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleUpdateItemUserInput = (newValue) => {
    setData(newValue);
    updateConfiguration({
      key: KEY_CONFIGURATION.ITEM_USER_INPUT_CONFIGURATION,
      value: newValue,
    })
      .then(() => {
        notifySuccess('Data ITEM_USER_INPUT updated successfully');
      })
      .catch(() => notifyError('Error updated data'));
  };

  const handleUpdateSurfaceFinishColor = (newValue) => {
    setSurfaceColorsConfiguration(newValue);
    updateConfiguration({
      key: KEY_CONFIGURATION.SURFACE_FINISH_COLORS_CONFIGURATION,
      value: newValue,
    })
      .then(() => {
        notifySuccess('Data SURFACE_FINISH_COLORS updated successfully');
      })
      .catch(() => notifyError('Error updated data'));
  };

  const handleUpdateSurfaceFinish3DP = (newValue) => {
    setSurfaceFinish3DPConfiguration(newValue);
    updateConfiguration({
      key: KEY_CONFIGURATION['3DP_SURFACE_FINISH_OPTIONS_CONFIGURATION'],
      value: newValue,
    })
      .then(() => {
        notifySuccess('Data 3DP_SURFACE_FINISH_OPTIONS updated successfully');
      })
      .catch(() => notifyError('Error updated data'));
  };

  const handleUpdateSurfaceFinishOptionsByTech = (newValue) => {
    setSurfaceFinishConfiguration(newValue);
    updateConfiguration({
      key: KEY_CONFIGURATION.SURFACE_FINISH_OPTIONS_BY_TECHNOLOGY_CONFIGURATION,
      value: newValue,
    })
      .then(() => {
        notifySuccess('Data SURFACE_FINISH_OPTIONS updated successfully');
      })
      .catch(() => notifyError('Error updated data'));
  };

  const handleUpdateFileTypes = (newValue) => {
    setFileTypesConfiguration(newValue);
    updateConfiguration({
      key: KEY_CONFIGURATION.FILE_TYPES_CONFIGURATION,
      value: newValue,
    })
      .then(() => {
        notifySuccess('Data SUPPORT_FILE_TYPES updated successfully');
      })
      .catch(() => notifyError('Error updated data'));
  };

  const handleUpdateVerifyItemChecklist = (newValue) => {
    setVerifyItemsChecklistConfiguration(newValue);
    updateConfiguration({
      key: KEY_CONFIGURATION.VERIFY_ITEMS_CHECKLIST_CONFIGURATION,
      value: verifyItemsChecklistConfiguration,
    })
      .then(() => {
        notifySuccess('Data VERIFY ITEMS CHECKLIST updated successfully');
      })
      .catch(() => notifyError('Error updated data'));
  };

  const listMaterialCategory = (select = data) => {
    return get(select, `[${selectedTechnology}].config`, []);
  };

  const listMaterial = (select = data) => {
    return get(
      listMaterialCategory(select),
      `[${selectedMaterialCategory}].options`,
      []
    );
  };

  const detailsMaterial = (select = data) => {
    return get(listMaterial(select), `[${selectedMaterial}]`, {});
  };

  if (isLoading) return null;

  const selectedTechnologyName = data[selectedTechnology].technology;

  const getSurfaceFinish = () => {
    if (selectedTechnologyName !== '3D Printing') {
      let indexTech = 0;
      const surfaceFinishData = surfaceFinishConfiguration?.find(
        (el, index) => {
          indexTech = index;
          return el.technologies?.includes(selectedTechnologyName);
        }
      );
      return surfaceFinishData
        ? {
            ...surfaceFinishData,
            technology: selectedTechnologyName,
            indexTech,
          }
        : null;
    }
    const technology3D =
      listMaterialCategory()[selectedMaterialCategory]['3DTechnology'];
    let indexTech = 0;
    const surfaceFinishData = surfaceFinish3DPConfiguration?.find(
      (el, index) => {
        indexTech = index;
        return el['3DTechnologies'].includes(technology3D);
      }
    );
    return surfaceFinishData
      ? {
          ...surfaceFinishData,
          technology3D,
          technology: selectedTechnologyName,
          indexTech,
        }
      : null;
  };

  const surfaceFinishColors = () => {
    let surfaceFinish = get(
      getSurfaceFinish(),
      `surfaceFinishOptions[${selectedSurfaceFinish}]`,
      ''
    );
    if (selectedTechnologyName === '3D Printing') {
      const selectSurfaceFinish =
        surfaceColorsConfiguration[1].surfaceFinishColors?.find(
          (el) => el.surfaceFinish === surfaceFinish
        );
      return selectSurfaceFinish
        ? {
            ...selectSurfaceFinish,
            surfaceFinish,
            technology: selectedTechnologyName,
          }
        : { options: {}, surfaceFinish, technology: selectedTechnologyName };
    }
    if (
      !surfaceColorsConfiguration[0].technologies.includes(
        selectedTechnologyName
      )
    ) {
      return {
        options: {},
        surfaceFinish,
        technology: selectedTechnologyName,
        includeTech: false,
      };
    }
    surfaceFinish = get(
      detailsMaterial(),
      `surfaceFinishOptions[${selectedSurfaceFinish}]`,
      null
    );

    if (surfaceFinish === null) {
      surfaceFinish = get(
        getSurfaceFinish(),
        `surfaceFinishOptions[${selectedSurfaceFinish}]`,
        ''
      );
    }
    const selectSurfaceFinish =
      surfaceColorsConfiguration[0].surfaceFinishColors?.find(
        (el) => el.surfaceFinish === surfaceFinish
      );
    return selectSurfaceFinish
      ? {
          ...selectSurfaceFinish,
          surfaceFinish,
          technology: selectedTechnologyName,
          includeTech: true,
        }
      : {
          options: {},
          surfaceFinish,
          technology: selectedTechnologyName,
          includeTech: true,
        };
  };

  const getIndexSurface = () => {
    let indexTech = 0;
    if (surfaceFinishColors().technology === '3D Printing') {
      indexTech = 1;
    } else if (!surfaceFinishColors().includeTech) {
      indexTech = 2;
    }
    const newSurfaceColorsConfiguration = JSON.parse(
      JSON.stringify(surfaceColorsConfiguration)
    );
    const indexSurfaceFinish = newSurfaceColorsConfiguration[
      indexTech
    ].surfaceFinishColors.findIndex(
      (el) => el.surfaceFinish === surfaceFinishColors().surfaceFinish
    );
    return { indexTech, indexSurfaceFinish, newSurfaceColorsConfiguration };
  };

  const handleDeleteData = () => {
    setOpenPopup(false);

    switch (selectedDataPopup.api) {
      case KEY_CONFIGURATION.ITEM_USER_INPUT_CONFIGURATION:
        handleUpdateItemUserInput(selectedDataPopup.data);
        break;
      case KEY_CONFIGURATION.SURFACE_FINISH_COLORS_CONFIGURATION:
        handleUpdateSurfaceFinishColor(selectedDataPopup.data);
        break;
      case KEY_CONFIGURATION['3DP_SURFACE_FINISH_OPTIONS_CONFIGURATION']:
        handleUpdateSurfaceFinish3DP(selectedDataPopup.data);
        break;
      case KEY_CONFIGURATION.SURFACE_FINISH_OPTIONS_BY_TECHNOLOGY_CONFIGURATION:
        handleUpdateSurfaceFinishOptionsByTech(selectedDataPopup.data);
        break;
      case KEY_CONFIGURATION.FILE_TYPES_CONFIGURATION:
        handleUpdateFileTypes(selectedDataPopup.data);
        break;
      case KEY_CONFIGURATION.VERIFY_ITEMS_CHECKLIST_CONFIGURATION:
        handleUpdateVerifyItemChecklist(selectedDataPopup.data);
        break;
      default:
        break;
    }

    switch (selectedDataPopup.type) {
      case 'Technology':
        setSelectedTechnology(0);
        setSelectedMaterialCategory(0);
        setSelectedMaterial(0);
        setSelectedSurfaceFinish(0);
        break;
      case 'Material Category':
      case '3D Technology':
        setSelectedMaterialCategory(0);
        setSelectedMaterial(0);
        setSelectedSurfaceFinish(0);
        break;
      case 'material':
        setSelectedMaterial(0);
        setSelectedSurfaceFinish(0);
        break;
      default:
        break;
    }
  };

  // Function to update position item
  const handleDragList = (droppedItem, updatedList) => {
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    return updatedList;
  };

  // Function to update list on drop file types
  const handleDropTechnology = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    let newListTechnology = cloneDeep(data);
    newListTechnology = handleDragList(droppedItem, newListTechnology);
    handleUpdateItemUserInput(newListTechnology);
  };

  // Function to update list on drop material category
  const handleDropMaterialCategory = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    let newData = cloneDeep(data);
    let updatedData = newData[selectedTechnology].config;
    updatedData = handleDragList(droppedItem, updatedData);
    newData[selectedTechnology].config = updatedData;
    handleUpdateItemUserInput(newData);
  };

  // Function to update list on drop material
  const handleDropMaterial = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    let newData = cloneDeep(data);
    let updatedData =
      newData[selectedTechnology].config[selectedMaterialCategory].options;
    updatedData = handleDragList(droppedItem, updatedData);
    newData[selectedTechnology].config[selectedMaterialCategory].options =
      updatedData;
    handleUpdateItemUserInput(newData);
  };

  // Function to update list on drop surface color
  const handleDropSurfaceColor = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const { indexTech, indexSurfaceFinish, newSurfaceColorsConfiguration } =
      getIndexSurface();
    let colorOptions =
      newSurfaceColorsConfiguration[indexTech].surfaceFinishColors[
        indexSurfaceFinish
      ].options;
    let updatedData = Object.entries(colorOptions);
    updatedData = handleDragList(droppedItem, updatedData);
    const updatedColors = Object.fromEntries(updatedData);
    newSurfaceColorsConfiguration[indexTech].surfaceFinishColors[
      indexSurfaceFinish
    ].options = updatedColors;
    handleUpdateSurfaceFinishColor(newSurfaceColorsConfiguration);
  };

  // Function to update list on drop surface finish
  const handleDropSurfaceFinish = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const { indexTech, technology } = getSurfaceFinish();
    if (technology === '3D Printing') {
      const newSurfaceFinish3DP = cloneDeep(surfaceFinish3DPConfiguration);
      let updatedData = newSurfaceFinish3DP[indexTech].surfaceFinishOptions;
      updatedData = handleDragList(droppedItem, updatedData);
      newSurfaceFinish3DP[indexTech].surfaceFinishOptions = updatedData;
      handleUpdateSurfaceFinish3DP(newSurfaceFinish3DP);
      return;
    }
    const newSurfaceFinish = cloneDeep(surfaceFinishConfiguration);
    let updatedData = newSurfaceFinish[indexTech].surfaceFinishOptions;
    updatedData = handleDragList(droppedItem, updatedData);
    newSurfaceFinish[indexTech].surfaceFinishOptions = updatedData;
    handleUpdateSurfaceFinishOptionsByTech(newSurfaceFinish);
  };

  // Function to update list on drop surface finish
  const handleDropSurfaceFinishItem = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    let newData = cloneDeep(data);
    let updatedData =
      newData[selectedTechnology].config[selectedMaterialCategory].options[
        selectedMaterial
      ].surfaceFinishOptions;
    updatedData = handleDragList(droppedItem, updatedData);
    newData[selectedTechnology].config[selectedMaterialCategory].options[
      selectedMaterial
    ].surfaceFinishOptions = updatedData;
    handleUpdateItemUserInput(newData);
  };

  // Function to update list on drop file types
  const handleDropFileTypes = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const newFileTypes = cloneDeep(fileTypesConfiguration);
    let updatedList = [
      ...newFileTypes[selectedTechnologyName].supportFileTypes,
    ];
    updatedList = handleDragList(droppedItem, updatedList);
    // Update State
    newFileTypes[selectedTechnologyName].supportFileTypes = updatedList;
    handleUpdateFileTypes(newFileTypes);
  };

  // Function to update list on drop verify items checklist
  const handleDropVerifyItemsChecklist = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const newVerifyItemsChecklist = cloneDeep(
      verifyItemsChecklistConfiguration
    );
    let updatedList = [...newVerifyItemsChecklist[selectedTechnologyName]];
    // update position item
    updatedList = handleDragList(droppedItem, updatedList);
    // Update State
    newVerifyItemsChecklist[selectedTechnologyName] = updatedList;
    handleUpdateVerifyItemChecklist(newVerifyItemsChecklist);
  };

  const handleAddNewMaterial = (materialName = '') => {
    let tempData = cloneDeep(data);
    const newMaterial = {
      material: materialName,
      description: '',
      datasheetUrl: '',
      defaultColor: '',
      materialColorOptions: {},
    };
    const technology = data[selectedTechnology].technology;
    if (technology !== '3D Printing') {
      newMaterial.surfaceFinishOptions = [];
      newMaterial.defaultSurfaceFinish = '';
      if (materialName === 'Custom Material') {
        newMaterial.surfaceFinishOptions = ['Custom Finish'];
        newMaterial.defaultSurfaceFinish = 'Custom Finish';
      }
    }
    tempData[selectedTechnology].config[selectedMaterialCategory].options.push(
      newMaterial
    );
    handleUpdateItemUserInput(tempData);
  };

  const handleAddSurfaceFinish = (newSurfaceFinish = '') => {
    let tempData = cloneDeep(data);
    tempData[selectedTechnology].config[selectedMaterialCategory].options[
      selectedMaterial
    ].surfaceFinishOptions.push(newSurfaceFinish);
    handleUpdateItemUserInput(tempData);
  };

  const handleAddSurfaceFinishItem = (newSurfaceFinish = '') => {
    const { indexTech, technology } = getSurfaceFinish();
    const dataSurfaceFinish =
      technology === '3D Printing'
        ? surfaceFinish3DPConfiguration
        : surfaceFinishConfiguration;
    const newDataSurfaceFinish = cloneDeep(dataSurfaceFinish);
    newDataSurfaceFinish[indexTech].surfaceFinishOptions.push(newSurfaceFinish);
    if (technology === '3D Printing') {
      handleUpdateSurfaceFinish3DP(newDataSurfaceFinish);
    } else {
      handleUpdateSurfaceFinishOptionsByTech(newDataSurfaceFinish);
    }
  };

  const handleAddMaterialCategory = (type = 'default') => {
    let tempData = cloneDeep(data);
    let options = {
      material: '',
      description: '',
      datasheetUrl: '',
      defaultColor: '',
      materialColorOptions: {},
    };
    let newMaterialCategory = {
      options: [options],
      materialCategory: '',
    };
    const technology = data[selectedTechnology].technology;
    if (technology !== '3D Printing') {
      options.surfaceFinishOptions = [];
      options.defaultSurfaceFinish = '';
      if (type === 'custom') {
        options.surfaceFinishOptions = ['Custom Finish'];
        options.defaultSurfaceFinish = 'Custom Finish';
      }
    }
    if (type === 'custom') {
      newMaterialCategory.materialCategory = 'Custom';
      options.material = 'Custom Material';
      newMaterialCategory.options = [options];
    }
    tempData[selectedTechnology].config.push(newMaterialCategory);
    handleUpdateItemUserInput(tempData);
  };

  function isCustomSurfaceFinishExist() {
    const { indexTech, technology } = getSurfaceFinish();
    const dataSurfaceFinish =
      technology === '3D Printing'
        ? surfaceFinish3DPConfiguration
        : surfaceFinishConfiguration;
    const isCustomFinishExist = dataSurfaceFinish[
      indexTech
    ].surfaceFinishOptions?.find(
      (surfaceFinish) => surfaceFinish === 'Custom Finish'
    );
    return isCustomFinishExist ? true : false;
  }

  const surfaceFinishOptionsTemp = detailsMaterial().surfaceFinishOptions || [];

  const conditionalSurfaceFinish =
    surfaceFinishOptionsTemp[0] !== 'Custom Finish' || !getSurfaceFinish();

  const conditionalSurfaceFinish2 =
    surfaceFinishOptionsTemp[0] === 'Custom Finish' ||
    !detailsMaterial().surfaceFinishOptions;

  return (
    <div className={classes.body}>
      <div className={classes.buttons}>
        <BlueButton
          btnContent='Versioning'
          onBtnClick={() => setShowVersioningPopup(true)}
        />
        {showVersioningPopup && (
          <VersioningCustomerInputPopup
            open={showVersioningPopup}
            onClose={() => setShowVersioningPopup(false)}
            data={[
              {
                key: KEY_CONFIGURATION.ITEM_USER_INPUT_CONFIGURATION,
                value: data,
              },
              {
                key: KEY_CONFIGURATION.SURFACE_FINISH_COLORS_CONFIGURATION,
                value: surfaceColorsConfiguration,
              },
              {
                key: KEY_CONFIGURATION[
                  '3DP_SURFACE_FINISH_OPTIONS_CONFIGURATION'
                ],
                value: surfaceFinish3DPConfiguration,
              },
              {
                key: KEY_CONFIGURATION.SURFACE_FINISH_OPTIONS_BY_TECHNOLOGY_CONFIGURATION,
                value: surfaceFinishConfiguration,
              },
              {
                key: KEY_CONFIGURATION.FILE_TYPES_CONFIGURATION,
                value: fileTypesConfiguration,
              },
              {
                key: KEY_CONFIGURATION.VERIFY_ITEMS_CHECKLIST_CONFIGURATION,
                value: verifyItemsChecklistConfiguration,
              },
            ]}
          />
        )}
      </div>
      <div className={classes.flex}>
        <List className={classes.list}>
          <h4 style={{ textAlign: 'center' }}>Technology</h4>
          <DragDropContext onDragEnd={handleDropTechnology}>
            <Droppable droppableId='list-technology'>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={classes.list}
                >
                  {data?.map((el, i) => (
                    <Draggable
                      key={`${i}${el.technology}`}
                      name={`${el.technology}`}
                      draggableId={String(i)}
                      index={i}
                    >
                      {(_provided) => (
                        <CustomerInput
                          providedDrag={_provided}
                          className={clsx(
                            classes.item,
                            selectedTechnology === i && classes.active
                          )}
                          onClick={() => {
                            setSelectedTechnology(i);
                            setSelectedMaterialCategory(0);
                            setSelectedMaterial(0);
                            setSelectedSurfaceFinish(0);
                          }}
                          onOk={(value) => {
                            if (value === el.technology) {
                              return;
                            }
                            const newData = [...data];
                            const newSurfaceColorsConfiguration = JSON.parse(
                              JSON.stringify(surfaceColorsConfiguration)
                            );
                            let indexTech = 0;
                            if (
                              surfaceFinishColors().technology === '3D Printing'
                            ) {
                              indexTech = 1;
                            }
                            const technology = data[i].technology;
                            const index = newSurfaceColorsConfiguration[
                              indexTech
                            ].technologies.findIndex(
                              (_el) => _el === technology
                            );
                            newSurfaceColorsConfiguration[
                              indexTech
                            ].technologies[index] = value;
                            handleUpdateSurfaceFinishColor(
                              newSurfaceColorsConfiguration
                            );
                            newData[i].technology = value;
                            handleUpdateItemUserInput(newData);
                          }}
                          onDelete={(value) => {
                            const newData = data?.filter(
                              (_el, iTemp) => iTemp !== i
                            );
                            setSelectedDataPopup({
                              data: newData,
                              type: 'Technology',
                              name: value,
                              api: KEY_CONFIGURATION.ITEM_USER_INPUT_CONFIGURATION,
                            });
                            setOpenPopup(true);
                          }}
                          value={el.technology}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <AddNewInput
            className={clsx(classes.item, classes.materialCategory)}
            onClick={() => {
              const newSurfaceColorsConfiguration = cloneDeep(
                surfaceColorsConfiguration
              );
              newSurfaceColorsConfiguration[0].technologies.push('');
              handleUpdateSurfaceFinishColor(newSurfaceColorsConfiguration);
              handleUpdateItemUserInput((prev) => [
                ...prev,
                {
                  config: [
                    {
                      technology: '',
                      options: [
                        {
                          material: '',
                          description: '',
                          datasheetUrl: '',
                          defaultColor: '',
                          materialColorOptions: {},
                          surfaceFinishOptions: [],
                          defaultSurfaceFinish: '',
                        },
                      ],
                    },
                  ],
                  technology: '',
                  partApplication: false,
                  technologyDesc: '',
                  isOtherTechnology: true,
                  technologyLink: '',
                },
              ]);
            }}
          />
          <FormControlLabel
            className={clsx(classes.item, classes.iconCheckbox)}
            style={{
              margin: '0',
              paddingLeft: '14px',
              background: colors.buttonColorBlueGradient,
              whiteSpace: 'normal',
            }}
            control={
              <Checkbox
                checked={showSupportFileTypes}
                onChange={() => {
                  setShowSupportFileTypes(!showSupportFileTypes);
                }}
                name={'showSupportedFileTypes'}
              />
            }
            label={'Show Supported File Types'}
          />
          <FormControlLabel
            className={clsx(classes.item, classes.iconCheckbox)}
            style={{
              margin: '0',
              paddingLeft: '14px',
              background: colors.buttonColorBlueGradient,
              whiteSpace: 'normal',
            }}
            control={
              <Checkbox
                checked={showVerifyItemsChecklist}
                onChange={() => {
                  setShowVerifyItemsChecklist(!showVerifyItemsChecklist);
                }}
                name={'showVerifyItemsChecklist'}
              />
            }
            label={'Show Verify Items Checklist'}
          />
        </List>

        {showSupportFileTypes && (
          <List className={classes.list}>
            <h4 style={{ textAlign: 'center' }}>Supported File Types</h4>
            <CustomerInput
              type='text-field'
              name='supported-file-type'
              value={
                fileTypesConfiguration[selectedTechnologyName]?.description ||
                ''
              }
              keyName='File Type Description'
              disabledDelete
              onOk={(value) => {
                const newFileTypes = cloneDeep(fileTypesConfiguration);
                const fileTypeTech = newFileTypes[selectedTechnologyName];
                if (!fileTypeTech) {
                  newFileTypes[selectedTechnologyName] = {
                    supportFileTypes: ['All File Types'],
                    description: '',
                  };
                } else {
                  newFileTypes[selectedTechnologyName].description = value;
                }
                handleUpdateFileTypes(newFileTypes);
              }}
            />
            {fileTypesConfiguration[selectedTechnologyName] && (
              <DragDropContext onDragEnd={handleDropFileTypes}>
                <Droppable droppableId='list-file-types'>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={classes.list}
                    >
                      {fileTypesConfiguration[
                        selectedTechnologyName
                      ].supportFileTypes?.map((fileType, i) => (
                        <Draggable
                          key={i + selectedTechnology}
                          draggableId={String(i)}
                          index={i}
                        >
                          {(_provided) => (
                            <CustomerInput
                              name='all-file-types'
                              disabledEdit={fileType === 'All File Types'}
                              providedDrag={_provided}
                              onOk={(value) => {
                                const newFileTypes = cloneDeep(
                                  fileTypesConfiguration
                                );
                                newFileTypes[
                                  selectedTechnologyName
                                ].supportFileTypes[i] = value;
                                handleUpdateFileTypes(newFileTypes);
                              }}
                              value={fileType}
                              className={clsx(
                                classes.item,
                                classes.surfaceFinishOptions
                              )}
                              onDelete={(value) => {
                                const newFileTypes = cloneDeep(
                                  fileTypesConfiguration
                                );
                                newFileTypes[
                                  selectedTechnologyName
                                ].supportFileTypes.splice(i, 1);
                                setSelectedDataPopup({
                                  data: newFileTypes,
                                  name: value,
                                  type: 'Support File Types',
                                  api: KEY_CONFIGURATION.FILE_TYPES_CONFIGURATION,
                                });
                                setOpenPopup(true);
                              }}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            <AddNewInputCheckbox
              className={clsx(classes.item, classes.materialCategory)}
              checkboxName='All-Files-Types'
              checkboxLabel='All File Types'
              checked={fileTypesConfiguration[
                selectedTechnologyName
              ]?.supportFileTypes?.includes('All File Types')}
              disablePlusButton={fileTypesConfiguration[
                selectedTechnologyName
              ]?.supportFileTypes?.includes('All File Types')}
              onChangeCheckbox={() => {
                const newFileTypes = cloneDeep(fileTypesConfiguration);
                const fileTypeTech = newFileTypes[selectedTechnologyName];
                if (!fileTypeTech) {
                  newFileTypes[selectedTechnologyName] = {
                    supportFileTypes: ['All File Types'],
                    description: '',
                  };
                } else {
                  const isChecked =
                    fileTypeTech?.supportFileTypes?.includes('All File Types');
                  newFileTypes[selectedTechnologyName].supportFileTypes =
                    isChecked ? [''] : ['All File Types'];
                }
                handleUpdateFileTypes(newFileTypes);
              }}
              onAdd={() => {
                const newFileTypes = cloneDeep(fileTypesConfiguration);
                const fileTypeTech = newFileTypes[selectedTechnologyName];
                if (!fileTypeTech) {
                  newFileTypes[selectedTechnologyName] = {
                    supportFileTypes: [''],
                    description: '',
                  };
                  handleUpdateFileTypes(newFileTypes);
                  return;
                }
                if (
                  fileTypeTech?.supportFileTypes?.includes('All File Types')
                ) {
                  notifyInfo('Already include all types!');
                  return;
                }
                newFileTypes[selectedTechnologyName].supportFileTypes.push('');
                handleUpdateFileTypes(newFileTypes);
              }}
            />
          </List>
        )}

        {showVerifyItemsChecklist && (
          <List className={classes.list}>
            <h4 style={{ textAlign: 'center' }}>Verify Items Checklist</h4>
            {verifyItemsChecklistConfiguration[selectedTechnologyName] && (
              <DragDropContext onDragEnd={handleDropVerifyItemsChecklist}>
                <Droppable droppableId='list-verify-items-checklist'>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={classes.list}
                    >
                      {verifyItemsChecklistConfiguration[
                        selectedTechnologyName
                      ]?.map((item, i) => (
                        <Draggable
                          key={i + selectedTechnology}
                          draggableId={String(i)}
                          index={i}
                        >
                          {(_provided) => (
                            <CustomerInput
                              name={`${i}.verify-items-checklist`}
                              providedDrag={_provided}
                              onOk={(value) => {
                                const newVerifyItemsChecklist = cloneDeep(
                                  verifyItemsChecklistConfiguration
                                );
                                newVerifyItemsChecklist[selectedTechnologyName][
                                  i
                                ] = value;
                                handleUpdateVerifyItemChecklist(
                                  newVerifyItemsChecklist
                                );
                              }}
                              value={item}
                              className={clsx(
                                classes.item,
                                classes.surfaceFinishOptions
                              )}
                              onDelete={(value) => {
                                const newVerifyItemsChecklist = cloneDeep(
                                  verifyItemsChecklistConfiguration
                                );
                                newVerifyItemsChecklist[
                                  selectedTechnologyName
                                ].splice(i, 1);
                                setSelectedDataPopup({
                                  data: newVerifyItemsChecklist,
                                  name: value,
                                  type: 'Verify Items Checklist',
                                  api: KEY_CONFIGURATION.VERIFY_ITEMS_CHECKLIST_CONFIGURATION,
                                });
                                setOpenPopup(true);
                              }}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            <AddNewInput
              className={clsx(classes.item, classes.materialCategory)}
              onClick={() => {
                const newVerifyItemsChecklist = cloneDeep(
                  verifyItemsChecklistConfiguration
                );
                if (!newVerifyItemsChecklist[selectedTechnologyName]) {
                  newVerifyItemsChecklist[selectedTechnologyName] = [''];
                } else {
                  newVerifyItemsChecklist[selectedTechnologyName].push('');
                }
                handleUpdateVerifyItemChecklist(newVerifyItemsChecklist);
              }}
            />
          </List>
        )}

        <List className={classes.list}>
          <h4 style={{ textAlign: 'center' }}>
            {is3DTechnologyUtil(data, 0)
              ? '3D Technology'
              : 'Material Category'}
          </h4>
          <DragDropContext onDragEnd={handleDropMaterialCategory}>
            <Droppable droppableId='list-material-category'>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={classes.list}
                >
                  {listMaterialCategory()?.map((el, i) => (
                    <Draggable
                      key={`${i}${selectedTechnology}`}
                      draggableId={String(i)}
                      index={i}
                    >
                      {(_provided) => (
                        <CustomerInput
                          name={`${i}.material-category.${el}`}
                          providedDrag={_provided}
                          onClick={() => {
                            setSelectedMaterialCategory(i);
                            setSelectedMaterial(0);
                            setSelectedSurfaceFinish(0);
                          }}
                          onOk={(value) => {
                            const newData = cloneDeep(data);
                            newData[selectedTechnology].config[i][
                              is3DTechnologyUtil(data, selectedTechnology)
                                ? '3DTechnology'
                                : 'materialCategory'
                            ] = value;
                            handleUpdateItemUserInput(newData);
                          }}
                          onDelete={(value) => {
                            const newData = JSON.parse(JSON.stringify(data));
                            const filteredData = listMaterialCategory()?.filter(
                              (_el, iTemp) => iTemp !== i
                            );
                            newData[selectedTechnology].config = filteredData;
                            setSelectedDataPopup({
                              data: newData,
                              type: el['3DTechnology']
                                ? '3D Technology'
                                : 'Material Category',
                              name: value,
                              api: KEY_CONFIGURATION.ITEM_USER_INPUT_CONFIGURATION,
                            });
                            setOpenPopup(true);
                          }}
                          value={
                            el['3DTechnology']
                              ? el['3DTechnology']
                              : el.materialCategory
                          }
                          className={clsx(
                            classes.item,
                            classes.materialCategory,
                            selectedMaterialCategory === i &&
                              classes.activeMaterialCategory
                          )}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <AddNewInput2Options
            label1={`Add ${
              is3DTechnologyUtil(data, selectedTechnology)
                ? '3D Technology'
                : 'Material Category'
            }`}
            onClick1={() => handleAddMaterialCategory()}
            label2='Add Custom Material Category'
            onClick2={() => handleAddMaterialCategory('custom')}
            disableLabel2={
              is3DTechnologyUtil(data, selectedTechnology) ||
              listMaterialCategory()?.find(
                (el) => el.materialCategory === 'Custom'
              )
            }
          />
        </List>
        <List className={classes.list}>
          <h4 style={{ textAlign: 'center' }}>Material</h4>
          <DragDropContext onDragEnd={handleDropMaterial}>
            <Droppable droppableId='list-material'>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={classes.list}
                >
                  {listMaterial()?.map((el, i) => (
                    <Draggable
                      key={`${i}${selectedTechnology}${selectedMaterialCategory}`}
                      draggableId={String(i)}
                      index={i}
                    >
                      {(_provided) => (
                        <CustomerInput
                          name={`${i}.material.${el}`}
                          providedDrag={_provided}
                          onClick={() => {
                            setSelectedMaterial(i);
                            setSelectedSurfaceFinish(0);
                          }}
                          onOk={(value) => {
                            if (el.material === value) {
                              return;
                            }
                            const newData = [...data];
                            newData[selectedTechnology].config[
                              selectedMaterialCategory
                            ].options[i].material = value;
                            const options =
                              newData[selectedTechnology].config[
                                selectedMaterialCategory
                              ].options;
                            forOptionList({
                              options,
                              technology: selectedTechnologyName,
                            });
                            handleUpdateItemUserInput(newData);
                          }}
                          value={el.material}
                          className={clsx(
                            classes.item,
                            classes.optionMaterial,
                            selectedMaterial === i &&
                              classes.activeOptionMaterial
                          )}
                          onDelete={(value) => {
                            const newData = JSON.parse(JSON.stringify(data));
                            const filteredData = listMaterial()?.filter(
                              (_el, iTemp) => iTemp !== i
                            );
                            newData[selectedTechnology].config[
                              selectedMaterialCategory
                            ].options = filteredData;
                            setSelectedDataPopup({
                              data: newData,
                              type: 'Material',
                              name: value,
                              api: KEY_CONFIGURATION.ITEM_USER_INPUT_CONFIGURATION,
                            });
                            setOpenPopup(true);
                          }}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <AddNewInput2Options
            label1='Add Material'
            onClick1={() => handleAddNewMaterial()}
            label2='Add Custom Material'
            onClick2={() => handleAddNewMaterial('Custom Material')}
            disableLabel2={data[selectedTechnology].config[
              selectedMaterialCategory
            ].options?.find(
              (material) => material.material === 'Custom Material'
            )}
          />
        </List>
        <List className={`${classes.list} ${classes.extraWidth}`}>
          <h4 style={{ textAlign: 'center' }}>Details</h4>
          <CustomerInput
            name='technology-description'
            disabledDelete
            onOk={(newValue) => {
              const newData = cloneDeep(data);
              newData[selectedTechnology].technologyDesc = newValue;
              handleUpdateItemUserInput(newData);
            }}
            keyName='Technology Description'
            value={data[selectedTechnology].technologyDesc}
            type='text-field'
            className={`${classes.item} ${classes.detailMaterial} ${classes.activeDetailMaterial}`}
          />
          <CustomerInput
            name='technology-link'
            disabledDelete
            onOk={(newValue) => {
              const newData = cloneDeep(data);
              newData[selectedTechnology].technologyLink = newValue;
              handleUpdateItemUserInput(newData);
            }}
            keyName='Technology Link'
            value={data[selectedTechnology].technologyLink}
            type='text-field'
            className={`${classes.item} ${classes.detailMaterial} ${classes.activeDetailMaterial}`}
          />
          <CustomerInput
            name='is-other-technology'
            disabledDelete
            listMenu={['true', 'false']}
            onOk={(newValue) => {
              const newData = cloneDeep(data);
              newData[selectedTechnology].isOtherTechnology =
                newValue === 'true' ? true : false;
              handleUpdateItemUserInput(newData);
            }}
            keyName='Is Other Technology'
            value={data[selectedTechnology].isOtherTechnology}
            type='select'
            className={`${classes.item} ${classes.detailMaterial} ${classes.activeDetailMaterial}`}
          />
          {Object.keys(detailsMaterial())
            ?.filter(
              (keyName) =>
                ![
                  'surfaceFinishOptions',
                  'materialColorOptions',
                  'finishDisplayMapping',
                ].includes(keyName)
            )
            ?.map((keyName, i) => {
              const listMenu = {
                defaultColor: Object.keys(
                  get(detailsMaterial(), ['materialColorOptions'], {})
                ),
                defaultSurfaceFinish:
                  detailsMaterial().surfaceFinishOptions || [],
                recommended: ['true', 'false'],
              };
              const value =
                keyName === 'defaultSurfaceFinish'
                  ? detailsMaterial().defaultSurfaceFinish
                  : detailsMaterial()[keyName];

              const key =
                i +
                selectedTechnology +
                selectedMaterialCategory +
                selectedMaterial +
                keyName;

              return (
                <CustomerInput
                  key={key}
                  name={key}
                  disabledDelete
                  listMenu={listMenu[keyName]}
                  onOk={(newValue) => {
                    const newData = cloneDeep(data);
                    newData[selectedTechnology].config[
                      selectedMaterialCategory
                    ].options[selectedMaterial][keyName] =
                      keyName === 'recommended'
                        ? newValue === 'true'
                          ? true
                          : false
                        : newValue;
                    handleUpdateItemUserInput(newData);
                  }}
                  keyName={keyName}
                  value={value}
                  type={
                    [
                      'defaultSurfaceFinish',
                      'defaultColor',
                      'recommended',
                    ].includes(keyName)
                      ? 'select'
                      : 'text-field'
                  }
                  className={`${classes.item} ${classes.detailMaterial} ${classes.activeDetailMaterial}`}
                />
              );
            })}
          <CustomerInput
            name='default-material'
            listMenu={listMaterialByTechnology[selectedTechnologyName]}
            disabledDelete
            keyName='defaultMaterial'
            value={
              listMaterialCategory()[selectedMaterialCategory] &&
              listMaterialCategory()[selectedMaterialCategory]['3DTechnology']
                ? listMaterialCategory()[selectedMaterialCategory]
                    .defaultMaterial
                : data[selectedTechnology].defaultMaterial
            }
            type='select'
            onOk={(value) => {
              const newData = [...data];
              if (
                listMaterialCategory()[selectedMaterialCategory]['3DTechnology']
              ) {
                newData[selectedTechnology].config[
                  selectedMaterialCategory
                ].defaultMaterial = value;
              } else {
                newData[selectedTechnology].defaultMaterial = value;
              }
              handleUpdateItemUserInput(newData);
            }}
            className={`${classes.item} ${classes.detailMaterial} ${classes.activeDetailMaterial}`}
          />
          {/* Select surface finish */}
          {getSurfaceFinish() && conditionalSurfaceFinish2 && (
            <CustomerInput
              name='default-surface-finish'
              listMenu={getSurfaceFinish().surfaceFinishOptions}
              disabledDelete
              keyName='defaultSurfaceFinish'
              value={getSurfaceFinish().defaultSurfaceFinish}
              type='select'
              onOk={(value) => {
                const { indexTech, technology } = getSurfaceFinish();
                if (technology === '3D Printing') {
                  const newSurfaceFinish3DP = JSON.parse(
                    JSON.stringify(surfaceFinish3DPConfiguration)
                  );
                  newSurfaceFinish3DP[indexTech].defaultSurfaceFinish = value;
                  handleUpdateSurfaceFinish3DP(newSurfaceFinish3DP);
                  return;
                }
                const newSurfaceFinish = JSON.parse(
                  JSON.stringify(surfaceFinishConfiguration)
                );
                newSurfaceFinish[indexTech].defaultSurfaceFinish = value;
                handleUpdateSurfaceFinishOptionsByTech(newSurfaceFinish);
              }}
              className={`${classes.item} ${classes.detailMaterial} ${classes.activeDetailMaterial}`}
            />
          )}
          {/* Select material colors */}
          {surfaceFinishColors() &&
            Object.keys(surfaceFinishColors().options).length !== 0 && (
              <CustomerInput
                name='default-surface-color'
                listMenu={Object.keys(surfaceFinishColors().options)}
                disabledDelete
                keyName='defaultSurfaceColor'
                value={surfaceFinishColors().defaultColor}
                type='select'
                onOk={(value) => {
                  const {
                    indexTech,
                    indexSurfaceFinish,
                    newSurfaceColorsConfiguration,
                  } = getIndexSurface();
                  newSurfaceColorsConfiguration[indexTech].surfaceFinishColors[
                    indexSurfaceFinish
                  ].defaultColor = value;
                  handleUpdateSurfaceFinishColor(newSurfaceColorsConfiguration);
                }}
                className={`${classes.item} ${classes.detailMaterial} ${classes.activeDetailMaterial}`}
              />
            )}
          <CustomerInput
            name='part-application'
            key={selectedTechnology}
            disabledDelete
            listMenu={['true', 'false']}
            onOk={(value) => {
              const newData = cloneDeep(data);
              newData[selectedTechnology].partApplication =
                value === 'true' ? true : false;
              handleUpdateItemUserInput(newData);
            }}
            keyName={'Part Application'}
            value={data[selectedTechnology].partApplication}
            type={'select'}
            className={`${classes.item} ${classes.detailMaterial} ${classes.activeDetailMaterial}`}
          />
        </List>

        {detailsMaterial().surfaceFinishOptions && conditionalSurfaceFinish && (
          <List className={classes.list}>
            <h4 style={{ textAlign: 'center' }}>Surface Finish Options</h4>
            <DragDropContext onDragEnd={handleDropSurfaceFinishItem}>
              <Droppable droppableId='list-surface-finish-item'>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={classes.list}
                  >
                    {detailsMaterial().surfaceFinishOptions?.map((el, i) => (
                      <Draggable
                        key={`${i}.${selectedTechnology}.${selectedMaterialCategory}.${selectedMaterial}`}
                        draggableId={String(i)}
                        index={i}
                      >
                        {(_provided) => (
                          <CustomerInput
                            name={`${i}.surfaceFinishOptions.${el}`}
                            providedDrag={_provided}
                            onOk={(value, additionalValue) => {
                              const newData = [...data];
                              const selectedMaterialConfig =
                                newData[selectedTechnology].config[
                                  selectedMaterialCategory
                                ].options[selectedMaterial];
                              selectedMaterialConfig.surfaceFinishOptions[i] =
                                value;
                              if (isNilString(el)) {
                                set(
                                  selectedMaterialConfig,
                                  ['finishDisplayMapping', value],
                                  {
                                    displayText:
                                      additionalValue?.displayText || '',
                                    tooltip: additionalValue?.tooltip || '',
                                  }
                                );
                              }
                              handleUpdateItemUserInput(newData);
                            }}
                            onClick={() => {
                              setSelectedSurfaceFinish(i);
                            }}
                            value={el}
                            displayText={
                              isNilString(el)
                                ? get(
                                    detailsMaterial().finishDisplayMapping,
                                    [el, 'displayText'],
                                    ''
                                  )
                                : undefined
                            }
                            tooltip={
                              isNilString(el)
                                ? get(
                                    detailsMaterial().finishDisplayMapping,
                                    [el, 'tooltip'],
                                    ''
                                  )
                                : undefined
                            }
                            className={clsx(
                              classes.item,
                              classes.surfaceFinishOptions,
                              selectedSurfaceFinish === i &&
                                classes.activeSurfaceFinishOptions
                            )}
                            onDelete={(value) => {
                              const newData = JSON.parse(JSON.stringify(data));
                              const filteredData =
                                detailsMaterial().surfaceFinishOptions?.filter(
                                  (_el, iTemp) => iTemp !== i
                                );
                              newData[selectedTechnology].config[
                                selectedMaterialCategory
                              ].options[selectedMaterial].surfaceFinishOptions =
                                filteredData;
                              setSelectedDataPopup({
                                data: newData,
                                name: value,
                                type: 'Surface Finish Options',
                                api: KEY_CONFIGURATION.ITEM_USER_INPUT_CONFIGURATION,
                              });
                              setOpenPopup(true);
                            }}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <AddNewInput2Options
              label1='Add Surface Finish'
              onClick1={() => handleAddSurfaceFinish()}
              label2='Add Custom Surface Finish'
              onClick2={() => handleAddSurfaceFinish('Custom Finish')}
              disableLabel2={data[selectedTechnology].config[
                selectedMaterialCategory
              ].options[selectedMaterial].surfaceFinishOptions?.find(
                (surfaceFinish) => surfaceFinish === 'Custom Finish'
              )}
            />
          </List>
        )}

        {/* This is for 3DP only? */}
        {getSurfaceFinish() && conditionalSurfaceFinish2 && (
          <List className={classes.list}>
            <h4 style={{ textAlign: 'center' }}>Surface Finish Options</h4>
            <DragDropContext onDragEnd={handleDropSurfaceFinish}>
              <Droppable droppableId='list-verify-items-checklist'>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={classes.list}
                  >
                    {getSurfaceFinish().surfaceFinishOptions?.map((el, i) => (
                      <Draggable
                        key={`${i}.${selectedTechnology}.${selectedMaterialCategory}.${selectedMaterial}`}
                        draggableId={String(i)}
                        index={i}
                      >
                        {(_provided) => (
                          <CustomerInput
                            name={`${i}.surfaceFinishOptions2.${el}`}
                            providedDrag={_provided}
                            onOk={(value, additionalValue) => {
                              const { indexTech, technology } =
                                getSurfaceFinish();
                              if (is3DPTechnology(technology)) {
                                const newSurfaceFinish3DP = JSON.parse(
                                  JSON.stringify(surfaceFinish3DPConfiguration)
                                );
                                const sf3DPConfig =
                                  newSurfaceFinish3DP[indexTech];
                                sf3DPConfig.surfaceFinishOptions[i] = value;

                                if (isNilString(el)) {
                                  const threeDTech = get(sf3DPConfig, [
                                    '3DTechnologies',
                                    selectedMaterialCategory,
                                  ]);
                                  if (!isEmptyValue(threeDTech)) {
                                    set(
                                      sf3DPConfig,
                                      [
                                        'finishDisplayMapping',
                                        threeDTech,
                                        value,
                                      ],
                                      {
                                        displayText:
                                          additionalValue?.displayText || '',
                                        tooltip: additionalValue?.tooltip || '',
                                      }
                                    );
                                  }
                                }
                                handleUpdateSurfaceFinish3DP(
                                  newSurfaceFinish3DP
                                );
                                return;
                              }

                              const newSurfaceFinish = JSON.parse(
                                JSON.stringify(surfaceFinishConfiguration)
                              );
                              newSurfaceFinish[indexTech].surfaceFinishOptions[
                                i
                              ] = value;
                              if (isNilString(el)) {
                                set(
                                  newSurfaceFinish,
                                  [indexTech, 'finishDisplayMapping', value],
                                  {
                                    displayText:
                                      additionalValue?.displayText || '',
                                    tooltip: additionalValue?.tooltip || '',
                                  }
                                );
                              }
                              handleUpdateSurfaceFinishOptionsByTech(
                                newSurfaceFinish
                              );
                            }}
                            onClick={() => {
                              setSelectedSurfaceFinish(i);
                            }}
                            value={el}
                            displayText={() => {
                              const {
                                technology,
                                finishDisplayMapping,
                                technology3D,
                              } = getSurfaceFinish();

                              return isNilString(el)
                                ? is3DPTechnology(technology)
                                  ? get(
                                      finishDisplayMapping,
                                      [technology3D, el, 'displayText'],
                                      ''
                                    )
                                  : get(
                                      finishDisplayMapping,
                                      [el, 'displayText'],
                                      ''
                                    )
                                : undefined;
                            }}
                            tooltip={() => {
                              const {
                                technology,
                                finishDisplayMapping,
                                technology3D,
                              } = getSurfaceFinish();

                              return isNilString(el)
                                ? is3DPTechnology(technology)
                                  ? get(
                                      finishDisplayMapping,
                                      [technology3D, el, 'tooltip'],
                                      ''
                                    )
                                  : get(
                                      finishDisplayMapping,
                                      [el, 'tooltip'],
                                      ''
                                    )
                                : undefined;
                            }}
                            className={clsx(
                              classes.item,
                              classes.surfaceFinishOptions,
                              selectedSurfaceFinish === i &&
                                classes.activeSurfaceFinishOptions
                            )}
                            onDelete={(value) => {
                              const { indexTech, technology } =
                                getSurfaceFinish();
                              if (technology === '3D Printing') {
                                const newSurfaceFinish3DP = JSON.parse(
                                  JSON.stringify(surfaceFinish3DPConfiguration)
                                );
                                const filteredData =
                                  getSurfaceFinish().surfaceFinishOptions?.filter(
                                    (_el, iTemp) => iTemp !== i
                                  );
                                newSurfaceFinish3DP[
                                  indexTech
                                ].surfaceFinishOptions = filteredData;
                                setSelectedDataPopup({
                                  data: newSurfaceFinish3DP,
                                  type: 'Surface Finish',
                                  name: value,
                                  api: KEY_CONFIGURATION[
                                    '3DP_SURFACE_FINISH_OPTIONS_CONFIGURATION'
                                  ],
                                });
                                setOpenPopup(true);
                                return;
                              }
                              const newSurfaceFinish = JSON.parse(
                                JSON.stringify(surfaceFinishConfiguration)
                              );
                              const filteredData =
                                getSurfaceFinish().surfaceFinishOptions?.filter(
                                  (_el, iTemp) => iTemp !== i
                                );
                              newSurfaceFinish[indexTech].surfaceFinishOptions =
                                filteredData;
                              setSelectedDataPopup({
                                data: newSurfaceFinish,
                                type: 'Surface Finish',
                                name: value,
                                api: KEY_CONFIGURATION.SURFACE_FINISH_OPTIONS_BY_TECHNOLOGY_CONFIGURATION,
                              });
                              setOpenPopup(true);
                            }}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <AddNewInput2Options
              label1='Add Surface Finish'
              onClick1={() => handleAddSurfaceFinishItem()}
              label2='Add Custom Surface Finish'
              onClick2={() => handleAddSurfaceFinishItem('Custom Finish')}
              disableLabel2={isCustomSurfaceFinishExist()}
            />
          </List>
        )}

        {!detailsMaterial().surfaceFinishOptions && !getSurfaceFinish() && (
          <List className={classes.list}>
            <h4 style={{ textAlign: 'center' }}>Surface Finish Options</h4>
            <AddNewInput
              className={clsx(classes.item, classes.materialCategory)}
              onClick={() => {
                const technology = data[selectedTechnology].technology;
                if (technology === '3D Printing') {
                  const newSurfaceFinish3DP = JSON.parse(
                    JSON.stringify(surfaceFinish3DPConfiguration)
                  );
                  const technology3D =
                    listMaterialCategory()[selectedMaterialCategory][
                      '3DTechnology'
                    ];
                  newSurfaceFinish3DP[newSurfaceFinish3DP.length] = {
                    '3DTechnologies': [technology3D],
                    defaultSurfaceFinish: '',
                    surfaceFinishOptions: [''],
                  };
                  handleUpdateSurfaceFinish3DP(newSurfaceFinish3DP);
                  return;
                }
                const newSurfaceFinish = JSON.parse(
                  JSON.stringify(surfaceFinishConfiguration)
                );
                newSurfaceFinish[newSurfaceFinish.length] = {
                  technologies: [technology],
                  defaultSurfaceFinish: '',
                  surfaceFinishOptions: [''],
                };
                handleUpdateSurfaceFinishOptionsByTech(newSurfaceFinish);
              }}
            />
          </List>
        )}

        {surfaceFinishColors() && (
          <List className={classes.list}>
            <h4 style={{ textAlign: 'center' }}>Surface Finish Colors</h4>
            <DragDropContext onDragEnd={handleDropSurfaceColor}>
              <Droppable droppableId='list-surface-finish-colors'>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={classes.list}
                  >
                    {Object.keys(surfaceFinishColors().options)?.map(
                      (keyName, i) => (
                        <Draggable
                          key={
                            i +
                            selectedTechnology +
                            selectedMaterialCategory +
                            selectedMaterial +
                            keyName
                          }
                          draggableId={String(i)}
                          index={i}
                        >
                          {(_provided) => (
                            <CustomerInput
                              name={`${i}.surfaceFinishColors.${keyName}`}
                              providedDrag={_provided}
                              type='color-options'
                              value={keyName}
                              disabledEdit={keyName === 'Custom Color'}
                              onOk={(colorKey, { colorVal }) => {
                                const {
                                  indexTech,
                                  indexSurfaceFinish,
                                  newSurfaceColorsConfiguration,
                                } = getIndexSurface();
                                const objColorOptions =
                                  newSurfaceColorsConfiguration[indexTech]
                                    .surfaceFinishColors[indexSurfaceFinish]
                                    .options;
                                objColorOptions[keyName] = colorVal;

                                if (keyName !== colorKey) {
                                  Object.defineProperty(
                                    objColorOptions,
                                    colorKey,
                                    Object.getOwnPropertyDescriptor(
                                      objColorOptions,
                                      keyName
                                    )
                                  );
                                  delete objColorOptions[keyName];
                                }
                                handleUpdateSurfaceFinishColor(
                                  newSurfaceColorsConfiguration
                                );
                              }}
                              defaultColor={
                                surfaceFinishColors().options[keyName]
                              }
                              className={clsx(
                                classes.item,
                                classes.detailMaterial
                              )}
                              onDelete={(keyColor) => {
                                const {
                                  indexTech,
                                  indexSurfaceFinish,
                                  newSurfaceColorsConfiguration,
                                } = getIndexSurface();
                                const objColorOptions =
                                  newSurfaceColorsConfiguration[indexTech]
                                    .surfaceFinishColors[indexSurfaceFinish]
                                    .options;
                                if (Object.keys(objColorOptions).length === 1) {
                                  const filteredData =
                                    newSurfaceColorsConfiguration[
                                      indexTech
                                    ].surfaceFinishColors?.filter(
                                      (e, iTemp) => iTemp !== indexSurfaceFinish
                                    );
                                  newSurfaceColorsConfiguration[
                                    indexTech
                                  ].surfaceFinishColors = filteredData;
                                } else {
                                  delete objColorOptions[keyColor];
                                }
                                setSelectedDataPopup({
                                  data: newSurfaceColorsConfiguration,
                                  type: 'Surface Finish Colors',
                                  name: keyColor,
                                  api: KEY_CONFIGURATION.SURFACE_FINISH_COLORS_CONFIGURATION,
                                });
                                setOpenPopup(true);
                              }}
                            />
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <AddNewInput2Options
              label1='New Color'
              onClick1={() => {
                const {
                  indexTech,
                  indexSurfaceFinish,
                  newSurfaceColorsConfiguration,
                } = getIndexSurface();

                if (Object.keys(surfaceFinishColors().options).length === 0) {
                  newSurfaceColorsConfiguration[
                    indexTech
                  ].surfaceFinishColors.push({
                    options: { 'New Color': '#ffffff' },
                    defaultColor: 'New Color',
                    surfaceFinish: surfaceFinishColors().surfaceFinish,
                  });
                } else if (
                  Object.keys(surfaceFinishColors().options).length > 0
                ) {
                  newSurfaceColorsConfiguration[indexTech].surfaceFinishColors[
                    indexSurfaceFinish
                  ].options = {
                    ...newSurfaceColorsConfiguration[indexTech]
                      .surfaceFinishColors[indexSurfaceFinish].options,
                    'New Color': '#ffffff',
                  };
                }
                handleUpdateSurfaceFinishColor(newSurfaceColorsConfiguration);
              }}
              label2='Custom Color'
              onClick2={() => {
                const {
                  indexTech,
                  indexSurfaceFinish,
                  newSurfaceColorsConfiguration,
                } = getIndexSurface();
                if (Object.keys(surfaceFinishColors().options).length === 0) {
                  newSurfaceColorsConfiguration[
                    indexTech
                  ].surfaceFinishColors.push({
                    options: { 'Custom Color': '#ffffff00' },
                    defaultColor: 'Custom Color',
                    surfaceFinish: surfaceFinishColors().surfaceFinish,
                  });
                } else if (
                  Object.keys(surfaceFinishColors().options).length > 0
                ) {
                  newSurfaceColorsConfiguration[indexTech].surfaceFinishColors[
                    indexSurfaceFinish
                  ].options = {
                    ...newSurfaceColorsConfiguration[indexTech]
                      .surfaceFinishColors[indexSurfaceFinish].options,
                    'Custom Color': '#ffffff00',
                  };
                }
                handleUpdateSurfaceFinishColor(newSurfaceColorsConfiguration);
              }}
            />
          </List>
        )}

        {detailsMaterial().materialColorOptions && (
          <List className={classes.list}>
            <h4 style={{ textAlign: 'center' }}>Material Color Options</h4>
            {detailsMaterial().materialColorOptions &&
              Object.keys(detailsMaterial().materialColorOptions)?.map(
                (keyName, i) => (
                  <CustomerInput
                    key={
                      i +
                      selectedTechnology +
                      selectedMaterialCategory +
                      selectedMaterial +
                      keyName
                    }
                    name={`${i}.materialColorOptions.${keyName}`}
                    disabledEdit={keyName === 'Custom Color'}
                    type='color-options'
                    value={keyName}
                    onOk={(colorKey, { colorVal }) => {
                      const newData = [...data];
                      const objMaterialColorOption =
                        newData[selectedTechnology].config[
                          selectedMaterialCategory
                        ].options[selectedMaterial].materialColorOptions;

                      newData[selectedTechnology].config[
                        selectedMaterialCategory
                      ].options[selectedMaterial].materialColorOptions[
                        keyName
                      ] = colorVal;
                      if (keyName !== colorKey) {
                        Object.defineProperty(
                          objMaterialColorOption,
                          colorKey,
                          Object.getOwnPropertyDescriptor(
                            objMaterialColorOption,
                            keyName
                          )
                        );
                        delete objMaterialColorOption[keyName];
                      }
                      handleUpdateItemUserInput(newData);
                    }}
                    defaultColor={
                      detailsMaterial().materialColorOptions[keyName]
                    }
                    className={clsx(classes.item, classes.detailMaterial)}
                    onDelete={(keyColor) => {
                      const newData = JSON.parse(JSON.stringify(data));
                      const objMaterialColorOption =
                        newData[selectedTechnology].config[
                          selectedMaterialCategory
                        ].options[selectedMaterial].materialColorOptions;
                      delete objMaterialColorOption[keyColor];
                      setSelectedDataPopup({
                        data: newData,
                        name: keyColor,
                        type: 'Material Color Options',
                        api: KEY_CONFIGURATION.ITEM_USER_INPUT_CONFIGURATION,
                      });
                      setOpenPopup(true);
                    }}
                  />
                )
              )}
            <AddNewInput2Options
              label1='New Color'
              onClick1={() => {
                let tempData = [...data];
                tempData[selectedTechnology].config[
                  selectedMaterialCategory
                ].options[selectedMaterial].materialColorOptions = {
                  ...tempData[selectedTechnology].config[
                    selectedMaterialCategory
                  ].options[selectedMaterial].materialColorOptions,
                  'New Color': '',
                };
                handleUpdateItemUserInput(tempData);
              }}
              label2='Custom Color'
              onClick2={() => {
                let tempData = [...data];
                tempData[selectedTechnology].config[
                  selectedMaterialCategory
                ].options[selectedMaterial].materialColorOptions = {
                  ...tempData[selectedTechnology].config[
                    selectedMaterialCategory
                  ].options[selectedMaterial].materialColorOptions,
                  'Custom Color': '#ffffff00',
                };
                setData(tempData);
              }}
            />
          </List>
        )}
      </div>
      <YesNoPopup
        open={openPopup}
        handleNo={() => setOpenPopup(false)}
        handleYes={() => {
          handleDeleteData();
          setOpenPopup(false);
        }}
        title='Are your sure you want to delete this data?'
        body={`Delete "${selectedDataPopup.name}" from ${selectedDataPopup.type}?`}
      />
    </div>
  );
}

export default ManageCustomerInputs;
