import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';

import { Delete as DeleteIcon, Cancel as CancelIcon } from '@material-ui/icons';

import ColorInputField from '../inputs/ColorInputField';
import MaterialCategoriesInputField from '../inputs/MaterialCategoriesInputField';
import OtherMaterialField from './form-fields/OtherMaterialField';
import OtherSurfaceFinishingField from './form-fields/OtherSurfaceFinishingField';
import SurfaceFinishingField from '../inputs/SurfaceFinishingField';
import ThreeDInfillFieldSelect from './form-fields/ThreeDInfillFieldSelect';
import ThreeDLayerThicknessFieldSelect from './form-fields/ThreeDLayerThicknessFieldSelect';
import ThreeDPrintingMaterialField from '../inputs/ThreeDPrintingMaterialField';
import ThreeDPrintingTechnologyInputField from '../inputs/ThreeDPrintingTechnologyInputField';
import RfqRemarksInputWithAttachment from './RfqRemarksInputWithAttachment';
import { FlexRow } from '../layouts/FlexLayouts';
import { FtrButton } from '../ftr-components';

import { useItemInputConfig } from '../../hooks/useItemInputConfig';

import withViewAsSupplierHOC from '../../hocs/withViewAsSupplierHOC';

import { getProjectBulkQuotationsFormSelector } from '../../selectors/projectBulkQuotationsFormSelector';

import { updateShowCustomLeadTime } from '../../actions/projectBulkQuotationsForm';

import { getCurrencySymbol } from '../../utils/currencyUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import {
  isCustomMaterial,
  isCustomSurfaceFinish,
} from '../../utils/inputUtils';
import { is3DPTechnology } from '../../utils/itemUtils';

import {
  ITEM_QUOTE_TYPE,
  QC_FORMAL_CMM_REPORT,
} from '../../constants/projectConstants';
import {
  TECHNOLOGY_OPTION_TYPE,
  THREE_D_P_FDM_TECH,
} from '../../constants/NewPartConstants';
import {
  CMM_PRICE_STR,
  COLOR_STR,
  CUSTOM_LEAD_TIME_STR,
  MATERIAL_COLOR_STR,
  MATERIAL_STR,
  OTHER_MATERIAL_STR,
  OTHER_SURFACE_FINISH_STR,
  QUANTITY_STR,
  SURFACE_FINISH_STR,
  THREE_D_INFILL,
  THREE_D_LAYER_THICKNESS,
  THREE_D_TECHNOLOGY,
  UNIT_QUOTE_STR,
} from '../../constants/quotationConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  quoteWrapper: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    display: 'flex',
    justifyContent: 'space-between',
  },
  quantityWrapper: {
    width: '7%',
    minWidth: '80px',
    marginRight: '4px',
  },
  multiplySymbolWrapper: {
    margin: '0 4px 0 4px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  unitPriceWrapper: {
    width: '14%',
    minWidth: '100px',
    marginRight: '4px',
  },
  equalSymbolWrapper: {
    margin: '0 4px 0 2px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  totalPriceWrapper: {
    width: '12%',
    minWidth: '120px',
    marginRight: '4px',
  },
  surfaceFinishWrapper: {
    width: '24%',
    minWidth: '180px',
    marginRight: '4px',
  },
  formControl: {
    minWidth: 150,
    width: '100%',
  },
  cmmReportWrapper: {
    width: '13%',
    minWidth: '100px',
    marginLeft: '4px',
  },
  cancelButton: {
    position: 'absolute',
    top: '0',
    right: -5,
    cursor: 'pointer',
    color: colors.orange,
    fontSize: '20px',
    backgroundColor: 'white',
  },
}));

function RfqQuotationRowInput(props) {
  const classes = useStyles();

  const unitPriceRef = useRef();
  const quantityRef = useRef();
  const cmmPriceRef = useRef();

  const dispatch = useDispatch();

  const {
    inputField,
    technology,
    currency,
    showDeleteButton,
    onInputFieldsChange,
    onSetAttachments,
    quantityError,
    unitQuoteError,
    otherMaterialError,
    otherSurfaceFinishError,
    customLeadTimeError,
    remarksError,
    onDeleteButtonClick,
    qcReports,
    cmmPriceError,
    expanded,
    user,
    item,
  } = props;

  const projectBulkQuotationsForm = useSelector(
    getProjectBulkQuotationsFormSelector
  );

  const quoteSelectedItems =
    projectBulkQuotationsForm?.items?.filter(
      (i) => i.ignoreItem === false && !i.supplierQuoted
    ) || [];
  const hasPPEQuote = quoteSelectedItems?.some(
    (i) => i.itemQuoteType === ITEM_QUOTE_TYPE.PPE
  );
  const hasOnboardingItem = quoteSelectedItems?.some((i) => i.isOnboardingItem);

  const showCustomLeadTimeForm = useMemo(() => {
    const isOnly1Item = quoteSelectedItems.length === 1;
    const isOnly1Quote = quoteSelectedItems[0]?.quotations?.length === 1;
    const isOnly1ItemQuote = isOnly1Item && isOnly1Quote;
    return !hasOnboardingItem && !hasPPEQuote && !isOnly1ItemQuote;
  }, [
    projectBulkQuotationsForm?.showCustomLeadTime,
    quoteSelectedItems,
    hasPPEQuote,
    hasOnboardingItem,
  ]);

  useEffect(() => {
    // change showCustomLeadTime to false if the form is not supported
    if (
      !showCustomLeadTimeForm &&
      projectBulkQuotationsForm?.showCustomLeadTime
    ) {
      dispatch(
        updateShowCustomLeadTime({
          showCustomLeadTime: false,
        })
      );
    }
  }, [showCustomLeadTimeForm]);

  const [
    {
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
      selectColorSurfaces,
    },
    {
      loadSelectColorSurfaces,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptionsForSupplier,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      materialHasChanged,
      threeDTechnologyHasChanged,
      surfaceFinishHasChanged,
    },
  ] = useItemInputConfig({
    setMaterial: (value) =>
      onInputFieldsChange({ target: { value } }, inputField.id, MATERIAL_STR),
    setSurfaceFinish: (value) =>
      onInputFieldsChange(
        { target: { value } },
        inputField.id,
        SURFACE_FINISH_STR
      ),
    setMaterialColor: (value) =>
      onInputFieldsChange(
        { target: { value } },
        inputField.id,
        MATERIAL_COLOR_STR
      ),
    setColor: (value) =>
      onInputFieldsChange({ target: { value } }, inputField.id, COLOR_STR),
  });

  useEffect(() => {
    if (isEmptyValue(technology) || isEmptyValue(inputField)) {
      return;
    }

    const { threeDTechnology, material, materialColor, surfaceFinish, color } =
      inputField;
    loadSelectColorSurfaces({ technology });
    loadMaterialCategoryOptions({ technology }, false);
    if (is3DPTechnology(technology)) {
      load3DTechnologyOptions(false);
      loadThreeDMaterialOptions(
        {
          technology,
          threeDTechnology,
        },
        false
      );
    }
    const params = {
      technology,
      threeDTechnology,
      material,
    };
    loadSurfaceFinishOptionsForSupplier(params, false);
    if (materialColor) {
      loadMaterialColorOptions(params, false);
    }
    if (color) {
      loadSurfaceFinishColorOptions(
        {
          technology,
          surfaceFinish,
        },
        false,
        { withAbort: false }
      );
    }
  }, [technology, inputField]);

  useEffect(() => {
    if (!isEmptyValue(quantityError)) {
      quantityRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }
    if (!isEmptyValue(unitQuoteError)) {
      unitPriceRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }
    if (!isEmptyValue(cmmPriceError) && cmmPriceRef.current) {
      cmmPriceRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [quantityError, unitQuoteError, cmmPriceError]);

  let timeCustomerLeadTimeID;
  const handleCustomLeadTime = (event) => {
    onInputFieldsChange(event, inputField.id, CUSTOM_LEAD_TIME_STR);

    // For items that have only 1 quote shown, adding in a custom lead time to that
    // would auto-fill that lead time on all items with 1 quote only with that same lead time
    // a. Will not auto-fill for items that have multiple quotes
    // b. Will not auto-fill for any items that already have lead time field populated on the FE
    // Clear any existing timeout before setting a new one
    if (timeCustomerLeadTimeID) {
      clearTimeout(timeCustomerLeadTimeID);
    }
    timeCustomerLeadTimeID = setTimeout(() => {
      if (item?.quotations?.length === 1) {
        for (const quoteItem of quoteSelectedItems) {
          const firstQuoteItem = quoteItem?.quotations?.[0];
          if (
            quoteItem?.quotations?.length === 1 &&
            !firstQuoteItem?.customLeadTime
          ) {
            onInputFieldsChange(
              event,
              firstQuoteItem.id,
              CUSTOM_LEAD_TIME_STR,
              quoteItem.itemID
            );
          }
        }
      }
    }, 500); // update the other field after 0.5 second if supplier type two digits
  };

  const renderOther3DInputs = () => {
    return (
      <div style={{ display: 'flex' }}>
        {inputField.threeDTechnology === THREE_D_P_FDM_TECH && (
          <div style={{ width: 'auto', marginLeft: 2 }}>
            <ThreeDInfillFieldSelect
              value={inputField.threeDInfill}
              onChange={(event) =>
                onInputFieldsChange(event, inputField.id, THREE_D_INFILL)
              }
            />
          </div>
        )}
        {inputField.threeDTechnology === THREE_D_P_FDM_TECH && (
          <div style={{ width: 'auto', minWidth: 150, marginLeft: 2 }}>
            <ThreeDLayerThicknessFieldSelect
              value={inputField.threeDLayerThickness}
              onChange={(event) =>
                onInputFieldsChange(
                  event,
                  inputField.id,
                  THREE_D_LAYER_THICKNESS
                )
              }
            />
          </div>
        )}
      </div>
    );
  };

  const renderSurfaceFinishColor = () => {
    return (
      <ColorInputField
        visible={!isEmptyValue(surfaceFinishColorOptions)}
        value={inputField.color}
        colorInputLabel={'Color'}
        colorPalette={surfaceFinishColorOptions}
        onSubmit={(selectedColor) =>
          onInputFieldsChange(
            { target: { value: selectedColor } },
            inputField.id,
            COLOR_STR
          )
        }
        bootstrapStyle={false}
        quotationField
      />
    );
  };

  const renderMaterialColorInputField = () => {
    return (
      <ColorInputField
        visible={!isEmptyValue(materialColorOptions)}
        value={inputField.materialColor}
        colorInputLabel='Material Color'
        colorPalette={materialColorOptions}
        onSubmit={(selectedColor) =>
          onInputFieldsChange(
            { target: { value: selectedColor } },
            inputField.id,
            MATERIAL_COLOR_STR
          )
        }
        bootstrapStyle={false}
        quotationField
      />
    );
  };

  const renderRemarksInputWithAttachment = () => {
    return (
      <div
        style={{
          width: '100%',
          marginTop: 4,
        }}
      >
        <RfqRemarksInputWithAttachment
          remarksError={remarksError}
          inputField={inputField}
          onInputFieldsChange={onInputFieldsChange}
          onSetAttachments={onSetAttachments}
        />
      </div>
    );
  };

  const renderCustomLeadTime = () => {
    const showCustomLeadTime =
      projectBulkQuotationsForm?.showCustomLeadTime || showCustomLeadTimeForm;
    if (!showCustomLeadTime) {
      return null;
    }

    const userCountryTooltipText =
      user.country === 'Singapore'
        ? '(not including shipping)'
        : '(including shipping)';
    const impactUpdateTooltipText =
      item?.quotations?.length === 1
        ? "and we'll copy it to any parts with a single quote and no lead time"
        : '';
    return (
      <FlexRow style={{ gap: '0' }}>
        {projectBulkQuotationsForm?.showCustomLeadTime && (
          <div style={{ position: 'relative' }}>
            <Tooltip
              title={`Please enter the lead time ${userCountryTooltipText} for this quote ${impactUpdateTooltipText}.`}
              placement='bottom'
              arrow
            >
              <TextField
                variant='outlined'
                required
                style={{ marginLeft: 4, width: '200px' }}
                margin='dense'
                label='Part Lead Time'
                name='customLeadTime'
                type='number'
                placeholder='E.g. 7'
                value={inputField?.customLeadTime}
                InputLabelProps={{ shrink: true }}
                onChange={handleCustomLeadTime}
                onWheel={(e) => e.target.blur()} // disable change value when mouse scroll
                error={customLeadTimeError}
                helperText={customLeadTimeError}
                InputProps={{
                  inputProps: {
                    min: 0,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  },
                  endAdornment: (
                    <InputAdornment position='end' disablePointerEvents>
                      working day(s)
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
            <Tooltip
              title='Remove all parts lead times'
              placement='bottom'
              arrow
            >
              <CancelIcon
                onClick={() => {
                  dispatch(
                    updateShowCustomLeadTime({
                      showCustomLeadTime: false,
                    })
                  );
                }}
                className={classes.cancelButton}
              />
            </Tooltip>
          </div>
        )}
        {!projectBulkQuotationsForm?.showCustomLeadTime && (
          <Tooltip title='Please ensure to specify a lead time for all parts in this project.'>
            <div>
              <FtrButton
                variant='text'
                onClick={() => {
                  dispatch(
                    updateShowCustomLeadTime({
                      showCustomLeadTime: true,
                    })
                  );
                }}
                style={{ padding: '4px' }}
              >
                + Add part lead time
              </FtrButton>
            </div>
          </Tooltip>
        )}
      </FlexRow>
    );
  };

  return (
    <div>
      <div className={classes.quoteWrapper}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <div className={classes.quantityWrapper}>
            <TextField
              id='quantity'
              ref={quantityRef}
              variant='outlined'
              required
              margin='dense'
              label='Quantity'
              name={QUANTITY_STR}
              type='number'
              value={inputField.quantity}
              InputLabelProps={{ shrink: true }}
              error={quantityError}
              helperText={quantityError}
              onChange={(event) =>
                onInputFieldsChange(event, inputField.id, QUANTITY_STR)
              }
              // disable change value when mouse scroll
              onWheel={(e) => e.target.blur()}
              disabled={inputField.id === 0 ? true : false}
              style={{ width: '100%' }}
            />
          </div>
          <div className={classes.multiplySymbolWrapper}>x</div>
          <div className={classes.unitPriceWrapper}>
            <Tooltip
              title='Include all charges (fabrication, shipping, etc.) except for GST'
              arrow
            >
              <TextField
                style={{ width: '100%' }}
                id='unit-price'
                ref={unitPriceRef}
                variant='outlined'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <div>{getCurrencySymbol(currency)}</div>
                    </InputAdornment>
                  ),
                }}
                required
                margin='dense'
                label='Unit Price'
                name={UNIT_QUOTE_STR}
                type='number'
                value={inputField.unitQuote}
                placeholder='00.00'
                error={unitQuoteError}
                helperText={unitQuoteError}
                // disable change value when mouse scroll
                onWheel={(e) => e.target.blur()}
                onChange={(event) =>
                  onInputFieldsChange(event, inputField.id, UNIT_QUOTE_STR)
                }
              />
            </Tooltip>
          </div>
          <div className={classes.equalSymbolWrapper}>=</div>
          <div className={classes.totalPriceWrapper}>
            <TextField
              id='outlined-basic'
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <div>{getCurrencySymbol(currency)}</div>
                  </InputAdornment>
                ),
              }}
              disabled
              fullWidth
              margin='dense'
              label='Total (excl. GST)'
              name='quote'
              type='number'
              value={inputField.quote}
              placeholder='00.00'
            />
          </div>
          <div style={{ width: 'auto' }}>
            <ThreeDPrintingTechnologyInputField
              visible={is3DPTechnology(technology)}
              value={inputField.threeDTechnology}
              onChange={(newValue) => {
                const event = { target: { value: newValue } };
                onInputFieldsChange(event, inputField.id, THREE_D_TECHNOLOGY);
                const params = {
                  technology,
                  threeDTechnology: newValue,
                };
                threeDTechnologyHasChanged(params);
              }}
              threeDTechnologyOptions={threeDTechnologyOptions}
              bootstrapStyle={false}
              withLabel={false}
              isBuyer={false}
              style={{ marginRight: '0.2rem' }}
            />
          </div>
          <div style={{ width: 'auto', minWidth: 150 }}>
            <MaterialCategoriesInputField
              visible={!is3DPTechnology(technology)}
              value={inputField.material}
              onSelect={(value) => {
                onInputFieldsChange(
                  { target: { value } },
                  inputField.id,
                  MATERIAL_STR
                );
                const { threeDTechnology } = inputField;
                const params = {
                  technology,
                  threeDTechnology,
                  material: value,
                };
                materialHasChanged(params);
              }}
              materialCategoryOptions={materialCategoryOptions}
              bootstrapStyle={false}
            />
            <ThreeDPrintingMaterialField
              visible={is3DPTechnology(technology)}
              value={inputField.material}
              onSelect={(_material) => {
                onInputFieldsChange(
                  { target: { value: _material } },
                  inputField.id,
                  MATERIAL_STR
                );
                const { threeDTechnology } = inputField;
                const params = {
                  technology,
                  threeDTechnology,
                  material: _material,
                };
                materialHasChanged(params);
              }}
              threeDMaterialOptions={threeDMaterialOptions}
              defaultThreeDMaterial={defaultThreeDMaterial}
              bootstrapStyle={false}
              withLabel={false}
              isBuyer={false}
              style={{ marginRight: '0.2rem' }}
            />
          </div>
          {isCustomMaterial(inputField.material) && (
            <div style={{ width: 'auto', minWidth: 150, marginLeft: 4 }}>
              <OtherMaterialField
                value={inputField.otherMaterial}
                error={otherMaterialError}
                helperText={otherMaterialError}
                onChange={(event) =>
                  onInputFieldsChange(event, inputField.id, OTHER_MATERIAL_STR)
                }
              />
            </div>
          )}
          {renderMaterialColorInputField()}
          <div style={{ width: 'auto', minWidth: 150, marginLeft: 4 }}>
            <SurfaceFinishingField
              visible={
                !isEmptyValue(surfaceFinishOptions) ||
                isCustomSurfaceFinish(inputField.surfaceFinish)
              }
              value={inputField.surfaceFinish}
              onSelect={(newValue) => {
                onInputFieldsChange(
                  { target: { value: newValue } },
                  inputField.id,
                  SURFACE_FINISH_STR
                );
                const { material } = inputField;
                const params = {
                  technology,
                  material,
                  surfaceFinish: newValue,
                };
                surfaceFinishHasChanged(params);
              }}
              surfaceFinishOptions={surfaceFinishOptions}
              selectColorSurfaces={selectColorSurfaces}
              bootstrapStyle={false}
            />
          </div>
          {isCustomSurfaceFinish(inputField.surfaceFinish) && (
            <div style={{ width: 'auto', minWidth: 220, marginLeft: 2 }}>
              <OtherSurfaceFinishingField
                value={inputField.otherSurfaceFinish}
                error={otherSurfaceFinishError}
                helperText={otherSurfaceFinishError}
                onChange={(event) =>
                  onInputFieldsChange(
                    event,
                    inputField.id,
                    OTHER_SURFACE_FINISH_STR
                  )
                }
              />
            </div>
          )}
          {renderSurfaceFinishColor()}
          {technology === TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING &&
            renderOther3DInputs()}
          {qcReports && qcReports.main === QC_FORMAL_CMM_REPORT && (
            <div className={classes.cmmReportWrapper}>
              <Tooltip
                title='Enter CMM report price. Insert "0" if no additional charge for CMM report'
                arrow
              >
                <TextField
                  style={{ width: '100%' }}
                  id='cmm-report'
                  ref={cmmPriceRef}
                  variant='outlined'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <div>{getCurrencySymbol(currency)}</div>
                      </InputAdornment>
                    ),
                  }}
                  required
                  margin='dense'
                  label='CMM Price'
                  name={CMM_PRICE_STR}
                  type='number'
                  value={inputField.cmmPrice}
                  placeholder='00.00'
                  error={cmmPriceError}
                  helperText={cmmPriceError}
                  onChange={(event) =>
                    onInputFieldsChange(event, inputField.id, CMM_PRICE_STR)
                  }
                  // disable change value when mouse scroll
                  onWheel={(e) => e.target.blur()}
                />
              </Tooltip>
            </div>
          )}
          {renderCustomLeadTime()}
        </div>
        <div>
          {showDeleteButton && (
            <IconButton
              aria-label='delete'
              onClick={onDeleteButtonClick}
              disabled={inputField.id === 0}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </div>
      {expanded && renderRemarksInputWithAttachment()}
    </div>
  );
}

export default withViewAsSupplierHOC(RfqQuotationRowInput);
