import { v4 as uuidv4 } from 'uuid';
import { sample } from 'lodash';

import * as multiCheckoutApi from '../apis/multiCheckoutApi';
import { createMultipleCheckoutPaymentIntent } from '../apis/stripeApi';

//Stripe publishable key
import { loadStripe } from '@stripe/stripe-js';

const stripe_publishable_key = `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`;
const stripePromise = loadStripe(stripe_publishable_key);

import { approveMultipleQuotations } from '../apis/quotationApi';
import { createAlertNotification } from '../apis/alertApi';
import { getUserAddresses } from '../apis/userApi';

import { isEmptyValue } from '../utils/commonUtils';
import { getDefaultShippingModeByCountry } from '../utils/deliveryUtils';

import { notifyError } from '../services/notificationService';

import { CURRENCY } from '../constants';
import { CUSTOMER_CREDIT_TYPES } from '../constants/customerConstants';
import { LOADING_MESSAGE_OPTIONS_ARRAY } from '../constants/messageConstants';
import { SHIPPING_MODES } from '../constants/checkoutConstants';

// --------------------- ACTIONS --------------------------------------------

export const INIT_MULTI_CHECKOUT_FORM_STATE = 'INIT_MULTI_CHECKOUT_FORM_STATE';
export const ADD_SELECTED_QUOTE = 'ADD_SELECTED_QUOTE';
export const REMOVE_SELECTED_QUOTE = 'REMOVE_SELECTED_QUOTE';
export const REMOVE_SELECTED_QUOTES = 'REMOVE_SELECTED_QUOTES';
export const UPDATE_CHECKOUT_PRICING_SUMMARY_START =
  'UPDATE_CHECKOUT_PRICING_SUMMARY_START';
export const UPDATE_CHECKOUT_PRICING_SUMMARY_SUCCESS =
  'UPDATE_CHECKOUT_PRICING_SUMMARY_SUCCESS';
export const UPDATE_CHECKOUT_PRICING_SUMMARY_FAIL =
  'UPDATE_CHECKOUT_PRICING_SUMMARY_FAIL';
export const RESET_CHECKOUT_PRICING_SUMMARY = 'RESET_CHECKOUT_PRICING_SUMMARY';
export const UPDATE_STATE = 'UPDATE_STATE';

export const initMultiCheckoutFormState = () => (dispatch, getState) => {
  const currency = getState().auth?.location?.currency ?? CURRENCY.SGD;
  dispatch({
    type: INIT_MULTI_CHECKOUT_FORM_STATE,
    payload: currency,
  });
};

export const startUpdateCheckoutPricingSummary = (payload) => {
  return {
    type: UPDATE_CHECKOUT_PRICING_SUMMARY_START,
    payload,
  };
};

export const updateCheckoutPricingSummary = (data) => {
  return {
    type: UPDATE_CHECKOUT_PRICING_SUMMARY_SUCCESS,
    payload: data,
  };
};

export const updateCheckoutPricingSummaryFailed = () => {
  return {
    type: UPDATE_CHECKOUT_PRICING_SUMMARY_FAIL,
  };
};

export const updateMultiCheckoutFormState = (payload) => {
  return {
    type: UPDATE_STATE,
    payload,
  };
};

export const getCheckoutPricingSummary =
  (bodyParam) => (dispatch, getState) => {
    let body = bodyParam;
    const multiCheckoutForm = getState().multiCheckoutForm;
    if (!body) {
      const {
        selectedQuotes,
        currency,
        shippingMode = SHIPPING_MODES.ONE_THREE_DAYS,
        country,
        deliveryInfoID,
      } = multiCheckoutForm;

      body = {
        selectedQuotes,
        currency,
        exchangeRate: 1,
        shippingMode,
        country,
        deliveryInfoID,
      };
    }

    const requestID = uuidv4();

    dispatch(
      startUpdateCheckoutPricingSummary({
        requestID,
      })
    );

    body.factoremCustomDiscountCode = isEmptyValue(
      multiCheckoutForm.factoremCustomDiscountCode
    )
      ? undefined
      : multiCheckoutForm.factoremCustomDiscountCode;

    if (multiCheckoutForm.creditType === CUSTOMER_CREDIT_TYPES.NON_CREDIT) {
      body.paymentType = multiCheckoutForm.paymentType;
    }

    multiCheckoutApi
      .getCheckoutPricingSummaryWithCache(body)
      .then((data) => {
        const _multiCheckoutForm = getState().multiCheckoutForm;
        const multiCheckoutFormRequestID = _multiCheckoutForm?.requestID;
        if (
          !multiCheckoutFormRequestID ||
          multiCheckoutFormRequestID === requestID
        ) {
          dispatch(updateCheckoutPricingSummary(data));
        }
      })
      .catch(() => {
        const _multiCheckoutForm = getState().multiCheckoutForm;
        const multiCheckoutFormRequestID = _multiCheckoutForm?.requestID;
        if (
          !multiCheckoutFormRequestID ||
          multiCheckoutFormRequestID === requestID
        ) {
          dispatch(updateCheckoutPricingSummaryFailed());
        }
      });
  };

export const addSelectedQuote = (quotation) => (dispatch) => {
  dispatch({
    type: ADD_SELECTED_QUOTE,
    payload: quotation,
  });
  dispatch(getCheckoutPricingSummary());
};
export const removeSelectedQuote = (quotation) => (dispatch) => {
  dispatch({
    type: REMOVE_SELECTED_QUOTE,
    payload: quotation.quotationID,
  });
  dispatch(getCheckoutPricingSummary());
};

export const removeSelectedQuotes = (quotationIDList) => (dispatch) => {
  dispatch({
    type: REMOVE_SELECTED_QUOTES,
    payload: quotationIDList,
  });
  dispatch(getCheckoutPricingSummary());
};

export const setPromoCodeAction =
  (factoremCustomDiscountCode) => (dispatch) => {
    dispatch(updateMultiCheckoutFormState({ factoremCustomDiscountCode }));
    dispatch(getCheckoutPricingSummary());
  };

export const resetCheckoutPricingSummary = () => {
  return {
    type: RESET_CHECKOUT_PRICING_SUMMARY,
  };
};

export const updatePaymentType = (paymentType) => (dispatch) => {
  dispatch(updateMultiCheckoutFormState({ paymentType }));
  dispatch(getCheckoutPricingSummary());
};

export const UPDATE_CHECKOUT_SUMMARY = {
  YES: true,
  NO: false,
};

export const updateShippingMode =
  (shippingMode, _updateCheckoutPricingSummary = UPDATE_CHECKOUT_SUMMARY.YES) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_STATE,
      payload: { shippingMode },
    });

    if (_updateCheckoutPricingSummary) {
      dispatch(getCheckoutPricingSummary());
    }
  };

export const updateMulticheckoutFormAddress =
  (payload) => (dispatch, getState) => {
    dispatch(updateMultiCheckoutFormState(payload));

    const { country } = payload || {};
    const shippingMode = getDefaultShippingModeByCountry(country);
    dispatch(updateShippingMode(shippingMode));

    const multiCheckoutForm = getState().multiCheckoutForm;
    const { billingAddressID } = multiCheckoutForm;
    if (isEmptyValue(billingAddressID)) {
      dispatch(
        updateMultiCheckoutFormState({
          billingAddressID: payload.deliveryInfoID,
        })
      );
    }
  };

export const updateMultiCheckoutCurrency = (currency) => (dispatch) => {
  dispatch({
    type: UPDATE_STATE,
    payload: { currency },
  });
};

export const confirmMultiCheckout =
  ({
    customerID,
    creditType,
    customerRemarks: customerRemarksParam,
    addresses: addressesParam,
  }) =>
  async (dispatch, getState) => {
    const multiCheckoutForm = getState().multiCheckoutForm;
    const quotes = multiCheckoutForm.selectedQuotes;
    const {
      shippingMode,
      paymentType,
      deliveryInfoID,
      factoremCustomDiscountCode = '',
    } = multiCheckoutForm;
    const { currency } = multiCheckoutForm;
    const exchangeRate = multiCheckoutForm.exchangeRate;
    const customerRemarks =
      multiCheckoutForm.customerRemarks || customerRemarksParam;
    const addresses = addressesParam || multiCheckoutForm.addresses;
    const deliveryTime = multiCheckoutForm.deliveryTime;

    const message =
      creditType === CUSTOMER_CREDIT_TYPES.CREDIT
        ? sample(LOADING_MESSAGE_OPTIONS_ARRAY)
        : 'Please hold while we retrieve your payment details';

    dispatch({
      type: UPDATE_STATE,
      payload: {
        confirmOrderStatus: 'loading',
        showAcceptedPopup: false,
        loadingMessage: message,
      },
    });

    const commonBody = {
      shippingMode,
      exchangeRate,
      addresses,
      deliveryInfoID,
      currency,
      deliveryTime,
    };

    if (creditType === CUSTOMER_CREDIT_TYPES.CREDIT) {
      const quotesApproval = [];
      for (const quote of quotes) {
        const data = {
          quotationID: quote.quotationID,
          itemID: quote.itemID,
          customerRemarks: customerRemarks[quote.quotationID],
        };
        quotesApproval.push(data);
      }

      const approveRequestBody = {
        quotesApproval,
        ...commonBody,
        factoremCustomDiscountCode,
      };

      approveMultipleQuotations(approveRequestBody)
        .then(() => {
          dispatch({
            type: UPDATE_STATE,
            payload: {
              confirmOrderStatus: 'success',
              showAcceptedPopup: true,
              loadingMessage: '',
            },
          });
        })
        .catch((error) => {
          const _message = error.message ?? `Error occurred while processing`;
          notifyError(_message);
          const body = {
            title: '[FE] Customer accepted quote failed!',
            errorStack: error.stack,
            additionalInfo: {
              customerID,
              approveRequestBody,
            },
          };
          createAlertNotification(body);
          dispatch({
            type: UPDATE_STATE,
            payload: {
              confirmOrderStatus: 'failed',
              loadingMessage: '',
            },
          });
        });
    } else {
      const body = {
        quotationList: quotes?.map((quote) => ({
          quotationID: quote.quotationID,
          customerRemarks: customerRemarks[quote.quotationID],
        })),
        ...commonBody,
        paymentType,
        factoremCustomDiscountCode,
      };

      const stripe = await stripePromise;

      createMultipleCheckoutPaymentIntent(body)
        .then((response) => {
          dispatch({
            type: UPDATE_STATE,
            payload: {
              loadingMessage: '',
            },
          });
          stripe.redirectToCheckout({
            sessionId: response.id,
          });
        })
        .catch((error) => {
          const _message = error.message ?? `Error occurred while processing`;
          notifyError(_message);
          const alertBody = {
            title: '[FE] Customer confirm payment for quote failed!',
            errorStack: error.stack,
            additionalInfo: {
              customerID,
              body,
            },
          };
          createAlertNotification(alertBody);
          dispatch({
            type: UPDATE_STATE,
            payload: {
              confirmOrderStatus: 'failed',
              loadingMessage: '',
            },
          });
        });
    }
  };

export const getCustomerDeliveryInfoList = (customerID) => async (dispatch) => {
  const customerDeliveryInfoList = await getUserAddresses(customerID);
  dispatch({
    type: UPDATE_STATE,
    payload: {
      customerDeliveryInfoList,
    },
  });
};
