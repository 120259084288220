import React from 'react';
import { Tooltip, Icon } from '@material-ui/core';
import { Publish } from '@material-ui/icons';

import { ReactComponent as LongReceiptIconGreen } from '../../assets/icons/long_receipt_green.svg';

export const CreditNoteIcon = ({
  hasSupplierCreditNote,
  creditNoteSentToHubdoc,
  requiredRework,
}) => {
  if (!requiredRework) {
    return null;
  }
  const tooltipTitle = hasSupplierCreditNote
    ? creditNoteSentToHubdoc
      ? 'Credit note uploaded and is processed'
      : 'Please check the uploaded credit note'
    : 'Please upload your credit note';

  return (
    <Tooltip title={tooltipTitle}>
      {hasSupplierCreditNote ? (
        <Icon>
          {creditNoteSentToHubdoc ? (
            <LongReceiptIconGreen />
          ) : (
            <LongReceiptIconGreen fill={'orange'} />
          )}
        </Icon>
      ) : (
        <Publish color='error' />
      )}
    </Tooltip>
  );
};
