import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons';
import { Container } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { convertFileToStl } from '../../apis/convertFileApi';

import { downloadS3File } from '../../utils/fileUtils';

import { uploadFileToS3 } from '../../services/s3Service';

import { CADPART_DIRNAME } from '../../constants';

// -------------------------------------------------------------------------------------------------

export default function StlConverter() {
  const [listLinkStl, setListLinkStl] = useState([]);

  function handleFilesChange(files) {
    let stringOfLinksInput = [];
    const toastId = toast('File(s) is uploading', {
      type: toast.TYPE.INFO,
      autoClose: false,
    });
    Promise.all(
      Array.from(files)?.map(async (file) => {
        const splitFilename = file.name?.split('.');
        const preFilename = splitFilename?.slice(0, -1)?.join('.');
        const fileExtension = splitFilename[splitFilename.length - 1];
        const s3ObjectKey = `${CADPART_DIRNAME}/${preFilename}_${file.lastModified}.${fileExtension}`;
        return uploadFileToS3(file, s3ObjectKey)
          .then(({ s3ObjectUrl }) => {
            stringOfLinksInput.push(s3ObjectUrl);
          })
          .catch((err) => {
            alert(err);
          });
      })
    )
      .then(() => {
        return convertFileToStl({
          file_urls: stringOfLinksInput,
        });
      })
      .then((res) => {
        setListLinkStl((prev) => [...prev, ...res.s3_file_urls]);
        toast.update(toastId, {
          render: 'File(s) is converted to .stl successfully',
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
      })
      .catch((err) => {
        toast.update(toastId, {
          render: err?.message || 'Stl converter failed!',
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
      });
  }

  return (
    <Container>
      <div
        style={{
          paddingTop: '2rem',
        }}
      >
        <input
          id='StlConverter'
          type='file'
          multiple
          accept='.step, .stp, .iges, .igs'
          onClick={(event) => (event.target.value = null)}
          onChange={(evt) => handleFilesChange(evt.target.files)}
          style={{
            display: 'none',
          }}
        />
        <label htmlFor='StlConverter'>
          <div
            style={{
              outline: 'dashed grey 2px',
              height: '6.25rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <CloudUploadIcon style={{ margin: '5px' }} />
            Convert file(s) in .step, .stp, .iges, or .igs format to .stl format
          </div>
        </label>
      </div>
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1.5rem',
        }}
      >
        Download Link(s):
      </span>
      {listLinkStl.length > 0 && (
        <ul>
          {listLinkStl?.map((link) => (
            <li key={link}>
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  downloadS3File(link);
                }}
              >
                {link}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </Container>
  );
}
