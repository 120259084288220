import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Box,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import BootstrapInput from './BootstrapInput';
import BootstrapInputSmall from './BootstrapInputSmall';
import InfoIcon from '../icons/InfoIcon';
import DifferentConfigWarningIcon from '@components/forms/DifferentConfigWarningIcon';
import FtrFieldLabel from '../ftr-components/FtrFieldLabel';

import { renderSkeletonOverlay } from '../util/skeleton';

import { useMaterialInfo } from '../../hooks/useMaterialInfo';

import { isEmptyValue } from '../../utils/commonUtils';
import { compareStringsEqualsIgnoreCase } from '@utils/stringUtils';

import {
  CONFIG_FIELDS_NAME_MAPPING,
  CONFIG_FIELDS_ORIGIN,
} from '@constants/itemConstants';
import { TECHNOLOGY_OPTION_TYPE } from '../../constants/NewPartConstants';
import { MATERIAL_STR } from '../../constants/quotationConstants';

import {
  FlexRow,
  FlexRowEnd,
  FlexRowSpaceBetween,
} from '../layouts/FlexLayouts';

import { colors } from '../../palette';
import inputsStyles from './inputsStyles';

const useStyles = makeStyles(() => ({
  inputLabel: {
    fontSize: 16,
    color: colors.fontGrey,
    fontWeight: 600,
  },
  inputLabelRowDisplay: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    color: colors.fontLightGrey,
    fontWeight: '600',
    textTransform: 'uppercase',
  },
  inputLabelRowDisplayFixWidth: {
    width: 150,
    minWidth: 150,
  },
  selectField: {
    width: '100%',
  },
  rowDisplay: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    width: '100%',
  },
}));

function ThreeDPrintingMaterialField(props) {
  const classes = useStyles();
  const inputStyles = inputsStyles();

  // Defining input styles
  classes.emptyField = inputStyles.emptyField;
  classes.v2 = inputStyles.v2;

  const {
    item = {},
    technology,
    threeDTechnology,
    visible = false,
    threeDMaterialOptions = [],
    onSelect,
    onBlur = () => {},
    value,
    rowDisplay = false,
    bootstrapStyle = true,
    defaultThreeDMaterial,
    disabled = false,
    loading = false,
    disableLabelOnBootstrapStyle = false,
    v2 = false,
    withLabel = true,
    isBuyer = true,
    style = {},
  } = props;

  const [{ description, datasheetUrl }, { setParams }] = useMaterialInfo();

  useEffect(() => {
    if (
      isEmptyValue(technology) ||
      isEmptyValue(value) ||
      isEmptyValue(threeDTechnology) ||
      technology !== TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING
    ) {
      return;
    }

    const params = {
      technology: TECHNOLOGY_OPTION_TYPE.THREE_D_PRINTING,
      category: threeDTechnology,
      material: value,
    };
    setParams(params);
  }, [value, threeDTechnology]);

  const handleChange = (evt) => {
    const newMaterial = evt.target.value;
    onSelect(newMaterial);
  };

  if (!visible) {
    return null;
  }

  const renderMenuItems = () => {
    return threeDMaterialOptions?.map((option) => {
      const label =
        defaultThreeDMaterial === option ? (
          <span>
            {option}&nbsp;
            <span
              style={{
                fontStyle: 'italic',
                color: colors.blue050,
                fontSize: '0.8rem',
              }}
            >
              (Preferred)
            </span>
          </span>
        ) : (
          option
        );
      return (
        <MenuItem key={option} value={option}>
          {label}
        </MenuItem>
      );
    });
  };

  const renderDataSheetUrl = () => {
    return (
      <FlexRow>
        <a
          style={{
            fontSize: 'small',
            fontStyle: 'italic',
          }}
          href={datasheetUrl}
          target='_blank'
          rel='noreferrer'
        >
          {value} Datasheet
        </a>
      </FlexRow>
    );
  };

  if (bootstrapStyle) {
    return (
      <div className={rowDisplay ? classes.rowDisplay : classes.container}>
        {!disableLabelOnBootstrapStyle && (
          <div
            className={rowDisplay ? classes.inputLabelRowDisplayFixWidth : null}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 7,
            }}
          >
            <FtrFieldLabel>Material</FtrFieldLabel>

            {description && (
              <div
                style={{
                  marginLeft: '0.5rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <InfoIcon toolTipText={description} />
              </div>
            )}
            {!rowDisplay && datasheetUrl && !loading && renderDataSheetUrl()}
          </div>
        )}
        <div style={{ position: 'relative' }}>
          {loading && renderSkeletonOverlay()}
          <Select
            className={`${classes.selectField} ${v2 ? classes.v2 : ''} ${
              !value ? classes.emptyField : ''
            }`}
            input={rowDisplay ? <BootstrapInputSmall /> : <BootstrapInput />}
            id='material'
            value={value ?? null}
            renderValue={(_value) => _value}
            onChange={handleChange}
            onBlur={onBlur}
            disabled={disabled}
          >
            {renderMenuItems()}
          </Select>
          {/* {rowDisplay && datasheetUrl && !loading && renderDataSheetUrl()} */}
        </div>
      </div>
    );
  }

  return (
    <FormControl variant='outlined' className={classes.formControl} fullWidth>
      <div style={{ position: 'relative', ...style }}>
        {loading && renderSkeletonOverlay()}
        {withLabel && (
          <FlexRowSpaceBetween>
            <FtrFieldLabel>
              <FlexRow>
                Material
                {value !== item?.tdeConfigs?.material && (
                  <DifferentConfigWarningIcon
                    field={CONFIG_FIELDS_NAME_MAPPING.THREED_MATERIAL}
                    recommendedConfigsOrigin={
                      item?.orderAgain
                        ? CONFIG_FIELDS_ORIGIN.ORDER_AGAIN
                        : CONFIG_FIELDS_ORIGIN.TDE
                    }
                    recommendedValue={
                      item?.orderAgain
                        ? item?.savedConfigs?.otherMaterial ||
                          item?.savedConfigs?.material
                        : item?.tdeConfigs?.otherMaterial ||
                          item?.tdeConfigs?.material
                    }
                    userInputValue={value}
                    technologyMatch={compareStringsEqualsIgnoreCase(
                      item?.technology,
                      item?.orderAgain
                        ? item?.savedConfigs?.technology
                        : item?.tdeConfigs?.technology
                    )}
                    iconSize={16}
                  />
                )}
              </FlexRow>
            </FtrFieldLabel>
            <FlexRowEnd>
              {description && <InfoIcon toolTipText={description} />}
              {!rowDisplay && datasheetUrl && !loading && renderDataSheetUrl()}
            </FlexRowEnd>
          </FlexRowSpaceBetween>
        )}
        {!isBuyer && <Box style={{ height: '0.5rem' }}></Box>}
        <TextField
          name={MATERIAL_STR}
          label={!isBuyer ? 'Material' : undefined}
          labelId='material'
          id='material'
          variant='outlined'
          select
          value={value ?? null}
          SelectProps={{
            renderValue: (_value) => _value,
          }}
          size='small'
          InputProps={{
            style: {
              borderRadius: isBuyer ? '10px' : '4px',
              fontWeight: isBuyer ? 600 : 400,
              color: colors.neutral070,
            },
            inputProps: { min: 1 },
          }}
          style={{ width: '100%' }}
          onChange={handleChange}
          onBlur={onBlur}
          margin={`${description ? 'dense' : ''}`}
          fullWidth
          disabled={disabled}
        >
          {renderMenuItems()}
        </TextField>
      </div>
    </FormControl>
  );
}

export default ThreeDPrintingMaterialField;
