import { useEffect, useState } from 'react';
import {
  getQualityProperties,
  getQualityPropertiesToUpdate,
} from '../utils/itemUtils';
import { usePrevious } from '../hooks/usePrevious';
import { isEmpty } from 'lodash';

const useQualityMetrics = (items) => {
  const [itemsQuality, setItemsQuality] = useState(items ?? []);
  const [combinedItemsQuality, setCombinedItemsQuality] = useState(items ?? []);

  const previousItems = usePrevious(items);

  const updateQualityInformation = (name, checked, itemID) => {
    setItemsQuality((prevItems) =>
      prevItems?.map((item) =>
        item.itemID === itemID
          ? {
              ...item,
              ...getQualityPropertiesToUpdate(name, checked),
            }
          : item
      )
    );
  };

  const combineData = (_items, _itemsQuality) => {
    const combinedData = _items?.map((item, index) => ({
      ...item,
      ...getQualityProperties(_itemsQuality[index], null, true),
    }));
    setCombinedItemsQuality(combinedData);
  };

  useEffect(() => {
    combineData(items, itemsQuality);
  }, [items, itemsQuality]);

  useEffect(() => {
    // Update default items quality
    if (!isEmpty(items) && isEmpty(previousItems)) {
      setItemsQuality(items);
    }
  }, [items, previousItems]);

  return [
    { itemsQuality, combinedItemsQuality },
    {
      updateQualityInformation,
      getQualityProperties,
      combineData,
      getQualityPropertiesToUpdate,
    },
  ];
};

export default useQualityMetrics;
