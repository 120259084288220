import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles/index';

import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
} from '@material-ui/core';

import { FtrButton, FtrTypography } from '../ftr-components';

import { isEmptyValue } from '../../utils/commonUtils';

import { colors } from '../../palette';

import {
  QC_COATING_FINISHING_CERTIFICATE,
  QC_FORMAL_CMM_REPORT,
  QC_MATERIAL_CERTIFICATE,
  QC_NO_QC_REPORT,
  QC_STANDARD_QC_REPORT,
} from '../../constants/projectConstants';
import estimateQcReportCostInfoHook from '../forms/part-upload-step-two/estimateQcReportCostInfoHook';
import useBottomCheck from '../../hooks/useButtonCheck';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '30%',
    background: colors.neutral010,
    minWidth: '270px',
  },
  option: {
    '&:hover': {
      backgroundColor: colors.neutral020,
      cursor: 'pointer',
    },
  },
  selected: {
    backgroundColor: colors.neutral020,
  },
  actionBtn: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  listItem: {
    display: 'block',
    padding: '1rem 2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem',
    },
  },
}));

function QCReportDrawer(props) {
  const classes = useStyles();

  const { open, onClose = () => {}, onConfirm = () => {} } = props;

  const { qcReports, qcOptionPrices, itemList, setQcReports } =
    estimateQcReportCostInfoHook((state) => ({
      qcReports: state.qcReports,
      qcOptionPrices: state.qcOptionPrices,
      itemList: state.itemList,
      setQcReports: state.setQcReports,
    }));

  const partCountStr =
    itemList > 1 ? `${itemList.length} parts` : `${itemList.length} part`;

  const [mainQcReport, setMainQcReport] = useState('');
  const [addOnsQcReports, setAddOnsQcReports] = useState({
    [QC_MATERIAL_CERTIFICATE]: false,
    [QC_COATING_FINISHING_CERTIFICATE]: false,
  });
  const [othersQcReports, setOthersQcReports] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const actionRef = useRef(null);

  const isBottom = useBottomCheck(actionRef, [
    mainQcReport,
    addOnsQcReports,
    othersQcReports,
  ]);

  useEffect(() => {
    if (isEmptyValue(qcReports)) {
      setMainQcReport(QC_NO_QC_REPORT);
    } else {
      setMainQcReport(qcReports.main);
      setAddOnsQcReports({
        [QC_MATERIAL_CERTIFICATE]: qcReports.addOns.includes(
          QC_MATERIAL_CERTIFICATE
        ),
        [QC_COATING_FINISHING_CERTIFICATE]: qcReports.addOns.includes(
          QC_COATING_FINISHING_CERTIFICATE
        ),
      });
      setOthersQcReports(
        isEmptyValue(qcReports.others) ? '' : qcReports.others?.join(',')
      );
    }
  }, [qcReports]);

  const handleConfirmButtonClick = () => {
    const selectedValue = {
      main: mainQcReport,
      addOns: Object.entries(addOnsQcReports)?.reduce((acc, [key, value]) => {
        if (value) {
          acc.push(key);
        }
        return acc;
      }, []),
      others: othersQcReports
        ?.split(',')
        ?.map((item) => item.trim())
        ?.filter((item) => !isEmptyValue(item)),
    };
    setQcReports(selectedValue);
    onConfirm(selectedValue);
  };

  const handleMainQcChange = (option) => {
    setMainQcReport(option);
    if (option === QC_NO_QC_REPORT) {
      setAddOnsQcReports({
        [QC_MATERIAL_CERTIFICATE]: false,
        [QC_COATING_FINISHING_CERTIFICATE]: false,
      });
      setOthersQcReports('');
    }
  };

  const handleChangeAddOns = (addsOnOption) => {
    const currentValue = addOnsQcReports[addsOnOption];
    setAddOnsQcReports({
      ...addOnsQcReports,
      [addsOnOption]: !currentValue,
    });
  };

  const renderAddOns = () => {
    return (
      <List>
        <div className={classes.listItem}>
          <FtrTypography
            type='heading'
            fontSize='18'
            style={{ paddingBottom: '0.5rem' }}
          >
            Add-ons
          </FtrTypography>
          <FtrTypography
            type='body'
            fontSize='12'
            style={{
              fontWeight: 300,
              paddingBottom: '0.3rem',
            }}
          >
            You may select more than one
          </FtrTypography>
        </div>
        <Divider />
        <div>
          <ListItem
            className={clsx(
              addOnsQcReports[QC_MATERIAL_CERTIFICATE] === true
                ? classes.selected
                : null,
              classes.option,
              classes.listItem
            )}
            onClick={() => handleChangeAddOns(QC_MATERIAL_CERTIFICATE)}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <FtrTypography
                type='subHeading'
                fontSize='16'
                style={{ color: colors.neutral070 }}
              >
                Material Certificate
              </FtrTypography>
              <FtrTypography
                type='subHeading'
                fontSize='14'
                style={{ color: colors.neutral070 }}
              >
                {qcOptionPrices[QC_MATERIAL_CERTIFICATE]?.split(' (')[0]}
              </FtrTypography>
            </div>
            <FtrTypography
              type='subHeading'
              fontSize='14'
              style={{ color: colors.neutral060, padding: '0.3rem 0' }}
            >
              {partCountStr}
            </FtrTypography>
            <FtrTypography
              type='body'
              fontSize='14'
              style={{ color: colors.neutral070 }}
            >
              Gain access to detailing of the specifications and quality of the
              materials used in the manufacturing process, ensuring compliance
              and transparency.
            </FtrTypography>
          </ListItem>
          <Divider />
          <ListItem
            className={clsx(
              addOnsQcReports[QC_COATING_FINISHING_CERTIFICATE] === true
                ? classes.selected
                : null,
              classes.option,
              classes.listItem
            )}
            onClick={() => handleChangeAddOns(QC_COATING_FINISHING_CERTIFICATE)}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <FtrTypography
                type='subHeading'
                fontSize='16'
                style={{ color: colors.neutral070 }}
              >
                Coating (Finishing) Certificate
              </FtrTypography>
              <FtrTypography
                type='subHeading'
                fontSize='14'
                style={{ color: colors.neutral070 }}
              >
                {
                  qcOptionPrices[QC_COATING_FINISHING_CERTIFICATE]?.split(
                    ' ('
                  )[0]
                }
              </FtrTypography>
            </div>
            <FtrTypography
              type='subHeading'
              fontSize='14'
              style={{ color: colors.neutral060, padding: '0.3rem 0' }}
            >
              {partCountStr}
            </FtrTypography>
            <FtrTypography
              type='body'
              fontSize='14'
              style={{ color: colors.neutral070 }}
            >
              Validate the quality and adherence of the applied coatings on your
              parts, ensuring durability and compliance with specified finishing
              standards.
            </FtrTypography>
          </ListItem>
          <Divider />
        </div>
      </List>
    );
  };

  const actionBtn = () => {
    let paddingRight = '2rem';
    if (isMobile) {
      paddingRight = '1rem';
    }
    if (isBottom) {
      paddingRight = '4rem';
    }
    return (
      <div
        className={classes.actionBtn}
        ref={actionRef}
        style={{
          padding: isBottom
            ? `1rem ${paddingRight} 1rem 1rem`
            : `1rem ${paddingRight}`,
        }}
      >
        <FtrButton
          color='blue'
          size='small'
          variant='contained'
          onClick={() => {
            handleConfirmButtonClick();
            onClose();
          }}
        >
          Confirm
        </FtrButton>
        <Box style={{ width: '1rem' }} />
        <FtrButton color='black' size='small' variant='text' onClick={onClose}>
          Cancel
        </FtrButton>
      </div>
    );
  };

  return (
    <Drawer
      anchor='right'
      open={open}
      PaperProps={{ className: classes.drawer }}
      BackdropProps={{
        style: {
          backgroundColor: colors.blackTint020,
        },
      }}
      onClose={() => {
        handleConfirmButtonClick();
        onClose();
      }}
    >
      <div className={classes.listItem}>
        <FtrTypography
          type='heading'
          fontSize='22'
          style={{ paddingBottom: '0.3rem' }}
        >
          Quality Certification
        </FtrTypography>
        <FtrTypography type='body' fontSize='16'>
          Certificates will be applied across the project.
        </FtrTypography>
      </div>
      <div>
        <List>
          <Divider />
          <ListItem
            className={clsx(
              mainQcReport === QC_NO_QC_REPORT ? classes.selected : null,
              classes.option,
              classes.listItem
            )}
            onClick={() => handleMainQcChange(QC_NO_QC_REPORT)}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <FtrTypography
                type='subHeading'
                fontSize='16'
                style={{ color: colors.neutral070 }}
              >
                Not required
              </FtrTypography>
              <FtrTypography
                type='subHeading'
                fontSize='14'
                style={{ color: colors.neutral070 }}
              >
                S$0.00
              </FtrTypography>
            </div>
          </ListItem>
          <Divider />
          <ListItem
            className={clsx(
              mainQcReport === QC_STANDARD_QC_REPORT ? classes.selected : null,
              classes.option,
              classes.listItem
            )}
            onClick={() => handleMainQcChange(QC_STANDARD_QC_REPORT)}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <FtrTypography
                type='subHeading'
                fontSize='16'
                style={{ color: colors.neutral070 }}
              >
                Standard QC Report
              </FtrTypography>
              <FtrTypography
                type='subHeading'
                fontSize='14'
                style={{ color: colors.neutral070 }}
              >
                {qcOptionPrices[QC_STANDARD_QC_REPORT]?.split(' (')[0]}
              </FtrTypography>
            </div>
            <FtrTypography
              type='subHeading'
              fontSize='14'
              style={{ color: colors.neutral060, padding: '0.3rem 0' }}
            >
              {partCountStr}
            </FtrTypography>
            <FtrTypography
              type='body'
              fontSize='14'
              style={{ color: colors.neutral070 }}
            >
              Obtain a detailed Standard QC Report for your manufactured parts,
              offering comprehensive insights into the quality and conformity of
              each component within your project.
            </FtrTypography>
          </ListItem>
          <Divider />
          <ListItem
            className={clsx(
              mainQcReport === QC_FORMAL_CMM_REPORT ? classes.selected : null,
              classes.option,
              classes.listItem
            )}
            onClick={() => handleMainQcChange(QC_FORMAL_CMM_REPORT)}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <FtrTypography
                type='subHeading'
                fontSize='16'
                style={{ color: colors.neutral070 }}
              >
                Formal/CMM Report
              </FtrTypography>
              <FtrTypography
                type='subHeading'
                fontSize='14'
                style={{ color: colors.neutral050 }}
              >
                Request Quote
              </FtrTypography>
            </div>
            <FtrTypography
              type='subHeading'
              fontSize='14'
              style={{ color: colors.neutral060, padding: '0.3rem 0' }}
            >
              {partCountStr}
            </FtrTypography>
            <FtrTypography
              type='body'
              fontSize='14'
              style={{ color: colors.neutral070 }}
            >
              Receive access in-depth, precision-based insights, ensuring
              meticulous quality control in parts manufacturing through advanced
              Coordinate Measuring Machine (CMM) analysis.
            </FtrTypography>
          </ListItem>
          <Divider />
        </List>
        {mainQcReport !== QC_NO_QC_REPORT && renderAddOns()}
        {actionBtn()}
      </div>
    </Drawer>
  );
}

export default QCReportDrawer;
