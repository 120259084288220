import React from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import Title from '../Title';
import { FlexColumn, FlexRowCenter } from '../layouts/FlexLayouts';
import { FtrButton, FtrTypography } from '../ftr-components';

import { getFileNameFromCadFile } from '../../utils/itemUtils';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 600,
    maxWidth: 800,
    borderRadius: 0,
  },
  space: {
    width: theme.spacing(2),
  },
}));

function DownloadFileInfoPopup(props) {
  const classes = useStyles();

  const {
    open = false,
    onClose = () => {},
    itemID,
    downloadFileTracking = [],
  } = props;

  const renderBody = () => {
    return (
      <FlexColumn style={{ gap: '1rem' }}>
        {downloadFileTracking?.map((item) => {
          const encodedFileName = item.file?.split('/').pop();
          const fileName = getFileNameFromCadFile(encodedFileName);
          return (
            <FlexColumn key={item.file}>
              <FtrTypography>{fileName}</FtrTypography>
              <ul style={{ margin: 0 }}>
                {item.tracking?.map((tracking) => {
                  return (
                    <li key={tracking.userID}>
                      <FtrTypography>
                        {tracking.userName} ({tracking.userID}) - Downloaded{' '}
                        {tracking.actionCount}x
                      </FtrTypography>
                    </li>
                  );
                })}
              </ul>
            </FlexColumn>
          );
        })}
      </FlexColumn>
    );
  };

  return (
    <div>
      <Dialog
        maxWidth='xl'
        open={open}
        onClose={onClose}
        aria-labelledby='download-file-info-popup'
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id='download-file-info-popup'>
          <Title
            contentTitle={`Download file Info for Item ID ${itemID}`}
            size='small'
          />
        </DialogTitle>
        <div
          style={{
            paddingBottom: '1rem',
          }}
        >
          <DialogContent
            style={{
              padding: '0 30px',
              paddingBottom: '2rem',
            }}
          >
            <React.Fragment>{renderBody()}</React.Fragment>
          </DialogContent>
          <FlexRowCenter>
            <FtrButton color='blue' onClick={onClose}>
              OK
            </FtrButton>
          </FlexRowCenter>
        </div>
      </Dialog>
    </div>
  );
}

export default DownloadFileInfoPopup;
