import React, { useReducer } from 'react';

import { makeStyles } from '@material-ui/core/styles/index';

import { Container, CircularProgress, Button } from '@material-ui/core';

import ShippingForm, {
  ShipmentInfo,
} from '../../components/forms/shipping-form/ShippingForm';

import { getJntRates } from '../../apis/shipmentApi';

import { isEmptyValue } from '../../utils/commonUtils';
import { convertPriceToCurrencyBeautified } from '../../utils/currencyUtils';

import { colors } from '../../palette';
import { COUNTRY_LIST, COUNTRY_NAMES } from '../../constants/countryConstants';
import { CURRENCY_CODE } from '../../constants/currencyConstants';

// --------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  buttonUpdate: {
    padding: '6px 16px',
    fontSize: '0.8rem',
  },
  editIcon: {
    fontSize: '0.9rem',
    '&:hover': {
      backgroundColor: colors.blue050,
      color: 'white',
      cursor: 'pointer',
      borderRadius: '50%',
    },
    padding: 2,
  },
}));

/**
 * this will support multiple items and quotes
 *
 * @returns
 */
function LocalShippingCalculatorMultipleItems() {
  const classes = useStyles();

  const defaultLocalState = {
    itemID: null,
    weight: null,
    length: null,
    width: null,
    height: null,
    totalWeight: null,
    jntPrice: '',
    isGetJntRatesProcessing: false,
    isSuccessGetJntRates: false,
    isCreateOrderJntProcessing: false,
    isSuccessJntCreateOrderRates: false,
    showConsigneeDetailsForm: false,
    factoremContactName: 'Factorem',
    factoremPhoneNumber: '+65 8925 8615',
    factoremAddress: '81 Ayer Rajah Crescent, #01-54, Singapore',
    factoremPostalCode: '139967',
  };

  const [localState, updateLocalState] = useReducer((prev, next) => {
    return { ...prev, ...next };
  }, defaultLocalState);

  const paramsRates = {
    weight: localState.totalWeight,
  };

  const validateParams = (params) => {
    let isFilled = true;
    for (const state in params) {
      if (isEmptyValue(params[state])) {
        isFilled = false;
      }
    }
    return isFilled;
  };

  const handleGetJNTShipmentFee = () => {
    updateLocalState({
      isGetJntRatesProcessing: true,
      isSuccessGetJntRates: false,
    });
    const body = {
      weight: Number(localState.totalWeight),
      length: Number(localState.length),
      width: Number(localState.width),
      height: Number(localState.height),
      country_code: COUNTRY_LIST[COUNTRY_NAMES.SINGAPORE].code,
    };
    getJntRates(body)
      .then((data) => {
        updateLocalState({
          isSuccessGetJntRates: true,
          price: convertPriceToCurrencyBeautified({
            currency: CURRENCY_CODE.SGD,
            price: data.rate,
          }),
        });
      })
      .finally(() => updateLocalState({ isGetJntRatesProcessing: false }));
  };

  return (
    <Container>
      <div>
        <ShippingForm
          onChangeState={(objStates) => updateLocalState(objStates)}
          isLocal
          initialValues={defaultLocalState}
        />
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={() => {
            handleGetJNTShipmentFee();
          }}
          disabled={
            localState.isGetJntRatesProcessing || !validateParams(paramsRates)
          }
        >
          {localState.isGetJntRatesProcessing && (
            <CircularProgress className={classes.circularProgress} size={20} />
          )}
          &nbsp;Get shipment fee
        </Button>
      </div>
      <div style={{ margin: '1rem 0 1rem' }}>
        <ShipmentInfo
          price={localState.price}
          service='Next Day Express Delivery'
          title='J&T Shipment'
          isLoading={localState.isGetJntRatesProcessing}
          isSuccess={localState.isSuccessGetJntRates}
        />
      </div>
    </Container>
  );
}

export default LocalShippingCalculatorMultipleItems;
