import React, { useState } from 'react';

import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import InternationalShippingCalculatorMultipleItems from './InternationalShippingCalculatorMultipleItems';
import LocalShippingCalculatorMultipleItems from './LocalShippingCalculatorMultipleItems';

const SHIPMENT_OPTIONS = {
  INTERNATIONAL: 'International',
  LOCAL: 'Local',
};

function ShipmentTools() {
  const [selectedShipmentOption, setSelectedShipmentOption] = useState(
    SHIPMENT_OPTIONS.INTERNATIONAL
  );

  const renderShipmentOption = () => {
    switch (selectedShipmentOption) {
      case SHIPMENT_OPTIONS.INTERNATIONAL: {
        return <InternationalShippingCalculatorMultipleItems />;
      }
      case SHIPMENT_OPTIONS.LOCAL: {
        return <LocalShippingCalculatorMultipleItems />;
      }
      default:
        return null;
    }
  };

  return (
    <Container>
      <FormControl component='fieldset' style={{ marginTop: '2rem' }}>
        <FormLabel id='shipping-mode'>Select Option</FormLabel>
        <RadioGroup
          row
          value={selectedShipmentOption}
          onChange={(event) => setSelectedShipmentOption(event.target.value)}
        >
          {Object.entries(SHIPMENT_OPTIONS)?.map(([key, value]) => {
            return (
              <FormControlLabel
                key={key}
                value={value}
                control={<Radio color='primary' />}
                label={value}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      {renderShipmentOption()}
    </Container>
  );
}

export default ShipmentTools;
