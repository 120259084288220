import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

import SidebarContainer from './sidebar/SidebarContainer';

import { removeGuestUser, retrieveUserLocation } from '../actions';

import { isEmptyValue } from '../utils/commonUtils';

import { setCookie } from '../services/cookiesService';

import useCustomerSettingsHook from '../hooks/useCustomerSettingsHook';

import { getGuestUserInfoSelector } from '../selectors/userSelector';

import { REDIRECT_URL_KEY } from '../constants/cookiesConstants';
import { ROLE_TYPES } from '../constants';

// -------------------------------------------------------------------------------------------------------------------

function ProtectedRoute({
  component: Component,
  pageTitle,
  isAuthenticated,
  isVerifying,
  role,
  userID,
  deniedRoles = [],
  allowedRoles = [],
  allowAll,
  allowedPermissionsForGuests = [],
  ...rest
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  useCustomerSettingsHook(userID);

  const [title, setTitle] = useState(pageTitle);

  const guestUserInfo = useSelector(getGuestUserInfoSelector);

  useEffect(() => {
    if (!isAuthenticated) {
      setCookie(REDIRECT_URL_KEY, `${location.pathname}${location.search}`, {
        path: '/',
        maxAge: 15 * 60, // 15 minutes
      });
    }
  }, []);

  useEffect(() => {
    setTitle(pageTitle);
  }, [pageTitle]);

  useEffect(() => {
    if (isAuthenticated && userID) {
      dispatch(retrieveUserLocation(userID));
    }
  }, [isVerifying, isAuthenticated]);

  const changeTitle = (_title) => {
    setTitle(_title);
  };

  if (isVerifying) {
    return <div />;
  }

  if (Component.type === Redirect) {
    return Component;
  }

  const getRedirectLoginComp = (props) => {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location },
        }}
      />
    );
  };

  if (isEmptyValue(role) && !allowAll) {
    return getRedirectLoginComp({});
  }

  const getRedirectDefaultComp = (props) => {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location },
        }}
      />
    );
  };

  const getAuthenticatedComp = (props) => {
    let comp;

    if (deniedRoles && deniedRoles.includes(role) && !allowAll) {
      comp = getRedirectDefaultComp(props);
    } else if (allowedRoles && allowedRoles.includes(role) && !allowAll) {
      comp = <Component {...props} />;
    } else if (role === ROLE_TYPES.BUYER || allowAll) {
      comp = <Component {...props} changeTitle={changeTitle} />;
    } else {
      comp = <Component {...props} />;
    }
    if (!isEmptyValue(guestUserInfo)) {
      const { permissions = [] } = guestUserInfo;
      const hasPermission = permissions?.some((perm) =>
        allowedPermissionsForGuests.includes(perm)
      );
      // if the guest token allows access to the route, keep the token in the cookie
      // if not, delete the guest token cookie as it is not relevant to the new route. As this route is authenticated,
      // it will redirect to login page but will load the URL to redirect to upon login
      if (!hasPermission) {
        dispatch(removeGuestUser());
      }
      return comp;
    }
    return (
      <SidebarContainer
        role={role}
        title={title}
        allowAll={allowAll}
        {...props}
      >
        {comp}
      </SidebarContainer>
    );
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated || allowAll
          ? getAuthenticatedComp({ ...props })
          : getRedirectLoginComp({ ...props })
      }
    />
  );
}

function mapStateToProps(state) {
  return {
    role: state.auth?.user?.role,
    userID: state.auth?.user?.userID,
  };
}

export default connect(mapStateToProps)(ProtectedRoute);
