import React from 'react';

import { FtrTooltip } from '../ftr-components';

import RedWarningSvgIcon from '../../assets/icons/red_warning.svg';

import { getToleranceDisplayText } from '../../utils/toleranceUtils';

// -------------------------------------------------------------------------------------------------

function DifferentToleranceWarningIcon(props) {
  const {
    sameRepeatOwner = false,
    hasFalloutReason = false,
    iconSize = 14,
    tdeTolerance,
    unitType,
  } = props;

  if (!sameRepeatOwner || !hasFalloutReason) {
    return null;
  }

  const toleranceValue = getToleranceDisplayText({
    tolerance: tdeTolerance,
    unitType,
  });

  const tooltipTitle = `Tolerance does not match technical drawing: ${toleranceValue}. Please reset tolerance or update drawing for an instant quote.`;

  return (
    <FtrTooltip description={tooltipTitle} arrow>
      <img
        src={RedWarningSvgIcon}
        width={iconSize}
        height={iconSize}
        alt='red-warning-icon'
      />
    </FtrTooltip>
  );
}

export default DifferentToleranceWarningIcon;
