import React from 'react';
import { isEmpty } from 'lodash';
import { getQcReportsList } from '../utils/projectUtils';
import { isEmptyValue } from '../utils/commonUtils';

import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';

const getCustomerUploadFiles = (customerUploadFiles) => {
  if (isEmpty(customerUploadFiles)) {
    return [];
  }
  const customerUploadFilesFlat = customerUploadFiles?.flatMap((files) => {
    if (isEmpty(files)) {
      return [];
    }
    const filesStrArr = files?.filter((f) => !!f)?.map((f) => f.url);
    return filesStrArr;
  });
  return customerUploadFilesFlat;
};

const getCustomerUploadFilesName = (rowData) => {
  // customerUploadFiles is an array of array
  const { customerUploadFiles } = rowData;
  if (isEmptyValue(customerUploadFiles)) {
    return '';
  }
  let result = '';
  for (const fileList of customerUploadFiles) {
    if (!isEmptyValue(fileList)) {
      for (const file of fileList) {
        if (!result.includes(file.fileName)) {
          result += result ? `,${file.fileName}` : `${file.fileName}`;
        }
      }
    }
  }
  return result;
};

export const customerUploadFilesCol = {
  title: 'Customer Upload Files',
  headerName: 'Customer Upload Files',
  field: 'customerUploadFiles',
  exportData: (rowData) => {
    const customerUploadFilesFlat = getCustomerUploadFiles(
      rowData.customerUploadFiles
    );
    return customerUploadFilesFlat?.join('; ');
  },
  valueGetter: ({ row: rowData }) => {
    const customerUploadFilesFlat = getCustomerUploadFiles(
      rowData.customerUploadFiles
    );
    return customerUploadFilesFlat?.join('; ');
  },
  hidden: true,
  hide: true,
  export: true,
};

export const customerUploadFilesNameCol = {
  headerName: 'Customer Upload Files',
  field: 'customerUploadFilesName',
  valueGetter: ({ row: rowData }) => {
    const result = getCustomerUploadFilesName(rowData);
    return result;
  },
  renderCell: ({ row: rowData }) => {
    const result = getCustomerUploadFilesName(rowData);
    return <DataGridWrapTextCell text={result} />;
  },
};

export const qcReportsCol = {
  title: 'QC Reports',
  headerName: 'QC Reports',
  field: 'qcReports',
  exportData: (rowData) => {
    const qcReportsList = getQcReportsList(rowData.qcReports);
    return qcReportsList?.join(', ');
  },
  valueGetter: ({ row: rowData }) => {
    const qcReportsList = getQcReportsList(rowData.qcReports);
    return qcReportsList?.join(', ');
  },
  hidden: true,
  hide: true,
  export: true,
};

export const disabledReasonCol = {
  title: 'Disabled Reason',
  headerName: 'Disabled Reason',
  field: 'disabledReason',
  exportData: (rowData) => rowData.disabledReason,
  valueGetter: ({ row: rowData }) => rowData.disabledReason,
  hidden: true,
  hide: true,
  export: true,
};
