import React, { useReducer } from 'react';
import CreateXeroInvoiceItemsPopupV2 from '../components/popups/CreateXeroInvoiceItemsPopupV2';

function withCreateXeroInvoiceItemsPopupV2HoC(WrappedComponent) {
  return function CreateXeroInvoiceItemsPopupV2HoC(props) {
    const [localState, updateLocalState] = useReducer(
      (prev, next) => {
        return { ...prev, ...next };
      },
      {
        openCreateXeroInvoicePopup: false,
        refetchTableData: () => {},
      }
    );
    return (
      <>
        <WrappedComponent
          updateXeroInvoiceItemPopupHoC={updateLocalState}
          {...props}
        />
        {localState.openCreateXeroInvoicePopup && (
          <CreateXeroInvoiceItemsPopupV2
            open={localState.openCreateXeroInvoicePopup}
            onClose={() =>
              updateLocalState({ openCreateXeroInvoicePopup: false })
            }
            onSuccess={() => localState.refetchTableData()}
          />
        )}
      </>
    );
  };
}

export default withCreateXeroInvoiceItemsPopupV2HoC;
