import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoadingPriceIcon from '../../ftr-components/LoadingPriceIcon';
import CustomerFeedbackButtons from '../../CustomerFeedbackButtons';
import { FlexRow } from '../../layouts/FlexLayouts';

import ViewBulkPricingButton from './ViewBulkPricingButton';
import { FtrTypography } from '../../ftr-components';

import { isPpeItem } from '../../../utils/itemUtils';
import { convertPriceWithQuantityToCurrency } from '../../../utils/currencyUtils';
import { isNumber } from '../../../utils/numberUtils';

import { colors } from '../../../palette';

import { updateCadPartIfExists } from '../../../actions';

import { FEEDBACK_STATUS } from '../../../constants/feedbackConstants';

import { upsertPriceFeedback } from '../../../apis/ppeFeedbackApi';

// -------------------------------------------------------------------------------------------------

export function DisplayItemPrice(props) {
  const dispatch = useDispatch();

  const {
    part,
    showLoadingPriceScreen,
    updatePriceFeedbackState,
    updateBulkPricingDialogState,
  } = props;

  const currency = useSelector((state) => state.auth.location.currency);
  const exchangeRates = useSelector((state) => state.auth.rates);
  const userID = useSelector((state) => state.auth.user.userID);
  const exchangeRate = exchangeRates[currency];

  const { repeatOrder, similarOrder, ppePricingStatus, tdeStatus } = part;

  const handleGoodFeedback = () => {
    dispatch(
      updateCadPartIfExists({
        id: part.id,
        priceFeedback: FEEDBACK_STATUS.GOOD,
      })
    );
    // upsert feedback into PPE Price feedback table
    upsertPriceFeedback({
      iqLogsID: part.ppePriceLogId,
      userID,
      priceFeedback: FEEDBACK_STATUS.GOOD,
    });
  };

  const handleBadFeedback = () => {
    dispatch(
      updateCadPartIfExists({
        id: part.id,
        priceFeedback: FEEDBACK_STATUS.BAD,
        showFeedbackCard: true,
      })
    );
    upsertPriceFeedback({
      iqLogsID: part.ppePriceLogId,
      userID,
      priceFeedback: FEEDBACK_STATUS.BAD,
    });
  };

  const handleFeedbackSubmit = () => {
    updatePriceFeedbackState({
      open: true,
      item: part,
    });
  };

  const handleFeedbackClose = () => {
    dispatch(
      updateCadPartIfExists({
        id: part.id,
        showFeedbackCard: false,
      })
    );
  };

  const renderManualRfq = () => (
    <>
      <FtrTypography
        type='heading'
        fontSize='16'
        style={{ color: colors.neutral060 }}
        id='manual-rfq-required'
      >
        Manual Quotation required
      </FtrTypography>
    </>
  );

  const renderPriceDisplay = () => {
    const newOrder = !repeatOrder && !similarOrder;

    const { unitPriceStr, totalPriceStr } = convertPriceWithQuantityToCurrency({
      totalPrice: part.totalPrice ?? 0,
      quantity: part.qty ?? 1,
      currency,
      exchangeRate,
    });

    const isPPEItem = isPpeItem(part);

    const showFeedback = isNumber(part.price) && !showLoadingPriceScreen;

    return isPPEItem ? (
      <>
        <FlexRow style={{ gap: 0, justifyContent: 'end' }}>
          <div id='ppe-item-price'>
            <FtrTypography type='heading' fontSize='18'>
              {totalPriceStr}
            </FtrTypography>
            <FtrTypography
              type='body'
              fontSize='12'
              style={{ marginBottom: '0.5rem' }}
            >
              {unitPriceStr}/unit
            </FtrTypography>
            {showFeedback && (
              <CustomerFeedbackButtons
                showFeedbackInput={part.showFeedbackCard}
                upIsActive={part.priceFeedback === FEEDBACK_STATUS.GOOD}
                handleUpClick={handleGoodFeedback}
                downIsActive={part.priceFeedback === FEEDBACK_STATUS.BAD}
                handleDownClick={handleBadFeedback}
                handleFeedbackSubmit={handleFeedbackSubmit}
                handleFeedbackClose={handleFeedbackClose}
                style={{ position: 'relative', left: '10px' }}
                feedbackType={'Price'}
              />
            )}
          </div>
        </FlexRow>

        {newOrder && (
          <ViewBulkPricingButton
            onClick={() => {
              updateBulkPricingDialogState({
                open: true,
                item: part,
              });
            }}
          />
        )}
      </>
    ) : (
      renderManualRfq()
    );
  };

  const showLoadingPriceIcon =
    ppePricingStatus === 'loading' ||
    tdeStatus === 'loading' ||
    showLoadingPriceScreen === true;

  if (showLoadingPriceIcon) {
    return <LoadingPriceIcon size={20} id='loading-ppe' />;
  }

  return renderPriceDisplay();
}
