import React from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles/index';

import { Badge, Button, Typography } from '@material-ui/core';

import { isPpeItem } from '../utils/itemUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { isSelectableQuote } from '../utils/quotationUtils';

import { ITEM_STATUS_MAPPING, RFQ_ITEM_STATUS } from '../constants/itemStatus';
import { FEATURE_FLAG_MULTI_CHECKOUT } from '../constants/featureFlagConstants';

import { colors } from '../palette';

// ------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  buttonBackground: {
    flex: '0 0 20%',
    textAlign: 'center',
    width: 'fit-content',
  },
  statusWhiteButton: {
    width: '100%',
    textTransform: 'capitalize',
    background: colors.fontWhite,
    border: `solid 1px ${colors.inputBorderBlue}`,
    borderRadius: '5px',
    fontSize: '9pt',
    color: colors.blue060,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    minWidth: 100,
  },
  statusDarkBlueButton: {
    width: '100%',
    textTransform: 'capitalize',
    background: colors.blue060,
    border: `solid 1px ${colors.blue060}`,
    borderRadius: '5px',
    fontSize: '9pt',
    color: colors.fontWhite,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    minWidth: 100,
    '&:hover': {
      color: colors.fontBlack,
    },
  },
  statusBlueButton: {
    width: '100%',
    textTransform: 'capitalize',
    background: colors.inputBorderBlue,
    border: `solid 1px ${colors.inputBorderBlue}`,
    borderRadius: '5px',
    fontSize: '9pt',
    color: colors.fontWhite,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    minWidth: 100,
  },
  statusGreenButton: {
    width: '100%',
    textTransform: 'capitalize',
    background: colors.successGreen,
    borderRadius: '5px',
    fontSize: '9pt',
    color: colors.fontWhite,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    minWidth: 100,
  },
  statusLightBlueButton: {
    width: '100%',
    textTransform: 'capitalize',
    background: colors.statusLightBlue,
    border: `solid 1px ${colors.statusLightBlue}`,
    borderRadius: '5px',
    fontSize: '9pt',
    color: colors.fontWhite,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    minWidth: 100,
  },
  statusOrangeGradientButton: {
    width: '100%',
    textTransform: 'capitalize',
    background: colors.orangeGradient,
    borderRadius: '5px',
    fontSize: '9pt',
    color: colors.fontWhite,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    minWidth: 100,
  },
  statusGreyButton: {
    width: '100%',
    textTransform: 'capitalize',
    background: colors.inputLabelGrey,
    border: `solid 1px ${colors.inputLabelGrey}`,
    borderRadius: '5px',
    fontSize: '9pt',
    color: colors.fontGrey,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    minWidth: 100,
  },
  quoteNumberBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: 'white',
      color: 'green',
      border: '1px solid green',
    },
  },
}));

/**
 * @deprecated
 * @param {*} param0
 * @returns
 */
function StatusButton({
  item,
  onClickBtn,
  hasQuoteSelected = false,
  allowUnverifiedQuotes = false,
}) {
  const classes = useStyles();
  const location = useLocation();

  let className = '';
  let buttonText = '';
  if (item.status === ITEM_STATUS_MAPPING.VERIFYING && !isPpeItem(item)) {
    className = classes.statusWhiteButton;
    buttonText = RFQ_ITEM_STATUS[item.status];
  } else if (item.status === ITEM_STATUS_MAPPING.VERIFYING) {
    // PPE item
    className = classes.statusLightBlueButton;
    buttonText = 'Processing';
  } else if (item.status === ITEM_STATUS_MAPPING.SOURCING) {
    className = classes.statusOrangeGradientButton;
    buttonText = RFQ_ITEM_STATUS[item.status];
  } else if (item.status === ITEM_STATUS_MAPPING.QUOTES_AVAILABLE) {
    if (FEATURE_FLAG_MULTI_CHECKOUT !== 'true' || !hasQuoteSelected) {
      className = classes.statusDarkBlueButton;
      const splitPath = location.pathname?.split('/');
      const checkManageProjectsWithID =
        splitPath.length >= 3 &&
        splitPath[1] === 'manage-projects' &&
        splitPath[2] &&
        !splitPath[3];
      buttonText =
        RFQ_ITEM_STATUS[item.status] === 'Quotes Available' &&
        checkManageProjectsWithID
          ? 'View Quotes'
          : RFQ_ITEM_STATUS[item.status];
    } else {
      className = classes.statusGreenButton;
      buttonText = 'Quote Selected';
    }
  } else if (
    item.status === ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS &&
    isPpeItem(item) &&
    item.ppeVerified === 0
  ) {
    className = classes.statusLightBlueButton;
    buttonText = 'Processing';
  } else if (item.status === ITEM_STATUS_MAPPING.ORDER_IN_PROGRESS) {
    className = classes.statusBlueButton;
    buttonText = RFQ_ITEM_STATUS[item.status];
  } else if (
    item.status === ITEM_STATUS_MAPPING.ORDER_READY ||
    item.status === ITEM_STATUS_MAPPING.DELIVERED
  ) {
    className = classes.statusGreenButton;
    buttonText = RFQ_ITEM_STATUS[item.status];
  } else {
    return <Typography className={classes.statusText}>Disabled</Typography>;
  }

  const selectableItemQuotations = item.quotations?.filter((quote) =>
    isSelectableQuote(quote, allowUnverifiedQuotes)
  );

  const renderButton = () => {
    return (
      <Button fullWidth={true} className={className} onClick={onClickBtn}>
        {buttonText}
      </Button>
    );
  };

  if (
    item.status !== ITEM_STATUS_MAPPING.QUOTES_AVAILABLE ||
    isEmptyValue(selectableItemQuotations) ||
    selectableItemQuotations?.length <= 1
  ) {
    return renderButton();
  }

  return (
    <Badge
      className={classes.quoteNumberBadge}
      badgeContent={selectableItemQuotations.length}
    >
      {renderButton()}
    </Badge>
  );
}

export default StatusButton;
