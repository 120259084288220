import { first } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';

import BlueButton from '../buttons/BlueButton';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index';
import Title from '../Title';

import { getLatestPPEFeedbacksForWatchingSuppliers } from '../../apis/itemApi';

import { watchingStatusInfo } from '../../constants/watchingJob';
import { ORDER_STATUS } from '../../constants';

import { transformArrayToMapObject } from '../../utils/arrayUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import {
  formatDateWithTime,
  withDateValidation,
} from '../../utils/dateTimeUtils';

import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '37.5rem',
    minWidth: '12.5rem',
    maxWidth: '50rem',
    borderRadius: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
}));

function WatchingJobPopup(props) {
  const classes = useStyles();

  const { open, onClose, projectID, itemID, data, title } = props;

  const watchingSuppliersIDs = data?.map(
    (watchingSupplier) => watchingSupplier?.userID
  );
  const { data: latestFeedbackData } = useQuery(
    'getLatestPPEFeedbacksForWatchingSuppliers',
    () =>
      getLatestPPEFeedbacksForWatchingSuppliers(itemID, {
        limit: 1,
        projectIDs: [projectID],
        watchingSuppliersIDs: watchingSuppliersIDs,
      }),
    {
      enabled:
        open && !isEmptyValue(itemID) && !isEmptyValue(watchingSuppliersIDs),
    }
  );

  const [latestPriceFeedbacksPerUserID, setLatestPriceFeedbacksPerUserID] =
    useState({});
  const [
    latestLeadTimeFeedbacksPerUserID,
    setLatestLeadTimeFeedbacksPerUserID,
  ] = useState({});

  useEffect(() => {
    const { latestSupplierPriceFeedbacks, latestSupplierLeadTimeFeedbacks } =
      latestFeedbackData || {};

    if (!isEmptyValue(latestSupplierPriceFeedbacks)) {
      setLatestPriceFeedbacksPerUserID(
        transformArrayToMapObject(latestSupplierPriceFeedbacks, 'userID') || {}
      );
    }
    if (!isEmptyValue(latestSupplierLeadTimeFeedbacks)) {
      setLatestLeadTimeFeedbacksPerUserID(
        transformArrayToMapObject(latestSupplierLeadTimeFeedbacks, 'userID') ||
          {}
      );
    }
  }, [latestFeedbackData]);

  if (!open) return null;

  const renderBodyPopupWatching = () => {
    return (
      <ul style={{ textAlign: 'start' }}>
        {data?.map((watchingPart) => {
          // Latest price feedback by this watching supplier.
          const {
            createdAt: latestPriceFeedbackCreatedAt,
            feedback: latestPriceFeedback,
            quotationID: latestPriceFeedbackQuotationID,
          } = first(
            latestPriceFeedbacksPerUserID[watchingPart?.userID]
              ?.latestPriceFeedbacks
          ) || {};

          // Latest lead-time feedback by this watching supplier.
          const {
            createdAt: latestLeadTimeFeedbackCreatedAt,
            feedback: latestLeadTimeFeedback,
          } =
            first(
              latestLeadTimeFeedbacksPerUserID[watchingPart?.userID]
                ?.latestLeadTimeFeedbacks
            ) || {};

          return (
            <li key={watchingPart.userID}>
              {watchingPart.name} ({watchingPart.userID}) -{' '}
              <span
                style={{
                  color:
                    watchingStatusInfo.color[watchingPart.watchingStatus] ||
                    'black',
                }}
              >
                {watchingStatusInfo.text[watchingPart.watchingStatus] ||
                  watchingPart.watchingStatus}{' '}
                -{' '}
              </span>
              <ul>
                <li>
                  {watchingPart.quoteStatus ? (
                    <React.Fragment>
                      <span>Quoted: </span>
                      <span
                        style={{
                          textTransform: 'capitalize',
                          color:
                            watchingPart.quoteStatus === ORDER_STATUS.ACCEPTED
                              ? colors.successGreen
                              : 'black',
                        }}
                      >
                        {watchingPart.quoteStatus}{' '}
                      </span>
                      {!isEmptyValue(watchingPart.quoteID) && (
                        <>
                          (Quote ID:{' '}
                          <Link
                            target='_blank'
                            to={`/order/edit/${watchingPart.quoteID}`}
                          >
                            #{watchingPart.quoteID}
                          </Link>
                          )
                        </>
                      )}
                    </React.Fragment>
                  ) : (
                    <span>No Quote</span>
                  )}
                </li>
                {!isEmptyValue(
                  latestPriceFeedbacksPerUserID[watchingPart?.userID]
                ) && (
                  <>
                    <li>
                      Submitted{' '}
                      {latestPriceFeedback === 'bad' ? (
                        <span style={{ color: 'red' }}>negative</span>
                      ) : (
                        <span style={{ color: 'green' }}>positive</span>
                      )}{' '}
                      price feedback on{' '}
                      {withDateValidation(formatDateWithTime)(
                        latestPriceFeedbackCreatedAt
                      )}
                      .
                    </li>
                  </>
                )}
                {!isEmptyValue(
                  latestLeadTimeFeedbacksPerUserID[watchingPart?.userID]
                ) && (
                  <>
                    <li>
                      Submitted{' '}
                      {latestLeadTimeFeedback === 'bad' ? (
                        <span style={{ color: 'red' }}>negative</span>
                      ) : (
                        <span style={{ color: 'green' }}>positive</span>
                      )}{' '}
                      lead-time feedback on{' '}
                      {withDateValidation(formatDateWithTime)(
                        latestLeadTimeFeedbackCreatedAt
                      )}
                      .
                    </li>
                    {!isEmptyValue(latestPriceFeedbackQuotationID) && (
                      <li>
                        <span>
                          View all feedback on quote{' '}
                          <Link
                            target='_blank'
                            to={`/order/edit/${latestPriceFeedbackQuotationID}`}
                          >
                            #{latestPriceFeedbackQuotationID}
                          </Link>
                        </span>
                        .
                      </li>
                    )}
                  </>
                )}
              </ul>
              <br />
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='warning-dialog-title'
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id='warning-dialog-title'>
        <Title contentTitle={title} size='small' />
      </DialogTitle>
      <div
        style={{
          paddingBottom: '1rem',
        }}
      >
        <DialogContent
          style={{
            padding: '0 30px',
            paddingBottom: '2rem',
            textAlign: 'center',
          }}
        >
          {renderBodyPopupWatching()}
        </DialogContent>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <BlueButton onBtnClick={onClose} btnContent='OK' />
        </div>
      </div>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default WatchingJobPopup;
