import queryString from 'query-string';

import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

// -------------------------------------------------------------------------------------------------

export const getAllRegionBasedMarkupsApi = async () => {
  const requestUrl = `${BACKEND_SERVICE_URL}/region-based-markups`;

  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const getRegionBasedMarkupsByParamsApi = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/region-based-markups?${queryString.stringify(
    params
  )}`;

  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const upsertRegionBasedMarkupApi = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/region-based-markups/upsert`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const deleteRegionBasedMarkupApi = async (id) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/region-based-markups/${id}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'DELETE',
    headers: getAuthorizedHeader(),
  });
};
