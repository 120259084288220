import { ceil } from 'lodash';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import { Tooltip } from '@material-ui/core';

import { Lens as LensIcon } from '@material-ui/icons';

import HorizontalExpandSpace from '../components/ftr-components/HorizontalExpandSpace';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../components/grid-data/CustomToolbar';
import GridDataPagination from '../components/grid-data/GridDataPagination';
import { ExportCsvButton } from '../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../components/grid-data/buttons/SearchBar';
import EmailStatusIcon from '../components/icons/EmailStatusIcon';
import { FlexRow } from '../components/layouts/FlexLayouts';
import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';

import { getUnSeenBrevoFailedTracking } from '../apis/brevoApi';
import {
  exportCsvRFQEmailsTrackingItems,
  getGenerateRFQEmailsTrackingItems,
  getTrackingBrevo,
} from '../apis/generateRfqEmailsTrackingApi';

import {
  getUserRoleSelector,
  getUserSelector,
} from '../selectors/userSelector';

import { exportCsvApi } from '../utils/csvExportUtils';
import { addDays, compareDate, dateTzSingapore } from '../utils/dateTimeUtils';
import { isSuperAdminRole } from '../utils/roleUtils';
import { convertToDigits } from '../utils/stringUtils';

import { EMAIL_BREVO_TYPES } from '../constants/emailConstants';

import { colors } from '../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '1rem',
    '& .MuiTablePagination-root': {
      marginRight: '4rem',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: colors.blue060,
      fontSize: '11pt',
      fontWeight: 600,
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'center',
      whiteSpace: 'normal',
      justifyContent: 'center !important',
    },
  },
  enterReasonBtn: {
    display: 'flex',
    margin: '0 auto',
    alignItems: 'center',
    width: 'fit-content',
    color: 'black',
    padding: '0.4rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#00000011',
    },
    backgroundColor: '#00000008',
    borderRadius: 4,
    textTransform: 'uppercase',
    fontSize: '9px',
    lineHeight: '13px',
    whiteSpace: 'nowrap',
  },
  uploadIcon: {
    color: 'gray',
    marginRight: '5px',
  },
  listReasons: {
    fontSize: '11px',
    fontWeight: '500',
    lineHeight: 'normal',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
  },
}));

function GenerateRFQEmailsQuoteInfoTab() {
  const classes = useStyles();

  const user = useSelector(getUserSelector);
  const role = useSelector(getUserRoleSelector);

  const [tableData, setTableData] = useState([]);
  const [brevoData, setBrevoData] = useState([]);
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const { data: unSeenBrevoFailedLog } = useQuery(
    ['getUnSeenBrevoFailedTracking', user?.userID],
    () => {
      if (user?.userID) {
        return getUnSeenBrevoFailedTracking(user?.userID);
      }

      return undefined;
    }
  );

  const loadTableData = () => {
    updateTableQueryParams({ loading: true });
    getGenerateRFQEmailsTrackingItems(tableQueryParams)
      .then((data) => {
        setTableData(data.rows);
        updateTableQueryParams({ totalCount: data.totalCount });
        const trackingIDs = data?.rows?.map((rfq) => rfq.id);
        getTrackingBrevo({ trackingIDs }).then((resBrevo) => {
          setBrevoData(resBrevo);
        });
      })
      .finally(() => {
        updateTableQueryParams({ loading: false });
      });
  };

  let timer;
  useEffect(() => {
    if (!tableQueryParams.search) {
      loadTableData();
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        loadTableData();
      }, 300);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [
    tableQueryParams.search,
    tableQueryParams.page,
    tableQueryParams.pageSize,
  ]);

  const getRfqID = (rowData) => {
    const partnerAccountID = convertToDigits(rowData.partnerAccountID);
    const projectID = convertToDigits(rowData.projectID);
    const itemProjectID = convertToDigits(rowData.itemProjectID);
    const itemID = convertToDigits(rowData.itemID);
    // If there is itemID, set 00000000
    if (partnerAccountID === '') {
      return '00000000';
    }
    let result = partnerAccountID + projectID;
    if (rowData.itemProjectID) {
      result += itemProjectID + itemID;
    }
    return result;
  };

  const renderDate = (dateParam) => {
    const dateTimeStr = dateTzSingapore(dateParam, 'DD MMM YYYY HH:mm');
    const splitStr = dateTimeStr?.split(' ');
    const date = splitStr?.slice(0, 3)?.join(' ');
    const time = splitStr[3];
    return (
      <Tooltip title='Date and time displayed in SGT'>
        <div style={{ lineHeight: 'normal' }}>
          {date}
          <br />
          {time}
        </div>
      </Tooltip>
    );
  };

  const columns = [
    {
      headerName: 'Unseen',
      field: 'unseen',
      renderCell: ({ row: rowData }) => {
        const { brevoUnseen } = useMemo(() => {
          const brevo = brevoData?.find(
            (tracking) => tracking.id === rowData.id
          );
          const _brevoUnseen = unSeenBrevoFailedLog?.rows?.find(
            (tracking) => tracking.brevoMessageID === brevo?.brevoMessageID
          );
          return { brevo, brevoUnseen: _brevoUnseen };
        }, [brevoData, unSeenBrevoFailedLog, rowData.id]);

        if (brevoUnseen?.hasSeen !== 0) return null;
        return (
          <Tooltip title='Unseen log'>
            <LensIcon color='error' fontSize='small' />
          </Tooltip>
        );
      },
    },
    {
      headerName: 'RFQ ID',
      field: 'rfqID',
      renderCell: ({ row: rowData }) => getRfqID(rowData),
      valueGetter: ({ row: rowData }) => getRfqID(rowData),
      width: 120,
    },
    {
      headerName: 'Sent Date',
      field: 'sentDate',
      width: 150,
      renderCell: ({ row: rowData }) => renderDate(rowData.createdAt),
      valueGetter: ({ row: rowData }) =>
        dateTzSingapore(rowData?.createdAt, 'DD MMM YYYY HH:mm'),
    },
    {
      headerName: 'Email status',
      field: 'emailStatus',
      renderCell: ({ row: rowData }) => {
        const brevo = brevoData?.find((tracking) => tracking.id === rowData.id);
        if (rowData.status === 'Failed') {
          return (
            <EmailStatusIcon
              status='failed'
              emailType={rowData?.type}
              date={rowData?.createdAt}
            />
          );
        }
        if (!brevo) {
          return null;
        }
        const scheduledDate = addDays(new Date(brevo?.ebtCreatedAt), 1);
        const showScheduled =
          compareDate(new Date(), scheduledDate) < 0 &&
          [EMAIL_BREVO_TYPES.RFQ_ITEM, EMAIL_BREVO_TYPES.RFQ_PROJECT].includes(
            brevo.type
          );
        return (
          <FlexRow>
            <EmailStatusIcon
              status={brevo?.lastStatus}
              emailType={brevo?.type}
              date={brevo?.ebtUpdatedAt}
            />
            {showScheduled && (
              <EmailStatusIcon
                status='scheduled'
                emailType={`${brevo?.type}-reminder`}
                date={scheduledDate}
              />
            )}
          </FlexRow>
        );
      },
    },
    {
      headerName: 'Technology',
      field: 'technology',
      width: 120,
    },
    {
      headerName: 'Type',
      field: 'type',
    },
    {
      headerName: 'Project ID',
      field: 'projectID',
      renderCell: ({ row: rowData }) => {
        return rowData.projectID || rowData.itemProjectID;
      },
      valueGetter: ({ row: rowData }) => {
        return rowData.projectID || rowData.itemProjectID;
      },
    },
    {
      headerName: 'Part ID',
      field: 'itemID',
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapTextCell
            text={rowData.itemID || rowData.itemIDs?.join(', ')}
          />
        );
      },
      valueGetter: ({ row: rowData }) => {
        return rowData.itemID || rowData.itemIDs?.join(', ');
      },
      width: 150,
    },
    {
      headerName: 'Partner Name',
      field: 'partnerName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.partnerName} />
      ),
      width: 150,
    },
    {
      headerName: 'Partner Email',
      field: 'partnerEmail',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.partnerEmail} />
      ),
      width: 150,
    },
    {
      headerName: 'Additional Remarks',
      field: 'remarks',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.remarks} />
      ),
      width: 180,
    },
    {
      headerName: 'Design Files Sent',
      field: 'designFilesSent',
      renderCell: ({ row: rowData }) => {
        return rowData.hasAttachment ? 'Yes' : 'No';
      },
      valueGetter: ({ row: rowData }) => {
        return rowData.hasAttachment ? 'Yes' : 'No';
      },
    },
    {
      headerName: 'RFQ Status',
      field: 'status',
    },
    {
      headerName: 'Admin Sent by',
      field: 'adminName',
      renderCell: ({ row: rowData }) => (
        <DataGridWrapTextCell text={rowData.adminName} />
      ),
      width: 150,
    },
  ];

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleExportCSV = () => {
    const fileName = `All Generate RFQ Emails Tracking Items`;
    exportCsvApi(exportCsvRFQEmailsTrackingItems(tableQueryParams), fileName);
  };

  const getToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleExportCSV}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.container}>
      <DataGrid
        autoHeight
        paginationMode='server'
        rows={tableData ?? []}
        columns={columns?.map((col) => ({
          ...col,
          sortable: false,
        }))}
        getRowId={(row) => row.id}
        rowHeight={120}
        headerHeight={80}
        components={{
          Toolbar: getToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        pageSize={tableQueryParams.pageSize}
        rowsPerPageOptions={[10, 20, 50]}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        page={tableQueryParams.page}
        onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
        rowCount={tableQueryParams.totalCount}
        loading={tableQueryParams.loading}
        disableRowSelectionOnClick
        disableSelectionOnClick
        disableColumnMenu
      />
    </div>
  );
}

export default GenerateRFQEmailsQuoteInfoTab;
