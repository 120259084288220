import React, { useEffect, useReducer, useState } from 'react';

import { CsvBuilder } from 'filefy';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { compose } from 'redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { DataGrid } from '@mui/x-data-grid';

import { IconButton, Tooltip } from '@material-ui/core';
import { DeleteOutline, Edit } from '@material-ui/icons';

import CustomToolbar, {
  DataGridToolbarLayout,
} from '../../components/grid-data/CustomToolbar';
import { AddNewButton } from '../../components/grid-data/buttons/AddNewButton';
import { ExportCsvButton } from '../../components/grid-data/buttons/ExportCsvButton';
import SearchBar from '../../components/grid-data/buttons/SearchBar';

import {
  deleteRegionBasedMarkupApi,
  getAllRegionBasedMarkupsApi,
} from '../../apis/regionBasedMarkupApi';

import withAddEditRegionBasedMarkupPopupHOC from '../../hocs/withAddEditRegionBasedMarkupPopupHOC';
import withFtrDeletePopupHOC from '../../hocs/withFtrDeletePopupHOC';

import { getUserRoleSelector } from '../../selectors/userSelector';
import { isSuperAdminRole } from '../../utils/roleUtils';

import { generateCurrentCustomDateTimeString } from '../../util';
import { isEmptyValue } from '../../utils/commonUtils';
import { transformCsvExportDataGrid } from '../../utils/csvExportUtils';
import { includesIgnoreCase } from '../../utils/stringUtils';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { COUNTRY_NAMES } from '../../constants/countryConstants';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
}));

function RegionBasedMarkupsDataGrid(props) {
  const classes = useStyles();

  const {
    updateAddEditRegionBasedMarkupPopupState = () => {},
    updateDeletePopupHOCState = () => {},
  } = props;

  const role = useSelector(getUserRoleSelector);

  const [tableData, setTableData] = useState([]);
  const {
    data: allRegionBasedMarkups = [],
    refetch: refetchRegionBasedMarkups,
  } = useQuery(['allRegionBasedMarkups'], () => getAllRegionBasedMarkupsApi());

  // Table search
  const [tableQueryParams, updateTableQueryParams] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 0,
      pageSize: 20,
      search: '',
      totalCount: 0,
      loading: false,
    }
  );

  const [filteredTableData, setFilterTableData] = useState([]);
  const [searchAutoFocus, setSearchAutoFocus] = useState(false);

  const columns = [
    {
      headerName: 'Actions',
      field: 'actions',
      minWidth: 130,
      export: false,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ row: rowData }) => {
        if (rowData.name === COUNTRY_NAMES.SINGAPORE) {
          return null;
        }

        return [
          <Tooltip title='Edit' key='edit'>
            <IconButton
              aria-label='edit'
              onClick={() => {
                updateAddEditRegionBasedMarkupPopupState({
                  open: true,
                  id: rowData.id,
                });
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>,
          rowData.name !== COUNTRY_NAMES.AMERICA ? (
            <Tooltip title='Delete' key='delete'>
              <IconButton
                aria-label='delete'
                onClick={() => {
                  updateDeletePopupHOCState({
                    open: true,
                    title: `Do you want to delete markup config for country: ${rowData.name}?`,
                    onConfirm: () => handleDeleteClick(rowData.id),
                  });
                }}
              >
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          ) : null,
        ];
      },
    },
    {
      headerName: 'Country',
      field: 'name',
      align: 'left',
      minWidth: 300,
      flex: 1,
    },
    {
      headerName: 'PPE Price Markup (%)',
      field: 'markup',
      align: 'left',
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: 'Lead Time Markup (Work Days)',
      field: 'leadTimeMarkup',
      align: 'left',
      minWidth: 100,
      flex: 1,
    },
    {
      headerName: 'Remarks',
      field: 'remarks',
      align: 'left',
      minWidth: 300,
      flex: 1,
    },
  ];

  let timer;
  useEffect(() => {
    updateTableQueryParams({ loading: true });
    if (!tableQueryParams.search) {
      setFilterTableData(tableData);
      updateTableQueryParams({
        loading: false,
      });
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        const filteredRows = tableData?.filter((row) => {
          return (
            includesIgnoreCase(row.id, tableQueryParams.search) ||
            includesIgnoreCase(row.name, tableQueryParams.search)
          );
        });
        setFilterTableData(filteredRows);
        updateTableQueryParams({
          loading: false,
        });
      }, 300);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [
    tableData,
    tableQueryParams.search,
    tableQueryParams.page,
    tableQueryParams.pageSize,
  ]);

  useEffect(() => {
    if (isEmptyValue(allRegionBasedMarkups)) {
      return;
    }

    setTableData(allRegionBasedMarkups);
  }, [allRegionBasedMarkups]);

  function handleDeleteClick(id) {
    deleteRegionBasedMarkupApi(id)
      .then(() => {
        updateDeletePopupHOCState({
          open: false,
        });
        notifySuccess('Region-based markup has been deleted successfully');
        refetchRegionBasedMarkups();
      })
      .catch((err) => {
        notifyError(err.message, {
          additional: {
            err,
            id,
          },
        });
      });
  }

  const handleSearch = (searchTerm) => {
    updateTableQueryParams({
      page: 0,
      search: searchTerm,
    });
  };

  const handleDownloadCsv = () => {
    const fileName = `All Project Order Ready ${generateCurrentCustomDateTimeString()}.csv`;
    const { exportedColumns, exportedData } = transformCsvExportDataGrid(
      columns,
      filteredTableData
    );
    const builder = new CsvBuilder(fileName);
    builder
      .setDelimeter(',')
      .setColumns(exportedColumns)
      .addRows(exportedData)
      .exportFile();
  };

  const getCustomToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <CustomToolbar
          buttons={[
            <AddNewButton
              key='add-new-promotion-code'
              handleClick={() => {
                updateAddEditRegionBasedMarkupPopupState({
                  open: true,
                });
              }}
              name='Add New'
            />,
          ]}
        />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
              autoFocus={searchAutoFocus}
              onFocus={() => {
                setSearchAutoFocus(true);
              }}
              onBlur={() => {
                setSearchAutoFocus(false);
              }}
            />,
            <ExportCsvButton
              key='export-csv'
              handleClick={handleDownloadCsv}
              show={isSuperAdminRole(role)}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes.body}>
      <DataGrid
        autoHeight
        columns={columns}
        rows={isEmptyValue(filteredTableData) ? [] : filteredTableData}
        getRowId={(row) => row.id}
        editMode='row'
        rowsPerPageOptions={[10, 20, 50]}
        components={{ Toolbar: getCustomToolbar }}
        pageSize={tableQueryParams.pageSize}
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        loading={tableQueryParams.loading}
        localeText={{ toolbarExport: 'Export CSV' }}
        disableSelectionOnClick
        disableRowSelectionOnClick
      />
    </div>
  );
}

export default compose(
  withAddEditRegionBasedMarkupPopupHOC,
  withFtrDeletePopupHOC
)(RegionBasedMarkupsDataGrid);
