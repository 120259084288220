import React from 'react';

function FinanceCreditNoteEmailInfo({ isForSupplier }) {
  return (
    <>
      <p style={{ marginTop: 4 }}>
        You are about to send a notification to&nbsp;
        <a href='mailto:finance@factorem.co'>finance@factorem.co</a> to
        {isForSupplier ? ' pay the supplier' : ' credit note the client'}.
        Please note that this action cannot be undone.
      </p>
      {isForSupplier && (
        <p style={{ marginTop: 0 }}>
          Note that you can only send one credit note per PO to finance. To make
          any further changes to the credit note or to upload another credit
          note, please reach out to both the finance and developer teams.
        </p>
      )}
    </>
  );
}

export default FinanceCreditNoteEmailInfo;
