import { get } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import ManageProjectItemsPresentationalV3 from './ManageProjectItemsPresentationalV3';

import withRequestMoreQuotesFeatureTourHOC from '../../hocs/withRequestMoreQuotesFeatureTourHOC';
import withManageProjectItemsPopupsHOC from './withManageProjectItemsPopupsHOC';

import { editSingleProjectApi, getProjectOwners } from '../../apis/projectApi';
import { getUserInfo } from '../../apis/userApi';

import {
  initMultiCheckoutFormState,
  removeSelectedQuotes,
  resetCheckoutPricingSummary,
  UPDATE_CHECKOUT_SUMMARY,
  updateShippingMode,
} from '../../actions/multiCheckoutForm';

import { getExchangeRateSelector } from '../../selectors/exchangeRatesSelector';
import { getMultiCheckoutFormSelector } from '../../selectors/multiCheckoutFormSelector';
import {
  getUserCurrencySelector,
  getUserRoleSelector,
} from '../../selectors/userSelector';

import useActionTracking from '../../hooks/useActionTrackingHook';
import useCustomerAvailablePromoCode from '../../hooks/useCustomerAvailablePromoCode';
import useIsAdminOrHigherUser from '../../hooks/useIsAdminOrHigherUser';
import useUpdatePaymentType from '../../hooks/useUpdatePaymentTypeHook';
import useUserShippingAddress from '../../hooks/useUserShippingAddressHook';
import useCustomerProjectInfo from './useCustomerProjectInfoHook';
import useUpdateCheckoutFormCreditType from './useUpdateCheckoutFormCreditTypeHook';

import ProjectItemsContext from '../../context/ProjectItemsContext';

import { isEmptyValue } from '../../utils/commonUtils';
import { formatDeliveryDate } from '../../utils/dateTimeUtils';
import * as deliveryUtils from '../../utils/deliveryUtils';
import { getItemStageStatusText, isItemEditable } from '../../utils/itemUtils';
import { isUnverifiedQuote } from '../../utils/quotationUtils';

import AppContext from '../../context/AppContext';

import { ROLE_TYPES } from '../../constants';
import { DEFAULT_SHIP_MODE_LIST } from '../../constants/itemConstants';
import { SEVERITY } from '../../constants/errorMessageConstants';

// --------------------------------------------------------------------------------

const WELCOME_BONUS_POPUP_MANAGE_PROJECT_ITEMS =
  'welcome-bonus-popup-manage-project-items';

function ManageProjectItemsV3(props) {
  const {
    isAdminView = false,
    toggleAdminView = () => {},
    setLoadingMessage = () => {},
    updateMovePartsPopupState = () => {},
    updateShareQuotePopupState = () => {},
    updateConfirmOrderPopupState = () => {},
    updateDeleteProjectFeedbackPopupState = () => {},
    updateEditProjectOwnerPopupState = () => {},
    updateDeletePartsPopupState = () => {},
    updateWelcomeBonusPopupHOCState = () => {},
    updateTourState = () => {},
  } = props;

  const { setSnackbarMessage } = useContext(AppContext);

  const dispatch = useDispatch();

  const { projectID } = useParams();

  const userRole = useSelector(getUserRoleSelector);

  const multiCheckoutForm = useSelector(getMultiCheckoutFormSelector);
  const selectedQuotes = multiCheckoutForm?.selectedQuotes || [];
  const { status: multiCheckoutFormStatus } = multiCheckoutForm;

  const currency = useSelector(getUserCurrencySelector);
  const exchangeRate = useSelector(getExchangeRateSelector);
  const isAdminOrHigherUser = useIsAdminOrHigherUser();

  const [
    {
      customerID,
      currentProjectDetail,
      allParts = [],
      currentProjectName: originProjectName,
      isProjectDeletable,
      projectOwner: originProjectOwner,
      creatorEmail,
      isProjectLoading = false,
    },
    { refetchProjectInfoByID },
  ] = useCustomerProjectInfo(projectID);

  const { creditType } = useUpdateCheckoutFormCreditType(customerID);

  const [{ promoCode }] = useCustomerAvailablePromoCode(customerID);
  const {
    data: bonusTrackingData,
    isLoading: bonusTrackingLoading,
    isFetching: bonusTrackingFetching,
    refetch: bonusTrackingRefetch,
  } = useActionTracking(customerID, WELCOME_BONUS_POPUP_MANAGE_PROJECT_ITEMS);

  const [filteredParts, setFilteredParts] = useState([]);
  const [currentProjectName, setCurrentProjectName] =
    useState(originProjectName);
  const [onEditProject, setOnEditProject] = useState(false);
  const [projectOwner, setProjectOwner] = useState(originProjectOwner);
  const [selectedFilters] = useState([]);
  const [qcReports, setQcReports] = useState(null);
  const [shippingModeList, setShippingModeList] = useState(
    Object.values(DEFAULT_SHIP_MODE_LIST)
  );
  const [shippingMode, setShippingMode] = useState(null);
  const [disableShippingMode, setDisableShippingMode] = useState(true);
  const [showCheckoutSummaryFullPanel, setShowCheckoutSummaryFullPanel] =
    useState(isAdminView);
  const [userSwitchShippingMode, setUserSwitchShippingMode] = useState(false);
  const [isShareQuotationForm, setIsShareQuotationForm] = useState(false);

  const deliveryDateModes = get(multiCheckoutForm, [
    'checkoutPriceSummary',
    'deliveryDateModes',
  ]);
  const deliveryModePrices = get(multiCheckoutForm, [
    'checkoutPriceSummary',
    'deliveryModePrices',
  ]);

  const [{ addresses }, setAddresses] = useUserShippingAddress(customerID, {
    shouldUpdateState: true,
  });

  const { data: userInfo } = useQuery(['getUserInfo', customerID], () => {
    if (!customerID) {
      return null;
    }
    return getUserInfo(customerID);
  });

  const MoreProjectActionsMenuList = useMemo(() => {
    const list = [];
    if (isProjectDeletable) {
      list.push({
        text: 'Delete Project',
        onClick: () => {
          handleDeleteProject(currentProjectDetail);
        },
      });
    }

    if (!isEmptyValue(allParts)) {
      list.push(
        {
          text: 'Move Parts',
          onClick: () => {
            updateMovePartsPopupState({
              open: true,
              items: currentProjectDetail?.items,
            });
          },
        },
        {
          text: 'Delete Parts',
          onClick: () => {
            updateDeletePartsPopupState({
              open: true,
              items: currentProjectDetail?.items,
            });
          },
        }
      );
    }

    if (isAdminOrHigherUser) {
      list.push({
        text: isAdminView ? 'Change to customer view' : 'Change to admin view',
        onClick: handleToggleAdminView,
      });
    }

    return list;
  }, [
    isProjectDeletable,
    isAdminOrHigherUser,
    isAdminView,
    currentProjectDetail,
    handleToggleAdminView,
  ]);

  // --

  useEffect(() => {
    dispatch(initMultiCheckoutFormState());
    return () => {
      dispatch(resetCheckoutPricingSummary());
    };
  }, []);

  useUpdatePaymentType(currency);

  useEffect(() => {
    if (
      userRole !== ROLE_TYPES.BUYER ||
      isEmptyValue(promoCode) ||
      isEmptyValue(currentProjectDetail)
    ) {
      updateWelcomeBonusPopupHOCState({
        open: false,
      });
      return;
    }

    if (
      bonusTrackingLoading ||
      bonusTrackingFetching ||
      !isEmptyValue(bonusTrackingData)
    ) {
      updateWelcomeBonusPopupHOCState({
        open: false,
      });
      return;
    }

    updateWelcomeBonusPopupHOCState({
      open: true,
      actionTrackingKey: WELCOME_BONUS_POPUP_MANAGE_PROJECT_ITEMS,
      refetch: bonusTrackingRefetch,
    });
  }, [
    promoCode,
    currentProjectDetail,
    bonusTrackingLoading,
    bonusTrackingData,
    userRole,
  ]);

  useEffect(() => {
    if (isProjectLoading) {
      setLoadingMessage('Loading project...');
      return;
    }

    setLoadingMessage('');

    if (isEmptyValue(currentProjectName)) {
      setCurrentProjectName(originProjectName);
    }

    if (isEmptyValue(projectOwner)) {
      setProjectOwner(originProjectOwner);
    }
  }, [isProjectLoading]);

  useEffect(() => {
    setShowCheckoutSummaryFullPanel(isAdminView);
  }, [isAdminView]);

  useEffect(() => {
    refetchProjectInfoByID().then(updateProjectOwnerDisplay);
    dispatch(initMultiCheckoutFormState());
  }, [projectID]);

  useEffect(() => {
    setCurrentProjectName(originProjectName);
  }, [originProjectName]);

  useEffect(() => {
    const newFilteredParts = isEmptyValue(selectedFilters)
      ? allParts
      : allParts?.filter((item) => {
          const itemStatusText = getItemStageStatusText(item);
          return selectedFilters.includes(itemStatusText);
        });
    setFilteredParts(newFilteredParts);
  }, [allParts, selectedFilters]);

  useEffect(() => {
    if (!isEmptyValue(currentProjectDetail)) {
      setQcReports(currentProjectDetail.qcReports);
    }
  }, [currentProjectDetail?.qcReports]);

  useEffect(() => {
    const { selectedQuotes: _selectedQuotes } = multiCheckoutForm ?? {};

    if (isEmptyValue(_selectedQuotes) && !isAdminView) {
      setShowCheckoutSummaryFullPanel(false);
    }

    if (!isEmptyValue(deliveryDateModes)) {
      setShippingModeList(
        Object.values(DEFAULT_SHIP_MODE_LIST)
          ?.filter((mode) =>
            Object.keys(deliveryModePrices).includes(mode.value)
          )
          ?.map((mode) => {
            const dateStr = deliveryDateModes[mode.value];
            return {
              ...mode,
              date: formatDeliveryDate(dateStr),
              fee:
                Number(deliveryModePrices[mode.value].fee) === 0
                  ? 'Free'
                  : deliveryModePrices[mode.value].feeStr,
            };
          })
      );

      const defaultShippingMode = deliveryUtils.getDefaultShippingModeByCountry(
        addresses?.shipping?.country,
        { deliveryModePrices }
      );
      if (
        defaultShippingMode &&
        defaultShippingMode !== shippingMode &&
        !userSwitchShippingMode
      ) {
        setShippingMode(defaultShippingMode);
        dispatch(
          updateShippingMode(defaultShippingMode, UPDATE_CHECKOUT_SUMMARY.NO)
        );
      }
      setDisableShippingMode(false);
    } else {
      setShippingModeList(Object.values(DEFAULT_SHIP_MODE_LIST));
      setDisableShippingMode(true);
      adjustShippingModeIfNeeded();
    }
  }, [
    multiCheckoutForm?.selectedQuotes,
    multiCheckoutForm?.checkoutPriceSummary,
  ]);

  useEffect(() => {
    adjustShippingModeIfNeeded();
  }, [addresses?.shipping?.country]);

  function adjustShippingModeIfNeeded() {
    if (isEmptyValue(addresses?.shipping?.country)) {
      return;
    }

    // if shipping destination is outside of SGP then only DHL_PRIORITY shipping mode is allowed
    const _shippingMode = deliveryUtils.getDefaultShippingModeByCountry(
      addresses?.shipping?.country,
      {
        deliveryModePrices,
      }
    );

    setShippingMode(_shippingMode);
    dispatch(updateShippingMode(_shippingMode, UPDATE_CHECKOUT_SUMMARY.NO));
  }

  async function handleEditProject(_projectID, newProjectDetail) {
    try {
      await editSingleProjectApi(_projectID, newProjectDetail);
      setSnackbarMessage('Your project name has been changed!');
      refetchProjectInfoByID();
    } catch {
      setSnackbarMessage(
        'Your project name cannot be changed. Please try again later.',
        {
          severity: SEVERITY.ERROR,
          errorTitle: 'Edit project failed!',
          body: {
            projectID: _projectID,
            newProjectDetail,
          },
        }
      );
    }
  }

  async function updateProjectOwnerDisplay() {
    return getProjectOwners(projectID).then((owners) => {
      if (!isEmptyValue(owners)) {
        setProjectOwner(owners[0]);
      } else if (currentProjectDetail) {
        setProjectOwner({
          ownerEmail: currentProjectDetail.userEmail,
          ownerName: currentProjectDetail.userName,
        });
      }
    });
  }

  const handleProjectNameOnChange = (value) => {
    const hasSameValue = currentProjectName === value;
    if (hasSameValue) {
      return;
    }

    setCurrentProjectName(value);
    let newProjectDetail = {
      name: value,
    };
    handleEditProject(projectID, newProjectDetail);
    setOnEditProject(false);
  };

  const handleEditButtonOnClick = () => {
    setOnEditProject(true);
  };

  function handleDeleteProject(project) {
    updateDeleteProjectFeedbackPopupState({
      open: true,
      projectID: project.projectID,
    });
  }

  function handleToggleAdminView() {
    const isNextViewAdmin = !isAdminView;
    if (!isNextViewAdmin) {
      const unverifiedQuotes = selectedQuotes
        ?.filter((selectedQuote) => isUnverifiedQuote(selectedQuote))
        ?.map((quote) => quote.quotationID);
      if (!isEmptyValue(unverifiedQuotes)) {
        dispatch(removeSelectedQuotes(unverifiedQuotes));
      }
    }

    toggleAdminView();
  }

  // TODO: temporarily disabled this
  // const handleFilterChange = (event) => {
  //   const { name, checked } = event.target;
  //   const newFilters = checked
  //     ? [...selectedFilters, name]
  //     : selectedFilters?.filter(item => item !== name);
  //   setSelectedFilters(newFilters);
  // }

  return (
    <ProjectItemsContext.Provider
      value={{
        addresses,
        creditType,
        isAdminView,
        projectID,
        projectOwner,
        qcReports,
        currentProjectName,
        onEditProject,
        currentProjectDetail,
        creatorEmail,
        customerID,
        isProjectDeletable,
        multiCheckoutForm,
        shippingModeList,
        disableShippingMode,
        multiCheckoutFormStatus,
        selectedQuotes,
        shippingMode,
        currency,
        exchangeRate,
        isShareQuotationForm,
        showCheckoutSummaryFullPanel,
        filteredParts,
        customerCountry: userInfo?.country,
        isItemEditable,
        allParts,
        projectMCTs: currentProjectDetail?.multipleCheckouts,
        setShowCheckoutSummaryFullPanel,
        refetchProjectInfoByID,
        setAddresses,
        MoreProjectActionsMenuList,
        handleProjectNameOnChange,
        handleDeleteProject,
        updateEditProjectOwnerPopupState,
        updateProjectOwnerDisplay,
        handleEditButtonOnClick,
        setShippingMode,
        updateShippingMode,
        setUserSwitchShippingMode,
        updateShareQuotePopupState,
        updateConfirmOrderPopupState,
        setIsShareQuotationForm,
        updateTourState,
      }}
    >
      {!isProjectLoading && <ManageProjectItemsPresentationalV3 />}
    </ProjectItemsContext.Provider>
  );
}

ManageProjectItemsV3.displayName = 'ManageProjectItems';

export default compose(
  withRouter,
  withManageProjectItemsPopupsHOC,
  withRequestMoreQuotesFeatureTourHOC
)(ManageProjectItemsV3);
