import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import {
  ppeTechnologyOptions,
  ppeSurfaceFinishOptions,
  ppeToleranceOptions,
  ppeQuantityOptions,
  ppeSafeguardPriceOptions,
  ppeMarkupOptions,
  ppeComplexityOptions,
  ppeFileSizeOptions,
  ppeParameters,
  csmOptions,
} from '../../constants/PPEConstants';

import {
  getAllPpeControlConfigs,
  updatePpeControlConfigs,
} from '../../apis/ppeSettingsApi';

import PpeMarkupForm from '../../components/forms/PpeMarkupForm';
import PpeSafeguardPriceForm from '../../components/forms/PpeSafeguardPriceForm';
import BlueButton from '../../components/buttons/BlueButton';
import PpeDataManagement from '../../components/forms/PpeDataManagement';
import FeatureFlagsSwitchForm from '../../components/forms/FeatureFlagsSwitchForm';
import PpeMarkupForm3DP from '../../components/forms/PpeMarkupForm3DP';
import PpeSwitchFormContainer from './PpeSwitchFormContainer';

import { notifyError, notifySuccess } from '../../services/notificationService';

import { colors } from '../../palette';

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
  },
  twoColumns: {
    display: 'flex',
    columnGap: '25px',
    padding: '0',
  },
  saveButtonWrapper: {},
  container: {
    margin: '25px, 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '25px',
    padding: '25px',
    border: `3px solid ${colors.bgLightGrey}`,
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '50px',
  },
  inputField: {
    marginTop: '10px',
    fontSize: 15,
    padding: '0px 8px',
    background: colors.fontWhite,
    border: `1px solid ${colors.inputBorderBlue}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: `${colors.fontGrey}`,
    width: '100%',
  },
}));

const technologyOptionsDefaultState = ppeTechnologyOptions?.reduce(
  (acc, option) => {
    return { ...acc, [option]: '0' };
  },
  {}
);

const surfaceFinishOptionsDefaultState = ppeSurfaceFinishOptions?.reduce(
  (acc, option) => {
    return { ...acc, [option]: '0' };
  },
  {}
);

const toleranceOptionsDefaultState = ppeToleranceOptions?.reduce(
  (acc, option) => {
    return { ...acc, [option]: '0' };
  },
  {}
);

const quantityOptionsDefaultState = ppeQuantityOptions?.reduce(
  (acc, option) => {
    return { ...acc, [option]: '0' };
  },
  {}
);

const markupOptionsDefaultState = ppeMarkupOptions?.reduce((acc, option) => {
  return { ...acc, [option]: '0' };
}, {});

const safeguardPriceOptionsDefaultState = ppeSafeguardPriceOptions?.reduce(
  (acc, option) => {
    return { ...acc, [option]: '0' };
  },
  {}
);

const complexityOptionsDefaultState = ppeComplexityOptions?.reduce(
  (acc, option) => {
    return { ...acc, [option]: '0' };
  },
  {}
);

const fileSizeOptionsDefaultState = ppeFileSizeOptions?.reduce(
  (acc, option) => {
    return { ...acc, [option]: '0' };
  },
  {}
);

const parametersOptionsDefaultState = ppeParameters?.reduce((acc, option) => {
  return { ...acc, [option]: '0' };
}, {});

const csmOptionsDefaultState = csmOptions?.reduce((acc, option) => {
  return { ...acc, [option]: '0' };
}, {});

function PpeControlPanelCommonTab() {
  const classes = useStyles();

  const [technologySwitchState, setTechnologySwitchState] = useState(
    technologyOptionsDefaultState
  );

  const [materialsCheckboxState, setMaterialsCheckboxState] = useState([]);
  const [surfaceFinishCheckboxState, setSurfaceFinishCheckboxState] = useState(
    surfaceFinishOptionsDefaultState
  );
  const [toleranceCheckboxState, setToleranceCheckboxState] = useState(
    toleranceOptionsDefaultState
  );
  const [quantityState, setQuantityState] = useState(
    quantityOptionsDefaultState
  );
  const [markupState, setMarkupState] = useState(markupOptionsDefaultState);
  const [safeguardPriceState, setSafeguardPriceState] = useState(
    safeguardPriceOptionsDefaultState
  );
  const [complexityState, setComplexityState] = useState(
    complexityOptionsDefaultState
  );

  const [fileSizeState, setFileSizeState] = useState(
    fileSizeOptionsDefaultState
  );
  const [parametersState, setParametersState] = useState(
    parametersOptionsDefaultState
  );
  const [csmState, setCsmState] = useState(csmOptionsDefaultState);

  const getPpeState = () => {
    getAllPpeControlConfigs()
      .then((response) => {
        setTechnologySwitchState(response.technology);
        setMaterialsCheckboxState(response.materials);
        setSurfaceFinishCheckboxState(response.surfaceFinish);
        setToleranceCheckboxState(response.tolerance);
        setQuantityState(response.quantity);
        setMarkupState(response.markup);
        setSafeguardPriceState(response.safeguardPrice);
        setComplexityState(response.complexity);
        setFileSizeState(response.fileSize);
        setParametersState(response.parameters);
        setCsmState(response.csm);
      })
      .catch((e) => console.log(e));
  };

  const savePpeState = () => {
    //To do insert backend api, format the payload accordingly
    let payload = {
      technology: technologySwitchState,
      tolerance: toleranceCheckboxState,
      quantity: quantityState,
      markup: markupState,
      safeguardPrice: safeguardPriceState,
      complexity: complexityState,
      fileSize: fileSizeState,
      parameters: parametersState,
      csm: csmState,
    };
    updatePpeControlConfigs(payload)
      .then(() => {
        notifySuccess('Your PPE settings have been saved!');
        getPpeState();
      })
      .catch((e) => {
        console.log(e);
        notifyError('Your PPE settings cannot be saved. Please try again');
      });
  };

  const handleTechnologySwitchChange = (technology, newValue) => {
    setTechnologySwitchState({
      ...technologySwitchState,
      [technology]: newValue ? '1' : '0',
    });
  };

  const handleMaterialMarkupChange = (settingID, newValue) => {
    const _materials = materialsCheckboxState?.map((material) => {
      if (material.id === settingID) {
        return {
          ...material,
          materialMarkup: newValue,
        };
      }
      return material;
    });
    setMaterialsCheckboxState(_materials);
  };

  const handleSurfaceFinishCheckboxChange = (surfaceFinish, newValue) => {
    setSurfaceFinishCheckboxState({
      ...surfaceFinishCheckboxState,
      [surfaceFinish]: newValue ? '1' : '0',
    });
  };

  const handleToleranceCheckboxChange = (tolerance, newValue) => {
    setToleranceCheckboxState({
      ...toleranceCheckboxState,
      [tolerance]: newValue ? '1' : '0',
    });
  };

  const handleQuantityChange = (quantity, newValue) => {
    setQuantityState({
      ...quantityState,
      [quantity]: newValue,
    });
  };

  const handleParametersSwitchChange = (parameter, newValue) => {
    setParametersState({
      ...parametersState,
      [parameter]: newValue ? '1' : '0',
    });
  };

  const handleAllowedOrderSelectionChange = {
    materialMarkup: handleMaterialMarkupChange,
    tolerance: handleToleranceCheckboxChange,
    surfaceFinish: handleSurfaceFinishCheckboxChange,
    quantity: handleQuantityChange,
  };

  const handleMarkupChange = (markup, newValue) => {
    setMarkupState({
      ...markupState,
      [markup]: newValue,
    });
  };

  const handleSafeguardPriceChange = (safeguardPrice, newValue) => {
    setSafeguardPriceState({
      ...safeguardPriceState,
      [safeguardPrice]: newValue,
    });
  };

  const handleComplexityChange = (complexity, newValue) => {
    setComplexityState({
      ...complexityState,
      [complexity]: newValue,
    });
  };

  const handleFileSizeChange = (fileSize, newValue) => {
    setFileSizeState({
      ...fileSizeState,
      [fileSize]: newValue,
    });
  };

  const handleCsmChange = (csm, newValue) => {
    setCsmState({
      ...csmState,
      [csm]: newValue,
    });
  };

  const handleSafeguardPriceFormChange = {
    safeguardPrice: handleSafeguardPriceChange,
    complexity: handleComplexityChange,
    fileSize: handleFileSizeChange,
    csm: handleCsmChange,
  };

  const handleSubmitButtonClick = () => {
    savePpeState();
  };

  useEffect(() => {
    getPpeState();
  }, []);

  const renderToleranceAndQuantity = () => {
    return (
      <div className={classes.rowContainer}>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Tolerance</FormLabel>
          <FormGroup>
            {Object.keys(toleranceCheckboxState)
              .sort()
              ?.map((tolerance, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={!!+toleranceCheckboxState[tolerance]}
                        onChange={(event) =>
                          handleAllowedOrderSelectionChange.tolerance(
                            tolerance,
                            event.target.checked
                          )
                        }
                        name={tolerance}
                      />
                    }
                    label={'<= ' + tolerance}
                  />
                );
              })}
          </FormGroup>
        </FormControl>
        <FormControl component='fieldset'>
          <FormLabel component='legend'>Quantity</FormLabel>
          <FormGroup>
            {Object.keys(quantityState)?.map((quantity, index) => {
              return (
                <Input
                  key={index}
                  className={classes.inputField}
                  disableUnderline={true}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100000,
                    type: 'number',
                  }}
                  value={quantityState[quantity]}
                  onChange={(event) =>
                    handleAllowedOrderSelectionChange.quantity(
                      quantity,
                      event.target.value
                    )
                  }
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </div>
    );
  };

  return (
    <div className={classes.body}>
      <Container className={classes.twoColumns}>
        <PpeDataManagement />
        <FeatureFlagsSwitchForm />
      </Container>
      <PpeSwitchFormContainer
        handlers={{
          handleTechnologySwitchChange,
          handleParametersSwitchChange,
        }}
        states={{ technologySwitchState, parametersState }}
      />
      <Container className={classes.container}>
        <div>
          <Typography variant='h6'>Tolerance & Quantity</Typography>
          <Divider />
        </div>
        <div className={classes.rowContainer}>
          {renderToleranceAndQuantity()}
        </div>
      </Container>
      <PpeMarkupForm
        markupState={markupState}
        onMarkupChange={handleMarkupChange}
      />
      <PpeMarkupForm3DP
        markupState={markupState}
        onMarkupChange={handleMarkupChange}
      />
      <PpeSafeguardPriceForm
        safeguardPriceState={safeguardPriceState}
        complexityState={complexityState}
        fileSizeState={fileSizeState}
        csmState={csmState}
        onSafeguardPriceFormChange={handleSafeguardPriceFormChange}
      />
      <div className={classes.saveButtonWrapper}>
        <BlueButton
          btnContent={'Save Changes'}
          onBtnClick={() => handleSubmitButtonClick()}
        />
      </div>
    </div>
  );
}

export default PpeControlPanelCommonTab;
