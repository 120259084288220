export const EXTRACT_DEFECT_KEYS_MAPPING = {
  deep_holes: 'deep_holes',
  small_holes: 'small_holes',
  holes_close_to_edge: 'holes_close_to_edge',
  thin_walls: 'thin_walls',
  undercuts: 'undercuts',
  dfm_success: 'dfm_success',
  part_sizes_too_big: 'part_sizes_too_big',
  sharp_edges: 'sharp_edges',
  visualization_links: 'visualization_links',
  overhangs: 'overhangs',
  fine_features: 'fine_features',
};

export const EXTRACT_DEFECT_RULES_MAPPING = {
  deep_holes: 0,
  holes_close_to_edge: 0,
  thin_walls: false,
  small_holes: 0,
  undercuts: 0,
  dfm_success: true,
  sharp_edges: 0,
  overhangs: false,
  fine_features: false,
  part_sizes_too_big: {
    tech: '3DP',
    value: 0,
  },
};

export const KEYS_TO_FEEDBACK_MAPPING = {
  deep_holes: 'deepHolesFeedback',
  holes_close_to_edge: 'holesNearEdgeFeedback',
  part_sizes_too_big: 'partSizeTooBigFeedback',
  thin_walls: 'thinWallsFeedback',
  sharp_edges: 'sharpEdgesFeedback',
  small_holes: 'smallHolesFeedback',
};

export const DFM_AUTOMATION_EXTENSIONS = ['step', 'stp', 'stl'];

export const DFM_TYPES = {
  NO_DFM: 'no-dfm',
  HAS_PARTS_LIBRARY_DFM_ONLY: 'has-parts-library-dfm-only',
  HAS_PUE_MPR_DFM_ONLY: 'has-pue-mpr-dfm-only',
  HAS_DFM: 'has-dfm',
};

export const EXTRACT_DEFECT_DEFINITIONS_DISPLAY = {
  deep_holes:
    'Recommended hole depth less than 3 times the diameter (for diameters less than 2mm). Hole depth less than 5 times the diameter (for diameters greater than 2mm).',
  small_holes: 'Holes with a diameter less than 0.5mm may be too small.',
  thin_walls:
    'Walls with thickness of 0.4mm or less may be challenging to fabricate.',
  sharp_edges:
    'Internal sharp edges with a 90-degree angle may have fillet requirements',
  holes_close_to_edge:
    'Holes with a distance to the edge less than 0.4mm may be challenging to fabricate.',
  part_sizes_too_big:
    'Part sizes greater than 300mm may be challenging to fabricate.',
  overhangs:
    'Overhangs greater than 30° may lead to poor surface quality or require support structures during 3D printing.',
  fine_features: `Small or intricate details may be difficult to print accurately, depending on the resolution of the printer.`,
};
