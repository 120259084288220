import React from 'react';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Warning as WarningIcon } from '@material-ui/icons';
import pluralize from 'pluralize';
import { useQuery } from 'react-query';

import { getRepeatOrderDashboardStats } from '../../apis/dashboardApi';

import { RepeatOrderIcon } from '../../components/icons/ItemIcon';
import { FtrButton } from '@components/ftr-components';
import { FtrTypography } from '@components/ftr-components';

import { withAutomationFailuresHOC } from '../../hocs/withAutomationFailuresHOC';

import { AUTOMATION_FLOW } from '../../constants/automationConstants';

const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
}));

const RepeatOrderDashboard = (props) => {
  const classes = useStyles();
  const { updateAutomationFailuresPopupState } = props;

  const {
    status: loading,
    data,
    error,
  } = useQuery('getRepeatOrderDashboardStats', getRepeatOrderDashboardStats);

  if (error) {
    return (
      <FtrTypography type='heading'>
        Error loading dashboard stats
      </FtrTypography>
    );
  }

  const rfqFailures =
    data?.automationFailures?.filter(
      (failure) => failure.type === AUTOMATION_FLOW.RFQ
    ) || [];

  const supplierTrackerFailures =
    data?.automationFailures?.filter(
      (failure) => failure.type === AUTOMATION_FLOW.SUPPLIER_TRACKER
    ) || [];

  const quoteVerifierFailures =
    data?.automationFailures?.filter(
      (failure) => failure.type === AUTOMATION_FLOW.QUOTE_VERIFIER
    ) || [];

  const poAcknowledgementFailures =
    data?.automationFailures?.filter(
      (failure) => failure.type === AUTOMATION_FLOW.PO_ACKNOWLEDGEMENT
    ) || [];

  return (
    <div className={classes.body}>
      {loading !== 'success' ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <h2 style={{ marginTop: 0 }}>
              Live Orders: {data?.dashboardItems?.allLiveOrders}
            </h2>
            <h2>
              <RepeatOrderIcon /> Repeat Live Orders:{' '}
              {data?.dashboardItems?.allRepeatLiveOrders}
            </h2>
          </Grid>
          <Grid item xs={8}>
            <div style={{ height: '50vh' }}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell align='right'># of Repeated Orders</TableCell>
                      <TableCell align='right'>Issues</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Verifying</TableCell>
                      <TableCell align='right'>
                        {data?.dashboardItems?.verifyingItems}
                      </TableCell>
                      <TableCell align='right'></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Quoted</TableCell>
                      <TableCell align='right'>
                        {data?.dashboardItems?.quotedItems}
                      </TableCell>
                      <TableCell align='right'></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Processed: Pending Verification</TableCell>
                      <TableCell align='right'>
                        {
                          data?.dashboardItems
                            ?.processedPendingVerificationItems
                        }
                      </TableCell>
                      <TableCell align='right'></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Processed: Pending RFQ</TableCell>
                      <TableCell align='right'>
                        {data?.dashboardItems?.processedPendingRFQItems}
                      </TableCell>
                      <TableCell align='right'>
                        {rfqFailures.length ? (
                          <FtrButton
                            color='red'
                            variant='contained'
                            onClick={() => {
                              updateAutomationFailuresPopupState({
                                open: true,
                                failures: rfqFailures,
                                type: AUTOMATION_FLOW.RFQ,
                              });
                            }}
                            endIcon={<WarningIcon />}
                          >
                            View {pluralize('issue', rfqFailures.length, true)}
                          </FtrButton>
                        ) : (
                          <FtrButton
                            color='blue'
                            variant='contained'
                            disabled={true}
                          >
                            No issues
                          </FtrButton>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Processed: Pending Supplier</TableCell>
                      <TableCell align='right'>
                        {
                          data?.dashboardItems
                            ?.processingPendingSupplierAcceptanceItems
                        }
                      </TableCell>
                      <TableCell align='right'>
                        {supplierTrackerFailures.length ? (
                          <FtrButton
                            color='red'
                            variant='contained'
                            onClick={() => {
                              updateAutomationFailuresPopupState({
                                open: true,
                                failures: supplierTrackerFailures,
                                type: AUTOMATION_FLOW.SUPPLIER_TRACKER,
                              });
                            }}
                            endIcon={<WarningIcon />}
                          >
                            View{' '}
                            {pluralize(
                              'issue',
                              supplierTrackerFailures.length,
                              true
                            )}
                          </FtrButton>
                        ) : (
                          <FtrButton
                            color='blue'
                            variant='contained'
                            disabled={true}
                          >
                            No issues
                          </FtrButton>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Processed: Pending Quote Verification
                      </TableCell>
                      <TableCell align='right'>
                        {
                          data?.dashboardItems
                            ?.processingPendingQuoteVerificationItems
                        }
                      </TableCell>
                      <TableCell align='right'>
                        {quoteVerifierFailures?.length ? (
                          <FtrButton
                            color='red'
                            variant='contained'
                            onClick={() => {
                              updateAutomationFailuresPopupState({
                                open: true,
                                failures: quoteVerifierFailures,
                                type: AUTOMATION_FLOW.QUOTE_VERIFIER,
                              });
                            }}
                            endIcon={<WarningIcon />}
                          >
                            View{' '}
                            {pluralize(
                              'issue',
                              quoteVerifierFailures?.length,
                              true
                            )}
                          </FtrButton>
                        ) : (
                          <FtrButton
                            color='blue'
                            variant='contained'
                            disabled={true}
                          >
                            No issues
                          </FtrButton>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        Processed: Pending PO Acknowledgement
                      </TableCell>
                      <TableCell align='right'>
                        {
                          data?.dashboardItems
                            ?.processingPendingPOAcknowledgementItems
                        }
                      </TableCell>
                      <TableCell align='right'>
                        {poAcknowledgementFailures?.length ? (
                          <FtrButton
                            color='red'
                            variant='contained'
                            onClick={() => {
                              updateAutomationFailuresPopupState({
                                open: true,
                                failures: poAcknowledgementFailures,
                                type: AUTOMATION_FLOW.PO_ACKNOWLEDGEMENT,
                              });
                            }}
                            endIcon={<WarningIcon />}
                          >
                            View{' '}
                            {pluralize(
                              'issue',
                              poAcknowledgementFailures?.length,
                              true
                            )}
                          </FtrButton>
                        ) : (
                          <FtrButton
                            color='blue'
                            variant='contained'
                            disabled={true}
                          >
                            No issues
                          </FtrButton>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Order-in-progress</TableCell>
                      <TableCell align='right'>
                        {data?.dashboardItems?.orderInProgressItems}
                      </TableCell>
                      <TableCell align='right'></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Order ready</TableCell>
                      <TableCell align='right'>
                        {data?.dashboardItems?.orderReadyItems}
                      </TableCell>
                      <TableCell align='right'></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Order delivered</TableCell>
                      <TableCell align='right'>
                        {data?.dashboardItems?.orderDeliveredItems}
                      </TableCell>
                      <TableCell align='right'></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default withAutomationFailuresHOC(RepeatOrderDashboard);
