import React, { useEffect, useMemo } from 'react';

import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { SEVERITY } from '../../constants/errorMessageConstants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

function FtrSnackbar(props) {
  const {
    open,
    autoHideDuration = 3000,
    message = 'This is a success message!',
    severity = SEVERITY,
    handleClose = () => {},
  } = props;

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        handleClose();
      }, autoHideDuration);
    }
  }, [open]);

  const backgroundColor = useMemo(() => {
    switch (severity) {
      case SEVERITY.ERROR:
        return colors.red010;
      case SEVERITY.WARNING:
        return colors.warningBackground;
      case SEVERITY.INFO:
        return colors.blue020;
      case SEVERITY.SUCCESS:
        return colors.green010;
      default:
        return colors.blue020;
    }
  }, [severity]);

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} {...props}>
      <Alert
        onClose={handleClose}
        severity={severity}
        style={{
          borderRadius: 10,
          backgroundColor,
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default FtrSnackbar;
