import React from 'react';

import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { sendErrorToDiscord } from '../utils/errorReportingUtils';

// -------------------------------------------------------------------------------------------------

const toastIDMap = {};

export const notifyError = (
  message,
  { sendToDiscord = true, additional = {} } = {}
) => {
  toast.error(message, { position: toast.POSITION.TOP_RIGHT });
  if (sendToDiscord) {
    const err = new Error(message);
    sendErrorToDiscord({
      message,
      additional: { err, errorTitle: `Something wrong on FE!`, ...additional },
    });
  }
};

export const notifyInfo = (message, extraOption = {}) => {
  const toastID = toast.info(message, {
    position: toast.POSITION.TOP_RIGHT,
    onClose: () => delete toastIDMap[toastID], // Clear state when dismissed
    ...extraOption,
  });

  toastIDMap[toastID] = true;

  return toastID;
};

export const notifyWarning = (message) =>
  toast.warning(message, { position: toast.POSITION.TOP_RIGHT });

export const notifySuccess = (message) =>
  toast.success(message, { position: toast.POSITION.TOP_RIGHT });

export const notifyOngoing = (message) =>
  notifyInfo(message, { type: toast.TYPE.INFO, autoClose: false });

export const updateNotification = (toastID, message, type = 'success') => {
  const toastTypeMap = {
    success: toast.TYPE.SUCCESS,
    info: toast.TYPE.INFO,
    warning: toast.TYPE.WARNING,
    error: toast.TYPE.ERROR,
    default: toast.TYPE.DEFAULT,
  };

  if (toastIDMap[toastID]) {
    const toastType = toastTypeMap[type] || toastTypeMap.default;

    toast.update(toastID, {
      render: message,
      type: toastType,
      autoClose: 3000,
    });

    return;
  }

  switch (type) {
    case toast.TYPE.SUCCESS:
      notifySuccess(message);
      break;
    case toast.TYPE.ERROR:
      notifyError(message);
      break;
    case toast.TYPE.INFO:
      notifyInfo(message);
      break;
    case toast.TYPE.WARNING:
      notifyWarning(message);
      break;
    default:
  }
};

export const dismissNotification = (toastID) => {
  toast.dismiss(toastID);
};

// Create a custom toast component with title and content
const CustomToast = ({
  title,
  content,
  cancellable = false,
  onCancel = () => {},
  cancelBtnText = 'Cancel',
}) => (
  <div>
    <strong>{title}</strong>
    <div style={{ fontSize: 12 }}>{content}</div>
    {cancellable && (
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <button onClick={onCancel}>{cancelBtnText}</button>
      </div>
    )}
  </div>
);

export const notifyErrorContent = (title = '', content = '') => {
  notifyError(<CustomToast title={title} content={content} />);
};

export const notifyInfoContent = (title = '', content = '') => {
  return toast.info(<CustomToast title={title} content={content} />, {
    position: toast.POSITION.TOP_RIGHT, // Set the position to top right
  });
};

export const notifyCancellableInfoContent = ({
  title = '',
  content = '',
  cancelBtnText = 'Cancel',
  onCancel = () => {},
}) => {
  const toastID = toast.info('', {
    position: toast.POSITION.TOP_RIGHT, // Set the position to top right
    autoClose: false,
  });

  const comp = (
    <CustomToast
      title={title}
      content={content}
      cancellable
      cancelBtnText={cancelBtnText}
      onCancel={() => {
        onCancel();
        toast.dismiss(toastID);
      }}
    />
  );

  toast.update(toastID, {
    render: comp,
  });

  return toastID;
};

export const notifySuccessContent = (title = '', content = '') => {
  toast.success(<CustomToast title={title} content={content} />, {
    position: toast.POSITION.TOP_RIGHT, // Set the position to top right
  });
};
