import React, { useEffect, useState } from 'react';

import { TextField, makeStyles } from '@material-ui/core';

import CountryDropDown from '../forms/CountryDropDown';

import { getCountryNameByPhoneCode } from '../../utils/contactUtils';

import { COUNTRY_LIST } from '../../constants/countryConstants';

import { colors } from '../../palette';

// -----------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  contactField: {
    display: 'flex',
    alignItems: 'top',
    width: '100%',
    '& .MuiAutocomplete-hasPopupIcon': {
      minWidth: '85px',
    },
    '& .MuiFormControl-fullWidth': {
      marginBottom: 0,
    },
    '& .MuiIconButton-label': {
      display: 'none',
    },
  },
  inputField: {
    width: '100%',
    background: colors.fontWhite,
    '& .MuiOutlinedInput-root': {
      color: colors.neutral070,
      borderRadius: 10,
    },
    '& .MuiOutlinedInput-root .Mui-disabled': {
      color: colors.neutral050,
      borderRadius: 10,
    },
  },
}));

/**
 *
 * @param {object} props
 * @param {({ contact, country, code, fullContact, isContactValidated }) => void} props.onChange
 * @param {boolean} props.required
 */
function ContactNumberField(props) {
  const classes = useStyles();

  const {
    initialValue = '(+65) ',
    required = false,
    error,
    onChange = () => {},
  } = props;

  const [country, setCountry] = useState('Singapore');
  const [contact, setContact] = useState('');

  useEffect(() => {
    if (initialValue) {
      const [phoneCode, contactNumber] = initialValue?.split(' ') || [];
      setContact(contactNumber || '');
      if (phoneCode && typeof phoneCode === 'string') {
        const countryName = getCountryNameByPhoneCode(phoneCode);
        if (countryName) {
          setCountry(countryName);
        }
      }
    }
  }, []);

  const handleUpdate = () => {
    const formattedContact = contact?.replace(/\s/g, ''); // remove empty space
    const fullContact = `(${COUNTRY_LIST[country].phone}) ${formattedContact}`;
    onChange({
      contact: formattedContact,
      country,
      code: COUNTRY_LIST[country].phone,
      fullContact,
    });
  };

  return (
    <div className={classes.contactField}>
      <CountryDropDown
        id='countryCode'
        isCountryCodeDropDown
        showDefaultFlag
        onSelect={(value) => setCountry(value)}
        valueSelected={country}
      />
      <TextField
        id='contact'
        name='contact'
        className={classes.inputField}
        value={contact}
        onChange={({ target }) => setContact(target.value)}
        error={!!error}
        helperText={error}
        variant='outlined'
        size='small'
        fullWidth
        required={required}
        onBlur={handleUpdate}
      />
    </div>
  );
}

export default ContactNumberField;
