import { useState } from 'react';
import { checkQCReport } from '../../apis/technicalDrawingExtractionApi';
import AutoAwesomeIcon from '../../assets/icons/auto_awesome.svg';
import { FtrIconButton, FtrTypography } from '../../components/ftr-components';
import CheckIcon from '../../components/icons/CheckIcon';
import FailedIcon from '../../components/icons/FailedIcon';
import FtrSvgImage from '../../components/images/FtrSvgImage';
import { FlexRow } from '../../components/layouts/FlexLayouts';
import { notifyError } from '../../services/notificationService';
import { isEmptyValue } from '../../utils/commonUtils';

const QCReportCheckButton = ({ file }) => {
  if (isEmptyValue(file?.fileURL) || isEmptyValue(file?.resourceID)) {
    return null;
  }

  const [loading, setLoading] = useState(false);
  const [isPass, setIsPass] = useState(file.qcCheck?.isPass);
  const [summary, setSummary] = useState(file.qcCheck?.summary);

  const handleCheckQCReport = async () => {
    setLoading(true);

    try {
      const response = await checkQCReport({
        fileUrl: file.fileURL,
        resourceID: file.resourceID,
      });
      if (response) {
        const {
          isPass: _isPass,
          failures: _failures,
          summary: _summary,
        } = response;
        setIsPass(_isPass);
        setSummary(_summary);
      }
    } catch (error) {
      notifyError('Error checking QC report: ' + error.message);
    }

    setLoading(false);
  };

  if (isEmptyValue(isPass)) {
    return (
      <FtrIconButton
        variant='outlined'
        onClick={handleCheckQCReport}
        loading={loading}
      >
        <FtrSvgImage src={AutoAwesomeIcon} />
        <FtrTypography style={{ marginLeft: '4px' }}>Check</FtrTypography>
      </FtrIconButton>
    );
  }

  return (
    <FlexRow>
      {isPass ? (
        <CheckIcon
          toolTipText={summary}
          style={{ whiteSpace: 'pre-wrap', fontSize: '16px' }}
        />
      ) : (
        <FailedIcon
          toolTipText={summary}
          style={{ whiteSpace: 'pre-wrap', fontSize: '16px' }}
        />
      )}
    </FlexRow>
  );
};

export default QCReportCheckButton;
