import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles/index';

import Title from '../Title';
import DfmAnalysis from '../DfmDefects/DfmAnalysis';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: '44rem',
    maxWidth: '70rem',
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      margin: '1rem',
      minWidth: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '5px',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  space: {
    width: theme.spacing(2),
  },
  dialogContent: {
    padding: '0 3rem',
    paddingBottom: '2rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2rem 2rem 2rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 1rem 1rem 1rem',
    },
  },
}));

function DfmAnalysisPopup(props) {
  const {
    onClose,
    open,
    dfmDefectOutput = {},
    twoDImageUrl,
    cadFile,
    isLoading,
    ...restProps
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      aria-labelledby='3d-cad-renderer-popup'
      classes={{ paper: classes.paper, root: classes.root }}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <DialogTitle id='3d-viewer-popup'>
        <Title contentTitle='DFM Analysis' size='small' />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DfmAnalysis
          dfmDefectOutput={dfmDefectOutput}
          twoDImageUrl={twoDImageUrl}
          cadFile={cadFile}
          isLoading={isLoading}
          {...restProps}
        />
      </DialogContent>
      <IconButton
        aria-label='close'
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}

export default DfmAnalysisPopup;
