import React, { useReducer } from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';

import YesNoPopup from '../components/popups/YesNoPopup';
import FinanceNoteInput from '../components/forms/FinanceNoteInput';

import {
  sendSupplierCreditNoteToHubdoc,
  updateSupplierCreditNoteInfo,
} from '../apis/supplierCreditNoteApi';

import {
  notifyOngoing,
  updateNotification,
} from '../services/notificationService';
import { isEmptyValue } from '../utils/commonUtils';
import FinanceCreditNoteEmailInfo from '../components/info/FinanceCreditNoteEmailInfo';

// ---------------------------------------------------------------------------------

function withSendSupplierCreditNoteToHubdocPopupHOC(WrappedComponent) {
  return function SendSupplierCreditNoteToHubdocPopupHOC(props) {
    const { refreshFunc } = props;

    const [localState, updateLocalState] = useReducer(
      (prev, next) => {
        return { ...prev, ...next };
      },
      {
        open: false,
        supplierCreditNoteInfoID: null,
        isCreditNoteReconciled: false,
        financeNote: null,
      }
    );

    const isFinanceNoteRequired = !localState.isCreditNoteReconciled;

    const CreditNoteReconciledSwitch = () => {
      return (
        <FormControlLabel
          control={
            <Switch
              color='primary'
              defaultChecked={localState.isCreditNoteReconciled}
              onChange={(e) => {
                updateLocalState({
                  isCreditNoteReconciled: e.target.checked,
                });
              }}
              name='setIsCreditNoteReconciled'
            />
          }
          label={
            <>
              The amount in this credit note{' '}
              <strong>
                {localState.isCreditNoteReconciled
                  ? 'matches'
                  : 'does not match'}
              </strong>
              .
            </>
          }
        />
      );
    };

    const closePopup = () => {
      refreshFunc();
      updateLocalState({ open: false });
    };

    return (
      <>
        <WrappedComponent
          updateSupplierCreditNoteInfoState={updateLocalState}
          {...props}
        />
        {localState.open && (
          <YesNoPopup
            title='Are you sure that you want to send this credit note to finance?'
            body={
              <>
                <FinanceNoteInput
                  financeNote={localState.financeNote}
                  setFinanceNote={(financeNote) =>
                    updateLocalState({ financeNote })
                  }
                  isRequired={isFinanceNoteRequired}
                />
                <div style={{ textAlign: 'left' }}>
                  <CreditNoteReconciledSwitch />
                </div>
                <FinanceCreditNoteEmailInfo isForSupplier={true} />
              </>
            }
            open={localState.open}
            handleNo={() => updateLocalState({ open: false })}
            handleYes={async () => {
              const toastID = notifyOngoing('Sending credit note to finance');
              try {
                await updateSupplierCreditNoteInfo(
                  localState.supplierCreditNoteInfoID,
                  {
                    isCreditNoteReconciled: localState.isCreditNoteReconciled,
                  }
                );
                await sendSupplierCreditNoteToHubdoc(
                  localState.supplierCreditNoteInfoID,
                  { financeCreditNote: localState.financeNote }
                );
                // a timeout is used here because the BE code emits an event to handle
                // sending an email and returns 200 immediately regardless of success or failure
                // to fix in the future by making this route return result of event emitter
                setTimeout(() => {
                  closePopup();
                }, 1000);
                updateNotification(
                  toastID,
                  'Sent the credit note to finance',
                  'success'
                );
              } catch (err) {
                updateNotification(
                  toastID,
                  'Could not send the credit note to finance',
                  'error'
                );
                closePopup();
              }
            }}
            isSubmitDisabled={
              isFinanceNoteRequired &&
              isEmptyValue(localState.financeNote?.trim())
            }
            showCloseButton={false}
          />
        )}
      </>
    );
  };
}

export default withSendSupplierCreditNoteToHubdocPopupHOC;
