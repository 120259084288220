import { ceil } from 'lodash';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles, Tooltip } from '@material-ui/core';

import {
  Edit as EditNeededIcon,
  SpeakerNotes as NotesGivenIcon,
} from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';

import FactCheckIcon from '../assets/icons/fact_check_blue.svg';

import { getAllSupplierLeadTimeFeedbacksWithParamsApi } from '../apis/supplierFeedbackApi';

import Thumb from '../components/fields/Thumb';
import HorizontalExpandSpace from '../components/ftr-components/HorizontalExpandSpace';
import SearchBar from '../components/grid-data/buttons/SearchBar';
import CustomToolbar, {
  DataGridToolbarLayout,
} from '../components/grid-data/CustomToolbar';
import GridDataPagination from '../components/grid-data/GridDataPagination';
import CheckIcon from '../components/icons/CheckIcon';
import { FlexRow } from '../components/layouts/FlexLayouts';
import DataGridWrapCell from '../components/tables/cells/DataGridWrapCell';
import DataGridWrapTextCell from '../components/tables/cells/DataGridWrapTextCell';

import { ROLE_TYPES } from '../constants';
import { FEEDBACK_STATUS } from '../constants/feedbackConstants';
import {
  LEAD_TIME_SUPPLIER_FEEDBACK_TOOLTIPS_FOR_ADMIN,
  SUPPLIER_FEEDBACK,
} from '../constants/ppeFeedbackConstants';

import useDataGridPaginationHook from '../hooks/useDataGridPaginationHook';

import { isEmptyValue } from '../utils/commonUtils';
import { formatDateWithTime, withDateValidation } from '../utils/dateTimeUtils';
import { openInNewTab } from '../utils/navigationUtils';
import { includesIgnoreCase } from '../utils/stringUtils';

const ALLOWED_ITEM_ORDER_STATUSES = [1, 3];
const ALLOWED_FEEDBACK_ROLES = [
  ROLE_TYPES.ADMIN,
  ROLE_TYPES.SUPER_ADMIN,
  ROLE_TYPES.SUPPLIER,
];

const useStyles = makeStyles(() => ({
  body: {
    ' & .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      margin: '0 auto',
      whiteSpace: 'normal',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnsContainer': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const ItemsSupplierLeadTimeFeedbackDataGrid = (props) => {
  const { preloadedSupplierFeedback = [], isEditQuotePopup = false } = props;

  const classes = useStyles();

  const [searchAutoFocus, setSearchAutoFocus] = useState(false);
  const [filteredPreloadedData, setFilteredPreloadedData] = useState(
    preloadedSupplierFeedback
  );

  const [
    { tableData, tableQueryParams, isFetching },
    { handleSearch, updateTableQueryParams },
  ] = useDataGridPaginationHook(getAllSupplierLeadTimeFeedbacksWithParamsApi, {
    initialQueryParams: {
      page: 0,
      pageSize: 10,
      search: '',
      totalCount: 0,
      statuses: ALLOWED_ITEM_ORDER_STATUSES,
      roles: ALLOWED_FEEDBACK_ROLES,
    },
    queryEnabled: !isEditQuotePopup && isEmptyValue(preloadedSupplierFeedback),
  });

  // This hook watches for changes to the preloaded data passed as prop arg from EditOrder page
  useEffect(() => {
    if (!isEmptyValue(preloadedSupplierFeedback)) {
      updateTableQueryParams({
        totalCount: filteredPreloadedData?.length,
      });
    }
  }, [filteredPreloadedData]);

  useEffect(() => {
    if (!isEmptyValue(preloadedSupplierFeedback)) {
      setFilteredPreloadedData(
        preloadedSupplierFeedback?.filter((row) => {
          return (
            includesIgnoreCase(
              '' + row?.userFeedbackEmail,
              tableQueryParams.search
            ) || includesIgnoreCase('' + row?.itemID, tableQueryParams.search)
          );
        })
      );
    }
  }, [tableQueryParams.search]);

  const columns = [
    {
      field: 'supplierFeedbackType',
      headerName: 'Type',
      width: 100,
      sortable: false,
      renderCell: ({ row }) => {
        return row?.feedback === FEEDBACK_STATUS.GOOD ? (
          <Thumb variant='up' isActive={true} tooltipText='Lead time is good' />
        ) : (
          <Thumb
            variant='down'
            isActive={true}
            tooltipText='Lead time is bad'
          />
        );
      },
      align: 'center',
    },
    {
      field: 'supplierFeedbackActionItem',
      headerName: 'Action Item',
      width: 100,
      sortable: false,
      renderCell: ({ row }) => {
        if (row?.feedback === 'revised') {
          return <CheckIcon toolTipText={'Price has been revised.'} />;
        }
        const feedbackDetails = row?.feedbackDetails || {};
        const feedbackType = Object.keys(feedbackDetails)?.filter(
          (key) => feedbackDetails[key]
        )[0];
        if (isEmptyValue(feedbackType)) {
          return '-';
        }
        return (
          <Tooltip
            title={
              LEAD_TIME_SUPPLIER_FEEDBACK_TOOLTIPS_FOR_ADMIN[feedbackType] || ''
            }
          >
            {SUPPLIER_FEEDBACK.NO_ACTIONS === feedbackType ? (
              <NotesGivenIcon color='primary' style={{ fill: 'green' }} />
            ) : SUPPLIER_FEEDBACK.TAKE_ORDER_WISH_PRICE_TO_REVISE ===
              feedbackType ? (
              <img src={FactCheckIcon} />
            ) : (
              <EditNeededIcon style={{ fill: 'orange' }} />
            )}
          </Tooltip>
        );
      },
      align: 'center',
    },
    {
      headerName: 'Item ID(s)',
      field: 'itemID',
      hide: isEditQuotePopup,
      renderCell: ({ row: rowData }) => {
        return (
          <DataGridWrapCell
            style={{
              lineHeight: 'normal',
            }}
          >
            {rowData.quotationInfo?.map((item) => {
              const itemURL = item?.quotationID
                ? `/order/edit/${item?.quotationID}`
                : `/verify-quotes`;
              return (
                <FlexRow key={item.itemID}>
                  <Tooltip title={'Go to order page for this item'}>
                    <Link
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        openInNewTab(itemURL);
                      }}
                      to={itemURL}
                    >
                      {item.itemID}
                    </Link>
                  </Tooltip>
                </FlexRow>
              );
            })}
          </DataGridWrapCell>
        );
      },
      width: 200,
      sortable: false,
      align: 'center',
    },
    {
      field: 'feedbackEmail',
      headerName: 'Feedback Email',
      width: 250,
      sortable: false,
      valueGetter: ({ row }) => row?.email || 'NIL',
    },
    {
      field: 'supplierRequestedDeliveryDate',
      headerName: 'Requested Delivery Date',
      width: 150,
      sortable: false,
      renderCell: ({ row }) => (
        <DataGridWrapCell>
          <div>
            {withDateValidation(formatDateWithTime)(row?.targetDeliveryDate) ||
              '-'}
          </div>
        </DataGridWrapCell>
      ),
      align: 'center',
    },
    {
      field: 'supplierOriginalDeliveryDate',
      headerName: 'Original Delivery Date',
      width: 150,
      sortable: false,
      renderCell: ({ row }) => (
        <DataGridWrapCell>
          <div>
            {withDateValidation(formatDateWithTime)(row?.deliveryDate) || '-'}
          </div>
        </DataGridWrapCell>
      ),
      align: 'center',
    },
    {
      field: 'feedbackRemarks',
      headerName: 'Feedback Remarks',
      width: 200,
      sortable: false,
      renderCell: ({ row }) => (
        <DataGridWrapTextCell text={row?.additionalRemarks || '-'} />
      ),
      align: 'center',
    },
    {
      field: 'feedbackCreatedAt',
      headerName: 'Created At',
      width: 150,
      hide: isEditQuotePopup,
      sortable: false,
      valueGetter: ({ row }) =>
        withDateValidation(formatDateWithTime)(row?.deliveryDate) || '-',
      align: 'center',
    },
  ];

  const getCustomToolbar = () => {
    return (
      <DataGridToolbarLayout>
        <HorizontalExpandSpace />
        <CustomToolbar
          buttons={[
            <SearchBar
              key='search'
              onSearch={handleSearch}
              searchTerm={tableQueryParams.search}
              autoFocus={searchAutoFocus}
              onFocus={() => {
                setSearchAutoFocus(true);
              }}
              onBlur={() => {
                setSearchAutoFocus(false);
              }}
            />,
          ]}
        />
      </DataGridToolbarLayout>
    );
  };

  return (
    <div className={classes?.body}>
      <DataGrid
        autoHeight
        columns={columns}
        components={{
          Toolbar: getCustomToolbar,
          Pagination: () => (
            <GridDataPagination
              pageCount={ceil(
                tableQueryParams.totalCount / tableQueryParams.pageSize
              )}
            />
          ),
        }}
        disableColumnMenu
        disableSelectionOnClick
        getRowId={(row) => row.createdAt}
        loading={isFetching}
        page={tableQueryParams.page}
        pageSize={tableQueryParams.pageSize}
        paginationMode='server'
        onPageSizeChange={(newPageSize) =>
          updateTableQueryParams({ pageSize: newPageSize })
        }
        onPageChange={(newPage) => updateTableQueryParams({ page: newPage })}
        rowCount={tableQueryParams.totalCount}
        rows={
          !isEmptyValue(filteredPreloadedData)
            ? filteredPreloadedData
            : tableData || []
        }
        rowsPerPageOptions={[5, 10, 20, 50]}
      />
    </div>
  );
};

export default ItemsSupplierLeadTimeFeedbackDataGrid;
