const queryString = require('query-string');

import { fetchWithErrorHandling, getAuthorizedHeader } from '../utils/apiUtils';

import { BACKEND_SERVICE_URL } from '../constants';

// -------------------------------------------------------------------------------------------------

export const getAutoBalloonItems = async (params) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/auto-balloon?${queryString.stringify(
    params
  )}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'GET',
    headers: getAuthorizedHeader(),
  });
};

export const updateStatusForAutoBalloonItem = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/auto-balloon/update-status`;
  return fetchWithErrorHandling(requestUrl, {
    method: `POST`,
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const verifyAutoBalloonItem = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/auto-balloon/verify`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const saveAutoBalloonItem = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/auto-balloon/save`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  });
};

export const generateAutoBalloonForItem = async ({ itemID, pdfUrl }) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/auto-balloon/items/${itemID}`;
  return fetchWithErrorHandling(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify({ pdfUrl }),
  });
};
