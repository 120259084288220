import React from 'react';

import FtrTypography, {
  FtrBoldText,
  FtrLightText,
} from '../../ftr-components/FtrTypography';
import { FlexRow, FlexRowSpaceBetween } from '../../layouts/FlexLayouts';
import { Skeleton } from '@material-ui/lab';
import FtrInfoIconTooltip from '../../ftr-components/FtrInfoIconTooltip';

import { isEmptyValue } from '../../../utils/commonUtils';
import {
  beautifyNumber,
  convertPriceToCurrency,
} from '../../../utils/currencyUtils';

import { colors } from '../../../palette';

import { CURRENCY_CODE } from '../../../constants/currencyConstants';

// --------------------------------------------------------------------------

function PricingSummaryDisplayPresentational() {
  return {};
}

function Container(props) {
  return <>{props.children}</>;
}

function PriceLabel(props) {
  const { title, tooltip = '' } = props;

  return (
    <FlexRow>
      <FtrLightText fontSize='12'>{title}</FtrLightText>
      {!isEmptyValue(tooltip) && <FtrInfoIconTooltip toolTipText={tooltip} />}
    </FlexRow>
  );
}

function PriceValue(props) {
  return (
    <FtrTypography
      type='subHeading'
      fontSize='14'
      style={{
        color: colors.neutral070,
        fontWeight: 400,
        textDecoration:
          props.style === 'strike-through' ? 'line-through' : 'none',
      }}
    >
      {props.children}
    </FtrTypography>
  );
}

function SubRow(props) {
  const { title, price, tooltip = '', loading = false, style } = props;

  return (
    <FlexRowSpaceBetween>
      <FlexRow>
        <PriceLabel title={title} tooltip={tooltip} />
      </FlexRow>
      {!loading && <PriceValue style={style}>{price}</PriceValue>}
    </FlexRowSpaceBetween>
  );
}

function TotalRow(props) {
  const { title = 'Estimated Total', price, loading = false } = props;

  return (
    <FlexRowSpaceBetween style={{ marginTop: '2rem' }}>
      <FtrBoldText>{title}</FtrBoldText>
      {!loading && (
        <FtrBoldText
          fontSize='18'
          style={{
            color: colors.neutral070,
          }}
        >
          {price}
        </FtrBoldText>
      )}
    </FlexRowSpaceBetween>
  );
}

function ConvertedPriceSubRow(props) {
  const {
    title,
    price = 0,
    tooltip = '',
    loading = false,
    currency = CURRENCY_CODE.SGD,
    exchangeRate = 1,
    style = '', // strike-through
  } = props;
  return (
    <PricingSummaryDisplayPresentational.SubRow
      title={title}
      price={convertPriceToCurrency({
        price,
        currency,
        exchangeRate: exchangeRate ?? 1,
      })}
      tooltip={tooltip}
      loading={loading}
      style={style}
    />
  );
}

function ConvertedPriceTotalRow(props) {
  const {
    title = 'Estimated Total',
    price = 0,
    loading = false,
    currency = CURRENCY_CODE.SGD,
    exchangeRate = 1,
  } = props;

  const convertedPrice = convertPriceToCurrency({
    price,
    currency,
    exchangeRate: exchangeRate ?? 1,
  });

  return (
    <PricingSummaryDisplayPresentational.TotalRow
      title={title}
      price={beautifyNumber(convertedPrice)}
      loading={loading}
    />
  );
}

function withLoadingSkeletonHOC(WrappedComponent) {
  function WrappedComponentWithHOC(props) {
    const { loading } = props;

    if (loading) {
      return (
        <div
          style={{ width: '100%', marginTop: '0.5rem', marginBottom: '0.5rem' }}
        >
          <Skeleton variant='rect' height={20} />
        </div>
      );
    }

    return (
      <>
        <WrappedComponent {...props} />
      </>
    );
  }

  return WrappedComponentWithHOC;
}

PricingSummaryDisplayPresentational.Container = Container;
PricingSummaryDisplayPresentational.SubRow = SubRow;
PricingSummaryDisplayPresentational.TotalRow = TotalRow;
PricingSummaryDisplayPresentational.ConvertedPriceSubRow =
  withLoadingSkeletonHOC(ConvertedPriceSubRow);
PricingSummaryDisplayPresentational.ConvertedPriceTotalRow =
  withLoadingSkeletonHOC(ConvertedPriceTotalRow);

export default PricingSummaryDisplayPresentational;
