import { SHIPPING_MODES } from './checkoutConstants';
import { capitalizeString } from '../utils/stringUtils';

export const ITEM_FILE_UPLOAD_TYPE = {
  CUSTOMER_PO: 'CUSTOMER_PO',
};

export const DELIVERY_OPTIONS_DISPLAY_MAPPING = {
  [SHIPPING_MODES.SAME_DATE]: 'Priority Shipping',
  [SHIPPING_MODES.NEXT_DATE]: 'Standard Shipping',
  [SHIPPING_MODES.ONE_THREE_DAYS]: 'Economy Shipping',
  [SHIPPING_MODES.DHL_PRIORITY]: 'Priority Shipping - DHL',
  [SHIPPING_MODES.FEDEX_PRIORITY]: 'Priority Shipping - FedEx',
  [SHIPPING_MODES.FEDEX_ECONOMY]: 'Economical Shipping - FedEx',
  [SHIPPING_MODES.UPS_PRIORITY]: 'Priority Shipping - UPS',
  [SHIPPING_MODES.UPS_ECONOMY]: 'Economical Shipping - UPS',
};

export const DEFAULT_SHIP_MODE_LIST = {
  [SHIPPING_MODES.SAME_DATE]: {
    label: DELIVERY_OPTIONS_DISPLAY_MAPPING[SHIPPING_MODES.SAME_DATE],
    value: SHIPPING_MODES.SAME_DATE,
    toolTipText: 'Delivery within the day of completion',
  },
  [SHIPPING_MODES.NEXT_DATE]: {
    label: DELIVERY_OPTIONS_DISPLAY_MAPPING[SHIPPING_MODES.NEXT_DATE],
    value: SHIPPING_MODES.NEXT_DATE,
    toolTipText: 'Delivery on day after completion',
  },
  [SHIPPING_MODES.ONE_THREE_DAYS]: {
    label: DELIVERY_OPTIONS_DISPLAY_MAPPING[SHIPPING_MODES.ONE_THREE_DAYS],
    value: SHIPPING_MODES.ONE_THREE_DAYS,
    toolTipText: 'Delivery within 3 days of completion',
  },
  [SHIPPING_MODES.DHL_PRIORITY]: {
    label: DELIVERY_OPTIONS_DISPLAY_MAPPING[SHIPPING_MODES.DHL_PRIORITY],
    value: SHIPPING_MODES.DHL_PRIORITY,
    toolTipText: 'Delivery with DHL on day after completion',
  },
  [SHIPPING_MODES.FEDEX_PRIORITY]: {
    label: DELIVERY_OPTIONS_DISPLAY_MAPPING[SHIPPING_MODES.FEDEX_PRIORITY],
    value: SHIPPING_MODES.FEDEX_PRIORITY,
    toolTipText: 'Delivery with FedEx on day after completion',
  },
  [SHIPPING_MODES.FEDEX_ECONOMY]: {
    label: DELIVERY_OPTIONS_DISPLAY_MAPPING[SHIPPING_MODES.FEDEX_ECONOMY],
    value: SHIPPING_MODES.FEDEX_ECONOMY,
    toolTipText: 'Delivery with FedEx within 1 week of completion',
  },
  [SHIPPING_MODES.UPS_PRIORITY]: {
    label: DELIVERY_OPTIONS_DISPLAY_MAPPING[SHIPPING_MODES.UPS_PRIORITY],
    value: SHIPPING_MODES.UPS_PRIORITY,
    toolTipText: 'Delivery with UPS on day after completion',
  },
  [SHIPPING_MODES.UPS_ECONOMY]: {
    label: DELIVERY_OPTIONS_DISPLAY_MAPPING[SHIPPING_MODES.UPS_ECONOMY],
    value: SHIPPING_MODES.UPS_ECONOMY,
    toolTipText: 'Delivery with UPS within 1 week of completion',
  },
};

export const ITEM_CANCELLATION_REASON = {
  ALTERNATE_OPTION_CHOSEN: 'Alternate Option Chosen',
  CHOSE_EXISTING_SUPPLIER_INCONVENIENCE:
    'Chose Existing Supplier (inconvenience)',
  CHOSE_EXISTING_SUPPLIER_NO_REASON_GIVEN:
    'Chose Existing Supplier (no reason given)',
  CLIENT_DESIGN_CHANGE_ISSUE: 'Client Design Change/Issue',
  CLIENT_TESTING: 'Client Testing',
  ENQUIRY_MISFIT: 'Enquiry Misfit',
  EXPIRED: 'Expired',
  HIGH_PART_PRICE: 'High Part Price',
  HIGHT_SERVICE_FEE: 'High Service Fee',
  HIGH_DELIVERY_FEE: 'High Delivery Fee',
  LACK_OF_CAPABILITIES: 'Lack of Capabilities',
  LOST_PROJECT_FROM_CLIENT: 'Lost Project from Client',
  LATE_QUOTE: 'Late quote (>24h)',
  LONG_LEAD_TIME: 'Long Lead Time',
  NO_REASON: 'No Reason/Ghost',
  PHISHING_PRICE_COMPARISON: 'Phishing / Price comparison',
  TESTING_FACTOREM_INTERNAL: 'Testing (Factorem Internal)',
};

export const FACTOREM_SAMPLE_PART_NAME = 'Factorem_Sample_Part';

export const QUALITY_ITEM_LABELS = {
  partnerDimQuality: 'Partner Dimensional Quality',
  partnerSFQuality: 'Partner Surface Finish Quality',
  partnerVisualQuality: 'Partner Visual Quality',
  partnerPackagingQuality: 'Partner Packaging Quality',
  customerDimQuality: 'Customer Dimensional Quality',
  customerSFQuality: 'Customer Surface Finish Quality',
  customerVisualQuality: 'Customer Visual Quality',
  customerPackagingQuality: 'Customer Packaging Quality',
};

export const ADMIN_UPDATE_ACTION_TYPES = {
  CLARIFY: 'clarify',
  SUBMIT_FOR_RQ: 'submit_rq',
  DISABLE_PROJECT_100: 'disable_project_100',
};

export const ITEM_ACTION_TYPES = {
  CONVERT_PPE_TO_RFQ: 'convert-ppe-to-rfq',
  UPDATE_QUALITY_METRICS: 'update-quality-metrics',
  UPDATE_CLARIFYING_STATUS: 'update-clarifying-status',
  SUBMIT_FOR_ROCKET_QUOTE: 'submit-for-rocket-quote',
};

export const CUSTOMER_CANCEL_ORDER_REASON = {
  QUOTE_TOO_HIGH_MANUFACTURING: 'High quote price',
  QUOTE_TOO_HIGH_DELIVERY: 'High delivery fees',
  QUOTE_TOO_HIGH_SERVICE_FEE: 'High service fees',
  LEAD_TIME_TOO_LONG: 'Long lead time',
  QUOTE_TOO_SLOW: 'Slow quotation',
  HAVE_DESIGN_CHANGES_ISSUES: 'Have design changes/issues',
  CHOSE_DIFFERENT_MANUFACTURING_TECHNOLOGY:
    'Chose different manufacturing technology',
  LOST_PROJECT: 'Lost client project',
  JUST_TRYING_OUT_THE_PLATFORM: 'Just trying out the platform',
  OTHERS: 'Others',
};

export const CUSTOMER_CANCEL_ORDER_REASON_MAPPING = {
  [CUSTOMER_CANCEL_ORDER_REASON.CHOSE_DIFFERENT_MANUFACTURING_TECHNOLOGY]:
    ITEM_CANCELLATION_REASON.ALTERNATE_OPTION_CHOSEN,
  [CUSTOMER_CANCEL_ORDER_REASON.HAVE_DESIGN_CHANGES_ISSUES]:
    ITEM_CANCELLATION_REASON.CLIENT_DESIGN_CHANGE_ISSUE,
  [CUSTOMER_CANCEL_ORDER_REASON.JUST_TRYING_OUT_THE_PLATFORM]:
    ITEM_CANCELLATION_REASON.CLIENT_TESTING,
  [CUSTOMER_CANCEL_ORDER_REASON.QUOTE_TOO_HIGH_DELIVERY]:
    ITEM_CANCELLATION_REASON.HIGH_DELIVERY_FEE,
  [CUSTOMER_CANCEL_ORDER_REASON.QUOTE_TOO_HIGH_MANUFACTURING]:
    ITEM_CANCELLATION_REASON.HIGH_PART_PRICE,
  [CUSTOMER_CANCEL_ORDER_REASON.QUOTE_TOO_HIGH_SERVICE_FEE]:
    ITEM_CANCELLATION_REASON.HIGHT_SERVICE_FEE,
  [CUSTOMER_CANCEL_ORDER_REASON.QUOTE_TOO_SLOW]:
    ITEM_CANCELLATION_REASON.LATE_QUOTE,
  [CUSTOMER_CANCEL_ORDER_REASON.LEAD_TIME_TOO_LONG]:
    ITEM_CANCELLATION_REASON.LONG_LEAD_TIME,
  [CUSTOMER_CANCEL_ORDER_REASON.LOST_PROJECT]:
    ITEM_CANCELLATION_REASON.LOST_PROJECT_FROM_CLIENT,
  [CUSTOMER_CANCEL_ORDER_REASON.OTHERS]: ITEM_CANCELLATION_REASON.NO_REASON,
};

export const DB_TECHNOLOGY_COLUMN = 'technology';
export const DB_SURFACE_FINISH_COLUMN = 'surfaceFinish';
export const DB_CUSTOM_SURFACE_FINISH_COLUMN = 'customSurfaceFinish';
export const DB_MATERIAL_COLUMN = 'material';
export const DB_CUSTOM_MATERIAL_COLUMN = 'customMaterial';
export const DB_MATERIALCOLOR_COLUMN = 'materialColor';
export const DB_COLOR_COLUMN = 'color';
export const DB_TOLERANCE_COLUMN = 'tolerance';

export const DB_3DP_INFILL_COLUMN = 'threeDInfill';
export const DB_3DP_TECHNOLOGY_COLUMN = 'threeDTechnology';
export const DB_3DP_THICKNESS_COLUMN = 'threeDLayerThickness';
export const ANODIZING_TYPE = {
  GLOSSY: 'glossy',
  MATTE: 'matte',
};
export const DB_METADATA_COLUMN = 'metadata';

export const ANODIZING_TYPE_OPTIONS = Object.values(ANODIZING_TYPE)?.map(
  (type) => ({
    key: type,
    label: capitalizeString(type),
  })
);

export const LATE_REASONS = {
  SG_OFF_HOURS: 'Out of SG office hours',
  SURGE: 'Orders surge',
  COMPLEX: 'Complex drawing',
  LONG_STANDUP: 'Standup delay',
  OTHERS: 'Others',
};

export const LATE_REASONS_OPTIONS = Object.values(LATE_REASONS)?.map(
  (reason) => ({
    key: reason,
    label: LATE_REASONS[reason],
  })
);

export const DEFAULT_TOLERANCE_STANDARD =
  '3.2 ISO 2768/1 TOLERANCE CLASS "MEDIUM"';

export const TOOLTIP_MESSAGE_TOLERANCE_STANDARD =
  'Factorem defaults to ISO 2768/1 Tolerance Class ‘Medium’, unless otherwise specified in your technical drawing.';

export const TOLERANCE_STANDARD_MAPPING = {
  [DEFAULT_TOLERANCE_STANDARD]: '3.2 ISO 2768/1 Tolerance Class "Medium"',
};

export const CONVERT_PPE_TO_RFQ_REASONS = {
  PPE_QUOTE_TO_HIGH: 'PPE quote too high',
  CLIENT_HAS_TARGET_LEAD_TIME: 'Client has target lead time',
  COMPLEXITY_ON_DRAWING: 'Complexity on drawing',
  OTHERS: 'Others',
};
export const CONVERT_PPE_TO_RFQ_REASONS_OPTIONS = Object.keys(
  CONVERT_PPE_TO_RFQ_REASONS
)?.map((reason) => ({
  key: reason,
  label: CONVERT_PPE_TO_RFQ_REASONS[reason],
}));

export const CONFIG_FIELDS_NAME_MAPPING = {
  TECHNOLOGY: 'technology',
  UNIT_TYPE: 'unit type',
  QUANTITY: 'quantity',
  MATERIAL: 'material',
  CUSTOM_MATERIAL: 'custom material',
  MATERIAL_COLOR: 'material color',
  CUSTOM_MATERIAL_COLOR: 'custom material material',
  SURFACE_FINISH: 'surface finish',
  CUSTOM_SURFACE_FINISH: 'custom surface finish',
  FINISH_COLOR: 'finish color',
  CUSTOM_FINISH_COLOR: 'custom finish color',
  ANODIZING_TYPE: 'anodizing type',
  TOLERANCE: 'tolerance',
  THREED_TECHNOLOGY: '3DP technology',
  THREED_MATERIAL: '3DP material',
  THREED_INFILL: '3DP infill',
  THREE_D_LAYER_THICKNESS: '3DP layer thickness',
};

export const CONFIG_FIELDS_ORIGIN = {
  TDE: 'TDE',
  PLATFORM: 'PLATFORM',
  ORDER_AGAIN: 'ORDER_AGAIN',
};
