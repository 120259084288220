import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { HighlightOff as HighlightOffIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import SupplierRfqChip from '../components/chips/SupplierRfqChip';
import MultiSelectChipDropdown from '../components/dropdowns/MultiSelectChipDropdown';
import MultiSelectChipDropdownWithCategoryFuzzySearch from '../components/dropdowns/MultiSelectChipDropdownWithCategoryFuzzySearch';

import { getAllSuppliersGroupByTech } from '../apis/userApi';
import { sendGenerateRfqEmails } from '../apis/emailApi';
import {
  getReadyForQuoteItems,
  getReadyForQuoteItemsGroupByProject,
} from '../apis/itemApi';

import { validateEmail } from '../utils/validators/emailValidator';

import { getSelectedSuppliersInfoFromGroupByTech } from '../utils/userUtils';
import { isEmptyValue } from '../utils/commonUtils';
import { compareStringsEqualsIgnoreCase } from '../utils/stringUtils';

import { notifyError, notifySuccess } from '../services/notificationService';

import { colors } from '../palette';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '1rem',
  },
  addOtherMailRow: {
    flexGrow: 1,
    alignItems: 'start',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    backgroundColor: colors.menuItemSelected,
    overflowX: 'auto',
  },
  chipDeleteIcon: {
    color: colors.blue060,
    '&:hover': {
      color: colors.blue060,
    },
  },
  chipDeletable: {
    '&:focus': {
      backgroundColor: colors.menuItemSelected,
    },
  },
}));

const DEFAULT_ATTACH_FILES_VALUE = false;
const SELECT_OPTIONS = {
  ITEMS: 'ITEMS',
  PROJECTS: 'PROJECTS',
};

function GenerateRFQEmailsTab() {
  const classes = useStyles();

  const { data: items } = useQuery('quoteReadyItems', getReadyForQuoteItems);
  const { data: projects } = useQuery(
    'quoteReadyProjects',
    getReadyForQuoteItemsGroupByProject
  );
  const { data: allSuppliers } = useQuery(
    'allSuppliers',
    getAllSuppliersGroupByTech
  );

  const [selectedOption, setSelectedOption] = useState(SELECT_OPTIONS.PROJECTS);
  const [itemList, setItemList] = useState([]);
  const [itemListError, setItemListError] = useState(null);
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [projectListError, setProjectListError] = useState(null);
  const [selectedProjectIds, setSelectedProjectIds] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [selectedSupplierIds, setSelectedSupplierIds] = useState([]);
  const [secondarySupplierList, setSecondarySupplierList] = useState([]);
  const [remarks, setRemarks] = useState('');
  const [attachFiles, setAttachFiles] = useState(DEFAULT_ATTACH_FILES_VALUE);
  const [enableSendButton, setEnableSendButton] = useState(true);
  const [otherMailName, setOtherMailName] = useState('');
  const [otherMailNameError, setOtherMailNameError] = useState(null);
  const [otherMailValue, setOtherMailValue] = useState('');
  const [otherMailValueError, setOtherMailValueError] = useState(null);
  const [otherMails, setOtherMails] = useState([]);
  const [receiverError, setReceiverError] = useState(null);
  const [selectedProjectID, setSelectedProjectID] = useState(null);

  useEffect(() => {
    if (!isEmptyValue(items)) {
      // filter itemList with selectedProjectID if selectedProjectID is exist
      const itemProps = (item) => {
        return {
          key: item.itemID,
          text: `${item.name} - Part ID: ${item.itemID} - Project ID: ${item.projectID}`,
        };
      };
      if (selectedProjectID) {
        setItemList(
          items
            ?.filter((item) => item.projectID === selectedProjectID)
            ?.map((item) => itemProps(item))
        );
      } else {
        setItemList(items?.map((item) => itemProps(item)));
      }
    }
  }, [items, selectedProjectID]);

  useEffect(() => {
    if (!isEmptyValue(projects)) {
      setProjectList(
        projects?.map((project) => {
          return {
            key: `${project.projectID} - ${project.projectTechnologies[0]}`,
            text: `Project ${project.projectID} (${project.projectTechnologies[0]})`,
          };
        })
      );
    }
  }, [projects]);

  useEffect(() => {
    if (!isEmptyValue(allSuppliers)) {
      setSupplierList(
        allSuppliers?.map((category) => {
          return {
            ...category,
            value: category.value?.map((supplier) => {
              const { companyName } = supplier;
              const text = companyName
                ? `${companyName} <${supplier.email}> (${supplier.name})`
                : `${supplier.name} <${supplier.email}>`;
              return {
                key: `${supplier.userID}`,
                text,
              };
            }),
          };
        })
      );
    }
  }, [allSuppliers]);

  useEffect(() => {
    // set selectedProjectID to null if selectedItemIds is empty
    if (isEmptyValue(selectedItemIds)) {
      setSelectedProjectID(null);
    }
  }, [selectedItemIds]);

  const onItemsSelectChange = (newValues) => {
    const newItemID = newValues.at(-1);
    // to remove item on selected items
    if (selectedItemIds?.length > newValues?.length) {
      setSelectedItemIds(newValues);
      setItemListError(null);
      return;
    }
    // if the new value is not valid
    if (!newItemID) {
      return;
    }
    const newItemInfo = items?.find((item) => item.itemID === newItemID);
    // check the new item project id is the same as selectedProjectID
    if (selectedProjectID && selectedProjectID !== newItemInfo?.projectID) {
      notifyError(
        `Multiple project selection is not allowed. Selected project ID ${selectedProjectID}`
      );
      return;
    }
    // set selected project id for first item
    if (newValues?.length === 1) {
      setSelectedProjectID(newItemInfo?.projectID);
    }
    // update the new item
    setSelectedItemIds(newValues);
    setItemListError(null);
  };

  const onProjectsSelectChange = (newValues) => {
    setSelectedProjectIds(newValues);
    setProjectListError(null);
  };

  const onSupplierSelectChange = (newValues) => {
    if (newValues.length < selectedSupplierIds.length) {
      // removing supplier
      const oldSupplierId = selectedSupplierIds?.filter(
        (id) => !newValues.includes(id)
      );
      setSelectedSupplierIds(newValues);
      const oldSecondarySupplier = getSelectedSuppliersInfoFromGroupByTech(
        allSuppliers,
        oldSupplierId
      );
      const isSecondarySupplierPresent = secondarySupplierList?.some(
        (ss) => ss?.secondaryEmail === oldSecondarySupplier[0]?.secondaryEmail
      );
      if (
        oldSecondarySupplier[0]?.secondaryEmail &&
        isSecondarySupplierPresent
      ) {
        const newSecondarySupplierList = secondarySupplierList?.filter(
          (ss) => ss?.secondaryEmail !== oldSecondarySupplier[0]?.secondaryEmail
        );
        setSecondarySupplierList(newSecondarySupplierList);
      }
    } else {
      // adding supplier
      const newSupplierId = newValues?.filter(
        (id) => !selectedSupplierIds.includes(id)
      );
      setSelectedSupplierIds(newValues);
      const newSecondarySupplier = getSelectedSuppliersInfoFromGroupByTech(
        allSuppliers,
        newSupplierId
      );
      const isSecondarySupplierPresent = secondarySupplierList?.some(
        (ss) => ss?.secondaryEmail === newSecondarySupplier[0]?.secondaryEmail
      );
      if (
        newSecondarySupplier[0]?.secondaryEmail &&
        !isSecondarySupplierPresent
      ) {
        setSecondarySupplierList((prev) => [...prev, newSecondarySupplier[0]]);
      }
    }
    setReceiverError(null);
  };

  const handleAddReceiver = () => {
    if (isEmptyValue(otherMailName)) {
      setOtherMailNameError('Name is required');
      return;
    }
    if (isEmptyValue(otherMailValue)) {
      setOtherMailValueError('Email is required');
      return;
    }
    if (!validateEmail(otherMailValue)) {
      setOtherMailValueError('Email is invalid');
      return;
    }
    setOtherMails([
      ...otherMails,
      { name: otherMailName, email: otherMailValue },
    ]);
    setOtherMailName('');
    setOtherMailValue('');
    setReceiverError(null);
  };

  const handleOtherEmailChipDelete = (email) => {
    setOtherMails(otherMails?.filter((otherMail) => otherMail.email !== email));
  };

  const handleSecondarySupplierChipDelete = (email) => {
    setSecondarySupplierList(
      secondarySupplierList?.filter(
        (secSupplier) => secSupplier?.secondaryEmail !== email
      )
    );
  };

  const resetForm = () => {
    setSelectedItemIds([]);
    setSelectedProjectIds([]);
    setSelectedSupplierIds([]);
    setSecondarySupplierList([]);
    setRemarks('');
    setAttachFiles(DEFAULT_ATTACH_FILES_VALUE);
    setOtherMails([]);
  };

  const handleSendRFQ = () => {
    if (
      selectedOption === SELECT_OPTIONS.ITEMS &&
      isEmptyValue(selectedItemIds)
    ) {
      setItemListError('Please select at least one item');
      return;
    }
    if (
      selectedOption === SELECT_OPTIONS.PROJECTS &&
      isEmptyValue(selectedProjectIds)
    ) {
      setProjectListError('Please select at least one project');
      return;
    }
    const _supplierList = getSelectedSuppliersInfoFromGroupByTech(
      allSuppliers,
      selectedSupplierIds
    );
    const secondaryEmailList = secondarySupplierList?.map(
      (ss) => ss?.secondaryEmail
    );
    const finalSupplierList = _supplierList?.map((supplierObj) => {
      if (secondaryEmailList.includes(supplierObj?.secondaryEmail)) {
        return {
          ...supplierObj,
          isSendSecondaryEmail: true,
        };
      } else {
        return {
          ...supplierObj,
          isSendSecondaryEmail: false,
        };
      }
    });
    if (isEmptyValue(finalSupplierList) && isEmptyValue(otherMails)) {
      setReceiverError('Either supplier or other receiver is required');
      return;
    }
    const body = {
      toEmailList: [...finalSupplierList, ...otherMails],
      remarks,
      attachFiles,
    };
    if (selectedOption === SELECT_OPTIONS.ITEMS) {
      body.partIdList = selectedItemIds;
    } else if (selectedOption === SELECT_OPTIONS.PROJECTS) {
      body.projectIdList = selectedProjectIds?.filter(
        (id) => !isEmptyValue(id)
      );
    }
    setEnableSendButton(false);
    sendGenerateRfqEmails(body)
      .then(() => {
        notifySuccess('RFQ Emails have been queued for sending successfully.');
        resetForm();
        setEnableSendButton(true);
      })
      .catch((err) => {
        notifyError(err.message || err);
        setEnableSendButton(true);
      });
  };

  const renderSelectOptions = () => {
    return (
      <FormControl component='fieldset'>
        <RadioGroup
          row
          value={selectedOption}
          onChange={(event) => setSelectedOption(event.target.value)}
        >
          <FormControlLabel
            value={SELECT_OPTIONS.ITEMS}
            control={<Radio color='primary' />}
            label='Items'
          />
          <FormControlLabel
            value={SELECT_OPTIONS.PROJECTS}
            control={<Radio color='primary' />}
            label='Projects'
          />
        </RadioGroup>
      </FormControl>
    );
  };

  const extractSelectedPartsForSupplierCapabilityCheck = useCallback(() => {
    if (isEmptyValue(selectedItemIds)) {
      return projects
        ?.filter((project) =>
          compareStringsEqualsIgnoreCase(
            `${project?.projectID.toString()} - ${project?.projectTechnologies}`,
            selectedProjectIds[0]
          )
        )
        ?.flatMap((project) => project?.items)
        ?.map((item) => item?.itemID);
    }
    return selectedItemIds;
  }, [selectedItemIds, selectedProjectIds]);

  const renderSupplierRfqChip = (name, email, onDelete) => {
    const supplierName = `${name} <${email}>`;
    return (
      <SupplierRfqChip
        classes={classes}
        key={email}
        onDelete={onDelete}
        selectedKeys={supplierList
          ?.flatMap((group) => group?.value)
          ?.filter((supplier) =>
            compareStringsEqualsIgnoreCase(supplier?.text, supplierName)
          )}
        selectedParts={extractSelectedPartsForSupplierCapabilityCheck()}
        selectedText={supplierName}
      />
    );
  };

  const renderOtherMailsList = () => {
    return (
      <div
        style={{
          padding: '8px',
        }}
      >
        {otherMails?.map(({ name, email }) =>
          renderSupplierRfqChip(name, email, () =>
            handleOtherEmailChipDelete(email)
          )
        )}
      </div>
    );
  };

  const renderAddOtherMailsArea = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '16px',
        }}
      >
        <Typography color={receiverError ? 'error' : 'inherit'}>
          Add other receivers
        </Typography>
        <div
          style={{
            padding: '8px',
          }}
        >
          <Grid container className={classes.addOtherMailRow} spacing={2}>
            <Grid item xs={3}>
              <TextField
                variant='outlined'
                margin='dense'
                id='otherMailName'
                label='Name'
                placeholder=''
                type='text'
                fullWidth
                error={!!otherMailNameError}
                helperText={otherMailNameError}
                onFocus={() => {
                  setOtherMailNameError(null);
                  setReceiverError(null);
                }}
                onChange={(evt) => setOtherMailName(evt.target.value)}
                value={otherMailName}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                variant='outlined'
                margin='dense'
                id='otherMailValue'
                label='Email'
                placeholder=''
                type='text'
                fullWidth
                error={!!otherMailValueError}
                helperText={otherMailValueError}
                onFocus={() => {
                  setOtherMailValueError(null);
                  setReceiverError(null);
                }}
                onChange={(evt) => setOtherMailValue(evt.target.value)}
                value={otherMailValue}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                style={{
                  marginTop: '9px',
                }}
                variant='contained'
                color='primary'
                fullWidth
                onClick={handleAddReceiver}
              >
                Add Receiver
              </Button>
            </Grid>
          </Grid>
          {!isEmptyValue(otherMails) && renderOtherMailsList()}
        </div>
      </div>
    );
  };

  const renderSecondarySupplierEmail = () => {
    return (
      <div
        style={{
          marginTop: '16px',
        }}
      >
        {secondarySupplierList.length !== 0 && (
          <span>
            <Typography>Secondary Supplier Emails:</Typography>
            {secondarySupplierList?.map((secSupplier) => (
              /* 
                Chip instead of SupplierRfqChip because no need to call OMS endpoint
                again for secondary emails (capability same as primary email)
              */
              <Chip
                style={{ marginTop: '3px' }}
                classes={{
                  deleteIcon: classes.chipDeleteIcon,
                  deletable: classes.chipDeletable,
                }}
                className={classes.chip}
                key={secSupplier?.secondaryEmail}
                label={
                  secSupplier?.secondaryName +
                  ' <' +
                  secSupplier?.secondaryEmail +
                  '>'
                }
                onMouseDown={(event) => event.stopPropagation()}
                onDelete={() =>
                  handleSecondarySupplierChipDelete(secSupplier?.secondaryEmail)
                }
                deleteIcon={<HighlightOffIcon />}
              />
            ))}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className={classes.container}>
      {renderSelectOptions()}
      {selectedOption === SELECT_OPTIONS.ITEMS && (
        <MultiSelectChipDropdown
          id='multi-select-items'
          label='Select Parts'
          itemList={itemList}
          selectedItems={selectedItemIds}
          onSelect={onItemsSelectChange}
          error={!!itemListError}
          errorMessage={itemListError}
          searchable
        />
      )}
      {selectedOption === SELECT_OPTIONS.PROJECTS && (
        <MultiSelectChipDropdown
          id='multi-select-projects'
          label='Select Projects'
          itemList={projectList}
          selectedItems={selectedProjectIds}
          onSelect={onProjectsSelectChange}
          error={!!projectListError}
          errorMessage={projectListError}
          searchable
        />
      )}
      <div
        style={{
          marginTop: '16px',
        }}
      >
        <MultiSelectChipDropdownWithCategoryFuzzySearch
          id='multi-select-suppliers'
          label='Select Partners'
          itemList={supplierList}
          selectedParts={extractSelectedPartsForSupplierCapabilityCheck()}
          value={selectedSupplierIds}
          onSelect={onSupplierSelectChange}
          error={!!receiverError}
          searchable
        />
      </div>
      {renderSecondarySupplierEmail()}
      {renderAddOtherMailsArea()}
      {receiverError && (
        <Typography variant='caption' color='error'>
          {receiverError}
        </Typography>
      )}
      <TextField
        style={{
          width: '100%',
          marginTop: '16px',
        }}
        variant='outlined'
        multiline
        rows={6}
        margin='dense'
        id='remarks'
        label='Additional Remarks'
        placeholder=''
        type='text'
        fullWidth
        onChange={(evt) => setRemarks(evt.target.value)}
        value={remarks}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={attachFiles}
            onChange={() => setAttachFiles(!attachFiles)}
            name='attachFiles'
          />
        }
        label='Attach Design Files'
      />
      <div style={{ marginTop: '20px' }}>
        <Button
          variant='contained'
          color='primary'
          fullWidth
          onClick={handleSendRFQ}
          disabled={!enableSendButton}
        >
          Send RFQ
        </Button>
      </div>
    </div>
  );
}

export default GenerateRFQEmailsTab;
