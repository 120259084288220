import { uniqWith } from 'lodash';
import UAParser from 'ua-parser-js';

import { getUserCountry } from '../apis/userApi';
import { createAlertNotification } from '../apis/alertApi';

import { validateContact } from './validators/contactValidator';

import * as toleranceUtils from './toleranceUtils';
import { isEmptyValue } from './commonUtils';
import { toFixed4Or5 } from './numberUtils';

import { COUNTRIES, COUNTRY_LIST } from '../constants/countryConstants';
import {
  CUSTOMER_STATUS_TYPES,
  CUSTOMER_TYPES,
  INDUSTRIES,
} from '../constants/customerConstants';
import { DISPLAY_UNIT_OPTIONS, UNIT_TYPES } from '../constants/unitConstants';
import { CURRENCY_CODE } from '../constants/currencyConstants';

// -------------------------------------------------------------------------------------------------

export const getSelectedSuppliersInfo = (allSuppliers, selectedSupplierIds) => {
  return allSuppliers
    ?.map((supplier) => {
      if (!selectedSupplierIds.includes(`${supplier.userID}`)) {
        return null;
      }
      // most of supplier names are in this format: <Supplier Name> , <Supplier Company Name>
      const supplierName = supplier.name
        ? supplier.name?.split(',')[0].trim()
        : undefined;
      return {
        name: supplierName,
        email: supplier.email,
      };
    })
    ?.filter((item) => !!item);
};

export const getSelectedSuppliersInfoFromGroupByTech = (
  allSuppliersGroupByTech,
  selectedSupplierIds
) => {
  const flatSuppliers = allSuppliersGroupByTech?.flatMap(
    (category) => category.value
  );
  const selectedSuppliers = flatSuppliers
    ?.map((supplier) => {
      if (!selectedSupplierIds.includes(`${supplier.userID}`)) {
        return null;
      }
      // most of supplier names are in this format: <Supplier Name> , <Supplier Company Name>
      const supplierName = supplier.name
        ? supplier.name?.split(',')[0].trim()
        : undefined;
      return {
        name: supplierName,
        email: supplier.email,
        secondaryName: supplier.secondaryName,
        secondaryEmail: supplier.secondaryEmail,
      };
    })
    ?.filter((item) => !!item);
  const uniqueSuppliers = uniqWith(
    selectedSuppliers,
    (supplierA, supplierB) => supplierA.email === supplierB.email
  );
  return uniqueSuppliers;
};

export const getUserName = (name = '') => {
  let nameStr = name?.split(',')[0];
  if (!nameStr) {
    return '';
  }
  return nameStr;
};

export const getCountryLookup = () => {
  return COUNTRIES?.reduce((acc, country) => {
    acc[country] = country;
    return acc;
  }, {});
};

export const getCountryLookupDataGrid = () => {
  return COUNTRIES?.map((country) => {
    return {
      value: country,
      label: country,
    };
  });
};

export const getCurrencyLookupDataGrid = () => {
  return Object.values(CURRENCY_CODE)?.map((currency) => ({
    value: currency,
    label: currency,
  }));
};

export const getIndustryLookup = () => {
  return Object.values(INDUSTRIES)?.reduce((acc, industry) => {
    acc[industry] = industry;
    return acc;
  }, {});
};

export const getIndustryLookupDataGrid = () => {
  return Object.values(INDUSTRIES)?.map((industry) => {
    return {
      value: industry,
      label: industry,
    };
  });
};

export const getCustomerTypeLookup = () => {
  return Object.values(CUSTOMER_TYPES)?.reduce((acc, customerType) => {
    acc[customerType] = customerType;
    return acc;
  }, {});
};

export const getCustomerTypeLookupDataGrid = () => {
  return Object.values(CUSTOMER_TYPES)?.map((customerType) => {
    return {
      value: customerType,
      label: customerType,
    };
  });
};

export const getCustomerStatusLookup = () => {
  return Object.values(CUSTOMER_STATUS_TYPES)?.reduce((acc, customerStatus) => {
    acc[customerStatus] = customerStatus;
    return acc;
  }, {});
};

export const getCustomerStatusLookupDataGrid = () => {
  return Object.values(CUSTOMER_STATUS_TYPES)?.map((customerStatus) => {
    return {
      value: customerStatus,
      label: customerStatus,
    };
  });
};

/**
 *
 * @param {string} contact
 * @param {string} countryCode
 * @returns `(<countryPhoneCode>) <contact>`
 * @example (+65) 87654321
 */
export const getContactWithCountryCode = (contact, countryPhoneCode) => {
  return `(${countryPhoneCode}) ${contact}`;
};

export const getDefaultProfilePic = (name) => {
  const formattedName = name?.replace(/ /g, '+');
  return `https://ui-avatars.com/api/?name=${formattedName}&background=1272E3&color=fff&size=128`;
};

export const convertMetricToImperial = (value) => {
  return toleranceUtils.convertMetricToImperial(value);
};

export const convertImperialToMetric = (value) => {
  return toleranceUtils.convertImperialToMetric(value);
};

/**
 *
 * @param {string|number} value
 * @param {'metric'|'imperial'} unitType
 * @returns the value with the unit
 */
export const showUnitValueFromMetric = (value, unitTypeParam) => {
  if (isEmptyValue(value) || isEmptyValue(unitTypeParam)) {
    return '';
  }

  const unitType = unitTypeParam ?? UNIT_TYPES.METRIC;
  if (unitType === UNIT_TYPES.IMPERIAL) {
    // convert from TOLERANCE_METRIC_TO_IMPERIAL and match with the value
    value = +convertMetricToImperial(value);
    return `${value}${DISPLAY_UNIT_OPTIONS[unitType].LENGTH}`;
  }

  return `${+value}${DISPLAY_UNIT_OPTIONS[unitType].LENGTH}`;
};

/**
 *
 * @param {number} boundingBoxX value in mm
 * @param {number} boundingBoxY value in mm
 * @param {number} boundingBoxZ value in mm
 * @param {'metric'|'imperial'} desired unitType to display in
 * @returns the bounding box display string
 */
export const displayBoundingBoxValues = (
  boundingBoxX,
  boundingBoxY,
  boundingBoxZ,
  unitType
) => {
  if (
    isEmptyValue(boundingBoxX) ||
    isEmptyValue(boundingBoxY) ||
    isEmptyValue(boundingBoxZ)
  ) {
    return '';
  }
  if (unitType === UNIT_TYPES.IMPERIAL) {
    boundingBoxX = convertMetricToImperial(boundingBoxX);
    boundingBoxY = convertMetricToImperial(boundingBoxY);
    boundingBoxZ = convertMetricToImperial(boundingBoxZ);
  }
  const unit = DISPLAY_UNIT_OPTIONS[unitType]?.LENGTH;
  return `${boundingBoxX} x ${boundingBoxY} x ${boundingBoxZ}${unit}`;
};

/**
 *
 * @param {string|number} value
 * @param {'metric'|'imperial'} unitType
 * @returns the value of the unit
 */
export const convertFromMetric = (value, unitType = UNIT_TYPES.METRIC) => {
  if (unitType === UNIT_TYPES.IMPERIAL) {
    return convertMetricToImperial(value);
  }
  return toFixed4Or5(value);
};

/**
 *
 * @param {string|number} value
 * @param {'metric'|'imperial'} unitType
 * @returns the value of the unit
 */
export const convertToMetric = (value, unitType = UNIT_TYPES.METRIC) => {
  if (unitType === UNIT_TYPES.IMPERIAL) {
    return convertImperialToMetric(value);
  }
  return toFixed4Or5(value);
};

/**
 *
 * @param {object} props
 * @param {string} props.address
 * @param {string} props.unitNo
 * @param {string} props.postalCode
 * @example
 * #<unit-no>, <address>, <city>, <country> <postal-code>
 * #01-54, 81 Ayer Rajah Crescent, Singapore 139967
 */
export const formattedAddress = ({ address, unitNo, postalCode, country }) => {
  if (isEmptyValue(address)) {
    return '';
  }
  let formatted = address;
  let splitAddress = address?.split(', ');
  // check the format first
  if (splitAddress[0][0] === '#') {
    splitAddress.shift();
  }
  const [_, oldPostalCode] = splitAddress.at(-1)?.split(' ') || [];
  if (!isNaN(oldPostalCode)) {
    splitAddress.pop();
  }
  // add new properties
  if (unitNo) {
    // if unitNo exist, it's should be on the first address
    const clearUnit = unitNo[0] === '#' ? unitNo?.slice(1) : unitNo; // remove # if user input for the first char
    splitAddress[0] = `#${clearUnit}, ${splitAddress[0]}`;
  }
  formatted = splitAddress?.join(', '); // convert array to be string address
  if (country) {
    // if country exists, it's should be on the last part after comma
    formatted += `, ${country}`;
  }
  if (postalCode) {
    // if postalCode exists, it's should be on the very last part
    formatted += ` ${postalCode}`;
  }
  return formatted;
};

/**
 *
 * @param {string} formattedAddress #<unit-no>, <address>, <city>, <country> <postal-code>
 */
// eslint-disable-next-line no-shadow
export const convertFormattedAddress = (formattedAddress) => {
  let result = {
    unitNo: '',
    postalCode: '',
    address: '',
    country: '',
    city: '',
  };
  if (!formattedAddress || typeof formattedAddress !== 'string') {
    return result;
  }
  let splitAddress = formattedAddress?.split(', ');
  if (splitAddress[0][0] === '#') {
    result.unitNo = splitAddress[0]?.slice(1);
    splitAddress.shift();
  }
  const lastSentence = splitAddress.at(-1)?.split(' ');
  if (splitAddress.length > 1) {
    // if with address and country
    if (!isNaN(lastSentence.at(-1))) {
      // ex: United Kingdom 124567
      result.postalCode = lastSentence.at(-1);
      result.country = lastSentence?.slice(0, -1)?.join(' ');
      splitAddress.pop();
    } else {
      // ex: United Kingdom
      result.country = lastSentence?.join(' ');
      splitAddress.pop();
    }
  }
  if (splitAddress.length >= 2) {
    // for the address and city. for the country is already removed
    result.city = splitAddress.at(-1); // city on the last split
  }
  result.address = splitAddress?.join(', ');
  return result;
};

/**
 *
 * @param {string} contactNumber
 * @param {string} country
 * @returns if it's a old format then return (+CountryCode) PhoneNumber else contactNumber
 */
export const convertOldFormattedContact = (contactNumber, country) => {
  if (!contactNumber) {
    return null;
  }
  // Check if contactNumber matches the format "(+CountryCode) PhoneNumber"
  const countryCodeRegex = /^\(\+\d+\) \d+$/;
  if (contactNumber.match(countryCodeRegex)) {
    return contactNumber;
  }

  // Check if contactNumber matches the old format "+<CountryCode><PhoneNumber>"
  const oldCountryCodeRegex = /^\+\d+$/;
  if (contactNumber.match(oldCountryCodeRegex)) {
    const match = Object.entries(COUNTRY_LIST)?.find(([_, countryInfo]) =>
      contactNumber.startsWith(countryInfo.phone)
    );
    if (match) {
      const [countryName, { phone: phoneCode }] = match;
      const phoneNumber = contactNumber.substring(phoneCode.length);
      if (phoneCode && validateContact(phoneNumber, countryName)) {
        return getContactWithCountryCode(phoneNumber, phoneCode);
      }
    }
  }

  // Check if contact without format e.g 87654321
  if (validateContact(contactNumber, country)) {
    return getContactWithCountryCode(
      contactNumber,
      COUNTRY_LIST[country].phone
    );
  }

  return contactNumber;
};

export const getUserAgentInfo = async (email) => {
  const userCountry = await getUserCountry({ email }).catch((error) => {
    const body = {
      title: '[WARNING] [FE] Get User IP Address Info Failed!',
      message: error?.message,
      errorStack: error?.stack,
      additionalInfo: {
        email,
        statusCode: error?.statusCode,
      },
    };
    createAlertNotification(body);
  });
  const userAgentParser = new UAParser();
  const userAgent = userAgentParser.getResult();
  return {
    ipAddress: userCountry?.ip,
    region: userCountry?.region,
    country: userCountry?.country_name,
    userAgent,
  };
};

export const getUserFirstName = (userName) => {
  return userName?.split(' ')[0]?.replace(/[.,;!?]*$/, '');
};

//todo refactor useUserShippingAddress code to here
/**
 * This function attempts to find a default shipping DeliveryInfo object from a user's
 * array of delivery_info if it exists, else it uses the values for the user
 * from the users table.
 *
 * It returns an object of type DeliveryInfo with the
 * properties {address, contactName, contactNumber} or null
 * @param user
 * @param deliveryInfo
 * @returns {{address, contactName, contactNumber}|null}
 */
export const getDefaultShippingDeliveryInfo = (user, deliveryInfo) => {
  const shippingDeliveryInfo = deliveryInfo?.find(
    (address) => address.defaultShipping
  );

  if (shippingDeliveryInfo) {
    return shippingDeliveryInfo;
  }

  return user?.address
    ? {
        address: user.address,
        contactName: user.name,
        contactNumber: user.contact,
        country: user.country,
      }
    : null;
};

/**
 * This function builds attention details for a user based on their name and company name.
 *
 * It returns a string in one of these formats:
 *  - <Name> (if no company name)
 *  - <Company name> (if no name)
 *  - <Name>, <Company name> (if both exist)
 *  - "" (if neither name nor company name is provided)
 *
 * @param {string} name - The user's name.
 * @param {string} [companyName=null] - The user's company name (optional).
 * @returns {string} - Formatted attention details or an empty string if neither name nor companyName is provided.
 */
export const getUserAttnLabel = (name, companyName = null) => {
  if (isEmptyValue(name)) {
    return companyName || '';
  }

  return companyName ? `${name}, ${companyName}` : name;
};

/**
 * Checks if an email address has a valid domain.
 *
 * @param {string} email - The email address to check.
 * @returns {boolean} - True if the email address has a valid domain, false otherwise.
 * @example
 * isValidEmailDomain('test@factorem.co') // true
 * isValidEmailDomain('@factorem.co') // true
 * isValidEmailDomain('test@') // false
 * isValidEmailDomain('@test.com') // false
 * isValidEmailDomain('test@.com') // false
 */
export const isEmailDomainValid = (email) => {
  if (typeof email !== 'string') {
    return false;
  }
  const emailDomain = email?.split('@')[1];
  const domainRegex = /[^\s@]+\.[^\s@]+$/;
  return domainRegex.test(emailDomain);
};
