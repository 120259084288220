import React, { useEffect, useReducer, useState } from 'react';

import { useQuery } from 'react-query';

import { makeStyles } from '@material-ui/core/styles';

import {
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';

import CountryDropDown from '../../components/forms/CountryDropDown';
import {
  FtrBoldText,
  FtrButton,
  FtrTextArea,
  FtrTypography,
} from '../../components/ftr-components';
import FtrNumberField from '../../components/ftr-components/FtrNumberField';
import { FlexColumn, FlexRowEnd } from '../../components/layouts/FlexLayouts';

import { getRegionBasedMarkupsByParamsApi } from '../../apis/regionBasedMarkupApi';

import { isEmptyValue } from '../../utils/commonUtils';

import useDeviceCheck from '../../hooks/useDeviceCheck';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '30px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
    maxWidth: 800,
  },
  container: {
    width: '100% !important',
    margin: '0 !important',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {},
  },
  closeIcon: {
    position: 'absolute',
    right: '1.5rem',
    top: '1.5rem',
    color: colors.neutral060,
    width: '1.5rem',
    height: '1.5rem',
    cursor: 'pointer',
  },
}));

const AddEditRegionBasedMarkupPopup = ({
  id,
  onSave = () => {},
  handleClose = () => {},
}) => {
  const classes = useStyles();

  const [{ isMobile }] = useDeviceCheck();

  const defaultLocalState = {
    id: undefined,
    name: '',
    markup: 0,
    leadTimeMarkup: 2,
    remarks: '',
  };

  const { data: existingData } = useQuery(
    ['region-based-markup', id],
    () => getRegionBasedMarkupsByParamsApi({ id: id }),
    {
      enabled: !!id,
    }
  );

  const [localState, updateLocalState] = useReducer((prev, next) => {
    if (isEmptyValue(next)) {
      return defaultLocalState;
    }
    return { ...prev, ...next };
  }, defaultLocalState);

  const [formError, setFormError] = useState({});
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (isEmptyValue(existingData)) {
      updateLocalState(defaultLocalState);
    } else {
      updateLocalState({
        id: existingData.id,
        name: existingData.name,
        markup: existingData.markup,
        leadTimeMarkup: existingData.leadTimeMarkup,
        remarks: existingData.remarks,
      });
    }
  }, [existingData]);

  const validateForm = () => {
    const errors = {};
    if (isEmptyValue(localState.name)) {
      errors.name = 'Required field';
    }
    if (isEmptyValue(localState.markup)) {
      errors.markup = 'Required field';
    }
    setFormError(errors);
    return errors;
  };

  const handleSubmit = () => {
    const errors = validateForm();
    if (!isEmptyValue(errors)) {
      return;
    }

    // Submit logic
    setProcessing(true);
    onSave(localState)
      .then(() => {
        handleClose();
        setProcessing(false);
      })
      .catch(() => {
        setProcessing(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      scroll='paper'
      width='md'
      fullWidth
      fullScreen={isMobile}
    >
      <Close className={classes.closeIcon} onClick={handleClose} />
      <FtrTypography
        type='heading'
        fontSize='22'
        style={{ padding: '30px 0px 20px 40px' }}
      >
        {isEmptyValue(id) ? 'Add New' : 'Edit'} Region Based Markup
      </FtrTypography>
      <DialogContent>
        <FlexColumn>
          {!isEmptyValue(id) && (
            <FtrNumberField label='ID' value={localState.id} disabled />
          )}
          <FtrBoldText
            style={{
              color: colors.neutral070,
            }}
          >
            Country
          </FtrBoldText>
          <FlexColumn style={{ marginBottom: '1rem', gap: 0 }}>
            <CountryDropDown
              valueSelected={localState.name}
              onSelect={(value) => {
                setFormError({});
                updateLocalState({ name: value });
              }}
              showFlag={false}
            />
            {!isEmptyValue(formError.name) && (
              <FtrTypography
                type='caption'
                style={{ color: colors.red030, fontStyle: 'italic' }}
                fontSize='12'
              >
                {formError.name}
              </FtrTypography>
            )}
          </FlexColumn>
          <FtrNumberField
            label='Markup Percent'
            type='number'
            value={localState.markup}
            onChange={(e) => {
              setFormError({});
              updateLocalState({ markup: e.target.valueAsNumber });
            }}
            error={!!formError.markup}
            helperText={formError.markup}
            InputProps={{
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
            }}
          />
          <FtrNumberField
            label='Markup Lead Time'
            type='number'
            value={localState.leadTimeMarkup}
            onChange={(e) => {
              setFormError({});
              updateLocalState({ leadTimeMarkup: e.target.valueAsNumber });
            }}
            error={!!formError.leadTimeMarkup}
            helperText={formError.leadTimeMarkup}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>working days</InputAdornment>
              ),
            }}
          />
          <FtrTextArea
            label='Remarks'
            value={localState.remarks}
            onChange={(e) => {
              setFormError({});
              updateLocalState({ remarks: e.target.value });
            }}
            error={!!formError.remarks}
            helperText={formError.remarks}
          />
        </FlexColumn>
      </DialogContent>
      <DialogActions>
        <FlexRowEnd style={{ width: '100%', padding: '1rem' }}>
          <FtrButton variant='text' onClick={handleClose} disabled={processing}>
            Cancel
          </FtrButton>
          <FtrButton
            variant='contained'
            color='blue'
            onClick={handleSubmit}
            disabled={processing}
            loading={processing}
          >
            {isEmptyValue(id) ? 'Create' : 'Update'}
          </FtrButton>
        </FlexRowEnd>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditRegionBasedMarkupPopup;
