import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';

import { DatePicker } from '@material-ui/pickers';

import FtrTypography, { FtrH5 } from '../../ftr-components/FtrTypography';
import { FlexColumn, FlexRow } from '../../layouts/FlexLayouts';
import FtrFieldLabel from '../../ftr-components/FtrFieldLabel';
import { FtrButton, FtrDropdown, FtrDropdownV2 } from '../../ftr-components';
import ThreeDPrintingTechnologyInputField from '../../inputs/ThreeDPrintingTechnologyInputField';
import ThreeDPrintingMaterialField from '../../inputs/ThreeDPrintingMaterialField';
import MaterialCategoriesInputField from '../../inputs/MaterialCategoriesInputField';
import ColorInputField from '../../inputs/ColorInputField';
import ThreeDInfillSelectField from '../../inputs/ThreeDInfillSelectField';
import ThreeDLayerThicknessField from '../../inputs/ThreeDLayerThicknessField';
import { FtrSmallButton } from '../../ftr-components/FtrButton';

import { getPpePriceForCadPart, updateCadPartIfExists } from '../../../actions';

import { useItemInputConfig } from '../../../hooks/useItemInputConfig';

import { is3DPTechnology } from '../../../utils/itemUtils';
import { isEmptyValue } from '../../../utils/commonUtils';
import { isNumber } from '../../../utils/numberUtils';
import { getDefaultTolerance } from '../../../utils/toleranceUtils';
import {
  isAnodizingSurfaceFinish,
  isCustomMaterial,
  isCustomSurfaceFinish,
  isCustomTechnology,
} from '../../../utils/inputUtils';

import { ppe3dpInfillOptions } from '../../../constants/PPEConstants';
import {
  TECHNOLOGY_OPTION_TYPE,
  THREE_D_P_FDM_TECH,
  threeDPrintingInfillDefault,
  threeDPrintingLayerThicknessDefault,
} from '../../../constants/NewPartConstants';
import {
  ANODIZING_TYPE_OPTIONS,
  ANODIZING_TYPE,
} from '../../../constants/itemConstants';

import { colors } from '../../../palette';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: 'blur(3px)',
  },
  dialog: {
    padding: '1.5rem',
    borderRadius: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
      borderRadius: 0,
    },
  },
  title: {
    display: 'flex',
    fontSize: '22px',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const FieldLabel = withStyles({
  root: {
    padding: '0.3rem 0',
    color: colors.neutral070,
  },
})((props) => <FtrTypography type='heading' fontSize='16' {...props} />);

function BulkConfigurePartsPopupV2(props) {
  const { open, selectedItems = [], onClose = () => {}, unitType } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formDataAvailable = useSelector(
    (state) => state.item.formDataAvailable
  );
  const itemState = formDataAvailable;

  const item = selectedItems[0] ?? {};

  const [qty, setQty] = useState(item.qty || 1);
  const [technology, setTechnology] = useState(
    item?.technology || TECHNOLOGY_OPTION_TYPE.CNC_MACHINING
  );
  const [otherTechnology, setOtherTechnology] = useState(
    item?.otherTechnology || ''
  );
  const [material, setMaterial] = useState(item?.material || '');
  const [otherMaterial, setOtherMaterial] = useState(item?.otherMaterial || '');
  const [surfaceFinish, setSurfaceFinish] = useState(item?.surfaceFinish || '');
  const [otherSurfaceFinish, setOtherSurfaceFinish] = useState(
    item?.otherSurfaceFinish || ''
  );
  const [anodizingType, setAnodizingType] = useState(
    item?.anodizingType || ANODIZING_TYPE.GLOSSY
  );
  const [threeDTechnology, setThreeDTechnology] = useState(
    item?.threeDTechnology || ''
  );
  const [threeDInfill, setThreeDInfill] = useState(item?.threeDInfill || '');
  const [threeDLayerThickness, setThreeDLayerThickness] = useState(
    item?.threeDLayerThickness || ''
  );
  const [color, setColor] = useState(item?.color || '');
  const [materialColor, setMaterialColor] = useState(item?.materialColor || '');
  const [expectedLeadTime, setExpectedLeadTime] = useState(
    item?.expectedLeadTime
  );
  const [tolerance, setTolerance] = useState(item?.tolerance);

  const [
    {
      technologyOptions,
      materialCategoryOptions,
      threeDTechnologyOptions,
      threeDMaterialOptions,
      surfaceFinishOptions,
      materialColorOptions,
      surfaceFinishColorOptions,
      defaultThreeDMaterial,
    },
    {
      loadSelectColorSurfaces,
      loadTechnologyOptions,
      loadMaterialCategoryOptions,
      load3DTechnologyOptions,
      loadThreeDMaterialOptions,
      loadSurfaceFinishOptions,
      loadMaterialColorOptions,
      loadSurfaceFinishColorOptions,
      technologyHasChanged,
      materialHasChanged,
      threeDTechnologyHasChanged,
      surfaceFinishHasChanged,
      setThreeDMaterialOptions,
    },
  ] = useItemInputConfig({
    setTechnology,
    setMaterial,
    setThreeDTechnology,
    setSurfaceFinish,
    setMaterialColor,
    setColor,
  });

  const surfaceFinishIsPainting = surfaceFinish === 'Painting';
  const surfaceFinishIsCoating = surfaceFinish === 'Powder Coating';

  /**
   * If technology is 3D Printing and surface finish is painting
   * or powder coating, color input label will take on the label
   * of the surface finish
   */
  let colorInputLabel = surfaceFinishIsPainting
    ? 'Paint Color'
    : surfaceFinishIsCoating
      ? 'Powder Coat Color'
      : 'Color';

  useEffect(() => {
    loadTechnologyOptions(false);
    loadSelectColorSurfaces({ technology });
    loadMaterialCategoryOptions({ technology }, false);
    if (is3DPTechnology(technology)) {
      load3DTechnologyOptions(false);
      loadThreeDMaterialOptions(
        {
          technology,
          threeDTechnology,
        },
        false
      ).catch(() => {
        setThreeDMaterialOptions(['Custom Material']);
      });
    }

    const params = {
      technology,
      threeDTechnology,
      material,
    };
    loadSurfaceFinishOptions(params, false);
    if (materialColor) {
      loadMaterialColorOptions(params, false);
    }
    if (color) {
      loadSurfaceFinishColorOptions(
        {
          technology,
          surfaceFinish,
        },
        false
      );
    }
  }, []);

  async function handleBulkApply(params) {
    const selectedItemIDs = selectedItems?.map((_item) => _item.id);
    for (const partID of selectedItemIDs) {
      const part = itemState?.find((i) => i.id === partID);
      if (!part) {
        continue;
      }

      const newGeneratedFields = part?.generatedFields?.includes('unitType')
        ? ['unitType']
        : [];

      const updatedItem = {
        id: partID,
        ...params,
        generatedFields: newGeneratedFields,
      };
      dispatch(updateCadPartIfExists(updatedItem));
      dispatch(getPpePriceForCadPart(partID));
    }
    onClose();
  }

  function handleSubmitForm() {
    if (!isNumber(qty) || Number(qty) <= 0) {
      return;
    }

    if (isCustomTechnology(technology) && isEmptyValue(otherTechnology)) {
      return;
    }
    if (isCustomMaterial(material) && isEmptyValue(otherMaterial)) {
      return;
    }
    if (
      isCustomSurfaceFinish(surfaceFinish) &&
      isEmptyValue(otherSurfaceFinish)
    ) {
      return;
    }

    const value = {
      qty,
      technology,
      threeDTechnology,
      material,
      otherMaterial,
      materialColor,
      surfaceFinish,
      otherSurfaceFinish,
      threeDInfill,
      threeDLayerThickness,
      expectedLeadTime,
      color,
      tolerance,
      anodizingType,
    };
    handleBulkApply(value);
    onClose();
  }

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='sm'
      scroll='body'
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      fullScreen={isMobile}
      PaperProps={{ className: classes.dialog }}
      onClose={onClose}
    >
      <DialogTitle disableTypography className={classes.title}>
        <FlexColumn>
          <FtrH5>Change specifications</FtrH5>
          <FtrTypography
            type='subHeading'
            fontSize='16'
            style={{ color: colors.neutral060 }}
          >
            {selectedItems?.length} parts selected
          </FtrTypography>
        </FlexColumn>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FlexColumn style={{ gap: '1rem' }}>
          <FlexColumn style={{ gap: 0 }}>
            <FtrFieldLabel>Quantity</FtrFieldLabel>
            <TextField
              size='small'
              type='number'
              margin='none'
              value={qty}
              variant='outlined'
              inputProps={{ min: 1 }}
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
                inputProps: { min: 1 },
              }}
              style={{ width: '100%' }}
              onChange={(evt) => {
                let value = evt.target.value;
                setQty(value);
              }}
              // disable change value when mouse scroll
              onWheel={(e) => e.target.blur()}
              error={!isNumber(qty) || Number(qty) <= 0}
              helperText={
                !isNumber(qty) || Number(qty) <= 0 ? 'Invalid value' : null
              }
            />
          </FlexColumn>
          <FlexColumn style={{ gap: 0 }}>
            <FtrFieldLabel>Technology</FtrFieldLabel>
            <FtrDropdown
              key='technology-dropdown'
              fullWidth
              value={technology}
              handleChange={(newTech) => {
                const _technology = newTech;
                let _tolerance = getDefaultTolerance({
                  technology: _technology,
                  unitType,
                });
                setTechnology(_technology);
                setTolerance(_tolerance);
                technologyHasChanged(_technology);
                if (is3DPTechnology(_technology)) {
                  setThreeDInfill(threeDPrintingInfillDefault);
                  setThreeDLayerThickness(threeDPrintingLayerThicknessDefault);
                } else {
                  setThreeDTechnology(null);
                  setThreeDInfill('');
                  setThreeDLayerThickness('');
                }
                setOtherTechnology(null);
              }}
              items={technologyOptions}
            />
          </FlexColumn>
          {is3DPTechnology(technology) && (
            <>
              <FieldLabel>
                <FlexRow>3D Printing Technology</FlexRow>
              </FieldLabel>
              <ThreeDPrintingTechnologyInputField
                bootstrapStyle={false}
                visible={is3DPTechnology(technology)}
                value={threeDTechnology}
                onChange={(newValue) => {
                  const _threeDTechnology = newValue;
                  const _tolerance = getDefaultTolerance({
                    technology,
                    unitType,
                    threeDTechnology: _threeDTechnology,
                  });
                  setThreeDTechnology(newValue);
                  setTolerance(_tolerance);
                  const params = {
                    technology,
                    threeDTechnology: newValue,
                  };
                  threeDTechnologyHasChanged(params);
                  if (newValue === THREE_D_P_FDM_TECH) {
                    setThreeDInfill(threeDPrintingInfillDefault);
                    setThreeDLayerThickness(
                      threeDPrintingLayerThicknessDefault
                    );
                  } else {
                    setThreeDInfill('');
                    setThreeDLayerThickness('');
                  }
                }}
                threeDTechnologyOptions={threeDTechnologyOptions}
              />
            </>
          )}
          {isCustomTechnology(technology) && (
            <FlexColumn style={{ gap: 0 }}>
              <FtrFieldLabel>Custom Technology</FtrFieldLabel>
              <TextField
                size='small'
                type='text'
                margin='none'
                value={otherTechnology}
                variant='outlined'
                InputProps={{
                  style: {
                    borderRadius: '10px',
                  },
                }}
                style={{ width: '100%' }}
                onChange={(evt) => setOtherTechnology(evt.target.value)}
                error={
                  isCustomTechnology(technology) &&
                  isEmptyValue(otherTechnology)
                }
                helperText={
                  isCustomTechnology(technology) &&
                  isEmptyValue(otherTechnology)
                    ? 'Required'
                    : ''
                }
              />
            </FlexColumn>
          )}
          <FlexColumn style={{ gap: 0 }}>
            <MaterialCategoriesInputField
              technology={technology}
              visible={!is3DPTechnology(technology)}
              value={material}
              onSelect={(newValue) => {
                setMaterial(newValue);
                const params = {
                  technology,
                  threeDTechnology,
                  material: newValue,
                };
                materialHasChanged(params);
                setOtherMaterial(null);
              }}
              materialCategoryOptions={materialCategoryOptions}
              isBuyer
            />
            <FlexColumn style={{ gap: 0 }}>
              <>
                <FieldLabel>
                  <FlexRow>Material</FlexRow>
                </FieldLabel>
                <ThreeDPrintingMaterialField
                  bootstrapStyle={false}
                  technology={technology}
                  threeDTechnology={threeDTechnology}
                  visible={is3DPTechnology(technology)}
                  value={material}
                  onSelect={(_material) => {
                    setMaterial(_material);
                    const params = {
                      technology,
                      threeDTechnology,
                      material: _material,
                    };
                    materialHasChanged(params);
                    setOtherMaterial(null);
                  }}
                  threeDMaterialOptions={threeDMaterialOptions}
                  defaultThreeDMaterial={defaultThreeDMaterial}
                />
              </>
              {isCustomMaterial(material) && (
                <FlexColumn style={{ gap: 0 }}>
                  <FtrFieldLabel>Custom Material</FtrFieldLabel>
                  <TextField
                    size='small'
                    type='text'
                    margin='none'
                    value={otherMaterial}
                    variant='outlined'
                    InputProps={{
                      style: {
                        borderRadius: '10px',
                      },
                    }}
                    style={{ width: '100%' }}
                    onChange={(evt) => {
                      setOtherMaterial(evt.target.value);
                    }}
                    error={
                      isCustomMaterial(material) && isEmptyValue(otherMaterial)
                    }
                    helperText={
                      isCustomMaterial(material) && isEmptyValue(otherMaterial)
                        ? 'Required'
                        : ''
                    }
                  />
                </FlexColumn>
              )}
              {!isEmptyValue(materialColorOptions) && (
                <ColorInputField
                  bootstrapStyle={false}
                  visible={!isEmptyValue(materialColorOptions)}
                  value={materialColor}
                  colorInputLabel='Material Color'
                  colorPalette={materialColorOptions}
                  onSubmit={(selectedColor) => {
                    setMaterialColor(selectedColor || materialColor);
                  }}
                />
              )}
            </FlexColumn>
          </FlexColumn>
          {is3DPTechnology(technology) &&
            threeDTechnology === THREE_D_P_FDM_TECH && (
              <Fragment>
                <FlexColumn style={{ gap: 0 }}>
                  <FieldLabel>
                    <FlexRow>3D Infill</FlexRow>
                  </FieldLabel>
                  <ThreeDInfillSelectField
                    bootstrapStyle={false}
                    visible
                    value={threeDInfill}
                    onSelect={(newValue) => {
                      setThreeDInfill(newValue);
                    }}
                    ppe3dpInfillOptions={ppe3dpInfillOptions}
                  />
                </FlexColumn>
                <FlexColumn style={{ gap: 0 }}>
                  <FieldLabel>
                    <FlexRow>Layer Thickness</FlexRow>
                  </FieldLabel>
                  <ThreeDLayerThicknessField
                    bootstrapStyle={false}
                    visible
                    value={threeDLayerThickness}
                    onSelect={(newValue) => {
                      setThreeDLayerThickness(newValue);
                    }}
                  />
                </FlexColumn>
              </Fragment>
            )}
          {(!isEmptyValue(surfaceFinishOptions) ||
            isCustomSurfaceFinish(surfaceFinish)) && (
            <FlexColumn style={{ gap: 0 }}>
              <FtrFieldLabel>Surface Finish</FtrFieldLabel>
              <FtrDropdown
                key='surface-finish-dropdown'
                fullWidth
                value={surfaceFinish}
                handleChange={(newValue) => {
                  setSurfaceFinish(newValue);
                  const params = {
                    technology,
                    material,
                    surfaceFinish: newValue,
                  };
                  surfaceFinishHasChanged(params);
                  setOtherSurfaceFinish(null);
                }}
                items={surfaceFinishOptions}
              />
              {isCustomSurfaceFinish(surfaceFinish) && (
                <FlexColumn style={{ gap: 0 }}>
                  <FtrFieldLabel>Custom Surface Finish</FtrFieldLabel>
                  <TextField
                    size='small'
                    type='text'
                    margin='none'
                    value={otherSurfaceFinish}
                    variant='outlined'
                    InputProps={{
                      style: {
                        borderRadius: '10px',
                      },
                    }}
                    style={{ width: '100%' }}
                    onChange={(evt) => {
                      setOtherSurfaceFinish(evt.target.value);
                    }}
                    error={
                      isCustomSurfaceFinish(surfaceFinish) &&
                      isEmptyValue(otherSurfaceFinish)
                    }
                    helperText={
                      isCustomSurfaceFinish(surfaceFinish) &&
                      isEmptyValue(otherSurfaceFinish)
                        ? 'Required'
                        : ''
                    }
                  />
                </FlexColumn>
              )}
              {!isEmptyValue(surfaceFinishColorOptions) && (
                <FlexColumn style={{ gap: 0 }}>
                  <ColorInputField
                    visible={!isEmptyValue(surfaceFinishColorOptions)}
                    value={color}
                    colorInputLabel={colorInputLabel}
                    colorPalette={surfaceFinishColorOptions}
                    onSubmit={(selectedColor) => {
                      setColor(selectedColor || color);
                    }}
                  />
                </FlexColumn>
              )}
              {isAnodizingSurfaceFinish(surfaceFinish) && (
                <FlexColumn style={{ gap: 0 }}>
                  <FtrFieldLabel>Anodizing Type</FtrFieldLabel>
                  <FtrDropdownV2
                    id='anodizing-type-dropdown'
                    key='anodizing-type-dropdown'
                    fullWidth
                    value={anodizingType || ANODIZING_TYPE_OPTIONS[0].key}
                    handleChange={(newType) => setAnodizingType(newType)}
                    items={ANODIZING_TYPE_OPTIONS}
                  />
                </FlexColumn>
              )}
            </FlexColumn>
          )}
          <FlexColumn style={{ gap: 0 }}>
            <FtrFieldLabel>Target Lead Time</FtrFieldLabel>
            <DatePicker
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
              }}
              disableToolbar
              value={expectedLeadTime}
              onChange={setExpectedLeadTime}
              animateYearScrolling
              inputVariant='outlined'
              margin='dense'
              fullWidth
              clearable
              clearLabel='No Preference'
              emptyLabel='No Preference'
              disablePast
            />
          </FlexColumn>
        </FlexColumn>
        <FlexRow
          style={{
            justifyContent: 'end',
            marginTop: '1rem',
          }}
        >
          <FtrButton
            color='black'
            size='small'
            variant='text-black'
            onClick={onClose}
          >
            Cancel
          </FtrButton>
          <FtrSmallButton onClick={handleSubmitForm}>Apply</FtrSmallButton>
        </FlexRow>
      </DialogContent>
    </Dialog>
  );
}

export default BulkConfigurePartsPopupV2;
